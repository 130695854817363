import CustomModal from '../../../components/base/CustomModal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useScandiumMutation, useScandiumQuery } from '../../../data-layer/utils';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Divider } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import Flex from '../../../components/base/Flex';
import EmptyState from '../../../components/base/EmptyState';
import { useDebounce } from 'use-debounce';
import { toast } from 'react-toastify';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase
} from '../../../components/helpers/inputHelper';
import { useTheme } from '@emotion/react';

const FolderItem = ({ folder, onClick, clickedFolder, isSubmitting }) => {
  const theme = useTheme();
  return (
    <Box
      px={2}
      py={2}
      sx={{ cursor: 'pointer' }}
      backgroundColor={'rgba(36, 195, 217, 0.1)'}
      borderRadius={'8px'}
      onClick={onClick}>
      <Flex justifyContent={'space-between'} columnGap={2}>
        <Flex>
          <FolderIcon sx={{ color: '#24C3D9' }} />
          <Typography fontWeight={500} color={theme.palette.text.black_grey} sx={{ lineHeight: 1, mt: '4px' }}>
            {folder?.name || ''}
          </Typography>
        </Flex>
        {clickedFolder?.id === folder?.id && isSubmitting && (
          <CircularProgress size={18} color={'primary'} />
        )}
      </Flex>
    </Box>
  );
};

const MoveToFolder = ({
  open,
  onClose,
  testId,
  refetch,
  folderItem,
  moveTest = true,
  targetResource,
  mobileId
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [clickedFolder, setClickedFolder] = React.useState(null);
  const [debouncedQuery] = useDebounce(searchQuery, 900);
  const { projectId } = useParams();
  const theme = useTheme();

  const { data: folders, isLoading } = useScandiumQuery(`/projects/${projectId}/folders`, {
    enabled: !!projectId && !!open,
    params: {
      target_resource: targetResource || undefined,
      mobile_app_id: mobileId || undefined
    },
    select: (response) => (response.data.length ? flattenDirectory(response.data) : [])
  });

  const { mutateAsync, isLoading: isSubmitting } = useScandiumMutation(
    `/projects/${projectId}/${moveTest ? `test-cases/${testId}` : `folders/${folderItem?.id}`}`,
    {
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
        setClickedFolder(null);
      },
      onSuccess: (data) => {
        toast.success(moveTest ? 'Test case moved successfully' : 'Folder moved successfully');
        refetch();
        setClickedFolder(null);
        onClose();
      }
    }
  );

  const handleMoveTestToFolder = async (folder) => {
    setClickedFolder(folder);

    await mutateAsync({
      [moveTest ? 'folder_id' : 'parent_id']: folder.id,
      ...(folderItem && { name: folderItem.name })
    });
  };

  function flattenDirectory(directory) {
    const result = [];

    for (const entry of directory) {
      result.push({
        id: entry.id,
        name: entry.name
      });

      if (entry.subdirectories && entry.subdirectories.length > 0) {
        result.push(...flattenDirectory(entry.subdirectories));
      }
    }

    return result;
  }

  const filteredFolders = (folders || []).filter((folder) =>
    folder.name?.toLowerCase().includes(debouncedQuery?.toLowerCase().trim())
  );

  return (
    <CustomModal
      open={open}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'} minHeight={400}>
        <IconButton
          onClick={() => {
            onClose();
            setClickedFolder(null);
          }}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          sx={{
            fontSize: '1.4rem'
          }}>
          {moveTest ? 'Move Test Case' : 'Move Folder'}
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          color={theme.palette.text.custom}
          sx={{
            fontSize: '0.9rem'
          }}>
          {moveTest
            ? 'Please select a destination folder to move the current test case into.'
            : 'Please select a destination folder to move the current folder into.'}
        </Typography>

        <Box mb={3} width={'200px'}>
          <Box>
            <Search sx={{ width: { xs: 'max-content' } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </Search>
          </Box>
        </Box>

        {isLoading && (
          <Box
            height={'250px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress color={'secondary'} size={26}></CircularProgress>
          </Box>
        )}

        {!isLoading && filteredFolders?.length === 0 && (
          <EmptyState
            title={'There are no matching folders'}
            description={'You can create a new folder or adjust your search query'}
          />
        )}

        <CustomScrollbar
          sx={{
            overflowY: 'auto',
            maxHeight: '350px',
            overflowX: 'hidden'
          }}>
          {filteredFolders?.map((folder, index) => (
            <React.Fragment key={index}>
              {folder.id !== folderItem?.id && (
                <>
                  <FolderItem
                    isLoading={isSubmitting}
                    folder={folder}
                    clickedFolder={clickedFolder}
                    onClick={() => handleMoveTestToFolder(folder)}
                  />
                  {index + 1 < folders.length && <Divider sx={{ my: 1 }} />}
                </>
              )}
            </React.Fragment>
          ))}
        </CustomScrollbar>
      </Box>
    </CustomModal>
  );
};
export default MoveToFolder;
