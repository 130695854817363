import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import Modal from '@mui/material/Modal';
import CustomButton from "../../../components/customButton";


const InstallExtensionCta = ({ openModal, setOpenModal }) => {
  const EXTENSION_ID = 'dokpohocljpghkmobklkccilgdiecgok'
  const webStoreLink = `https://chrome.google.com/webstore/detail/${EXTENSION_ID}`

  const handleInstallExtension = () => {
    window.open(webStoreLink)
    setOpenModal(false)
  }

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-setOpenModal"
      aria-describedby="modal-modal-description"
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '25rem',
          bgcolor: 'background.paper',
          border: '1px solid #42a5f5',
          borderRadius: '0.6rem',
          boxShadow: 24,
          px: 0.3,
          pb: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: { xs: 0.4, sm: 0.4, },
          }}
        >
          <IconButton
            aria-label="cancel"
            sx={{
              float: 'right',
              mr: { xs: 0.4, sm: 0.4, },
            }}
            onClick={() => setOpenModal(false)}
          >
            <DisabledByDefaultRoundedIcon
              color='disabled'
              sx={{
                fontSize: 30
              }}
            />
          </IconButton>
        </Box>
          <Box
            sx={{
              mx: { xs: 3, sm: 3, },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography
              id="modal-modal-setOpenModal"
              variant="h4"
              color='warning'
              sx={{
                textAlign: 'center',
                fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.3rem' },
              }}
            >
              Install Scandium Browser Extension on your Chrome Browser
            </Typography>
            <Typography
              id="modal-modal-setOpenModal"
              color='primary'
              sx={{
                textAlign: 'center',
                fontSize: { xs: '0.8rem', sm: '1.0rem', },
                mt: { xs: 2, sm: 2, },
              }}
            >
              Note: Scandium only works on Chrome Browser at this moment
            </Typography>
            <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  mt: { xs: 2, sm: 2, }
                }}
              >
                <CustomButton
                  onClick={() => setOpenModal(false)}
                  disableRipple={true}
                  disableFocusRipple={true}
                  fullWidth
                  disableElevation={true}
                  label='Dismiss'
                  sx={{
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    typography: { xs: 'caption', sm: 'caption' },
                    borderRadius: '0.4rem',
                    bgcolor: "#EDEDED",
                    color: '#000000',
                    py: { xs: 0.97, sm: 0.97, },
                    width: { xs: '7rem', sm: '10rem', },
                    height: { xs: '2.3rem', sm: '2.3rem', },
                    '&:hover': {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                />
                <CustomButton
                  onClick={handleInstallExtension}
                  disableRipple={true}
                  disableFocusRipple={true}
                  fullWidth
                  disableElevation={true}
                  label='Install'
                  sx={{
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    typography: { xs: 'caption', sm: 'caption' },
                    borderRadius: '0.4rem',
                    bgcolor: "primary",
                    color: '#FFFFFF',
                    py: { xs: 1.2, sm: 1.2, },
                    ml: { xs: '1.0rem', sm: '0rem', },
                    width: { xs: '7rem', sm: '10rem', },
                    height: { xs: '2.3rem', sm: '2.3rem', },
                  }}
                />
              </Box>
              <Typography
                textAlign={'center'}
                sx={{ mt: 2, fontSize: '0.75rem' }}
              >
                Refresh this page after successful installation of the extension
              </Typography>
          </Box>
      </Box>
    </Modal>
  );
};

export default InstallExtensionCta;
