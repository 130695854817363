import { useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Tooltip, Divider, Skeleton, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomScrollbar from '../../../TestPage/components/CustomScrollbar';
import Flex from '../../../../components/base/Flex';
import TestActionMenu from '../../components/TestActionMenu';
import { useConfirmDialog } from '../../../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import EmptyState from '../../../../components/base/EmptyState';
import ErrorState from '../../../../components/base/ErrorState';
import { TestAutomationContext } from '../../../../store/testAutomationState';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useTheme } from '@emotion/react';
export const colorMap = {
  get: '#53BB63',
  post: '#FFB100',
  put: '#00CCFF',
  patch: '#E39FF6',
  delete: '#FF775E',
  head: '#53BB63',
  options: '#B03060'
};
const FolderDisplayInAutomation = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box>
      <Box
        sx={{
          borderRadius: '2px',
          borderBottom: '0.05px solid #ccc',
          borderTop: '0.05px solid #ccc',
          borderRight: '0.05px solid #ccc',
          marginBottom: '2px'
        }}>
        <Box
          sx={{
            bgcolor: 'rgba(36, 195, 217, 0.05)',
            py: '10px',
            pl: '8px',
            pr: '4px',
            // borderLeft: data.id === +folderId ? '3px solid #1958b8' : '',
            cursor: 'pointer',
            userSelect: 'none',
            transition: 'background-color 0.3s ease',
            '&: hover': {
              background: 'rgba(36, 195, 217, 0.2)'
            }
          }}
          onClick={(e) => e.currentTarget.contains(e.target) && handleClick()}>
          <Flex justifyContent={'space-between'} sx={{ maxHeight: 'max-content', minHeight: 0 }}>
            <Tooltip title={data.name}>
              <Typography
                variant={'body2'}
                fontWeight={400}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  textOverflow: 'ellipsis'
                }}>
                {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                <FolderIcon sx={{ color: '#24C3D9', ml: 1, mr: '3px' }} /> {data.name}
              </Typography>
            </Tooltip>
          </Flex>
        </Box>
      </Box>
      {isOpen &&
        data.subdirectories?.map((subfolder) => (
          <Box key={subfolder.id} pl={1.5} sx={{ bgcolor: 'rgba(36, 195, 217, 0.05)' }}>
            <FolderDisplayInAutomation data={subfolder} />
          </Box>
        ))}
      {isOpen &&
        data.api_tests?.map((test, index) => (
          <TestRequestDisplayInAutomation
            key={index}
            data={data.api_tests}
            index={index}
            test={test}
            folderId={data.id}
          />
        ))}
      {isOpen && !data.api_tests && data.subdirectories?.length === 0 && (
        <Typography textAlign={'center'} variant={'body2'} py={1.5}>
          This folder is empty
        </Typography>
      )}
    </Box>
  );
};
const TestExample = ({ test, index, data, refetchTests, testId, resetInitialState, folderId }) => {
  const navigate = useNavigate();
  const { exampleId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredTestCase, setHoveredTestCase] = useState(null);
  const testRef = useRef(null);
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const activeProject = useActiveProject();

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const { mutateAsync: deleteTest, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${testId}/examples/${test.uuid}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTests();

        if (exampleId === test.uuid) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteTest = async () => {
    if (await requestConfirm()) return await deleteTest();
  };

  const addNewPathSegment = () => {
    if (exampleId !== test.uuid) {
      resetInitialState();
    }

    // Use the navigate function to update the URL
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${testId}/example/${test.uuid}` : `${testId}/example/${test.uuid}`
      }`
    );
  };

  return (
    <Box
      pr={'4px'}
      pl={'4px'}
      sx={{
        bgcolor: exampleId === test.uuid ? 'rgba(220,220,220, 0.5)' : 'transparent',
        borderLeft: exampleId === test.uuid ? '2px solid #1958B8' : '',
        borderBottom: '0.05px solid #ddd',
        '&: hover': {
          backgroundColor: 'rgba(220,220,220, 0.1)'
        }
      }}
      onClick={(e) => e.currentTarget.contains(e.target) && addNewPathSegment()}
      onMouseEnter={() => setHoveredTestCase(test.uuid)}
      onMouseLeave={() => setHoveredTestCase(null)}>
      <Flex
        justifyContent={'space-between'}
        sx={{
          cursor: 'pointer',
          py: '2px'
        }}>
        <Flex columnGap={0}>
          <IconButton size={'small'} onClick={handleClick} sx={{ visibility: 'hidden' }}>
            {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          <Tooltip title={test.name}>
            <Flex
              variant={'body2'}
              columnGap={0}
              sx={{
                width: 'inherit',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                pl: exampleId === test.uuid ? '18px' : '20px'
              }}>
              <Typography
                component={'span'}
                variant={'body2'}
                sx={{
                  border: '2px solid black',
                  fontSize: '6px',
                  px: '2.2px',
                  py: '1px',
                  color: 'black',
                  fontWeight: 'bold'
                }}>
                e.g.
              </Typography>
              <Typography
                variant={'body2'}
                component={'span'}
                ml={'6px'}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  alignSelf: 'stretch'
                }}>
                {test.name}
              </Typography>
            </Flex>
          </Tooltip>
        </Flex>
        {hoveredTestCase === test.uuid && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <TestActionMenu
              onDelete={handleDeleteTest}
              ref={testRef}
              isLoading={isDeleting}
              exampleId={test.uuid}
              onEdit={addNewPathSegment}
            />
          </Box>
        )}
      </Flex>
      {index + 1 < data.length && <Divider />}
      <ConfirmationDialog
        title={'Are you sure you want to delete this request example?'}
        description={'When you delete a request example, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </Box>
  );
};
const TestRequestDisplayInAutomation = ({ test, index, data, folderId }) => {
  const theme = useTheme();
  const { activeSuite, setActiveSuite } = useContext(TestAutomationContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const isAdded = activeSuite?.test_cases?.find((testCase) => {
    return testCase.api_test_case_id === test.id;
  });
  const AddTestToCurrentAutomateList = () => {
    return (
      <Typography
        color={'primary'}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => {
          if (!isAdded) {
            // console.log(test);
            setActiveSuite((prev) => {
              return {
                ...prev,
                test_cases: [
                  ...prev.test_cases,
                  {
                    id: test.id,
                    api_test_case: {
                      name: test.name,
                      request_method: test.request_method
                    },
                    api_test_case_id: test.id,
                    status: 'active'
                  }
                ]
              };
            });
          } else {
            setActiveSuite((prev) => {
              const prevSuite = { ...prev };
              const newList = prevSuite.test_cases.filter((testCase) => {
                return testCase.api_test_case_id !== test.id;
              });
              return { ...prevSuite, test_cases: newList };
            });
          }
        }}>
        {!!isAdded ? (
          <Tooltip title={'Remove Test'} arrow>
            <RemoveOutlinedIcon />
          </Tooltip>
        ) : (
          <Tooltip title={'Add Test'} arrow>
            <AddOutlinedIcon />
          </Tooltip>
        )}
      </Typography>
    );
  };
  return (
    <Box>
      <Box
        pr={'4px'}
        pl={folderId ? '14px' : '4px'}
        sx={{
          // bgcolor: testId === test.id && !exampleId ? 'rgba(220,220,220, 0.5)' : 'transparent',
          // borderLeft: testId === test.id && !exampleId ? '2px solid #1958B8' : '',

          '&: hover': {
            backgroundColor: theme.palette.hover.default
          }
        }}
        onClick={(e) => {}}>
        <Flex
          justifyContent={'space-between'}
          sx={{
            cursor: 'pointer',
            py: '2px'
          }}>
          <Flex columnGap={0} sx={{ width: '90%' }}>
            <Tooltip title={test.name}>
              <Flex
                variant={'body2'}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}>
                <Typography
                  component={'span'}
                  variant={'body2'}
                  textTransform={'uppercase'}
                  fontWeight={600}
                  sx={{ color: colorMap[test.request_method.toLowerCase()] || 'black' }}>
                  {test.request_method}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  ml={1}
                  sx={{
                    width: 'inherit',
                    overflow: 'hidden',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}>
                  {test.name}
                </Typography>
              </Flex>
            </Tooltip>
          </Flex>
          <AddTestToCurrentAutomateList />
        </Flex>
        {index + 1 < data.length && <Divider />}
      </Box>
      {/* {isOpen &&
        test.examples?.map((example, index) => (
          <TestExample
            key={example.uuid}
            index={index}
            test={example}
            data={test}
            testId={test.id}
            folderId={folderId}
          />
        ))} */}
    </Box>
  );
};
export const TestAndFoldersDisplayInAutomation = ({
  folders,
  isLoadingTestFolder,
  tests,
  error
}) => {
  const [path] = useState([]);
  const { activeSuite } = useContext(TestAutomationContext);
  const currentFolder = path.length > 0 ? path[path.length - 1] : null;

  const arrayOfNumbers = Array.from({ length: 10 }, (_, index) => index + 1);

  const allEntries = [...folders, ...tests?.data];

  return (
    <>
      {!!activeSuite && (
        <CustomScrollbar
          sx={{ overflowY: 'auto', maxHeight: '300px', pr: 1, mb: 3, maxWidth: '550px' }}>
          <Box>
            {currentFolder ? (
              <FolderDisplayInAutomation data={currentFolder} />
            ) : (
              folders?.map((folder) => <FolderDisplayInAutomation key={folder.id} data={folder} />)
            )}
            {tests.data?.map((test, index) => (
              <TestRequestDisplayInAutomation
                key={index}
                data={tests.data}
                index={index}
                test={test}
              />
            ))}
            {isLoadingTestFolder && (
              <Box>
                {arrayOfNumbers.map((num) => (
                  <Skeleton
                    animation={'wave'}
                    key={num}
                    variant={'rectangular'}
                    width={'100%'}
                    height={30}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Box>
            )}

            {!isLoadingTestFolder && error ? (
              <ErrorState error={error} />
            ) : (
              !isLoadingTestFolder &&
              !allEntries?.length && (
                <EmptyState
                  width={'50px'}
                  height={'50px'}
                  title={
                    !folders?.length && !tests.data?.length
                      ? "It's lonely here at the moment"
                      : 'There are no matching results'
                  }
                  description={
                    !folders?.length && !tests.data?.length
                      ? `Why not create a few tests or folders?`
                      : 'Try searching for something else, or create a new test'
                  }
                />
              )
            )}
          </Box>
        </CustomScrollbar>
      )}
    </>
  );
};
