import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
  TableHead
} from '@mui/material';
// import { StatusTag, StyledTableCell, StyledTableRow } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import { formatDate } from '../../../utils/formateDate';
import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import MobileSvg from '../../../components/svg/MobileSvg';
import ActionMenu from './ActionMenu';
import { getDateTime } from '../../../utils/time';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useTheme } from '@emotion/react';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
    padding: '8px 0px',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    },
    '.cell-content-wrapper': {
      padding: '16px',
      backgroundColor: 'rgba(36, 195, 217, 0.1)',
      color: theme.palette.text.resultTableText,
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: '68px',
      display: 'flex',
      alignItems: 'center'
    },

    '&:first-child': {
      minWidth: '250px',
      '.cell-content-wrapper': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    },

    '&:last-child': {
      '.cell-content-wrapper': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:last-child td, &:last-child th': {
    border: 0
  },
  '&:focus td, &:focus th': {
    '.cell-content-wrapper': {
      borderTop: '1px solid #1958B8',
      borderBottom: '1px solid #1958B8'
    }
  },
  '&:focus th': {
    '&:first-of-type': {
      '.cell-content-wrapper': {
        borderLeft: '1px solid #1958B8'
      }
    }
  },
  '&:focus td': {
    '&:last-of-type': {
      '.cell-content-wrapper': {
        borderRight: '1px solid #1958B8'
      }
    }
  }
}));

const AppRow = ({ id, app, refetchMobileApps }) => {
  const navigate = useNavigate();
  const activeProject = useActiveProject();
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const theme = useTheme();

  const { mutateAsync: deleteApp, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/mobile-tests/apps/${app.publicKey}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchMobileApps();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteApp = async () => {
    if (await requestConfirm()) return await deleteApp();
  };

  return (
    <StyledTableRow
      sx={{ textDecoration: 'none' }}
      onClick={(e) =>
        e.currentTarget.contains(e.target) &&
        navigate(`/projects/${activeProject?.id}/mobile-testing/${app.publicKey}/app?page=1`)
      }>
      <StyledTableCell component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <MobileSvg fill={theme.palette.svg.main} />
          <Typography
            fontWeight={500}
            sx={{
              lineHeight: 1,
              mt: '4px',
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}>
            {app.name}
          </Typography>
        </Flex>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{app.bundle}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{app.platform}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{app.creator?.name || ''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{getDateTime(app.created)}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <ActionMenu isLoading={isDeleting} onDelete={handleDeleteApp} app={true} />
        </Box>
      </StyledTableCell>
      <ConfirmationDialog
        title={'Are you sure you want to delete this app?'}
        description={
          'When you delete a app, all associated test cases and data will be permanently removed'
        }
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </StyledTableRow>
  );
};

const AllAppsTable = ({ mobileApps, refetchMobileApps }) => {
  const { testId } = useParams();
  const activeProject = useActiveProject();

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
      <Table sx={{ width: '100%', minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Bundle Id</StyledTableCell>
            <StyledTableCell>Platform</StyledTableCell>
            <StyledTableCell>Created By</StyledTableCell>
            <StyledTableCell>Created On</StyledTableCell>
            <StyledTableCell align={'right'}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mobileApps?.map((app, index) => (
            <AppRow key={index} id={index} app={app} refetchMobileApps={refetchMobileApps} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllAppsTable;
