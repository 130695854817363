import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { useTheme } from '@emotion/react';

const ActionMenu = React.forwardRef(({ isLoading, onDelete, disabled, isDelete }, ref) => {
  const { triggerProps, menuProps } = useMenu();
  const theme = useTheme();

  return (
    <div>
      <IconButton
        ref={ref}
        aria-controls={menuProps.open ? 'action-menu' : undefined}
        aria-haspopup="true"
        disabled={isLoading || disabled}
        aria-expanded={menuProps.open ? 'true' : undefined}
        {...triggerProps}>
        {isLoading ? (
          <CircularProgress size={18} color={'primary'} />
        ) : (
          <MoreVert sx={{ color: theme.palette.svg.main }} />
        )}
      </IconButton>
      <Menu id="action-menu" {...menuProps} elevation={1}>
        <MenuItem sx={{ color: 'red' }} onClick={onDelete}>
          {isDelete ? 'Delete' : 'Remove'}
        </MenuItem>
      </Menu>
    </div>
  );
});

export default ActionMenu;
