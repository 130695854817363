import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import { stripLeadingSlash } from '../utils/strings';

export const API_URL = process.env.REACT_APP_API_URL;

/**
 * Returns the queryKey for a react-query request. The key is obtained by splitting the url into fragments (hence creating a unique key for each request). The params is only helpful when we want to cache data
 * @param {String} url
 * @param {object} params
 * @returns {(String | Object)[]}
 */
export const getReactQueryKey = (url, params) => {
  return [...url.split('/').filter((fragment) => !!fragment), params || {}];
};

/**
 * A handy method to obtain the queryFn to be used in useQuery or useMutation
 * @param path
 * @param params
 * @param token
 * @param headers
 * @param method
 * @returns {function(*): Promise<Response>}
 */
export const getQueryFn = ({ path, params, token, headers, method }) => {
  // The returned function takes the data because that often needs to be set at calling time.
  return async (data) => {
    const urlObj = new URL(`${API_URL}/${stripLeadingSlash(path)}`);
    Object.entries(params || {}).forEach(([key, value]) => {
      if (![null, undefined].includes(value)) urlObj.searchParams.set(key, value);
    });
    const url = urlObj.toString();

    const _headers = {
      Accept: 'application/json',
      Authorization: token && `Bearer ${token}`,
      ...(headers || {})
    };
    const response = await fetch(url, {
      method,
      body: data,
      headers: _headers
    });

    if (!response.ok) {
      const error = await response.json();
      throw {
        status: response.status,
        ...error
      };
    }

    return await response.json();
  };
};

// * This perhaps does not scale well enough for all scenarios, but suffices for now.
// * Ideally we want a separate hook created for each request. But this generalisation helps us avoid a lot of repetition

export const useScandiumUpload = (
  path,
  { method = 'POST', mutationKey, params, headers, mutationFn, json, ...reactQueryOptions } = {}
) => {
  const [cookies] = useCookies(['token', 'email']);

  const _queryFn = getQueryFn({ path, params, token: cookies?.token, headers, method });

  return useMutation({
    mutationKey: mutationKey || getReactQueryKey(path, params),
    mutationFn: mutationFn || _queryFn,
    ...(reactQueryOptions || {})
  });
};
