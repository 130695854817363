const TestSuiteSvg = ({ width, height, fill }) => {
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.7775 13L25.3801 13.9613C25.479 14.0205 25.5608 14.1043 25.6177 14.2046C25.6745 14.3048 25.7044 14.4181 25.7044 14.5333C25.7044 14.6486 25.6745 14.7618 25.6177 14.8621C25.5608 14.9623 25.479 15.0462 25.3801 15.1053L13.0001 22.5333L0.620132 15.1053C0.52126 15.0462 0.439419 14.9623 0.382594 14.8621C0.325768 14.7618 0.295898 14.6486 0.295898 14.5333C0.295898 14.4181 0.325768 14.3048 0.382594 14.2046C0.439419 14.1043 0.52126 14.0205 0.620132 13.9613L2.2228 13L13.0001 19.4667L23.7775 13ZM23.7775 19.2667L25.3801 20.228C25.479 20.2872 25.5608 20.371 25.6177 20.4713C25.6745 20.5715 25.7044 20.6848 25.7044 20.8C25.7044 20.9152 25.6745 21.0285 25.6177 21.1288C25.5608 21.229 25.479 21.3128 25.3801 21.372L13.6868 28.388C13.4794 28.5126 13.2421 28.5784 13.0001 28.5784C12.7582 28.5784 12.5208 28.5126 12.3135 28.388L0.620132 21.372C0.52126 21.3128 0.439419 21.229 0.382594 21.1288C0.325768 21.0285 0.295898 20.9152 0.295898 20.8C0.295898 20.6848 0.325768 20.5715 0.382594 20.4713C0.439419 20.371 0.52126 20.2872 0.620132 20.228L2.2228 19.2667L13.0001 25.7333L23.7775 19.2667ZM13.6855 0.745345L25.3801 7.76134C25.479 7.82053 25.5608 7.90434 25.6177 8.00459C25.6745 8.10484 25.7044 8.21811 25.7044 8.33334C25.7044 8.44858 25.6745 8.56185 25.6177 8.6621C25.5608 8.76235 25.479 8.84615 25.3801 8.90534L13.0001 16.3333L0.620132 8.90534C0.52126 8.84615 0.439419 8.76235 0.382594 8.6621C0.325768 8.56185 0.295898 8.44858 0.295898 8.33334C0.295898 8.21811 0.325768 8.10484 0.382594 8.00459C0.439419 7.90434 0.52126 7.82053 0.620132 7.76134L12.3135 0.745345C12.5208 0.620754 12.7582 0.554932 13.0001 0.554932C13.2421 0.554932 13.4794 0.620754 13.6868 0.745345H13.6855Z"
        fill={fill || '#24C3D9'}
      />
    </svg>
  );
};
export default TestSuiteSvg;
