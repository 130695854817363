import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { useParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Flex from '../../components/base/Flex';
import ActionPopup from './components/ActionPopup';
import { useActiveProject } from '../../store/projectState';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumQuery } from '../../data-layer/utils';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';
import RunsTable from './components/RunsTable';
import AppFilter from './components/AppFilter';
import { useDebounce } from 'use-debounce';
import { format } from 'date-fns';
import { useFetchTest } from '../../data-layer/test-management';
import usePagination from '../../hooks/usePagination';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Ranges } from '../Reports/Reports';
import StatusFilter from './components/StatusFilter';

const resultsOptions = [
  { value: 'all', label: 'All' },
  { value: 'passed', label: 'Passed' },
  { value: 'failed', label: 'Failed' }
];

const deviceOptions = [
  { value: undefined, label: 'All' },
  { value: 'android', label: 'Android' },
  { value: 'ios', label: 'iOS' }
];

export const _resultFIlter = [
  { label: 'All', value: undefined },
  { label: 'Passed', value: 'success' },
  { label: 'Failed', value: 'error' }
];

const RunsAndReports = () => {
  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();
  const { testId } = useParams();
  const activeProject = useActiveProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterByApp, setFilterByApp] = useState(null);
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [dateRange, setDateRange] = useState([]);
  const initialStatus = searchParams.get('status');
  const initialDevice = searchParams.get('device');
  const [filterByStatus, setFilterByStatus] = useState(
    _resultFIlter.find((filter) => filter.value === initialStatus) || _resultFIlter[0]
  );
  const [filterByDevice, setFilterByDevice] = useState(
    deviceOptions.find((filter) => filter.value === initialDevice) || deviceOptions[0]
  );

  const { afterToday } = DateRangePicker;

  useDocumentTitle('Mobile - Runs and Reports');

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    const params = new URLSearchParams(searchParams);

    if (!newQuery) {
      params.delete('query');
    } else {
      params.set('query', newQuery);
    }

    setSearchParams(params, { replace: true });
  };

  const handleChangeDatePicker = (newValue) => {
    setDateRange(newValue);

    if (newValue?.length === 2) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        date_from: newValue[0].toISOString().split('T')[0],
        date_to: newValue[1].toISOString().split('T')[0]
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('date_from');
      newParams.delete('date_to');
      setSearchParams(newParams);
      setDateRange(null);
    }
  };

  useEffect(() => {
    const dateFrom = searchParams.get('date_from');
    const dateTo = searchParams.get('date_to');

    if (dateFrom && dateTo) {
      setDateRange([new Date(dateFrom), new Date(dateTo)]);
    }
  }, []);

  const {
    data: mobileApps,
    error: mobileError,
    isLoading: isLoadingMobileApps
  } = useScandiumQuery(`/projects/${activeProject?.id}/mobile-tests/apps`, {
    enabled: !!activeProject?.id,
    params: {
      unlimited: 'true'
    },
    select: (data) => data.data
  });

  const {
    data: runsResponse,
    error: runsError,
    isLoading: isLoadingRuns
  } = useScandiumQuery(
    `/projects/${activeProject?.id}/mobile-tests/${testId ? `${testId}/` : ''}runs`,
    {
      enabled: !!activeProject?.id,
      params: {
        status: filterByStatus.value,
        search: debouncedQuery || undefined,
        page: activePage,
        limit: pageLimit,
        platform: filterByDevice.value,
        appKey: filterByApp?.publicKey || undefined,
        date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
        date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
      }
    }
  );

  const testRuns = runsResponse?.runs || runsResponse?.results || [];

  const paginate = runsResponse?.runs || runsResponse?.results?.meta;

  const { data: testData } = useFetchTest({
    projectId: activeProject?.id,
    testId,
    select: (data) => data.data
  });

  if (isLoadingMobileApps || (!mobileError && !mobileApps)) return <PageLoader />;
  if (mobileError) return <ErrorState error={mobileError} />;

  return (
    <Box mt={-2}>
      <Flex justifyContent={'space-between'} flexWrap={'wrap'} mb={4}>
        <Typography
          sx={{
            textAlign: 'right',
            fontSize: '1.3rem',
            fontWeight: 500
          }}>
          {testData?.name || 'Test Runs'} - {testRuns?.total || testRuns?.meta?.total}
        </Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          <Box display={'flex'} flexWrap={'wrap'}>
            <DateRangePicker
              shouldDisableDate={afterToday()}
              appearance={'default'}
              style={{ width: 210, marginRight: '10px' }}
              value={dateRange}
              onChange={handleChangeDatePicker}
              placement={'bottom'}
              ranges={Ranges}
              size={'sm'}
              placeholder={'Filter by Date Range'}
            />
            <StatusFilter
              selectedFilter={filterByStatus}
              setSelectedFilter={setFilterByStatus}
              filterOptions={_resultFIlter}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              query={'status'}
              defaultButtonLabel={'Filter by Result'}
            />
            {!testId && (
              <>
                <StatusFilter
                  selectedFilter={filterByDevice}
                  setSelectedFilter={setFilterByDevice}
                  filterOptions={deviceOptions}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  query={'device'}
                  defaultButtonLabel={'Filter by Device'}
                />
                <AppFilter
                  value={filterByApp}
                  setValue={setFilterByApp}
                  options={deviceOptions}
                  title={'Filter by App'}
                  mobileApps={mobileApps}
                />
              </>
            )}
          </Box>
          {!testId && (
            <Box>
              <Search sx={{ width: { xs: 'max-content' } }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={'Search test case names'}
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
              </Search>
            </Box>
          )}
        </Box>
      </Flex>

      <RunsTable testData={testData} testRuns={testRuns?.data} />

      {isLoadingRuns && <PageLoader height={'100px'} />}
      {!isLoadingRuns &&
        (runsError
          ? !testRuns?.data?.length && <ErrorState error={runsError} />
          : !testRuns?.data?.length && (
              <EmptyState
                description={`Try searching for something else, or run some tests a few times`}></EmptyState>
            ))}

      {paginate?.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={paginate?.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Runs per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Runs per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(paginate?.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default RunsAndReports;
