import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Flex from '../../../components/base/Flex';
import { useParams, useNavigate } from 'react-router-dom';
import { useScandiumMutation, useScandiumQuery } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { useContext, useEffect } from 'react';
import { TestAutomationContext } from '../../../store/testAutomationState';
import { TestAndFoldersDisplayInAutomation } from './components/TestAndFolderDisplayInAutomation';
import { toast } from 'react-toastify';
import TestListAndScheduleTab from './components/TestListsAndScheduleTab';
import Sidebar from '../../../components/sidebar';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import useAwaitModal from '../../../hooks/useAwaitModal';
import ScheduleModal from '../../../components/ScheduleModal';
import { ArrowBack } from '@mui/icons-material';
import PageLoader from '../../../components/PageLoader';
import SaveSvg from '../../../components/svg/SaveSvg';
const CreateOrEditSuite = () => {
  const activeProject = useActiveProject();
  const { activeSuite, setActiveSuite, testsPage, setTestpage } = useContext(TestAutomationContext);
  const { api_suite_id } = useParams();
  const navigate = useNavigate();
  const [
    requestScheduleModal,
    { open: openScheduleModal, onClose: onCloseScheduleModal, onComplete: completeScheduleModal }
  ] = useAwaitModal();

  const {
    data: allFolders = { folders: { data: [] }, test_cases: { data: [] } },
    isFetching: isFetchingAllFoldersAndTests,
    error: folderAndTestErrors,
    refetch: refetchFoldersAndTests
  } = useScandiumQuery(`/projects/${activeProject?.id}/api-tests/all`, {
    enabled: !!activeProject?.id,
    select: (data) => data.data
  });

  const {
    mutateAsync: runTestSuite,
    isLoading: isRunningSuite,
    isSuccess: runSuccessful
  } = useScandiumMutation(`/projects/${activeProject?.id}/api-suites/${api_suite_id}/run`, {
    method: 'POST',
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: () => {
      toast.success('Api suite run initiated. You will get a notification when it is ready', {
        autoClose: 1500
      });
      const timeOutId = setTimeout(() => {
        navigate(`/projects/${activeProject?.id}/api-suites/api_suite_runs?page=1`);
        return () => clearTimeout(timeOutId);
      }, 2500);
    },
    enabled: !!api_suite_id
  });

  const paginate = allFolders.test_cases.data.meta;

  const {
    isFetching: isFetchingSuite,
    refetch: refetchSingleSuite,
    isSuccess: fetchedSingleSuite
  } = useScandiumQuery(`/projects/${activeProject?.id}/api-suites/${api_suite_id}`, {
    queryKey: ['fetch-single-suite'],
    enabled: !!activeProject?.id && !!api_suite_id,
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      const sortSuiteTestByOrder = data.data.test_cases.sort((a, b) => a.order - b.order);
      data.data.test_cases = sortSuiteTestByOrder;
      setActiveSuite(data.data);
    }
  });
  useEffect(() => {
    setActiveSuite({ name: '', description: '', test_cases: [] });
  }, []);
  const { mutateAsync: createSuite, isLoading: isSavingSuite } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-suites`,
    {
      method: 'POST',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Test Suite created successfully', { autoClose: 800 });
        setTimeout(() => {
          navigate(`/projects/${activeProject?.id}/api-suites/automate`);
        }, 1000);
      }
    }
  );
  const { mutateAsync: updateTestSuite, isLoading: isUpdatingSuite } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-suites`,
    {
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Test Suite updated successfully', { autoClose: 1000 });
        // queryClient.invalidateQueries({ queryKey: ['api-suites'] });
        //invalidate the fetch suite query
      }
    }
  );
  const handlePageChange = (e, value) => {
    setTestpage(value.toString());
  };
  const handleSuiteCreate = () => {
    const testCases = activeSuite.test_cases.map((test) => {
      return { id: test.api_test_case_id, status: test.status };
    });
    const data = {
      name: activeSuite.name,
      test_cases: testCases,
      description: activeSuite.description || ''
    };
    return createSuite(data);
  };
  const handleSuiteUpdate = () => {
    let order = 0;
    const testCases = activeSuite.test_cases.map((test) => {
      order++;
      return { id: test.api_test_case_id, status: test.status, order };
    });

    const data = {
      api_suite_id: activeSuite.id,
      name: activeSuite.name,
      test_cases: testCases,
      description: activeSuite.description
    };
    return updateTestSuite(data);
  };
  const { isFeatureEnabled: showAPIAutomationMenu } = useFeatureEnabled('api-automation');
  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        mt: 18
      }}>
      {!!fetchedSingleSuite && !showAPIAutomationMenu ? (
        <Box
          sx={{
            width: '200px',
            height: 'auto',
            margin: 'auto'
          }}>
          <PageLoader height={'100px'} />
        </Box>
      ) : (
        <>
          <Stack gap={50} width="200px" sx={{ height: 'inherit', justifyItems: 'center', pl: 5 }}>
            <Flex
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                mr: 3,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              <ArrowBack />
              <Typography fontSize={16}>Back </Typography>
            </Flex>
            <Sidebar height={'500px'} customStyle={true} />{' '}
          </Stack>
          <Stack
            flex={1}
            gap={2}
            sx={{
              mb: 10,
              px: 10,
              minHeight: '700px'
            }}>
            <Flex>
              <Typography color={'primary'} fontSize={18} fontWeight={500} sx={{ mr: 'auto' }}>
                {!!api_suite_id ? 'Edit Test Suite' : 'Create Test Suite'}
              </Typography>
              <Flex ml={'auto'}>
                {isRunningSuite ? (
                  <CircularProgress size={24} sx={{ mr: 2 }} />
                ) : (
                  <Tooltip title={'run suite'}>
                    <PlayCircleOutlineOutlinedIcon
                      onClick={() => {
                        runTestSuite();
                      }}
                      color="primary"
                      fontSize="large"
                      sx={{
                        mr: 2,
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Tooltip>
                )}
                <Chip
                  variant={'outlined'}
                  label={'Save Test Suite'}
                  onClick={() => {
                    !!api_suite_id ? handleSuiteUpdate() : handleSuiteCreate();
                  }}
                  disabled={!activeSuite?.name}
                  size={'small'}
                  color={'primary'}
                  icon={
                    isSavingSuite || isUpdatingSuite ? (
                      <CircularProgress size={18} />
                    ) : (
                      <SaveSvg fill={'#1976d2'} />
                    )
                  }
                  sx={{
                    fontSize: 12,
                    columnGap: 1,
                    borderRadius: '0.3rem',
                    px: 0.2,
                    py: 2
                  }}
                />
                <Chip
                  label={`Schedule`}
                  disabled={!!activeSuite?.schedules?.length > 0}
                  onClick={() => {
                    requestScheduleModal();
                  }}
                  size={'small'}
                  color={'primary'}
                  icon={<AddIcon />}
                  sx={{
                    fontSize: 12,
                    columnGap: 1,
                    borderRadius: '0.3rem',
                    px: 0.2,
                    py: 2,
                    // ml: 'auto',
                    display: !!api_suite_id ? 'box' : 'none'
                  }}
                />
              </Flex>
            </Flex>
            <Flex
              sx={{
                width: 'inherit',
                // flex: 1,
                minHeight: 'inherit',
                // outline: '1px solid green',
                columnGap: 6,
                alignItems: 'flex-start'
              }}>
              <Stack
                gap={3}
                sx={{
                  flex: 1
                  // outline: '1px solid blue'
                }}>
                <TextField
                  size="large"
                  label="Suite Name"
                  // defaultValue={!!fetchedSuite ? singleSuite.data.name : ''}
                  placeholder="Name your Test Suite"
                  value={activeSuite?.name}
                  onChange={(e) => {
                    setActiveSuite((prev) => {
                      return { ...prev, name: e.target.value };
                    });
                  }}
                  InputProps={{
                    endAdornment: !!isFetchingSuite && (
                      <InputAdornment position="start">
                        {' '}
                        <CircularProgress size={18} color="info" />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  placeholder="Add description"
                  multiline
                  minRows={8}
                  label="Description"
                  value={activeSuite?.description}
                  onChange={(e) => {
                    setActiveSuite((prev) => {
                      return { ...prev, description: e.target.value };
                    });
                  }}
                />
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography color={'primary'} fontSize={15}>
                    Add / Remove test cases
                  </Typography>
                  <TestAndFoldersDisplayInAutomation
                    folders={allFolders.folders.data}
                    isLoadingTestFolder={isFetchingAllFoldersAndTests}
                    tests={allFolders.test_cases}
                    error={folderAndTestErrors}
                  />
                  {paginate?.total > 15 && (
                    <Pagination
                      count={paginate?.last_page}
                      page={+testsPage || 1}
                      color="secondary"
                      onChange={handlePageChange}
                      size={'small'}
                      sx={{
                        '& .Mui-selected': {
                          backgroundColor: '#24C3D9',
                          color: '#ffffff'
                        },
                        '& .MuiPaginationItem-root:not(.Mui-selected)': {
                          backgroundColor: '#1958B8',
                          color: '#fff'
                        },
                        ml: 2
                      }}
                    />
                  )}
                </Stack>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Box
                sx={{
                  flex: 1
                }}>
                <TestListAndScheduleTab
                  isFetchingSuite={isFetchingSuite}
                  refetchSingleSuite={refetchSingleSuite}
                />
              </Box>
            </Flex>
          </Stack>
          {!!api_suite_id && (
            <ScheduleModal
              open={openScheduleModal}
              onClose={onCloseScheduleModal}
              onSuiteCreated={completeScheduleModal}
              scheduleType={'api_suite'}
              suiteId={api_suite_id}
              refetchSuites={refetchSingleSuite}
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default CreateOrEditSuite;
