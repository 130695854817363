import Flex from '../../../../components/base/Flex';
import TextButton from '../../../../components/base/TextButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import TypeTextSvg from '../../../../components/svg/TypeTextSvg';
import { useMenu } from '../../../../components/base/useMenu';

const TypeText = ({ orientation, session, theme, isRecordingMode }) => {
  const { triggerProps, menuProps } = useMenu();
  const [value, setValue] = useState('');

  const handleOnChange = async (e) => {
    setValue(e.target.value);
    await session.type(e.target.value);
  };

  const anchorOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'right' }
      : { vertical: 'bottom', horizontal: 'left' };

  const transformOrigin =
    orientation === 'portrait'
      ? { vertical: 'top', horizontal: 'left' }
      : { vertical: 'top', horizontal: 'left' };

  return (
    <Box>
      <TextButton
        sx={{ py: 1 }}
        {...triggerProps}
        disabled={!session || !isRecordingMode}
        aria-controls="simple-menu"
        aria-haspopup="true">
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <TypeTextSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Type Text
          </Typography>
        </Flex>
      </TextButton>
      <Menu
        id="simple-menu"
        {...menuProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={1}
        PaperProps={{
          style: {
            width: 250
          }
        }}>
        <Box pl={2}>
          <TextField
            fullWidth
            multiline
            minRows={6}
            value={value}
            onChange={handleOnChange}
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            placeholder={'Enter text'}
            size={'small'}
          />
        </Box>
      </Menu>
    </Box>
  );
};

export default TypeText;
