import { SvgIcon } from '@mui/material';

const MobileReportSvg = ({ width = 12, height = 18, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 12 18'} {...props} fill={'none'}>
      <path
        d="M9.08789 0H2.02148C0.906855 0 0 0.90682 0 2.02148V15.9785C0 17.0932 0.90682 18 2.02148 18H9.08789C10.2026 18 11.1094 17.0932 11.1094 15.9785V2.02148C11.1094 0.90682 10.2026 0 9.08789 0ZM2.02148 1.05469H9.08789C9.621 1.05469 10.0547 1.48841 10.0547 2.02148V13.8867H1.05469V2.02148C1.05469 1.48841 1.48841 1.05469 2.02148 1.05469ZM9.08789 16.9453H2.02148C1.48837 16.9453 1.05469 16.5116 1.05469 15.9786V14.9414H10.0547V15.9785C10.0547 16.5116 9.621 16.9453 9.08789 16.9453Z"
        fill="#24C3D9"
      />
      <path
        d="M5.55468 15.3633C5.26345 15.3633 5.02734 15.5994 5.02734 15.8907V15.961C5.02734 16.2522 5.26345 16.4883 5.55468 16.4883C5.84592 16.4883 6.08203 16.2522 6.08203 15.961V15.8907C6.08203 15.5994 5.84595 15.3633 5.55468 15.3633Z"
        fill="#24C3D9"
      />
    </SvgIcon>
  );
};

export default MobileReportSvg;
