import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ContainedButton from '../../../components/base/ContainedButton';
import TextButton from '../../../components/base/TextButton';
import { useMenu } from '../../../components/base/useMenu';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTheme } from '@emotion/react';

const Reports = ({
  handleDownloadReport,
  isLoading,
  setReportFormat,
  format,
  mode,
  setMode,
  suite,
  setSource,
  source,
  runs
}) => {
  const { menuProps, triggerProps } = useMenu();
  const theme = useTheme();

  return (
    <Box>
      <TextButton
        {...triggerProps}
        loadingProps={{ size: 16 }}
        disabled={!runs?.length}
        startIcon={<CloudDownloadIcon sx={{ color: theme.palette.svg.main }} />}
        isLoading={isLoading}>
        Export
      </TextButton>
      <Popover
        {...menuProps}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Box
          as={'ul'}
          role={'listbox'}
          sx={{
            px: 2,
            py: 3,
            my: 0,
            listStyle: 'none',
            maxWidth: 'max-content'
          }}>
          <Box as={'li'}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Mode</FormLabel>
              <RadioGroup
                aria-labelledby={'demo-controlled-radio-buttons-group'}
                name={'controlled-radio-buttons-group'}
                row
                size={'small'}
                value={mode}
                onChange={(e) => setMode(e.target.value)}>
                <FormControlLabel
                  value={'simple'}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'Simple'}
                />
                <FormControlLabel
                  value={'advanced'}
                  disabled
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'Advanced'}
                />
              </RadioGroup>
            </FormControl>
            <Divider sx={{ my: 1 }} />
          </Box>
          <Box as={'li'}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Format</FormLabel>
              <RadioGroup
                aria-labelledby={'demo-controlled-radio-buttons-group'}
                name={'controlled-radio-buttons-group'}
                row
                size={'small'}
                value={format}
                onChange={(e) => setReportFormat(e.target.value)}>
                <FormControlLabel
                  value={'excel'}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'Excel'}
                />
                <FormControlLabel
                  value={'csv'}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'Csv'}
                />
                <FormControlLabel
                  value={'pdf'}
                  disabled
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'Pdf'}
                />
              </RadioGroup>
            </FormControl>
            <Divider sx={{ my: 1 }} />
          </Box>
          {/* {!!suite && (
            <Box as={'li'}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Source</FormLabel>
                <RadioGroup
                  aria-labelledby={'demo-controlled-radio-buttons-group'}
                  name={'controlled-radio-buttons-group'}
                  row
                  size={'small'}
                  value={source}
                  onChange={(e) => setSource(e.target.value)}>
                  <FormControlLabel value={'all'} control={<Radio />} label={'All'} />
                  <FormControlLabel value={'local'} control={<Radio />} label={'Local'} />
                  <FormControlLabel
                    value={'remote'}
                    disabled
                    control={<Radio />}
                    label={'Remote'}
                  />
                </RadioGroup>
              </FormControl>
              <Divider sx={{ my: 1 }} />
            </Box>
          )} */}

          <Box as={'li'} sx={{ mt: 2 }}>
            <ContainedButton
              fullWidth
              onClick={() => {
                handleDownloadReport();
                menuProps.onClose();
              }}>
              Download
            </ContainedButton>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Reports;
