import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useScandiumQuery } from '../../../data-layer/utils';
import { SelectDropdown } from './GithubConnectModal';

const ClickUpConnectModal = ({
  open,
  onClose,
  value,
  handleUpdate,
  onComplete,
  updateChannelData,
  setSuccessChannels,
  successChannels,
  errorChannels,
  setErrorChannels,
  activeTab,
  isClickUpTokenSuccess,
  showClickUpSettings,
  handlePostChannels
}) => {
  const activeChannel = activeTab === 0 ? successChannels : errorChannels;
  const clickupChannel = activeChannel.find((channel) => channel.channel === 'clickup');
  const activeWorkspace = clickupChannel.channel_details.data;

  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceSpaces, setWorkspaceSpaces] = useState([]);
  const [spaceFolders, setSpaceFolders] = useState([]);
  const [folderLists, setFolderLists] = useState([]);

  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedWorkspacesSpace, setSelectedWorkspaceSpaces] = useState(null);
  const [selectedSpaceFolder, setSelectedSpaceFolder] = useState(null);
  const [selectedFolderLists, setSelectedFolderLists] = useState(null);

  const handleWorkspaceChange = (event) => {
    setSelectedWorkspace(event.target.value);
  };

  const handleWorkspaceSpacesChange = (event) => {
    setSelectedWorkspaceSpaces(event.target.value);
  };

  const handleSpaceFoldersChange = (event) => {
    setSelectedSpaceFolder(event.target.value);
  };

  const handleFolderListsChange = (event) => {
    setSelectedFolderLists(event.target.value);
  };

  useEffect(() => {
    const matchingWorkspace = workspaces?.find(
      (workspace) => workspace.id === activeWorkspace?.workspace_id
    );
    const matchingSpace = workspaceSpaces?.find(
      (space) => space.id === activeWorkspace?.workspace_space_id
    );
    const matchingSpaceFolder = spaceFolders?.find(
      (folder) => folder.id === activeWorkspace?.space_folder_id
    );
    const matchingFolderList = folderLists?.find(
      (list) => list.id === activeWorkspace?.list_id
    );

    setSelectedWorkspace(matchingWorkspace || workspaces?.[0]);
    setSelectedWorkspaceSpaces(matchingSpace || workspaceSpaces?.[0]);
    setSelectedSpaceFolder(matchingSpaceFolder || spaceFolders?.[0]);
    setSelectedWorkspace(matchingFolderList || folderLists?.[0]);
  }, [activeChannel, clickupChannel]);

  const handleSaveTrelloData = async () => {
    if (
      !selectedWorkspace ||
      !selectedWorkspacesSpace ||
      !selectedSpaceFolder ||
      !selectedFolderLists
    ) {
      toast.error('All fields are required');
      return;
    }

    const updater = activeTab === 0 ? setSuccessChannels : setErrorChannels;

    updater((prevChannels) =>
      prevChannels.map((channel) => {
        if (channel.channel === 'clickup') {
          return {
            ...channel,
            status: 'active',
            channel_details: {
              data: {
                workspace_id: selectedWorkspace?.id,
                workspace_space_id: selectedWorkspacesSpace?.id,
                space_folder_id: selectedSpaceFolder?.id,
                list_id: selectedFolderLists?.id
              }
            }
          };
        } else {
          return channel;
        }
      })
    );

    onClose();
  };

  const { isLoading: isFetchingWorkspaces } = useScandiumQuery(
    '/third-parties/clickup/workspaces',
    {
      enabled: !!showClickUpSettings || isClickUpTokenSuccess,
      onSuccess: (data) => {
        const matchingWorkspace = data.data.find(
          (workspace) => workspace.id === activeWorkspace?.workspace_id
        );
        setSelectedWorkspace(matchingWorkspace || data?.data?.[0]);
        setWorkspaces(data.data);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { isLoading: isFetchingWorkspaceSpaces } = useScandiumQuery(
    `/third-parties/clickup/workspaces/${selectedWorkspace?.id}/spaces`,
    {
      enabled: !!selectedWorkspace?.id,
      onSuccess: (data) => {
        const matchingWorkspaceSpace = data.data.find(
          (workspace) => workspace.id === activeWorkspace?.workspace_space_id
        );
        setSelectedWorkspaceSpaces(matchingWorkspaceSpace || data?.data?.[0]);
        setWorkspaceSpaces(data.data);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { isLoading: isFetchingSpaceFolders } = useScandiumQuery(
    `/third-parties/clickup/spaces/${selectedWorkspacesSpace?.id}/folders`,
    {
      enabled: !!selectedWorkspacesSpace?.id,
      onSuccess: (data) => {
        const matchingSpaceFolder = data.data.find(
          (folder) => folder.id === activeWorkspace?.space_folder
        );
        setSelectedSpaceFolder(matchingSpaceFolder || data?.data?.[0]);
        setSpaceFolders(data.data);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { isLoading: isFetchingFolderLists } = useScandiumQuery(
    `/third-parties/clickup/folders/${selectedSpaceFolder?.id}/lists`,
    {
      enabled: !!selectedSpaceFolder?.id,
      onSuccess: (data) => {
        const matchingFolderList = data.data.find(
          (list) => list.id === activeWorkspace?.list_id
        );
        setSelectedFolderLists(matchingFolderList || data?.data?.[0]);
        setFolderLists(data.data);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Connect Your Clickup Account
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          The modal allows you to choose a Clickup workspace. When a test fails or passes, a card
          will be created in the selected folder list to track the outcome.
        </Typography>

        <Box>
          <SelectDropdown
            options={workspaces}
            isLoading={isFetchingWorkspaces}
            onChange={handleWorkspaceChange}
            value={selectedWorkspace}
            label={'Select workspace'}
            labelId={'Workspaces'}
            sx={{ mb: 2 }}
          />

          <SelectDropdown
            options={workspaceSpaces}
            isLoading={isFetchingWorkspaceSpaces}
            onChange={handleWorkspaceSpacesChange}
            value={selectedWorkspacesSpace}
            label={'Select space'}
            labelId={'Workspace spaces'}
            sx={{ mb: 2 }}
          />

          <SelectDropdown
            options={spaceFolders}
            isLoading={isFetchingSpaceFolders}
            onChange={handleSpaceFoldersChange}
            value={selectedSpaceFolder}
            label={'Select a space folder'}
            labelId={'Space Folders'}
            sx={{ mb: 2 }}
          />
          <SelectDropdown
            options={folderLists}
            isLoading={isFetchingFolderLists}
            onChange={handleFolderListsChange}
            value={selectedFolderLists}
            label={'Select a folder list'}
            labelId={'Folder list'}
            sx={{ mb: 2 }}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton onClick={handleSaveTrelloData} color={'primary'} autoFocus>
            connect
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default ClickUpConnectModal;
