import React, { useContext, useEffect, useState } from 'react';
import { TestCaseContext } from '../store/testState';

export const useDebounceSearchValue = (delay = 1200) => {
  const { searchValue } = useContext(TestCaseContext);
  const [debounceSearchValue, setDebounceSearchValue] = useState(searchValue);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceSearchValue(searchValue);
    }, delay);
    return () => clearTimeout(timeout);
  }, [searchValue]);
  return { debounceSearchValue };
};
