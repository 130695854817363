import React, { useContext, useRef, useState } from 'react';
import Editor from '@monaco-editor/react';
import * as xlsx from 'xlsx';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import {
  Box,
  Chip,
  Divider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Button
} from '@mui/material';
import BackButton from '../../../components/base/BackButton';
import Flex from '../../../components/base/Flex';
import ContainedButton from '../../../components/base/ContainedButton';
import ResponseSvg from '../../../components/svg/ResponseSvg';
import Dropdown from '../../../components/base/Dropdown';
import { ScreenSize } from './TestProperties';
import AddIcon from '@mui/icons-material/Add';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import OutlinedButton from '../../../components/base/OutlinedButton';
import UploadFileSvg from '../../../components/svg/UploadFileSvg';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { toast } from 'react-toastify';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../../AppTheme';

const TabPanel = ({ children, value, index }) => {
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const VariableInput = ({ index, variable, onDelete, onVariableChange }) => {
  const handleNameChange = (event) => {
    onVariableChange(index, 'name', event.target.value);
  };

  const handleValueChange = (event) => {
    onVariableChange(index, 'value', event.target.value);
  };

  return (
    <Flex sx={{ alignItems: 'center', gap: 2, width: '100%', mb: 2 }}>
      <Flex sx={{ flex: '98%', gap: '4rem' }}>
        <TextField
          label={'Variable name'}
          variant={'outlined'}
          value={variable.name}
          size={'small'}
          onChange={handleNameChange}
          sx={{ flex: 0.55 }}
        />
        <TextField
          label={'Variable value'}
          variant={'outlined'}
          value={variable.value}
          size={'small'}
          onChange={handleValueChange}
          sx={{ flex: 0.5 }}
        />
      </Flex>

      <Tooltip title={'Delete this input fields'}>
        <Box sx={{ cursor: 'pointer', flex: '2%' }} onClick={() => onDelete(index)}>
          <DeleteSvg width={16} height={18} />
        </Box>
      </Tooltip>
    </Flex>
  );
};

const DataSource = ({ code, setCode, silentRefetchTest, onClose }) => {
  const { testId } = useParams();
  const activeProject = useActiveProject();
  const hiddenFileInput = useRef(null);
  const theme = useTheme();
  const { mode } = useContext(ColorContext);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const { mutateAsync: appendDataSource, isLoading: isAppendingDataSource } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${testId}`,
    {
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success('Data source updated successfully');
        silentRefetchTest();
      }
    }
  );

  const parseCSVToJson = async (fileContent) => {
    const resultObject = Papa.parse(fileContent, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        const rowsArray = [];
        const valuesArray = [];

        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        setCode(JSON.stringify(results.data, null, 2));
      }
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (file) {
      reader.onload = (e) => {
        const fileContent = e.target.result;
        if (file.type === 'application/json') {
          setCode(fileContent);
        } else if (file.type === 'text/csv') {
          parseCSVToJson(fileContent);
        } else if (file.type === excel) {
          parseExcelToJson(fileContent);
        } else {
          toast.error('Unsupported file type');
        }
      };
      file.type !== excel ? reader.readAsText(file) : reader.readAsArrayBuffer(file);
    }
  };

  const parseExcelToJson = async (excelContent) => {
    const workbook = xlsx.read(excelContent);
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = xlsx.utils.sheet_to_json(firstSheet);
    setCode(JSON.stringify(jsonData, null, 2));
  };

  const handleAppendData = async () => {
    if (!code) {
      toast.error('No data source provided');
      return;
    }

    const testData = JSON.parse(code, null, 2);

    if (!Array.isArray(testData)) {
      toast.error('Invalid data source');
      return;
    }

    await appendDataSource({ data_source: testData });
  };

  const editorOptions = {
    fontSize: 12,
    formatOnPaste: true,
    renderIndentGuides: true,
    formatOnType: true,
    inlineSuggest: true,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  const beautifyCode = () => {
    try {
      const parsedJSON = JSON.parse(code);
      const beautifiedJSON = JSON.stringify(parsedJSON, null, 2);
      return beautifiedJSON;
    } catch (error) {
      return code; // Return as is if JSON parsing fails
    }
  };

  const handleBeautify = () => {
    const beautified = beautifyCode();
    setCode(beautified);
  };

  return (
    <Box>
      <ContainedButton
        onClick={handleClick}
        startIcon={<UploadFileSvg fill={theme.palette.svg.main} />}>
        Upload file
      </ContainedButton>
      <input
        ref={hiddenFileInput}
        accept={'.csv,.json,.xlsx'}
        type={'file'}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
      <Box
        data-color-mode="light"
        sx={{ width: '80%', mt: 2, backgroundColor: theme.palette.background.lowerToolBar }}>
        <Typography
          as={'h4'}
          mb={1}
          sx={{
            fontSize: '0.85rem',
            px: 2,
            pt: 1.5
            // mb: 1
          }}>
          <Typography as={'span'} fontWeight={'bold'}>
            Note
          </Typography>
          : Upload a CSV or Excel spreadsheet with rows of values corresponding to variables in your
          test. The test case will run for each row in the spreadsheet. The 1st row will be used as
          the variable name for each column
        </Typography>
        <Flex justifyContent={'flex-end'}>
          <Button
            onClick={handleBeautify}
            disableRipple
            sx={{
              minWidth: 0,
              mr: 3,
              textTransform: 'none',
              maxWidth: 'max-content'
            }}>
            Beautify
          </Button>
        </Flex>
        <Editor
          height={'300px'}
          theme={mode === 'dark' ? 'vs-dark' : 'blackboard'}
          value={code}
          onChange={(value, event) => setCode(value)}
          language={'json'}
          options={editorOptions}
        />
        <Divider />
      </Box>
      <Flex sx={{ width: '35%', mt: 4, mb: 8 }}>
        <ContainedButton
          isLoading={isAppendingDataSource}
          onClick={() => handleAppendData()}
          loadingProps={{ size: 14 }}
          sx={{ flex: '50%' }}>
          Save
        </ContainedButton>
        <OutlinedButton onClick={onClose} sx={{ flex: '50%' }}>
          Cancel
        </OutlinedButton>
      </Flex>
    </Box>
  );
};

const TestCaseVariables = ({
  handleAddVariable,
  variables,
  handleDeleteVariable,
  handleVariableChange,
  silentRefetchTest,
  setVariables,
  onClose
}) => {
  const { testId } = useParams();
  const activeProject = useActiveProject();

  const { mutateAsync: saveTestVariables, isLoading: isSavingTestVariables } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${testId}/variables`,
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success('Test case variables updated');
        silentRefetchTest();
      }
    }
  );

  const handleSaveTestVariables = async () => {
    // only save fields whose name and value are valid
    const _variables = variables.filter((variable) => variable.name && variable.value);

    if (!_variables.length) {
      toast.error('Please at least one variable must be specified');
      return;
    }

    await saveTestVariables({ variables: _variables });
  };

  return (
    <Box sx={{ width: '80%' }}>
      <Flex justifyContent={'space-between'} alignItems={'flex-end'} mb={4}>
        <Typography>
          Variables you add here will be available to all steps within the test
        </Typography>
        <Chip
          label={`Add new variable`}
          onClick={handleAddVariable}
          size={'small'}
          color={'primary'}
          icon={<AddIcon />}
          sx={{
            borderRadius: '0.3rem',
            px: 1,
            py: 2,
            mt: 1
          }}
        />
      </Flex>
      {variables.map((variable, index) => (
        <VariableInput
          key={index}
          index={index}
          variable={variable}
          onDelete={handleDeleteVariable}
          onVariableChange={handleVariableChange}
        />
      ))}
      {!!variables.length && (
        <Flex sx={{ width: '47.5%', mt: 8 }}>
          <ContainedButton
            isLoading={isSavingTestVariables}
            onClick={() => handleSaveTestVariables()}
            loadingProps={{ size: 14 }}
            sx={{ flex: '50%' }}>
            Save
          </ContainedButton>
          <OutlinedButton onClick={onClose} sx={{ flex: '50%' }}>
            Cancel
          </OutlinedButton>
        </Flex>
      )}
    </Box>
  );
};

const AdditionalTestSettings = ({
  onClose,
  title,
  browserType,
  operatingOS,
  savedTest,
  silentRefetchTest,
  description,
  setDescription,
  setTitle,
  customScreenSize,
  setCustomScreenSize,
  screenSizeIndex,
  setScreenSizeIndex,
  screenSize,
  setScreenSize
}) => {
  const dataSource = savedTest?.data_source ? JSON.stringify(savedTest?.data_source, null, 2) : ``;

  const [selectedTab, setSelectedTab] = useState(0);
  const [variables, setVariables] = useState(savedTest?.variables || []);
  const { testId, folderId } = useParams();
  const [browser, setBrowser] = useState(browserType);
  const [os, setOs] = useState(operatingOS);
  const [code, setCode] = useState(dataSource);
  const [pre_step_delay, setPreStepDelay] = useState(savedTest?.pre_step_delay || null);
  const [step_timeout, setStepTimeout] = useState(savedTest?.step_timeout || null);
  const theme = useTheme();

  const activeProject = useActiveProject();

  const { isFeatureEnabled: showDataDrivenFeature } = useFeatureEnabled('data-driven-test');

  const { mutateAsync: saveTestEnvironment, isLoading: isUpdatingTestEnvironment } =
    useScandiumMutation(`/projects/${activeProject?.id}/test-cases/${savedTest?.id || ''}`, {
      method: savedTest?.id ? 'PATCH' : 'POST',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success('Test execution environment updated');
        silentRefetchTest();
      }
    });

  const { mutateAsync: saveTest, isLoading: isSavingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${testId}`,
    {
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success(testId ? 'Test case updated successfully' : 'Test case created successfully');
        silentRefetchTest();
      }
    }
  );

  const handleSaveTest = async () => {
    if (!title) {
      toast.error('Please enter a test name');
      return;
    }

    if (title === 'Untitled test') {
      toast.error(
        'Please provide a descriptive name other than Untitled test for your test case before saving'
      );
      return;
    }

    await saveTest({
      name: title,
      description: description || null,
      pre_step_delay: pre_step_delay || null,
      step_timeout: step_timeout || null
    });
  };

  const updateTestEnvironment = async () => {
    if (!screenSize || !screenSize[0] || !screenSize[1]) {
      toast.error('Please enter a valid screen size');
      return;
    }

    const testData = {
      width: screenSize[0],
      height: screenSize[1],
      operating_system: os,
      browser
    };

    if (folderId) testData['folder_id'] = folderId;

    await saveTestEnvironment(testData);
  };

  const handleAddVariable = () => {
    setVariables([...variables, { name: '', value: '' }]);
  };

  const handleDeleteVariable = (index) => {
    const updatedVariables = variables.filter((_, i) => i !== index);
    setVariables(updatedVariables);
  };

  const handleVariableChange = (index, field, value) => {
    const updatedVariables = variables.map((variable, i) =>
      i === index ? { ...variable, [field]: value } : variable
    );
    setVariables(updatedVariables);
  };

  const browsersOptions = [
    { label: 'Chrome', value: 'chrome' },
    { label: 'Firefox', value: 'firefox' },
    { label: 'Edge', value: 'edge' }
  ];

  const oSoptions = [
    { label: 'MacOs', value: 'macos' },
    { label: 'Windows', value: 'windows' },
    { label: 'Linux', value: 'linux' }
  ];

  return (
    <Box flex={1} mr={2} mb={4}>
      <BackButton label={'Back'} onClick={onClose} />

      <Flex alignItems={'flex-end'} justifyContent={'space-between'}>
        <Flex>
          <Box>
            <Typography
              color="primary"
              sx={{
                textAlign: 'left',
                fontSize: '1.1rem'
              }}>
              Test Settings
            </Typography>
          </Box>
        </Flex>
      </Flex>

      <Tabs
        value={selectedTab}
        onChange={(event, value) => setSelectedTab(value)}
        indicatorColor={'secondary'}
        sx={{
          borderBottom: `3px solid ${theme.palette.table.outline}`,
          mt: 4
        }}
        TabIndicatorProps={{
          sx: { height: 3 }
        }}>
        <Tab
          label={'Test case details'}
          sx={{
            textTransform: 'none',
            textAlign: 'left',
            mr: 6,
            py: 1,
            px: 0,
            minWidth: 0,
            maxWidth: 'max-content',
            minHeight: 0,
            maxHeight: 'max-content'
          }}
        />
        <Tab
          label={'Execution environment'}
          sx={{
            textTransform: 'none',
            textAlign: 'left',
            mr: 6,
            px: 0,
            minWidth: 0,
            maxWidth: 'max-content',
            minHeight: 0,
            maxHeight: 'max-content'
          }}
        />
        <Tab
          label={'Test case variable'}
          sx={{
            textTransform: 'none',
            textAlign: 'left',
            mr: 6,
            px: 0,
            minWidth: 0,
            maxWidth: 'max-content',
            minHeight: 0,
            maxHeight: 'max-content'
          }}
        />
        {showDataDrivenFeature && (
          <Tab
            label={'Data source'}
            sx={{
              textTransform: 'none',
              textAlign: 'left',
              mr: 6,
              px: 0,
              minWidth: 0,
              maxWidth: 'max-content',
              minHeight: 0
            }}
          />
        )}
      </Tabs>

      {!testId && selectedTab !== 0 && (
        <Flex flexDirection={'column'} sx={{ mt: 7 }}>
          <ResponseSvg />
          <Typography mt={2} textAlign={'center'} fontSize={'1.2rem'} sx={{ color: '#000000' }}>
            Nothing to see here!
          </Typography>
          <Typography mt={1} textAlign={'center'} fontSize={'0.9rem'}>
            You have to firstly save a test <br /> case before you can make changes
          </Typography>
          <ContainedButton sx={{ mt: 2 }} onClick={onClose}>
            Back to previous page
          </ContainedButton>
        </Flex>
      )}

      <Box mt={5}>
        <TabPanel value={selectedTab} index={0}>
          <Flex
            sx={{ width: '80%', alignItems: 'normal', minHeight: '350px', mb: 12 }}
            columnGap={10}>
            <Flex
              sx={{
                alignItems: 'normal',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                flex: '50%'
              }}>
              <Box>
                <TextField
                  size={'small'}
                  value={title}
                  label={'Test Name'}
                  required
                  fullWidth
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  mb={2}
                />
                <TextField
                  label={'Pre step delay (ms)'}
                  type={'number'}
                  value={pre_step_delay}
                  fullWidth
                  size={'small'}
                  sx={{ mt: 3 }}
                  onChange={(e) => setPreStepDelay(e.target.value)}
                />
                <Typography variant={'body2'} color={theme.palette.text.black_grey}>
                  Override prestep delay
                </Typography>
                <TextField
                  label={'Step timeout (ms)'}
                  type={'number'}
                  value={step_timeout}
                  fullWidth
                  size={'small'}
                  sx={{ mt: 3 }}
                  onChange={(e) => setStepTimeout(e.target.value)}
                />
                <Typography variant={'body2'} color={theme.palette.text.black_grey}>
                  Override step timeout
                </Typography>
              </Box>
              <Flex sx={{ mt: 6 }}>
                <ContainedButton
                  isLoading={isSavingTest}
                  loadingProps={{ size: 14 }}
                  onClick={handleSaveTest}
                  sx={{ flex: '50%' }}>
                  Save
                </ContainedButton>
                <OutlinedButton onClick={onClose} sx={{ flex: '50%' }}>
                  Cancel
                </OutlinedButton>
              </Flex>
            </Flex>
            <TextField
              size={'small'}
              value={description}
              label={'Test Description'}
              multiline
              rows={7}
              fullWidth
              sx={{
                height: { xs: 1, sm: 1 },
                flex: '50%'
              }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Flex>
        </TabPanel>
      </Box>

      {!!testId && (
        <Box mt={5}>
          <TabPanel value={selectedTab} index={1}>
            <Box>
              <Typography mb={3}>Update Execution Environment</Typography>
              <Flex sx={{ width: '80%', alignItems: 'normal' }} columnGap={10}>
                <Flex
                  sx={{
                    alignItems: 'normal',
                    flexDirection: 'column',
                    gap: '1.5rem',
                    flex: '50%'
                  }}>
                  <Box mt={-1.5}>
                    <Dropdown
                      value={os}
                      onChange={setOs}
                      label={'Operating system'}
                      options={oSoptions}
                    />
                  </Box>
                  <Dropdown
                    value={browser}
                    onChange={setBrowser}
                    label={'Browser'}
                    options={browsersOptions}
                  />
                  <Flex sx={{ mt: 10 }}>
                    <ContainedButton
                      isLoading={isUpdatingTestEnvironment}
                      loadingProps={{ size: 14 }}
                      onClick={() => updateTestEnvironment()}
                      sx={{ flex: '50%' }}>
                      Save
                    </ContainedButton>
                    <OutlinedButton onClick={onClose} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Flex>
                <Flex
                  sx={{
                    alignItems: 'normal',
                    flexDirection: 'column',
                    gap: '1.5rem',
                    flex: '50%'
                  }}>
                  <ScreenSize
                    onChange={setScreenSize}
                    customScreenSize={customScreenSize}
                    setCustomScreenSize={setCustomScreenSize}
                    screenSizeIndex={screenSizeIndex}
                    setScreenSizeIndex={setScreenSizeIndex}
                    test
                  />
                </Flex>
              </Flex>
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <TestCaseVariables
              handleAddVariable={handleAddVariable}
              variables={variables}
              setVariables={setVariables}
              handleDeleteVariable={handleDeleteVariable}
              handleVariableChange={handleVariableChange}
              silentRefetchTest={silentRefetchTest}
              onClose={onClose}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <DataSource
              onClose={onClose}
              code={code}
              setCode={setCode}
              silentRefetchTest={silentRefetchTest}
            />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};

export default AdditionalTestSettings;
