import { SvgIcon } from '@mui/material';

const EmailNotificationSvg = ({ width = 35, height = 29, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} fill="none" viewBox="0 0 25 19" {...props}>
      <path
        d="M24.9167 1.08332V16.25C24.9157 16.8245 24.6879 17.3754 24.2829 17.7829L14.625 8.125L14.4733 7.89206L24.9167 1.08332Z"
        fill="#F6B445"
      />
      <path
        d="M24.2829 17.7829C23.8754 18.1879 23.3245 18.4157 22.75 18.4167H2.16669C1.59215 18.4157 1.04128 18.1879 0.63376 17.7829L10.2917 8.125L10.4271 7.91375L12.4584 9.20831L14.4733 7.89206L14.625 8.125L24.2829 17.7829Z"
        fill="#F6A645"
      />
      <path
        d="M10.4271 7.91375L10.2917 8.125L0.63376 17.7829C0.228779 17.3754 0.00102538 16.8245 9.75333e-06 16.25V2.16668C-0.000650403 1.94812 0.0322051 1.73073 0.0975097 1.52212L0.541693 1.625L10.4271 7.91375Z"
        fill="#F6B445"
      />
      <path
        d="M24.9167 1.08332L14.4733 7.89206L12.4584 9.20831L10.4271 7.91375L0.541693 1.625L0.0975097 1.52212C0.379701 0.616535 1.21815 -0.000253828 2.16669 7.83626e-08H22.2084C24.375 7.83626e-08 24.9167 1.08332 24.9167 1.08332Z"
        fill="#FED049"
      />
    </SvgIcon>
  );
};

export default EmailNotificationSvg;
