import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Tooltip,
  Box
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Flex from '../../../components/base/Flex';
import { isValidUrl } from '../utils';
import { useFocusedState } from '../../../hooks/useFocusedState';
import DeleteSvg from '../../../components/svg/DeleteSvg';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const QueryParamsTable = ({ queryParams, url, setQueryParams, setUrl }) => {
  const rowStyle = {
    height: '9px',
    outline: 'none',
    fontSize: '12px',
    marginTop: '4px'
  };
  const { handleVariableFloatingWindow } = useFocusedState();
  const updateUrlWithParams = (_queryParams) => {
    if (!isValidUrl(url)) {
      return;
    }
    if (!_queryParams) {
      _queryParams = queryParams;
    }
    const _params = _queryParams?.filter(
      (param) => (param.key || param.value) && param.src !== 'authorization'
    );
    const searchParams = new URLSearchParams();
    _params.forEach((param) => {
      searchParams.append(param.key, param.value);
    });
    const updateUrl = new URL(url);
    // updateUrl.search = searchParams.toString();
    updateUrl.search = decodeURIComponent(searchParams.toString());
    setUrl(decodeURIComponent(updateUrl.href));
  };
  const handleInputChange = (e, index, field) => {
    const updatedQueryParams = [...queryParams];
    updatedQueryParams[index][field] = e.target.value;
    // Add a new row if both key and value are entered in the last row
    const lastRow = updatedQueryParams[updatedQueryParams.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      updatedQueryParams.push({ key: '', value: '', description: '' });
    }
    setQueryParams(updatedQueryParams);
    updateUrlWithParams(updatedQueryParams);
  };
  const handleRowDelete = (index) => {
    const updatedParams = [...queryParams];
    if (updatedParams.length > 1) {
      updatedParams.splice(index, 1);
    } else if (updatedParams.length === 1) {
      updatedParams[index] = { key: '', value: '', description: '' };
    }
    const lastRow = updatedParams[updatedParams.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      updatedParams.push({ key: '', value: '', description: '' });
    }
    setQueryParams(updatedParams);
    updateUrlWithParams(updatedParams);
  };

  return (
    <Table sx={{ width: '100%' }} size="small" aria-label={'a Query Param Table'}>
      <TableHead>
        <TableRow>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Description</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {queryParams.map((param, index) => (
          <TableRow key={index}>
            <StyledTableCell
              sx={{
                textAlign: 'center'
                // width: '50px'
              }}>
              {param?.src === 'authorization' ? (
                <InformationIcon
                  toolTipText={
                    'This field is added automatically, modifications can only be made from the authorization tab'
                  }
                />
              ) : (
                ''
              )}
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'key'}
                value={param?.key}
                onChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleInputChange(e, index, 'key');
                  });
                  handleInputChange(e, index, 'key');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  readOnly: param?.src === 'authorization',
                  style: { ...rowStyle, opacity: param?.src === 'authorization' ? 0.6 : 1 }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'value'}
                value={param.value}
                onChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleInputChange(e, index, 'value');
                  });
                  handleInputChange(e, index, 'value');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  readOnly: param?.src === 'authorization',
                  style: { ...rowStyle, opacity: param?.src === 'authorization' ? 0.6 : 1 }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  fullWidth
                  placeholder={'description'}
                  value={param.description}
                  onChange={(e) => handleInputChange(e, index, 'description')}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    readOnly: param?.src === 'authorization',
                    style: { ...rowStyle, opacity: param?.src === 'authorization' ? 0.6 : 1 }
                  }}
                />
                {param.src !== 'authorization' && (!!param.key || !!param.value) && (
                  <Tooltip title={'Delete'}>
                    <Box
                      sx={{ cursor: 'pointer', flex: '2%' }}
                      onClick={() => {
                        handleRowDelete(index);
                      }}>
                      <DeleteSvg width={16} height={18} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const InformationIcon = ({ toolTipText }) => {
  return (
    <Tooltip title={toolTipText} arrow>
      {/* <IconButton> */}
      <InfoIcon color="primary" />
      {/* </IconButton> */}
    </Tooltip>
  );
};

export default QueryParamsTable;
