import { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Tooltip,
  Divider,
  Skeleton,
  IconButton,
  Pagination,
  Checkbox
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import Flex from '../../../components/base/Flex';
import FolderActionMenu from './FolderActionMenu';
import TestActionMenu from './TestActionMenu';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import EmptyState from '../../../components/base/EmptyState';
import ErrorState from '../../../components/base/ErrorState';
import { TestCaseContext } from '../../../store/testState';
import { useTheme } from '@emotion/react';
import React from 'react';
import { DndContext, useSensors } from '@dnd-kit/core';
import { useDroppable } from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { MouseSensor, useSensor } from '@dnd-kit/core';
import CustomModal from '../../../components/base/CustomModal';
import MoveToFolderSuggestion from './MoveToFolder';

export const colorMap = {
  get: '#53BB63',
  post: '#FFB100',
  put: '#00CCFF',
  patch: '#E39FF6',
  delete: '#FF775E',
  head: '#53BB63',
  options: '#B03060'
};
const Folder = ({
  data,
  isDeletingFolder,
  requestExampleDeleteConfirm,
  requestFolderDeleteConfirm,
  requestSaveChangesDialog,
  requestCreateFolderModal,
  requestTestDeleteConfirm,
  requestConfirmDuplicate,
  refetchFolders,
  resetInitialState,
  isSavingTest,
  isDuplicating,
  handleSaveTest,
  requestRenameFolderModal,
  requestRenameTestModal
}) => {
  const theme = useTheme();
  let activeId;
  const { isOver, setNodeRef, over } = useDroppable({
    id: data.id,
    data: {
      name: data.name
    }
  });
  const { isDraggingId } = useContext(TestCaseContext);
  const { folderId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const folderRef = useRef(null);
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const activeProject = useActiveProject();
  const navigate = useNavigate();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box ref={setNodeRef}>
      <Box
        sx={{
          borderBottom: '0.05px solid #ccc',
          borderTop: '0.05px solid #ccc',
          borderRight: '0.05px solid #ccc',
          marginBottom: '2px',
          position: 'relative'
        }}>
        <Box
          onMouseEnter={() => setHoveredFolder(data.id)}
          onMouseLeave={() => setHoveredFolder(null)}
          sx={{
            bgcolor:
              data.id === +folderId ? 'rgba(36, 195, 217, 0.15)' : 'rgba(36, 195, 217, 0.05)',
            py: '10px',
            pl: '8px',
            pr: '4px',
            borderLeft: data.id === +folderId ? '3px solid #1958b8' : '',
            cursor: 'pointer',
            userSelect: 'none',
            transition: 'background-color 0.3s ease',
            '&: hover': {
              background: 'rgba(36, 195, 217, 0.2)'
            }
          }}
          onClick={(e) => e.currentTarget.contains(e.target) && handleClick()}>
          <Flex justifyContent={'space-between'} sx={{ maxHeight: 'max-content', minHeight: 0 }}>
            <Tooltip title={data.name}>
              <Typography
                variant={'body2'}
                fontWeight={400}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  textOverflow: 'ellipsis'
                }}>
                {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                <FolderIcon sx={{ color: '#24C3D9', ml: 1, mr: '3px' }} /> {data.name}
              </Typography>
            </Tooltip>
            {!!isOver && isDraggingId !== data.id && (
              <Typography
                sx={{
                  position: 'absolute',
                  right: 50,
                  px: 2,
                  py: 0.8,
                  borderRadius: 2,
                  background: theme.palette.background.light_dark,
                  top: -10,
                  zIndex: 2
                }}>
                Move into {data.name}
              </Typography>
            )}
            {hoveredFolder === data.id && (
              <FolderActionMenu
                data={data}
                onAddFolder={requestCreateFolderModal}
                ref={folderRef}
                isLoading={isDeletingFolder}
                onDelete={requestFolderDeleteConfirm}
                onRename={requestRenameFolderModal}
                folderId={data.id}
                projectId={activeProject?.id}
              />
            )}
          </Flex>
        </Box>
      </Box>
      {isOpen &&
        data.subdirectories?.map((subfolder) => (
          <DraggableSubfolder
            isDuplicating={isDuplicating}
            isSavingTest={isSavingTest}
            handleSaveTest={handleSaveTest}
            requestFolderDeleteConfirm={requestFolderDeleteConfirm}
            requestRenameTestModal={requestRenameTestModal}
            requestRenameFolderModal={requestRenameFolderModal}
            requestCreateFolderModal={requestCreateFolderModal}
            key={subfolder.id}
            data={subfolder}
            refetchFolders={refetchFolders}
            resetInitialState={resetInitialState}
          />
        ))}
      {isOpen &&
        data.api_tests?.map((test, index) => (
          <TestRequest
            requestExampleDeleteConfirm={requestExampleDeleteConfirm}
            requestSaveChangesDialog={requestSaveChangesDialog}
            isDuplicating={isDuplicating}
            requestTestDeleteConfirm={requestTestDeleteConfirm}
            requestConfirmDuplicate={requestConfirmDuplicate}
            requestRenameTestModal={requestRenameTestModal}
            isSavingTest={isSavingTest}
            handleSaveTest={handleSaveTest}
            key={index}
            data={data.api_tests}
            index={index}
            test={test}
            refetchTests={refetchFolders}
            resetInitialState={resetInitialState}
            folderId={data.id}
          />
        ))}
      {isOpen && !data.api_tests && data.subdirectories?.length === 0 && (
        <Typography textAlign={'center'} variant={'body2'} py={1.5}>
          This folder is empty
        </Typography>
      )}
    </Box>
  );
};
const DraggableSubfolder = ({
  data,
  isSavingTest,
  isDuplicating,
  requestFolderDeleteConfirm,
  requestCreateFolderModal,
  requestRenameTestModal,
  handleSaveTest,
  refetchFolders,
  resetInitialState,
  requestRenameFolderModal
}) => {
  const {
    attributes,
    listeners,
    setNodeRef: draggableRef,
    transform
  } = useDraggable({
    id: data.id,
    data: {
      name: data.name,
      case: 'subfolder',
      parentId: data.parent_id
    }
  });
  const style = {
    transform: CSS.Translate.toString(transform)
  };
  return (
    <Box
      key={data.id}
      pl={1.5}
      sx={{ bgcolor: 'rgba(36, 195, 217, 0.05)', ...style }}
      {...attributes}
      {...listeners}
      ref={draggableRef}>
      <Folder
        data={data}
        isDuplicating={isDuplicating}
        isSavingTest={isSavingTest}
        requestFolderDeleteConfirm={requestFolderDeleteConfirm}
        requestCreateFolderModal={requestCreateFolderModal}
        handleSaveTest={handleSaveTest}
        refetchFolders={refetchFolders}
        resetInitialState={resetInitialState}
        requestRenameTestModal={requestRenameTestModal}
        requestRenameFolderModal={requestRenameFolderModal}
      />
    </Box>
  );
};

const TestRequest = ({
  isDuplicating,
  requestTestDeleteConfirm,
  requestExampleDeleteConfirm,
  requestSaveChangesDialog,
  requestConfirmDuplicate,
  requestRenameTestModal,
  test,
  index,
  data,
  refetchTests,
  resetInitialState,
  handleSaveTest,
  isSavingTest,
  folderId
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: test.id,
    data: {
      name: test?.name,
      case: 'test',
      folderId: test?.folder_id
    }
  });
  const style = {
    transform: CSS.Translate.toString(transform)
  };
  const { massSelection, setMassSelection, isTestChanges, setClickedTest } =
    useContext(TestCaseContext);
  const { testId, exampleId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredTestCase, setHoveredTestCase] = useState(null);
  const testRef = useRef(null);
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  // const { requestConfirm: requestSaveChangesDialog, ConfirmationDialog: SaveChangesDialog } =
  //   useConfirmDialog();
  const activeProject = useActiveProject();
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const { mutateAsync: deleteTest, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${test.id}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTests();

        // Check if the deleted testId matches the testId from the URL, if it does, navigate to the api base url
        if (testId === test.id) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  const handleDeleteTest = async () => {
    if (await requestConfirm()) return await deleteTest();
  };
  const addNewPathSegment = () => {
    if (testId !== test.id) {
      resetInitialState();
    }
    // Use the navigate function to update the URL
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${test.id}/edit` : `${test.id}/edit`
      }`
    );
  };

  return (
    <Box ref={setNodeRef} sx={{ ...style }} {...attributes} {...listeners}>
      <Box
        pr={'4px'}
        py={'2px'}
        pl={folderId ? '14px' : '4px'}
        sx={{
          bgcolor:
            testId === test.id && !exampleId && !massSelection.active
              ? 'rgba(220,220,220, 0.3)'
              : 'transparent',
          borderLeft:
            testId === test.id && !exampleId && !massSelection.active ? '2px solid #1958B8' : '',
          '&: hover': {
            backgroundColor: theme.palette.hover.default
          }
        }}
        onClick={(e) => {
          if (!massSelection.active) {
            setClickedTest(test);
            if (!!isTestChanges && testId !== test.id) {
              requestSaveChangesDialog();
              return;
            }
            e.currentTarget.contains(e.target) && addNewPathSegment();
            return;
          }
        }}
        onMouseEnter={() => setHoveredTestCase(test.id)}
        onMouseLeave={() => setHoveredTestCase(null)}>
        <Flex
          justifyContent={'space-between'}
          sx={{
            cursor: 'pointer'
          }}>
          <Flex
            columnGap={0}
            sx={{
              width: '85%'
            }}>
            <IconButton
              size={'small'}
              onClick={handleClick}
              sx={{
                visibility: test.examples?.length ? 'visible' : 'hidden'
              }}>
              {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>

            {!!massSelection.active && (
              <IconButton
                size={'small'}
                sx={{
                  p: 0,
                  m: 0
                }}>
                <Checkbox
                  checked={!!massSelection.selectedApiTests.includes(test.id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMassSelection((prev) => {
                      let testIDs = [...prev.selectedApiTests];
                      if (testIDs.includes(test.id)) {
                        testIDs = testIDs.filter((id) => id !== test.id);
                      } else {
                        testIDs = testIDs.concat(test.id);
                      }
                      return { ...prev, selectedApiTests: testIDs };
                    });
                  }}
                />
              </IconButton>
            )}

            <Tooltip title={test.name}>
              <Flex
                variant={'body2'}
                sx={{
                  width: 'inherit',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                <Typography
                  component={'span'}
                  variant={'body2'}
                  textTransform={'uppercase'}
                  fontWeight={600}
                  sx={{
                    color: colorMap[test?.request_method?.toLowerCase()] || 'black'
                  }}>
                  {test.request_method}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'span'}
                  ml={1}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}>
                  {test.name}
                </Typography>
              </Flex>
            </Tooltip>
          </Flex>
          {hoveredTestCase === test.id && (
            <TestActionMenu
              test={test}
              onDelete={requestTestDeleteConfirm}
              ref={testRef}
              isLoading={isDeleting || isDuplicating}
              onEdit={addNewPathSegment}
              onRename={requestRenameTestModal}
              onDuplicate={requestConfirmDuplicate}
              // onDuplicate={handleDuplicateTest}
              _folderId={folderId}
            />
          )}
        </Flex>
        {index + 1 < data.length && <Divider />}
      </Box>

      {isOpen &&
        test.examples?.map((example, index) => (
          <TestExample
            requestExampleDeleteConfirm={requestExampleDeleteConfirm}
            key={example.uuid}
            index={index}
            test={example}
            data={test}
            testId={test.id}
            refetchTests={refetchTests}
            resetInitialState={resetInitialState}
            folderId={folderId}
          />
        ))}
      {/* <ConfirmationDialog
        title={'Are you sure you want to delete this test request?'}
        description={'When you delete a test request, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      /> */}
      {/* <ConfirmationDuplicateDialog
        title={'Are you sure you want to duplicate this test request?'}
        description={
          'By duplicating this test request, a new request will be created. The new test request will be identical to the original, and you can edit it as needed.'
        }
        confirmLabel={'Duplicate'}
      /> */}
      {/* <RenameModal
        open={openRenameTestModal}
        onClose={onCloseRenameTestModal}
        onComplete={completeRenameTestModal}
        test={test}
        refetch={refetchTests}
      /> */}
      {/* <SaveChangesDialog
        isSavingTest={isSavingTest}
        useCase={'save-changes'}
        handleSaveTest={handleSaveTest}
        runRequest={addNewPathSegment}
        title={'Saved Changes'}
        description={'You have unsaved changes, are you sure you want to leave?'}
        confirmLabel={['Back', 'Proceed, do not save', 'Save, then proceed']}
      /> */}
    </Box>
  );
};

const TestExample = ({
  test,
  index,
  data,
  refetchTests,
  testId,
  resetInitialState,
  folderId,
  requestExampleDeleteConfirm
}) => {
  const navigate = useNavigate();
  const { exampleId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { massSelection, setMassSelection, setClickedExample } = useContext(TestCaseContext);
  const [hoveredTestCase, setHoveredTestCase] = useState(null);
  const testRef = useRef(null);
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const activeProject = useActiveProject();

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const { mutateAsync: deleteTest, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${testId}/examples/${test.uuid}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTests();
        if (exampleId === test.uuid) {
          resetInitialState();
          navigate(`/projects/${activeProject?.id}/api-suites/new`);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteTest = async () => {
    if (await requestConfirm()) return await deleteTest();
  };

  const addNewPathSegment = () => {
    if (exampleId !== test.uuid) {
      resetInitialState();
    }

    // Use the navigate function to update the URL
    navigate(
      `/projects/${activeProject?.id}/api-suites/${
        folderId ? `${folderId}/${testId}/example/${test.uuid}` : `${testId}/example/${test.uuid}`
      }`
    );
  };

  return (
    <Box
      pr={'4px'}
      pl={'44px'}
      sx={{
        bgcolor: exampleId === test.uuid ? 'rgba(220,220,220, 0.5)' : 'transparent',
        borderLeft: exampleId === test.uuid ? '2px solid #1958B8' : '',
        borderBottom: '0.05px solid #ddd',
        '&: hover': {
          backgroundColor: 'rgba(220,220,220, 0.1)'
        }
      }}
      onClick={(e) => {
        if (!massSelection.active) {
          setClickedExample(test);
          e.currentTarget.contains(e.target) && addNewPathSegment();
          return;
        }
      }}
      onMouseEnter={() => setHoveredTestCase(test.uuid)}
      onMouseLeave={() => setHoveredTestCase(null)}>
      <Flex
        justifyContent={'space-between'}
        sx={{
          cursor: 'pointer',
          py: '2px'
        }}>
        <Flex columnGap={0}>
          {/* <IconButton size={'small'} onClick={handleClick} sx={{ visibility: 'visible' }}>
            {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton> */}
          {!!massSelection.active && (
            <IconButton
              size={'small'}
              sx={{
                p: 0,
                m: 0
              }}>
              <Checkbox
                checked={!!massSelection.selectedApiTestExamples.includes(test.uuid)}
                onClick={(e) => {
                  e.stopPropagation();
                  setMassSelection((prev) => {
                    let testIDs = [...prev.selectedApiTestExamples];
                    if (testIDs.includes(test.uuid)) {
                      testIDs = testIDs.filter((uuid) => uuid !== test.uuid);
                    } else {
                      testIDs = testIDs.concat(test.uuid);
                    }
                    return { ...prev, selectedApiTestExamples: testIDs };
                  });
                }}
              />
            </IconButton>
          )}
          <Tooltip title={test.name}>
            <Flex
              variant={'body2'}
              columnGap={0}
              sx={{
                width: 'inherit',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                pl: exampleId === test.uuid ? '5px' : '5px'
              }}>
              <Typography
                component={'span'}
                variant={'body2'}
                sx={{
                  border: '2px solid black',
                  fontSize: '6px',
                  px: '2.2px',
                  py: '1px',
                  color: 'black',
                  fontWeight: 'bold'
                }}>
                e.g.
              </Typography>
              <Typography
                variant={'body2'}
                component={'span'}
                ml={'6px'}
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  alignSelf: 'stretch'
                }}>
                {test.name}
              </Typography>
            </Flex>
          </Tooltip>
        </Flex>
        {hoveredTestCase === test.uuid && (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <TestActionMenu
              test={test}
              useCase={'test-example'}
              onDelete={requestExampleDeleteConfirm}
              ref={testRef}
              isLoading={isDeleting}
              exampleId={test.uuid}
              onEdit={addNewPathSegment}
            />
          </Box>
        )}
      </Flex>
      {index + 1 < data.length && <Divider />}
      <ConfirmationDialog
        title={'Are you sure you want to delete this request example?'}
        description={'When you delete a request example, the action cannot be undone.'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </Box>
  );
};

const TestFolders = ({
  isDeletingFolder,
  requestFolderDeleteConfirm,
  requestExampleDeleteConfirm,
  requestSaveChangesDialog,
  requestCreateFolderModal,
  requestTestDeleteConfirm,
  requestConfirmDuplicate,
  requestRenameTestModal,
  requestRenameFolderModal,
  handleSaveTest,
  isDuplicating,
  isSavingTest,
  debounceSearchValue,
  testsPage,
  setTestpage,
  refetchFoldersAndTests,
  isFetchingAllFoldersAndTests,
  error,
  resetInitialState,
  foldersAndTests
}) => {
  const activeProject = useActiveProject();
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 5 pixels before activating, this prevent the draggable listeners to collide with the click listener for test component. the dg context would think you want to drag but you just want to click to fetch the test
    activationConstraint: {
      distance: 5
    }
  });
  const sensors = useSensors(mouseSensor);
  const theme = useTheme();
  const { clickedTest, setIsDraggingId, showDestinationFolder } = useContext(TestCaseContext);
  const { searchValue } = useContext(TestCaseContext);
  const [path, setPath] = useState([]);
  // move
  const { mutateAsync: moveEntityToFolder, isLoading: isMovingFolder } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/move`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );
  const { mutateAsync: moveTestToFolder, isLoading: isMovingTest } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/move`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchFoldersAndTests();
      },
      onError: (error) => {
        toast.error(error.message);
      },
      enabled: !!clickedTest.id
    }
  );
  const handleEntityMove = async (entitiesId, useCase) => {
    try {
      if (useCase === 'folder-folder') {
        await moveEntityToFolder(entitiesId);
      } else {
        await moveTestToFolder(entitiesId);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleDragStart = (event) => {
    setIsDraggingId(event.active.id);
  };
  const handleDragEnd = (event) => {
    setIsDraggingId(null);
    const { active, over } = event;
    if (!over || !active) {
      return;
    }
    let data = {
      folder_id: active.id,
      parent_id: over.id
    };
    if (active.id === over.id) {
      return;
    }
    if (active.data.current.case === 'test') {
      data = {
        api_test_id: active.id,
        folder_id: over.id
      };
      if (active.data.current.folderId === over.id) {
        // console.log('test already exist in destination folder');
        return;
      }
      handleEntityMove(data, 'test-folder');
    }
    if (active.data.current.case === 'subfolder') {
      if (active.data.current.parentId === over.id) {
        // console.log('subfolder already exist in destination folder');
        return;
      }
      handleEntityMove(data, 'folder-folder');
    }
  };
  const handleFolderClick = (folder) => {
    setPath((prevPath) => [...prevPath, folder]);
  };
  const handlePageChange = (e, value) => {
    setTestpage(value.toString());
  };
  const currentFolder = path.length > 0 ? path[path.length - 1] : null;
  const arrayOfNumbers = Array.from({ length: 10 }, (_, index) => index + 1);
  const allEntries = [...foldersAndTests?.folders?.data, ...foldersAndTests?.test_cases?.data];
  const paginate = foldersAndTests.test_cases?.data.meta;
  return (
    <Box
      mt={2}
      sx={{
        position: 'relative',
        pointerEvents: !!isMovingFolder || isMovingTest ? 'none' : ''
      }}>
      <Skeleton
        variant="rectangular"
        sx={{
          display: !!isMovingTest || !!isMovingFolder ? 'block' : 'none',
          width: '100%',
          height: '100%',
          position: 'absolute'
        }}
      />
      <CustomScrollbar
        sx={{
          overflowY: 'scroll',
          minHeight: '580px',
          maxHeight: '580px',
          overflowX: 'clip',
          pr: 1
        }}>
        <DndContext
          // collisionDetection={closestCenter}
          sensors={sensors}
          onDragCancel={() => {}}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}>
          {currentFolder ? (
            <Folder
              requestExampleDeleteConfirm={requestExampleDeleteConfirm}
              requestFolderDeleteConfirm={requestFolderDeleteConfirm}
              requestSaveChangesDialog={requestSaveChangesDialog}
              requestCreateFolderModal={requestCreateFolderModal}
              isDuplicating={isDuplicating}
              requestTestDeleteConfirm={requestTestDeleteConfirm}
              requestConfirmDuplicate={requestConfirmDuplicate}
              requestRenameTestModal={requestRenameTestModal}
              requestRenameFolderModal={requestRenameFolderModal}
              isDeletingFolder={isDeletingFolder}
              handleSaveTest={handleSaveTest}
              isSavingTest={isSavingTest}
              data={currentFolder}
              onClick={handleFolderClick}
              refetchFolders={refetchFoldersAndTests}
              resetInitialState={resetInitialState}
            />
          ) : (
            foldersAndTests.folders?.data?.map((folder) => (
              <Folder
                requestExampleDeleteConfirm={requestExampleDeleteConfirm}
                requestFolderDeleteConfirm={requestFolderDeleteConfirm}
                requestSaveChangesDialog={requestSaveChangesDialog}
                requestCreateFolderModal={requestCreateFolderModal}
                isDuplicating={isDuplicating}
                requestTestDeleteConfirm={requestTestDeleteConfirm}
                requestConfirmDuplicate={requestConfirmDuplicate}
                requestRenameFolderModal={requestRenameFolderModal}
                handleSaveTest={handleSaveTest}
                isDeletingFolder={isDeletingFolder}
                isSavingTest={isSavingTest}
                key={folder.id}
                data={folder}
                onClick={handleFolderClick}
                refetchFolders={refetchFoldersAndTests}
                resetInitialState={resetInitialState}
              />
            ))
          )}
          {foldersAndTests.test_cases?.data?.map((test, index) => (
            <TestRequest
              requestExampleDeleteConfirm={requestExampleDeleteConfirm}
              requestSaveChangesDialog={requestSaveChangesDialog}
              isDuplicating={isDuplicating}
              requestTestDeleteConfirm={requestTestDeleteConfirm}
              requestConfirmDuplicate={requestConfirmDuplicate}
              requestRenameTestModal={requestRenameTestModal}
              isSavingTest={isSavingTest}
              handleSaveTest={handleSaveTest}
              key={index}
              data={foldersAndTests.test_cases.data}
              index={index}
              test={test}
              refetchTests={refetchFoldersAndTests}
              resetInitialState={resetInitialState}
            />
          ))}
          {isFetchingAllFoldersAndTests && (
            <Box>
              {arrayOfNumbers.map((num) => (
                <Skeleton
                  animation={'wave'}
                  key={num}
                  variant={'rectangular'}
                  width={'100%'}
                  height={30}
                  sx={{ mb: 1 }}
                />
              ))}
            </Box>
          )}

          {!isFetchingAllFoldersAndTests && error ? (
            <ErrorState error={error} />
          ) : (
            !isFetchingAllFoldersAndTests &&
            !error &&
            !allEntries?.length &&
            (!!debounceSearchValue ? (
              <EmptyState
                width={'50px'}
                height={'50px'}
                title={
                  // !folders?.length && !tests?.data?.length
                  // ? "It's lonely here at the moment"
                  // :
                  'There are no matching results'
                }
                description={
                  // !folders?.length && !tests?.data?.length
                  // ? `Why not create a few tests or folders?`
                  // :
                  'Try searching for something else,, or create a new test'
                }
              />
            ) : (
              <EmptyState
                width={'50px'}
                height={'50px'}
                title={
                  // !folders?.length && !tests?.data?.length
                  // ?
                  "It's lonely here at the moment"
                  // : 'There are no matching results'
                }
                description={
                  // !folders?.length && !tests?.data?.length
                  // ?
                  `Why not create a few tests or folders?`
                  // : 'Try searching for something else, or create a new test'
                }
              />
            ))
          )}
        </DndContext>
      </CustomScrollbar>
      {paginate?.total > 15 && (
        <Pagination
          count={paginate?.last_page}
          page={+testsPage || 1}
          color="secondary"
          onChange={handlePageChange}
          size={'small'}
          sx={{
            '& .Mui-selected': {
              backgroundColor: '#24C3D9',
              color: '#ffffff'
            },
            '& .MuiPaginationItem-root:not(.Mui-selected)': {
              backgroundColor: '#1958B8',
              color: '#fff'
            },
            my: 3,
            ml: 2
          }}
        />
      )}
      <CustomModal open={showDestinationFolder}>
        <MoveToFolderSuggestion />
      </CustomModal>
    </Box>
  );
};

export default TestFolders;
