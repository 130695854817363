import { SvgIcon } from "@mui/material";

const TestRunsSvg = ({ width = 40, height = 40, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={"0 0 16 18"} {...props}>
      <path d="M7.33184 16.778C7.33184 17.1547 7.02652 17.46 6.64981 17.46H2.72813C1.22379 17.46 0 16.2362 0 14.7319V2.72812C0 1.22379 1.22379 0 2.72813 0H11.1134C12.6176 0 13.8415 1.22379 13.8415 2.72812V9.95766C13.8415 10.3344 13.5361 10.6397 13.1595 10.6397C12.7828 10.6397 12.4774 10.3344 12.4774 9.95766V2.72812C12.4774 1.97603 11.8655 1.36406 11.1134 1.36406H2.72813C1.97603 1.36406 1.36406 1.97603 1.36406 2.72812V14.7319C1.36406 15.484 1.97603 16.0959 2.72813 16.0959H6.64981C7.02652 16.0959 7.33184 16.4013 7.33184 16.778ZM15.3675 11.1082C15.0613 10.8889 14.6353 10.9595 14.416 11.2656L11.0542 15.9615C10.9625 16.068 10.8474 16.0909 10.7858 16.095C10.7221 16.0991 10.5991 16.0911 10.4934 15.9906L8.31705 13.9008C8.04544 13.64 7.61371 13.6486 7.35275 13.9204C7.09179 14.1921 7.10058 14.6238 7.37233 14.8847L9.55084 16.9766C9.8772 17.2871 10.3127 17.46 10.7608 17.46C10.7993 17.46 10.8379 17.4587 10.8765 17.4561C11.3646 17.4236 11.8203 17.1863 12.1268 16.8047C12.1347 16.795 12.1424 16.7849 12.1497 16.7746L15.5251 12.0597C15.7444 11.7534 15.6739 11.3274 15.3675 11.1082ZM10.4313 4.09219H3.40643C3.02971 4.09219 2.7244 4.3975 2.7244 4.77422C2.7244 5.15093 3.02971 5.45625 3.40643 5.45625H10.4313C10.8079 5.45625 11.1134 5.15093 11.1134 4.77422C11.1134 4.3975 10.8079 4.09219 10.4313 4.09219ZM11.1134 7.50234C11.1134 7.12563 10.8079 6.82031 10.4313 6.82031H3.40643C3.02971 6.82031 2.7244 7.12563 2.7244 7.50234C2.7244 7.87906 3.02971 8.18437 3.40643 8.18437H10.4313C10.8079 8.18437 11.1134 7.87906 11.1134 7.50234ZM3.40643 9.54844C3.02971 9.54844 2.7244 9.85375 2.7244 10.2305C2.7244 10.6072 3.02971 10.9125 3.40643 10.9125H7.67285C8.04957 10.9125 8.35488 10.6072 8.35488 10.2305C8.35488 9.85375 8.04957 9.54844 7.67285 9.54844H3.40643Z" />
    </SvgIcon>
  );
};

export default TestRunsSvg;
