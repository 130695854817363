import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Typography } from '@mui/material';
import { StyledTableRow } from './StyledTableCell';
import Flex from '../../../components/base/Flex';
import ProjectSvg from '../../../components/svg/ProjectSvg';
import { StatusTag, getTestStatus, StyledTableCell2 as StyledTableCell } from '../../projects/ProjectFolders';
import { formatDate } from '../../../utils/formateDate';

const DraggableRow = React.memo(
  ({
    id,
    index,
    test,
    moveTest,
    findTestIndex,
    onDragEnd,
    handleClick,
    handleUpdateSuite,
    children,
    ...props
  }) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: 'tests',
        item: { id, index },
        end: (item, monitor) => {
          // onDragEnd();
          const didDrop = monitor.didDrop();
          if (didDrop) {
            handleUpdateSuite();
          }
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      }),
      [id, index, onDragEnd]
    );

    const opacity = isDragging ? 0.7 : 1;

    const [, drop] = useDrop(
      () => ({
        accept: 'tests',
        hover(item, monitor) {
          const dragIndex = findTestIndex(item.id);
          const hoverIndex = findTestIndex(id);
          moveTest(dragIndex, hoverIndex);
        }
      }),
      [id, index, moveTest]
    );

    return (
      <StyledTableRow
        onClick={handleClick}
        ref={(node) => drag(drop(node))}
        sx={{
          cursor: 'move',
          opacity,
          width: '100%'
        }}
        {...props}>
        <StyledTableCell component="th" scope="row">
          <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
            <Flex alignItems={'center'} columnGap={2}>
              <ProjectSvg width={24} height={24} color={'primary'} />
              <Flex alignItems="flex-start" sx={{ flexDirection: 'column' }}>
                <Typography
                  fontWeight={500}
                  sx={{
                    lineHeight: 1,
                    mt: '4px',
                    display: 'inline-block',
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}>
                  {test.name}
                </Typography>
                <Typography
                  sx={{
                    lineHeight: 1,
                    mt: '4px',
                    display: 'inline-block',
                    fontSize: '0.85rem',
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}>
                  {test.description || ''}
                </Typography>
              </Flex>
            </Flex>
          </Flex>
        </StyledTableCell>
        <StyledTableCell>
          <Box className={'cell-content-wrapper'}>{test.creator.name}</Box>
        </StyledTableCell>
        <StyledTableCell>
          <Box className={'cell-content-wrapper'}>{formatDate(test.created_at)}</Box>
        </StyledTableCell>
        <StyledTableCell>
          <Flex className={'cell-content-wrapper'} justifyContent={'center'}>
            <StatusTag status={getTestStatus(test.last_run_result)} />
          </Flex>
        </StyledTableCell>
        <StyledTableCell>
          <Box className={'cell-content-wrapper'}>{''}</Box>
        </StyledTableCell>
      </StyledTableRow>
    );
  }
);

export default DraggableRow;
