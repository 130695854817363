import { SvgIcon } from '@mui/material';

const TypeTextSvg = ({ width = 28, height = 28, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 19 20'} {...props} fill={'none'}>
      <path
        d="M0.636929 4.74019C0.936909 4.74019 1.18011 4.49699 1.18011 4.19701V1.68987H3.68724C3.98722 1.68987 4.23042 1.44667 4.23042 1.14669C4.23042 0.846715 3.98722 0.603516 3.68724 0.603516H0.636929C0.336949 0.603516 0.09375 0.846715 0.09375 1.14669V4.19701C0.09375 4.49702 0.336949 4.74019 0.636929 4.74019Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M18.0911 0.603516H15.0408C14.7408 0.603516 14.4976 0.846715 14.4976 1.14669C14.4976 1.44667 14.7408 1.68987 15.0408 1.68987H17.5479V4.19701C17.5479 4.49699 17.7911 4.74019 18.0911 4.74019C18.3911 4.74019 18.6343 4.49699 18.6343 4.19701V1.14669C18.6343 0.846715 18.3911 0.603516 18.0911 0.603516Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M18.0911 15.0074C17.7911 15.0074 17.5479 15.2506 17.5479 15.5505V18.0577H15.0408C14.7408 18.0577 14.4976 18.3009 14.4976 18.6009C14.4976 18.9008 14.7408 19.144 15.0408 19.144H18.0911C18.3911 19.144 18.6343 18.9008 18.6343 18.6009V15.5505C18.6343 15.2505 18.3911 15.0074 18.0911 15.0074Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M3.68724 18.0577H1.18011V15.5505C1.18011 15.2506 0.936909 15.0074 0.636929 15.0074C0.336949 15.0074 0.09375 15.2506 0.09375 15.5505V18.6009C0.09375 18.9008 0.336949 19.144 0.636929 19.144H3.68724C3.98722 19.144 4.23042 18.9008 4.23042 18.6009C4.23042 18.3009 3.98726 18.0577 3.68724 18.0577Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M15.2905 7.53376V4.1034C15.2905 3.9332 15.1525 3.79524 14.9819 3.79524H3.74609C3.57553 3.79524 3.43756 3.9332 3.43756 4.1034V7.53376C3.43756 7.70432 3.57553 7.84228 3.74609 7.84228H5.38432C5.55451 7.84228 5.69248 7.70432 5.69248 7.53376V6.35868C5.69248 6.18812 5.83081 6.05016 6.00101 6.05016H7.92784C8.0984 6.05016 8.23637 6.18812 8.23637 6.35868V14.8373C8.23637 15.0079 8.0984 15.1459 7.92784 15.1459H7.08519C6.91463 15.1459 6.77667 15.2838 6.77667 15.4544V17.0926C6.77667 17.2628 6.91463 17.4008 7.08519 17.4008H11.6428C11.8134 17.4008 11.9514 17.2628 11.9514 17.0926V15.4544C11.9514 15.2838 11.8134 15.1459 11.6428 15.1459H10.8002C10.6296 15.1459 10.4917 15.0079 10.4917 14.8373V6.35868C10.4917 6.18812 10.6296 6.05016 10.8002 6.05016H12.727C12.8972 6.05016 13.0355 6.18812 13.0355 6.35868V7.53376C13.0355 7.70432 13.1735 7.84228 13.3437 7.84228H14.9819C15.1525 7.84228 15.2905 7.70432 15.2905 7.53376Z"
        fill={fill || '#1958B8'}
      />
    </SvgIcon>
  );
};

export default TypeTextSvg;
