import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CustomButton from '../../components/customButton';
import ContainedButton from '../../components/base/ContainedButton';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Logo from '../../components/base/Logo';

function VerifyOtp() {
  useDocumentTitle("Scandium - Verify Your One Time Password")
  let navigate = useNavigate();
  // const [showProgress, setShowProgress] = useState(false);
  const [textFieldValues, setTextFieldValues] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef()
  ]);
  const [cookies] = useCookies(['email']);
  // const [error, setError] = useState('');

  const token = textFieldValues.join('');

  const { mutateAsync, isLoading: showProgress } = useScandiumMutation('/auth/password/verify');

  const handleChange = (e, index) => {
    const newValue = e.target.value;

    setTextFieldValues(textFieldValues.map((value, i) => (i === index ? newValue : value)));
    if (newValue !== '' && index !== 5) {
      inputRefs.current[index + 1].current.focus();
    } else if (newValue === '' && index !== 0) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const content = (
      (e.clipboardData || window.clipboardData).getData('text')?.toUpperCase() || ''
    ).split('');
    setTextFieldValues(textFieldValues.map((value, index) => content[index] || value));
    const nextIndex =
      content.length >= textFieldValues.length ? textFieldValues.length - 1 : content.length;
    inputRefs.current[nextIndex].current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await mutateAsync({ email: cookies.email, token });
      navigate('/change-password');
    } catch (e) {
      toast.error(e.status === 422 ? e.message : 'An error occurred, please try again');
    }
  };

  // const handleResend = () => {};

  return (
    <Box
      sx={{
        mx: { xs: 3.5, sm: 5 }
      }}>
      <Box
        sx={{
          mt: { xs: 1, sm: 1 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Logo />
        <Typography
          sx={{
            cursor: 'pointer',
            textAlign: 'right',
            typography: { sm: 'h6', md: 'h6' }
          }}>
          Password reset
        </Typography>
      </Box>
      <Box
        sx={{
          mx: { xs: 0, sm: 6 },
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box>
          <Typography
            sx={{
              mt: { xs: 20, sm: 20 },
              backgroundColor: 'rgba(36, 195, 217, 0.16)',
              border: '0.09rem solid #24C3D9',
              borderRadius: '0.2rem',
              width: { xs: '22rem', sm: '22rem', md: '25rem', lg: '25rem' },
              height: '5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <IconButton
              aria-label="cancel"
              sx={{
                clear: 'right'
              }}>
              <CheckCircleOutlineOutlinedIcon
                sx={{
                  color: '#000',
                  ml: 2
                }}
                fontSize="large"
              />
            </IconButton>
            <Typography
              id="modal-modal-description"
              variant="caption"
              sx={{
                // mt: 0.8,
                pl: 0.7,
                mr: 2,
                textAlign: 'left'
              }}>
              Please check your inbox. We've sent you an email with an OTP to reset your password.
            </Typography>
          </Typography>
          <Box
            sx={{
              mt: { xs: 6, sm: 4 },
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            {inputRefs.current.map((ref, index) => (
              <TextField
                key={index}
                required
                inputRef={ref}
                onChange={(e) => handleChange(e, index)}
                value={textFieldValues[index]}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
                sx={{
                  width: { xs: 48, sm: 48, md: 52, lg: 52 },
                  height: { xs: 48, sm: 48, md: 52, lg: 52 }
                }}
                inputProps={{
                  style: { textAlign: 'center' },
                  maxLength: 1
                }}
              />
            ))}
          </Box>
          <ContainedButton
            onClick={handleSubmit}
            disableRipple={true}
            fullWidth
            isLoading={showProgress}
            sx={{
              textAlign: 'center',
              py: 1.5,
              mt: { xs: 2, sm: 3 }
            }}>
            Proceed
          </ContainedButton>
          {/*There is no resend functionality*/}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    mt: { xs: 3, sm: 3 },*/}
          {/*    px: { xs: 1, sm: 1 }*/}
          {/*  }}>*/}
          {/*  <Typography*/}
          {/*    component="span"*/}
          {/*    sx={{*/}
          {/*      textAlign: 'right',*/}
          {/*      typography: { sm: 'body2' },*/}
          {/*      color: '#A3A3A3'*/}
          {/*    }}>*/}
          {/*    Didn't receive an email ?{' '}*/}
          {/*  </Typography>*/}
          {/*  <Typography*/}
          {/*    onClick={handleResend}*/}
          {/*    component="span"*/}
          {/*    sx={{*/}
          {/*      cursor: 'pointer',*/}
          {/*      textAlign: 'right',*/}
          {/*      typography: { sm: 'body2' },*/}
          {/*      textDecoration: 'underline'*/}
          {/*    }}>*/}
          {/*    Resend*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Box>
      </Box>
    </Box>
  );
}

export default VerifyOtp;
