import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import { getBaseUrl } from '..';
import ProtocolMenu, { getProtocolFromString } from './ProtocolMenu';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch
} from '@mui/material';
import { useScandiumRemoteMutation } from '../../../data-layer/remote-execution';
import { useParams } from 'react-router-dom';
import UserContext from '../../../store/userState';
import { useTheme } from '@emotion/react';

const RemoteRunsModal = ({
  open,
  onClose,
  value,
  onComplete,
  url,
  setUrl,
  protocol,
  setProtocol,
  browserType
}) => {
  const [browser, setBrowser] = React.useState(browserType || '');
  const [takeScreenshot, setTakeScreenshot] = React.useState(true);
  const { testId, projectId } = useParams();
  const [showSuccessMesssage, setSuccessMesssage] = React.useState(false);
  const { userData } = React.useContext(UserContext);
  const theme = useTheme();

  const { mutateAsync: runTest, isLoading: isRunningTest } = useScandiumRemoteMutation(`/test`, {
    headers: {
      'x-api-token': userData?.user?.api_token || ''
    },
    onSuccess: (data) => {
      onComplete();
      toast.success(
        'Your test run have been successfully completed. Please check the test Runs result page for details'
      );
    },
    onError: (error) => {
      setSuccessMesssage(false);
      toast.error(error.message);
    }
  });

  const handlerRunTestRemotely = async () => {
    const baseUrl = getBaseUrl(url);

    setTimeout(() => {
      setSuccessMesssage(true);
    }, 1500);

    await runTest({
      test_id: testId,
      project_id: projectId,
      browser,
      starting_url: `${protocol}${baseUrl}`,
      screenshot: takeScreenshot
    });
  };

  const handleCloseModal = () => {
    setSuccessMesssage(false);
    onClose();
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'550px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem',
            mb: 3
          }}>
          Run Test Remotely
        </Typography>

        <Box mb={3}>
          <Flex columnGap={0} alignItems={'stretch'} width={'100%'}>
            <ProtocolMenu
              protocol={protocol}
              setProtocol={(protocol) => {
                if (url) {
                  const _protocol = getProtocolFromString(url);
                  const baseUrl = getBaseUrl(url);
                  setUrl(`${_protocol ? protocol : ''}${baseUrl}`);
                }
                setProtocol(protocol);
              }}
            />
            <TextField
              fullWidth
              value={url}
              onChange={(event) => {
                const _protocol = getProtocolFromString(event.target.value);
                if (_protocol) setProtocol(_protocol);
                setUrl(event.target.value);
              }}
              size={'small'}
              label={'Starting url'}
              InputProps={{
                sx: {
                  paddingLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }
              }}
            />
          </Flex>

          <Box mt={2}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Browser</FormLabel>
              <RadioGroup
                aria-labelledby={'demo-controlled-radio-buttons-group'}
                name={'controlled-radio-buttons-group'}
                row
                size={'small'}
                sx={{ color: theme.palette.text.black_grey }}
                value={browser}
                onChange={(e) => setBrowser(e.target.value)}>
                <FormControlLabel value={'chrome'} control={<Radio />} label={'chrome'} />
                <FormControlLabel value={'edge'} control={<Radio />} label={'microsoft edge'} />
                <FormControlLabel value={'firefox'} control={<Radio />} label={'firefox'} />
              </RadioGroup>
            </FormControl>
            <Divider sx={{ my: 1 }} />
          </Box>
        </Box>

        <Box mb={3}>
          <FormControlLabel
            control={
              <Switch
                checked={takeScreenshot}
                onChange={(e) => setTakeScreenshot(e.target.checked)}
              />
            }
            label="Screenshots"
          />
          <Typography variant={'body2'} sx={{ color: theme.palette.text.black_grey }}>
            Enhance your testing with screenshots, but remember: enabling this feature may slightly
            extend the duration of your remote test runs.
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Box>

        {showSuccessMesssage && (
          <Box>
            <Typography variant={'body2'} sx={{ color: 'green' }}>
              We have started running your test in the background, you will be notified once the
              execution is completed. Feel free to close this modal
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
        )}

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={handleCloseModal}>Close</OutlinedButton>
          <ContainedButton
            isLoading={isRunningTest}
            disabled={isRunningTest}
            color={'primary'}
            onClick={handlerRunTestRemotely}
            autoFocus>
            Run
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default RemoteRunsModal;
