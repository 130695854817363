const ExportTestSvg = ({ width = 31, height = 31, fill, ...props }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.01142 6.81646C8.07816 6.88568 8.11476 6.97851 8.11324 7.07464C8.11187 7.17056 8.07266 7.26205 8.00415 7.32919L7.27688 8.03464C7.20756 8.09892 7.11686 8.1352 7.02233 8.13645C6.97368 8.13555 6.9256 8.12568 6.88052 8.10736C6.81479 8.07953 6.75871 8.03296 6.71926 7.97347C6.67982 7.91398 6.65876 7.8442 6.6587 7.77282V7.40919H5.5678C5.47135 7.40919 5.37886 7.37087 5.31067 7.30268C5.24247 7.23449 5.20416 7.14199 5.20416 7.04555C5.20416 6.94911 5.24247 6.85662 5.31067 6.78842C5.37886 6.72023 5.47135 6.68192 5.5678 6.68192H6.6587V6.31828C6.65826 6.24561 6.67961 6.17447 6.71999 6.11404C6.76037 6.05361 6.81794 6.00667 6.88525 5.97928C6.95257 5.95188 7.02655 5.94528 7.09765 5.96034C7.16875 5.97539 7.23371 6.01141 7.28415 6.06374L8.01142 6.81646Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M8.11324 3.04557V4.86375C8.11324 4.96019 8.07492 5.05268 8.00673 5.12087C7.93853 5.18907 7.84604 5.22738 7.7496 5.22738H6.6587C6.08005 5.22738 5.5251 5.45725 5.11593 5.86642C4.70676 6.27558 4.47689 6.83053 4.47689 7.40919C4.47689 7.50563 4.43858 7.59812 4.37039 7.66631C4.30219 7.73451 4.2097 7.77282 4.11326 7.77282H1.18237C0.898827 7.77282 0.626901 7.66018 0.426409 7.45969C0.225917 7.2592 0.113281 6.98727 0.113281 6.70374V1.95467C0.113281 1.66535 0.228215 1.38787 0.432799 1.18329C0.637383 0.978704 0.914859 0.86377 1.20418 0.86377H2.9969C3.17498 0.864095 3.35027 0.90801 3.50747 0.99168C3.66467 1.07535 3.79899 1.19623 3.89871 1.34377L4.20053 1.79467C4.23423 1.84454 4.27978 1.88526 4.33309 1.91318C4.3864 1.94111 4.44581 1.95536 4.50598 1.95467H7.02233C7.31166 1.95467 7.58913 2.06961 7.79372 2.27419C7.9983 2.47877 8.11324 2.75625 8.11324 3.04557Z"
        fill={fill || "#1958B8"}
      />
    </svg>
  );
};

export default ExportTestSvg;
