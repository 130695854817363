import Button from '@mui/material/Button';
import * as React from 'react';
import { CircularProgress, useTheme } from '@mui/material';

const OutlinedButton = ({
  children,
  isLoading,
  sx,
  fullWidth,
  onClick,
  loadingProps,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      variant={'outlined'}
      sx={{
        borderRadius: '6px',
        minWidth: '80px',
        textDecoration: 'none',
        minHeight: '36px',
        width: fullWidth ? '100%' : 'auto',
        textTransform: { xs: 'none' },
        fontWeight: { xs: 500 },
        // borderColor: 'red',
        border: `1px solid ${theme.palette.button.border}`,
        boxShadow: 'none',
        color: theme.palette.button.textOutlined,
        '&:hover': {
          borderColor: theme.palette.button.border,
          boxShadow: 'none',
          ...(sx?.['&:hover'] || {})
        },
        ...sx
      }}
      onClick={isLoading ? undefined : onClick}
      {...props}>
      {isLoading ? <CircularProgress size={24} color={'inherit'} {...loadingProps} /> : children}
    </Button>
  );
};

export default OutlinedButton;
