import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useContext, useEffect, useState } from 'react';
import { Box, Divider, Typography, Stack, Tabs, Tab, Tooltip, Checkbox } from '@mui/material';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { TestAutomationContext } from '../../../../store/testAutomationState';
import EmptyState from '../../../../components/base/EmptyState';
import Flex from '../../../../components/base/Flex';
import ProjectSvg from '../../../../components/svg/ProjectSvg';
import { useTheme } from '@emotion/react';

export const colorMap = {
  get: '#53BB63',
  post: '#FFB100',
  put: '#00CCFF',
  patch: '#E39FF6',
  delete: '#FF775E',
  head: '#53BB63',
  options: '#B03060'
};

export const TestListItemsDraggable = () => {
  const { activeSuite, setActiveSuite } = useContext(TestAutomationContext);
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 5 pixels before activating, this prevent the draggable listeners to collide with the click listener for checkbox component. the dg context would think you want to drag but you just want to click the checkbox.
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    // activationConstraint: {
    //   delay: 250,
    //   tolerance: 5
    // }
  });
  const sensors = useSensors(mouseSensor);
  const onDragEnd = (e) => {
    const { active, over } = e;
    if (active.id === over.id) {
      return;
    }
    setActiveSuite((prev) => {
      const tests = [...prev.test_cases];
      const oldItem = tests.findIndex((testCase) => {
        return testCase.id === active.id;
      });
      const newItem = tests.findIndex((testCase) => {
        return testCase.id === over.id;
      });
      return { ...prev, test_cases: arrayMove(tests, oldItem, newItem) };
    });
  };
  return (
    <Stack gap={1}>
      {activeSuite.test_cases?.length > 0 ? (
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} sensors={sensors}>
          <SortableContext items={activeSuite.test_cases} strategy={verticalListSortingStrategy}>
            {activeSuite.test_cases.map((test, index) => {
              return <TestItem key={test.id} test={test} index={index} />;
            })}
          </SortableContext>
        </DndContext>
      ) : (
        <Box>
          <EmptyState
            title={'There are no tests in this suite'}
            description={`Why not add a few tests to your suite?`}
          />
        </Box>
      )}
    </Stack>
  );
};

const TestItem = ({ test, index }) => {
  const theme = useTheme();
  const flipStatus = (status) => {
    if (status === 'active') {
      return 'inactive';
    }
    return 'active';
  };
  const [isHover, setIsHover] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: test.id });
  const { setActiveSuite } = useContext(TestAutomationContext);
  const toggleTest = (index) => {
    setActiveSuite((prev) => {
      let prevValue = { ...prev };
      let prevTestCase = [...prevValue.test_cases];
      const status = flipStatus(prevTestCase[index].status);
      prevTestCase[index] = { ...prevTestCase[index], status };
      return { ...prevValue, test_cases: prevTestCase };
    });
  };
  const style = {
    borderRadius: 10,
    transition,
    transform: CSS.Transform.toString(transform),
    background: isHover ? theme.palette.hover : theme.palette.background.suiteList,
    cursor: isHover && 'move'
  };
  return (
    <Flex
      // borderBottom={'1px solid green'}
      ref={setNodeRef}
      borderLeft={'blue'}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}>
      <Flex
        sx={{
          alignItems: 'center',
          outline: '',
          '&:hover': {
            background: 'rgba(220,220,220, 0.1)',
            pointer: 'cursor'
          },
          py: 1
        }}>
        <Checkbox
          checked={test.status === 'active' ? true : false}
          onClick={(e) => {
            toggleTest(index);
          }}
        />
        <ProjectSvg fill={'#24C3D9'} color={'info'} width={20} height={25} />
        <Typography
          component={'span'}
          variant={'body2'}
          fontSize={14}
          textTransform={'uppercase'}
          fontWeight={400}
          sx={{
            color: colorMap[test.api_test_case?.request_method.toLowerCase()] || 'black'
          }}>
          {test.api_test_case?.request_method}
        </Typography>
        <Typography
          variant={'body2'}
          component={'span'}
          fontSize={14}
          fontWeight={500}
          sx={{
            width: 'inherit',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}>
          {test?.api_test_case?.name}
        </Typography>
      </Flex>
    </Flex>
  );
};
