import ScandiumLogoSolid from '../svg/ScandiumLogoSolid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Flex from './Flex';
import * as React from 'react';

const Logo = ({ logoWidth = 38, logoHeight = 38, fontSize = '1.7rem' }) => {
  return (
    <Flex styles={{ flexGrow: 1 }}>
      <ScandiumLogoSolid width={logoWidth} height={logoHeight} style={{ marginRight: '6px' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}>
        <Typography
          component="div"
          sx={{
            display: { xs: 'block', sm: 'block' },
            textAlign: 'left',
            fontWeight: 500,
            fontSize
          }}
          lineHeight={0.9}>
          Scandium
        </Typography>
      </Box>
    </Flex>
  );
};

export default Logo;
