import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';

const SaveNewTest = ({
  open,
  onClose,
  onComplete,
  name,
  setName,
  setStepTimeout,
  step_timeout,
  description,
  setDescription,
  onSaveTestCase,
  isSavingTest,
  pre_step_delay,
  setPreStepDelay
}) => {
  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'}>
        <Typography as={'h4'} color="primary" sx={{ fontSize: '1.5rem' }}>
          Save Test
        </Typography>

        <Typography sx={{ mb: 2 }}>
          Please enter a distinct name for your test and provide a short description to help others
          understand its purpose. ALso setting the timeout and delay will override the individual timeout and delay settings for each step.
        </Typography>

        <Box mb={1}>
          <TextField
            onChange={(e) => setName(e.target.value)}
            value={name}
            size={'small'}
            placeholder={'Name your test'}
            fullWidth
          />
        </Box>
        <Box my={1.5}>
          <TextField
            onChange={(e) => setStepTimeout(e.target.value)}
            value={step_timeout}
            size={'small'}
            type={'number'}
            label={'Step Timeout (ms)'}
            placeholder={'15000'}
            fullWidth
          />
        </Box>
        <Box my={1.5}>
          <TextField
            onChange={(e) => setPreStepDelay(e.target.value)}
            value={pre_step_delay}
            size={'small'}
            type={'number'}
            label={'Pre step delay (ms)'}
            placeholder={'1000'}
            fullWidth
          />
        </Box>
        <Box mb={3}>
          <TextField
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            multiline
            placeholder={'Description'}
            rows={2}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            isLoading={isSavingTest}
            disabled={!name}
            onClick={onSaveTestCase}
            color={'primary'}
            sx={{
              '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important'
              }
            }}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default SaveNewTest;
