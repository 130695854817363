import Toolbar from '@mui/material/Toolbar';
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { useContext, useEffect, useState } from 'react';
import { format, startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import {
  CircularProgress,
  Divider,
  Popover,
  styled,
  Typography,
  Chip,
  TextField
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContainedButton from '../base/ContainedButton';
import ProjectContext, { useActiveProject } from '../../store/projectState';
import UserContext from '../../store/userState';
import { useMenu } from '../base/useMenu';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useScandiumMutation } from '../../data-layer/utils';
import CustomScrollbar from '../../pages/TestPage/components/CustomScrollbar';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import Flex from '../base/Flex';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../AppTheme';
import { setMixpanelUser } from '../../mixpanel.constants';
import { InputAdornment } from '@mui/material/node';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const nonActiveClassNameFn = (theme) => {
  return {
    color: theme.palette.text.black_grey,
    textDecoration: 'none',
    borderBottom: '0.2rem solid transparent'
  };
};
const nonActiveClassName = {
  // color: theme.palette.custom.grey,
  textDecoration: 'none',
  borderBottom: '0.2rem solid transparent'
};
const activeClassName = {
  color: '#24C3D9',
  textDecoration: 'none',
  borderBottom: '0.2rem solid',
  '.button': {
    backgroundColor: 'red'
  }
};

const navButtonSx = {
  typography: { sm: 'b7', md: 'body1' },
  textTransform: 'capitalize',
  textAlign: 'center',
  color: 'inherit',
  gap: '8px',
  display: 'flex',
  textDecoration: 'none',
  width: '100%',
  fontWeight: 500
};

const NavItem = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.black_grey,
  textDecoration: 'none'
}));

const NavItemForApi = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.black_grey,
  textDecoration: 'none',
  width: '100%'
  // outline: '1px solid black'
}));

const styledButton = {
  height: '100%',
  boxShadow: 'none',
  borderRadius: '0px',
  border: '1px solid #80F0FF',
  fontWeight: { xs: 600 },
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  color: '#FFF',
  typography: { sm: 'b7', md: 'body1' },
  textTransform: 'capitalize',
  maxWidth: '250px',
  textDecoration: 'none',
  '&:hover': {
    boxShadow: 'none'
  }
};

const LowerToolbar = ({ navItems }) => {
  const [cookies, setCookie] = useCookies([
    'token',
    'email',
    'name',
    'user',
    'local_features',
    'company',
    'active_company',
    'role',
    'designation',
    'memberships',
    'projectType'
  ]);
  const theme = useTheme();
  const { mode } = useContext(ColorContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { fetchUserData: refetchUserData, userData } = useContext(UserContext);
  const { triggerProps, menuProps } = useMenu();
  const { triggerProps: apiTriggerProps, menuProps: apiMenuProps } = useMenu();
  const [searchQuery, setSearchQuery] = useState('');

  const containsAPI = pathname.includes('/api-suites');

  const date_from = format(startOfDay(subDays(new Date(), 6)), 'yyyy-LL-dd');
  const date_to = format(endOfDay(new Date()), 'yyyy-LL-dd');

  const { projects, setActiveProject, isProjectsLoading, fetchProjects } =
    useContext(ProjectContext);

  const activeProject = useActiveProject();

  const { isFeatureEnabled: showAPIMenu } = useFeatureEnabled('api-testing');
  const { isFeatureEnabled: showAPIAutomationMenu } = useFeatureEnabled('api-automation');
  const { isFeatureEnabled: isProjectSettings } = useFeatureEnabled('project-settings');

  // Save active project to the backend on page load if it's not yet saved
  useEffect(() => {
    if (activeProject && activeProject.id !== projects[0].id) setActiveProject(activeProject.id);
  }, [activeProject?.id]);

  // we need to identify and set up the user data here for mixpanel analytics for already sign in users
  useEffect(() => {
    if (userData?.user) {
      setMixpanelUser(userData);
    }
  }, [userData]);

  const projectType = cookies?.projectType || 'web';

  const filteredProject = (projects || []).filter((project) =>
    project.name?.toLowerCase().includes(searchQuery)
  );

  const handleRefetchProjects = () => {
    fetchProjects().then((res) => {
      const _activeProject = res.data[0];

      setCookie('projectType', _activeProject.type, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });

      setActiveProject(_activeProject);
      navigate(`/projects/${_activeProject?.id}/dashboard`);
    });
  };

  const { mutateAsync: switchOrganization, isLoading: isSwitchingOrganization } =
    useScandiumMutation(`/company/switch`, {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: async (data) => {
        handleRefetchProjects();
        toast.success(data.message);
        refetchUserData();
      }
    });

  const handleSwitchOrganization = async (id) => {
    if (cookies?.active_company?.id === id) return;

    await switchOrganization({ company_id: id });
  };

  const isActiveOrganization = (membership) => {
    return cookies?.active_company?.id === membership.company.id;
  };

  const organizationPopover = (
    <Popover
      {...menuProps}
      PaperProps={{
        elevation: 1,
        sx: {
          overflow: 'visible',
          mt: 1,
          boxShadow: 1
          // bgcolor: '#F6F6F6'
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <Flex alignItems={'flex-start'} columnGap={0}>
        <Box
          as={'ul'}
          role={'listbox'}
          sx={{
            px: 1,
            py: 1,
            my: 0,
            // backgroundColor: '#F6F6F6',
            listStyle: 'none',
            width: '250px'
          }}>
          {(userData?.user?.company_memberships || cookies?.memberships || [])?.map(
            (membership, i) => (
              <Box as={'li'} key={i}>
                <Tooltip title={membership.company.name}>
                  <Button
                    variant={'text'}
                    disableElevation
                    sx={{
                      ...navButtonSx,
                      color: isActiveOrganization(membership) ? 'white' : 'black',
                      justifyContent: 'space-between',
                      py: 1,
                      fontWeight: 'medium',
                      bgcolor: isActiveOrganization(membership) ? '#24C3D9' : 'transparent',
                      '&:hover': {
                        color: isActiveOrganization(membership) ? 'white' : 'black'
                      }
                    }}
                    onClick={() => {
                      handleSwitchOrganization(membership.company.id);
                    }}>
                    <Flex>
                      <Box
                        className="profileImageContainer"
                        sx={{
                          width: '26px',
                          height: '26px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <img
                          src={membership?.company?.avatar || '/images/blank-img.png'}
                          alt={'Company logo'}
                        />
                      </Box>

                      <Typography
                        variant={'body2'}
                        color={theme.palette.text.black_white}
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          fontWeight: 500,
                          maxWidth: '20ch'
                        }}>
                        {membership.company.name}
                      </Typography>
                    </Flex>
                    <ArrowForwardIosIcon
                      sx={{
                        color: isActiveOrganization(membership) ? '#FFF' : '#24C3D9',
                        fontSize: 12
                      }}
                    />
                  </Button>
                </Tooltip>
              </Box>
            )
          )}
        </Box>
        <Box
          as={'ul'}
          role={'listbox'}
          sx={{
            px: 1,
            py: 1,
            my: 0,
            backgroundColor: mode === 'dark' ? 'inherit' : 'rgba(210, 244, 249, 1)',
            listStyle: 'none',
            width: '250px'
          }}>
          <Box as={'li'}>
            <NavItem to={'/projects/new-project'} tabIndex={-1}>
              <Button
                sx={{
                  ...navButtonSx,
                  typography: 'body2'
                }}
                onClick={() => {
                  menuProps.onClose();
                }}>
                <Add />
                Add New Project
              </Button>
            </NavItem>
            <Divider sx={{ my: 0.5 }} />
          </Box>

          <Box>
            <TextField
              size={'small'}
              fullWidth
              value={searchQuery}
              placeholder={'Search for a project...'}
              autoFocus
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ '& .MuiInputBase-input': { fontSize: 12, height: 13, padding: 1 } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.svg.primary }} />
                  </InputAdornment>
                )
              }}
            />
          </Box>

          <CustomScrollbar
            sx={{
              overflowY: 'auto',
              maxHeight: '300px',
              overflowX: 'hidden'
            }}>
            {filteredProject?.map((project) => (
              <Box as={'li'} key={project.id}>
                {/* TODO: if we decide to identify active project by link, then use NavLink instead */}
                <Button
                  as={Link}
                  to={`/projects/${project.id}/dashboard`}
                  sx={{
                    ...navButtonSx,
                    justifyContent: 'flex-start'
                  }}
                  onClick={() => {
                    menuProps.onClose();
                  }}>
                  {project.type === 'web' && (
                    <LanguageIcon
                      sx={{
                        fontSize: 18,
                        color: theme.palette.primary.main,
                        mr: 1
                      }}
                    />
                  )}
                  {project.type === 'mobile' && (
                    <PhoneIphoneIcon
                      sx={{
                        fontSize: 18,
                        color: theme.palette.primary.main,
                        mr: 1
                      }}
                    />
                  )}
                  <Tooltip title={project.name}>
                    <Typography
                      variant={'body2'}
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontWeight: 500,
                        maxWidth: '20ch'
                      }}>
                      {project.name}
                    </Typography>
                  </Tooltip>
                </Button>
              </Box>
            ))}
          </CustomScrollbar>

          <Box as={'li'} sx={{ mt: 2 }}>
            <NavItem to={'/projects'}>
              <ContainedButton
                fullWidth
                sx={{
                  typography: 'body2'
                }}
                onClick={() => {
                  menuProps.onClose();
                }}>
                View all
              </ContainedButton>
            </NavItem>
          </Box>
        </Box>
      </Flex>
    </Popover>
  );

  const handleCloseAPIMenu = () => {
    const { onClose } = apiMenuProps;
    onClose();
  };

  return (
    <Toolbar
      variant="dense"
      style={{ minHeight: '40px' }}
      sx={{
        paddingX: { xs: '1.5rem', sm: '2rem', md: '4rem' },
        display: { xs: 'none', sm: 'flex' },
        gap: '1rem',
        bgcolor: theme.palette.background.lowerToolBar,
        alignItems: 'stretch'
      }}>
      <Box sx={{ display: 'flex' }}>
        <Button
          variant={'contained'}
          color={'secondary'}
          {...triggerProps}
          sx={{
            ...styledButton,
            border: `2px solid ${mode === 'dark' ? '#000' : '#80F0FF'}`,
            mr: 2
          }}>
          <Box
            component={'div'}
            className="profileImageContainer"
            sx={{
              width: '34px',
              height: '34px',
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img
              src={cookies?.active_company?.avatar || '/images/blank-img.png'}
              alt={'Enterprise avatar'}
              sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Box>

          <Flex columnGap={0} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              variant={'body2'}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '15ch',
                fontWeight: 'medium'
              }}>
              {cookies?.active_company?.name || ''}
            </Typography>
            <Typography
              variant={'caption'}
              fontStyle={'light'}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '15ch'
              }}>
              {activeProject?.name || ''}
            </Typography>
          </Flex>
          {isSwitchingOrganization || isProjectsLoading ? (
            <CircularProgress size={15} color={'inherit'} />
          ) : (
            <ArrowDropDownIcon color={'inherit'} />
          )}
        </Button>
        {organizationPopover}
      </Box>
      {navItems
        .filter((item) => item.name !== 'Reports')
        .map((item) => (
          <NavItem
            style={({ isActive }) => (isActive ? activeClassName : nonActiveClassNameFn(theme))}
            key={item.name + item.path}
            to={{
              pathname: `${activeProject ? `projects/${activeProject?.id}` : ''}${item.path}`,
              search: item.search || ''
            }}>
            <Button
              size="small"
              sx={{
                ...navButtonSx,
                justifyContent: 'center',
                mb: '-2px',
                px: '1rem',
                typography: 'body2'
              }}>
              {item.name}
            </Button>
          </NavItem>
        ))}
      {showAPIMenu && projectType === 'web' && (
        <Flex sx={{ columnGap: 0 }}>
          <NavItem
            style={{ ...(containsAPI ? activeClassName : nonActiveClassName), height: '100%' }}>
            <Button
              // onClick={(e) => {
              //   setAnchorElForApi(e.currentTarget);
              // }}
              {...apiTriggerProps}
              sx={{
                ...navButtonSx,
                justifyContent: 'center',
                mb: '-2px',
                px: '1rem',
                typography: 'body2'
              }}>
              API Tests <ExpandMore ml={1} color={'inherit'} />
            </Button>
          </NavItem>
          <Menu
            id="action-menu"
            elevation={1}
            // anchorEl={anchorElForApi}
            // open={Boolean(anchorElForApi)}
            // onClose={() => {
            //   setAnchorElForApi(null);
            // }}
            {...apiMenuProps}
            PaperProps={{
              style: {
                width: 200,
                paddingLeft: 5,
                paddingRight: 5
              }
            }}>
            <MenuItem onClick={handleCloseAPIMenu}>
              <NavItemForApi to={'/api-suites/new'}>Tests </NavItemForApi>
            </MenuItem>
            <MenuItem onClick={handleCloseAPIMenu} disabled={!showAPIAutomationMenu}>
              <NavItemForApi to={`/projects/${activeProject?.id}/api-suites/automate?page=1`}>
                {' '}
                Suites{' '}
              </NavItemForApi>
              {!showAPIAutomationMenu && (
                <Chip label="Premium" color="primary" sx={{ ml: '15px' }} />
              )}
            </MenuItem>
            <MenuItem onClick={handleCloseAPIMenu} disabled={!showAPIAutomationMenu}>
              <NavItemForApi to={`/projects/${activeProject?.id}/api-suites/api_suite_runs?page=1`}>
                SuitesRuns{' '}
              </NavItemForApi>
              {!showAPIAutomationMenu && (
                <Chip label="Premium" color="primary" sx={{ ml: '15px' }} />
              )}
            </MenuItem>
          </Menu>
        </Flex>
      )}
      <NavItem
        style={({ isActive }) => (isActive ? activeClassName : nonActiveClassName)}
        to={{
          pathname: `${activeProject ? `projects/${activeProject?.id}` : ''}/reports`,
          search: `?date_from=${date_from}&date_to=${date_to}`
        }}>
        <Button
          size="small"
          sx={{
            ...navButtonSx,
            justifyContent: 'center',
            mb: '-2px',
            px: '1rem',
            typography: 'body2'
          }}>
          Reports
        </Button>
      </NavItem>
      {isProjectSettings && (
        <NavItem
          style={({ isActive }) => (isActive ? activeClassName : nonActiveClassName)}
          to={`projects/${activeProject?.id}/editProject`}>
          <Button
            size="small"
            sx={{
              ...navButtonSx,
              justifyContent: 'center',
              px: '1rem',
              typography: 'body2'
            }}>
            Settings
          </Button>
        </NavItem>
      )}
    </Toolbar>
  );
};

export default LowerToolbar;
