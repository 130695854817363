import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import { toast } from 'react-toastify';
import CustomInput from '../../../components/customInput';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useLocation } from 'react-router-dom';
import PageLoader from '../../../components/PageLoader';

const SlackConnectModal = ({
  open,
  onClose,
  value,
  handleUpdate,
  onComplete,
  setSuccessChannels,
  successChannels,
  errorChannels,
  setErrorChannels,
  activeTab,
  isLoading,
}) => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const __code = queryParams.get('code');


  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} minHeight={300}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Connect Your Slack Account
        </Typography>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          {/* <ContainedButton onClick={handleSaveJiraData} color={'primary'} autoFocus>
            {'Add Data'}
          </ContainedButton> */}
        </Flex>
      </Box>

      {isLoading && <PageLoader height={'100px'} />}
    </CustomModal>
  );
};

export default SlackConnectModal;
