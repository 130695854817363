import { Box, Typography } from '@mui/material';
import { StyledTableRow, StyledTableCell2 as StyledTableCell } from './StyledTableCell';
import Flex from '../../../components/base/Flex';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import ScheduleActionMenu from './ScheduleActionMenu';
import { useRef } from 'react';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumMutation } from '../../../data-layer/utils';
import { toast } from 'react-toastify';
import ScheduleModal from '../../../components/ScheduleModal';
import useAwaitModal from '../../../hooks/useAwaitModal';
import { useParams } from 'react-router-dom';

const Schedule = ({ schedule, refetchSuites }) => {
  const { suiteId } = useParams();
  const menuRef = useRef(null);
  const activeProject = useActiveProject();
  const { requestConfirm: confirmDelete, ConfirmationDialog: DeleteScheduleDialog } =
    useConfirmDialog();

  const [
    requestScheduleModal,
    { open: openScheduleModal, onClose: onCloseScheduleModal, onComplete: completeScheduleModal }
  ] = useAwaitModal();

  const { mutateAsync: deleteSchedule, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/schedules`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchSuites();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteSuite = async () => {
    if (await confirmDelete()) return await deleteSchedule({ suite_schedule_id: `${schedule.id}` });
  };

  return (
    <StyledTableRow
      sx={{
        width: '100%'
      }}>
      <StyledTableCell component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <Flex alignItems={'center'} columnGap={2}>
            <ScheduleIcon fontSize="large" sx={{ color: '#24C3D9' }} />
            <Flex alignItems="flex-start" sx={{ flexDirection: 'column' }}>
              <Typography fontWeight={500} sx={{ lineHeight: 1, mt: '4px' }}>
                {schedule?.name || ''}
              </Typography>
              <Typography
                sx={{
                  lineHeight: 1,
                  mt: '4px',
                  display: 'inline-block',
                  fontSize: '0.85rem',
                  maxWidth: '300px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {schedule.description || ''}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          <ScheduleActionMenu
            suiteId={suiteId}
            isLoading={isDeleting}
            onDelete={handleDeleteSuite}
            schedule={schedule}
            onSchedule={() => requestScheduleModal()}
            ref={menuRef}
          />
        </Box>
      </StyledTableCell>
      <DeleteScheduleDialog
        title={'Are you sure you want to delete this test suite schedule?'}
        description={'Suite Schedule will be permanently deleted'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <ScheduleModal
        open={openScheduleModal}
        onClose={onCloseScheduleModal}
        onSuiteCreated={completeScheduleModal}
        suiteId={suiteId}
        scheduleType={'test_suite'}
        refetchSuites={refetchSuites}
        schedule={schedule}
      />
    </StyledTableRow>
  );
};

export default Schedule;
