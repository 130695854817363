import { SvgIcon } from '@mui/material';

const SendSvg = ({ width = 20, height = 20, disabled, fill, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={'0 0 10 10'}
      {...props}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d="M9.11236 0.687732L2.17001 7.6282C2.3201 7.70135 2.48471 7.73979 2.65167 7.74065H3.84064C3.94011 7.74039 4.03553 7.77994 4.10566 7.85048L4.75 8.49446C5.09943 8.84629 5.57469 9.04433 6.07054 9.04471C6.27527 9.04452 6.4786 9.01096 6.67253 8.94539C7.34108 8.7262 7.82736 8.14664 7.92711 7.45016L9.19368 1.43404C9.25415 1.18336 9.22539 0.919468 9.11236 0.687732Z"
        fill={fill || '#24C3D9'}
      />
      <path
        d="M7.84543 0.0722436L1.84393 1.33658C0.818481 1.47746 0.101396 2.42296 0.242276 3.44841C0.297921 3.85337 0.484432 4.22906 0.773395 4.51818L1.41737 5.16216C1.48776 5.23253 1.52727 5.328 1.5272 5.42754V6.61652C1.52806 6.78347 1.56651 6.94811 1.63965 7.09817L8.58086 0.155844C8.35276 0.0437443 8.09286 0.0142084 7.84543 0.0722436Z"
        fill={fill || '#24C3D9'}
      />
    </SvgIcon>
  );
};

export default SendSvg;
