/* global chrome */
import React, { useState, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
import Flex from '../../components/base/Flex';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useActiveProject } from '../../store/projectState';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { StyledTableCell } from './components/StyledTableCell';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import { useFetchTests } from '../../data-layer/project-management';
import DraggableRow from './components/DraggableRow';
import { Edit } from '@mui/icons-material';
import { tabStyle } from '../ApiSuites/NewApi';
import Schedule from './components/Schedule';
import ScheduleModal from '../../components/ScheduleModal';
import useAwaitModal from '../../hooks/useAwaitModal';
import RunSuiteMenu from './components/RunSuiteMenu';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import { useRef } from 'react';
import useMeta from '../../hooks/useMeta';
import { getExtensionId } from '../TestPage';
import { toast } from 'react-toastify';
import InstallExtensionCta from '../TestPage/components/InstallExtensionCta';
import { useGlobalVariables } from '../../store/globalVariables';

const WebTestSuite = () => {
  const activeProject = useActiveProject();
  const { suiteId, folderId, projectId } = useParams();
  const [tests, setTests] = useState([]);
  const navigate = useNavigate();
  const extRef = useRef(getExtensionId());
  const [openModal, setOpenModal] = useState(false);
  const { meta } = useMeta();
  const { globalVariables } = useGlobalVariables()

  const [activeTab, setActiveTab] = useState(0);

  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();

  const [
    requestScheduleModal,
    { open: openScheduleModal, onClose: onCloseScheduleModal, onComplete: completeScheduleModal }
  ] = useAwaitModal();

  const readyOnly = activeProject?.read_only;

  const [, drop] = useDrop(() => ({ accept: 'tests' }));

  const {
    data,
    isLoading: isFetchingSuites,
    error: suitesError,
    refetch: refetchSuites
  } = useScandiumQuery(`/projects/${activeProject?.id}/suites/${suiteId}`, {
    enabled: !!activeProject?.id && !!suiteId,
    select: (data) => data.data
  });

  useDocumentTitle(suiteId ? `Test Suites${data?.name ? `: ${data.name}` : ''}` : 'Test Suites');

  const { mutateAsync: updateSuite } = useScandiumMutation(
    `/projects/${activeProject?.id}/suites/${suiteId}`,
    {
      method: 'PUT',
      enabled: !!activeProject?.id && !!suiteId
    }
  );

  const { isLoading: isTestsLoading, error: testsError } = useFetchTests(
    activeProject?.id,
    folderId,
    {
      enabled: !!activeProject?.id && !!suiteId,
      params: {
        unlimited: 'false',
        suite_id: suiteId || undefined
      },
      onSuccess: (data) => {
        setTests(data.data);
      }
    }
  );

  const isLoading = isFetchingSuites || isTestsLoading || !activeProject;

  const reorderTests = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === dragIndex) return;

      const newTests = tests.map(({ preview, ...test }) => test);
      const draggedTest = newTests[dragIndex];

      newTests.splice(dragIndex, 1);
      newTests.splice(hoverIndex, 0, { ...draggedTest, preview: true });
      setTests(newTests);
    },
    [tests, setTests]
  );

  const handleDragEnd = useCallback(() => {
    const newTests = tests.map(({ preview, ...test }) => test);
    setTests(newTests);
  }, [tests, setTests]);

  const handleUpdateSuite = async () => {
    const _test_ids = tests?.map((test) => test.id);

    await updateSuite({
      name: data.name,
      description: data.description,
      starting_url: data.starting_url,
      execution_type: data.execution_type,
      environment_ids: data.environment_ids,
      test_ids: _test_ids,
      test_delay: 0,
      test_timeout: 0,
      test_retries: 0
    });
  };
  const handleNavigateToTest = (testId) => {
    navigate(`/projects/${projectId}/folders/tests/${testId}/edit`);
  };
  const findTestIndex = useCallback(
    (id) => {
      return tests.findIndex((test) => test.id === id);
    },
    [tests]
  );

  const runSuiteLocally = () => {
    if (!window.chrome) {
      toast.error(
        'The Scandium extension only works on Chrome for now. Other browsers are coming soon'
      );
      return;
    }

    // In case you somehow got here without having the extension.
    extRef.current = getExtensionId();
    if (!extRef.current) {
      setOpenModal(true);
      toast.error(
        'The Scandium extension is yet to be installed on your browser. Please install the extension and try again'
      );
      return;
    }
    if (!data?.id) return;

    chrome.runtime.sendMessage(
      extRef.current,
      {
        cmd: 'playSuite',
        suite: data,
        meta: meta,
        global_variables: globalVariables || [],
      },
      (res) => {
        toast.success(
          'Your test suite runs have been successfully completed. Please check the Suite Runs result page for details'
        );
      }
    );

    toast.success(
      'Your test suite is currently being run Locally. Do not close your browser until the test suite has completed running'
    );
  };

  const handleLocalRun = async () => {
    if (await requestConfirm()) return runSuiteLocally();
  };

  return (
    <Box
      sx={{
        mt: -2
      }}>
      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Flex>
          <Typography fontWeight={'500'} as={'h2'} fontSize={'1.2rem'} color={'primary'}>
            {data?.name || 'Test Suites'}
          </Typography>
          {data?.name && (
            <Typography fontWeight={'400'} as={'span'} fontSize={'1.1rem'} color={'primary'}>
              {` / ${data?.test_ids?.length} Test cases`}
            </Typography>
          )}
        </Flex>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            gap: 1
          }}>
          <RunSuiteMenu suite={data} onLocalRun={handleLocalRun} />
          <OutlinedButton
            startIcon={<DirectionsRunIcon />}
            onClick={() => requestScheduleModal()}
            disabled={!!readyOnly || !data}
            to={'edit'}
            sx={{
              borderRadius: '0.4rem',
              bgcolor: 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 1.5,
              minHeight: '30px'
            }}>
            Schedule Runs
          </OutlinedButton>
          <OutlinedButton
            startIcon={<Edit />}
            component={Link}
            disabled={!!readyOnly}
            to={'edit'}
            sx={{
              borderRadius: '0.4rem',
              bgcolor: 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 1.5,
              minHeight: '30px'
            }}>
            Edit Suite
          </OutlinedButton>
        </Box>
      </Flex>

      <Box sx={{ mt: 4 }}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          indicatorColor={'secondary'}
          sx={{ minHeight: 0, maxHeight: 'max-content' }}
          TabIndicatorProps={{
            sx: { height: 2 }
          }}>
          <Tab
            label={'Tests'}
            disableRipple={true}
            sx={{
              ...tabStyle,
              mr: 1,
              fontSize: 15
            }}
          />
          <Tab
            disabled={!tests?.length}
            label={'Schedules'}
            disableRipple={true}
            sx={{
              ...tabStyle,
              mr: 1,
              fontSize: 15
            }}
          />
        </Tabs>
        {activeTab === 0 && (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 1,
              mt: 2,
              mb: 4
            }}>
            <Table sx={{ width: '100%', minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Created On</StyledTableCell>
                  <StyledTableCell align={'center'}>Last Run</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody ref={drop}>
                {!!suiteId &&
                  tests?.map((test, index) => (
                    <DraggableRow
                      key={test.id}
                      test={test}
                      index={index}
                      id={test.id}
                      moveTest={reorderTests}
                      onDragEnd={handleDragEnd}
                      findTestIndex={findTestIndex}
                      handleUpdateSuite={handleUpdateSuite}
                      handleClick={() => {
                        handleNavigateToTest(test.id);
                      }}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {activeTab === 1 && (
          <Box>
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: 1,
                mt: 2,
                mb: 4
              }}>
              <Table sx={{ width: '100%', minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align={'center'}></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody ref={drop}>
                  {data?.schedules?.map((schedule, index) => (
                    <Schedule schedule={schedule} key={index} refetchSuites={refetchSuites} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {data?.schedules?.length === 0 && (
              <EmptyState
                title={'Time to Schedule a Run!'}
                description={'Get started by creating a schedule for this suite.'}
                cta={
                  <Flex>
                    <OutlinedButton disabled={!!readyOnly} onClick={() => requestScheduleModal()}>
                      Schedule Runs
                    </OutlinedButton>
                  </Flex>
                }
              />
            )}
          </Box>
        )}
      </Box>

      <Box>
        {isLoading && <PageLoader height={'100px'} />}
        {!isLoading &&
          (suitesError || testsError ? (
            <ErrorState error={suitesError || testsError} />
          ) : (
            data?.length === 0 && (
              <EmptyState
                title={
                  !data?.length ? "It's lonely here at the moment" : 'There are no matching results'
                }
                description={
                  !data?.length
                    ? `Why not create a few test suites?`
                    : 'Try searching for something else, or create a new test suites'
                }
                cta={
                  <Flex>
                    <OutlinedButton disabled={!!readyOnly} component={Link} to={'new'}>
                      Create Test Suite
                    </OutlinedButton>
                  </Flex>
                }
              />
            )
          ))}
      </Box>

      <ScheduleModal
        open={openScheduleModal}
        onClose={onCloseScheduleModal}
        onSuiteCreated={completeScheduleModal}
        suiteId={suiteId}
        scheduleType={'test_suite'}
        refetchSuites={refetchSuites}
      />

      <ConfirmationDialog
        title={'Run Suite Locally'}
        description={`You are about to run a suite containing ${tests?.length} test cases in your browser. Keep your browser open while the suite is running.`}
        confirmLabel={'Run'}
      />

      <InstallExtensionCta openModal={openModal} setOpenModal={setOpenModal} />
    </Box>
  );
};

export default WebTestSuite;
