import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import CustomButton from './customButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomModalB from './InviteMemberModal';
import Divider from '@mui/material/Divider';
import PageLoader from './PageLoader';
import ErrorState from './base/ErrorState';
import { useActiveProject } from '../store/projectState';
import { Collaborator } from '../pages/projects/Members';
import { ColorContext } from '../AppTheme';
import { useTheme } from '@emotion/react';
import { useCollaborators } from '../store/membersState';
import { useMenu } from './base/useMenu';

const Collaborators = ({
  members,
  membersError,
  isLoading,
  refetchMembers,
  menuProps
}) => {
  return (
    <Menu
      sx={{
        '& .MuiPaper-root': {
          width: 450,
          px: 2
        },
        '& .MuiMenu-list': {
          py: 3
        },
        '& li:hover': { bgcolor: 'transparent', cursor: 'default' }
      }}
      id="collaborators-menu"
      {...menuProps}
      PaperProps={{
        elevation: 1,
        sx: {
          overflow: 'visible',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      {members?.map((member) => (
        <Collaborator key={member.id} member={member} refetchMembers={refetchMembers} />
      ))}
      {isLoading && <PageLoader height={'100px'} />}
      {!isLoading && membersError && <ErrorState error={membersError} />}
    </Menu>
  );
};

const InviteCollaboratorsCta = () => {
  const { mode } = useContext(ColorContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const activeProject = useActiveProject();
  const { triggerProps, menuProps } = useMenu();

  const { collaborators, isLoadingCollaborators, collaboratorsError, refetchCollaborators } =
    useCollaborators();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const readyOnly = activeProject?.read_only;

  return (
    <Box>
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' }
        }}>
        <CustomButton
          onClick={handleOpen}
          disableRipple={true}
          startIcon={<GroupAddIcon color={'secondary'} />}
          disableFocusRipple={true}
          disabled={!!readyOnly}
          fullWidth
          disableElevation={true}
          label={'Add Members'}
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            typography: { xs: 'caption', sm: 'caption' },
            borderTopLeftRadius: '0.4rem',
            borderBottomLeftRadius: '0.4rem',
            borderTopRightRadius: '0rem',
            borderBottomRightRadius: '0rem',
            color: theme.palette.button.textContained,
            border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
            background: mode === 'dark' ? 'none' : 'primary',
            py: { xs: '0.4rem', sm: '0.4rem' },
            px: 2,
            width: 'max-content'
          }}
        />
        {mode === 'light' && (
          <Divider
            orientation="vertical"
            light={true}
            flexItem
            style={{ background: '#123E7C', opacity: 0.9 }}
          />
        )}
        <CustomButton
          {...triggerProps}
          disableRipple={true}
          label={<ArrowDropDownIcon sx={{ color: '#FFFFFF' }} />}
          disableFocusRipple={true}
          fullWidth
          disableElevation={true}
          sx={{
            borderTopLeftRadius: '0rem',
            borderBottomLeftRadius: '0rem',
            borderTopRightRadius: '0.4rem',
            borderBottomRightRadius: '0.4rem',
            borderRight: 2,
            borderRightColor: '#FFFFFF',
            color: theme.palette.button.textContained,
            border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
            background: mode === 'dark' ? 'none' : 'primary',
            py: { xs: '8rem', sm: '0.4rem' },
            px: '0.2rem',
            maxWidth: '0.3rem'
          }}
        />
      </Box>
      <Collaborators
        isLoading={isLoadingCollaborators}
        members={collaborators}
        membersError={collaboratorsError}
        refetchMembers={refetchCollaborators}
        menuProps={menuProps}
      />
      <CustomModalB open={open} handleClose={handleClose} />
    </Box>
  );
};

export default InviteCollaboratorsCta;
