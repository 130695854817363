import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useScandiumMutation } from '../../../data-layer/utils';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/base/Flex';
import TextButton from '../../../components/base/TextButton';
import CustomInput from '../../../components/customInput';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';

const DuplicateGroup = ({ group, afterDuplicateCreated }) => {
  const [openConfirmDuplicate, setOpenConfirmDuplicate] = useState(false);
  const [title, setTitle] = React.useState(`Copy of ${group.title}`);

  const { testId } = useParams();
  const { mutateAsync: createGroup, isLoading } = useScandiumMutation('/groups/create', {});

  const duplicateGroup = async () => {
    const id = v4();
    const groupData = {
      id,
      title,
      description: group.description,
      steps: group.steps,
      project_id: group.project_id,
      is_reusable: false,
      meta: group.meta
    };

    try {
      const response = await createGroup({
        test_id: testId,
        group_data: groupData
      });
      toast.success('Group replaced with local duplicate');
      afterDuplicateCreated({ ...response.data, type: 'group' }, group.id);
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Box>
      <Flex alignItems={'center'} columnGap={1} mt={2}>
        <TextButton
          isLoading={isLoading}
          disabled={!group.is_reusable}
          sx={{ ml: -1 }}
          onClick={() => {
            setOpenConfirmDuplicate(true);
          }}>
          Replace with duplicate
        </TextButton>
        {!group.is_reusable && (
          <Tooltip title="This is a non-reusable local group. There is no need to duplicate it">
            <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
          </Tooltip>
        )}
      </Flex>
      <Dialog
        open={openConfirmDuplicate}
        onClose={() => setOpenConfirmDuplicate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Duplicate group: {group.title}</DialogTitle>
        <DialogContent>
          <Box minWidth={'300px'} py={2}>
            <Box mb={2}>
              This will create a local, non-reusable copy of the group. The original group will not
              be affected.
            </Box>
            <CustomInput
              onChange={(e) => setTitle(e.target.value)}
              size={'small'}
              label={'Group title'}
              value={title}
              formControlStyle={{
                width: '100%'
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 2 }}>
          <OutlinedButton onClick={() => setOpenConfirmDuplicate(false)}>Cancel</OutlinedButton>
          <ContainedButton
            color={'primary'}
            onClick={async () => {
              await duplicateGroup();
              setOpenConfirmDuplicate(false);
            }}
            autoFocus>
            Proceed
          </ContainedButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default DuplicateGroup;
