import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React, { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { TestCaseContext } from '../../../store/testState';

const CreateFolder = ({ open, onClose, onComplete, folderId, refetchFolders }) => {
  const { clickedFolder } = useContext(TestCaseContext);
  const [name, setName] = React.useState('');
  const activeProject = useActiveProject();

  const { mutateAsync: postNewFolder, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders`,
    {
      enabled: !!activeProject?.id,
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Folder successfully created');
        refetchFolders();
        onComplete();
        setName('');
      }
    }
  );

  const handlePostNewFolder = async (e) => {
    if (!name) {
      toast.error('Folder name is required');
      return;
    }

    await postNewFolder({
      name,
      target_resource: 'api-test',
      parent_id: clickedFolder.id || undefined
    });
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Create folder
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Input a folder name below
        </Typography>

        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            autoFocus
            size={'small'}
            placeholder={'Name your folder'}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!name}
            isLoading={isLoading}
            onClick={handlePostNewFolder}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateFolder;
