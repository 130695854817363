import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const ResponseCookies = ({ responseCookies }) => {
  return (
    <CustomScrollbar sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '250px', mt: 1 }}>
      <Table sx={{ width: '100%', mt: 2 }} size="small" aria-label={'Response Header Table'}>
        <TableHead>
          <TableRow>
            {/* <StyledTableCell>{''}</StyledTableCell> */}
            <StyledTableCell>Key</StyledTableCell>
            <StyledTableCell>Value</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {responseCookies?.map((item, i) => (
            <TableRow key={i}>
              <StyledTableCell sx={{ minWidth: 250 }}>{item.key}</StyledTableCell>
              <StyledTableCell>
                {Array.isArray(item.value) ? item.value[0] : item.value}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CustomScrollbar>
  );
};

export default ResponseCookies;
