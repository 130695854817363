import { SvgIcon } from '@mui/material';

const TrelloSvg = ({ width = 33, height = 33, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} fill="none" viewBox="0 0 23 23" {...props}>
      <path
        d="M20.125 0H2.875C1.28704 0 0 1.28704 0 2.875V20.125C0 21.712 1.28704 23 2.875 23H20.125C21.712 23 23 21.712 23 20.125V2.875C23 1.28704 21.712 0 20.125 0ZM10.005 17.4225C10.005 18.1844 9.38688 18.8025 8.625 18.8025H4.37C3.60812 18.8025 2.99 18.1834 2.99 17.4225V4.37C2.99 3.60812 3.60812 2.99 4.37 2.99H8.625C9.38688 2.99 10.005 3.60812 10.005 4.37V17.4225ZM20.01 11.6725C20.01 12.4334 19.3919 13.0525 18.63 13.0525H14.375C13.6131 13.0525 12.995 12.4334 12.995 11.6725V4.37C12.995 3.60812 13.6141 2.99 14.375 2.99H18.63C19.3919 2.99 20.01 3.60812 20.01 4.37V11.6725Z"
        fill="#0079C0"
      />
    </SvgIcon>
  );
};

export default TrelloSvg;
