import { getQueryFn, getReactQueryKey, useScandiumMutation, useScandiumQuery } from './utils';
import { useCookies } from 'react-cookie';

export const useFetchFolders = (projectId, { folderId, ...options } = {}) => {
  return useScandiumQuery(`/projects/${projectId}/folders/${folderId || ''}`, options);
};

export const useCreateFolder = (projectId, { folderId, editFolder, ...options } = {}) => {
  return useScandiumMutation(`/projects/${projectId}/folders/${editFolder ? folderId : ''}`, options);
};

export const useDeleteFolder = (projectId, { folderId, ...options }) => {
  return useScandiumMutation(`/projects/${projectId}/folders/${folderId}`, {
    enabled: !!folderId && options?.enabled,
    method: 'DELETE',
    ...options
  });
};

export const useFetchTests = (projectId, folderId, options) => {
  return useScandiumQuery(
    `/projects/${projectId}/test-cases?${folderId ? `folder_id=${folderId}` : ''}`,
    options
  );
};

export const useDeleteProject = (options = {}) => {
  const [cookies] = useCookies(['token', 'email', 'name']);
  const mutationKey = getReactQueryKey('/projects/{project_id}');
  const mutationFn = (projectId) =>
    getQueryFn({
      path: `/projects/${projectId}`,
      token: cookies?.token,
      method: 'DELETE'
    })();
  return useScandiumMutation('', {
    mutationFn,
    mutationKey,
    ...options
  });
};
