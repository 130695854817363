import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TextField,
  Box,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import { useFocusedState } from '../../../hooks/useFocusedState';
import Flex from '../../../components/base/Flex';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const CookiesTabPage = ({ cookies, setCookies }) => {
  const { handleVariableFloatingWindow } = useFocusedState();
  const handleDelete = (index) => {
    const cookiesList = [...cookies];
    if (cookiesList.length > 1) {
      cookiesList.splice(index, 1);
    } else if (cookiesList.length === 1) {
      cookiesList[index] = { key: '', value: '', expires_at: '' };
    }
    setCookies(cookiesList);
  };
  const handleInputChange = (e, index, field) => {
    const updatedCookies = [...cookies];
    if (field === 'expires_at' && !!e.target.value) {
      // this changes the expiration to standard format using the seconds value exnterd by the user
      const expireFormat = new Date(Date.now() + e.target.value * 1000).toUTCString();
      updatedCookies[index][field] = expireFormat;
    } else {
      updatedCookies[index][field] = e.target.value;
    }

    // Add a new row if both key and value are entered in the last row
    const lastRow = updatedCookies[updatedCookies.length - 1];
    if (lastRow.key !== '' && lastRow.value !== '') {
      updatedCookies.push({ key: '', value: '', expires_at: '' });
    }

    setCookies(updatedCookies);
  };
  return (
    <Table sx={{ width: '98%' }} size="small" aria-label={'a Query Param Table'}>
      <TableHead>
        <TableRow>
          <StyledTableCell>{''}</StyledTableCell>
          <StyledTableCell>Key</StyledTableCell>
          <StyledTableCell>Value</StyledTableCell>
          <StyledTableCell>Expires In</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cookies.map((param, index) => (
          // <Box
          //   sx={{
          //     // width: 100,/
          //     height: 100
          //   }}>
          <TableRow key={index}>
            <StyledTableCell>{''}</StyledTableCell>
            <StyledTableCell>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'key'}
                value={param.key}
                onClick={(e) => {
                  handleInputChange(e, index, 'key');
                }}
                onChange={(e) => {
                  handleVariableFloatingWindow(e);
                  handleInputChange(e, index, 'key');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  style: {
                    height: '8px',
                    outline: 'none',
                    fontSize: '12px',
                    marginTop: '4px'
                  }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <TextField
                size={'small'}
                fullWidth
                placeholder={'value'}
                value={param.value}
                onClick={(e) => {
                  handleInputChange(e, index, 'value');
                }}
                onChange={(e) => {
                  handleVariableFloatingWindow(e);
                  handleInputChange(e, index, 'value');
                }}
                variant={'standard'}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  style: {
                    height: '8px',
                    outline: 'none',
                    fontSize: '12px',
                    marginTop: '4px'
                  }
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Flex>
                <TextField
                  size={'small'}
                  fullWidth
                  type="number"
                  placeholder={'expiration value in seconds'}
                  value={param.description}
                  onChange={(e) => handleInputChange(e, index, 'expires_at')}
                  variant={'standard'}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                    style: {
                      height: '8px',
                      outline: 'none',
                      fontSize: '12px',
                      marginTop: '4px'
                    }
                  }}
                />
                {(!!param.key || !!param.value) && (
                  <Tooltip title={'Delete'}>
                    <Box
                      sx={{ cursor: 'pointer', flex: '2%' }}
                      onClick={() => {
                        handleDelete(index);
                      }}>
                      <DeleteSvg width={16} height={18} />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CookiesTabPage;
