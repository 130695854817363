const GreenFancyCircle = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 334 334"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="166.945"
        cy="166.945"
        r="146.944"
        stroke="#24C3D9"
        strokeWidth="38.8889"
      />
    </svg>
  );
};

export default GreenFancyCircle;
