import { Box, Switch, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomInput from '../../../components/customInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
// import { createGroup } from '../utils';
import { useScandiumMutation } from '../../../data-layer/utils';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@emotion/react';

/**
 *
 * @param { boolean } open
 * @param { () => void } onClose
 * @param { ({meta: {}, title: any, id: string, steps: any}) => void } onGroupCreated - callback function that will be called when group is created
 * @param selectedSteps
 * @returns {JSX.Element}
 * @constructor
 */
const CreateGroup = ({ open, onClose, onGroupCreated, selectedSteps }) => {
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [isReusable, setReusable] = React.useState(true);
  const { testId, projectId } = useParams();
  const theme = useTheme();

  const { mutateAsync: createGroup, isLoading } = useScandiumMutation('/groups/create', {});

  const handleGroupCreation = async () => {
    const id = v4();
    const groupData = {
      id,
      title,
      description,
      steps: selectedSteps,
      project_id: projectId,
      is_reusable: isReusable,
      meta: {}
    };

    try {
      const response = await createGroup({
        test_id: testId,
        group_data: groupData
      });
      toast.success('Group created successfully');
      onGroupCreated({ ...response.data, type: 'group' });
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          mb={3}
          sx={{
            fontSize: '1.5rem'
          }}>
          Create new group
        </Typography>

        <Box mb={3}>
          <CustomInput
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            size={'small'}
            label={'Title'}
            fullWidth
          />
        </Box>
        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            multiline
            label={'Description'}
            rows={2}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <FormControlLabel
            control={
              <Switch checked={isReusable} onChange={(e) => setReusable(e.target.checked)} />
            }
            label="Is reusable"
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            isLoading={isLoading}
            disabled={!title}
            onClick={handleGroupCreation}
            color={'primary'}
            autoFocus>
            Create Group
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateGroup;
