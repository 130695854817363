import { Box, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import Flex from '../../../components/base/Flex';
import { useMenu } from '../../../components/base/useMenu';
import CustomButton from '../../../components/customButton';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { ColorContext } from '../../../AppTheme';

const StatusFilter = ({
  filterOptions,
  setSearchParams,
  searchParams,
  selectedFilter,
  setSelectedFilter,
  query,
  defaultButtonLabel
}) => {
  const { triggerProps, menuProps } = useMenu();
  const theme = useTheme();
  const { mode } = useContext(ColorContext);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    menuProps.onClose();
    if (filter.value) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        [query]: filter.value
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete(query);
      setSearchParams(newParams);
    }
  };

  const buttonLabel = selectedFilter.value ? selectedFilter.label : defaultButtonLabel;

  return (
    <Box>
      <CustomButton
        disableRipple
        disableFocusRipple
        disableElevation
        {...triggerProps}
        fullWidth
        variant={'outlined'}
        label={
          <Flex>
            <Typography variant={'body2'}>{buttonLabel}</Typography>
            {menuProps.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Flex>
        }
        sx={{
          textAlign: 'center',
          textTransform: 'capitalize',
          borderRadius: '0.4rem',
          color: theme.palette.button.textOutlined,
          border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
          py: { xs: '0.2rem', sm: '0.3rem' },
          px: 2,
          mr: 1,
          width: 'max-content',
          '&: hover': {
            background: 'transparent'
          }
        }}
      />
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        {filterOptions?.map((filter) => (
          <MenuItem
            key={filter.label}
            onClick={() => handleFilterChange(filter)}
            sx={{ color: filter.label === selectedFilter.label ? 'blue' : 'inherit' }}>
            <Flex>
              <DoneIcon
                sx={{ visibility: filter.label === selectedFilter.label ? 'visible' : 'hidden' }}
              />
              {filter.label}
            </Flex>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default StatusFilter;
