import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Flex from '../../../components/base/Flex';
import ScreenshotSvg from '../../../components/svg/ScreenshotSvg';
import RotateSvg from '../../../components/svg/RotateSvg';
import KeyboardToggleSvg from '../../../components/svg/KeyboardToggleSvg';
import ShakeSvg from '../../../components/svg/ShakeSvg';
import TextButton from '../../../components/base/TextButton';
import Biometrics from './widgets/Biometrics';
import jsFileDownload from 'js-file-download';
import OpenUrl from './widgets/OpenUrl';
import TypeText from './widgets/TypeText';
import SelectKey from './widgets/SelectKey';
import { useState } from 'react';
import { getCurrentDate } from '../../SuitesRuns/utils';
import InspectSourceSvg from '../../../components/svg/InspectSourceSvg';
import { toast } from 'react-toastify';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useTheme } from '@emotion/react';
import EmailIcon from '@mui/icons-material/Email';
import { v4 } from 'uuid';
import { useActiveProject } from '../../../store/projectState';

const WidgetsPro = ({
  orientation,
  setOrientation,
  session,
  platform,
  osVersion,
  onInspect,
  setUnsaved,
  setActions,
  selectedElement,
  assertionMode,
  setAssertionMode,
  assertionModeRef,
  toggleAssertionModeRef,
  insertEventsAtRef,
  startRecordingRef
}) => {
  const activeProject = useActiveProject();
  const [direction, setDirection] = useState('right');
  const theme = useTheme();
  const isRecordingMode = startRecordingRef?.current;

  const toggleDirection = async () => {
    setDirection((prevDirection) => (prevDirection === 'left' ? 'right' : 'left'));
    await session.rotate(direction);
  };

  const toggleAssertionMode = async () => {
    setAssertionMode((prevState) => !prevState);
    assertionModeRef.current = !assertionModeRef.current;

    toast.info(
      assertionMode
        ? "You're back to recording mode"
        : 'Your are now in assertion mode, click on an element on the emulator to assert against',
      {
        autoClose: 7000
      }
    );
  };

  const format = 'png';
  const isIOS = platform === 'ios';

  const handleScreenshot = async () => {
    const { data } = await session.screenshot();
    jsFileDownload(data, `${getCurrentDate()} High Resolution Screenshot .${format}`);
  };

  const handleDeviceShake = async () => {
    await session.shake();
  };

  const handleToggleKeyboard = async () => {
    await session.toggleSoftKeyboard();
  };

  const handleOpenScandiumMailBox = async () => {
    const isProduction = window.location.href.includes('app');
    const mailBoxAddress = `https://${
      isProduction ? 'app' : 'playground'
    }.getscandium.com/projects/${activeProject?.id}/mailbox`;

    const data = {
      type: 'openUrl',
      url: mailBoxAddress,
      id: v4(),
      timeout: 15000,
      step_delay: 6000
    };

    try {
      await session.openUrl(mailBoxAddress);

      if (insertEventsAtRef.current !== null) {
        const insertIndex = insertEventsAtRef.current + 1;
        setActions((prevActions) => [
          ...prevActions.slice(0, insertIndex),
          { ...data },
          ...prevActions.slice(insertIndex)
        ]);
        insertEventsAtRef.current = insertIndex;
      } else {
        setActions((prevActions) => [...prevActions, { ...data }]);
      }

      setUnsaved(true);
    } catch (e) {
      console.error('Failed to open mailbox', e);
    }
  };

  return (
    <Flex
      sx={{
        flexDirection: orientation === 'portrait' ? 'column' : 'row',
        py: orientation === 'portrait' ? 2 : 0,
        px: orientation === 'portrait' ? 0 : 2,
        bgcolor: theme.palette.background.reportCard,
        borderRadius: '0.3rem'
      }}>
      <TextButton sx={{ py: 1 }} onClick={onInspect}>
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <InspectSourceSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Inspect
          </Typography>
        </Flex>
      </TextButton>
      {platform === 'android' && +osVersion >= 8 && (
        <Biometrics
          orientation={orientation}
          setUnsaved={setUnsaved}
          setActions={setActions}
          session={session}
          insertEventsAtRef={insertEventsAtRef}
          theme={theme}
          isRecordingMode={isRecordingMode}
        />
      )}
      <TextButton sx={{ py: 1 }} disabled={!session} onClick={handleScreenshot}>
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <ScreenshotSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Screenshot
          </Typography>
        </Flex>
      </TextButton>
      <TextButton sx={{ py: 1 }} onClick={toggleDirection}>
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <RotateSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Rotate
          </Typography>
        </Flex>
      </TextButton>
      <OpenUrl
        orientation={orientation}
        session={session}
        theme={theme}
        isRecordingMode={isRecordingMode}
        setUnsaved={setUnsaved}
        setActions={setActions}
        insertEventsAtRef={insertEventsAtRef}
      />
      <TypeText
        orientation={orientation}
        session={session}
        theme={theme}
        isRecordingMode={isRecordingMode}
      />
      <SelectKey
        orientation={orientation}
        session={session}
        platform={platform}
        theme={theme}
        isRecordingMode={isRecordingMode}
      />
      <Tooltip title={'Click to toggle assertion mode'}>
        <TextButton
          sx={{ py: 1 }}
          onClick={toggleAssertionMode}
          disabled={!session || !isRecordingMode}
          aria-controls="simple-menu"
          aria-haspopup="true">
          <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
            <FindInPageIcon
              color="primary"
              sx={{ color: assertionMode ? 'green' : theme.palette.svg.primary }}
            />
            <Typography
              variant={'body2'}
              color={assertionMode ? 'green' : theme.palette.text.black_grey}
              fontWeight={500}
              fontSize={'0.85rem'}>
              Assertion
            </Typography>
          </Flex>
        </TextButton>
      </Tooltip>
      {isIOS && (
        <>
          <TextButton sx={{ py: 1 }} disabled={!session} onClick={handleToggleKeyboard}>
            <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
              <KeyboardToggleSvg fill={theme.palette.svg.primary} />
              <Typography
                variant={'body2'}
                textAlign={'center'}
                color={theme.palette.text.black_grey}
                fontWeight={500}
                fontSize={'0.85rem'}>
                Keyboard <br />
                Toggle
              </Typography>
            </Flex>
          </TextButton>

          <TextButton sx={{ py: 1 }} disabled={!session} onClick={handleDeviceShake}>
            <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
              <ShakeSvg fill={theme.palette.svg.primary} />
              <Typography
                variant={'body2'}
                color={theme.palette.text.black_grey}
                fontWeight={500}
                fontSize={'0.85rem'}>
                Shake
              </Typography>
            </Flex>
          </TextButton>
        </>
      )}

      <TextButton
        sx={{ py: 1 }}
        disabled={!session || !isRecordingMode}
        onClick={handleOpenScandiumMailBox}>
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <EmailIcon sx={{ color: theme.palette.svg.primary }} />
          <Typography
            variant={'body2'}
            textAlign={'center'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Open <br />
            Mailbox
          </Typography>
        </Flex>
      </TextButton>
    </Flex>
  );
};

export default WidgetsPro;
