const MobileSvg = ({ width = 25, height = 25, fill, ...props }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="25" rx="3" fill={fill || "#24C3D9"} />
      <g clip-path="url(#clip0_3574_4891)">
        <mask
          id="mask0_3574_4891"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="4"
          width="17"
          height="18">
          <path d="M20 4.00098H3V21.001H20V4.00098Z" fill="white" />
          <path
            d="M11.5 18.6436C11.1332 18.6436 10.8359 18.3463 10.8359 17.9795C10.8359 17.6127 11.1332 17.3154 11.5 17.3154C11.8668 17.3154 12.1641 17.6127 12.1641 17.9795C12.1641 18.3463 11.8668 18.6436 11.5 18.6436Z"
            fill="white"
          />
          <path
            d="M13.7342 15.8213C14.0357 15.8213 14.3336 15.6226 14.4153 15.3349L15.9236 10.0291C16.0447 9.60327 15.7219 9.18067 15.2757 9.18067C14.9742 9.18067 14.7095 9.37935 14.6277 9.66702L13.1194 14.9728C12.9984 15.3987 13.288 15.8213 13.7342 15.8213Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3574_4891)">
          <mask
            id="mask1_3574_4891"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="4"
            width="17"
            height="17">
            <path d="M3 4H20V21H3V4Z" fill="white" />
          </mask>
          <g mask="url(#mask1_3574_4891)">
            <path
              d="M16.7129 7.68569V6.65235C16.7129 5.55209 15.821 4.66016 14.7207 4.66016H8.2793C7.17904 4.66016 6.28711 5.55209 6.28711 6.65235V18.3398C6.28711 19.4401 7.17904 20.332 8.2793 20.332H14.7207C15.821 20.332 16.7129 19.4401 16.7129 18.3398V17.0822"
              stroke="white"
              stroke-width="1.32812"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M11.5 18.6436C11.1332 18.6436 10.8359 18.3463 10.8359 17.9795C10.8359 17.6127 11.1332 17.3154 11.5 17.3154C11.8668 17.3154 12.1641 17.6127 12.1641 17.9795C12.1641 18.3463 11.8668 18.6436 11.5 18.6436Z"
              fill="white"
            />
            <path
              d="M10.8359 7.02246H12.1641"
              stroke="white"
              stroke-width="1.32812"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M13.7342 15.8203C14.0357 15.8203 14.3336 15.6216 14.4153 15.334L15.9236 10.0281C16.0447 9.6023 15.7219 9.17969 15.2757 9.17969C14.9742 9.17969 14.7095 9.37838 14.6277 9.66605L13.1194 14.9719C12.9984 15.3977 13.288 15.8203 13.7342 15.8203Z"
              fill="white"
            />
            <path
              d="M11.5 10.4756L9.85558 11.8543C9.43616 12.2105 9.43616 12.8578 9.85558 13.214L11.5 14.5928"
              stroke="white"
              stroke-width="1.32812"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.4102 10.4756L19.0214 11.8543C19.4408 12.2105 19.4408 12.8578 19.0214 13.214L17.4102 14.5928"
              stroke="white"
              stroke-width="1.32812"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3574_4891">
          <rect width="17" height="17" fill="white" transform="translate(3 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MobileSvg;
