import { getQueryFn, getReactQueryKey, useScandiumMutation, useScandiumQuery } from './utils';
import { useCookies } from 'react-cookie';

export const useSaveTestSteps = (options = {}) => {
  const [cookies] = useCookies(['token', 'email', 'name']);

  const mutationKey = getReactQueryKey('/projects/{project_id}/test-cases/{testId}/steps');
  const mutationFn = ({ testId, projectId, folderId, data }) =>
    getQueryFn({
      path: `/projects/${projectId}/test-cases/${testId}/steps${
        folderId ? `?folder_id=${folderId}` : ''
      }`,
      token: cookies?.token,
      method: 'POST'
    })(data);
  return useScandiumMutation('', {
    mutationFn,
    mutationKey,
    ...options
  });
};

export const useDeleteTest = ({ projectId, testId, ...options }) => {
  return useScandiumMutation(`/projects/${projectId}/test-cases/${testId}`, {
    method: 'DELETE',
    ...options
  });
};

export const useFetchTest = ({ projectId, testId, folderId, ...options }) => {
  return useScandiumQuery(
    `/projects/${projectId}/test-cases/${testId}?${folderId ? `folder_id=${folderId}` : ''}`,
    {
      enabled: !!projectId && !!testId,
      ...options
    }
  );
};

export const useSaveTestRun = (options) => {
  const [cookies] = useCookies(['token', 'email', 'name']);

  const mutationKey = getReactQueryKey('/projects/{project_id}/test-cases/{testId}/runs');
  const mutationFn = ({ testId, projectId, folderId, data }) =>
    getQueryFn({
      path: `/projects/${projectId}/test-cases/${testId}/runs${
        folderId ? `?folder_id=${folderId}` : ''
      }`,
      token: cookies?.token,
      method: 'POST'
    })(data);
  return useScandiumMutation('', {
    mutationFn,
    mutationKey,
    ...options
  });
};
