import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContainedButton from '../../components/base/ContainedButton';
import Logo from '../../components/base/Logo';
import Flex from '../../components/base/Flex';
import useUpdateToken from '../../hooks/useUpdateToken';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useRedirect } from '../../store/RedirectContext';
import { identifyMixpanelUser, setMixpanelUser, TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import mixpanel from 'mixpanel-browser';

function Login() {
  useDocumentTitle('Scandium - Login to Your Account');
  let navigate = useNavigate();
  const updateToken = useUpdateToken();
  const [cookies] = useCookies(['token', 'email', 'name']);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const { redirectPath } = useRedirect();

  const {
    mutateAsync: postLogin,
    isLoading: showProgress,
    error
  } = useScandiumMutation('/auth/login', {
    onError: (error) => {
      if (error.status === 401)
        toast.error('Invalid credentials: Please check your login information and try again');
      else toast.error(error.message);

      trackMixPanel(TRACKING_IDS.LOGIN_STATUS, {
        status: false
      });
    },
    onSuccess: (data) => {
      updateToken(data);
      navigate(redirectPath);

      identifyMixpanelUser(data.user?.id);
      setMixpanelUser(data)

      trackMixPanel(TRACKING_IDS.LOGIN_STATUS, {
        status: true
      });
    }
  });

  if (cookies.token) {
    return <Navigate to="/dashboard" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError('Email is required');
    }

    if (!password) {
      setPasswordError('Password is required');
    }

    if (!emailRegex.test(email)) {
      setEmailError('Enter a valid email');
    } else if (!password) {
      setPasswordError('Password is required');
    } else {
      await postLogin({ email, password });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((val) => !val);
  };

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const handleError = () => {
    if (password) {
      setPasswordError(null);
    }

    if (emailRegex.test(email)) {
      setEmailError(null);
    }

    if (email) {
      setEmailError(null);
    }
  };

  // Check if the error message is "Failed to fetch"
  const isOfflineError = error?.message === 'Failed to fetch';

  return (
    <Box>
      <Grid container columnSpacing={2} sx={{ height: '100vh' }}>
        <Grid
          item
          md={5}
          sx={{
            display: { xs: 'none', md: 'block' },
            height: '100%'
          }}>
          <div
            style={{
              overflow: 'clip',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgb(22,85,187)'
            }}>
            <img
              style={{
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
              src="/images/login-cover.png"
              alt="Laptop"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={7} sx={{ overflowY: 'auto', height: '100%', pb: 5 }}>
          <Box
            sx={{
              mx: { xs: 3, sm: 4, md: 5 }
            }}>
            <Flex justifyContent={'space-between'} mt={{ xs: 6, sm: 3 }}>
              <Logo />
              <Typography
                sx={{
                  cursor: 'pointer',
                  typography: { sm: 'h6', md: 'h6' }
                  // backgroundColor: 'red'
                }}
                onClick={() => navigate('/register?account_type=personal')}>
                Create an account
              </Typography>
            </Flex>
            <Box
              as={'form'}
              sx={{
                mx: { xs: 0, sm: 6, md: 8 },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
              onSubmit={handleSubmit}>
              <Typography
                sx={{
                  textAlign: 'left',
                  typography: { sm: 'h6', md: 'h4' },
                  // mt: { xs: 6, sm: 6, md: 5, lg: 15 },
                  mt: { xs: 6, sm: 6, md: 15, lg: 15 }
                }}>
                Log in to Scandium
              </Typography>

              <TextField
                type="email"
                required
                onBlur={handleError}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                value={email}
                error={emailError != null}
                helperText={emailError}
                sx={{
                  mt: { xs: 6, sm: 4.5 }
                }}
                placeholder="example@example.com"
              />
              <TextField
                type={showPassword ? 'text' : 'password'}
                required
                onBlur={handleError}
                onChange={(e) => setPassword(e.target.value)}
                label="Password (8+ Characters)"
                value={password}
                sx={{
                  mt: { xs: 6, sm: 4.5 }
                }}
                error={passwordError != null}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <ContainedButton
                disableRipple={true}
                isLoading={showProgress}
                sx={{
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  py: 1.5,
                  mt: { xs: 4, sm: 4 }
                }}
                type={'submit'}>
                Log In
              </ContainedButton>
              {!!error && (
                <Typography style={{ fontSize: '12px' }} color="error" align="center">
                  {isOfflineError
                    ? "Oops! It seems like you're offline. Please check your internet connection and try again."
                    : error.message}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                mt: { xs: 9, sm: 9, md: 15, lg: 25.5 },
                mx: { xs: 6, sm: 8 },
                display: { xs: 'block', lg: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center'
                // backgroundColor: 'olivedrab'
              }}>
              <Box>
                <Typography
                  component="span"
                  sx={{
                    textAlign: 'right',
                    typography: { sm: 'body2' },
                    pt: { xs: 6, sm: 3 },
                    color: '#A3A3A3'
                  }}>
                  Forgot password?{' '}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    cursor: 'pointer',
                    align: 'right',
                    textDecoration: 'underline',
                    typography: { sm: 'body2' },
                    pt: { xs: 6, sm: 3 }
                  }}
                  onClick={() => navigate('/reset-password')}>
                  Reset Password
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: { xs: '0.5rem', sm: '0.5rem', lg: '0rem' }
                }}>
                <Typography
                  component="span"
                  sx={{
                    textAlign: 'right',
                    typography: { sm: 'body2' },
                    color: '#A3A3A3'
                  }}>
                  Don't have an account?{' '}
                </Typography>
                <Typography
                  component="span"
                  color="primary"
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'right',
                    typography: { sm: 'body2' }
                  }}
                  onClick={() => navigate('/register?account_type=personal')}>
                  Sign up
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
