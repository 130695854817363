import { SvgIcon } from '@mui/material';

const EmailSvg = ({ width = 31, height = 31, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} fill="none" viewBox="0 0 31 31" {...props}>
      <g clip-path="url(#clip0_3287_2447)">
        <path
          d="M1.25684 12.5676C1.16927 12.5676 1.0839 12.5402 1.01273 12.4891C0.941558 12.4381 0.888165 12.3661 0.860057 12.2831C0.831948 12.2002 0.830538 12.1105 0.856024 12.0268C0.881509 11.943 0.93261 11.8693 1.00214 11.8161L6.02917 7.96996C6.1176 7.90487 6.22806 7.87711 6.33676 7.89264C6.44546 7.90818 6.54371 7.96577 6.61037 8.05302C6.67704 8.14028 6.70678 8.25022 6.69319 8.35918C6.67961 8.46814 6.62378 8.56741 6.53774 8.63562L1.51071 12.4813C1.43799 12.5374 1.34869 12.5678 1.25684 12.5676Z"
          fill="#1958B8"
        />
        <path
          d="M29.7433 30.1622H1.25681C1.14571 30.1622 1.03915 30.118 0.960589 30.0395C0.882027 29.9609 0.837891 29.8544 0.837891 29.7432V12.1487C0.837891 12.0376 0.882027 11.931 0.960589 11.8524C1.03915 11.7739 1.14571 11.7297 1.25681 11.7297C1.36791 11.7297 1.47447 11.7739 1.55303 11.8524C1.63159 11.931 1.67573 12.0376 1.67573 12.1487V29.3243H29.3244V12.1487C29.3244 12.0376 29.3685 11.931 29.4471 11.8524C29.5256 11.7739 29.6322 11.7297 29.7433 11.7297C29.8544 11.7297 29.961 11.7739 30.0395 11.8524C30.1181 11.931 30.1622 12.0376 30.1622 12.1487V29.7432C30.1622 29.8544 30.1181 29.9609 30.0395 30.0395C29.961 30.118 29.8544 30.1622 29.7433 30.1622Z"
          fill="#1958B8"
        />
        <path
          d="M15.4999 22.5421C14.028 22.544 12.6014 22.0335 11.4649 21.0981L0.991944 12.4722C0.906171 12.4015 0.851982 12.2997 0.841298 12.189C0.830613 12.0784 0.864308 11.9681 0.934971 11.8823C1.00563 11.7966 1.10747 11.7424 1.21809 11.7317C1.32871 11.721 1.43904 11.7547 1.52481 11.8254L11.9978 20.4513C12.9849 21.262 14.2226 21.7051 15.4999 21.7051C16.7773 21.7051 18.015 21.262 19.0021 20.4513L29.4751 11.8254C29.5609 11.7547 29.6712 11.721 29.7818 11.7317C29.8924 11.7424 29.9943 11.7966 30.0649 11.8823C30.1356 11.9681 30.1693 12.0784 30.1586 12.189C30.1479 12.2997 30.0937 12.4015 30.0079 12.4722L19.535 21.0981C18.3985 22.0335 16.9718 22.544 15.4999 22.5421Z"
          fill="#1958B8"
        />
        <path
          d="M29.7434 30.1621C29.6463 30.162 29.5523 30.1283 29.4774 30.0666L18.7949 21.2693C18.7525 21.2343 18.7173 21.1913 18.6914 21.1427C18.6656 21.0941 18.6496 21.0409 18.6443 20.9862C18.6336 20.8755 18.6673 20.7652 18.7379 20.6794C18.8086 20.5937 18.9105 20.5395 19.0211 20.5288C19.1317 20.5181 19.242 20.5518 19.3278 20.6225L30.0102 29.4198C30.0769 29.4747 30.125 29.5488 30.1479 29.6321C30.1708 29.7153 30.1674 29.8036 30.1382 29.8849C30.109 29.9662 30.0553 30.0364 29.9847 30.086C29.914 30.1356 29.8297 30.1622 29.7434 30.1621Z"
          fill="#1958B8"
        />
        <path
          d="M1.25665 30.1621C1.1703 30.1622 1.08602 30.1356 1.01535 30.086C0.944679 30.0364 0.891068 29.9662 0.861856 29.8849C0.832644 29.8036 0.829258 29.7153 0.852162 29.6321C0.875065 29.5488 0.923139 29.4747 0.989803 29.4198L11.6722 20.6225C11.758 20.5518 11.8683 20.5181 11.979 20.5288C12.0896 20.5395 12.1914 20.5937 12.2621 20.6794C12.3327 20.7652 12.3664 20.8755 12.3557 20.9862C12.3451 21.0968 12.2909 21.1986 12.2051 21.2693L1.52267 30.0666C1.44774 30.1283 1.35371 30.162 1.25665 30.1621Z"
          fill="#1958B8"
        />
        <path
          d="M20.2502 5.30771C20.1583 5.30762 20.069 5.2773 19.996 5.22141L15.5001 1.78418L11.0043 5.22141C10.916 5.2889 10.8045 5.31857 10.6944 5.30388C10.5843 5.28919 10.4845 5.23134 10.417 5.14307C10.3495 5.0548 10.3198 4.94333 10.3345 4.83318C10.3492 4.72304 10.407 4.62324 10.4953 4.55575L15.2458 0.924138C15.3189 0.868355 15.4082 0.838135 15.5001 0.838135C15.592 0.838135 15.6814 0.868355 15.7544 0.924138L20.5049 4.55575C20.5755 4.60867 20.6276 4.68252 20.6537 4.76678C20.6799 4.85103 20.6787 4.94138 20.6505 5.02496C20.6223 5.10853 20.5684 5.18106 20.4965 5.23221C20.4246 5.28336 20.3385 5.31051 20.2502 5.3098V5.30771Z"
          fill="#1958B8"
        />
        <path
          d="M29.7433 12.5676C29.6514 12.5678 29.5621 12.5374 29.4894 12.4813L24.4624 8.63562C24.3763 8.56741 24.3205 8.46814 24.3069 8.35918C24.2933 8.25022 24.3231 8.14028 24.3897 8.05302C24.4564 7.96577 24.5547 7.90818 24.6634 7.89264C24.7721 7.87711 24.8825 7.90487 24.971 7.96996L29.998 11.8161C30.0675 11.8693 30.1186 11.943 30.1441 12.0268C30.1696 12.1105 30.1682 12.2002 30.1401 12.2831C30.112 12.3661 30.0586 12.4381 29.9874 12.4891C29.9162 12.5402 29.8308 12.5676 29.7433 12.5676Z"
          fill="#1958B8"
        />
        <path
          d="M24.7161 16.7066C24.605 16.7066 24.4984 16.6625 24.4199 16.5839C24.3413 16.5053 24.2972 16.3988 24.2972 16.2877V5.30781H6.70258V16.2877C6.70258 16.3988 6.65845 16.5053 6.57989 16.5839C6.50132 16.6625 6.39477 16.7066 6.28367 16.7066C6.17256 16.7066 6.06601 16.6625 5.98744 16.5839C5.90888 16.5053 5.86475 16.3988 5.86475 16.2877V4.88889C5.86475 4.77779 5.90888 4.67123 5.98744 4.59267C6.06601 4.51411 6.17256 4.46997 6.28367 4.46997H24.7161C24.8272 4.46997 24.9338 4.51411 25.0123 4.59267C25.0909 4.67123 25.135 4.77779 25.135 4.88889V16.2877C25.135 16.3988 25.0909 16.5053 25.0123 16.5839C24.9338 16.6625 24.8272 16.7066 24.7161 16.7066Z"
          fill="#1958B8"
        />
        <path
          d="M21.2602 9.21626H9.73996C9.62886 9.21626 9.52231 9.17212 9.44374 9.09356C9.36518 9.015 9.32104 8.90844 9.32104 8.79734C9.32104 8.68623 9.36518 8.57968 9.44374 8.50112C9.52231 8.42255 9.62886 8.37842 9.73996 8.37842H21.2602C21.3713 8.37842 21.4779 8.42255 21.5565 8.50112C21.635 8.57968 21.6792 8.68623 21.6792 8.79734C21.6792 8.90844 21.635 9.015 21.5565 9.09356C21.4779 9.17212 21.3713 9.21626 21.2602 9.21626Z"
          fill="#1958B8"
        />
        <path
          d="M21.2602 12.2534H9.73996C9.62886 12.2534 9.52231 12.2092 9.44374 12.1307C9.36518 12.0521 9.32104 11.9456 9.32104 11.8344C9.32104 11.7233 9.36518 11.6168 9.44374 11.5382C9.52231 11.4597 9.62886 11.4155 9.73996 11.4155H21.2602C21.3713 11.4155 21.4779 11.4597 21.5565 11.5382C21.635 11.6168 21.6792 11.7233 21.6792 11.8344C21.6792 11.9456 21.635 12.0521 21.5565 12.1307C21.4779 12.2092 21.3713 12.2534 21.2602 12.2534Z"
          fill="#1958B8"
        />
        <path
          d="M21.2602 15.2905H9.73996C9.62886 15.2905 9.52231 15.2463 9.44374 15.1678C9.36518 15.0892 9.32104 14.9827 9.32104 14.8716C9.32104 14.7605 9.36518 14.6539 9.44374 14.5753C9.52231 14.4968 9.62886 14.4526 9.73996 14.4526H21.2602C21.3713 14.4526 21.4779 14.4968 21.5565 14.5753C21.635 14.6539 21.6792 14.7605 21.6792 14.8716C21.6792 14.9827 21.635 15.0892 21.5565 15.1678C21.4779 15.2463 21.3713 15.2905 21.2602 15.2905Z"
          fill="#1958B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_3287_2447">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default EmailSvg;
