const GroupSvg = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 139 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M126.735 13.9826H70.2904L59.0478 0.614066C58.7122 0.210693 58.2093 -0.0155236 57.685 0.000829353H12.2647C5.46598 0.0682852 -0.00782738 5.60274 8.40222e-06 12.4018V94.1665C-0.000332284 100.96 5.47143 106.487 12.2647 106.554H126.735C133.529 106.487 139 100.96 139 94.1665V26.3699C139 19.5763 133.529 14.05 126.735 13.9826Z"
        fill="#24C3D9"
      />
    </svg>
  );
};

export default GroupSvg;
