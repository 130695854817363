import React, { useContext } from 'react';
import { Typography, Stack, Divider, Box } from '@mui/material';
import { TestAutomationContext } from '../../../../store/testAutomationState';
import Flex from '../../../../components/base/Flex';
import { useLocation, useNavigate } from 'react-router-dom';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { getDateTime } from '../../../../utils/time';
import EmptyState from '../../../../components/base/EmptyState';
import CustomScrollbar from '../../../TestPage/components/CustomScrollbar';
import { StatusBadge } from '../../components/AssertionResults';
import { useTheme } from '@emotion/react';
const SuiteRunsList = () => {
  const { suiteRuns } = useContext(TestAutomationContext);

  return (
    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '500px', minHeight: '300px', mt: 1 }}>
      <Stack gap={0.5} sx={{ mr: 2 }}>
        {suiteRuns.map((run, index) => {
          return <SuiteRun key={run.id} run={run} index={index} />;
        })}
      </Stack>
    </CustomScrollbar>
  );
};

const SuiteRun = ({ run, index }) => {
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const { suiteRuns } = useContext(TestAutomationContext);
  const activeRunID = new URLSearchParams(search).get('run_id') || '';
  const navigate = useNavigate();
  const setRunIDinUrl = (id) => {
    navigate(`${pathname}?run_id=${run.id}`);
  };

  return (
    <>
      <Flex
        onMouseEnter={(e) => {}}
        onClick={(e) => {
          setRunIDinUrl();
        }}
        sx={{
          borderRadius: 2,
          py: 1.2,
          px: 4,
          borderLeft: activeRunID === run.id ? '3px solid #24C3D9' : '',
          background: theme.palette.background.suiteList,

          borderBottom: '1px solid rgba(220,220,220 , .5)',
          '&:hover': {
            background: theme.palette.hover.default,
            cursor: 'pointer'
          },
          columnGap: 2,
          width: '100%'
        }}>
        <DirectionsRunIcon color="primary" />
        <Stack>
          <Typography fontSize={15} color={'primary'}>
            {run.api_suite.name}
          </Typography>
          <Typography fontSize={13}>{getDateTime(run?.created_at)}</Typography>
        </Stack>
        <Box
          sx={{
            ml: 'auto'
          }}>
          {run.status === 'error' && <StatusBadge status={'failed'} height={18} />}
          {run.status === 'success' && <StatusBadge status={'passed'} height={18} />}
        </Box>
      </Flex>
      {index + 1 !== suiteRuns.length && <Divider orientation="horizontal" sx={{ mt: 1 }} />}
    </>
  );
};

export default SuiteRunsList;
