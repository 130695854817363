import React, { useContext, useRef, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  IconButton,
  Tooltip,
  Pagination,
  Paper,
  TableContainer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import Table from '@mui/material/Table';
import FolderIcon from '@mui/icons-material/Folder';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import Flex from '../../../components/base/Flex';
import CustomButton from '../../../components/customButton';
import { useActiveProject } from '../../../store/projectState';
import useAwaitModal from '../../../hooks/useAwaitModal';
import UploadAppModal from './UploadAppModal';
import {
  StatusTag,
  StyledTableCell,
  getTestStatus,
  StyledTableRow
} from '../../projects/ProjectFolders';
import { StyledTableCell2, StyledTableRow2 } from '../../../components/base/Table';
import ProjectSvg from '../../../components/svg/ProjectSvg';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { useScandiumMutation, useScandiumQuery } from '../../../data-layer/utils';
import { getDateTime } from '../../../utils/time';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase
} from '../../../components/helpers/inputHelper';
import { useDebounce } from 'use-debounce';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import PageLoader from '../../../components/PageLoader';
import ErrorState from '../../../components/base/ErrorState';
import EmptyState from '../../../components/base/EmptyState';
import { formatDate } from '../../../utils/formateDate';
import ActionMenu from './ActionMenu';
import { toast } from 'react-toastify';
import CreateFolder from './CreateFolder';
import {
  useDeleteFolder,
  useFetchFolders,
} from '../../../data-layer/project-management';
import OutlinedButton from '../../../components/base/OutlinedButton';
import BackButton from '../../../components/base/BackButton';
import TextButton from '../../../components/base/TextButton';
import usePagination from '../../../hooks/usePagination';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import MoveToFolder from '../../projects/components/MoveToFolder';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../../AppTheme';

const StyledTableCellSmall = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.mobileOutline}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.mobileOutline}`
  }
}));

export const TestRow = ({ test, index, refetchTests, platform, mobileId }) => {
  const ref = useRef(null);
  const theme = useTheme();
  const { publicKey, folderId } = useParams();
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const {
    requestConfirm: requestConfirmDuplicate,
    ConfirmationDialog: ConfirmationDuplicateDialog
  } = useConfirmDialog();

  const [
    requestMoveToFolderModal,
    {
      open: moveToFolderOpen,
      onClose: onCloseMoveToFolder,
      onComplete: completeMoveToFolderModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const { mutateAsync: deleteApp, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/mobile-tests/apps/${publicKey}/test-cases/${test.id}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTests();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteApp = async () => {
    if (await requestConfirm()) return await deleteApp();
  };

  const onNavigate = () => {
    navigate(
      `/projects/${activeProject?.id}/mobile-testing/${publicKey}/${test.id}/${
        folderId ? `${folderId}/` : ''
      }edit?platform=${platform}`
    );
  };

  const { mutateAsync: duplicateTest, isLoading: isDuplicating } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${test.id}/duplicate`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTests();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDuplicateTest = async () => {
    if (await requestConfirmDuplicate()) return await duplicateTest();
  };

  return (
    <StyledTableRow2
      ref={ref}
      sx={{ textDecoration: 'none' }}
      onClick={(e) => e.currentTarget.contains(e.target) && onNavigate()}>
      <StyledTableCell2 component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <ProjectSvg width={24} height={24} fill={theme.palette.svg.primary} color={'primary'} />
          <Flex alignItems="flex-start" sx={{ flexDirection: 'column' }}>
            <Typography
              fontWeight={500}
              sx={{
                lineHeight: 1,
                mt: '4px',
                display: 'inline-block',
                maxWidth: '300px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              {test.name}
            </Typography>
            <Typography
              sx={{
                lineHeight: 1,
                mt: '4px',
                display: 'inline-block',
                fontSize: '0.85rem',
                maxWidth: '300px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              {test.description || ''}
            </Typography>
          </Flex>
        </Flex>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>{test.creator?.name || ''}</Box>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>{formatDate(test.created_at)}</Box>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Flex className={'cell-content-wrapper'} justifyContent={'center'}>
          <StatusTag status={getTestStatus(test.last_run_result)} />
        </Flex>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            isLoading={isDeleting || isDuplicating}
            onDelete={handleDeleteApp}
            testId={test.id}
            onNavigate={onNavigate}
            onDuplicate={handleDuplicateTest}
            onMoveToFolder={() => requestMoveToFolderModal({ moveTest: true })}
            testLink={`/projects/${activeProject?.id}/mobile-testing/${publicKey}/${test.id}/${
              folderId ? `${folderId}/` : ''
            }edit?platform=${platform}`}
          />
        </Box>
      </StyledTableCell2>
      <ConfirmationDialog
        title={'Are you sure you want to delete this test?'}
        description={'This action is not reversible'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <MoveToFolder
        open={moveToFolderOpen}
        onClose={onCloseMoveToFolder}
        testId={test.id}
        refetch={refetchTests}
        mobileId={mobileId}
        targetResource={'mobile-test'}
        {...otherProps}
      />
      <ConfirmationDuplicateDialog
        title={'Are you sure you want to duplicate this test case?'}
        description={
          'By duplicating this test case, a new test case will be created. The new test case will be identical to the original, and you can edit it as needed.'
        }
        confirmLabel={'Duplicate'}
      />
    </StyledTableRow2>
  );
};

const FolderRow = ({ folder, refetchFolders, mobileId }) => {
  const { projectId, publicKey } = useParams();
  const navigate = useNavigate();
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const menuRef = useRef(null);
  const activeProject = useActiveProject();
  const theme = useTheme();

  const [
    requestRenameFolderModal,
    {
      open: openRenameFolderModal,
      onClose: onCloseRenameFolderModal,
      onComplete: completeRenameFolderModal
    }
  ] = useAwaitModal();

  const [
    requestMoveToFolderModal,
    {
      open: moveToFolderOpen,
      onClose: onCloseMoveToFolder,
      onComplete: completeMoveToFolderModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const { mutateAsync: deleteFolder, isLoading: isDeleting } = useDeleteFolder(projectId, {
    folderId: folder.id,
    onSuccess: () => {
      refetchFolders();
      toast.success('Folder deleted successfully');
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handleDeleteFolder = async () => {
    if (await requestConfirm()) return await deleteFolder();
  };

  const onNavigate = (e) => {
    e.currentTarget.contains(e.target) &&
      navigate(
        `/projects/${activeProject?.id}/mobile-testing/${publicKey}/app/${folder.id}?page=1`
      );
  };

  return (
    <StyledTableRow sx={{ textDecoration: 'none' }} onClick={onNavigate}>
      <StyledTableCell component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <FolderIcon sx={{ color: theme.palette.svg.main }} />
          <Typography
            fontWeight={500}
            sx={{
              lineHeight: 1,
              mt: '4px',
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}>
            {folder.name}
          </Typography>
        </Flex>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{folder.creator?.name || ''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{formatDate(folder.created_at)}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            folderId={folder.id}
            isLoading={isDeleting}
            onDelete={handleDeleteFolder}
            ref={menuRef}
            onRename={() => requestRenameFolderModal()}
            onMoveToFolder={() => requestMoveToFolderModal({ moveTest: false })}
            folderLink={`/projects/${activeProject?.id}/mobile-testing/${publicKey}/app/${folder.id}?page=1`}
          />
        </Box>
      </StyledTableCell>
      <ConfirmationDialog
        title={'Are you sure you want to delete this folder?'}
        description={'All tests within the folder will be deleted as well'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <CreateFolder
        open={openRenameFolderModal}
        onClose={onCloseRenameFolderModal}
        onComplete={completeRenameFolderModal}
        refetchFolders={refetchFolders}
        mobileId={mobileId}
        folder={folder}
      />
      <MoveToFolder
        open={moveToFolderOpen}
        onClose={onCloseMoveToFolder}
        folderId={folder.id}
        refetch={refetchFolders}
        folderItem={folder}
        mobileId={mobileId}
        targetResource={'mobile-test'}
        {...otherProps}
      />
    </StyledTableRow>
  );
};

const AppDetails = () => {
  useDocumentTitle('App Details page');

  const theme = useTheme();
  const { mode } = useContext(ColorContext);

  const { isFeatureEnabled: isMobileTestingEnabled } = useFeatureEnabled('mobile-app-testing');

  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();

  const { publicKey, folderId } = useParams();
  const navigate = useNavigate();

  const activeProject = useActiveProject();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 600);

  const [
    requestUploadNewBuildModal,
    {
      open: openUploadNewBuildModal,
      onClose: onCloseUploadNewBuildModal,
      onComplete: completeUploadNewBuildModal
    }
  ] = useAwaitModal();

  const [
    requestCreateFolderModal,
    {
      open: openCreateFolderModal,
      onClose: onCloseCreateFolderModal,
      onComplete: completeCreateFolderModal
    }
  ] = useAwaitModal();

  const { data: activeFolder } = useFetchFolders(activeProject?.id, {
    folderId,
    enabled: !!activeProject?.id && !!folderId,
    select: (data) => data.data
  });

  const {
    data: appInfo,
    error: appError,
    isLoading: isLoadingApp,
    refetch: refetchAppInfo
  } = useScandiumQuery(`/projects/${activeProject?.id}/mobile-tests/apps/${publicKey}`, {
    enabled: !!activeProject?.id && !!publicKey,
    select: (data) => data.data
  });

  const {
    data: testsResponse,
    error: testError,
    isLoading: isLoadingTests,
    refetch: refetchTests
  } = useScandiumQuery(`/projects/${activeProject?.id}/mobile-tests/apps/${publicKey}/test-cases`, {
    enabled: !!activeProject?.id && !!publicKey,
    params: {
      page: activePage,
      limit: pageLimit,
      search: debouncedQuery || undefined,
      appPublicKey: publicKey,
      folder_id: folderId || undefined
    },
    select: (data) => data.data
  });

  const {
    data: folderRows = [],
    isLoading: isProjectFoldersLoading,
    error: foldersError,
    refetch: refetchFolders
  } = useFetchFolders(activeProject?.id, {
    folderId,
    enabled: !!activeProject?.id && appInfo?.mobile_app?.id,
    params: {
      target_resource: 'mobile-test',
      mobile_app_id: appInfo?.mobile_app?.id
    },
    select: (data) =>
      folderId ? data.data?.subdirectories : data.data?.filter((folder) => !folder.parent_id)
  });

  const isLoading = isProjectFoldersLoading || isLoadingTests;

  const testCases = testsResponse?.data || [];

  if (isLoadingApp || (!appError && !appInfo)) return <PageLoader />;

  if (appError) return <ErrorState error={appError} />;

  const allEntries = [...folderRows, ...testCases];

  return (
    <Box mb={12} mt={-2}>
      {folderId && (
        <BackButton
          label={'Go up'}
          size={'small'}
          onClick={(e) =>
            navigate(
              `/projects/${activeProject?.id}/mobile-testing/${publicKey}/app/${
                activeFolder?.parent_id || ''
              }?page=1`
            )
          }
        />
      )}
      <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
        <Typography
          sx={{
            textAlign: 'right',
            fontSize: '1.3rem',
            fontWeight: 500
          }}>
          {activeFolder?.name || 'All Apps'}
        </Typography>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={1}>
          <CustomButton
            disableRipple
            disableFocusRipple
            disableElevation
            disabled={!isMobileTestingEnabled}
            onClick={() =>
              navigate(
                `/projects/${activeProject?.id}/mobile-testing/${publicKey}/${
                  folderId ? `${folderId}/` : ''
                }new-test?platform=${appInfo?.platform}`
              )
            }
            fullWidth
            label={'New test'}
            sx={{
              textAlign: 'center',
              textTransform: 'none',
              borderRadius: '0.4rem',
              color: theme.palette.button.textContained,
              border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
              background: mode === 'dark' ? 'none' : 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 2,
              width: 'max-content'
            }}
          />
          <CustomButton
            disableRipple
            disableFocusRipple
            disableElevation
            fullWidth
            disabled={!isMobileTestingEnabled}
            onClick={() => requestUploadNewBuildModal()}
            variant={'outlined'}
            label={'New build'}
            sx={{
              textAlign: 'center',
              textTransform: 'none',
              borderRadius: '0.4rem',
              color: theme.palette.button.textOutlined,
              border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 2,
              width: 'max-content'
            }}
          />
          <Box>
            <Search sx={{ width: { xs: 'max-content' } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={'Select'}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </Search>
          </Box>
        </Box>
      </Flex>

      <Box sx={{ mt: 4 }}>
        <Typography
          color={'primary'}
          sx={{
            textAlign: 'left',
            fontSize: '1.1rem',
            fontWeight: 500
          }}>
          App information
        </Typography>

        <Table sx={{ width: '100%', mt: 1 }} size="small" aria-label={'email header Table'}>
          <TableHead>
            <TableRow>
              <StyledTableCellSmall>App name</StyledTableCellSmall>
              <StyledTableCellSmall>Version code</StyledTableCellSmall>
              <StyledTableCellSmall>Version name</StyledTableCellSmall>
              <StyledTableCellSmall>Creator</StyledTableCellSmall>
              <StyledTableCellSmall>Platform</StyledTableCellSmall>
              <StyledTableCellSmall>Date uploaded</StyledTableCellSmall>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCellSmall>{appInfo?.name || ''}</StyledTableCellSmall>
              <StyledTableCellSmall>{appInfo?.versionCode}</StyledTableCellSmall>
              <StyledTableCellSmall>
                {appInfo?.appVersionCode || ''} {appInfo?.appVersionName || ''}
              </StyledTableCellSmall>
              <StyledTableCellSmall>{appInfo?.creator}</StyledTableCellSmall>
              <StyledTableCellSmall>{appInfo?.platform}</StyledTableCellSmall>
              <StyledTableCellSmall>
                {appInfo?.created ? getDateTime(appInfo?.created) : ''}
              </StyledTableCellSmall>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Flex justifyContent={'space-between'}>
          <Typography
            color={'primary'}
            sx={{
              textAlign: 'left',
              fontSize: '1.1rem',
              fontWeight: 500
            }}>
            Test cases
          </Typography>
          <Tooltip title={'Create Folder'}>
            <TextButton
              startIcon={<FolderIcon sx={{ color: theme.palette.svg.main }} />}
              onClick={() => requestCreateFolderModal()}>
              Create Folder
            </TextButton>
          </Tooltip>
        </Flex>
        <TableContainer
          component={Paper}
          sx={{
            mb: 2,
            position: 'relative'
          }}>
          <Table sx={{ width: '100%', minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created On</StyledTableCell>
                <StyledTableCell align="center">Last Run</StyledTableCell>
                <StyledTableCell align={'right'}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activePage === 1 &&
                folderRows?.map((row) => (
                  <FolderRow
                    key={row.id}
                    folder={row}
                    refetchFolders={refetchFolders}
                    mobileId={appInfo?.mobile_app?.id}
                  />
                ))}
              {testCases?.map((test, i) => (
                <TestRow
                  key={i}
                  index={i}
                  test={test}
                  platform={appInfo?.platform}
                  refetchTests={refetchTests}
                  mobileId={appInfo?.mobile_app?.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <UploadAppModal
        open={openUploadNewBuildModal}
        onClose={onCloseUploadNewBuildModal}
        onComplete={completeUploadNewBuildModal}
        publicKey={publicKey}
        refetchAppInfo={refetchAppInfo}
      />

      <CreateFolder
        open={openCreateFolderModal}
        onClose={onCloseCreateFolderModal}
        onComplete={completeCreateFolderModal}
        refetchFolders={refetchFolders}
        mobileId={appInfo?.mobile_app?.id}
      />

      {testsResponse?.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={testsResponse?.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Tests per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Tests per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(testsResponse?.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      {isLoading && <PageLoader height={'100px'} />}
      {!isLoading &&
        (foldersError || testError
          ? !allEntries.length && <ErrorState error={foldersError || testError} />
          : !allEntries.length && (
              <EmptyState
                title={
                  !folderRows?.length && !testCases?.length
                    ? "It's lonely here at the moment"
                    : 'There are no matching results'
                }
                description={
                  !folderRows?.length && !testCases?.length
                    ? `Why not create a few tests or folders?`
                    : 'Try searching for something else, or create a new test'
                }
                cta={
                  <Flex>
                    <OutlinedButton
                      as={NavLink}
                      to={`/projects/${activeProject?.id}/mobile-testing/${publicKey}/${
                        folderId ? `${folderId}/` : ''
                      }new-test?platform=${appInfo?.platform}`}>
                      Create Test
                    </OutlinedButton>
                  </Flex>
                }
              />
            ))}
    </Box>
  );
};

export default AppDetails;
