import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useActiveProject } from '../../../store/projectState';
import { useMenu } from '../../../components/base/useMenu';
import Flex from '../../../components/base/Flex';
import CopySvg from '../../../components/svg/CopySvg';
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const EmailMenu = ({ theme }) => {
  const { triggerProps, menuProps } = useMenu();
  const activeProject = useActiveProject();
  const currentUrl = window.location.href;
  const isProduction = currentUrl.includes('app');

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  };

  const randomStringLength = 5;
  const randomString = generateRandomString(randomStringLength);
  const modifiedEmail = `${randomString}.${activeProject?.email_identifier}@${
    isProduction ? 'testmail.getscandium.com' : 'playmail.getscandium.com'
  }`;

  const copyEmailToClipBoard = () => {
    navigator.clipboard.writeText(modifiedEmail);

    toast.success('copied to clipboard');
  };

  return (
    <div>
      <Tooltip title={'Click to get a project level email address'}>
        <IconButton size={'small'} {...triggerProps}>
          <EmailIcon sx={{ color: theme.palette.svg.primary }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{
          elevation: 1,
          style: {
            // width: 200,
            paddingLeft: 10,
            paddingRight: 5
          },
          sx: {
            overflow: 'visible',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 148,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}>
        <Flex>
          <Typography textAlign={'left'} color={theme.palette.text.primary} fontSize={'0.85rem'}>
            {modifiedEmail}
          </Typography>
          <Tooltip title={'Click to copy'}>
            <IconButton size={'medium'} onClick={copyEmailToClipBoard}>
              <CopySvg fill={theme.palette.svg.primary} />
            </IconButton>
          </Tooltip>
        </Flex>
        <Divider my={1} />
        <Flex
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.background.lightGrey_dark
            },
            textDecoration: 'none'
          }}
          py={1}
          component={Link}
          to={`/projects/${activeProject?.id}/mailbox`}
          onClick={() => menuProps.onClose()}
          target={'_blank'}>
          <Typography
            fontSize={'0.85rem'}
            color={theme.palette.text.primary}
            fontStyle={'underline'}>
            Visit mailbox
          </Typography>
          <OpenInNewIcon sx={{ color: theme.palette.svg.primary }} fontSize={'1rem'} />
        </Flex>
      </Menu>
    </div>
  );
};

export default EmailMenu;
