const LinuxSvg = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1117_6515)">
        <path
          d="M3.72657 0.625075C3.54578 0.623114 3.36276 0.659743 3.2036 0.745514C3.03444 0.836671 2.89587 0.982486 2.81048 1.15464C2.72515 1.32671 2.69161 1.51049 2.68654 1.71288C2.6769 2.09744 2.69522 2.50078 2.72423 2.8663C2.73193 2.9776 2.74575 3.04273 2.73162 3.15695C2.68404 3.39984 2.4715 3.56333 2.35821 3.77667C2.23324 4.01202 2.18057 4.27811 2.08623 4.52733C1.99991 4.7554 1.87872 4.96924 1.79692 5.19897C1.68254 5.5202 1.64835 5.87318 1.72391 6.20568C1.78151 6.45916 1.90184 6.69684 2.06402 6.90013C2.04055 6.94243 2.01781 6.98517 1.99357 7.02703C1.91842 7.15685 1.82661 7.27992 1.78382 7.42369C1.76242 7.49557 1.75399 7.57224 1.76781 7.64595C1.78162 7.71967 1.81893 7.79019 1.87757 7.83694C1.91595 7.86754 1.96225 7.88726 2.01008 7.89832C2.05792 7.90938 2.10735 7.91208 2.15644 7.91102C2.34295 7.90706 2.52365 7.85036 2.70408 7.80299C2.81101 7.77492 2.91873 7.75 3.02699 7.72763C3.41139 7.64888 3.84042 7.68031 4.19726 7.73206C4.31813 7.75031 4.43813 7.77421 4.55693 7.80299C4.743 7.84808 4.92842 7.90558 5.1198 7.91102C5.17017 7.91246 5.22096 7.91025 5.27016 7.8993C5.31934 7.88834 5.36706 7.86835 5.40646 7.83693C5.4652 7.79008 5.50252 7.71946 5.51632 7.6456C5.53012 7.57174 5.5216 7.49485 5.49996 7.42288C5.4567 7.27896 5.36403 7.15616 5.28719 7.02701C5.25687 6.97606 5.22867 6.92378 5.19856 6.87272C5.42981 6.61304 5.61595 6.31272 5.7236 5.98285C5.84109 5.62279 5.86268 5.23511 5.8132 4.85961C5.76372 4.48412 5.64466 4.12007 5.48787 3.77531C5.2912 3.34304 5.12586 3.18685 5.00604 2.80725C4.87652 2.39698 4.98347 1.91151 4.88722 1.53939C4.85288 1.41285 4.79811 1.29204 4.72767 1.18145C4.64511 1.05187 4.54041 0.93573 4.41599 0.845583C4.21749 0.701787 3.97171 0.627738 3.72657 0.625075Z"
          fill="black"
        />
        <path
          d="M3.01965 2.79091C2.99327 2.82399 2.97607 2.86373 2.9657 2.90475C2.95532 2.94577 2.95152 2.98818 2.94989 3.03048C2.94666 3.11505 2.95182 3.20123 2.92943 3.28286C2.90551 3.37011 2.85186 3.44571 2.79935 3.51939C2.70843 3.647 2.61652 3.77792 2.57167 3.92805C2.54451 4.01897 2.53546 4.11527 2.54523 4.20966C2.44345 4.35913 2.35533 4.5179 2.28234 4.68335C2.1717 4.93411 2.09579 5.20108 2.06909 5.47385C2.03639 5.80784 2.07911 6.15137 2.21102 6.45994C2.3064 6.68306 2.44857 6.88731 2.63188 7.04628C2.72508 7.1271 2.8286 7.19609 2.93933 7.2505C3.32302 7.43909 3.79723 7.43944 4.17454 7.23838C4.37174 7.13331 4.53819 6.97965 4.69868 6.82418C4.79532 6.73055 4.89147 6.63469 4.9652 6.52213C5.10713 6.30541 5.15657 6.04201 5.18729 5.78477C5.24101 5.33497 5.24273 4.85987 5.04549 4.45207C4.97759 4.31167 4.88712 4.18222 4.77863 4.07019C4.74974 3.87395 4.69271 3.68186 4.60983 3.50163C4.55004 3.3716 4.47696 3.24784 4.4214 3.11596C4.3986 3.06181 4.37878 3.00637 4.35347 2.95335C4.32816 2.90033 4.29694 2.84932 4.25449 2.8087C4.21102 2.76711 4.15685 2.73762 4.09973 2.71874C4.0426 2.69988 3.98247 2.69133 3.92238 2.6883C3.8022 2.68226 3.68179 2.69797 3.56156 2.6932C3.46585 2.6894 3.37043 2.67263 3.27481 2.67828C3.227 2.6811 3.17919 2.68965 3.13482 2.70766C3.09045 2.72566 3.04954 2.75348 3.01965 2.79091Z"
          fill="#FCFCFA"
        />
        <path
          opacity="0.25"
          d="M2.59669 3.99608C2.62232 4.03451 2.58075 4.16704 3.17792 4.08222C3.17792 4.08222 3.07305 4.09375 2.96967 4.11763C2.80893 4.17144 2.66891 4.23156 2.56091 4.31122C2.45391 4.38995 2.37554 4.49274 2.27734 4.58103C2.27734 4.58103 2.43636 4.29049 2.476 4.20301C2.51565 4.11553 2.46936 4.11952 2.50072 3.9908C2.53207 3.86208 2.60884 3.7384 2.60884 3.7384C2.60884 3.7384 2.54616 3.92028 2.59669 3.99608Z"
          fill="url(#paint0_radial_1117_6515)"
        />
        <path
          opacity="0.42"
          d="M4.51685 3.96132C4.39515 4.0461 4.32384 4.03606 4.17618 4.05265C4.02852 4.06923 3.62891 4.06323 3.62891 4.06323C3.62891 4.06323 3.68676 4.06215 3.81498 4.08603C3.94321 4.10992 4.20475 4.13276 4.35201 4.18881C4.49928 4.24486 4.553 4.26085 4.6425 4.31746C4.76991 4.39802 4.8634 4.52421 4.98519 4.61248C4.98519 4.61248 4.99109 4.49535 4.94192 4.40787C4.89275 4.32039 4.76055 4.1825 4.72167 4.05378C4.68279 3.92506 4.66431 3.67212 4.66431 3.67212C4.66431 3.67212 4.63855 3.87653 4.51685 3.96132Z"
          fill="url(#paint1_radial_1117_6515)"
        />
        <path
          opacity="0.2"
          d="M3.37148 3.78027C3.36696 3.81699 3.36109 3.85355 3.3539 3.88984C3.35036 3.90774 3.34647 3.92565 3.3398 3.94263C3.33314 3.9596 3.32355 3.97571 3.31 3.98793C3.29813 3.99865 3.28366 4.00599 3.26913 4.01265C3.21242 4.03861 3.15173 4.05585 3.08984 4.06358C3.11456 4.06539 3.13925 4.06765 3.16388 4.07034C3.1794 4.07204 3.19495 4.07392 3.21008 4.0778C3.2252 4.08168 3.23996 4.08762 3.25256 4.09686C3.26638 4.10697 3.27723 4.12077 3.28533 4.13586C3.30147 4.16591 3.30687 4.20049 3.30985 4.23448C3.31367 4.27794 3.31395 4.32172 3.31069 4.36523C3.31362 4.33612 3.31972 4.30732 3.32885 4.27953C3.34531 4.22944 3.37183 4.18248 3.40754 4.14367C3.4217 4.12827 3.4373 4.11415 3.45443 4.10211C3.50685 4.06522 3.57343 4.049 3.63694 4.05768C3.57241 4.06053 3.50708 4.04025 3.45549 4.00135C3.42923 3.98154 3.40634 3.95689 3.39043 3.92809C3.36582 3.88358 3.35889 3.82958 3.37148 3.78027Z"
          fill="url(#paint2_radial_1117_6515)"
        />
        <path
          opacity="0.11"
          d="M3.21529 4.63892C3.18428 4.75694 3.16294 4.87751 3.15158 4.99901C3.13552 5.17083 3.13938 5.34386 3.13016 5.51618C3.12233 5.6624 3.10516 5.81016 3.13055 5.95438C3.14267 6.02315 3.16442 6.09022 3.19499 6.153C3.19985 6.12904 3.2034 6.10481 3.20564 6.08046C3.2164 5.96353 3.19672 5.84622 3.19006 5.72899C3.17839 5.52349 3.20684 5.31812 3.21843 5.11263C3.22733 4.95487 3.22628 4.79654 3.21529 4.63892Z"
          fill="black"
        />
        <path
          opacity="0.25"
          d="M2.6639 3.47685C2.66093 3.44064 2.6092 3.43521 2.58615 3.45326C2.56667 3.46851 2.54839 3.52063 2.56521 3.54098C2.59252 3.57402 2.66799 3.52649 2.6639 3.47685Z"
          fill="#7D7D7D"
        />
        <path
          opacity="0.75"
          d="M5.27322 4.46592C5.30317 4.46128 5.48805 4.61845 5.46274 4.67684C5.43745 4.73523 5.38998 4.70002 5.35603 4.70043C5.32208 4.70084 5.22917 4.74302 5.2136 4.71639C5.19802 4.68975 5.25469 4.6276 5.28434 4.57563C5.30821 4.53379 5.24326 4.47056 5.27322 4.46592Z"
          fill="#7D7D7D"
        />
        <path
          d="M4.14101 0.95065C4.07636 0.996279 4.1046 1.05325 4.13241 1.11043C4.16021 1.1676 4.07125 1.33244 4.07032 1.33955C4.06938 1.34667 4.24541 1.25599 4.2934 1.19709C4.35006 1.12753 4.49326 1.29119 4.48546 1.26629C4.4855 1.22175 4.20567 0.90502 4.14101 0.95065Z"
          fill="#7D7D7D"
        />
        <path
          d="M4.63173 2.86792C4.57719 2.91758 4.65729 2.9922 4.69528 3.07989C4.7189 3.1344 4.78423 3.21118 4.84744 3.19888C4.89497 3.18963 4.92447 3.12122 4.91998 3.073C4.9113 2.97997 4.83279 2.95818 4.77577 2.92622C4.73058 2.90089 4.67003 2.83304 4.63173 2.86792Z"
          fill="#838384"
        />
        <path
          d="M2.96214 3.23959C3.00509 3.36412 3.09153 3.47043 3.19383 3.55345C3.22784 3.58106 3.264 3.60652 3.30395 3.6245C3.3439 3.64247 3.38798 3.65272 3.43163 3.64902C3.47438 3.64539 3.51505 3.62861 3.55241 3.60752C3.58977 3.58642 3.62444 3.56094 3.66069 3.53798C3.72279 3.49863 3.78932 3.46681 3.85381 3.43151C3.93157 3.38897 4.0069 3.34096 4.07236 3.28121C4.10232 3.25385 4.13027 3.224 4.16336 3.20053C4.19645 3.17705 4.23607 3.16003 4.27655 3.16279C4.30692 3.16485 4.3354 3.17777 4.36515 3.18417C4.38003 3.18737 4.39544 3.18894 4.41049 3.18665C4.42553 3.18436 4.44024 3.17796 4.45079 3.167C4.46371 3.15357 4.46936 3.13422 4.46869 3.11559C4.46801 3.09696 4.46151 3.07895 4.45282 3.06244C4.43545 3.02944 4.40907 3.00102 4.39733 2.96563C4.38688 2.93414 4.38899 2.90009 4.38991 2.86691C4.39083 2.83374 4.3901 2.79908 4.37481 2.76964C4.36316 2.7472 4.34364 2.72941 4.32129 2.71758C4.29896 2.70576 4.2739 2.69967 4.24875 2.69732C4.19844 2.69259 4.14801 2.70236 4.09755 2.70522C4.03057 2.70902 3.96332 2.70064 3.89634 2.70466C3.81282 2.70968 3.73096 2.73393 3.6473 2.73408C3.55181 2.73425 3.45641 2.70301 3.36209 2.71794C3.32161 2.72435 3.28302 2.73907 3.24415 2.75205C3.20529 2.76503 3.16514 2.77644 3.12417 2.77584C3.07766 2.77517 3.03263 2.75906 2.98623 2.75575C2.96303 2.7541 2.93925 2.75575 2.91747 2.7639C2.8957 2.77205 2.876 2.7872 2.8653 2.80785C2.85917 2.81969 2.85614 2.83299 2.85557 2.84631C2.855 2.85962 2.85684 2.87297 2.86005 2.88591C2.86649 2.91178 2.87831 2.93591 2.88804 2.96073C2.92318 3.05037 2.93075 3.14859 2.96214 3.23959Z"
          fill="black"
          fillOpacity="0.2588"
        />
        <path
          opacity="0.3"
          d="M2.84095 2.88461C2.92439 2.93617 2.99926 2.99948 3.06213 3.07167C3.12048 3.13866 3.1701 3.21464 3.24623 3.26424C3.30001 3.29927 3.36465 3.31901 3.43019 3.32501C3.50716 3.33205 3.58505 3.32067 3.65925 3.30057C3.72801 3.28194 3.79453 3.25575 3.85554 3.22108C3.97151 3.15518 4.06839 3.05863 4.19629 3.01531C4.22422 3.00586 4.25328 2.99909 4.28019 2.98743C4.3071 2.97577 4.33244 2.95829 4.3453 2.93359C4.35765 2.90984 4.35718 2.88242 4.36121 2.85634C4.36551 2.82845 4.37517 2.80146 4.38048 2.77371C4.38578 2.74597 4.38641 2.71627 4.37338 2.69078C4.3626 2.66966 4.34297 2.65297 4.32042 2.64236C4.29786 2.63175 4.27247 2.62692 4.24716 2.62578C4.19656 2.62349 4.14658 2.63542 4.09614 2.63966C4.0293 2.64528 3.96202 2.63737 3.89493 2.63922C3.81149 2.64154 3.72927 2.65895 3.64589 2.66258C3.55075 2.66672 3.45586 2.65288 3.36068 2.64978C3.31952 2.64843 3.27807 2.64912 3.23749 2.65564C3.19691 2.66217 3.15708 2.67473 3.12277 2.69574C3.08948 2.71613 3.06232 2.74377 3.03181 2.76755C3.01654 2.77945 3.00033 2.79044 2.98253 2.7988C2.96472 2.80715 2.94523 2.81285 2.92527 2.81372C2.915 2.81418 2.90464 2.81337 2.89446 2.81482C2.87742 2.81725 2.86158 2.82629 2.85163 2.83927C2.84168 2.85225 2.83771 2.86898 2.84095 2.88461Z"
          fill="black"
        />
        <path
          d="M5.34927 4.3456C5.50274 4.46905 5.60012 4.65548 5.63028 4.85013C5.6538 5.00193 5.6385 5.15771 5.60429 5.30746C5.57007 5.45721 5.51721 5.60196 5.46477 5.74634C5.44385 5.80391 5.42284 5.86204 5.41453 5.92273C5.40621 5.98341 5.41148 6.04769 5.44075 6.1015C5.47431 6.16318 5.53693 6.20566 5.60424 6.22567C5.6707 6.24541 5.74249 6.24499 5.80977 6.22823C5.87704 6.21146 5.99029 6.18974 6.04506 6.14722C6.18417 6.03919 6.2172 5.85163 6.25015 5.68693C6.28453 5.51515 6.26772 5.33506 6.23751 5.16154C6.19631 4.92492 6.12699 4.69364 6.03876 4.47025C5.97386 4.30594 5.89072 4.14886 5.7918 4.00313C5.69476 3.86015 5.55706 3.74857 5.45533 3.60878C5.41998 3.56021 5.37747 3.50962 5.34275 3.46051C5.26788 3.35463 5.28482 3.37438 5.23839 3.30585C5.20469 3.25613 5.15148 3.23905 5.09549 3.2173C5.0395 3.19555 4.97663 3.18914 4.91881 3.20542C4.84259 3.22687 4.78034 3.28692 4.74722 3.35883C4.7141 3.43074 4.70831 3.51326 4.72119 3.59138C4.73781 3.69215 4.78389 3.78565 4.83586 3.87359C4.89454 3.97289 4.96252 4.06848 5.05116 4.1423C5.14363 4.21935 5.25548 4.27016 5.34927 4.3456Z"
          fill="black"
        />
        <path
          d="M2.4586 3.57844C2.28019 3.78115 2.0966 3.97737 1.99588 4.19651C1.94603 4.30782 1.92246 4.42874 1.8883 4.54583C1.84951 4.6788 1.79684 4.80739 1.7377 4.93264C1.6831 5.0483 1.62294 5.16123 1.56269 5.27405C1.51885 5.35611 1.47428 5.44004 1.45908 5.53184C1.44707 5.60438 1.45407 5.67925 1.47254 5.75042C1.49101 5.8216 1.5207 5.88941 1.5542 5.95487C1.69715 6.23421 1.9116 6.475 2.16563 6.6592C2.28092 6.74279 2.40441 6.8151 2.53395 6.87425C2.60413 6.9063 2.67805 6.9349 2.75519 6.93623C2.79376 6.9369 2.83272 6.93054 2.86805 6.91502C2.90336 6.89951 2.9349 6.87457 2.95588 6.8422C2.98168 6.8024 2.99043 6.75296 2.98525 6.70581C2.98007 6.65867 2.96168 6.61367 2.93699 6.57317C2.87671 6.47424 2.78149 6.40265 2.6899 6.3317C2.49215 6.17851 2.30085 6.01692 2.11781 5.84643C2.06612 5.7983 2.01443 5.74863 1.97833 5.68793C1.94316 5.62882 1.92417 5.56148 1.91129 5.49391C1.87599 5.30855 1.88504 5.11348 1.94811 4.93566C1.97278 4.86609 2.00539 4.79966 2.03685 4.73289C2.09137 4.61714 2.14297 4.49919 2.21466 4.39321C2.3039 4.26128 2.42318 4.14955 2.49593 4.00786C2.55732 3.88827 2.58218 3.75381 2.60595 3.62151C2.62453 3.51798 2.66018 3.4166 2.67831 3.31299C2.64284 3.3802 2.52853 3.4906 2.4586 3.57844Z"
          fill="black"
        />
        <path
          d="M5.65991 5.30339C5.60957 5.48764 5.53669 5.66369 5.47318 5.84339C5.45167 5.90398 5.43133 5.96544 5.41669 6.02822C5.4104 5.99369 5.41069 5.95754 5.41552 5.92271C5.42386 5.86198 5.44479 5.80388 5.46572 5.74637C5.51825 5.60193 5.57108 5.4572 5.60533 5.30749C5.63943 5.15764 5.65479 5.00193 5.63123 4.85002C5.60108 4.65539 5.50377 4.4691 5.35025 4.34558C5.25864 4.27183 5.14976 4.22163 5.05859 4.14758C5.16235 4.20422 5.27079 4.25368 5.36547 4.32363C5.52952 4.4451 5.64543 4.6289 5.67835 4.82807C5.70455 4.98656 5.71011 5.11915 5.65991 5.30339Z"
          fill="#838384"
        />
        <path
          opacity="0.95"
          d="M1.9499 4.93558C1.88683 5.11338 1.8779 5.30846 1.91317 5.49387C1.92605 5.56148 1.94507 5.6288 1.9802 5.68792C2.01634 5.74866 2.068 5.79827 2.11966 5.84641C2.12756 5.85388 2.13561 5.86119 2.14351 5.86836C2.14 5.86807 2.13649 5.86778 2.13312 5.86734C2.09522 5.86251 2.05937 5.84539 2.02937 5.82168C1.99937 5.79783 1.97493 5.76753 1.95517 5.73475C1.93073 5.69451 1.91273 5.65017 1.88463 5.61241C1.85098 5.56719 1.80341 5.53265 1.75 5.51495C1.78951 5.5009 1.82361 5.47222 1.8441 5.43563C1.86868 5.39187 1.87337 5.33978 1.87161 5.28973C1.87 5.23968 1.86254 5.18963 1.86517 5.13958C1.87029 5.04212 1.91273 4.95124 1.94903 4.8608C1.99805 4.73919 2.03756 4.61275 2.10488 4.50036C2.14571 4.43202 2.19649 4.36967 2.25503 4.31582C2.26117 4.30163 2.27112 4.28919 2.28356 4.28011C2.29249 4.27338 2.30259 4.26855 2.31342 4.26562C2.27961 4.30719 2.24639 4.34904 2.21654 4.39324C2.14483 4.49919 2.09317 4.61714 2.03873 4.7329C2.00727 4.79963 1.97464 4.86607 1.9499 4.93558Z"
          fill="#7D7D7D"
        />
        <path
          opacity="0.2"
          d="M6.38147 6.93544C6.36965 6.97451 6.35163 7.01226 6.32838 7.04668C6.27709 7.12267 6.20264 7.17984 6.12826 7.23258C6.00138 7.32252 5.86919 7.40505 5.75106 7.50727C5.67192 7.57576 5.59977 7.65253 5.53291 7.73338C5.47572 7.80253 5.42195 7.87511 5.35676 7.93661C5.29098 7.99865 5.21352 8.04876 5.13009 8.07607C5.02861 8.10927 4.92139 8.10768 4.82363 8.08261C4.75513 8.06505 4.68834 8.03451 4.6459 7.97928C4.6033 7.92383 4.5895 7.84933 4.58457 7.7762C4.57585 7.64685 4.5913 7.51466 4.60538 7.38334C4.61707 7.27425 4.62787 7.16505 4.63228 7.05617C4.64029 6.85812 4.62718 6.66179 4.60037 6.46916C4.59588 6.43689 4.59099 6.40439 4.59373 6.37115C4.59645 6.33792 4.6076 6.30335 4.63093 6.2777C4.65249 6.25401 4.68254 6.23997 4.71253 6.23308C4.74251 6.22619 4.77287 6.22578 4.803 6.22497C4.87397 6.22309 4.94536 6.21892 5.01525 6.22512C5.05936 6.22904 5.1026 6.23709 5.14665 6.24133C5.22015 6.2484 5.29639 6.24469 5.3701 6.22309C5.44946 6.19983 5.52515 6.15621 5.60579 6.14421C5.63887 6.13929 5.67206 6.13986 5.70423 6.14435C5.73706 6.14895 5.76972 6.15802 5.79495 6.17806C5.81412 6.19328 5.82815 6.21419 5.83913 6.23649C5.85549 6.2697 5.86553 6.30637 5.87128 6.34396C5.87637 6.37727 5.87817 6.41156 5.88676 6.44378C5.90089 6.49678 5.93289 6.5418 5.97139 6.57846C6.0099 6.61512 6.05492 6.6441 6.09987 6.67265C6.14465 6.70108 6.18969 6.72931 6.23752 6.75276C6.25996 6.76376 6.28304 6.77371 6.3045 6.78639C6.32595 6.79906 6.34596 6.81466 6.36021 6.83521C6.37953 6.86304 6.38728 6.89957 6.38147 6.93544Z"
          fill="url(#paint3_linear_1117_6515)"
        />
        <path
          d="M6.81686 7.14937C6.75068 7.22793 6.65877 7.27978 6.56724 7.32643C6.41116 7.406 6.2494 7.47579 6.10265 7.57151C6.00434 7.63564 5.91359 7.7109 5.82873 7.79202C5.75616 7.86138 5.68733 7.93546 5.60595 7.99425C5.52384 8.05355 5.42891 8.09651 5.32875 8.11154C5.20692 8.12982 5.08104 8.10617 4.96809 8.05696C4.88895 8.02246 4.81284 7.97315 4.76725 7.89984C4.72149 7.82626 4.71106 7.7362 4.71094 7.64954C4.71073 7.49627 4.73914 7.34461 4.76589 7.19368C4.7881 7.06828 4.80927 6.9426 4.82289 6.81597C4.8477 6.58566 4.84752 6.35308 4.83095 6.12202C4.82819 6.08333 4.82497 6.04425 4.83075 6.0059C4.83653 5.96754 4.85231 5.9293 4.88172 5.904C4.90888 5.88063 4.94531 5.8703 4.98109 5.86832C5.01687 5.86635 5.05257 5.87202 5.08802 5.8772C5.17157 5.88941 5.25578 5.89902 5.33744 5.92049C5.38897 5.93404 5.43917 5.95225 5.49061 5.96617C5.57642 5.98938 5.66632 6.0005 5.75462 5.99018C5.84969 5.97906 5.94201 5.94335 6.03771 5.94566C6.07697 5.94661 6.11593 5.95403 6.15339 5.96584C6.19161 5.97789 6.22928 5.99514 6.25738 6.02374C6.27873 6.04545 6.2936 6.07281 6.30476 6.10114C6.3214 6.14334 6.33036 6.18833 6.33419 6.23353C6.33759 6.27359 6.33706 6.31411 6.34464 6.35358C6.35713 6.41851 6.39124 6.47775 6.43363 6.52849C6.47603 6.57922 6.52669 6.62232 6.57731 6.66488C6.62773 6.70727 6.67846 6.74949 6.73286 6.78666C6.75838 6.80411 6.78472 6.82045 6.80896 6.83964C6.83319 6.85884 6.85548 6.88117 6.87064 6.90813C6.8912 6.94468 6.89746 6.98904 6.88421 7.03113C6.87095 7.07321 6.84685 7.11377 6.81686 7.14937Z"
          fill="url(#paint4_linear_1117_6515)"
        />
        <path
          d="M6.80836 6.95033C6.79279 6.98936 6.77078 7.02581 6.7435 7.05777C6.6829 7.12875 6.599 7.17517 6.51554 7.21695C6.37296 7.28834 6.22542 7.35107 6.09127 7.43728C6.00163 7.49488 5.91873 7.56252 5.84112 7.63552C5.77482 7.69789 5.71186 7.76456 5.63768 7.81732C5.56249 7.87081 5.47581 7.90929 5.38452 7.92276C5.27332 7.93916 5.15853 7.91788 5.05517 7.87369C4.98311 7.84287 4.91348 7.79878 4.87174 7.73244C4.83022 7.66644 4.82051 7.5854 4.82032 7.50741C4.81998 7.36958 4.846 7.23322 4.8705 7.09759C4.89085 6.98488 4.91023 6.8719 4.92255 6.75804C4.94496 6.55101 4.94395 6.34193 4.92993 6.13416C4.92758 6.09935 4.92488 6.0642 4.93022 6.02972C4.93556 5.99524 4.94984 5.96093 4.97627 5.93815C5.00106 5.91679 5.03436 5.90744 5.06703 5.90573C5.09971 5.90401 5.13229 5.9093 5.16467 5.91407C5.24092 5.92529 5.31784 5.93372 5.39245 5.95299C5.4395 5.96514 5.48537 5.98153 5.53231 5.99406C5.61074 6.01497 5.69278 6.02495 5.77341 6.01564C5.86021 6.00562 5.94459 5.97348 6.03194 5.97563C6.06776 5.97651 6.10333 5.98321 6.13758 5.99376C6.17244 6.00451 6.20692 6.01986 6.23255 6.0458C6.25186 6.06535 6.26522 6.09003 6.27582 6.11539C6.29159 6.15315 6.30187 6.19351 6.3027 6.23442C6.30358 6.27714 6.29424 6.32005 6.30002 6.36239C6.30472 6.39676 6.31928 6.42918 6.33787 6.45846C6.35645 6.48774 6.37908 6.51423 6.4015 6.5407C6.44482 6.59188 6.48825 6.64404 6.54257 6.68333C6.60442 6.72807 6.67781 6.75425 6.7417 6.796C6.76074 6.80845 6.77918 6.82253 6.7926 6.84091C6.81525 6.87186 6.82134 6.91424 6.80836 6.95033Z"
          fill="#E18900"
        />
        <path
          d="M6.79987 6.90635C6.78253 6.94565 6.76003 6.9823 6.73325 7.01486C6.67117 7.09031 6.58734 7.14226 6.49905 7.17563C6.35371 7.23055 6.19504 7.23793 6.05236 7.30046C5.9654 7.33857 5.88715 7.39599 5.80792 7.45023C5.74387 7.49407 5.67841 7.53629 5.60808 7.56698C5.52864 7.60165 5.44418 7.62108 5.35941 7.63469C5.30549 7.64335 5.25095 7.64973 5.19649 7.64672C5.14204 7.6437 5.08739 7.63094 5.03934 7.60318C5.0029 7.58212 4.97012 7.5518 4.95067 7.51248C4.92905 7.46878 4.92531 7.41749 4.9258 7.36798C4.92712 7.23601 4.95536 7.10547 4.95452 6.9735C4.95378 6.85862 4.93101 6.74521 4.92447 6.63055C4.91189 6.41004 4.9592 6.18567 4.91755 5.96936C4.91088 5.93474 4.90192 5.90014 4.90236 5.86478C4.90259 5.84711 4.90521 5.82933 4.91145 5.81296C4.9177 5.79662 4.92768 5.78172 4.94098 5.77139C4.95219 5.76268 4.96549 5.7574 4.97913 5.75501C4.99277 5.75263 5.00673 5.7531 5.0204 5.75523C5.04774 5.75948 5.07378 5.77022 5.1004 5.77815C5.17656 5.80082 5.25702 5.80035 5.33442 5.81746C5.38301 5.8282 5.43004 5.84579 5.47811 5.85893C5.55884 5.881 5.64301 5.89042 5.72581 5.88074C5.81485 5.87032 5.90188 5.83799 5.99141 5.84032C6.02811 5.84127 6.06456 5.84809 6.09994 5.85864C6.13534 5.86919 6.17072 5.88412 6.1975 5.9112C6.2168 5.93073 6.23069 5.95568 6.24196 5.98149C6.25841 6.01916 6.26984 6.06008 6.26957 6.10171C6.26943 6.12337 6.26615 6.14484 6.26419 6.1664C6.26224 6.18795 6.26166 6.21002 6.26682 6.23095C6.27202 6.25198 6.2828 6.27094 6.29516 6.28815C6.30751 6.30536 6.32153 6.32109 6.33432 6.33794C6.37013 6.38509 6.39571 6.44006 6.4236 6.49306C6.45149 6.54607 6.48277 6.59851 6.52681 6.6367C6.58677 6.68866 6.66455 6.70943 6.73139 6.75049C6.75095 6.76252 6.76992 6.77663 6.78367 6.79586C6.79469 6.81125 6.80206 6.82968 6.80486 6.84889C6.8077 6.86808 6.80596 6.88804 6.79987 6.90635Z"
          fill="#FFC000"
        />
        <path
          d="M4.90121 5.93959C4.98383 5.91721 5.05421 5.97988 5.11414 6.02718C5.15286 6.0602 5.2088 6.05189 5.25631 6.05346C5.33502 6.0506 5.41332 6.06664 5.49206 6.05688C5.64704 6.04379 5.79899 6.00569 5.9547 5.99912C6.02908 5.99444 6.11258 5.99011 6.17686 6.03356C6.20291 6.0516 6.2411 6.12792 6.26681 6.09926C6.25622 6.0191 6.19676 5.94148 6.11702 5.92013C6.05459 5.91031 5.99116 5.92747 5.92812 5.92193C5.74089 5.91746 5.55499 5.88269 5.36732 5.89266C5.23676 5.89378 5.10637 5.88774 4.97599 5.88265C4.92477 5.87218 4.90671 5.91768 4.87891 5.93513"
          fill="url(#paint5_linear_1117_6515)"
        />
        <path
          d="M1.62923 5.74722C1.66956 5.73049 1.71494 5.72745 1.75788 5.73538C1.80081 5.74331 1.84139 5.76185 1.87751 5.7864C1.94974 5.83548 2.00332 5.90691 2.05464 5.97756C2.17248 6.13984 2.28589 6.30551 2.38871 6.4777C2.47211 6.61737 2.54871 6.76162 2.64507 6.89268C2.70797 6.97823 2.77892 7.05762 2.84214 7.14294C2.90536 7.22826 2.96139 7.32091 2.98942 7.42333C3.02584 7.55642 3.01173 7.70313 2.94838 7.8257C2.90382 7.91194 2.83548 7.98592 2.75234 8.03602C2.66921 8.08613 2.57148 8.11205 2.47448 8.10842C2.32027 8.10264 2.1794 8.02556 2.03603 7.96846C1.74393 7.85212 1.42666 7.81563 1.12559 7.72502C1.03306 7.69717 0.942077 7.66421 0.848901 7.6386C0.807501 7.62722 0.765442 7.61721 0.726354 7.59947C0.687252 7.58173 0.650695 7.55535 0.628949 7.51834C0.612134 7.48973 0.605022 7.45604 0.60549 7.42287C0.605944 7.38969 0.613715 7.35692 0.625012 7.32572C0.647608 7.26332 0.68412 7.20684 0.708749 7.14521C0.748979 7.04459 0.756208 6.93363 0.750779 6.82541C0.74535 6.71717 0.727803 6.60989 0.720398 6.50177C0.717091 6.4535 0.715847 6.4046 0.725052 6.3571C0.734271 6.3096 0.754701 6.26316 0.789559 6.22959C0.821696 6.19864 0.86434 6.18027 0.908009 6.17116C0.951677 6.16204 0.996677 6.16163 1.04127 6.16264C1.08587 6.16365 1.13057 6.16602 1.17504 6.16238C1.2195 6.15872 1.26416 6.14874 1.3029 6.12661C1.33972 6.10557 1.36985 6.07429 1.3948 6.03999C1.41975 6.00569 1.43993 5.96821 1.4606 5.93119C1.48127 5.89415 1.50267 5.85725 1.52974 5.82459C1.55685 5.79193 1.59004 5.76346 1.62923 5.74722Z"
          fill="url(#paint6_linear_1117_6515)"
        />
        <path
          d="M1.6947 5.81671C1.73141 5.80193 1.77275 5.80031 1.8113 5.80904C1.84984 5.81778 1.8857 5.83654 1.91694 5.86067C1.97943 5.90894 2.02286 5.97704 2.0646 6.04401C2.16474 6.20626 2.26263 6.37024 2.35506 6.53753C2.4251 6.66348 2.49189 6.7921 2.57797 6.90776C2.63507 6.98448 2.70022 7.05485 2.75851 7.13064C2.81678 7.20645 2.86876 7.28909 2.89453 7.38118C2.92774 7.49988 2.91428 7.63113 2.85682 7.74016C2.81552 7.81849 2.7521 7.88509 2.67558 7.92964C2.59905 7.97419 2.50964 7.99646 2.42118 7.99239C2.27997 7.9859 2.15101 7.91537 2.01811 7.86752C1.76328 7.77506 1.48709 7.76265 1.22572 7.69046C1.13203 7.66503 1.04067 7.63112 0.946516 7.60738C0.904853 7.59687 0.862443 7.58828 0.822872 7.57159C0.783301 7.55489 0.746028 7.529 0.724574 7.49177C0.708608 7.4641 0.702242 7.43154 0.703222 7.39961C0.704203 7.36767 0.712281 7.33628 0.723652 7.30644C0.746393 7.24674 0.782174 7.19268 0.805091 7.13304C0.839862 7.0429 0.844179 6.94378 0.837726 6.84737C0.831272 6.75097 0.814486 6.65544 0.808633 6.55899C0.806013 6.51589 0.805618 6.47225 0.81434 6.42996C0.823062 6.38766 0.841486 6.34648 0.872218 6.31616C0.90554 6.28315 0.951448 6.2651 0.997751 6.25805C1.04407 6.25098 1.09122 6.25407 1.13786 6.25823C1.18448 6.26238 1.23131 6.26762 1.27813 6.26482C1.32494 6.26203 1.37236 6.25062 1.41102 6.22429C1.44626 6.20046 1.4727 6.16566 1.49286 6.12848C1.51299 6.09129 1.52735 6.05141 1.5427 6.01203C1.55805 5.97265 1.57465 5.93329 1.59873 5.89828C1.62281 5.86329 1.65512 5.8326 1.6947 5.81671Z"
          fill="#EC9A00"
        />
        <path
          d="M1.6586 5.72507C1.69414 5.70743 1.73604 5.70382 1.77503 5.71177C1.81401 5.71973 1.85015 5.73878 1.88085 5.76367C1.94227 5.81347 1.98124 5.88442 2.01753 5.95403C2.10089 6.11391 2.17854 6.27728 2.27828 6.42783C2.35064 6.53705 2.43415 6.6388 2.50734 6.74747C2.6066 6.89487 2.68732 7.05699 2.72063 7.23065C2.74304 7.34746 2.74229 7.47362 2.68602 7.57886C2.64898 7.64812 2.58891 7.70493 2.51796 7.74048C2.447 7.77602 2.36553 7.79044 2.2862 7.78344C2.16124 7.77242 2.04775 7.71099 1.93012 7.66814C1.72141 7.59213 1.49584 7.57394 1.28104 7.51659C1.19044 7.4924 1.10171 7.46123 1.00988 7.44204C0.969371 7.43358 0.928074 7.42739 0.889395 7.41283C0.850732 7.39826 0.814073 7.37405 0.794317 7.3383C0.780414 7.31314 0.775746 7.2837 0.777809 7.25513C0.779888 7.22658 0.788419 7.19879 0.799614 7.17235C0.822005 7.11949 0.855122 7.07105 0.872522 7.0164C0.898073 6.93609 0.887566 6.84903 0.868395 6.76704C0.849224 6.68504 0.821566 6.60453 0.813049 6.5208C0.809258 6.48351 0.809346 6.44551 0.817878 6.40898C0.82641 6.37246 0.843781 6.33735 0.871059 6.31115C0.906195 6.27741 0.955396 6.26062 1.00432 6.25594C1.05324 6.25126 1.10248 6.25765 1.15111 6.26463C1.19974 6.27163 1.24868 6.27925 1.29778 6.27701C1.34688 6.27477 1.39687 6.26188 1.43532 6.23182C1.47336 6.2021 1.49727 6.15783 1.51097 6.11204C1.52468 6.06625 1.52913 6.01837 1.53475 5.97095C1.54037 5.92354 1.54738 5.87568 1.56576 5.8315C1.58415 5.78732 1.61521 5.74659 1.6586 5.72507Z"
          fill="#FFBD00"
        />
        <path
          d="M2.10547 6.12427C2.17121 6.24316 2.2113 6.37956 2.27651 6.49944C2.3365 6.60973 2.40475 6.72326 2.46568 6.82497C2.49281 6.87025 2.55606 6.93985 2.62001 7.04786C2.67796 7.14575 2.7365 7.28236 2.76923 7.32822C2.75043 7.27453 2.71175 7.13031 2.66561 7.02751C2.6225 6.93145 2.57229 6.87663 2.54135 6.82495C2.48043 6.72325 2.41481 6.63184 2.35219 6.5349C2.26577 6.40121 2.19993 6.25154 2.10547 6.12427Z"
          fill="url(#paint7_linear_1117_6515)"
        />
        <path
          opacity="0.35"
          d="M6.01686 6.11594C6.00267 6.08815 5.97684 6.06737 5.94804 6.05534C5.91924 6.04331 5.88758 6.03947 5.85637 6.03972C5.79395 6.04021 5.7319 6.05646 5.66972 6.05108C5.6172 6.04653 5.56736 6.02679 5.51612 6.01432C5.46322 6.00144 5.40711 5.99642 5.35458 6.01072C5.29844 6.026 5.2489 6.06352 5.21789 6.11275C5.19054 6.15615 5.17756 6.2074 5.17348 6.25853C5.1694 6.30968 5.1738 6.36107 5.17898 6.41212C5.18273 6.44904 5.18688 6.48604 5.19582 6.52205C5.20475 6.55807 5.21862 6.59325 5.24037 6.62332C5.27166 6.66661 5.31814 6.69746 5.36823 6.71601C5.44999 6.74629 5.54312 6.74467 5.62377 6.71155C5.7665 6.64957 5.88732 6.53843 5.96096 6.40135C5.98733 6.35227 6.0078 6.29998 6.02103 6.24584C6.02629 6.22433 6.03041 6.2024 6.03062 6.18025C6.03084 6.15813 6.02693 6.13566 6.01686 6.11594Z"
          fill="url(#paint8_radial_1117_6515)"
        />
        <path
          opacity="0.35"
          d="M6.01686 6.03629C6.00267 6.0162 5.97684 6.00117 5.94804 5.99249C5.91924 5.9838 5.88758 5.98102 5.85637 5.98119C5.79395 5.98155 5.7319 5.9933 5.66972 5.9894C5.6172 5.98613 5.56736 5.97184 5.51612 5.96283C5.46322 5.95352 5.40711 5.94989 5.35458 5.96022C5.29844 5.97127 5.2489 5.9984 5.21789 6.03398C5.19054 6.06536 5.17756 6.10241 5.17348 6.13938C5.1694 6.17634 5.1738 6.21351 5.17898 6.25041C5.18273 6.2771 5.18688 6.30385 5.19582 6.32988C5.20475 6.35592 5.21862 6.38135 5.24037 6.4031C5.27166 6.43439 5.31814 6.4567 5.36823 6.47011C5.44999 6.492 5.54312 6.49082 5.62377 6.46687C5.7665 6.42208 5.88732 6.34172 5.96096 6.24262C5.98733 6.20713 6.0078 6.16933 6.02103 6.1302C6.02629 6.11464 6.03041 6.09879 6.03062 6.08278C6.03082 6.06679 6.02693 6.05055 6.01686 6.03629Z"
          fill="url(#paint9_radial_1117_6515)"
        />
        <path
          d="M6.13887 5.96968C6.12774 6.019 6.10331 6.06436 6.07448 6.10593C5.9874 6.23222 5.85848 6.3291 5.71301 6.37783C5.62491 6.40666 5.52803 6.40812 5.4392 6.38178C5.38755 6.36656 5.3375 6.3411 5.30208 6.30056C5.25833 6.25022 5.24164 6.18188 5.23637 6.11544C5.2292 6.02617 5.24091 5.93456 5.27808 5.85305C5.29301 5.82027 5.31203 5.78924 5.3353 5.76188C5.34467 5.75046 5.35491 5.73963 5.36589 5.72968C5.4035 5.69544 5.4493 5.66983 5.49891 5.65856C5.55555 5.64583 5.61482 5.65197 5.67203 5.66178C5.72721 5.67114 5.78179 5.68373 5.83667 5.69397C5.89491 5.70495 5.95433 5.71358 6.00906 5.7367C6.02735 5.74446 6.04506 5.75397 6.06145 5.76553C6.0695 5.77095 6.07711 5.77695 6.08428 5.78339C6.10623 5.80329 6.12423 5.82802 6.13448 5.85583C6.14765 5.89197 6.14735 5.93207 6.13887 5.96968Z"
          fill="black"
        />
        <path
          d="M6.09955 5.91656C6.08609 5.94085 6.0656 5.95988 6.04292 5.97568C5.98921 6.01329 5.92379 6.031 5.85926 6.04344C5.87331 6.031 5.88765 6.01812 5.90096 6.00495C5.9175 5.98856 5.93287 5.97071 5.94428 5.95051C5.9557 5.93017 5.96228 5.90632 5.95774 5.88349C5.95467 5.86797 5.94677 5.85393 5.93638 5.84178C5.92614 5.82963 5.91399 5.81924 5.90096 5.81017C5.84506 5.7711 5.77921 5.749 5.71262 5.73451C5.6696 5.72514 5.6254 5.71944 5.58179 5.71402C5.53628 5.70831 5.48974 5.70334 5.44467 5.71168C5.42843 5.71461 5.41262 5.71944 5.3974 5.72573C5.39447 5.7269 5.3914 5.72807 5.38847 5.72968C5.3873 5.73012 5.38613 5.73056 5.38511 5.73129C5.3816 5.73275 5.37808 5.73451 5.37472 5.73641C5.37413 5.7367 5.37355 5.737 5.37311 5.73729C5.36886 5.73949 5.36477 5.74183 5.36067 5.74446C5.35643 5.74709 5.35218 5.74988 5.34808 5.7528C5.34384 5.75558 5.33989 5.75851 5.33594 5.76173C5.34545 5.75031 5.35555 5.73963 5.36652 5.72968C5.40413 5.69544 5.44994 5.66983 5.49955 5.65856C5.55618 5.64583 5.61545 5.65197 5.67267 5.66178C5.72784 5.67114 5.78243 5.68373 5.83731 5.69397C5.89555 5.70495 5.95496 5.71358 6.0097 5.7367C6.02799 5.74446 6.0457 5.75397 6.06209 5.76553C6.06414 5.76714 6.06618 5.7689 6.06823 5.77066C6.07131 5.77314 6.07423 5.77592 6.07716 5.77885C6.08009 5.78149 6.08272 5.78441 6.08536 5.78734C6.09779 5.80139 6.10775 5.81807 6.11228 5.83622C6.11887 5.86314 6.11316 5.89241 6.09955 5.91656Z"
          fill="url(#paint10_radial_1117_6515)"
        />
        <path
          d="M6.09955 5.91656C6.08609 5.94085 6.0656 5.95988 6.04292 5.97568C5.98921 6.01329 5.92379 6.031 5.85926 6.04344C5.87331 6.031 5.88765 6.01812 5.90096 6.00495C5.9175 5.98856 5.93287 5.97071 5.94428 5.95051C5.9557 5.93017 5.96228 5.90632 5.95774 5.88349C5.95467 5.86797 5.94677 5.85393 5.93638 5.84178C5.92614 5.82963 5.91399 5.81924 5.90096 5.81017C5.84506 5.7711 5.77921 5.749 5.71262 5.73451C5.6696 5.72514 5.6254 5.71944 5.58179 5.71402C5.53628 5.70831 5.48974 5.70334 5.44467 5.71168C5.42843 5.71461 5.41262 5.71944 5.3974 5.72573C5.39433 5.7269 5.3914 5.72822 5.38847 5.72968C5.3873 5.73012 5.38613 5.73056 5.38511 5.73129C5.3816 5.73275 5.37808 5.73451 5.37472 5.73641C5.37413 5.7367 5.37355 5.737 5.37311 5.73729C5.36886 5.73949 5.36477 5.74197 5.36067 5.74446C5.35643 5.74709 5.35218 5.74988 5.34808 5.7528C5.34399 5.75573 5.33989 5.75866 5.33594 5.76188V5.76173C5.34545 5.75031 5.35555 5.73963 5.36652 5.72968C5.40413 5.69544 5.44994 5.66983 5.49955 5.65856C5.55618 5.64583 5.61545 5.65197 5.67267 5.66178C5.72784 5.67114 5.78243 5.68373 5.83731 5.69397C5.89555 5.70495 5.95496 5.71358 6.0097 5.7367C6.02799 5.74446 6.0457 5.75397 6.06209 5.76553C6.06414 5.76714 6.06618 5.7689 6.06823 5.77066C6.07131 5.77314 6.07423 5.77592 6.07716 5.77885C6.08009 5.78149 6.08272 5.78441 6.08536 5.78734C6.09779 5.80139 6.10775 5.81807 6.11228 5.83622C6.11887 5.86314 6.11316 5.89241 6.09955 5.91656Z"
          fill="url(#paint11_linear_1117_6515)"
        />
        <path
          d="M3.05488 1.78419C3.00995 1.78663 2.9837 1.79328 2.95083 1.824C2.91796 1.85471 2.91189 1.87893 2.89765 1.92161C2.88488 1.95989 2.85028 1.99091 2.84839 2.0312C2.84608 2.08069 2.84964 2.13102 2.85321 2.1805C2.85908 2.26176 2.86508 2.34458 2.8955 2.42015C2.91072 2.45794 2.93209 2.49355 2.96058 2.52265C2.98909 2.55176 3.02494 2.57415 3.06445 2.58405C3.10151 2.59333 3.14108 2.59141 3.17752 2.57997C3.21395 2.56853 3.24723 2.54772 3.27453 2.52101C3.31435 2.48204 3.3412 2.43107 3.35658 2.37751C3.37198 2.32396 3.37637 2.26775 3.37556 2.21204C3.37457 2.14232 3.36545 2.07234 3.34363 2.00612C3.33161 1.96967 3.31566 1.93434 3.29564 1.90162C3.2793 1.87489 3.24177 1.87434 3.21993 1.85186C3.19638 1.82764 3.16764 1.82985 3.13734 1.81491C3.10705 1.79997 3.08861 1.78235 3.05488 1.78419Z"
          fill="url(#paint12_radial_1117_6515)"
        />
        <path
          d="M2.94765 2.11742C2.93827 2.17407 2.93792 2.2335 2.95739 2.2875C2.97026 2.32316 2.99145 2.35552 3.01722 2.38332C3.03409 2.40153 3.05325 2.41808 3.07562 2.42881C3.09801 2.43953 3.12395 2.4441 3.14802 2.43806C3.17025 2.43248 3.18967 2.41817 3.20401 2.4003C3.21835 2.38243 3.22791 2.36112 3.23473 2.33926C3.25483 2.27468 3.2516 2.20403 3.23164 2.1394C3.21709 2.09231 3.19299 2.04688 3.15531 2.01514C3.13732 2 3.11626 1.98813 3.09339 1.98268C3.07052 1.97725 3.04576 1.97853 3.02435 1.98824C3.00058 1.99904 2.98218 2.01959 2.97014 2.04276C2.95811 2.06591 2.95192 2.09166 2.94765 2.11742Z"
          fill="black"
        />
        <path
          d="M3.06538 2.0765C3.07149 2.09248 3.09197 2.09829 3.10321 2.11118C3.11413 2.12371 3.12559 2.13668 3.13159 2.15217C3.14306 2.18184 3.12026 2.2258 3.1442 2.24675C3.15171 2.25332 3.16677 2.25347 3.17416 2.24675C3.20305 2.22047 3.19664 2.16745 3.18519 2.1301C3.17482 2.09621 3.14919 2.06412 3.11761 2.04801C3.10301 2.04056 3.08103 2.03437 3.06853 2.04498C3.0605 2.05181 3.06162 2.06664 3.06538 2.0765Z"
          fill="url(#paint13_linear_1117_6515)"
        />
        <path
          d="M3.29473 1.9018C3.26034 1.8936 3.2258 1.88716 3.19068 1.88394C3.11224 1.87648 3.02985 1.88585 2.9621 1.92624C2.91731 1.95287 2.88161 1.99107 2.84766 2.03102C2.84941 1.99077 2.85512 1.95111 2.86785 1.91292C2.88219 1.87033 2.90531 1.83009 2.9381 1.79936C2.97102 1.76863 3.01405 1.74785 3.05897 1.7455C3.09263 1.7436 3.12644 1.75209 3.15673 1.76702C3.18702 1.78194 3.21395 1.80316 3.23751 1.82746C3.25946 1.84999 3.27849 1.87502 3.29473 1.9018Z"
          fill="url(#paint14_radial_1117_6515)"
        />
        <path
          d="M3.24269 1.69538C3.30584 1.75454 3.35279 1.82755 3.38457 1.90496C3.37068 1.81958 3.34867 1.75101 3.29314 1.69538C3.25905 1.66345 3.22033 1.63541 3.17649 1.61572C3.13812 1.59848 3.09543 1.58778 3.07103 1.58732C3.04664 1.58689 3.04071 1.58743 3.03569 1.58766C3.03067 1.5879 3.02668 1.58807 3.04256 1.58987C3.05843 1.59167 3.09385 1.60126 3.13187 1.61833C3.1699 1.63541 3.20863 1.66345 3.24269 1.69538Z"
          fill="url(#paint15_linear_1117_6515)"
        />
        <path
          d="M3.88775 1.81029C3.82203 1.85544 3.70242 1.92712 3.70242 1.92712C3.70005 1.93253 3.69887 1.93596 3.69707 1.94099C3.66085 2.04193 3.67472 2.1549 3.70968 2.25627C3.74549 2.36012 3.80538 2.45909 3.8958 2.52141C3.94102 2.55259 3.99337 2.57395 4.04789 2.58041C4.10244 2.58686 4.1591 2.57801 4.20782 2.55264C4.26717 2.52172 4.31244 2.46758 4.34053 2.40684C4.36865 2.34611 4.3806 2.27897 4.38436 2.21215C4.38773 2.15244 4.38463 2.09203 4.37282 2.0335C4.3678 2.00857 4.33445 1.99003 4.32609 1.96597C4.2957 1.87848 4.23567 1.86397 4.15791 1.81363C4.11903 1.78846 4.08751 1.77356 4.04163 1.76723C3.99575 1.76088 3.98892 1.76206 3.94575 1.77883C3.92116 1.78836 3.90951 1.79535 3.88775 1.81029Z"
          fill="url(#paint16_radial_1117_6515)"
        />
        <path
          d="M4.01624 1.95717C3.98986 1.95906 3.96417 1.96806 3.94179 1.98215C3.91942 1.99624 3.90035 2.01533 3.88538 2.03712C3.85542 2.08068 3.84228 2.13414 3.84016 2.18697C3.83856 2.22663 3.84295 2.2668 3.85611 2.30425C3.86926 2.3417 3.89143 2.37635 3.92201 2.40167C3.95328 2.42757 3.99304 2.44313 4.03361 2.44508C4.07417 2.44702 4.11531 2.43533 4.14865 2.41212C4.17521 2.39364 4.19662 2.36819 4.21187 2.33965C4.22711 2.31111 4.2363 2.27953 4.2405 2.24745C4.24792 2.19079 4.23954 2.13161 4.21334 2.08082C4.18715 2.03002 4.1427 1.98818 4.08908 1.96842C4.06586 1.95986 4.04094 1.9554 4.01624 1.95717Z"
          fill="black"
        />
        <path
          d="M4.07956 2.13007C4.05772 2.13869 4.02314 2.13326 4.01357 2.1547C4.00789 2.1674 4.01591 2.18831 4.02879 2.19355C4.05483 2.20416 4.09517 2.18495 4.10569 2.15887C4.11026 2.14754 4.10334 2.12959 4.09208 2.12486C4.08791 2.12312 4.08376 2.12841 4.07956 2.13007Z"
          fill="#0C0C0B"
        />
        <path
          d="M4.17892 2.1868C4.20821 2.15752 4.15808 2.07987 4.11902 2.04808C4.09072 2.02506 4.00829 2.00096 4.01183 2.02601C4.01537 2.05107 4.05244 2.08317 4.07727 2.10767C4.10784 2.13782 4.16502 2.20072 4.17892 2.1868Z"
          fill="url(#paint17_linear_1117_6515)"
        />
        <path
          d="M4.3699 2.03447C4.33244 2.00433 4.29556 1.97316 4.25503 1.94725C4.11937 1.86121 3.94507 1.84247 3.79156 1.88989C3.75966 1.89969 3.72878 1.91213 3.69922 1.92721C3.72937 1.8533 3.78541 1.79096 3.85156 1.7456C3.87322 1.73067 3.89634 1.71721 3.92093 1.70769C3.9641 1.69101 4.01166 1.68618 4.05746 1.69247C4.10342 1.69891 4.14746 1.71618 4.18639 1.74135C4.2641 1.79169 4.31927 1.87247 4.34971 1.95999C4.3582 1.98428 4.36478 2.00916 4.3699 2.03447Z"
          fill="url(#paint18_radial_1117_6515)"
        />
        <path
          d="M4.09793 1.53772C4.0859 1.55285 4.07503 1.5693 4.06641 1.58737C4.12054 1.59904 4.17231 1.62131 4.21853 1.65201C4.30794 1.7114 4.37585 1.79821 4.42347 1.89478C4.43459 1.88247 4.4447 1.86926 4.45342 1.85458C4.40557 1.754 4.33629 1.66302 4.24376 1.60157C4.19929 1.57204 4.14979 1.54976 4.09793 1.53772Z"
          fill="url(#paint19_linear_1117_6515)"
        />
        <path
          d="M3.26701 2.33818C3.31158 2.28807 3.34208 2.22693 3.3849 2.17533C3.40631 2.14953 3.43101 2.12601 3.4601 2.10933C3.4892 2.09265 3.52296 2.08308 3.55637 2.08596C3.59355 2.08918 3.62837 2.1077 3.65559 2.13324C3.68282 2.15878 3.703 2.191 3.71911 2.22468C3.73454 2.25692 3.7468 2.29128 3.76903 2.31926C3.79275 2.34913 3.82602 2.36951 3.85608 2.39298C3.87111 2.40472 3.88552 2.41742 3.8974 2.43235C3.90929 2.44726 3.91858 2.46453 3.9224 2.48322C3.92628 2.50218 3.92434 2.52219 3.91773 2.54038C3.91112 2.55857 3.89991 2.57497 3.88596 2.58841C3.85807 2.61528 3.82003 2.62977 3.78169 2.63519C3.70501 2.64604 3.62746 2.62319 3.55005 2.62532C3.47168 2.62747 3.39514 2.65517 3.31681 2.6523C3.27763 2.65087 3.23791 2.6413 3.20572 2.61894C3.18962 2.60776 3.1756 2.59344 3.16547 2.57667C3.15533 2.5599 3.14916 2.54063 3.1485 2.52105C3.14787 2.50236 3.15223 2.4837 3.15969 2.46655C3.16716 2.44941 3.17766 2.43371 3.1895 2.41925C3.21318 2.39031 3.24214 2.36613 3.26701 2.33818Z"
          fill="url(#paint20_radial_1117_6515)"
        />
        <path
          d="M2.84375 2.82171C2.8453 2.82916 2.8481 2.83635 2.852 2.84289C2.85871 2.85413 2.8685 2.86323 2.87908 2.87093C2.88966 2.87862 2.90109 2.88503 2.91216 2.892C2.97102 2.929 3.01899 2.98101 3.06159 3.03594C3.11876 3.10964 3.16935 3.19143 3.24569 3.24501C3.29961 3.28284 3.36413 3.30441 3.42966 3.31099C3.50662 3.31872 3.58452 3.30631 3.65871 3.28446C3.72748 3.26422 3.79398 3.23579 3.855 3.19816C3.97099 3.12663 4.06786 3.02179 4.19576 2.97477C4.22368 2.96451 4.25275 2.95717 4.27966 2.94449C4.30657 2.93183 4.3319 2.91287 4.34477 2.88603C4.35713 2.86024 4.35665 2.83048 4.36067 2.80216C4.36498 2.77187 4.37463 2.74258 4.37995 2.71245C4.38524 2.68232 4.38587 2.6501 4.37285 2.62241C4.36206 2.59949 4.34245 2.58134 4.31989 2.56983C4.29732 2.55831 4.27193 2.55307 4.24663 2.55183C4.19602 2.54934 4.14605 2.56231 4.09559 2.5669C4.02876 2.573 3.96147 2.56443 3.89438 2.56642C3.81094 2.56891 3.72871 2.58773 3.64534 2.59176C3.55022 2.59637 3.4553 2.58171 3.36013 2.57786C3.31892 2.57619 3.27741 2.57657 3.23675 2.58354C3.1961 2.59052 3.15616 2.60432 3.12222 2.62775C3.08915 2.65059 3.06281 2.68174 3.03243 2.70806C3.01724 2.72123 3.00091 2.73326 2.98283 2.74204C2.96476 2.75082 2.94481 2.75627 2.92472 2.75584C2.91439 2.75561 2.90376 2.7539 2.89392 2.75703C2.88777 2.75899 2.8823 2.76275 2.87769 2.76727C2.87306 2.77178 2.86924 2.77703 2.86563 2.78239C2.85724 2.79486 2.84991 2.80803 2.84375 2.82171Z"
          fill="url(#paint21_radial_1117_6515)"
        />
        <path
          d="M3.21944 2.9242C3.20969 2.96446 3.21561 3.00829 3.23567 3.04452C3.25573 3.08076 3.28976 3.10903 3.32905 3.12213C3.37894 3.13875 3.43515 3.13061 3.48176 3.10628C3.50879 3.09217 3.53349 3.07233 3.54909 3.04615C3.55689 3.03305 3.5623 3.01847 3.56434 3.00337C3.56637 2.98827 3.56497 2.97264 3.55967 2.95834C3.55388 2.94267 3.54351 2.92887 3.53077 2.91808C3.51801 2.9073 3.5029 2.89947 3.48704 2.89423C3.45533 2.88373 3.42119 2.88353 3.38782 2.88514C3.33725 2.88757 3.28686 2.89392 3.23726 2.90413"
          fill="#E6B300"
        />
        <path
          d="M3.0525 2.61099C3.01872 2.63095 2.9862 2.65294 2.9542 2.67564C2.93763 2.68738 2.92055 2.70008 2.91182 2.7184C2.90563 2.7314 2.90421 2.74612 2.904 2.76051C2.90381 2.77489 2.90473 2.78936 2.90283 2.80362C2.90154 2.81336 2.89894 2.82295 2.8985 2.83276C2.89828 2.83766 2.89862 2.84263 2.89992 2.84737C2.90121 2.85212 2.9035 2.85664 2.90687 2.86021C2.91122 2.86483 2.91716 2.86767 2.92323 2.86953C2.9293 2.87137 2.93561 2.87234 2.94179 2.87379C2.97142 2.88076 2.99719 2.89893 3.01983 2.91929C3.04246 2.93964 3.06278 2.96255 3.08639 2.98175C3.15038 3.03381 3.23495 3.05536 3.31745 3.05672C3.39994 3.05808 3.4815 3.04081 3.56199 3.02268C3.6252 3.00844 3.68847 2.99356 3.74927 2.97114C3.84267 2.9367 3.92938 2.88458 4.00448 2.81925C4.03853 2.78963 4.07049 2.75711 4.1087 2.73311C4.14252 2.71188 4.1804 2.69786 4.2148 2.67756C4.21788 2.67574 4.22093 2.67387 4.22369 2.67159C4.22644 2.66931 4.22888 2.6666 4.23046 2.66339C4.23321 2.65788 4.23323 2.65126 4.23144 2.64535C4.22967 2.63944 4.22623 2.63415 4.22237 2.62935C4.21808 2.62404 4.21321 2.61923 4.20801 2.61479C4.17209 2.58405 4.12335 2.57287 4.0762 2.56912C4.02907 2.56539 3.98134 2.56802 3.9347 2.56024C3.89085 2.55291 3.84912 2.53656 3.80679 2.52298C3.76234 2.50872 3.71692 2.49744 3.67086 2.48992C3.56254 2.47224 3.451 2.47564 3.34354 2.49801C3.24119 2.51931 3.14248 2.55779 3.0525 2.61099Z"
          fill="#694200"
        />
        <path
          d="M3.04136 2.49684C2.9927 2.5296 2.94888 2.56956 2.91182 2.61501C2.89061 2.64102 2.87137 2.66926 2.86068 2.70106C2.85228 2.72607 2.84941 2.75256 2.84413 2.77841C2.84216 2.78809 2.83981 2.79781 2.83984 2.80769C2.83986 2.81264 2.84049 2.8176 2.84202 2.82229C2.84356 2.82699 2.84602 2.83143 2.84944 2.835C2.85511 2.84089 2.86307 2.8441 2.87109 2.84575C2.87911 2.84741 2.88733 2.84764 2.89545 2.84858C2.93214 2.85275 2.96593 2.87057 2.99636 2.89151C3.02677 2.91246 3.05481 2.93679 3.08601 2.95653C3.15496 3.00014 3.23721 3.01955 3.31873 3.02269C3.40026 3.02584 3.4816 3.01345 3.56162 2.99748C3.6252 2.98478 3.68858 2.96972 3.74891 2.94594C3.84128 2.90951 3.92455 2.85345 4.00411 2.79405C4.04026 2.76706 4.07589 2.73923 4.10834 2.70791C4.11936 2.69727 4.13001 2.68624 4.14182 2.67649C4.15363 2.66676 4.16674 2.65829 4.18132 2.65361C4.20353 2.64646 4.22762 2.64851 4.25064 2.65236C4.26798 2.65526 4.28535 2.65915 4.30291 2.65845C4.31169 2.6581 4.32049 2.65658 4.32864 2.65331C4.33679 2.65005 4.34431 2.64499 4.3499 2.63821C4.35725 2.6293 4.361 2.61766 4.36104 2.60612C4.36108 2.59457 4.35754 2.58314 4.35169 2.57319C4.33997 2.55329 4.3197 2.53982 4.2986 2.53044C4.26969 2.5176 4.23841 2.51124 4.20766 2.50381C4.1132 2.48099 4.02139 2.44729 3.93435 2.40408C3.89108 2.38259 3.849 2.35878 3.80643 2.33597C3.76266 2.31251 3.71804 2.28996 3.67051 2.27556C3.56424 2.24335 3.44809 2.25392 3.34319 2.29034C3.22664 2.33077 3.12127 2.40288 3.04136 2.49684Z"
          fill="url(#paint22_linear_1117_6515)"
        />
        <path
          d="M3.78964 2.52018C3.78393 2.51297 3.77574 2.50787 3.76691 2.50527C3.75807 2.50266 3.74863 2.50249 3.73956 2.50404C3.72141 2.50714 3.70514 2.51692 3.68998 2.52735C3.64688 2.55703 3.60879 2.59365 3.57554 2.63404C3.53152 2.68751 3.49521 2.74934 3.48304 2.81752C3.48099 2.82901 3.47963 2.84076 3.48106 2.85233C3.4825 2.86391 3.48693 2.87541 3.49516 2.8837C3.50237 2.89097 3.5122 2.8954 3.52235 2.89687C3.53249 2.89833 3.54292 2.89691 3.55264 2.89363C3.57206 2.88708 3.58817 2.87347 3.60345 2.8598C3.6876 2.78449 3.76368 2.69414 3.79412 2.58541C3.79718 2.57449 3.79977 2.56332 3.79969 2.552C3.79962 2.54068 3.79668 2.52907 3.78964 2.52018Z"
          fill="#FFDA37"
        />
        <path
          opacity="0.8"
          d="M3.58629 2.36407C3.59213 2.3828 3.62231 2.37969 3.63974 2.38867C3.65503 2.39655 3.66734 2.41383 3.68455 2.41433C3.70097 2.41479 3.72652 2.40863 3.72865 2.39236C3.73148 2.37085 3.70006 2.35718 3.67983 2.34929C3.65381 2.33915 3.62049 2.334 3.59608 2.34756C3.59049 2.35067 3.58439 2.35795 3.58629 2.36407Z"
          fill="url(#paint23_radial_1117_6515)"
        />
        <path
          opacity="0.8"
          d="M3.29974 2.35342C3.27733 2.34615 3.24028 2.38565 3.25237 2.40586C3.25569 2.4114 3.26586 2.41844 3.27271 2.41468C3.28302 2.409 3.29147 2.38872 3.30266 2.38076C3.31011 2.37545 3.30844 2.35626 3.29974 2.35342Z"
          fill="url(#paint24_radial_1117_6515)"
        />
        <path
          d="M4.37141 2.64884C4.36589 2.69807 4.32914 2.738 4.28934 2.738C4.24953 2.738 4.22174 2.69809 4.22726 2.64884C4.23278 2.59961 4.26952 2.55969 4.30933 2.55969C4.34912 2.55969 4.37692 2.59961 4.37141 2.64884Z"
          fill="url(#paint25_linear_1117_6515)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2.37827 4.16698) rotate(180) scale(0.556978 0.527409)"
        >
          <stop />
          <stop offset="1" stopOpacity="0.2489" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.26702 4.47167) scale(0.690868 0.527409)"
        >
          <stop />
          <stop offset="1" stopOpacity="0.2489" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.34333 4.35946) scale(0.273545 0.292475)"
        >
          <stop />
          <stop offset="1" stopOpacity="0.2489" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1117_6515"
          x1="5.40362"
          y1="6.76108"
          x2="5.4945"
          y2="7.45749"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1117_6515"
          x1="4.94884"
          y1="7.98509"
          x2="6.16161"
          y2="6.0188"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#422600" />
          <stop offset="1" stopColor="#BE7900" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1117_6515"
          x1="5.58162"
          y1="5.9384"
          x2="5.54595"
          y2="6.25943"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCD00" />
          <stop offset="1" stopColor="#FFCD00" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1117_6515"
          x1="1.14635"
          y1="6.3355"
          x2="2.3502"
          y2="8.3553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CA8300" />
          <stop offset="1" stopColor="#3B2100" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1117_6515"
          x1="2.482"
          y1="6.7825"
          x2="2.86099"
          y2="7.49589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8C400" />
          <stop offset="1" stopColor="#F8C400" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint8_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.55891 6.32039) rotate(14.9987) scale(0.575439 0.60404)"
        >
          <stop stopColor="#0B0200" />
          <stop offset="0.5907" stopColor="#BB5A00" stopOpacity="0.8" />
          <stop offset="1" stopColor="#FF9200" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.5595 6.18644) rotate(10.9625) scale(0.566166 0.443821)"
        >
          <stop stopColor="#0B0200" />
          <stop offset="0.5907" stopColor="#BB5A00" stopOpacity="0.8" />
          <stop offset="1" stopColor="#FF9200" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.97154 5.98939) scale(0.143783)"
        >
          <stop stopColor="#7D7D7D" />
          <stop offset="1" stopColor="#7D7D7D" stopOpacity="0.3294" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_1117_6515"
          x1="5.52107"
          y1="5.83918"
          x2="5.58806"
          y2="5.84197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7D7D7D" />
          <stop offset="1" stopColor="#7D7D7D" stopOpacity="0.3294" />
        </linearGradient>
        <radialGradient
          id="paint12_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.121 2.14956) rotate(-2.40933) scale(0.293178 0.448483)"
        >
          <stop stopColor="#FDFDFB" />
          <stop offset="0.7573" stopColor="#FDFDFB" />
          <stop offset="1" stopColor="#D4D4D4" />
        </radialGradient>
        <linearGradient
          id="paint13_linear_1117_6515"
          x1="3.05325"
          y1="1.9879"
          x2="3.20039"
          y2="2.25097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#767675" stopOpacity="0" />
          <stop offset="0.2629" stopColor="#767675" />
          <stop offset="0.5" stopColor="#767675" />
          <stop offset="1" stopColor="#767675" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint14_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.0636 1.84385) scale(0.256303)"
        >
          <stop stopColor="#D4D4D4" />
          <stop offset="1" stopColor="#7D7D7D" />
        </radialGradient>
        <linearGradient
          id="paint15_linear_1117_6515"
          x1="3.06231"
          y1="1.54556"
          x2="3.40028"
          y2="1.87104"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#646464" stopOpacity="0" />
          <stop offset="0.3063" stopColor="#646464" stopOpacity="0.5825" />
          <stop offset="0.47" stopColor="#646464" />
          <stop offset="0.7283" stopColor="#646464" stopOpacity="0.2573" />
          <stop offset="1" stopColor="#646464" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint16_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.04839 2.16598) rotate(-1.80935) scale(0.381363 0.438332)"
        >
          <stop stopColor="#FDFDFB" />
          <stop offset="0.7573" stopColor="#FDFDFB" />
          <stop offset="1" stopColor="#D4D4D4" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_1117_6515"
          x1="4.03763"
          y1="2.00778"
          x2="4.20706"
          y2="2.20842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#949494" stopOpacity="0.3922" />
          <stop offset="0.5" stopColor="#949494" />
          <stop offset="1" stopColor="#949494" stopOpacity="0.3922" />
        </linearGradient>
        <radialGradient
          id="paint18_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.09139 1.88495) scale(0.31824)"
        >
          <stop stopColor="#D4D4D4" />
          <stop offset="1" stopColor="#7D7D7D" />
        </radialGradient>
        <linearGradient
          id="paint19_linear_1117_6515"
          x1="4.09261"
          y1="1.53043"
          x2="4.45153"
          y2="1.86929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#646464" stopOpacity="0" />
          <stop offset="0.3063" stopColor="#646464" stopOpacity="0.5825" />
          <stop offset="0.47" stopColor="#646464" />
          <stop offset="0.7283" stopColor="#646464" stopOpacity="0.2573" />
          <stop offset="1" stopColor="#646464" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint20_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.44499 2.38447) rotate(-36.0117) scale(0.334689 0.30378)"
        >
          <stop />
          <stop offset="0.7345" />
          <stop offset="1" stopColor="#5C5C5C" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.80312 2.69007) rotate(-22.4275) scale(0.794751 0.558065)"
        >
          <stop stopColor="#E59400" />
          <stop offset="0.7514" stopColor="#EB9C00" />
          <stop offset="0.8658" stopColor="#C87F00" />
          <stop offset="1" stopColor="#A46100" />
        </radialGradient>
        <linearGradient
          id="paint22_linear_1117_6515"
          x1="2.87021"
          y1="2.64421"
          x2="4.29477"
          y2="2.63306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BE7900" />
          <stop offset="0.1197" stopColor="#EA9E00" />
          <stop offset="0.2551" stopColor="#FFB800" />
          <stop offset="0.3919" stopColor="#F7C800" />
          <stop offset="0.5274" stopColor="#FFD815" />
          <stop offset="0.7691" stopColor="#FFD800" />
          <stop offset="1" stopColor="#FFCD04" />
        </linearGradient>
        <radialGradient
          id="paint23_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.64736 2.37614) scale(0.0814702 0.0475215)"
        >
          <stop stopColor="#3D2400" />
          <stop offset="0.5547" stopColor="#7E5100" />
          <stop offset="1" stopColor="#B78C00" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_1117_6515"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.28203 2.37942) scale(0.0387177 0.0416255)"
        >
          <stop stopColor="#3D2400" />
          <stop offset="0.5547" stopColor="#7E5100" />
          <stop offset="1" stopColor="#B78C00" />
        </radialGradient>
        <linearGradient
          id="paint25_linear_1117_6515"
          x1="4.3011"
          y1="2.60284"
          x2="4.32276"
          y2="2.70354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCE00" />
          <stop offset="1" stopColor="#EA9B00" />
        </linearGradient>
        <clipPath id="clip0_1117_6515">
          <rect
            width="7.49271"
            height="7.49271"
            fill="white"
            transform="translate(0 0.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LinuxSvg;
