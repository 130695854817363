import React from 'react';
import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem, Chip } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import OutlinedButton from '../../../components/base/OutlinedButton';
import useAwaitModal from '../../../hooks/useAwaitModal';
import RemoteRunsModal from './RemoteRunsModal';
import { useParams } from 'react-router-dom';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import { useTheme } from '@emotion/react';

const RunTestMenu = ({ events, startPlayback, url, protocol, setProtocol, browser, setUrl }) => {
  const { testId } = useParams();
  const { menuProps, triggerProps } = useMenu();
  const theme = useTheme();

  const { isFeatureEnabled: enabledRemoteExecutionFeature } = useFeatureEnabled('remote-execution');

  const [
    requestRemoteRunModal,
    { open: openRemoteRunModal, onClose: onCloseRemoteRunModal, onComplete: completeRemoteRunModal }
  ] = useAwaitModal();

  return (
    <Box>
      <OutlinedButton
        disabled={!events.length}
        {...triggerProps}
        sx={{
          borderTopLeftRadius: '0rem',
          borderBottomLeftRadius: '0rem',
          borderTopRightRadius: '0.4rem',
          borderBottomRightRadius: '0.4rem',
          minWidth: '25px',
          borderLeft: 'none',
          ml: -1,
          px: '0.2rem',
          minHeight: '33px'
        }}>
        <ArrowDropDownIcon sx={{ color: theme.palette.text.black_grey }} />
      </OutlinedButton>
      <Menu id="action-menu" {...menuProps} elevation={1} PaperProps={{ style: { width: 'max-content' } }}>
        <MenuItem
          divider
          sx={{ color: theme.palette.text.black_grey }}
          onClick={() => {
            startPlayback();
            menuProps.onClose();
          }}>
          Run locally
        </MenuItem>
        <MenuItem
          disabled={!testId || !enabledRemoteExecutionFeature}
          sx={{ color: theme.palette.text.black_grey }}
          onClick={() => {
            requestRemoteRunModal();
            menuProps.onClose();
          }}>
          Run remotely{' '}
          {!enabledRemoteExecutionFeature && (
            <Chip label="Premium" color="primary" sx={{ fontSize: '0.7rem', ml: 1 }} size={'small'} />
          )}
        </MenuItem>
      </Menu>

      <RemoteRunsModal
        open={openRemoteRunModal}
        onClose={onCloseRemoteRunModal}
        onComplete={completeRemoteRunModal}
        url={url}
        protocol={protocol}
        setProtocol={setProtocol}
        browserType={browser}
        setUrl={setUrl}
      />
    </Box>
  );
};

export default RunTestMenu;
