import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useParams, Link } from 'react-router-dom';
import { Box, Menu, Grid, Typography, Divider, MenuItem, Tooltip } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { formatDate } from '../../../utils/formateDate';
import useAwaitModal from '../../../hooks/useAwaitModal';
import TestOverviewModal from './TestOverviewModal';
import { useContext } from 'react';
import { ColorContext } from '../../../AppTheme';
import { useTheme } from '@emotion/react';

const formatTimeFromTimestamp = (timestamp) => {
  if (!timestamp) return;
  const dateObj = new Date(timestamp);

  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const formatDateFromTimestamp = (timestamp) => {
  if (!timestamp) return;
  const dateObj = new Date(timestamp);

  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const TestDetails = ({
  os,
  browser,
  savedTest,
  previousRun,
  testInfo,
  silentRefetchTest,
  url,
  title
}) => {
  const { triggerProps, menuProps } = useMenu();
  const { projectId, testId, batchId } = useParams();
  const theme = useTheme();
  const { mode } = useContext(ColorContext)
  const iconColor = mode === 'dark' ? '#5e6062' : '#24C3D9';

  const [requestTestOverviewModal, { open: testOverviewOpen, onClose: onCloseTestOverview }] =
    useAwaitModal();

  return (
    <div>
      <IconButton
        size={'small'}
        aria-controls={menuProps.open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuProps.open ? 'true' : undefined}
        {...triggerProps}>
        <MoreVertIcon sx={{ color: theme.palette.svg.primary }} />
      </IconButton>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            maxWidth: 250
          },
          sx: {
            background: mode === 'light' && '#F6F6F6',
            py: 0,
            zIndex: 1300
          }
        }}>
        <Box py={1} sx={{ bgcolor: '', my: 0 }}>
          <Grid container spacing={1} px={2}>
            <Grid item xs={5}>
              <Typography variant="body2">Created By</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                textTransform={'capitalize'}
                fontWeight={'medium'}
                variant="body2"
                sx={{ wordWrap: 'break-word' }}>
                {testInfo?.data?.creator?.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Date Created</Typography>
            </Grid>
            <Grid item xs={7}>
              <Tooltip title={formatDate(testInfo?.data?.created_at)}>
                <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {formatDateFromTimestamp(testInfo?.data?.created_at)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Last Modified</Typography>
            </Grid>
            <Grid item xs={7}>
              <Tooltip title={formatDate(testInfo?.data?.updated_at)}>
                <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {formatDateFromTimestamp(testInfo?.data?.updated_at)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Last Run</Typography>
            </Grid>
            <Grid item xs={7}>
              <Tooltip title={formatDate(testInfo?.data?.last_run_at)}>
                <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                  {formatDateFromTimestamp(testInfo?.data?.last_run_at)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1.5 }} />
          {batchId && (
            <>
              <Grid container spacing={1} px={2} py={1}>
                <Grid item xs={5}>
                  <Typography variant="body2">Source</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    textTransform={'capitalize'}
                    fontWeight={'medium'}
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}>
                    {previousRun?.source}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">Run Config</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {`${testInfo?.data?.width}px X ${testInfo?.data?.height}px`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">OS</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {previousRun?.operating_system} {previousRun?.operating_system_version}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">Browser</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {previousRun?.browser} {previousRun?.browser_version}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">Date (UTC)</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {formatDateFromTimestamp(previousRun?.started_at)}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">Time (UTC)</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                    {formatTimeFromTimestamp(previousRun?.started_at)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
            </>
          )}
          {/* <MenuItem sx={{ color: '#1958B8', py: 0.5 }}>View console log</MenuItem> */}
          {/* <MenuItem sx={{ color: '#1958B8', py: 0.5 }}>View change history</MenuItem> */}
          <MenuItem
            component={Link}
            disabled={!testId}
            to={`/projects/${projectId}/test-results/${testId}?page=1`}
            sx={{ color: '#1958B8', py: 0.5 }}>
            View runs history
          </MenuItem>
          <MenuItem
            disabled={!testId}
            onClick={() => {
              requestTestOverviewModal();
              menuProps.onClose();
            }}
            sx={{ color: '#1958B8', py: 0.5 }}>
            Test overview
          </MenuItem>
        </Box>
      </Menu>

      <TestOverviewModal
        open={testOverviewOpen}
        onClose={onCloseTestOverview}
        testInfo={testInfo?.data}
        silentRefetchTest={silentRefetchTest}
        url={url}
        title={title}
      />
    </div>
  );
};

export default TestDetails;
