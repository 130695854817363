import React from 'react';
import Box from '@mui/material/Box';
import ContainedButton from '../../../components/base/ContainedButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';

export const getProtocolFromString = (str) => {
  const match = str.match(/(http(s?)):\/\//i);
  if (!match) return undefined;
  return match[0];
};

const ProtocolMenu = ({ protocol, setProtocol }) => {
  const { menuProps, triggerProps } = useMenu();

  return (
    <Box>
      <ContainedButton
        {...triggerProps}
        size={'small'}
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          color: '#FFF',
          height: '100%',
          pr: 1,
          py: 0.5,
          fontSize: 12,
          minHeight: '28px'
        }}
        color={'secondary'}>
        {protocol}
        <ArrowDropDownIcon ml={1} color={'inherit'} />
      </ContainedButton>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{ style: { width: 150 } }}>
        <MenuItem
          onClick={() => {
            setProtocol('https://');
            menuProps.onClose();
          }}>
          https://
        </MenuItem>
        <MenuItem
          onClick={() => {
            setProtocol('http://');
            menuProps.onClose();
          }}>
          http://
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProtocolMenu;
