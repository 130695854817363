import { SvgIcon } from '@mui/material';

const WebSvg = ({ width = 15, height = 14, disabled, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 15 14'} {...props} fill={'none'}>
      <path
        d="M2.76451 2.98035C3.03098 2.98035 3.24699 2.76433 3.24699 2.49786C3.24699 2.2314 3.03098 2.01538 2.76451 2.01538C2.49804 2.01538 2.28202 2.2314 2.28202 2.49786C2.28202 2.76433 2.49804 2.98035 2.76451 2.98035Z"
        fill="#24C3D9"
      />
      <path
        d="M4.37306 2.98035C4.63953 2.98035 4.85555 2.76433 4.85555 2.49786C4.85555 2.2314 4.63953 2.01538 4.37306 2.01538C4.1066 2.01538 3.89058 2.2314 3.89058 2.49786C3.89058 2.76433 4.1066 2.98035 4.37306 2.98035Z"
        fill="#24C3D9"
      />
      <path
        d="M5.9812 2.98035C6.24767 2.98035 6.46368 2.76433 6.46368 2.49786C6.46368 2.2314 6.24767 2.01538 5.9812 2.01538C5.71473 2.01538 5.49872 2.2314 5.49872 2.49786C5.49872 2.76433 5.71473 2.98035 5.9812 2.98035Z"
        fill="#24C3D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0226 7.64435V2.17621C14.0226 1.02147 13.0866 0.0854492 11.9318 0.0854492C9.46022 0.0854492 4.75376 0.0854492 2.28217 0.0854492C1.12742 0.0854492 0.191406 1.02147 0.191406 2.17621V11.8259C0.191406 12.9806 1.12742 13.9166 2.28217 13.9166H7.10699C7.37332 13.9166 7.58948 13.7005 7.58948 13.4341C7.58948 13.1678 7.37332 12.9517 7.10699 12.9517H2.28217C1.66008 12.9517 1.15637 12.4479 1.15637 11.8259V2.17621C1.15637 1.55413 1.66008 1.05041 2.28217 1.05041H11.9318C12.5539 1.05041 13.0576 1.55413 13.0576 2.17621V7.64435C13.0576 7.91068 13.2738 8.12683 13.5401 8.12683C13.8064 8.12683 14.0226 7.91068 14.0226 7.64435Z"
        fill="#24C3D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5401 3.94531H0.673889C0.407559 3.94531 0.191406 4.16146 0.191406 4.42779C0.191406 4.69413 0.407559 4.91028 0.673889 4.91028H13.5401C13.8064 4.91028 14.0226 4.69413 14.0226 4.42779C14.0226 4.16146 13.8064 3.94531 13.5401 3.94531Z"
        fill="#24C3D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1906 13.1575L11.839 11.2276C11.6866 11.0095 11.3855 10.9561 11.1674 11.1092C10.9493 11.2617 10.8959 11.5628 11.0491 11.7808L12.4 13.7108C12.5531 13.9288 12.8542 13.9822 13.0723 13.8291C13.2903 13.6767 13.3437 13.3756 13.1906 13.1575Z"
        fill="#24C3D9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.583 9.62832C12.4842 8.99401 10.4945 7.84635 9.42078 7.22684C9.12486 7.05636 8.75752 7.07244 8.47768 7.26801C8.19849 7.46358 8.0576 7.80389 8.11679 8.1397C8.33165 9.3607 8.72986 11.6232 8.94987 12.8725C9.0097 13.2154 9.26767 13.4908 9.60605 13.5731C9.94443 13.6561 10.2995 13.5313 10.5118 13.2547L11.6402 11.784L13.408 11.2269C13.7406 11.1221 13.9793 10.8307 14.0172 10.4846C14.0552 10.1385 13.8847 9.80201 13.583 9.62832ZM12.93 10.3655L11.2047 10.9098C11.1101 10.9394 11.0271 10.9973 10.9673 11.0758L9.86595 12.5116L9.09912 8.15513L12.93 10.3655Z"
        fill="#24C3D9"
      />
    </SvgIcon>
  );
};

export default WebSvg;
