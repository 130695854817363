import { rgba } from '../../../utils/colors';
import PassedSvg from '../../../components/svg/PassedSvg';
import SkippedSvg from '../../../components/svg/SkippedSvg';
import FailedSvg from '../../../components/svg/FailedSvg';
import { statusColors, statusMap } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import useAwaitModal from '../../../hooks/useAwaitModal';
import CreateFailureTag from '../../TestRuns/components/CreateFailureTagModal';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../../mixpanel.constants';

export const processString = (inputString) => {
  if (!inputString) return null;

  let lowercaseString = inputString.toLowerCase();
  lowercaseString = lowercaseString.replace(/,/g, '');

  const words = lowercaseString.split(' ');
  const joinedString = words.join('-');

  return joinedString;
};

export const StatusBadge = ({
  status,
  error,
  errorStep,
  onViewDetails,
  index,
  failureTag,
  refetchPreviousRuns,
  sessionId
}) => {
  const { batchId, testId } = useParams();
  const theme = useTheme();
  const _status = status in statusMap ? statusMap[status] : status;
  const iconMap = {
    passed: <PassedSvg />,
    failed: <FailedSvg />,
    skipped: <SkippedSvg />
  };

  const [
    requestFailureTagModal,
    {
      open: openFailureTagModal,
      onClose: onCloseFailureTagModal,
      onComplete: completeFailureTagModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const linkText = processString(error);

  const style = {
    cursor: 'pointer',
    textDecoration: 'underline',
    bgcolor: 'transparent',
    outline: 'none',
    border: 'none',
    fontSize: '0.85rem',
    px: 1
  };

  return (
    <Box>
      <Flex
        alignItems={'center'}
        justifyContent={'flex-start'}
        backgroundColor={rgba(statusColors[_status], 15)}
        px={1}
        py={1}
        sx={{
          border: `1px solid ${statusColors[_status]}`,
          borderRadius: '4px'
        }}>
        {iconMap[_status] || null}
        <Typography
          color={statusColors[_status]}
          textTransform={'none'}
          fontWeight={500}
          lineHeight={1}>
          {_status === 'failed' && error ? `Step ${index + 1} failed : ${error}` : _status}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography
          as={'button'}
          onClick={() => {
            onViewDetails();
            trackMixPanel(TRACKING_IDS.SEE_ERROR_CLICKED, {
              position: 'Error Banner'
            });
          }}
          sx={{
            ...style,
            color: theme.palette.text.black_white
          }}>
          See error
        </Typography>
        <Divider orientation="vertical" flexItem />
        {testId && (sessionId || batchId) && (
          <>
            <Typography
              onClick={() =>
                requestFailureTagModal({
                  testData: failureTag
                })
              }
              sx={{
                ...style,
                textTransform: 'capitalize'
              }}>
              {failureTag?.failure_type === 'new ui change'
                ? 'New UI Change'
                : failureTag?.failure_type || 'Tag test failure'}
            </Typography>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <Typography
          as={'a'}
          href={`https://docs.getscandium.com/why-did-my-test-fail#${linkText}`}
          onClick={() => {
            trackMixPanel(TRACKING_IDS.GET_HELP_CLICKED, {
              position: 'Error Banner'
            });
          }}
          target={'_blank'}
          sx={{
            ...style,
            color: theme.palette.text.black_white
          }}>
          Get help
        </Typography>
        <Divider orientation="vertical" flexItem />
      </Flex>
      <CreateFailureTag
        open={openFailureTagModal}
        onClose={onCloseFailureTagModal}
        onComplete={completeFailureTagModal}
        refetchRuns={refetchPreviousRuns}
        sessionId={sessionId}
        {...otherProps}
      />
    </Box>
  );
};
