import { useState } from 'react';
import Flex from '../../../../components/base/Flex';
import TextButton from '../../../../components/base/TextButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import Select from '@mui/material/Select';
import KeyPressSvg from '../../../../components/svg/KeyPressSvg';
import { useMenu } from '../../../../components/base/useMenu';

const SelectKey = ({ orientation, session, platform, theme, isRecordingMode }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const { triggerProps, menuProps } = useMenu();

  const handleKeyPress = async (key) => {
    await session.keypress(key);
  };

  const handleKeyOnChange = (event) => {
    handleKeyPress(event.target.value);
    setSelectedOption(event.target.value);
  };

  const androidOptions = [
    { value: 'VOLUME_UP', label: 'Volume up' },
    { value: 'VOLUME_DOWN', label: 'Volume down' },
    { value: 'ANDROID_KEYCODE_MENU', label: 'Keycode menu' }
  ];

  const anchorOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'right' }
      : { vertical: 'bottom', horizontal: 'left' };

  const transformOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'left' }
      : { vertical: 'top', horizontal: 'left' };

  return (
    <Box>
      <TextButton
        sx={{ py: 1 }}
        {...triggerProps}
        disabled={!session || !isRecordingMode}
        aria-controls="simple-menu"
        aria-haspopup="true">
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <KeyPressSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Key Press
          </Typography>
        </Flex>
      </TextButton>
      <Menu
        id="simple-menu"
        {...menuProps}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        elevation={1}
        PaperProps={{
          style: {
            width: 250
          }
        }}>
        <FormControl fullWidth size={'small'}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Select"
            value={selectedOption}
            displayEmpty
            renderValue={selectedOption !== '' ? undefined : () => 'Select'}
            MenuProps={{
              elevation: 1
            }}
            sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
            label="Age"
            onChange={handleKeyOnChange}>
            <MenuItem value={'HOME'}>Home</MenuItem>
            {platform === 'android' &&
              androidOptions.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Menu>
    </Box>
  );
};

export default SelectKey;
