const ChromeSvg = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1117_6507)">
        <path
          d="M7.21952 2.95226H3.7479C2.972 2.95149 2.3424 3.57986 2.34162 4.35575C2.34139 4.59674 2.40314 4.83375 2.52096 5.04397L0.785156 2.06561C2.04945 0.429289 4.40087 0.127707 6.0372 1.392C6.5627 1.79803 6.97076 2.33652 7.21952 2.95226Z"
          fill="#F44336"
        />
        <path
          d="M7.492 4.35716C7.49027 6.4255 5.81398 8.10179 3.74564 8.10351C3.58899 8.1041 3.43251 8.09366 3.27734 8.0723L4.9632 5.0596C5.34959 4.38695 5.11926 3.52849 4.44808 3.13959C4.23558 3.01656 3.9943 2.95192 3.74876 2.95227H7.21725C7.39957 3.39818 7.4929 3.87543 7.492 4.35716Z"
          fill="#FFC107"
        />
        <path
          d="M4.96422 5.05952L3.27836 8.07222H3.27524C1.2237 7.81302 -0.22927 5.93978 0.0299315 3.88824C0.113829 3.22421 0.374187 2.59481 0.783915 2.06555L2.51972 5.04391L2.52909 5.05952C2.91618 5.73196 3.77509 5.96328 4.44753 5.57621C4.66233 5.45257 4.84059 5.27431 4.96422 5.05952Z"
          fill="#4CAF50"
        />
        <path
          d="M4.96393 5.05961C4.57684 5.73205 3.71793 5.96338 3.04549 5.5763C2.83071 5.45266 2.65245 5.2744 2.5288 5.05961L2.51944 5.044C2.1401 4.36715 2.3813 3.51095 3.05815 3.13161C3.26839 3.01379 3.50539 2.95204 3.74637 2.95227H3.74948C3.99503 2.95192 4.23631 3.01656 4.44881 3.13959C5.12001 3.52851 5.35032 4.38697 4.96393 5.05961Z"
          fill="#F44336"
        />
        <path
          d="M4.96393 5.05961C4.57684 5.73205 3.71793 5.96338 3.04549 5.5763C2.83071 5.45266 2.65245 5.2744 2.5288 5.05961L2.51944 5.044C2.1401 4.36715 2.3813 3.51095 3.05815 3.13161C3.26839 3.01379 3.50539 2.95204 3.74637 2.95227H3.74948C3.99503 2.95192 4.23631 3.01656 4.44881 3.13959C5.12001 3.52851 5.35032 4.38697 4.96393 5.05961Z"
          fill="#2196F3"
        />
        <path
          d="M3.74043 5.9181C3.46826 5.91807 3.2009 5.84635 2.96524 5.71018C2.72762 5.57275 2.53028 5.3753 2.39298 5.13762C1.96193 4.39101 2.21774 3.43633 2.96435 3.00528C3.2019 2.86813 3.47142 2.796 3.74574 2.79614C4.60784 2.79547 5.30727 3.49381 5.30793 4.35592C5.30815 4.63034 5.236 4.89996 5.09879 5.13762C4.81746 5.62128 4.29995 5.91863 3.74043 5.9181ZM3.74854 3.10833C3.30054 3.10595 2.88578 3.34438 2.6624 3.73273C2.31756 4.33069 2.52275 5.09499 3.1207 5.43983C3.71865 5.78467 4.48295 5.57948 4.82779 4.98153C5.17264 4.38425 4.96799 3.6205 4.37071 3.27565C4.18161 3.16648 3.96719 3.10879 3.74884 3.10835L3.74854 3.10833Z"
          fill="#FAFAFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1117_6507">
          <rect
            width="7.49305"
            height="7.49305"
            fill="white"
            transform="translate(0 0.610474)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChromeSvg;
