import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { StatusTag, StyledTableCell, StyledTableRow } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import ProjectSvg from '../../../components/svg/ProjectSvg';
import ExecutionEnv from './ExecutionEnv';
import { formatDate } from '../../../utils/formateDate';
import React, { useContext } from 'react';
import { getDuration } from '../../../utils/time';
import { useParams, Link } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import { useCookies } from 'react-cookie';
import MobileExecutionEnv from '../../Mobile/components/MobileExecutionEnv';
import ActionMenu from './ActionMenu';
import useAwaitModal from '../../../hooks/useAwaitModal';
import CreateFailureTag from './CreateFailureTagModal';
import { ColorContext } from '../../../AppTheme';
import { useTheme } from '@emotion/react';

export const getTestStatus = (results) => {
  return results.some((result) => result.status === 'error') ? 'failed' : 'passed';
};

const ResultsTable = ({ testRuns, testData, refetchRuns }) => {
  const { mode } = useContext(ColorContext);
  const [cookies] = useCookies(['name', 'token', 'email', 'projectType']);
  const { testId } = useParams();
  const activeProject = useActiveProject();
  const theme = useTheme();

  const projectType = activeProject?.type || cookies?.projectType || 'web';
  const isMobile = projectType === 'mobile';

  const [
    requestFailureTagModal,
    {
      open: openFailureTagModal,
      onClose: onCloseFailureTagModal,
      onComplete: completeFailureTagModal,
      ...otherProps
    }
  ] = useAwaitModal();

  return (
    <Box>
      <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
        <Table sx={{ width: '100%', minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {!testData && <StyledTableCell>Test Case</StyledTableCell>}
              <StyledTableCell>Execution Environment</StyledTableCell>
              <StyledTableCell>Started</StyledTableCell>
              <StyledTableCell>{!isMobile ? 'Failure Tag' : 'Finished'}</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              <StyledTableCell align={'center'}>
                {!!testData ? 'Status' : 'Last run'}
              </StyledTableCell>
              {testId && <StyledTableCell></StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(testRuns || []).map((row, index) => (
              <StyledTableRow
                tabIndex={0}
                key={index}
                component={Link}
                sx={{ textDecoration: 'none' }}
                to={{
                  pathname: !testId
                    ? `/projects/${activeProject?.id}/${
                        projectType === 'web' ? 'test-results' : 'mobile-test-runs'
                      }/${row.id}`
                    : `/projects/${activeProject?.id}/folders/tests/${testId}/runs/${row?.batchId}`,
                  search: !testId ? '?page=1' : '?limit=50'
                }}>
                {!testData && (
                  <StyledTableCell component="th" scope="row">
                    <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
                      <ProjectSvg
                        width={24}
                        height={24}
                        fill={theme.palette.svg.primary}
                        color={'primary'}
                      />
                      <Typography
                        fontWeight={500}
                        color={mode === 'dark' ? '#fff' : 'black'}
                        sx={{
                          lineHeight: 1,
                          mt: '4px',
                          maxWidth: '300px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}>
                        {row.name}
                      </Typography>
                    </Flex>
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  <Flex className={'cell-content-wrapper'} columnGap={0.5}>
                    {!isMobile && (
                      <ExecutionEnv
                        browser={
                          row.last_run_result?.browser?.toLowerCase() || row?.browser?.toLowerCase()
                        }
                        os={
                          row.last_run_result?.operating_system?.toLowerCase() ||
                          row.operating_system?.toLowerCase() ||
                          testData?.operating_system?.toLowerCase()
                        }
                      />
                    )}

                    {isMobile && (
                      <MobileExecutionEnv
                        platform={row.last_run_result?.platform?.toLowerCase()}
                        device={row.last_run_result?.device_name?.toLowerCase()}
                      />
                    )}
                  </Flex>
                </StyledTableCell>
                <StyledTableCell>
                  <Box className={'cell-content-wrapper'}>
                    {formatDate(
                      row.started_at ||
                        row.last_run_result?.started_at ||
                        testData?.last_run_result?.started_at
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box className={'cell-content-wrapper'}>
                    {isMobile ? (
                      formatDate(
                        row.finished_at ||
                          row.last_run_result?.finished_at ||
                          testData?.last_run_result?.finished_at
                      )
                    ) : (
                      <Typography variant={'body2'} sx={{ textTransform: 'capitalize' }}>
                        {row.last_run_result?.failure_type === 'new ui change'
                          ? 'New UI Change'
                          : row.last_run_result?.failure_type || row?.failure_type}
                      </Typography>
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box className={'cell-content-wrapper'}>
                    {getDuration(
                      row.duration ||
                        row.last_run_result?.duration ||
                        testData?.last_run_result?.duration ||
                        0
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Flex className={'cell-content-wrapper'} justifyContent={'center'}>
                    <StatusTag status={row?.last_run_result?.status || row?.status} />
                  </Flex>
                </StyledTableCell>
                {!isMobile && testId && (
                  <StyledTableCell align="right">
                    <Box className={'cell-content-wrapper'}>
                      {row.status === 'error' ? (
                        <ActionMenu
                          onClickCreateTag={() =>
                            requestFailureTagModal({
                              testData: row
                            })
                          }
                        />
                      ) : null}
                    </Box>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateFailureTag
        open={openFailureTagModal}
        onClose={onCloseFailureTagModal}
        onComplete={completeFailureTagModal}
        refetchRuns={refetchRuns}
        {...otherProps}
      />
    </Box>
  );
};

export default ResultsTable;
