import { SvgIcon } from '@mui/material';

const GithubSvg = ({ width = 33, height = 33, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} fill="none" viewBox="0 0 23 23" {...props}>
      <path
        d="M11.4986 0C5.14913 0 0 5.16925 0 11.546C0 16.6462 3.29475 20.9731 7.866 22.5012C8.441 22.6076 8.65088 22.2511 8.65088 21.9449C8.65088 21.6703 8.64081 20.9444 8.63506 19.9812C5.43663 20.6784 4.761 18.4331 4.761 18.4331C4.23919 17.0991 3.4845 16.744 3.4845 16.744C2.43944 16.0281 3.56212 16.0425 3.56212 16.0425C4.71644 16.1244 5.32306 17.2327 5.32306 17.2327C6.34944 18.9966 8.0155 18.4877 8.671 18.1916C8.7745 17.4455 9.07206 16.9366 9.40125 16.6477C6.84825 16.3559 4.163 15.3654 4.163 10.9423C4.163 9.68156 4.6115 8.65088 5.3475 7.843C5.22819 7.55119 4.83431 6.37675 5.45963 4.78831C5.45963 4.78831 6.42563 4.47781 8.62213 5.97138C9.53925 5.7155 10.5225 5.58756 11.5014 5.58325C12.4775 5.589 13.4622 5.7155 14.3807 5.97281C16.5758 4.47925 17.5404 4.78975 17.5404 4.78975C18.1671 6.37962 17.7732 7.55263 17.6554 7.84444C18.3928 8.65231 18.837 9.683 18.837 10.9437C18.837 15.3784 16.1489 16.3544 13.5872 16.6405C13.9998 16.997 14.3678 17.7014 14.3678 18.7781C14.3678 20.3219 14.3534 21.5668 14.3534 21.9449C14.3534 22.2539 14.5604 22.6133 15.1441 22.4998C19.7081 20.9702 23 16.6448 23 11.546C23 5.16925 17.8509 0 11.4986 0Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default GithubSvg;
