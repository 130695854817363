import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomInput from '../../components/customInput';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ProjectContext from '../../store/projectState';
import ContainedButton from '../../components/base/ContainedButton';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import BackButton from '../../components/base/BackButton';
import ProjectType from './components/ProjectType';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';

function NewProject() {
  const { projectId } = useParams();

  useDocumentTitle(projectId ? 'Edit Project' : 'New Project');
  const { fetchProjects } = useContext(ProjectContext);
  let navigate = useNavigate();
  const { isFeatureEnabled: isMobileTestingEnabled } = useFeatureEnabled('mobile-app-testing');

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [purpose, setPurpose] = useState('');
  const [projectType, setProjectType] = useState('web');
  const [tag, setTag] = useState('');

  const { isLoading: isFetchingProjects } = useScandiumQuery(`/projects/${projectId}`, {
    onSuccess: (data) => {
      setName(data.project.name);
      setDescription(data.project.description);
      setPurpose(data.project.purpose);
      setProjectType(data.project.type);
      setTag(data.project.tags?.[0]?.name?.en || '');
    },
    enabled: !!projectId
  });

  const { mutateAsync: saveProject, isLoading } = useScandiumMutation(
    `/projects/${projectId || ''}`,
    {
      method: projectId ? 'PUT' : 'POST',
      onSuccess: (data) => {
        toast.success(data.message);
        navigate(projectId ? '/projects' : `/projects/${data.project.id}/dashboard`);
        fetchProjects();

        if (!projectId) {
          trackMixPanel(TRACKING_IDS.PROJECT_CREATED, {
            type: projectType
          });
        }
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  const { data: tags = [], isLoading: isFetchingTags } = useScandiumQuery(`/tags`, {
    select: (data) => data.data
  });

  const handleCreate = async (event) => {
    event.preventDefault();

    await saveProject({ name, description, purpose, type: projectType, tag });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start'
      }}>
      <Box
        as={'form'}
        onSubmit={handleCreate}
        sx={{
          width: { xs: '100%', sm: '50%' }
        }}>
        <BackButton label={'Back'} onClick={() => navigate('..')} />
        <Typography
          color="primary"
          sx={{
            textAlign: 'left',
            fontSize: '1.1rem',
            mt: { xs: 4, sm: 4 }
          }}>
          {projectId ? 'Update project' : 'Create project'}
        </Typography>
        <CustomInput
          required
          onChange={(event) => {
            setName(event.target.value);
          }}
          label="Name of Project"
          formHelperText=" "
          value={name}
          size={'small'}
          formControlStyle={{
            mt: 1,
            width: '100%'
          }}
          endAdornment={
            (isFetchingProjects && (
              <InputAdornment position="end">
                <CircularProgress size={16} color={'secondary'} />
              </InputAdornment>
            )) ||
            undefined
          }
        />
        <CustomInput
          required
          onChange={(event) => {
            setDescription(event.target.value);
          }}
          label="Description of Project"
          formHelperText=" "
          value={description}
          size={'small'}
          formControlStyle={{
            mt: 1,
            width: '100%'
          }}
          endAdornment={
            (isFetchingProjects && (
              <InputAdornment position="end">
                <CircularProgress size={16} color={'secondary'} />
              </InputAdornment>
            )) ||
            undefined
          }
        />
        <CustomInput
          required
          onChange={(event) => {
            setPurpose(event.target.value);
          }}
          label="Purpose of Project"
          formHelperText=" "
          value={purpose}
          size={'small'}
          formControlStyle={{
            mt: 1,
            width: '100%'
          }}
          endAdornment={
            (isFetchingProjects && (
              <isFetchingProjects position="end">
                <CircularProgress size={16} color={'secondary'} />
              </isFetchingProjects>
            )) ||
            undefined
          }
        />
        <Box mb={1}>
          <ProjectType
            value={projectType}
            setValue={setProjectType}
            disabled={!isMobileTestingEnabled}
          />
        </Box>
        <Autocomplete
          inputValue={tag}
          freeSolo
          onInputChange={(event, newValue) => setTag(newValue)}
          options={tags.length > 0 ? tags?.map((option) => option.name?.en) : []}
          id="flat-demo"
          renderInput={(params) => (
            <TextField
              {...params}
              size={'small'}
              label="Tag"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetchingTags || isFetchingProjects ? (
                      <CircularProgress color={'secondary'} size={14} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
          sx={{ my: 2 }}
        />
        <ContainedButton
          disableRipple={true}
          type={'submit'}
          fullWidth
          size={'small'}
          isLoading={isLoading}
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            py: 1.5,
            mt: 1
          }}>
          {projectId ? 'Update project' : 'Create project'}
        </ContainedButton>
      </Box>
    </Box>
  );
}

export default NewProject;
