import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { CircularProgress, Menu, MenuItem, Tooltip, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMenu } from '../../../components/base/useMenu';
import { TestCaseContext } from '../../../store/testState';

const FolderActionMenu = React.forwardRef(
  ({ data, isLoading, onDelete, onAddFolder, onRename, folderId, projectId }, ref) => {
    const { setClickedFolder, setShowDestinationFolder, setFoldersState } =
      useContext(TestCaseContext);
    const { triggerProps, menuProps } = useMenu();
    const navigate = useNavigate();
    const onAddRequest = async () => {
      menuProps.onClose();
      navigate(`/projects/${projectId}/api-suites/${folderId}/new`);
    };
    return (
      <div>
        <Tooltip title={'View more actions'}>
          <IconButton
            ref={ref}
            size={'small'}
            sx={{ p: '4px' }}
            aria-controls={menuProps.open ? 'action-menu' : undefined}
            aria-haspopup="true"
            disabled={isLoading}
            aria-expanded={menuProps.open ? 'true' : undefined}
            onClick={async (e) => {
              e.stopPropagation();
              triggerProps.onClick(e);
              setClickedFolder(data);
            }}
            // {...triggerProps}
          >
            {isLoading ? (
              <CircularProgress size={18} color={'primary'} />
            ) : (
              <MoreHoriz color={'inherit'} />
            )}
          </IconButton>
        </Tooltip>
        <Menu
          id="action-menu"
          {...menuProps}
          PaperProps={{
            elevation: 0,
            style: {
              width: 200,
              paddingLeft: 5,
              paddingRight: 5
            },
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem onClick={() => onRename()}>Rename</MenuItem>
          <MenuItem onClick={onAddRequest}>Add request</MenuItem>
          <MenuItem onClick={() => onAddFolder()}>Add folder</MenuItem>
          <MenuItem
            onClick={() => {
              setFoldersState((prev) => {
                return { ...prev, entityToMove: data, entityType: 'folder' };
              });
              setShowDestinationFolder(true);
            }}>
            Move{' '}
          </MenuItem>
          <MenuItem sx={{ color: 'red' }} onClick={onDelete}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default FolderActionMenu;
