import { SvgIcon } from '@mui/material';

const ExternalLinkSvg = ({ width = 24, height = 24, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 14 14'} {...props} fill={'none'}>
      <path
        d="M8.19921 0.618164L10.0677 2.48667C8.28458 4.26981 4.50034 8.05467 4.31567 8.23873C3.84279 8.71161 3.84279 9.47815 4.31567 9.95103C4.78855 10.4239 5.55508 10.4239 6.02796 9.95103C6.21203 9.76636 9.99628 5.98211 11.78 4.19897L13.6485 6.06748V0.618164H8.19921Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M11.8322 7.88452C11.4974 7.88452 11.2268 8.15517 11.2268 8.49V12.7284H1.53909V3.04068H5.77745C6.11167 3.04068 6.38293 2.77003 6.38293 2.4352C6.38293 2.10036 6.11167 1.82972 5.77745 1.82972H1.53909C0.870635 1.82972 0.328125 2.37162 0.328125 3.04068V12.7284C0.328125 13.3974 0.870635 13.9393 1.53909 13.9393H11.2268C11.8952 13.9393 12.4377 13.3974 12.4377 12.7284V8.49C12.4377 8.15517 12.1671 7.88452 11.8322 7.88452Z"
        fill={fill || '#1958B8'}
      />
    </SvgIcon>
  );
};

export default ExternalLinkSvg;
