const AndroidSvg = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width={width}
      height={width}
      viewBox="0 0 256 256"
      xmlSpace="preserve">
      <defs></defs>
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 66.479 23.103 c -1.32 -5.932 -5.005 -10.898 -10.089 -13.893 l 4.014 -6.996 c 0.406 -0.708 0.162 -1.61 -0.546 -2.017 c -0.707 -0.404 -1.609 -0.162 -2.016 0.546 l -4.098 7.142 C 51.082 6.771 48.134 6.15 45 6.15 c -3.134 0 -6.081 0.622 -8.743 1.735 l -4.098 -7.142 c -0.406 -0.707 -1.309 -0.952 -2.017 -0.546 c -0.707 0.406 -0.952 1.309 -0.546 2.017 l 4.014 6.997 c -5.084 2.995 -8.769 7.962 -10.089 13.894 c -0.342 1.536 0.855 2.99 2.428 2.99 h 38.102 C 65.625 26.093 66.821 24.639 66.479 23.103 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(61, 220, 132)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 55.095 19.842 c -1.009 0 -1.827 -0.818 -1.827 -1.826 c 0 -1.009 0.818 -1.827 1.826 -1.827 c 1.009 0 1.827 0.818 1.827 1.826 C 56.921 19.023 56.104 19.841 55.095 19.842"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(255, 255, 255)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 34.905 19.842 c -1.009 0 -1.827 -0.818 -1.827 -1.826 c 0 -1.009 0.818 -1.827 1.826 -1.827 c 1.009 0 1.827 0.818 1.827 1.826 C 36.73 19.024 35.913 19.841 34.905 19.842"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(255, 255, 255)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 58.058 71.065 H 31.942 c -4.902 0 -8.876 -3.974 -8.876 -8.876 V 33.216 c 0 -1.071 0.868 -1.938 1.938 -1.938 h 39.991 c 1.071 0 1.938 0.868 1.938 1.938 v 28.973 C 66.934 67.091 62.96 71.065 58.058 71.065 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(61,220,132)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 13.555 65.76 L 13.555 65.76 c -2.874 0 -5.203 -2.329 -5.203 -5.203 V 36.481 c 0 -2.873 2.329 -5.203 5.203 -5.203 h 0 c 2.873 0 5.203 2.329 5.203 5.203 v 24.076 C 18.757 63.43 16.428 65.76 13.555 65.76 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(61,220,132)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 76.445 65.76 L 76.445 65.76 c -2.874 0 -5.203 -2.329 -5.203 -5.203 V 36.481 c 0 -2.873 2.329 -5.203 5.203 -5.203 h 0 c 2.873 0 5.203 2.329 5.203 5.203 v 24.076 C 81.648 63.43 79.319 65.76 76.445 65.76 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(61,220,132)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 53.789 90 L 53.789 90 c -2.874 0 -5.203 -2.329 -5.203 -5.203 V 60.721 c 0 -2.873 2.329 -5.203 5.203 -5.203 h 0 c 2.873 0 5.203 2.329 5.203 5.203 v 24.076 C 58.992 87.671 56.663 90 53.789 90 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(61,220,132)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 36.211 90 L 36.211 90 c -2.874 0 -5.203 -2.329 -5.203 -5.203 V 60.721 c 0 -2.873 2.329 -5.203 5.203 -5.203 h 0 c 2.873 0 5.203 2.329 5.203 5.203 v 24.076 C 41.414 87.671 39.084 90 36.211 90 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(61,220,132)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
      </g>
    </svg>
  );
};

export default AndroidSvg;
