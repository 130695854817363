import React, { useEffect } from 'react';
import { TextField, Autocomplete, Box, Checkbox, Typography, Chip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Flex from '../../../components/base/Flex';
import { useActiveProject } from '../../../store/projectState';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useScandiumQuery } from '../../../data-layer/utils';
import { capitalize } from '../../../utils/strings';
import useAwaitModal from '../../../hooks/useAwaitModal';
import NewExecutionEnvironmentModal from './NewExecutionEnvironmentModal';
import { useTheme } from '@emotion/react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExecutionEnvirons = ({
  environment_Ids,
  setEnvironmentIds,
  env_Ids,
  suiteId,
  isFetchingSuite,
  isSuccess,
  exeEnvIds
}) => {
  const activeProject = useActiveProject();
  const [requestNewExecutionModal, { open, onClose, onComplete }] = useAwaitModal();
  const theme = useTheme();

  const {
    data: environments,
    isLoading: isFetchingEnvirons,
    refetch: refetchEnvirons
  } = useScandiumQuery(`/projects/${activeProject?.id}/environments`, {
    enabled: !!activeProject?.id,
    onSuccess: (data) => {
      if (!!suiteId) {
        const defaultValues = data.data?.filter((env) => exeEnvIds?.includes(env.id));
        setEnvironmentIds(defaultValues);
      }
    }
  });

  useEffect(() => {
    if (!!suiteId) {
      const defaultValues = environments?.data?.filter((env) => exeEnvIds?.includes(env.id)) || [];
      setEnvironmentIds(defaultValues);
    }
  }, [exeEnvIds, environments]);

  return (
    <Box sx={{ width: '100%' }}>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-end', mb: '3px' }}>
        <Typography sx={{ fontSize: '1rem', color: theme.palette.text.black_grey }}>
          Execution Environment
        </Typography>
        <Chip
          label={`Add new`}
          onClick={() => requestNewExecutionModal()}
          size="small"
          color="primary"
          variant="outlined"
          sx={{
            borderRadius: '0.3rem'
          }}
        />
      </Flex>

      <Autocomplete
        id={'autocomplete_with_select_all_execution_environments'}
        multiple
        size={'small'}
        options={environments?.data || []}
        fullWidth
        limitTags={1}
        disableCloseOnSelect
        freeSolo={false}
        value={environment_Ids}
        getOptionLabel={(option) =>
          `${capitalize(option.operating_system)} ${capitalize(option.browser)} (${option.width}x${
            option.height
          })`
        }
        loading={isFetchingEnvirons}
        onChange={(_e, value) => {
          setEnvironmentIds(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Search execution environments'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetchingEnvirons ? <CircularProgress color={'secondary'} size={14} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.radio.main
                }
              }}
              style={{ marginRight: 6 }}
              checked={selected}
            />
            {`${capitalize(option.operating_system)} ${capitalize(option.browser)} (${
              option.width
            }x${option.height})`}
          </li>
        )}
      />

      <NewExecutionEnvironmentModal
        open={open}
        onClose={onClose}
        onComplete={onComplete}
        refetchEnvirons={refetchEnvirons}
        setEnvironmentIds={setEnvironmentIds}
      />
    </Box>
  );
};

export default ExecutionEnvirons;
