import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';

const AddDomainsModal = ({ open, onClose, onComplete, refetchDomains }) => {
  const [domain, setDomain] = React.useState('');
  const activeProject = useActiveProject();

  const { mutateAsync: createDomain, isLoading } = useScandiumMutation(`/company/domains`, {
    enabled: !!activeProject?.id,
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      refetchDomains();
      setDomain('');
    }
  });

  const handleCreateDomains = async (e) => {
    e.preventDefault();

    if (!domain) {
      toast.error('Domain must be specified');
      return;
    }

    await createDomain({ domain });
  };

  return (
    <CustomModal open={open}>
      <Box
        py={2}
        px={2}
        width={'80vw'}
        maxWidth={'480px'}
        position={'relative'}
        component={'form'}
        onSubmit={handleCreateDomains}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Add New Domain
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Specify the domain in the input field below
        </Typography>

        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setDomain(e.target.value);
            }}
            value={domain}
            autoFocus={true}
            placeholder={'scandiumtech.com'}
            required
            size={'small'}
            type={'text'}
            label={`Domain`}
            fullWidth
            sx={{ mb: 1 }}
          />

        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!domain || isLoading}
            isLoading={isLoading}
            type={'submit'}
            color={'primary'}
            sx={{
              '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important'
              }
            }}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default AddDomainsModal;
