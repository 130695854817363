import { SvgIcon } from '@mui/material';

const SlackSvg = ({ width = 36, height = 36, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} fill="none" viewBox="0 0 26 26" {...props}>
      <path
        d="M9.57775 13.7063C8.06975 13.7063 6.84667 14.9294 6.84667 16.4374V23.2689C6.84667 24.7769 8.06975 26 9.57775 26C11.0857 26 12.3088 24.7769 12.3088 23.2689V16.4374C12.3077 14.9294 11.0847 13.7063 9.57775 13.7063Z"
        fill="#E91E63"
      />
      <path
        d="M0.0184167 16.4374C0.0184167 17.9465 1.24258 19.1707 2.75167 19.1707C4.26075 19.1707 5.48492 17.9465 5.48492 16.4374V13.7042H2.75383C2.75275 13.7042 2.75275 13.7042 2.75167 13.7042C1.24258 13.7042 0.0184167 14.9283 0.0184167 16.4374Z"
        fill="#E91E63"
      />
      <path
        d="M9.581 0C9.57992 0 9.57883 0 9.57775 0C8.06867 0 6.8445 1.22417 6.8445 2.73325C6.8445 4.24233 8.06867 5.4665 9.57775 5.4665H12.3088V2.73325C12.3088 2.73217 12.3088 2.73 12.3088 2.72783C12.3077 1.22092 11.0868 0 9.581 0Z"
        fill="#00BCD4"
      />
      <path
        d="M2.73325 12.3186H9.57775C11.0868 12.3186 12.311 11.0944 12.311 9.58533C12.311 8.07625 11.0868 6.85208 9.57775 6.85208H2.73325C1.22417 6.85208 0 8.07625 0 9.58533C0 11.0944 1.22417 12.3186 2.73325 12.3186Z"
        fill="#00BCD4"
      />
      <path
        d="M23.2429 6.851C21.736 6.851 20.5151 8.07191 20.5151 9.57883V12.3175H23.2462C24.7552 12.3175 25.9794 11.0933 25.9794 9.58425C25.9794 8.07516 24.7552 6.851 23.2462 6.851C23.2451 6.851 23.244 6.851 23.2429 6.851Z"
        fill="#4CAF50"
      />
      <path
        d="M13.6922 2.73325V9.58533C13.6922 11.0933 14.9153 12.3164 16.4233 12.3164C17.9313 12.3164 19.1544 11.0933 19.1544 9.58533V2.73325C19.1544 1.22525 17.9313 0.00216667 16.4233 0.00216667C14.9153 0.00216667 13.6922 1.22525 13.6922 2.73325Z"
        fill="#4CAF50"
      />
      <path
        d="M19.1533 23.2667C19.1533 21.7587 17.9302 20.5357 16.4222 20.5357H13.6912V23.2689C13.6922 24.7758 14.9142 25.9978 16.4222 25.9978C17.9302 25.9978 19.1533 24.7747 19.1533 23.2667Z"
        fill="#FF9800"
      />
      <path
        d="M23.2667 13.7042H16.4222C14.9132 13.7042 13.689 14.9283 13.689 16.4374C13.689 17.9465 14.9132 19.1707 16.4222 19.1707H23.2667C24.7758 19.1707 26 17.9465 26 16.4374C26 14.9283 24.7758 13.7042 23.2667 13.7042Z"
        fill="#FF9800"
      />
    </SvgIcon>
  );
};

export default SlackSvg;
