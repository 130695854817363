import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Logo from './Logo';
import Flex from './Flex';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

const Error404Page = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        bgcolor: theme.palette.background.lowerToolBar
      }}>
      <Box py={2} pl={2}>
        <Logo />
      </Box>
      <Divider />
      <Flex flexDirection={'column'} height={'60vh'}>
        <Box
          component="img"
          sx={{
            height: '100%',
            width: '100%',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
          alt="404 - Page Not Found "
          src="/images/errorImage.svg"
        />
        <Typography fontWeight={600} fontSize={'1.4rem'}>
          Oops! Page Not Found
        </Typography>
        <Typography variant={'body1'} textAlign={'center'} maxWidth={'400px'}>
          It seems like the page you are looking for doesn't exist. Don't worry, let's get you back
          on track!
        </Typography>
        <Typography
          textAlign={'center'}
          component={Link}
          to={'/'}
          variant={'body2'}
          color={'primary'}>
          Go to Dashboard
        </Typography>
      </Flex>
    </Box>
  );
};

export default Error404Page;
