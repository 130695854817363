import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

export const TRACKING_IDS = {
  NEW_TEST_SIDEBAR: 'NEW_TEST_SIDEBAR',
  NEW_TEST_TOPNAV: 'NEW_TEST_TOPNAV',
  START_RECORDING: 'START_RECORDING',
  STOP_RECORDING: 'STOP_RECORDING',
  START_RUN: 'START_RUN',
  STOP_REPLAY: 'START_REPLAY',
  SIGNUP_CLICKED: 'SIGNUP_CLICKED',
  SIGNUP_SUCCESSFUL: 'SIGNUP_SUCCESSFUL',
  SIGNUP_COMPLETED: 'SIGNUP_COMPLETED',
  RECORDING_STARTED: 'RECORDING_STARTED',
  RECORDING_STOPPED: 'RECORDING_STOP',
  TEST_RUN: 'TEST_RUN',
  TEST_SAVED: 'TEST_SAVED',
  TESTCASE_IMPORTED: 'TESTCASE_IMPORTED',
  DATA_EXPORTED: 'DATA_EXPORTED',
  PROJECT_CREATED: 'PROJECT_CREATED',
  MEMBER_INVITED: 'MEMBER_INVITED',
  SCHEDULE_CREATED: 'SCHEDULE_CREATED',
  LOGIN_STATUS: 'LOGIN_STATUS',
  NEW_TEST_CLICKED: 'NEW_TEST_CLICKED',
  USER_GUIDE_CLICKED: 'USER_GUIDE_CLICKED',
  GET_HELP_CLICKED: 'GET_HELP_CLICKED',
  SEE_ERROR_CLICKED: 'SEE_ERROR_CLICKED',
};

export const trackMixPanel = (eventName, properties) => {
  try {
    mixpanel.track(eventName, properties);
  } catch (error) {
    console.error('Failed to send mixpanel event', error);
  }
};

export const resetMixpanelData = () => {
  try {
    mixpanel.reset();
  } catch (error) {
    console.error('Failed to reset mixpanel event', error);
  }
};

export const setMixpanelUser = (data) => {
  try {
    if (data && data.user) {
      mixpanel.identify(data.user.id);

      mixpanel.people.set({
        $name: data.user.name,
        $email: data.user.email
      });
    } else {
      throw new Error("Invalid data object. 'user.id' and 'user.email' are required.");
    }
  } catch (error) {
    console.error('Failed to set Mixpanel user data:', error);
  }
};

export const identifyMixpanelUser = (id) => {
  try {
    mixpanel.identify(id);
  } catch (error) {
    console.error('Failed to identify Mixpanel user:', error);
  }
};

export const MixpanelPageViewTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    try {
      mixpanel.track_pageview({ title: document.title || 'Scandium' });
    } catch (error) {
      console.error('Error tracking page view:', error);
    }
  }, [location]);

  return children;
};
