import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import ProjectContext, { useActiveProject } from '../store/projectState';
import { stripLeadingSlash } from '../utils/strings';
import PageLoader from './PageLoader';

const ProjectRedirect = () => {
  const { isProjectsLoading } = useContext(ProjectContext);
  const activeProject = useActiveProject();
  const { search, pathname, hash } = useLocation();
  if (isProjectsLoading || !activeProject) return <PageLoader />;

  return (
    <Navigate
      to={{
        pathname: `/projects/${activeProject?.id}/${stripLeadingSlash(pathname)}`,
        search,
        hash
      }}
    />
  );
};

export default ProjectRedirect;
