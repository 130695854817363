import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ContainedButton from '../../../components/base/ContainedButton';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TextField, Typography } from '@mui/material';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import { useContext, useState } from 'react';
import { TestCaseContext } from '../../../store/testState';
import { MuiMarkdown } from 'mui-markdown';

export const DescriptionModal = () => {
  const { showDescriptionModal, setShowDescriptionModal, clickedTest } =
    useContext(TestCaseContext);
  const [descriptionContent, setDescriptionContent] = useState('');
  const handleClose = () => {
    setShowDescriptionModal(false);
  };
  const activeProject = useActiveProject();
  const { mutateAsync: saveDescription, isLoading: isSavingDescription } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest.id}`,
    {
      method: 'PUT',
      onSuccess: (data) => {
        // console.log(data);
        toast.success('Documentation saved sucessfully', {
          autoClose: 1000
        });
      },
      onError: () => {
        toast.error('Failed to save, try again', {
          autoClose: 1000
        });
      },
      enabled: !!clickedTest.id
    }
  );
  const handleSaveDescription = () => {
    saveDescription({ documentation: descriptionContent });
  };
  return (
    <Dialog
      open={showDescriptionModal}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 10
        }
      }}>
      <IconButton
        sx={{ ml: 'auto', pr: '20px', pt: '20px' }}
        onClick={() => {
          handleClose();
        }}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogTitle color={'primary'} fontSize={25}>
        {' '}
        Add description
      </DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: 'gray',
                borderWidth: 1
              }
            }
          }}
          autoFocus
          margin="dense"
          id="name"
          fullWidth
          variant="outlined"
          size="lg"
          minRows={10}
          multiline
          value={descriptionContent}
          onChange={(e) => {
            setDescriptionContent(e.target.value);
          }}
        />
        <Typography
          sx={{
            mt: 2,
            fontSize: 18,
            color: 'rgba(0, 0, 0, 0.8)'
          }}>
          Preview{' '}
        </Typography>

        <h3>
          <ReactMarkdown>{descriptionContent}</ReactMarkdown>{' '}
        </h3>

        <ContainedButton
          isTextEmpty={descriptionContent.trim() === ''}
          isLoading={isSavingDescription}
          onClick={() => {
            // console.log(descriptionContent);
            handleSaveDescription();
          }}
          loadingProps={{ size: 14 }}
          sx={{ flex: '100%', width: '100%', mt: 5, pt: 1.5, pb: 1.5, fontSize: 15 }}>
          Save
        </ContainedButton>
      </DialogContent>
    </Dialog>
  );
};
