export const dateFormats = [
  'YYYY-MM-DD', // 2024-08-09
  'MM/DD/YYYY', // 08/09/2024
  'DD/MM/YYYY', // 09/08/2024
  'DD-MM-YYYY', // 09-08-2024
  'YYYY/MM/DD', // 2024/08/09
  'YYYY.MM.DD', // 2024.08.09
  'DD.MM.YYYY', // 09.08.2024
  'MM-DD-YYYY', // 08-09-2024
  'YYYYMMDD', // 20240809
  'YYYY/MM/DD HH:mm', // 2024/08/09 15:30
  'YYYY-MM-DD HH:mm:ss', // 2024-08-09 15:30:00
  'MM/DD/YYYY HH:mm:ss' // 08/09/2024 15:30:00
];

export const iOSDevices = [
  {
    device: 'iPhone 8',
    device_identifier: 'iphone8',
    os: ['14.5', '15.5', '16.2'],
    os_type: 'iOS'
  },
  {
    device: 'iPhone 8 Plus',
    device_identifier: 'iphone8plus',
    os: ['14.5', '15.5', '16.2'],
    os_type: 'iOS'
  },
  {
    device: 'iPhone 11 Pro',
    device_identifier: 'iphone11pro',
    os: ['14.5', '15.5', '16.2'],
    os_type: 'iOS'
  },
  {
    device: 'iPhone 12',
    device_identifier: 'iphone12',
    os: ['14.5', '15.5', '16.2'],
    os_type: 'iOS'
  },
  {
    device: 'iPhone 13 Pro',
    device_identifier: 'iphone13pro',
    os: ['15.5', '16.2'],
    os_type: 'iOS'
  },
  {
    device: 'iPhone 13 Pro Max',
    device_identifier: 'iphone13promax',
    os: ['15.5', '16.2'],
    os_type: 'iOS'
  },
  { device: 'iPhone 14 Pro', device_identifier: 'iphone14pro', os: ['16.2'], os_type: 'iOS' },
  {
    device: 'iPhone 14 Pro Max',
    device_identifier: 'iphone14promax',
    os: ['16.2'],
    os_type: 'iOS'
  },
  { device: 'iPhone 15 Pro', device_identifier: 'iphone15pro', os: ['17.0'], os_type: 'iOS' },
  {
    device: 'iPhone 15 Pro Max',
    device_identifier: 'iphone15promax',
    os: ['17.0'],
    os_type: 'iOS'
  },
  {
    device: 'iPad Air',
    device_identifier: 'ipadair4thgeneration',
    os: ['14.5', '15.5', '16.2', '17.0'],
    os_type: 'iOS'
  },
  {
    device: 'iPad Pro 12.9"',
    device_identifier: 'ipadpro129inch5thgeneration',
    os: ['14.5', '15.5', '16.2', '17.0'],
    os_type: 'iOS'
  },
  {
    device: 'iPad',
    device_identifier: 'ipad9thgeneration',
    os: ['15.5', '16.2', '17.0'],
    os_type: 'iOS'
  }
];

export const androidDevices = [
  {
    device: 'Nexus 5',
    device_identifier: 'nexus5',
    os: ['5.1', '6.0', '7.1', '8.1', '9.0', '10.0', '11.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 4',
    device_identifier: 'pixel4',
    os: ['10.0', '11.0', '12.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 4 XL',
    device_identifier: 'pixel4xl',
    os: ['10.0', '11.0', '12.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 6',
    device_identifier: 'pixel6',
    os: ['12.0', '13.0', '14.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 6 Pro',
    device_identifier: 'pixel6pro',
    os: ['12.0', '13.0', '14.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 7',
    device_identifier: 'pixel7',
    os: ['13.0', '14.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 7 Pro',
    device_identifier: 'pixel7pro',
    os: ['13.0', '14.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 8',
    device_identifier: 'pixel8',
    os: ['14.0'],
    os_type: 'Android'
  },
  {
    device: 'Pixel 8 Pro',
    device_identifier: 'pixel8pro',
    os: ['14.0'],
    os_type: 'Android'
  },
  {
    device: 'Galaxy Tab S7',
    device_identifier: 'galaxytabs7',
    os: ['10.0', '11.0', '12.0', '13.0'],
    os_type: 'Android'
  }
];
