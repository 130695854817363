import React, { useState, useCallback } from 'react';
import { useDebounce } from 'use-debounce';
import { useDrop } from 'react-dnd';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Chip,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { toast } from 'react-toastify';
import Flex from '../../components/base/Flex';
import SearchIcon from '@mui/icons-material/Search';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useActiveProject } from '../../store/projectState';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StyledTableCell } from './components/StyledTableCell';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import SuiteRow from './components/SuiteRow';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import { useFetchTests } from '../../data-layer/project-management';
import AddIcon from '@mui/icons-material/Add';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';
import { Edit } from '@mui/icons-material';
import ContainedButton from '../../components/base/ContainedButton';
import usePagination from '../../hooks/usePagination';
import { useTheme } from '@emotion/react';

const TestSuites = () => {
  const theme = useTheme();
  const activeProject = useActiveProject();
  const { suiteId, folderId, projectId } = useParams();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [tests, setTests] = useState([]);
  const navigate = useNavigate();
  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();

  const readyOnly = activeProject?.read_only;

  const handleChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    const params = new URLSearchParams(searchParams);

    if (!newQuery) {
      params.delete('query');
    } else {
      params.set('query', newQuery);
    }

    setSearchParams(params, { replace: true });
  };

  const [, drop] = useDrop(() => ({ accept: 'tests' }));

  const {
    data,
    isLoading: isFetchingSuites,
    error: suitesError,
    refetch: refetchSuites
  } = useScandiumQuery(
    `/projects/${activeProject?.id}/${suiteId ? `suites/${suiteId}` : 'suites'}`,
    {
      enabled: !!activeProject?.id,
      params: {
        search: !suiteId && (debouncedQuery || undefined),
        page: activePage,
        limit: pageLimit
      },
      select: (data) => data.data
    }
  );

  useDocumentTitle(suiteId ? `Test Suites${data?.name ? `: ${data.name}` : ''}` : 'Test Suites');

  const { mutateAsync: updateSuite } = useScandiumMutation(
    `/projects/${activeProject?.id}/suites/${suiteId}`,
    {
      method: 'PUT',
      enabled: !!activeProject?.id && !!suiteId
    }
  );

  const {
    isLoading: isTestsLoading,
    error: testsError,
    isSuccess: fetchedTests
  } = useFetchTests(activeProject?.id, folderId, {
    enabled: !!activeProject?.id && !!suiteId,
    params: {
      unlimited: 'false',
      suite_id: suiteId || undefined,
      search: debouncedQuery || undefined
    },
    onSuccess: (data) => {
      setTests(data.data);
    }
  });

  const isLoading = isFetchingSuites || isTestsLoading || !activeProject;

  const reorderTests = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === dragIndex) return;

      const newTests = tests.map(({ preview, ...test }) => test);
      const draggedTest = newTests[dragIndex];

      newTests.splice(dragIndex, 1);
      newTests.splice(hoverIndex, 0, { ...draggedTest, preview: true });
      setTests(newTests);
    },
    [tests, setTests]
  );

  const handleDragEnd = useCallback(() => {
    const newTests = tests.map(({ preview, ...test }) => test);
    setTests(newTests);
  }, [tests, setTests]);

  const handleUpdateSuite = async () => {
    const _test_ids = tests?.map((test) => test.id);

    await updateSuite({
      name: data.name,
      description: data.description,
      starting_url: data.starting_url,
      execution_type: data.execution_type,
      environment_ids: data.environment_ids,
      test_ids: _test_ids,
      test_delay: 0,
      test_timeout: 0,
      test_retries: 0
    });
  };
  const handleNavigateToTest = (testId) => {
    navigate(`/projects/${projectId}/folders/tests/${testId}/edit`);
  };
  const findTestIndex = useCallback(
    (id) => {
      return tests.findIndex((test) => test.id === id);
    },
    [tests]
  );

  return (
    <Box
      sx={{
        mt: -2,
        mb: '12rem'
      }}>
      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Flex>
          <Typography fontWeight={'500'} as={'h2'} fontSize={'1.2rem'} color={'primary'}>
            {data?.name || 'Test Suites'}
          </Typography>
          {data?.name && (
            <Typography fontWeight={'400'} as={'span'} fontSize={'1.1rem'} color={'primary'}>
              {` / ${data?.test_ids?.length} Test cases`}
            </Typography>
          )}
        </Flex>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center'
          }}>
          {!!suiteId && (
            <NavLink to={'edit'}>
              <Chip
                variant="outlined"
                label={`Edit Suite`}
                onClick={() => {}}
                size={'small'}
                icon={<Edit />}
                sx={{
                  borderRadius: '0.3rem',
                  px: 0,
                  py: 2,
                  ml: 'auto',
                  mr: 1.5
                  // color: 'red'
                }}
              />
            </NavLink>
          )}

          <ContainedButton
            startIcon={<AddIcon sx={{ color: theme.palette.svg.main }} />}
            disabled={!!readyOnly}
            onClick={() => {
              if (!suiteId) {
                navigate(`${pathname}/new`);
              } else {
                navigate(`${pathname.substring(0, pathname.lastIndexOf('/'))}/new`);
              }
            }}
            sx={{
              borderRadius: '0.4rem',
              bgcolor: 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 1.5,
              minHeight: '28px'
            }}>
            New Suite
          </ContainedButton>

          <Search sx={{ width: { xs: 'max-content' } }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
              value={searchQuery}
            />
          </Search>
        </Box>
      </Flex>

      <Box>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 1,
            mt: 2,
            mb: 4
          }}>
          <Table sx={{ width: '100%', minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Created By</StyledTableCell>
                <StyledTableCell>Created On</StyledTableCell>
                <StyledTableCell align={'center'}></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.map((row) => (
                <SuiteRow
                  key={row.id}
                  refetchSuites={refetchSuites}
                  suiteId={suiteId}
                  suite={row}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {isLoading && <PageLoader height={'100px'} />}
        {!isLoading &&
          (suitesError || testsError ? (
            <ErrorState error={suitesError || testsError} />
          ) : (
            data?.length === 0 && (
              <EmptyState
                title={
                  !data?.length ? "It's lonely here at the moment" : 'There are no matching results'
                }
                description={
                  !data?.length
                    ? `Why not create a few test suites?`
                    : 'Try searching for something else, or create a new test suites'
                }
                cta={
                  <Flex>
                    <OutlinedButton disabled={!!readyOnly} component={NavLink} to={'new'}>
                      Create Test Suite
                    </OutlinedButton>
                  </Flex>
                }
              />
            )
          ))}
      </Box>

      {data?.meta?.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={data?.meta.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Tests per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Tests per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(data?.meta.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default TestSuites;
