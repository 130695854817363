import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import { useScandiumCustomQuery } from '../../../data-layer/custom';
import PageLoader from '../../../components/PageLoader';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useScandiumQuery } from '../../../data-layer/utils';

const SelectDropdown = ({
  value,
  options,
  onChange,
  isLoading = false,
  label,
  labelId,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth size={'small'}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={'Trello Board'}
        value={value}
        label={label}
        onChange={onChange}
        IconComponent={(defaultProps) =>
          isLoading ? (
            <CircularProgress size={16} color={'secondary'} {...defaultProps} />
          ) : (
            <ArrowDropDownIcon {...defaultProps} />
          )
        }
        renderValue={(selectedValue) => {
          return selectedValue ? selectedValue.name : '';
        }}
        MenuProps={{
          elevation: 1
        }}
        {...otherProps}>
        {options?.map((board) => (
          <MenuItem key={options.id} value={board}>
            {board.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const JiraConnectModal = ({
  open,
  onClose,
  value,
  handleUpdate,
  onComplete,
  updateChannelData,
  setSuccessChannels,
  successChannels,
  errorChannels,
  setErrorChannels,
  activeTab,
  showJiraSettings,
  isJiraTokenSuccess,
  handlePostChannels
}) => {
  const activeChannel = activeTab === 0 ? successChannels : errorChannels;
  const jiraChannel = activeChannel.find((channel) => channel.channel === 'jira');
  const activeWorkspace = jiraChannel.channel_details?.data;

  const [workspace, setWorkspace] = useState([]);
  const [workspaceProjects, setWorkspaceProjects] = useState([]);
  const [projectIssueTypes, setProjectIssueTypes] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectWorkspaceProject, setSelectedWorkspaceProject] = useState(null);
  const [selectedProjectIssueType, setSelectedProjectIssueType] = useState(null);

  const handleJiraWorkspaceChange = (event) => {
    setSelectedWorkspace(event.target.value);
  };

  const handleProjectChange = (event) => {
    setSelectedWorkspaceProject(event.target.value);
  };

  const handleIssueTypeChange = (event) => {
    setSelectedProjectIssueType(event.target.value);
  };

  useEffect(() => {
    const activeWorkspace = jiraChannel.channel_details?.data;
    const matchingWorkspace = workspace.find(
      (workspace) => workspace.id === activeWorkspace?.workspace_id
    );
    const matchingProject = workspaceProjects.find(
      (project) => project.id === activeWorkspace?.project_id
    );
    const matchingIssueType = projectIssueTypes.find(
      (issueType) => issueType.id === activeWorkspace?.issue_type_id
    );
    setSelectedProjectIssueType(matchingIssueType || projectIssueTypes?.[0]);
    setSelectedWorkspaceProject(matchingProject || workspaceProjects?.[0]);
    setSelectedWorkspace(matchingWorkspace || workspace?.[0]);
  }, [activeChannel, jiraChannel]);

  const handleSaveJiraData = async () => {
    if (!selectedWorkspace || !selectWorkspaceProject || !selectedProjectIssueType) {
      toast.error('All fields are required');
      return;
    }

    const updater = activeTab === 0 ? setSuccessChannels : setErrorChannels;

    updater((prevChannels) =>
      prevChannels.map((channel) => {
        if (channel.channel === 'jira') {
          return {
            ...channel,
            status: 'active',
            channel_details: {
              data: {
                workspace_id: selectedWorkspace?.id,
                project_id: selectWorkspaceProject?.id,
                issue_type_id: selectedProjectIssueType?.id
              }
            }
          };
        } else {
          return channel;
        }
      })
    );

    onClose();
  };

  const { isLoading: isFetchingWorkspace } = useScandiumQuery('/third-parties/jira/workspaces', {
    enabled: !!showJiraSettings || isJiraTokenSuccess,
    onSuccess: (data) => {
      setWorkspace(data.data);
      const matchingWorkspace = data.data.find(
        (workspace) => workspace.id === activeWorkspace?.workspace_id
      );
      setSelectedWorkspace(matchingWorkspace || data?.data?.[0]);
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const { isLoading: isFetchingWorkspaceProjects } = useScandiumQuery(
    `/third-parties/jira/workspaces/${selectedWorkspace?.id}/projects`,
    {
      enabled: !!selectedWorkspace?.id,
      onSuccess: (data) => {
        setWorkspaceProjects(data.data);
        const matchingProject = data.data.find(
          (project) => project.id === activeWorkspace?.project_id
        );
        setSelectedWorkspaceProject(matchingProject || data.data?.[0]);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { isLoading: isFetchingIssueTypes } = useScandiumQuery(
    `/third-parties/jira/workspaces/${selectedWorkspace?.id}/issue-types?project_id=${selectWorkspaceProject?.id}`,
    {
      enabled: !!selectWorkspaceProject?.id,
      onSuccess: (data) => {
        setProjectIssueTypes(data.data);
        const matchingIssueType = data.data.find(
          (issueType) => issueType.id === activeWorkspace?.issue_type_id
        );
        setSelectedProjectIssueType(matchingIssueType || data.data?.[0]);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Connect Your Jira Account
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          The modal allows you to choose a Jira project. When a test fails or passes, a issue will
          be created in the selected project to track the outcome.
        </Typography>

        <Box>
          <SelectDropdown
            options={workspace}
            isLoading={isFetchingWorkspace}
            onChange={handleJiraWorkspaceChange}
            value={selectedWorkspace}
            label={'Select a Jira workspace'}
            labelId={'Board label'}
            sx={{ mb: 2 }}
          />
          <SelectDropdown
            options={workspaceProjects}
            isLoading={isFetchingWorkspaceProjects}
            onChange={handleProjectChange}
            value={selectWorkspaceProject}
            label={'Select a workspace project'}
            labelId={'Board label'}
            sx={{ mb: 2 }}
          />
          <SelectDropdown
            options={projectIssueTypes}
            isLoading={isFetchingIssueTypes}
            onChange={handleIssueTypeChange}
            value={selectedProjectIssueType}
            label={'Select a issue type'}
            labelId={'Board label'}
            sx={{ mb: 2 }}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton onClick={handleSaveJiraData} color={'primary'} autoFocus>
            connect
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default JiraConnectModal;
