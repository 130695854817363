import { SvgIcon } from '@mui/material';

const ScreenshotSvg = ({ width = 28, height = 28, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 18 18'} {...props} fill={'none'}>
      <path
        d="M12.8838 0.982422H11.053C10.8154 0.982422 10.6227 1.17508 10.6227 1.41273C10.6227 1.65037 10.8154 1.84303 11.053 1.84303H12.8838C13.3997 1.84303 13.8193 2.26266 13.8193 2.77848V4.60928C13.8193 4.84692 14.0119 5.03958 14.2496 5.03958C14.4872 5.03958 14.6799 4.84692 14.6799 4.60928V2.77848C14.6799 1.78812 13.8742 0.982422 12.8838 0.982422Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M0.422491 5.03958C0.660134 5.03958 0.852795 4.84692 0.852795 4.60928V2.77848C0.852795 2.26266 1.27243 1.84303 1.78825 1.84303H3.61905C3.85669 1.84303 4.04935 1.65037 4.04935 1.41273C4.04935 1.17508 3.85669 0.982422 3.61905 0.982422H1.78825C0.797889 0.982422 -0.0078125 1.78812 -0.0078125 2.77848V4.60928C-0.0078125 4.84692 0.184849 5.03958 0.422491 5.03958Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M3.61905 14.8095H1.78825C1.27243 14.8095 0.852795 14.3899 0.852795 13.8741V12.0433C0.852795 11.8056 0.660134 11.613 0.422491 11.613C0.184849 11.613 -0.0078125 11.8056 -0.0078125 12.0433V13.8741C-0.0078125 14.8644 0.797889 15.6701 1.78825 15.6701H3.61905C3.85669 15.6701 4.04935 15.4775 4.04935 15.2398C4.04935 15.0022 3.85669 14.8095 3.61905 14.8095Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M14.2496 11.613C14.0119 11.613 13.8193 11.8056 13.8193 12.0433V13.8741C13.8193 14.3899 13.3997 14.8095 12.8838 14.8095H11.053C10.8154 14.8095 10.6227 15.0022 10.6227 15.2398C10.6227 15.4775 10.8154 15.6701 11.053 15.6701H12.8838C13.8742 15.6701 14.6799 14.8644 14.6799 13.8741V12.0433C14.6799 11.8056 14.4872 11.613 14.2496 11.613Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M7.33604 6.16385C7.59048 6.16385 7.79675 5.95759 7.79675 5.70314C7.79675 5.4487 7.59048 5.24243 7.33604 5.24243C7.0816 5.24243 6.87533 5.4487 6.87533 5.70314C6.87533 5.95759 7.0816 6.16385 7.33604 6.16385Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M10.7983 10.2549L9.30196 8.75859L7.24137 10.8192C7.07327 10.9873 6.80074 10.9873 6.63292 10.8192L5.59101 9.77726L3.92344 11.4451C3.84255 11.5257 3.73325 11.5711 3.61908 11.5711H1.8729V13.3591C1.8729 13.5966 2.06568 13.7894 2.30321 13.7894H12.3689C12.6064 13.7894 12.7992 13.5966 12.7992 13.3591V10.3808H11.1026C10.9885 10.3808 10.8789 10.3355 10.7983 10.2549Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M12.7992 3.29344C12.7992 3.05591 12.6064 2.86314 12.3689 2.86314H2.30321C2.06568 2.86314 1.8729 3.05591 1.8729 3.29344V10.7104H3.44093L5.28665 8.86473C5.45475 8.69662 5.72728 8.69662 5.8951 8.86473L6.937 9.90635L8.99787 7.84577C9.16598 7.67766 9.43822 7.67766 9.60632 7.84577L11.2808 9.52022H12.7992V3.29344ZM7.33604 7.02446C6.60739 7.02446 6.01472 6.43179 6.01472 5.70314C6.01472 4.97478 6.60739 4.38182 7.33604 4.38182C8.06469 4.38182 8.65736 4.97478 8.65736 5.70314C8.65736 6.43179 8.06469 7.02446 7.33604 7.02446Z"
        fill={fill || "#1958B8"}
      />
    </SvgIcon>
  );
};

export default ScreenshotSvg;
