import React, { useContext, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { useActiveProject } from '../../../store/projectState';
import { TestAutomationContext } from '../../../store/testAutomationState';
import { useLocation, Link, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useScandiumQuery } from '../../../data-layer/utils';
import { convertTimeToSeconds, getDateTime } from '../../../utils/time';
import { toast } from 'react-toastify';
import Flex from '../../../components/base/Flex';
import {
  Badge,
  Box,
  Chip,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import SuiteRunsList from './components/SuiteRunsList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { rgba } from '../../../utils/colors';
import prettyBytes from 'pretty-bytes';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import {
  AssertionComponentForResponseHeaders,
  AssertionComponentForStatusCode
} from '../components/AssertionResults';
import Sidebar from '../../../components/sidebar';
import EmptyState from '../../../components/base/EmptyState';
import FilterForSuitesAndRuns from './components/FilterForSuitesAndRuns';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { LoadingComponent } from './components/TestAutomationSuites';
import { ArrowBack, Co2Sharp, Visibility } from '@mui/icons-material';
import { tabStyle } from '../NewApi';
import { TabPanel } from '../NewApi';
import ResponseEditor from '../components/Response';
import ResponseHeader from '../components/ResponseHeader';
import NoTestAssertionsSvg from '../../../components/svg/NoTestAssertionsSvg';
import {} from '../NewApi';
import NoCookiesSvg from '../../../components/svg/NoCookiesSvg';
import PageLoader from '../../../components/PageLoader';
import { format } from 'date-fns';
import { _resultFIlter } from '../../Mobile/RunsAndReports';
import { Ranges } from '../../Reports/Reports';
import StatusFilter from '../../Mobile/components/StatusFilter';
import usePagination from '../../../hooks/usePagination';
import { cleanJson } from '../utils';
import { useTheme } from '@emotion/react';

const colorMap = {
  get: '#53BB63',
  post: '#FFB100',
  put: '#00CCFF',
  patch: '#E39FF6',
  delete: '#FF775E',
  head: '#53BB63',
  options: '#B03060'
};

const chipStyle = {
  fontWeight: 'medium',
  fontSize: 12,
  maxHeight: '20px',
  borderRadius: 1,
  px: 0.1,
  py: 0.1,
  background: 'rgba(220,220,220, 0.2)'
};
const assertionStyle = {
  background: 'rgba(220,220,220, 0.1)',
  ml: 3,
  mb: 0.3,
  pl: 2,
  py: 1,
  columnGap: 4
};
const IsFailedOrPassedRuns = ({ text }) => {
  return (
    <Typography
      sx={{
        fontStyle: 'italic',
        py: 4,
        width: '100%',
        textAlign: 'center',
        opacity: 0.5
      }}>
      {text}
    </Typography>
  );
};
const filterFailedOrPaassed = (ApiRun, check) => {
  if (!JSON.parse(ApiRun).test_cases) {
    return;
  }
  let run = JSON.parse(ApiRun);
  const testsWith_All_Failed_Passed_Assertions = run.test_cases.map((test) => {
    const assertionResult = Object.entries(test.assertions).map(([key, value]) => {
      return { [key]: value };
    });
    if (check === 'all') {
      test.assertions = assertionResult;
    } else {
      const failedOrPassedAssertions = assertionResult.filter((ass) => {
        if (ass.status_code) {
          return ass.status_code?.status === check;
        } else if (ass.response_body_type) {
          return ass.response_body_type?.status === check;
        } else if (ass.response_body_content) {
          const failedBodyContent = ass.response_body_content.filter((results) => {
            return results.status === check;
          });
          ass.response_body_content = failedBodyContent;
          return ass.response_body_content.length > 0;
        } else if (ass.response_headers) {
          const failedHeaders = ass.response_headers.filter((results) => {
            return results.status === check;
          });
          ass.response_headers = failedHeaders;
          return ass.response_headers.length > 0;
        } else if (ass.create_global_variable) {
          const failedEntries = ass.create_global_variable.filter((results) => {
            return results.status === check;
          });
          ass.create_global_variable = failedEntries;
          return ass.create_global_variable.length > 0;
        }
      });
      test.assertions = failedOrPassedAssertions;
    }
    return test;
  });
  const filterOutTestsWithEmptyArray = testsWith_All_Failed_Passed_Assertions.filter((ele) => {
    return ele.assertions.length > 0;
  });
  if (check !== 'all') {
    run.test_cases = filterOutTestsWithEmptyArray;
  } else {
    run.test_cases = testsWith_All_Failed_Passed_Assertions;
  }
  return run;
};

const ApiSuiteRuns = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const responseRef = useRef(null);
  const arrayOfNumbers = Array.from({ length: 4 }, (_, index) => index + 1);
  const [runsResultsTab, setRunsResultTab] = useState(0);
  const {
    activeRun,
    setActiveRun,
    suiteRuns,
    setSuiteRuns,
    suiteOrRunQueryParams,
    setSuiteOrRunQueryParams,
    activeTestResult,
    setActiveTestResult
  } = useContext(TestAutomationContext);
  const [AllPassed, setPassed] = useState({});
  const [AllFailed, setFailed] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState([]);
  const initialStatus = searchParams.get('status');
  const [filterByStatus, setFilterByStatus] = useState(
    _resultFIlter.find((filter) => filter.value === initialStatus) || _resultFIlter[0]
  );
  const activeProject = useActiveProject();
  const { search } = useLocation();
  const runId = new URLSearchParams(search).get('run_id');
  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();

  const { afterToday } = DateRangePicker;

  const handleChangeDatePicker = (newValue) => {
    setDateRange(newValue);

    if (newValue?.length === 2) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        date_from: newValue[0].toISOString().split('T')[0],
        date_to: newValue[1].toISOString().split('T')[0]
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('date_from');
      newParams.delete('date_to');
      setSearchParams(newParams);
      setDateRange(null);
    }
  };

  useEffect(() => {
    const dateFrom = searchParams.get('date_from');
    const dateTo = searchParams.get('date_to');

    if (dateFrom && dateTo) {
      setDateRange([new Date(dateFrom), new Date(dateTo)]);
    }
  }, []);

  // const [responseEditorState, setResponseEditorState] = useState({
  //   wordWrap: 'on',
  //   responseEditorLanguage: 'json',
  //   lineNumbers: 'on',
  //   //  value: bodyType === 'json' ? JSON.stringify(_body, null, 2) : _body,
  //   value: '',
  //   activeBTN: 'pretty'
  // });
  const {
    data: allRuns,
    isLoading: fetchingAllRuns,
    isSuccess: fetchedAllRuns,
    refetch: refetchAllRuns,
    isRefetching: refetchingRuns
  } = useScandiumQuery(`/projects/${projectId}/api-runs`, {
    params: {
      page: activePage,
      status: filterByStatus.value,
      limit: pageLimit,
      date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
      date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
    },
    enabled: !!projectId,
    onSuccess: (data) => {
      setSuiteRuns(data.data.runs.data);
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: 1000
      });
    }
  });
  const { isFetching: fetchingSingleRun, isSuccess: fetchedSingleRun } = useScandiumQuery(
    `/projects/${projectId}/api-runs/${runId}`,
    {
      enabled: !!projectId && !!runId,
      onSuccess: (data) => {
        setActiveTestResult();
        setActiveRun(data.data);
      },
      onError: (error) => {
        toast.error(error.message, {
          autoClose: 1000
        });
      }
    }
  );
  const { isFeatureEnabled: showAPIAutomationMenu } = useFeatureEnabled('api-automation');
  useEffect(() => {
    setSuiteOrRunQueryParams((prev) => {
      return {
        ...prev,
        limit: '',
        status: '',
        date_from: '',
        date_to: format(new Date(), 'yyyy-MM-dd'),
        showFilter: false
      };
    });
  }, []);
  const paginate = allRuns?.data?.runs;
  const handleRunsDisplayLimit = (value) => {
    setSuiteOrRunQueryParams((prev) => {
      return { ...prev, page: '1', limit: value.toString() };
    });
  };
  const resultBatchOptions =
    allRuns?.data?.runs?.total > 15 && Math.ceil(Number(allRuns?.data?.runs.total) / 5);

  useEffect(() => {
    const intervalRunsFetch = setInterval(() => {
      if (!runId) {
        refetchAllRuns();
      } else {
        return;
      }
    }, 15000);
    return () => clearInterval(intervalRunsFetch);
  }, []);

  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        pt: 18
      }}>
      {!showAPIAutomationMenu ? (
        <Box
          sx={{
            width: '200px',
            height: 'auto',
            margin: 'auto'
          }}>
          <PageLoader height={'100px'} />
        </Box>
      ) : (
        <>
          <Stack width="200px" sx={{ height: 'inherit', justifyItems: 'center', pl: 5 }}>
            <Flex
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                mr: 3,
                '&:hover': {
                  cursor: 'pointer'
                },
                visibility: !runId && 'hidden'
              }}>
              <ArrowBack />
              <Typography fontSize={16}>Back </Typography>
            </Flex>
            <Sidebar height={'500px'} customStyle={true} />{' '}
          </Stack>
          <Stack
            gap={1}
            sx={{
              width: '100%',
              mb: 5,
              minHeight: '65vh',
              px: 4
            }}>
            {!suiteRuns.length &&
              !fetchingAllRuns &&
              (!dateRange?.[0] || !dateRange?.[1]) &&
              !filterByStatus.value && (
                <Stack alignItems={'center'}>
                  <EmptyState
                    title={'This project has no API suite runs'}
                    description="Why not create one now"
                    sx={{ mt: 15 }}
                  />
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={`Back to Suites`}
                    onClick={() => {
                      navigate(`/projects/${activeProject?.id}/api-suites/automate`);
                    }}
                    size={'small'}
                    sx={{
                      width: 200,
                      borderRadius: '0.3rem',
                      px: 0.5,
                      py: 2,
                      mt: -4
                    }}
                  />
                </Stack>
              )}
            {!suiteRuns.length &&
              !fetchingAllRuns &&
              ((!!dateRange?.[0] && !!dateRange?.[1]) || !!filterByStatus.value) && (
                <Stack alignItems={'center'}>
                  <EmptyState
                    title="Result not  found"
                    description="No result matches your filter option"
                    sx={{ mt: 15 }}
                  />
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={`Back to Suites Runs`}
                    onClick={async () => {
                      const newParams = new URLSearchParams(searchParams);
                      newParams.delete('date_from');
                      newParams.delete('date_to');
                      newParams.delete('status');
                      setSearchParams(newParams);
                      setDateRange(null);
                      setFilterByStatus(_resultFIlter[0]);
                    }}
                    size={'small'}
                    sx={{
                      width: 200,
                      borderRadius: '0.3rem',
                      px: 0.5,
                      py: 2,
                      mt: -4
                    }}
                  />
                </Stack>
              )}

            {suiteRuns.length > 0 && (
              <Flex justifyContent={'space-between'}>
                {' '}
                <Typography color={'primary'} fontSize={16} fontWeight={500}>
                  API Suite Runs
                </Typography>
                {!runId && (
                  <Flex>
                    <DateRangePicker
                      shouldDisableDate={afterToday()}
                      appearance={'default'}
                      style={{ width: 210, marginRight: '10px' }}
                      value={dateRange}
                      onChange={handleChangeDatePicker}
                      placement={'bottom'}
                      ranges={Ranges}
                      size={'sm'}
                      placeholder={'Filter by Date Range'}
                    />
                    <StatusFilter
                      selectedFilter={filterByStatus}
                      setSelectedFilter={setFilterByStatus}
                      filterOptions={_resultFIlter}
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                      query={'status'}
                      defaultButtonLabel={'Filter by Result'}
                    />
                  </Flex>
                )}
              </Flex>
            )}
            {!!fetchingAllRuns && !runId && <LoadingComponent />}
            {/* {!!refetchingRuns && !runId && <LoadingComponent />} */}
            {!!fetchedAllRuns && !fetchingAllRuns && !runId && (
              <Stack gap={4}>
                <SuiteRunsList />
                {paginate?.total > 15 && (
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{
                      mb: { xs: 6, sm: 8 }
                    }}>
                    <Pagination
                      count={paginate?.last_page}
                      page={activePage}
                      color="secondary"
                      onChange={handlePageChange}
                      size={'small'}
                      sx={{
                        '& .Mui-selected': {
                          backgroundColor: '#24C3D9',
                          color: '#ffffff'
                        },
                        '& .MuiPaginationItem-root:not(.Mui-selected)': {
                          backgroundColor: '#1958B8',
                          color: '#fff'
                        }
                      }}
                    />

                    <FormControl sx={{ mr: 5 }}>
                      <InputLabel id="go-to-page-label">Tests per page</InputLabel>
                      <Select
                        labelId={'go-to-page-label'}
                        value={pageLimit}
                        label={'Tests per page'}
                        size={'small'}
                        MenuProps={{
                          elevation: 1
                        }}
                        onChange={handleSelectPageChange}>
                        {[...Array(paginate?.last_page).keys()].map((page, i) => (
                          <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                            {(page + 1) * DEFAULT_PAGE_SIZE}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Stack>
            )}

            {!!runId && (
              <Box>
                <Typography
                  color={'primary'}
                  sx={{
                    // borderBottom: '1px solid rgba(220,220,220)',
                    borderTop: '1px solid rgba(220,220,220)',
                    py: 1,
                    mb: 1
                  }}>
                  Run Details
                </Typography>
                {!!activeRun?.results?.summary?.test_cases && fetchedSingleRun && (
                  <Flex columnGap={0} my={'5px'}>
                    <Chip
                      label={'All'}
                      variant={'outlined'}
                      onClick={() => {
                        setRunsResultTab(0);
                      }}
                      sx={{
                        borderRadius: 0,
                        border: 'none',
                        bgcolor:
                          runsResultsTab === 0
                            ? 'rgba(220,220,220, 0.5)'
                            : theme.palette.background.lightGrey_dark,
                        color: runsResultsTab === 0 ? '#1958B8' : 'inherit',
                        py: 1,
                        px: 1.5,
                        '&: hover': {
                          color: '#1958B8',
                          backgroundColor: 'rgba(220,220,220, 0.6)'
                        }
                      }}
                    />
                    <Chip
                      label={'Passed'}
                      variant={'outlined'}
                      onClick={() => {
                        const rez = filterFailedOrPaassed(
                          JSON.stringify(activeRun.results.summary),
                          'passed'
                        );
                        setRunsResultTab(1);
                        setPassed(rez);
                      }}
                      sx={{
                        border: 'none',
                        borderRadius: 0,
                        bgcolor:
                          runsResultsTab === 1
                            ? 'rgba(220,220,220, 0.5)'
                            : theme.palette.background.lightGrey_dark,
                        color: runsResultsTab === 1 ? '#1958B8' : 'inherit',
                        py: 1,
                        px: 1,
                        '&: hover': {
                          color: '#1958B8',
                          backgroundColor: 'rgba(220,220,220, 0.6)'
                        }
                      }}
                    />
                    <Chip
                      label={'Failed'}
                      variant={'outlined'}
                      onClick={() => {
                        const rez = filterFailedOrPaassed(
                          JSON.stringify(activeRun.results.summary),
                          'failed'
                        );
                        setRunsResultTab(2);
                        setFailed(rez);
                      }}
                      sx={{
                        borderRadius: 0,
                        border: 'none',
                        bgcolor:
                          runsResultsTab === 2
                            ? 'rgba(220,220,220, 0.5)'
                            : theme.palette.background.lightGrey_dark,
                        color: runsResultsTab === 2 ? '#1958B8' : 'inherit',
                        py: 1,
                        px: 1,
                        '&: hover': {
                          color: '#1958B8',
                          backgroundColor: 'rgba(200,200,200, 1)'
                        }
                      }}
                    />
                    <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
                    <Typography ml={1} fontSize={13} color={'primary'}>
                      {activeRun?.api_suite?.name} /
                    </Typography>
                    <Typography ml={1} fontSize={13} color={'primary'}>
                      {getDateTime(activeRun?.created_at)}
                    </Typography>
                  </Flex>
                )}

                {/* <CustomScrollbar
                  sx={{
                    overflowY: 'auto',
                    maxHeight: '300px',
                    mt: 0,
                    width: '100%'
                  }}> */}
                <Flex
                  sx={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  {fetchingSingleRun && (
                    <Stack gap={1} sx={{ width: '100%' }}>
                      {arrayOfNumbers.map((num) => {
                        return (
                          <Skeleton
                            key={num}
                            animation="wave"
                            variant="rectangular"
                            width={'100%'}
                            height={25}
                          />
                        );
                      })}
                    </Stack>
                  )}
                  {!!activeRun?.results?.summary?.test_cases && !fetchingSingleRun && runId && (
                    <>
                      {runsResultsTab === 0 &&
                        filterFailedOrPaassed(
                          JSON.stringify(activeRun.results.summary),
                          'all'
                        ).test_cases?.map((testCase, index) => {
                          return (
                            <EachTest key={index} testCase={testCase} responseRef={responseRef} />
                          );
                        })}
                      {runsResultsTab === 1 &&
                        (!!AllPassed.test_cases?.length ? (
                          AllPassed.test_cases?.map((testCase) => {
                            return <EachTest testCase={testCase} responseRef={responseRef} />;
                          })
                        ) : (
                          <IsFailedOrPassedRuns text={'No Run with passed assertions'} />
                        ))}
                      {runsResultsTab === 2 &&
                        (!!AllFailed.test_cases?.length ? (
                          AllFailed.test_cases?.map((testCase) => {
                            return <EachTest testCase={testCase} responseRef={responseRef} />;
                          })
                        ) : (
                          <IsFailedOrPassedRuns text={'No Run with failed assertions'} />
                        ))}
                      <Box ref={responseRef}>
                        <TestResultDisplayPanel />
                      </Box>
                    </>
                  )}
                </Flex>
                {/* </CustomScrollbar> */}
              </Box>
            )}
          </Stack>
        </>
      )}
    </Flex>
  );
};
const EachTest = ({ testCase, responseRef }) => {
  const { projectId } = useParams();
  const statusCodeColorMap = {
    2: '#00CA08',
    4: rgba('#EF1B1B', 70)
  };
  const testCaseResponseSize = prettyBytes(JSON.stringify(cleanJson(testCase?.body)).length);
  let bodyType;
  const { activeTestResult, setActiveTestResult, setResponseEditorState } =
    useContext(TestAutomationContext);
  let responseContentType = testCase?.headers?.find((header) => {
    return header.key === 'Content-Type';
  });
  bodyType = responseContentType?.value?.split(';')[0].split('/')[1];
  return (
    <Stack
      alignContent={'flex-start'}
      gap={0.5}
      key={testCase.name}
      width={'100%'}
      onClick={() => {
        responseRef.current?.scrollIntoView({ behaviour: 'smooth' });
        setResponseEditorState((prev) => {
          if (bodyType === 'json') {
            return { ...prev, value: JSON.stringify(testCase?.body, null, 2) };
          } else {
            return { ...prev, value: JSON.stringify(testCase?.body, null, 2) };
          }
        });
        setActiveTestResult(testCase);
      }}>
      <Flex
        sx={{
          py: 1,
          pl: 0.9,
          background:
            activeTestResult?.id === testCase.id
              ? 'rgba(220, 220, 220, .6)'
              : 'rgba(220, 220, 220, .)',
          '&:hover': {
            background: 'rgba(220, 220, 220, 0.2)',
            cursor: 'pointer'
          },
          borderLeft: activeTestResult?.id === testCase.id && '3px solid #1976d2'
        }}>
        <Typography
          component={'span'}
          variant={'body2'}
          textTransform={'uppercase'}
          fontWeight={400}
          sx={{
            color: colorMap[testCase.request_method.toLowerCase()] || 'black'
          }}>
          {testCase.request_method}
        </Typography>
        <Typography
          variant={'body2'}
          component={'span'}
          sx={{
            width: 'inherit',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}>
          {testCase.name}
        </Typography>
        <Flex
          sx={{
            justifyContent: 'flex-start',
            width: '400px',
            ml: 'auto'
          }}>
          <Tooltip title={'open test'}>
            <Link to={`/projects/${projectId}/api-suites/${testCase.id}/edit`}>
              <OpenInNewIcon
                fontSize="small"
                color="primary"
                sx={{
                  mt: 0.5
                }}
              />
            </Link>
          </Tooltip>

          <Flex>
            <Typography fontSize={12}>status</Typography>
            <Chip
              size="small"
              label={testCase.status_code}
              sx={{
                ...chipStyle,
                color: statusCodeColorMap[testCase.status_code.toString().charAt(0)]
              }}
            />
          </Flex>
          <Divider orientation="vertical" flexItem />
          <Flex>
            <Typography fontSize={12}>time taken</Typography>
            <Chip
              size="small"
              label={convertTimeToSeconds(testCase.time_taken)}
              sx={{ ...chipStyle }}
            />
          </Flex>
          <Divider orientation="vertical" flexItem />
          <Flex>
            <Typography fontSize={12}>size</Typography>
            <Chip
              size="small"
              label={testCase?.size ? prettyBytes(testCase?.size) : testCaseResponseSize}
              sx={{ ...chipStyle }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" flexItem />
    </Stack>
  );
};

const AssertionsList = ({ assertion }) => {
  return (
    <>
      {assertion.status_code && (
        <AssertionComponentForStatusCode
          key={JSON.stringify(assertion)}
          type={'status_code'}
          assertion={assertion}
          sx={{
            ...assertionStyle
          }}
          badgeProps={{ height: 8, width: 8 }}
        />
      )}
      {assertion.response_body_type && (
        <AssertionComponentForStatusCode
          assertion={assertion}
          type={'response_body_type'}
          sx={{
            ...assertionStyle
          }}
          badgeProps={{ height: 8, width: 8 }}
        />
      )}
      {assertion.request_duration && (
        <AssertionComponentForStatusCode
          assertion={assertion}
          type={'request_duration'}
          sx={{
            ...assertionStyle
          }}
          badgeProps={{ height: 8, width: 8 }}
        />
      )}
      {assertion.response_headers?.length &&
        assertion.response_headers.map((header) => (
          <AssertionComponentForResponseHeaders
            key={header.expected}
            assertion={header}
            badgeProps={{ height: 8, width: 8 }}
            type={'response_headers'}
            sx={{
              ...assertionStyle
            }}
          />
        ))}
      {assertion.response_body_content?.length &&
        assertion.response_body_content.map((bodyContent) => (
          <AssertionComponentForResponseHeaders
            assertion={bodyContent}
            type={'response_body_content'}
            badgeProps={{ height: 8, width: 8 }}
            sx={{
              ...assertionStyle
            }}
          />
        ))}
      {assertion.create_global_variable?.length &&
        assertion.create_global_variable.map((variableAssertions) => (
          <AssertionComponentForResponseHeaders
            assertion={variableAssertions}
            type={'create_global_variable'}
            badgeProps={{ height: 8, width: 8 }}
            sx={{
              ...assertionStyle
            }}
          />
        ))}
    </>
  );
};

const TestResultDisplayPanel = React.forwardRef(({ ref }) => {
  const { activeTestResult, responseEditorState, setResponseEditorState } =
    useContext(TestAutomationContext);
  let [assertionLength, setAssertionLength] = useState(0);
  let [passedAssertions, setPassedAssertions] = useState(0);
  let _body = activeTestResult?.body;
  let bodyType;
  const expectedMimeForFile = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'image',
    'jpeg',
    'png',
    'pdf'
  ];
  let responseContentType = activeTestResult?.headers?.find((header) => {
    return header.key.toLowerCase() === 'content-type';
  });
  bodyType = responseContentType?.value?.split(';')[0].split('/')[1];
  bodyType === 'plain' && (bodyType = 'plaintext');
  let responseIsAFile = expectedMimeForFile.includes(bodyType)
    ? { isFile: true, fileType: responseContentType?.value, fileBase64: _body.replace(/\s/g, '') }
    : { isFile: false };
  const [responseTab, setResponseTab] = useState(0);
  if (bodyType === 'json') {
    _body = activeTestResult?.body;
  }
  const handleResponseEditorStateChange = (newState) => {
    setResponseEditorState({ ...responseEditorState, ...newState });
  };
  useEffect(() => {
    let assertionObj = {};
    if (!!activeTestResult?.assertions?.length) {
      activeTestResult.assertions.map((entry) => {
        assertionObj = { ...assertionObj, ...entry };
      });
      let length = Object.values(assertionObj).filter(Boolean).flat().length;
      setAssertionLength(length);
      const passed = Object.values(assertionObj)
        .filter(Boolean)
        .flat()
        .filter((entry) => entry.status === 'passed').length;
      setPassedAssertions(passed);
    } else {
      setAssertionLength(0);
    }
  }, [activeTestResult]);

  return (
    <Box ref={ref}>
      {!!activeTestResult && (
        <Stack gap={2} sx={{ width: '100%', mt: 2 }}>
          <Tabs
            value={responseTab}
            onChange={(event, value) => setResponseTab(value)}
            indicatorColor={'secondary'}
            sx={{
              borderBottom: '1px solid #E3E3E3',
              minHeight: 0,
              maxHeight: 'max-content'
            }}
            TabIndicatorProps={{
              sx: { height: 3 }
            }}>
            {/* <Tab
              label={'Body'}
              disableRipple={true}
              sx={{
                ...tabStyle,
                mr: 3
              }}
            /> */}
            <Tab
              label={'Cookies'}
              disableRipple={true}
              sx={{
                ...tabStyle,
                mr: 3
              }}
            />
            <Tab
              // disabled={responseHeaders.length > 0 ? false : true}
              label={
                <Flex columnGap={'2px'}>
                  <Typography fontSize={'0.85rem'}>Headers</Typography>
                  <Typography
                    fontSize={'0.80rem'}
                    color={'primary'}
                    sx={{
                      borderRadius: '100%',
                      p: '3px'
                    }}>{`(${activeTestResult.headers?.length})`}</Typography>
                </Flex>
              }
              disableRipple={true}
              sx={{
                ...tabStyle,
                mr: 3
              }}
            />
            <Tab
              label={
                <Flex columnGap={'5px'}>
                  <Typography fontSize={'0.85rem'}>Assertion result</Typography>
                  {!!assertionLength && (
                    <Badge
                      sx={{
                        ml: 2.5
                      }}
                      color={!passedAssertions ? 'error' : 'primary'}
                      badgeContent={
                        <Flex
                          borderRadius={10}
                          sx={{
                            mt: 0.2,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <Typography fontSize={10}>
                            {passedAssertions}
                            <Typography display={'inline'} fontSize={10}>
                              /{assertionLength}
                            </Typography>
                          </Typography>
                        </Flex>
                      }
                    />
                  )}
                </Flex>
              }
              disableRipple={true}
              sx={{
                ...tabStyle,
                mr: 3
              }}
            />
          </Tabs>
          {/* <TabPanel value={responseTab} index={0}>
            {!!activeTestResult?.body && (
              <Box mb={2}>
                <ResponseEditor
                  checkForFile={responseIsAFile}
                  data={_body}
                  editorState={responseEditorState}
                  setEditorState={setResponseEditorState}
                  handleEditorStateChange={handleResponseEditorStateChange}
                  // copyResponseToClipBoard={copyResponseToClipBoard}
                  // requestSaveTestExampleModal={requestSaveTestExampleModal}
                  // hideSaveResponseBTN={hideSaveResponseBTN}
                />
              </Box>
            )}
          </TabPanel> */}
          <TabPanel value={responseTab} index={0}>
            <Flex justifyContent={'center'} flexDirection={'column'} mt={2}>
              <NoCookiesSvg />
              <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
                No cookies received from the server
              </Typography>
              <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
                All your cookies and their associated domains will appear here.
              </Typography>
            </Flex>
          </TabPanel>
          {activeTestResult?.headers?.length > 0 && (
            <TabPanel value={responseTab} index={1}>
              <ResponseHeader responseHeaders={activeTestResult?.headers} />
            </TabPanel>
          )}
          <TabPanel value={responseTab} index={2}>
            {activeTestResult?.assertions?.length > 0 &&
              activeTestResult?.assertions?.map((assertion, index) => {
                return <AssertionsList key={index} assertion={assertion} />;
              })}
            {!activeTestResult?.assertions?.length && (
              <Flex justifyContent={'center'} flexDirection={'column'} mt={2}>
                <NoTestAssertionsSvg />
                <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
                  There are no tests for this request
                </Typography>
                <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
                  Create assertions to automate debugging
                </Typography>
              </Flex>
            )}
          </TabPanel>
        </Stack>
      )}
    </Box>
  );
});

export default ApiSuiteRuns;
