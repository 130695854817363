export const capitalize = ([first, ...rest]) => {
  return `${first.toUpperCase()}${rest.join('').toLowerCase()}`;
};

/**
 * strip leading slashes
 * @param path
 * @returns {*}
 */
export const stripLeadingSlash = (path) => {
  return path.indexOf('/') === 0 ? path.slice(1) : path;
};

export const pluralize = (count, str, pluralForm = `${str}s`) => {
  return count === 1 ? str : pluralForm;
};
