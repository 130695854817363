import { Box, Checkbox, Typography, styled } from '@mui/material';
import { rgba } from '../../../utils/colors';

const GridItem = styled(Box)(() => ({
  '&:last-of-type': {
    // marginRight: "auto",
  }
}));

const Badge = ({ status }) => {
  const colors = {
    success: '#00CA08',
    error: '#EF1B1B',
    skipped: '#D4D2D2',
    ignored: '#474747'
  };

  const texts = {
    success: 'Passed',
    error: 'Failed',
    skipped: 'Skipped',
    ignored: 'Ignored'
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        py: 0.2,
        width: { xs: '50px', sm: '64px' },
        backgroundColor: colors[status],
        color: '#fff',
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: '0.7rem',
        borderTopLeftRadius: 5,
        zIndex: 1,
        borderBottomRightRadius: 5
      }}>
      {texts[status]}
    </Box>
  );
};

const EventCard = ({ index, action, toggleStepsSelection, selectedStepIndexes }) => {
  return (
    <GridItem
      backgroundColor={rgba('#24C3D9', 33)}
      sx={{
        borderRadius: '8px',
        position: 'relative'
      }}>
      <>
        {action.status && <Badge status={action.status} />}
        <Box
          sx={{
            width: '100%',
            borderRadius: '8px',
            height: '8rem',
            position: 'relative',
            overflow: 'hidden'
          }}>
          <Box
            position={'absolute'}
            right={0}
            top={0}
            backgroundColor={'#FFF'}
            sx={{ borderBottomLeftRadius: '8px' }}>
            <Checkbox
              size={'small'}
              onChange={() => toggleStepsSelection(action.id)}
              checked={selectedStepIndexes.includes(action.id)}
            />
          </Box>
          <Box
            as={'img'}
            src={'/images/blank-img.png'}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
        <Box position={'relative'} sx={{ px: 1.5, py: 1.5 }} height={'4rem'}>
          {/* <ActionIcons
              isExpanded={isActionsExpanded}
              onExpand={onExpandActions}
              onExpandSettings={onExpandSettings}
              onViewDetails={onViewDetails}
              onStartPlayback={onStartPlayback}
              disabled={disabled}
              onInsertBreakpoint={onInsertBreakpoint}
              onStartRecording={onStartRecording}
            /> */}
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: 600,
              whiteSpace: 'nowrap'
            }}>
            Step {index + 1}
          </Typography>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: '0.6rem',
              maxWidth: 250,
              color: '#787878'
            }}>
            {`${action.type} on ${
              action?.element?.attributes?.text ||
              action?.element?.accessibilityElements?.[0]?.accessibilityLabel ||
              action?.value ||
              ''
            }`}
          </Typography>
        </Box>
      </>
    </GridItem>
  );
};

export default EventCard;
