import { Box, Menu, MenuItem, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import Flex from '../../../components/base/Flex';
import { useMenu } from '../../../components/base/useMenu';
import CustomButton from '../../../components/customButton';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { ColorContext } from '../../../AppTheme';

const ProjectFilter = ({ value, setValue, tags }) => {
  const { triggerProps, menuProps } = useMenu();
  const theme = useTheme();
  const { mode } = useContext(ColorContext)

  const onSelectAll = () => {
    setValue(null);
    menuProps.onClose();
  };

  const onSelectApp = (tag) => {
    setValue(tag);
    menuProps.onClose();
  };

  return (
    <Box>
      <CustomButton
        disableRipple
        disableFocusRipple
        disableElevation
        {...triggerProps}
        fullWidth
        variant={'outlined'}
        label={
          <Flex>
            <Typography variant={'body2'}>{value?.name?.en || 'Filter by Tags'}</Typography>
            {menuProps.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Flex>
        }
        sx={{
          textAlign: 'center',
          textTransform: 'capitalize',
          borderRadius: '0.4rem',
          color: theme.palette.button.textOutlined,
          border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
          py: { xs: '0.2rem', sm: '0.25rem' },
          px: 2,
          mr: 1,
          width: 'max-content',
          '&: hover': {
            background: 'transparent'
          }
        }}
      />
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <MenuItem
          onClick={onSelectAll}
          sx={{ color: value?.name?.en ? 'inherit' : theme.palette.text.black_white }}>
          <Flex>
            <DoneIcon sx={{ visibility: value?.name?.en ? 'hidden' : 'visible' }} />
            All
          </Flex>
        </MenuItem>
        {(tags || [])?.map((option, index) => (
          <MenuItem
            onClick={() => onSelectApp(option)}
            sx={{
              color:
                option?.name?.en === value?.name?.en ? theme.palette.text.black_white : 'inherit'
            }}>
            <Flex>
              <DoneIcon
                sx={{ visibility: option?.name?.en === value?.name?.en ? 'visible' : 'hidden' }}
              />
              {option?.name?.en || ''}
            </Flex>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ProjectFilter;
