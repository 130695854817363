import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch
} from '@mui/material';
import { useScandiumRemoteMutation } from '../../../data-layer/remote-execution';
import ProtocolMenu, { getProtocolFromString } from '../../TestPage/components/ProtocolMenu';
import { getBaseUrl } from '../../TestPage';
import UserContext from '../../../store/userState';
import { useTheme } from '@emotion/react';

const RemoteRunsModal = ({ open, onClose, value, onComplete, suite }) => {
  const [url, setUrl] = React.useState(suite?.starting_url || '');
  const [takeScreenshot, setTakeScreenshot] = React.useState(true);
  const [protocol, setProtocol] = React.useState('https://');
  const [showSuccessMesssage, setSuccessMesssage] = React.useState(false);
  const { userData } = React.useContext(UserContext);
  const theme = useTheme();

  const { mutateAsync: runSuite, isLoading: isRunningTest } = useScandiumRemoteMutation(`/suite`, {
    headers: {
      'x-api-token': userData?.user?.api_token || ''
    },
    onSuccess: (data) => {
      onComplete();
      toast.success(
        'Your test suite runs have been successfully completed. Please check the Suite Runs result page for details'
      );
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handlerRunTestRemotely = async () => {
    const baseUrl = getBaseUrl(url);

    setTimeout(() => {
      setSuccessMesssage(true);
    }, 1500);

    await runSuite({
      suite_id: suite.id,
      project_id: suite.project_id,
      retry: 0,
      strategy: 'await',
      starting_url: url ? `${protocol}${baseUrl}` : undefined,
      screenshot: takeScreenshot
    });
  };

  const handleCloseModal = () => {
    setSuccessMesssage(false);
    onClose();
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'550px'}>
        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          sx={{
            fontSize: '1.4rem',
            mb: 3
          }}>
          Run Suite Remotely
        </Typography>

        <Box mb={3}>
          <Flex columnGap={0} alignItems={'stretch'} width={'100%'}>
            <ProtocolMenu
              protocol={protocol}
              setProtocol={(protocol) => {
                if (url) {
                  const _protocol = getProtocolFromString(url);
                  const baseUrl = getBaseUrl(url);
                  setUrl(`${_protocol ? protocol : ''}${baseUrl}`);
                }
                setProtocol(protocol);
              }}
            />
            <TextField
              fullWidth
              value={url}
              onChange={(event) => {
                const _protocol = getProtocolFromString(event.target.value);
                if (_protocol) setProtocol(_protocol);
                setUrl(event.target.value);
              }}
              size={'small'}
              label={'Starting url'}
              InputProps={{
                sx: {
                  paddingLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }
              }}
            />
          </Flex>
          <Typography variant={'caption'} color={theme.palette.text.black_grey}>
            Starting URL (optional). Use this field to set a common starting point for all test
            cases in this suite. Leave blank to use the individual starting URLs defined in each
            test case.
          </Typography>
        </Box>

        <Box mb={3}>
          <FormControlLabel
            control={
              <Switch
                checked={takeScreenshot}
                onChange={(e) => setTakeScreenshot(e.target.checked)}
              />
            }
            label="Screenshots"
          />
          <Typography variant={'body2'} color={theme.palette.text.black_grey}>
            Enhance your testing with screenshots, but remember: enabling this feature may slightly
            extend the duration of your remote test runs.
          </Typography>
          <Divider sx={{ my: 1 }} />
        </Box>

        {showSuccessMesssage && (
          <Box>
            <Typography variant={'body2'} sx={{ color: 'green' }}>
              We have started running your test in the background, you will be notified once the
              execution is completed. Feel free to close this modal
            </Typography>
            <Divider sx={{ my: 1 }} />
          </Box>
        )}

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={handleCloseModal}>Close</OutlinedButton>
          <ContainedButton
            isLoading={isRunningTest}
            disabled={isRunningTest}
            color={'primary'}
            onClick={handlerRunTestRemotely}
            autoFocus>
            Run
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default RemoteRunsModal;
