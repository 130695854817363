/**
 *
 * @param {MessageEvent} e
 * @param {string} extId
 * @param {(step: Object) => void} onEventRecorded
 * @param {Function} onEventPlayed
 * @param {(event: MessageEvent) => void} onStopRecording
 * @param {(data: Object) => void} onPlaybackEnded
 */
export const extensionEventHandler = (
  e,
  extId,
  { onEventRecorded, onEventPlayed, onStopRecording, onPlaybackEnded, onPlayPaused }
) => {
  if (e.data && e.data.from === `sc_${extId}`) {
    if (process.env.NODE_ENV === 'development') console.log(extId, 'received event', e.data);
    if (e.data.cmd === 'event-recorded') {
      //received when a new step has just been captured or updated
      onEventRecorded(e.data.event);
    }

    if (e.data.cmd === 'event-played') {
      onEventPlayed(e.data.event);
    }

    if (e.data.cmd === 'stop-recording') {
      //received when a user stops recording directly from the extension
      onStopRecording(e);
    }

    if (e.data.cmd === 'paused') {
      //received when playback is paused directly from the extension
      onPlayPaused(e);
    }

    if (e.data.cmd === 'playback-ended') {
      // received when the playback has ended
      onPlaybackEnded(e.data.data);
    }
  }
};

export const getUserBrowser = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.match(/chrome|chromium|crios/i) && !userAgent.match(/edg/i)) {
    return 'chrome';  // Make sure it's not Edge disguised as Chrome
  } else if (userAgent.match(/firefox|fxios/i)) {
    return 'firefox';
  } else if (userAgent.match(/safari/i) && !userAgent.match(/chrome/i)) {
    return 'safari';  // Make sure it's not Chrome disguised as Safari
  } else if (userAgent.match(/opr\//i)) {
    return 'opera';
  } else if (userAgent.match(/edg/i)) {
    return 'edge';
  }
};

export const getUserOS = () => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform;
  if (platform.indexOf('Win') > -1) return 'windows';
  if (platform.indexOf('mac') > -1) return 'macos';
  if (platform.indexOf('Linux') > -1) return 'linux';
};

export function getCurrentDate(dateString) {
  const date = dateString ? new Date(dateString) : new Date();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const month = months[date.getMonth()];
  let day = date.getDate();
  let ordinal = 'th';
  if (day === 1 || day === 21 || day === 31) {
    ordinal = 'st';
  } else if (day === 2 || day === 22) {
    ordinal = 'nd';
  } else if (day === 3 || day === 23) {
    ordinal = 'rd';
  }
  const year = date.getFullYear();

  return `${month}. ${day}${ordinal}, ${year}`;
}

const getGroups = () => {
  return localStorage.getItem('groups') ? JSON.parse(localStorage.getItem('groups')) : [];
};
