import React from 'react';
import { useCookies } from 'react-cookie';
import { useScandiumQuery } from '../data-layer/utils';

export const UserContext = React.createContext({
  userData: {},
  fetchUserError: null,
  isUserDataLoading: false,
  fetchUserData: () => {}
});

export const UserContextProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['token', 'email', 'name', 'user']);

  const {
    data: userData = {},
    error: fetchUserError,
    isLoading: isUserDataLoading,
    refetch: fetchUserData
  } = useScandiumQuery('/user/account', {
    onSuccess: (data) => {
      setCookie('name', data.user.name, { path: '/', sameSite: 'lax', secure: true });
      setCookie('user', data.user, { path: '/', sameSite: 'lax', secure: true });
      setCookie('local_features', data.user.enabled_features, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
      setCookie('active_company', data.user.active_company, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
      setCookie('company', data.user.company, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
      setCookie('designation', data.user.active_company_designation, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
      setCookie('role', data.user.active_company_role, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
      setCookie('memberships', data.user.company_memberships, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });  
    }
  });

  return (
    <UserContext.Provider
      value={{
        userData,
        fetchUserError,
        isUserDataLoading,
        fetchUserData
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
