import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import OutlinedButton from '../../../components/base/OutlinedButton';
import { useActiveProject } from '../../../store/projectState';
import ReplaySvg from '../../../components/svg/ReplaySvg';
import { useMenu } from '../../../components/base/useMenu';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import useAwaitModal from '../../../hooks/useAwaitModal';
import RemoteRunsModal from './RemoteRunsModal';
import { useTheme } from '@emotion/react';

const RunSuiteMenu = ({ suite, onLocalRun }) => {
  const activeProject = useActiveProject();
  const { menuProps, triggerProps } = useMenu();
  const theme = useTheme();

  const handleLocalRunClick = () => {
    onLocalRun();
    menuProps.onClose();
  };

  const [
    requestRemoteRunModal,
    { open: openRemoteRunModal, onClose: onCloseRemoteRunModal, onComplete: completeRemoteRunModal }
  ] = useAwaitModal();

  const { isFeatureEnabled: enabledRemoteExecutionFeature } = useFeatureEnabled('remote-execution');

  const readyOnly = activeProject?.read_only;
  return (
    <>
      <OutlinedButton
        startIcon={<ReplaySvg fill={theme.palette.svg.primary} disabled={!!readyOnly || !suite} />}
        {...triggerProps}
        disabled={!!readyOnly || !suite}
        sx={{
          borderRadius: '0.4rem',
          bgcolor: 'primary',
          py: { xs: '0.2rem', sm: '0.3rem' },
          px: 1.5,
          minHeight: '30px'
        }}>
        Run Suite
      </OutlinedButton>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{ style: { width: 'max-content' } }}>
        <MenuItem divider onClick={handleLocalRunClick}>
          Run locally
        </MenuItem>
        <MenuItem
          disabled={!enabledRemoteExecutionFeature}
          onClick={() => {
            requestRemoteRunModal();
            menuProps.onClose();
          }}>
          Run remotely{' '}
          {!enabledRemoteExecutionFeature && (
            <Chip
              label="Premium"
              color="primary"
              sx={{ fontSize: '0.7rem', ml: 1 }}
              size={'small'}
            />
          )}
        </MenuItem>
      </Menu>
      <RemoteRunsModal
        open={openRemoteRunModal}
        onClose={onCloseRemoteRunModal}
        onComplete={completeRemoteRunModal}
        suite={suite}
      />
    </>
  );
};

export default RunSuiteMenu;
