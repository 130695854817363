import { useRef, useContext } from 'react';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WrapTextIcon from '@mui/icons-material/WrapText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Flex from '../../../components/base/Flex';
import { useMenu } from '../../../components/base/useMenu';
import SaveSvg from '../../../components/svg/SaveSvg';
import CopySvg from '../../../components/svg/CopySvg';
import SearchSvg from '../../../components/svg/SearchSvg';
import { Card, CardMedia } from '@mui/material';
import { FloatingVariableWindowContext } from '../../../store/floatingVariableWindowState';
import { ColorContext } from '../../../AppTheme';
import { useTheme } from '@emotion/react';

const ResponseLanguage = ({ editorState, handleEditorStateChange }) => {
  //these two parameters are state and its handler function
  const { triggerProps, menuProps } = useMenu();
  const theme = useTheme();

  const handleLanguageChange = (str) => {
    handleEditorStateChange({
      responseEditorLanguage: str
    });
    menuProps.onClose();
  };

  const textMap = {
    json: 'JSON',
    xml: 'XML',
    html: 'HTML',
    plaintext: 'Text'
  };

  return (
    <Box>
      <Button
        size={'small'}
        {...triggerProps}
        variant={'text'}
        sx={{
          borderRadius: '0.3rem',
          border: 'none',
          bgcolor: theme.palette.background.lightGrey_dark,
          py: '6px',
          px: '8px',
          color: 'inherit',
          '&: hover': {
            color: '#1958B8'
          }
        }}>
        {textMap[editorState.responseEditorLanguage] || ''}
        <ExpandMore ml={1} color={'inherit'} />
      </Button>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <MenuItem onClick={() => handleLanguageChange('json')}>JSON</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('plaintext')}>Text</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('html')}>HTML</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('xml')}>XML</MenuItem>
      </Menu>
    </Box>
  );
};
export const ResponseImageDisplay = ({ fileState }) => {
  let fileSrc;
  fileSrc = `data:${fileState.fileType};base64,${fileState.fileBase64}`;
  //:: backend could return the full string and save the stress of appending - data:${fileState.fileType};base64,${fileState.fileBase64}
  return (
    <Card>
      {fileState.fileType === 'application/pdf' ? (
        <CardMedia
          component="iframe"
          title="PDF viewer"
          src={fileSrc}
          theme={'vs-light'}
          style={{ width: '50%', objectFit: 'contain' }}
        />
      ) : (
        <CardMedia
          component="img"
          alt="if the repsonse contains an image"
          height="400"
          src={fileSrc}
          // src=""
          theme={'vs-light'}
          style={{ width: '50%', objectFit: 'contain' }}
        />
      )}
    </Card>
  );
};

const ResponseEditor = ({
  checkForFile,
  data,
  setEditorState,
  editorState,
  handleEditorStateChange,
  copyResponseToClipBoard,
  requestSaveTestExampleModal,
  showSaveResponseBTN
}) => {
  const theme = useTheme();
  const { mode } = useContext(ColorContext);
  const { setSaveHighLightedTextState, setEditorCursorPosition, jsonResponseFields } = useContext(
    FloatingVariableWindowContext
  );
  const editorRef = useRef(null);
  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
  };
  const handleTextHighLight = (e) => {
    if (
      editorState.responseEditorLanguage === 'json' ||
      editorState.responseEditorLanguage === 'plaintext'
    ) {
      let highLightedText;
      const selection = editorRef.current.getSelection();
      if (selection) {
        let model = editorRef.current.getModel();
        let selectedText = model.getValueInRange(selection);
        highLightedText = selectedText;
      }
      const highLightIsField = jsonResponseFields.keys.includes(highLightedText);
      if (highLightedText.trim().length > 0 && !highLightIsField) {
        setEditorCursorPosition({
          top: e.pageY,
          left: e.pageX
        });
        setSaveHighLightedTextState((prev) => {
          return {
            ...prev,
            highLightedValue: highLightedText.trim(),
            showModal: true,
            useCase: 'text'
          };
        });
        return;
      } else if (highLightedText.length > 0 && !!highLightIsField) {
        setEditorCursorPosition({
          top: e.pageY,
          left: e.pageX
        });
        setSaveHighLightedTextState((prev) => {
          return {
            ...prev,
            highLightedValue: highLightedText.trim(),
            showModal: true,
            useCase: 'field'
          };
        });
        return;
      } else {
        setSaveHighLightedTextState((prev) => {
          return { ...prev, showModal: false };
        });
      }
    }
  };
  // Function to toggle the visibility of the search widget
  const toggleSearchWidget = () => {
    const controller = editorRef.current.getContribution('editor.contrib.findController');
    const isFindWidgetActive = controller.getState().isRevealed;

    if (isFindWidgetActive) {
      controller.closeFindWidget();
    } else {
      controller.start({
        forceRevealReplace: true
      });
    }
  };

  const onToggleWordWrap = () => {
    handleEditorStateChange({ wordWrap: editorState.wordWrap === 'on' ? 'off' : 'on' });
  };

  const handlePrettyClick = () => {
    handleEditorStateChange({
      lineNumbers: 'on',
      responseEditorLanguage: 'json',
      value: JSON.stringify(data, null, 2),
      activeBTN: 'pretty',
      wordWrap: 'on'
    });
  };

  const handleRawClick = () => {
    handleEditorStateChange({
      lineNumbers: 'off',
      responseEditorLanguage: 'plaintext',
      value: JSON.stringify(data, null, 2),
      activeBTN: 'raw',
      wordWrap: 'on'
    });
  };

  const handlePreviewClick = () => {
    handleEditorStateChange({
      lineNumbers: 'off',
      responseEditorLanguage: 'plaintext',
      value: JSON.stringify(data),
      activeBTN: 'preview',
      wordWrap: 'on'
    });
  };

  const editorOptions = {
    readOnly: false,
    fontSize: 12,
    lineNumbers: editorState.lineNumbers, // off or on
    wordWrap: editorState.wordWrap,
    minimap: {
      enabled: false
    }
  };
  return (
    <Box>
      {checkForFile.isFile === false ? (
        <>
          <Flex justifyContent={'space-between'}>
            <>
              <Flex columnGap={0} my={'5px'} justifyContent={'flex-start'}>
                <Chip
                  label="pretty"
                  variant="outlined"
                  onClick={handlePrettyClick}
                  sx={{
                    borderRadius: 0,
                    border: 'none',
                    bgcolor:
                      editorState.activeBTN === 'pretty'
                        ? 'rgba(220,220,220, 0.5)'
                        : theme.palette.background.lightGrey_dark,
                    color: editorState.activeBTN === 'pretty' ? '#1958B8' : 'inherit',
                    py: 1,
                    '&: hover': {
                      color: '#1958B8'
                    }
                  }}
                />
                <Chip
                  label="Raw"
                  variant="outlined"
                  onClick={handleRawClick}
                  sx={{
                    border: 'none',
                    borderRadius: 0,
                    bgcolor:
                      editorState.activeBTN === 'raw'
                        ? 'rgba(220,220,220, 0.5)'
                        : theme.palette.background.lightGrey_dark,
                    color: editorState.activeBTN === 'raw' ? '#1958B8' : 'inherit',
                    py: 1,
                    '&: hover': {
                      color: '#1958B8'
                    }
                  }}
                />
                <Chip
                  label="Preview"
                  variant="outlined"
                  onClick={handlePreviewClick}
                  sx={{
                    borderRadius: 0,
                    border: 'none',
                    bgcolor:
                      editorState.activeBTN === 'preview'
                        ? 'rgba(220,220,220, 0.5)'
                        : theme.palette.background.lightGrey_dark,
                    color: editorState.activeBTN === 'preview' ? '#1958B8' : 'inherit',
                    py: 1,
                    '&: hover': {
                      color: '#1958B8'
                    }
                  }}
                />

                {editorState.activeBTN === 'pretty' && (
                  <Flex ml={2}>
                    <ResponseLanguage
                      editorState={editorState}
                      handleEditorStateChange={handleEditorStateChange}
                    />
                    <Tooltip title={'Wrap Line'}>
                      <IconButton
                        size={'small'}
                        onClick={onToggleWordWrap}
                        sx={{
                          borderRadius: '0.3rem',
                          border: 'none',
                          bgcolor: theme.palette.background.lightGrey_dark,
                          py: '6px',
                          px: '8px'
                        }}>
                        <WrapTextIcon
                          color={editorState.wordWrap === 'on' ? 'primary' : 'inherit'}
                        />
                      </IconButton>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            </>

            <Flex justifyContent={'center'} columnGap={0}>
              <Tooltip title={'Copy'}>
                <IconButton size={'medium'} onClick={copyResponseToClipBoard}>
                  <CopySvg />
                </IconButton>
              </Tooltip>
              {editorState.activeBTN === 'pretty' && (
                <Tooltip title={'Search'}>
                  <IconButton size={'medium'} onClick={toggleSearchWidget}>
                    <SearchSvg />
                  </IconButton>
                </Tooltip>
              )}
              {showSaveResponseBTN && (
                <Tooltip title={'Save this response'}>
                  <Chip
                    label={`Save as Example`}
                    onClick={() => requestSaveTestExampleModal()}
                    size={'small'}
                    color={'primary'}
                    icon={<SaveSvg width={10} height={10} />}
                    sx={{
                      borderRadius: '0.3rem',
                      py: 1.5,
                      px: '6px',
                      ml: 1
                    }}
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
          <Box
            onMouseUp={(event) => {
              handleTextHighLight(event);
            }}>
            <Editor
              height={'235px'}
              find={editorState.activeBTN === 'pretty'}
              width={'100%'}
              language={editorState.responseEditorLanguage}
              readOnly={true}
              theme={mode === 'dark' && 'vs-dark'}
              value={editorState.value}
              options={editorOptions}
              onMount={handleEditorDidMount}
            />
          </Box>
          <Divider />
        </>
      ) : (
        <ResponseImageDisplay fileState={checkForFile} />
      )}
    </Box>
  );
};

export default ResponseEditor;
