import React, { useState, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import CustomButton from '../../components/customButton';
import { Details, EditProfile, ImageComponent, MainProfile } from '../../components/personalInformation';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumMutation } from '../../data-layer/utils';
import UserContext from '../../store/userState';
import { toast } from 'react-toastify';

function PersonalInformation() {
  useDocumentTitle('Settings - Personal Information');
  const { userData, fetchUserData } = useContext(UserContext)
  const [cookies] = useCookies(['token', 'email', 'name']);

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [companyMail, setCompanyMail] = useState();
  const [details, setDetails] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if(userData.user) {
      setFullName(userData.user.name);
      setEmail(userData.user.email);
      setCompanyMail(userData.user.company?.name);
    }
  }, [userData])

  const { mutateAsync } = useScandiumMutation('/user/account', {
    method: 'PUT',
    onSuccess: () => {
      setEditProfile(false);
      fetchUserData();
    }
  });

  const handleEditProfile = async (e) => {
    e.preventDefault();

    await mutateAsync({ name: fullName, company_name: companyMail });
  };

  return (
    <Box
      sx={{
        width: '100%',
        pl: { md: 5 },
        pt: 2,
      }}>
      {(details || editProfile) && (
        <Typography
          sx={{
            cursor: 'w-resize',
            textAlign: 'left',
            typography: { sm: 'subtitle1', md: 'subtitle1' },
            width: { xs: '5rem', sm: '5rem' },
            mb: { xs: 1.5, sm: 1.5 },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
          onClick={() => {
            setDetails(false);
            setEditProfile(false);
          }}>
          <KeyboardBackspaceRoundedIcon /> Back
        </Typography>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          mb: { xs: '5rem', sm: '5rem' },
          ml: 0
        }}>
        <Box>
          <ImageComponent name={cookies.name} backButton={details || editProfile} />
        </Box>
        {!details && !editProfile && (
          <Grid item xs={12} sm={7} md={7}>
            <Box
              sx={{
                mx: { xs: '0rem', sm: '0.9rem', md: '1.5rem' },
                width: '100%'
              }}>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontSize: { xs: '1.15rem', sm: '1.35rem' },
                  fontWeight: 'bold'
                }}>
                {details ? 'My Subscription' : 'My profile'}
              </Typography>
              {!details && !editProfile && (
                <MainProfile
                  setFullName={setFullName}
                  fullName={fullName}
                  setCompanyMail={setCompanyMail}
                  companyMail={companyMail}
                  setEmail={setEmail}
                  email={email}
                  setDetails={setDetails}
                  apiToken={userData?.user?.api_token || ''}
                />
              )}
            </Box>
          </Grid>
        )}
        {(details || editProfile) && (
          <Grid
            item
            xs={12}
            sm={9}
            md={9}
            sx={
              {
                // backgroundColor: 'orange',
              }
            }>
            <Box
              sx={{
                ml: { xs: '0rem', sm: '1.6rem', md: '2.7rem' },
                width: '100%'
                // backgroundColor: { xs: 'lawngreen', sm: 'blanchedalmond', md: ' beige' },
              }}>
              <Typography
                sx={{
                  textAlign: 'left',
                  // typography: { sm: 'h6', md: 'h6' },
                  fontSize: { xs: '1.15rem', sm: '1.35rem' },
                  fontWeight: 'bold'
                }}>
                {details ? 'My Subscription' : 'My profile'}
              </Typography>
              {editProfile && (
                <EditProfile
                  setFullName={setFullName}
                  fullName={fullName}
                  setCompanyMail={setCompanyMail}
                  companyMail={companyMail}
                  setEmail={setEmail}
                  email={email}
                  setEditProfile={setEditProfile}
                  handleEditProfile={handleEditProfile}
                  error={error}
                />
              )}
              {details && (
                <Box>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      typography: { sm: 'h6', md: 'h6' },
                      color: '#00CA08',
                      mt: { xs: 6, sm: 3 }
                    }}
                    // color='success'
                  >
                    Enterprise
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      // typography: { sm: 'h6', md: 'body2' },
                      typography: { sm: 'h6', md: 'caption' }
                      // pt: { xs: 6, sm: 3, }
                    }}>
                    Flexible, custom pricing for your business
                  </Typography>
                </Box>
              )}
              {details && <Details />}
            </Box>
          </Grid>
        )}
        {!details && !editProfile && (
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            sx={{
              display: 'flex',
              // justifyContent: 'flex-end',
              justifyContent: { xs: 'center', sm: 'flex-end' },
              alignItems: 'flex-start'
              // backgroundColor: 'blue',
              // width: '50%'
            }}>
            <CustomButton
              onClick={() => setEditProfile(true)}
              disableRipple={true}
              // disableFocusRipple
              disableFocusRipple={true}
              fullWidth
              disableElevation={true}
              // label='Update profile'
              label="Update"
              startIcon={<BorderColorOutlinedIcon color="primary" />}
              sx={{
                textAlign: 'center',
                textTransform: 'capitalize',
                typography: { xs: 'body1', sm: 'caption', md: 'body1' },
                borderRadius: '0.4rem',
                bgcolor: '#DEEAFD',
                color: 'black',
                width: { xs: '10.8rem', sm: '10.8rem' },
                // height: { xs: 3, sm: 20, },
                mt: { xs: '2rem', sm: '0rem' },
                mb: { xs: '3rem', sm: '0rem' },
                '&:hover': {
                  backgroundColor: '#DEEAFD'
                }
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default PersonalInformation;

// Slack-based support.
// Unlimited users.
// Unlimited test cases.
// On-premise available.
// SSO.
// SLA.
