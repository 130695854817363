import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { useParams } from 'react-router-dom';

const CreateFolder = ({ open, onClose, onComplete, refetchFolders, mobileId, folder }) => {
  const [name, setName] = React.useState(folder?.name || '');
  const { folderId } = useParams();
  const activeProject = useActiveProject();

  const { mutateAsync: postNewFolder, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/${folder?.id || ''}`,
    {
      enabled: !!activeProject?.id,
      method: folder?.id ? 'PATCH' : 'POST',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success(folder?.id ? 'Folder updated' : 'Folder successfully created');
        refetchFolders();
        onComplete();
      }
    }
  );

  const handlePostNewFolder = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error('Folder name is required');
      return;
    }

    const newFolderData = {
      name,
      target_resource: 'mobile-test',
      parent_id: folderId || undefined,
      mobile_app_id: mobileId
    }

    const renameFolderData = {
      name
    }

    const testData = folder?.id ? renameFolderData : newFolderData

    await postNewFolder({
      ...testData
    });
  };

  return (
    <CustomModal open={open}>
      <Box
        py={2}
        px={2}
        width={'80vw'}
        maxWidth={'500px'}
        component={'form'}
        onSubmit={handlePostNewFolder}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          {folder ? 'Rename folder' : 'Create folder'}
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Input a folder name below
        </Typography>

        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            autoFocus
            size={'small'}
            placeholder={'Name your folder'}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!name}
            isLoading={isLoading}
            type={'submit'}
            onClick={handlePostNewFolder}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateFolder;
