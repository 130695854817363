import React from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Box, CircularProgress, Menu, MenuItem, Tooltip } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { useContext } from 'react';
import { TestCaseContext } from '../../../store/testState';
import { useActiveProject } from '../../../store/projectState';

const TestActionMenu = React.forwardRef(
  ({ isLoading, onDelete, test, onEdit, onRename, exampleId, onDuplicate, useCase }, ref) => {
    const { setClickedTest, setClickedExample, setShowDestinationFolder, setFoldersState } =
      useContext(TestCaseContext);
    const { triggerProps, menuProps } = useMenu();
    const activeProject = useActiveProject();
    const readyOnly = activeProject?.read_only;
    const handleEdit = () => {
      menuProps.onClose();
      onEdit();
    };
    const { setShowDescriptionModal, setFocusedTestId } = useContext(TestCaseContext);

    return (
      <div>
        <Tooltip title={'View more actions'}>
          <IconButton
            ref={ref}
            size={'small'}
            sx={{ p: '4px' }}
            aria-controls={menuProps.open ? 'action-menu' : undefined}
            aria-haspopup="true"
            disabled={isLoading}
            aria-expanded={menuProps.open ? 'true' : undefined}
            onClick={async (e) => {
              e.stopPropagation();
              triggerProps.onClick(e);
              if (!!test) {
                if (useCase === 'test-example') {
                  setClickedExample(test);
                } else {
                  setClickedTest(test);
                }
              }
            }}>
            {isLoading ? (
              <CircularProgress size={18} color={'primary'} />
            ) : (
              <MoreHoriz color={'inherit'} />
            )}
          </IconButton>
        </Tooltip>
        <Menu
          id="action-menu"
          {...menuProps}
          PaperProps={{
            elevation: 0,
            style: {
              width: 200,
              minHeight: 100,
              paddingLeft: 5,
              paddingRight: 5
            },
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem onClick={handleEdit}>Open</MenuItem>
          {!exampleId && (
            <Box>
              <MenuItem disabled={!!readyOnly} onClick={() => onRename()}>
                Rename
              </MenuItem>
              <MenuItem disabled={!!readyOnly} onClick={() => onDuplicate()}>
                Duplicate
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFoldersState((prev) => {
                    return { ...prev, entityToMove: test, entityType: 'test-case' };
                  });
                  setShowDestinationFolder(true);
                }}
                disabled={!!readyOnly}>
                Move
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowDescriptionModal(true);
                }}
                disabled={!!readyOnly}>
                Add description
              </MenuItem>
            </Box>
          )}
          <MenuItem disabled={!!readyOnly} sx={{ color: 'red' }} onClick={onDelete}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default TestActionMenu;
