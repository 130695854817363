import { Box, styled, TableCell, tableCellClasses, TableRow } from '@mui/material';
import { rgba } from '../../utils/colors';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@emotion/react';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
    padding: '8px 0px',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    },
    '.cell-content-wrapper': {
      padding: '16px',
      backgroundColor: theme.palette.background.testCaseList,
      color: theme.palette.text.resultTableText,
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: '64px',
      display: 'flex',
      alignItems: 'center'
    },

    '&:first-child': {
      minWidth: '250px',
      '.cell-content-wrapper': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    },

    '&:last-child': {
      '.cell-content-wrapper': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:last-child td, &:last-child th': {
    border: 0
  },
  '&:focus td, &:focus th': {
    '.cell-content-wrapper': {
      borderTop: '1px solid #1958B8',
      borderBottom: '1px solid #1958B8'
    }
  },
  '&:focus th': {
    '&:first-of-type': {
      '.cell-content-wrapper': {
        borderLeft: '1px solid #1958B8'
      }
    }
  },
  '&:focus td': {
    '&:last-of-type': {
      '.cell-content-wrapper': {
        borderRight: '1px solid #1958B8'
      }
    }
  }
}));

export function createData(name, status) {
  return {
    name,
    environment: 'Chrome',
    started: 'Nov. 10th 2022  I  3:56pm',
    finished: 'Nov. 10th 2022  I  3:56pm',
    duration: '00:00:32',
    status
  };
}

export const rows = [
  createData('Random Testing', 'passed'),
  createData('Random Testing', 'running'),
  createData('Random Testing', 'failed'),
  createData('Random Testing', 'passed'),
  createData('Random Testing', 'running')
];

export const statusColors = {
  passed: '#00CA08',
  running: '#FFEA00',
  skipped: '#A4A4A4',
  ignored: '#474747',
  ignore: '#474747',
  failed: '#EF1B1B',
  error: '#EF1B1B',
  aborted: '#FF44FF',
};

export const statusMap = {
  success: 'passed',
  error: 'failed'
};

export const StatusTag = ({ size = 'medium', status }) => {
  const theme = useTheme()
  const colors = {
    passed: rgba('#00CA08', 19),
    running: rgba('#FFEA00', 20),
    aborted: rgba('#FF44FF', 20),
    skipped: '#D4D2D2',
    ignored: '#474747',
    ignore: '#474747',
    failed: rgba('#EF1B1B', 20),
    error: rgba('#EF1B1B', 20),
  };

  const _status = status in statusMap ? statusMap[status] : status;

  return (
    <Box
      sx={{
        py: size === 'small' ? 0.5 : 1.5,
        px: size === 'small' ? 1 : 2,
        backgroundColor: colors[_status],
        borderRadius: '24px',
        width: 'max-content'
      }}>
      <Typography
        sx={{ color: theme.palette.text.resultTableText, textTransform: 'capitalize', lineHeight: 1 }}
        fontSize={'0.8rem'}>
        {_status}
      </Typography>
    </Box>
  );
};
