import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContainedButton from '../../components/base/ContainedButton';
import GreenFancyCircle from '../../components/svg/GreenFancyCircle';
import Logo from '../../components/base/Logo';
import Flex from '../../components/base/Flex';
import useUpdateToken from '../../hooks/useUpdateToken';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';

function Register() {
  useDocumentTitle('Scandium - Create a New Account');
  let navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const { inviteCode } = useParams();
  const [cookies] = useCookies(['email']);
  const [email, setEmail] = useState(cookies?.email || '');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [company_name, setCompanyName] = useState('');
  const [company_domain, setCompanyDomain] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [role, setRole] = useState('');
  const [customRole, setCustomRole] = useState('');
  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [isTypingCustomValue, setIsTypingCustomValue] = useState(false);
  // const [accountType, setAccountType] = useState(urlParams.get('account_type') || 'personal');
  // const [selectedTab, setSelectedTab] = useState(accountType === 'personal' ? 0 : 1);

  const getCodeParam = urlParams.get('code');
  const accountType = urlParams.get('account_type') || 'personal';

  // const changeURLParam = (newAccountType) => {
  //   const currentURL = new URL(window.location.href);
  //   currentURL.searchParams.set('account_type', newAccountType);
  //   window.history.pushState({}, '', currentURL);
  //   setAccountType(currentURL.searchParams.get('account_type') || 'personal');
  // };

  const ALPHA_REGEX = /^[a-zA-Z -]+$/;

  const updateToken = useUpdateToken();

  const {
    mutateAsync,
    isLoading: showProgress,
    error
  } = useScandiumMutation(inviteCode ? `/join-project/${inviteCode}` : '/auth/register');

  const { isLoading: isValidatingInvite } = useScandiumQuery(
    `/company/verify-invite/${getCodeParam}`,
    {
      requireAuth: false,
      enabled: getCodeParam,
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        setCompanyDomain(data.company.domain);
        setCompanyName(data.company.name);
        setEmail(data.email);
        toast.success(data.message);
      }
    }
  );

  const options = [
    'Manual Tester',
    'QA Engineer/ SDET',
    'QA Lead',
    'Product Manager',
    'Software Developer',
    'C-suites Executive'
  ];

  const handleClickShowPassword = () => {
    setShowPassword((val) => !val);
  };

  const handleCustomRole = (e) => {
    setRole(customRole);
    setCustomRole('');
    setIsRoleOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsRoleOpen(false);
      setIsTypingCustomValue(false);
      setRole(customRole);
      return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    if (!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Email is required'
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Enter a valid email'
      }));
    }

    if (!name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: 'Full name is required'
      }));
    }

    if (!password) {
      setPasswordError('Password is required');
    }

    if (!company_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        company: 'Company name is required'
      }));
    }

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long.');
    } else if (!company_name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        company: 'Company name is required'
      }));
    } else if (!name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullName: 'Full name is required'
      }));
    } else if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'A valid email is required'
      }));
    } else {
      try {
        const testData = {
          email,
          name,
          password,
          company_name,
          role,
          account_type: accountType
        };
        if (accountType === 'enterprise' && company_domain)
          testData['company_domain'] = company_domain;
        if (getCodeParam) testData['code'] = getCodeParam;

        const data = await mutateAsync(testData);
        updateToken(data);
        navigate('/verify-email');
      } catch (error) {
        toast.error(error.message || 'An error occurred, please try again');
        if (error.status === 422) setErrorMessage(error.message);
      }
    }
  };

  const checkBoxLabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

  // Check if the error message is "Failed to fetch"
  const isOfflineError = error?.message === 'Failed to fetch';

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  return (
    <Box>
      <Grid container spacing={2} sx={{ height: '100vh' }}>
        <Grid
          item
          md={5}
          sx={{
            display: { xs: 'none', md: 'block' },
            height: '100%'
          }}>
          <div
            style={{
              overflow: 'hidden',
              backgroundColor: 'rgb(22,85,187)',
              height: '100vh',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <GreenFancyCircle
              style={{
                position: 'absolute',
                top: '-270px',
                right: '-170px',
                width: '400px'
              }}
            />
            <Box sx={{ px: 2, maxWidth: '350px' }}>
              <Typography fontSize={'1.2rem'} fontWeight={'400'} sx={{ color: 'white', mb: 3 }}>
                No-code Test Automation Tool.
              </Typography>
              <Typography fontSize={'2.5rem'} fontWeight={'500'} sx={{ color: 'white' }}>
                Create and maintain automated tests seamlessly
              </Typography>
            </Box>
            <GreenFancyCircle
              style={{
                position: 'absolute',
                bottom: '-270px',
                left: '-170px',
                width: '400px'
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={7} sx={{ overflowY: 'auto', height: '100%', pb: 5 }}>
          <Box
            sx={{
              mx: { xs: 3, sm: 4, md: 5 }
            }}>
            <Flex justifyContent={'space-between'} mt={{ xs: 6, sm: 3 }}>
              <Logo />
              <Typography
                sx={{
                  cursor: 'pointer',
                  typography: { sm: 'h6', md: 'h6' }
                }}
                onClick={() => navigate('/')}>
                Sign in
              </Typography>
            </Flex>

            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  mx: { xs: 0, sm: 6, md: 8 },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    typography: { sm: 'h6', md: 'h4' },
                    mt: { xs: 6, sm: 6, md: 6, lg: 15 }
                  }}>
                  Create a Scandium account.
                </Typography>
                {/* <Tabs
                  value={selectedTab}
                  variant={'fullWidth'}
                  sx={{ backgroundColor: '#F1F7FF', mt: 1 }}
                  onChange={(event, value) => setSelectedTab(value)}>
                  <Tab
                    label={'Personal'}
                    disableRipple={true}
                    onClick={() => changeURLParam('personal')}
                  />
                  <Tab
                    label={'Enterprise'}
                    disableRipple={true}
                    onClick={() => changeURLParam('enterprise')}
                  />
                </Tabs> */}
                <TextField
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== '' && !ALPHA_REGEX.test(value)) {
                      return;
                    }
                    setName(e.target.value);
                  }}
                  label={'Full Name'}
                  inputProps={{ maxLength: 150 }}
                  value={name}
                  type={'text'}
                  sx={{
                    mt: { xs: 2, sm: 2 }
                  }}
                />
                <TextField
                  required
                  onChange={(e) => setCompanyName(e.target.value)}
                  label={'Company Name'}
                  value={company_name}
                  inputProps={{ maxLength: 150, readOnly: getCodeParam }}
                  sx={{
                    mt: { xs: 2, sm: 2, md: 2, lg: 2 }
                  }}
                  InputProps={{
                    endAdornment: !isValidatingInvite ? undefined : (
                      <InputAdornment position="end">
                        <CircularProgress size={12} color={'secondary'} />
                      </InputAdornment>
                    )
                  }}
                />
                {/* {accountType === 'enterprise' && (
                  <TextField
                    required
                    size={'medium'}
                    onChange={(e) => setCompanyDomain(e.target.value)}
                    label={'Company Domain (e.g. getscandium.com)'}
                    value={company_domain}
                    inputProps={{ maxLength: 150, readOnly: getCodeParam }}
                    InputProps={{
                      endAdornment: !isValidatingInvite ? undefined : (
                        <InputAdornment position="end">
                          <CircularProgress size={12} color={'secondary'} />
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      mt: { xs: 2, sm: 2, md: 2, lg: 2 }
                    }}
                  />
                )} */}
                <TextField
                  type={'email'}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  label={
                    accountType === 'personal'
                      ? 'Email address'
                      : 'Work email address (e.g. example@getscandium.com)'
                  }
                  value={email}
                  inputProps={{ maxLength: 150, readOnly: getCodeParam }}
                  InputProps={{
                    endAdornment: !isValidatingInvite ? undefined : (
                      <InputAdornment position="end">
                        <CircularProgress size={12} color={'secondary'} />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    mt: { xs: 2, sm: 2, md: 2, lg: 2 }
                  }}
                  placeholder="example@example.com"
                />

                <Autocomplete
                  label="Role"
                  id={'autocomplete-role'}
                  options={options}
                  autoSelect
                  value={role}
                  sx={{ mt: 2 }}
                  onChange={(event, newValue) => {
                    setRole(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Role"}
                      required
                      placeholder={'Select a role or type a new one'}
                    />
                  )}
                  inputValueTransformer={(inputValue) => inputValue.toLowerCase()}
                  freeSolo
                />

                <TextField
                  type={showPassword ? 'text' : 'password'}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password (8+ Characters)"
                  value={password}
                  inputProps={{ maxLength: 150 }}
                  sx={{
                    mt: { xs: 2, sm: 2, md: 2, lg: 2 }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Box
                  sx={{
                    mt: { xs: 1, sm: 1 },
                    display: { xs: 'block', lg: 'flex' },
                    justifyContent: 'space-between'
                  }}>
                  <Box
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        lineHeight: '2px'
                      }
                    }}>
                    <Checkbox
                      {...checkBoxLabel}
                      checked={checked}
                      sx={{ pl: 0 }}
                      onChange={(event) => setChecked(event.target.checked)}
                    />
                    <Typography
                      component="span"
                      sx={{
                        textAlign: 'left',
                        typography: { xs: 'caption', sm: 'body2' },
                        color: '#A3A3A3',
                        lineHeight: '2px',
                        fontWeight: 'medium'
                      }}>
                      I have read, understood and I agree to Scandium{' '}
                    </Typography>
                    <Link
                      href={'https://getscandium.com/scandium-privacy-policy/'}
                      target={'_blank'}
                      underline={'none'}>
                      <Typography
                        component="span"
                        sx={{
                          cursor: 'pointer',
                          textAlign: 'right',
                          textDecoration: 'underline',
                          typography: { xs: 'caption', sm: 'body2' },
                          lineHeight: '2px'
                        }}>
                        Privacy Policy
                      </Typography>
                    </Link>
                    <Typography
                      component="span"
                      sx={{
                        textAlign: 'right',
                        mx: 0,
                        typography: { xs: 'caption', sm: 'body2' },
                        lineHeight: '2px',
                        color: '#A3A3A3'
                      }}>
                      {' '}
                      and{' '}
                    </Typography>
                    <Link
                      href={'https://getscandium.com/scandium-terms-of-use/'}
                      target={'_blank'}
                      underline={'none'}>
                      <Typography
                        component="span"
                        sx={{
                          cursor: 'pointer',
                          textAlign: 'right',
                          textDecoration: 'underline',
                          typography: { xs: 'caption', sm: 'body2' }
                        }}>
                        Terms &#38; Conditions
                      </Typography>
                    </Link>
                  </Box>
                </Box>
                <ContainedButton
                  disableRipple={true}
                  disabled={!checked}
                  onClick={() => trackMixPanel(TRACKING_IDS.SIGNUP_CLICKED, { type: 'signup' })}
                  isLoading={showProgress}
                  sx={{
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    mt: { xs: 4, sm: 4 },
                    py: 1.5
                  }}
                  type="submit">
                  Create account
                </ContainedButton>
                {error && (
                  <Typography style={{ fontSize: '12px' }} color="error" align="center">
                    {isOfflineError
                      ? "Oops! It seems like you're offline. Please check your internet connection and try again."
                      : error.message}
                  </Typography>
                )}
              </Box>
            </form>
            <Box
              sx={{
                mt: { xs: 9, sm: 9, md: 10, lg: 13 },
                mx: { xs: 6, sm: 8 },
                display: 'flex',
                // flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
              <Box>
                <Typography
                  component="span"
                  sx={{
                    textAlign: 'right',
                    typography: { xs: 'caption', sm: 'body2' },
                    pt: { xs: 6, sm: 3 },
                    color: '#A3A3A3'
                  }}>
                  Already have an account?{' '}
                </Typography>
                <Typography
                  component="span"
                  color="primary"
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'right',
                    typography: { xs: 'caption', sm: 'body2' },
                    pt: { xs: 6, sm: 3 }
                  }}
                  onClick={() => navigate('/')}>
                  Log in
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Register;
