import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePagination = () => {
  const DEFAULT_PAGE_SIZE = 15;
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const defaultPage = Number(queryParams.get('page')) || 1;
  const [activePage, setActivePage] = useState(+defaultPage);
  const [pageLimit, setPageLimit] = useState(DEFAULT_PAGE_SIZE);

  const handlePageChange = (e, value) => {
    setActivePage(value);

    queryParams.set('page', value);
    navigate({ search: queryParams.toString() });
  };

  const handleSelectPageChange = (event) => {
    setActivePage(1);
    setPageLimit(parseInt(event.target.value));
  };

  return { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange };
};

export default usePagination;
