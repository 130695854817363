import { useState } from 'react';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { Box, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Table, TableBody, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import GroupedStepRow from './components/GroupedStepRow';
import { useDebounce } from 'use-debounce';
import { useActiveProject } from '../../store/projectState';
import { useScandiumQuery } from '../../data-layer/utils';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import { StyledTableCell } from '../projects/ProjectFolders';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';

const GroupedSteps = () => {
  useDocumentTitle('Grouped Steps');
  const activeProject = useActiveProject();

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 600);

  const {
    data: groupedSteps,
    isLoading,
    error: groupedStepsError,
    refetch: refetchGroups
  } = useScandiumQuery(`/groups?project_id=${activeProject?.id}`, {
    enabled: !!activeProject?.id,
    params: {
      search: debouncedQuery || undefined
    },
    select: (data) => data.data
  });

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Search sx={{ width: { xs: 'max-content' } }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </Search>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 1,
          mb: 2
        }}>
        <Table sx={{ width: '100%', minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Num. of Tests</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedSteps?.map((group) => (
              <GroupedStepRow key={group.id} group={group} refetchGroups={refetchGroups} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <PageLoader height={'100px'} />}
      {!isLoading && !!groupedStepsError ? (
        <ErrorState error={groupedStepsError} />
      ) : (
        groupedSteps?.length === 0 && (
          <EmptyState
            title={
              !groupedSteps?.length
                ? "It's lonely here at the moment"
                : 'There are no matching results'
            }
            description={
              !groupedSteps?.length
                ? `Why not create a few grouped steps?`
                : 'Try searching for something else, or create a new test'
            }
          />
        )
      )}
    </Box>
  );
};

export default GroupedSteps;
