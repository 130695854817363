import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import Skeleton from '@mui/material/Skeleton';
import CustomButton from '../../components/customButton';
import ProjectContext from '../../store/projectState';
import { AppRoutes } from '../../routes';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';
import SearchIcon from '@mui/icons-material/Search';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import ProjectCard from './components/ProjectCard';
import { useScandiumQuery } from '../../data-layer/utils';
import ProjectFilter from './components/ProjectFilter';
import Flex from '../../components/base/Flex';
import { useTheme } from '@emotion/react';

function Projects() {
  const theme = useTheme();
  useDocumentTitle('Projects');
  const {
    projects,
    isProjectsLoading,
    fetchProjects,
    fetchProjectsError,
    filterByTags,
    setFilterByTags
  } = useContext(ProjectContext);

  const [searchString, setSearchString] = useState('');

  const filteredProjects = (projects || []).filter(
    (project) =>
      project.name?.toLowerCase().includes(searchString) ||
      project.description?.toLowerCase().includes(searchString) ||
      project.purpose?.toLowerCase().includes(searchString)
  );

  const { data: tags = [] } = useScandiumQuery(`/tags`, {
    select: (data) => data.data
  });

  return (
    <Box>
      <Box
        sx={{
          mt: { xs: '2rem', sm: '0rem' }
        }}>
        <Typography
          as={'h1'}
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: '1.5rem'
          }}>
          Your Projects
        </Typography>
        <Typography sx={{ color: '#6A6A6A' }}>A directory of your projects</Typography>
      </Box>

      <Flex sx={{ mt: 4 }} columnGap={2}>
        <Search sx={{ width: { xs: 'max-content', ml: -1 } }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </Search>

        {!!tags?.length && (
          <ProjectFilter value={filterByTags} setValue={setFilterByTags} tags={tags} />
        )}
      </Flex>

      {isProjectsLoading && (
        <Box sx={{ mt: 4 }}>
          <Skeleton variant="rectangular" width={'100%'} height={50} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mt: { xs: 6, sm: 4 },
          mb: { xs: 3, sm: 3 }
        }}>
        {filteredProjects.map((project) => (
          <ProjectCard key={project.id} project={project} fetchProjects={fetchProjects} />
        ))}

        {!!fetchProjectsError ? (
          <ErrorState error={fetchProjectsError} />
        ) : (
          !filteredProjects.length && (
            <EmptyState
              title={'There are no matching results'}
              description={'Try searching for something else, or create a new project'}
            />
          )
        )}
      </Box>
      <CustomButton
        as={Link}
        to={AppRoutes.projects.children.newProject.path}
        disableRipple={true}
        disableFocusRipple={true}
        fullWidth
        disableElevation={true}
        startIcon={
          <AddCircleOutlineSharpIcon
            color="primary"
            sx={{
              ml: { xs: 1, sm: -2 }
            }}
          />
        }
        label="Create a new project"
        sx={{
          textAlign: 'center',
          textTransform: 'capitalize',
          typography: { xs: 'caption', sm: 'caption' },
          borderRadius: '0.4rem',
          border: `0.1rem solid ${theme.palette.button.border}`,
          bgcolor: 'transparent',
          color: theme.palette.text.black_white,
          width: { xs: '15.6rem', sm: '15.6rem' },
          height: { xs: '3rem', sm: '3.5rem' },
          display: 'flex',
          textDecoration: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: 1,
          '&:hover': {
            boxShadow: 'none',
            color: '#fff'
          }
        }}
      />
    </Box>
  );
}

export default Projects;
