import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ContainedButton from '../../../components/base/ContainedButton';
import { useMenu } from '../../../components/base/useMenu';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TextButton from '../../../components/base/TextButton';
import { useTheme } from '@emotion/react';

const TestCaseReports = ({ onExportTestCase, isExportTestCases, setFormat, format, disabled }) => {
  const { menuProps, triggerProps } = useMenu();
  const theme = useTheme();

  return (
    <Box>
      <Tooltip title={'Export test cases'}>
        <TextButton
          {...triggerProps}
          loadingProps={{ size: 16 }}
          disabled={disabled}
          startIcon={<CloudDownloadIcon sx={{ color: theme.palette.svg.main }} />}
          isLoading={isExportTestCases}>
          Export
        </TextButton>
      </Tooltip>
      <Popover
        {...menuProps}
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Box
          as={'ul'}
          role={'listbox'}
          sx={{
            px: 2,
            py: 3,
            my: 0,
            listStyle: 'none',
            maxWidth: 'max-content'
          }}>
          <Box as={'li'}>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">Format</FormLabel>
              <RadioGroup
                aria-labelledby={'demo-controlled-radio-buttons-group'}
                name={'controlled-radio-buttons-group'}
                row
                size={'small'}
                value={format}
                onChange={(e) => setFormat(e.target.value)}>
                <FormControlLabel
                  value={'excel'}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'excel'}
                />
                <FormControlLabel
                  value={'csv'}
                  control={
                    <Radio
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={'csv'}
                />
              </RadioGroup>
            </FormControl>
            <Divider sx={{ my: 1 }} />
          </Box>

          <Box as={'li'} sx={{ mt: 2 }}>
            <ContainedButton
              fullWidth
              onClick={() => {
                onExportTestCase();
                menuProps.onClose();
              }}>
              Download
            </ContainedButton>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default TestCaseReports;
