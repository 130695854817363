import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import Flex from '../../components/base/Flex';
import CustomButton from '../../components/customButton';
import AllAppsTable from './components/AllAppsTable';
import { useActiveProject } from '../../store/projectState';
import useAwaitModal from '../../hooks/useAwaitModal';
import UploadAppModal from './components/UploadAppModal';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumQuery } from '../../data-layer/utils';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';
import { useDebounce } from 'use-debounce';
import usePagination from '../../hooks/usePagination';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../AppTheme';

export const tabStyle = {
  textTransform: 'none',
  textAlign: 'left',
  py: '2px',
  fontSize: '0.85rem',
  minWidth: 0,
  maxWidth: 'max-content',
  px: 1,
  mr: 6,
  '&: hover': {
    color: '#1958B8'
  }
};

const Mobile = () => {
  const theme = useTheme()
  const { mode } = useContext(ColorContext)
  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();
  const { isFeatureEnabled: isMobileTestingEnabled } = useFeatureEnabled('mobile-app-testing');
  const activeProject = useActiveProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [debouncedQuery] = useDebounce(searchQuery, 800);

  useDocumentTitle('Mobile - All apps');

  const [
    requestUploadAppModal,
    { open: openUploadAppModal, onClose: onCloseUploadAppModal, onComplete: completeUploadAppModal }
  ] = useAwaitModal();

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    const params = new URLSearchParams(searchParams);

    if (!newQuery) {
      params.delete('query');
    } else {
      params.set('query', newQuery);
    }

    setSearchParams(params, { replace: true });
  };

  const {
    data: appResponse,
    error: mobileError,
    isLoading: isLoadingMobileApps,
    refetch: refetchMobileApps
  } = useScandiumQuery(`/projects/${activeProject?.id}/mobile-tests/apps`, {
    enabled: !!activeProject?.id,
    params: {
      page: activePage,
      limit: pageLimit,
      search: debouncedQuery || undefined
    }
  });

  const mobileApps = appResponse?.data?.data;

  // if (isLoadingMobileApps || (!mobileError && !appResponse)) return <PageLoader />;
  // if (mobileError) return <ErrorState error={mobileError} />;

  return (
    <Box sx={{ mt: -2 }}>
      <Flex justifyContent={'space-between'} flexWrap={'wrap'} mb={4}>
        <Typography
          sx={{
            textAlign: 'right',
            fontSize: '1.3rem',
            fontWeight: 500
          }}>
          All apps
        </Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          <CustomButton
            onClick={() => requestUploadAppModal()}
            disableRipple
            disableFocusRipple
            disableElevation
            disabled={!isMobileTestingEnabled}
            fullWidth
            label={'Upload new app'}
            sx={{
              textAlign: 'center',
              textTransform: 'none',
              borderRadius: '0.4rem',
              color: theme.palette.button.textContained,
              border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
              background: mode === 'dark' ? 'none' : 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 2,
              mr: 2,
              width: 'max-content'
            }}
          />

          <Box>
            <Search sx={{ width: { xs: 'max-content' } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={'Search...'}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearchChange}
                value={searchQuery}
              />
            </Search>
          </Box>
        </Box>
      </Flex>

      <AllAppsTable mobileApps={mobileApps} refetchMobileApps={refetchMobileApps} />

      <UploadAppModal
        open={openUploadAppModal}
        onClose={onCloseUploadAppModal}
        onComplete={completeUploadAppModal}
        refetchMobileApps={refetchMobileApps}
      />

      {isLoadingMobileApps && <PageLoader height={'100px'} />}
      {!isLoadingMobileApps && !!mobileError
        ? !mobileApps?.length && <ErrorState error={mobileError} />
        : mobileApps?.length === 0 && (
            <EmptyState
              title={"It's lonely here at the moment"}
              description={'Try searching for something else, or upload a new app'}
            />
          )}

      {appResponse?.data?.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={appResponse?.data?.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Apps per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Apps per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(appResponse?.data.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default Mobile;
