import { Box, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import { useScandiumMutation } from '../../../data-layer/utils';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dropdown from '../../../components/base/Dropdown';
import { useActiveProject } from '../../../store/projectState';
import { useParams } from 'react-router-dom';

function isValidUrl(urlString) {
  try {
    new URL(urlString);
    return true;
  } catch (error) {
    return false;
  }
}

const CreateFailureTag = ({ open, onClose, onComplete, refetchRuns, sessionId, testData }) => {
  const [failure_type, setFailureType] = React.useState('');
  const [failure_link, setFailureLink] = React.useState('');
  const [description, setDescription] = React.useState('');
  const activeProject = useActiveProject();
  const { batchId } = useParams();

  React.useEffect(() => {
    setFailureType(testData?.failure_type || '');
    setFailureLink(testData?.failure_link || '');
    setDescription(testData?.failure_description || '');
  }, [testData]);

  const handleCloseModal = () => {
    onClose();
    setFailureLink('');
    setFailureType('');
    setDescription('');
  };

  const { mutateAsync: createFailureTag, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-runs/${
      testData?.uuid || batchId || sessionId
    }/failure-tag`,
    {
      enabled: !!activeProject?.id,
      onSuccess: (data) => {
        toast.success(data.message);
        onComplete();
        refetchRuns();
        setFailureLink('');
        setFailureType('');
        setDescription('');
      },
      onError: (err) => {
        toast.error(err.message);
      }
    }
  );

  const handleTagCreation = async () => {
    if (!failure_type) {
      toast.error('Please select a failure type from the failure type options');
      return;
    }

    if (failure_link && !isValidUrl(failure_link)) {
      toast.error('Please specify a valid link if you want to specify a link');
      return;
    }

    await createFailureTag({
      failure_link,
      failure_type,
      failure_description: description
    });
  };

  const failureTagOptions = [
    { label: 'Bug in app', value: 'bug in app' },
    { label: 'Environment issue', value: 'environment issue' },
    { label: 'New UI change', value: 'new ui change' },
    { label: 'Improper test design', value: 'improper test design' },
    { label: 'Invalid test data', value: 'invalid test data' },
    { label: 'Other', value: 'other' },
  ];
  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color="primary"
          mb={3}
          sx={{
            fontSize: '1.5rem'
          }}>
          Tag Test Failure
        </Typography>

        <Box mb={2}>
          <Dropdown
            options={failureTagOptions}
            value={failure_type}
            onChange={setFailureType}
            label={'Failure tag'}
            disabled={false}
          />
        </Box>
        <Box mb={2}>
          <TextField
            onChange={(e) => {
              setFailureLink(e.target.value);
            }}
            value={failure_link}
            size={'small'}
            placeholder="https://example.com"
            label={'Failure link (Optional)'}
            fullWidth
          />
        </Box>
        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            multiline
            label={'Description (Optional)'}
            rows={2}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={handleCloseModal}>Cancel</OutlinedButton>
          <ContainedButton
            isLoading={isLoading}
            onClick={handleTagCreation}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateFailureTag;
