import { Box, Grid } from '@mui/material';
import { toHexAlpha } from '../../utils/colors';
import React from 'react';
import SettingsSvg from '../../components/svg/SettingsSvg';
import Typography from '@mui/material/Typography';
import TestRunsSvg from '../../components/svg/TestRunsSvg';
import TestStatusChart from './TestStatusChart';
import Flex from '../../components/base/Flex';
import { Link, useParams } from 'react-router-dom';
import ComingSoonBadge from '../../components/base/ComingSoonBadge';
import { useCookies } from 'react-cookie';

const colorMap = {
  testCases: {
    text: 'primary',
    border: '#FFD600',
    backgroundOpacity: 15,
    icon: '#FFD600'
  },
  localRuns: {
    text: 'secondary',
    border: '#1958B8',
    backgroundOpacity: 6,
    icon: '#24C3D9'
  },
  remoteRuns: {
    text: '#1958B8',
    border: '#24C3D9',
    backgroundOpacity: 15,
    icon: '#1958B8'
  }
};

export const StatusDot = ({ status }) => {
  return (
    <Box
      sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: status === 'success' ? '#00CA08' : '#EF1B1B'
      }}></Box>
  );
};

const DashboardCard = ({ cardType, children, ...props }) => {
  return (
    <Box
      as={Link}
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
        border: `1px solid ${colorMap[cardType].border}`,
        backgroundColor: toHexAlpha(
          colorMap[cardType].border,
          colorMap[cardType].backgroundOpacity
        ),
        color: '#6A6A6A',
        px: 1.5,
        py: 1.5,
        borderRadius: '8px',
        position: 'relative'
      }}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-around'}
      gap={1}
      {...props}>
      {children}
    </Box>
  );
};

const TestCasesCard = ({ project }) => {
  const [cookies] = useCookies(['projectType']);
  const { projectId } = useParams();

  const projectType = cookies?.projectType || 'web';

  return (
    <DashboardCard
      cardType={'testCases'}
      to={
        projectType === 'web'
          ? `/projects/${projectId}/folders`
          : `/projects/${projectId}/mobile-testing`
      }>
      <Box
        borderRadius={'4px'}
        px={1}
        py={1}
        backgroundColor={toHexAlpha(colorMap['testCases'].icon, 21)}>
        <SettingsSvg
          width={32}
          height={32}
          sx={{ display: 'block', color: colorMap['testCases'].icon }}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography
          color={colorMap['testCases'].text}
          fontSize={'3rem'}
          fontWeight={600}
          lineHeight={1}>
          {project.test_cases_count}
        </Typography>
        <Typography>Test cases</Typography>
      </Box>
    </DashboardCard>
  );
};

const ApiTestCasesCard = ({ project }) => {
  const { projectId } = useParams();

  return (
    <DashboardCard cardType={'testCases'} to={`/projects/${projectId}/api-suites/new`}>
      <Box
        borderRadius={'4px'}
        px={1}
        py={1}
        backgroundColor={toHexAlpha(colorMap['testCases'].icon, 21)}>
        <SettingsSvg
          width={32}
          height={32}
          sx={{ display: 'block', color: colorMap['testCases'].icon }}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography
          color={colorMap['testCases'].text}
          fontSize={'3rem'}
          fontWeight={600}
          lineHeight={1}>
          {project.api?.testCounts || 0}
        </Typography>
        <Typography variant="body2">API Test cases</Typography>
      </Box>
    </DashboardCard>
  );
};

const LocalRunsCard = ({ counts }) => {
  const [cookies] = useCookies(['projectType']);
  const { projectId } = useParams();

  const projectType = cookies?.projectType || 'web';

  return (
    <DashboardCard
      cardType={'localRuns'}
      to={
        projectType === 'web'
          ? `/projects/${projectId}/test-results`
          : `/projects/${projectId}/mobile-test-runs`
      }>
      <Box
        borderRadius={'4px'}
        px={1}
        py={1}
        backgroundColor={toHexAlpha(colorMap['localRuns'].icon, 21)}>
        <TestRunsSvg
          width={32}
          height={32}
          sx={{ display: 'block', color: colorMap['localRuns'].icon }}
        />
      </Box>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} flex={1}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            color={colorMap['localRuns'].text}
            fontSize={'3rem'}
            fontWeight={600}
            lineHeight={1}>
            {counts.all || '0'}
          </Typography>
          <Typography>Runs (Local)</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['localRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}>
              {counts.passed || '0'}
            </Typography>
            <StatusDot status={'success'} />
          </Box>
          <Typography fontSize={'0.7rem'}>Passed</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['localRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}>
              {counts.failed || '0'}
            </Typography>
            <StatusDot status={'failed'} />
          </Box>
          <Typography fontSize={'0.7rem'}>Failed</Typography>
        </Box>
      </Box>
    </DashboardCard>
  );
};

const ApiRunsCard = ({ project }) => {
  const { projectId } = useParams();

  return (
    <DashboardCard
      cardType={'localRuns'}
      to={`/projects/${projectId}/api-suites/api_suite_runs?page=1`}>
      <Box
        borderRadius={'4px'}
        px={1}
        py={1}
        backgroundColor={toHexAlpha(colorMap['localRuns'].icon, 21)}>
        <TestRunsSvg
          width={32}
          height={32}
          sx={{ display: 'block', color: colorMap['localRuns'].icon }}
        />
      </Box>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} flex={1}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            color={colorMap['localRuns'].text}
            fontSize={'3rem'}
            fontWeight={600}
            lineHeight={1}>
            {project.api?.testRuns?.total || '0'}
          </Typography>
          <Typography>API Runs</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['localRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}>
              {project.api?.testRuns?.passed || '0'}
            </Typography>
            <StatusDot status={'success'} />
          </Box>
          <Typography fontSize={'0.7rem'}>Passed</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['localRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}>
              {project.api?.testRuns?.failed || '0'}
            </Typography>
            <StatusDot status={'failed'} />
          </Box>
          <Typography fontSize={'0.7rem'}>Failed</Typography>
        </Box>
      </Box>
    </DashboardCard>
  );
};

const RemoteRunsCard = ({ counts }) => {
  return (
    <DashboardCard cardType={'remoteRuns'} to={`#`}>
      <Box
        borderRadius={'4px'}
        px={1}
        py={1}
        backgroundColor={toHexAlpha(colorMap['remoteRuns'].icon, 21)}>
        <TestRunsSvg
          width={32}
          height={32}
          sx={{ display: 'block', color: colorMap['remoteRuns'].icon }}
        />
      </Box>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} flex={1}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            color={colorMap['remoteRuns'].text}
            fontSize={'3rem'}
            fontWeight={600}
            lineHeight={1}>
            {counts.remote?.all || '0'}
          </Typography>
          <Typography>Runs (Remote)</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['remoteRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}>
              {counts.remote?.passed || '0'}
            </Typography>
            <StatusDot status={'success'} />
          </Box>
          <Typography fontSize={'0.7rem'}>Passed</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['remoteRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}>
              {counts.remote?.failed || '0'}
            </Typography>
            <StatusDot status={'failed'} />
          </Box>
          <Typography fontSize={'0.7rem'}>Failed</Typography>
        </Box>
      </Box>
    </DashboardCard>
  );
};

const DashboardCards = ({ project }) => {
  const [cookies] = useCookies(['projectType']);
  const projectType = cookies?.projectType || 'web';

  const { passed = 0, failed = 0, all = 0 } = project.runsCount;
  const { passed: api_passed = 0, failed: api_failed = 0, total = 0 } = project.api?.testRuns || {};

  return (
    <Box>
      <Grid container spacing={2} mt={0} alignItems={'center'} justifyContent={'space-around'}>
        <Grid item xs={12} sm={4} lg={2}>
          <TestCasesCard project={project} />
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <LocalRunsCard counts={project.runsCount} />
        </Grid>
        <Grid item xs={12} sm={8} lg={4}>
          <RemoteRunsCard counts={project.runsCount} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2} justifyContent={{ xs: 'center' }}>
          <Box
            width={'80px'}
            height={'80px'}
            sx={{ position: 'relative' }}
            display={'flex'}
            flexDirection={'column'}
            gap={0.5}
            justifyContent={'center'}
            alignItems={'center'}>
            <Box position={'absolute'} top={0} left={0}>
              <TestStatusChart passing={(passed / (all || 1)) * 100} height={80} />
            </Box>
            <Flex columnGap={0.5}>
              <StatusDot status={'success'} />
              <Typography sx={{ color: '#00CA08' }} fontSize={'0.8rem'}>
                {Math.round((passed / (all || 1)) * 100)}%
              </Typography>
            </Flex>
            <Flex columnGap={0.5}>
              <StatusDot status={'error'} />
              <Typography sx={{ color: '#EF1B1B' }} fontSize={'0.7rem'}>
                {Math.round((failed / (all || 1)) * 100)}%
              </Typography>
            </Flex>
          </Box>
        </Grid>
      </Grid>

      {projectType === 'web' && (
        <Grid container spacing={2} mt={0}>
          <Grid item xs={12} sm={4} lg={2}>
            <ApiTestCasesCard project={project} />
          </Grid>

          <Grid item xs={12} sm={8} lg={4}>
            <ApiRunsCard project={project} />
          </Grid>

          <Grid item xs={12} sm={4} lg={2} justifyContent={{ xs: 'center' }}>
            <Box
              width={'80px'}
              height={'80px'}
              sx={{ position: 'relative' }}
              display={'flex'}
              flexDirection={'column'}
              gap={0.5}
              justifyContent={'center'}
              alignItems={'center'}>
              <Box position={'absolute'} top={0} left={0}>
                <TestStatusChart passing={(api_passed / (total || 1)) * 100} height={80} />
              </Box>
              <Flex columnGap={0.5}>
                <StatusDot status={'success'} />
                <Typography sx={{ color: '#00CA08' }} fontSize={'0.8rem'}>
                  {Math.round((api_passed / (total || 1)) * 100)}%
                </Typography>
              </Flex>
              <Flex columnGap={0.5}>
                <StatusDot status={'error'} />
                <Typography sx={{ color: '#EF1B1B' }} fontSize={'0.7rem'}>
                  {Math.round((api_failed / (total || 1)) * 100)}%
                </Typography>
              </Flex>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DashboardCards;
