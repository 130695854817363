import {
  Badge,
  Box,
  Tab,
  Tabs,
  Typography,
  FormControl,
  Pagination,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import Flex from '../../components/base/Flex';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import ResultsTable from './components/ResultsTable';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { useFetchTest } from '../../data-layer/test-management';
import PageLoader from '../../components/PageLoader';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import { useDebounce } from 'use-debounce';
import ErrorBoundary from '../../components/base/ErrorBoundary';
import { format } from 'date-fns';
import Reports from './components/Reports';
import jsFileDownload from 'js-file-download';
import { getCurrentDate } from '../SuitesRuns/utils';
import ErrorState from '../../components/base/ErrorState';
import { DateTime } from 'luxon';
import usePagination from '../../hooks/usePagination';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';
import { Ranges } from '../Reports/Reports';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';

const TabPanel = ({ children, value, index }) => {
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

export const tabValues = {
  all: { label: 'All', queryParam: undefined },
  success: { label: 'Passed', queryParam: 'success' },
  error: { label: 'Failed', queryParam: 'error' }
};

const LatestRuns = () => {
  useDocumentTitle('Test Runs');
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [activeTab, setActiveTab] = useState('all');
  const activeProject = useActiveProject();
  const { testId } = useParams();
  const [report_format, setReportFormat] = useState('excel');
  const [mode, setMode] = useState('simple');
  const [dateRange, setDateRange] = useState([]);
  const theme = useTheme();

  const { afterToday } = DateRangePicker;

  const readyOnly = activeProject?.read_only;

  useEffect(() => {
    const dateFrom = searchParams.get('date_from');
    const dateTo = searchParams.get('date_to');

    if (dateFrom && dateTo) {
      setDateRange([new Date(dateFrom), new Date(dateTo)]);
    }
  }, []);

  useEffect(() => {
    const currentTab = searchParams.get('status');
    if (tabValues[currentTab]) {
      setActiveTab(currentTab);
    } else {
      setActiveTab('all');
    }
  }, [searchParams]);

  const handleChangeDatePicker = (newValue) => {
    setDateRange(newValue);

    if (newValue?.length === 2) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        date_from: newValue[0].toISOString().split('T')[0],
        date_to: newValue[1].toISOString().split('T')[0]
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('date_from');
      newParams.delete('date_to');
      setSearchParams(newParams);
      setDateRange(null);
    }
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    const newParams = new URLSearchParams(searchParams);
    if (tabValues[newTab].queryParam) {
      newParams.set('status', tabValues[newTab].queryParam);
    } else {
      newParams.delete('status');
    }
    setSearchParams(newParams);
  };

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    const params = new URLSearchParams(searchParams);

    if (!newQuery) {
      params.delete('query');
    } else {
      params.set('query', newQuery);
    }

    setSearchParams(params, { replace: true });
  };

  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();

  const fileExtensionMap = {
    excel: 'xlsx',
    csv: 'csv',
    pdf: 'pdf'
  };

  const {
    data,
    error,
    isLoading,
    refetch: refetchRuns
  } = useScandiumQuery(
    `/projects/${activeProject?.id}/${testId ? `test-cases/${testId}/runs` : `test-runs`}`,
    {
      enabled: !!activeProject?.id,
      refetchInterval: 10000,
      params: {
        status: tabValues[activeTab].queryParam,
        search: debouncedQuery || undefined,
        date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
        date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined,
        page: activePage,
        limit: pageLimit
      }
    }
  );

  const { data: testData } = useFetchTest({
    projectId: activeProject?.id,
    testId,
    select: (data) => data.data
  });

  const testRuns = data?.runs || data?.results;

  const userTimezone = DateTime.local().zoneName;

  const { isLoading: isFetchingReport, refetch: downloadReport } = useScandiumQuery(
    `/projects/${activeProject?.id}/reports/test-runs`,
    {
      json: false,
      enabled: false,
      headers: {
        'SCANDIUM-USER-TIMEZONE': userTimezone
      },
      params: {
        mode: mode,
        format: report_format,
        search: debouncedQuery || undefined,
        date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
        date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
      },
      onSuccess: (data) => {
        jsFileDownload(
          data,
          `Scandium test runs report ${getCurrentDate()}.${fileExtensionMap[report_format]}`
        );
        toast.success('Your report is being downloaded');

        trackMixPanel(TRACKING_IDS.DATA_EXPORTED, {
          type: 'results',
        });
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  return (
    <Box sx={{ mt: -2 }}>
      <Box>
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ mb: 2 }}
          flexWrap={'wrap'}>
          <Typography fontWeight={'600'} as={'h2'} fontSize={'1.2rem'} color={'primary'}>
            {testData?.name || 'Test Runs'}
          </Typography>

          <Flex ml={'auto'}>
            <DateRangePicker
              shouldDisableDate={afterToday()}
              appearance={'default'}
              style={{ width: 220 }}
              value={dateRange}
              placement={'bottom'}
              ranges={Ranges}
              onChange={handleChangeDatePicker}
              size={'sm'}
              placeholder={'Filter by Date'}
            />
            {!testId && (
              <Search sx={{ width: { xs: 'max-content', ml: -1 } }}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Search>
            )}
          </Flex>
        </Flex>
      </Box>

      <Box>
        <Flex
          sx={{ borderBottom: `3px solid ${theme.palette.table.outline}` }}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label={'Test cases tabs'}
            indicatorColor={'primary'}
            TabIndicatorProps={{
              sx: { height: 3 }
            }}>
            <Tab
              label={
                <Flex columnGap={2}>
                  All
                  {!!data?.count && (
                    <Badge color={'primary'} badgeContent={data.count.all} max={999}></Badge>
                  )}
                </Flex>
              }
              value={'all'}
              sx={{ textTransform: 'none', textAlign: 'left' }}
            />
            <Tab
              label={
                <Flex columnGap={2}>
                  Passed
                  {!!data?.count && (
                    <Badge color={'primary'} badgeContent={data.count.passed} max={999}></Badge>
                  )}
                </Flex>
              }
              value={'success'}
              sx={{ textTransform: 'none', textAlign: 'left' }}
            />
            <Tab
              label={
                <Flex columnGap={2}>
                  Failed
                  {!!data?.count && (
                    <Badge color={'primary'} badgeContent={data.count.failed} max={999}></Badge>
                  )}
                </Flex>
              }
              value={'error'}
              sx={{ textTransform: 'none', textAlign: 'right' }}
            />
          </Tabs>
          {!testId && (
            <Reports
              handleDownloadReport={() => downloadReport()}
              isLoading={isFetchingReport}
              format={report_format}
              setReportFormat={setReportFormat}
              mode={mode}
              setMode={setMode}
              runs={testRuns?.data}
            />
          )}
        </Flex>
        <ErrorBoundary key={tabValues[activeTab].queryParam}>
          <ResultsTable testData={testData} testRuns={testRuns?.data} refetchRuns={refetchRuns} />
        </ErrorBoundary>

        {isLoading && <PageLoader height={'100px'} />}
        {!isLoading &&
          (error
            ? !testRuns?.data?.length && <ErrorState error={error} />
            : !testRuns?.data?.length && (
                <EmptyState
                  description={`Why not run ${testId ? 'the test' : 'some tests'} a few times?`}
                  cta={
                    <OutlinedButton
                      components={NavLink}
                      disabled={!!readyOnly}
                      to={
                        testId
                          ? `/projects/${activeProject?.id}/folders/tests/${testId}/edit`
                          : '/folders'
                      }>
                      Run tests
                    </OutlinedButton>
                  }></EmptyState>
              ))}
      </Box>

      {testRuns?.meta.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={testRuns?.meta.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Runs per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Runs per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(testRuns?.meta.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default LatestRuns;
