import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import Modal from '@mui/material/Modal';
import CustomButton from './customButton';
import CustomInput from './customInput';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../data-layer/utils';
import ContainedButton from './base/ContainedButton';
import { useActiveProject } from '../store/projectState';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../mixpanel.constants';

const InviteMembersModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [copyLink, setCopyLink] = useState(false);
  const activeProject = useActiveProject();
  const theme = useTheme();

  const { mutateAsync: postSendInvite, isLoading: showProgress } = useScandiumMutation(
    `/projects/${activeProject?.id}/collaborators`,
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success('Invite link successfully sent to the email provided');
        setSuccess(true);
        setInviteLink(data.invite_url);
        setEmail('');

        trackMixPanel(TRACKING_IDS.MEMBER_INVITED, {
          type: 'project'
        });
      }
    }
  );

  const handleSendInvite = async (e) => {
    e.preventDefault();

    await postSendInvite({ email });
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopyLink(true);

    setTimeout(() => {
      setCopyLink(false);
    }, 3000);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        if (success) setSuccess(false);
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.4)'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: success ? '400' : '464',
          // width: { success ? '400': '464' },
          bgcolor: 'background.paper',
          // bgcolor: 'rgba(0, 0, 0, 0.6)',
          border: `1px solid ${theme.palette.table.outline}`,
          borderRadius: '0.6rem',
          boxShadow: 24,
          px: 0.3,
          pb: 4
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: { xs: 0.4, sm: 0.4 }
          }}>
          <IconButton
            aria-label="cancel"
            sx={{
              float: 'right',
              mr: { xs: 0.4, sm: 0.4 }
            }}
            onClick={() => {
              if (success) setSuccess(false);
              handleClose();
            }}>
            <DisabledByDefaultRoundedIcon
              color="disabled"
              sx={{
                fontSize: 30
              }}
            />
          </IconButton>
        </Box>
        {!success && (
          <Box
            component={'form'}
            onSubmit={handleSendInvite}
            sx={{
              mx: { xs: 5, sm: 5 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <Typography
              id="modal-modal-title"
              color="primary"
              sx={{
                textAlign: 'left',
                fontSize: { xs: '0.8rem', sm: '1.0rem' },
                mt: { xs: 2, sm: 4 }
              }}>
              Enter email address of the member
            </Typography>
            <CustomInput
              type="email"
              required
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
              error={false}
              helperText=" "
              formControlStyle={{
                mt: { xs: 2, sm: 3 },
                width: '100%'
              }}
              sx={{
                borderRadius: '9.4rem',
                height: { xs: '2.4rem', sm: '2.7rem' }
              }}
            />
            <ContainedButton
              disableRipple={true}
              isLoading={showProgress}
              sx={{
                textAlign: 'center',
                textTransform: 'capitalize',
                px: 7,
                fontSize: { xs: '0.8rem', sm: '1.0rem' },
                mt: { xs: 2.5, sm: 3.5 },
                '&:hover': {
                  backgroundColor: '#1958B8'
                }
              }}
              type={'submit'}>
              Send invite
            </ContainedButton>
            <Typography
              id="modal-modal-description"
              // variant="body2"
              sx={{
                mt: 3,
                opacity: 0.5,
                fontSize: { xs: '0.8rem', sm: '1.0rem' },
                textAlign: 'left'
              }}>
              An invite link will be sent to the provided email box
            </Typography>
          </Box>
        )}
        {success && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '80%'
                // backgroundColor: 'moccasin'
              }}>
              <IconButton
                aria-label="cancel"
                sx={
                  {
                    // clear: 'right'
                  }
                }>
                <CheckCircleIcon
                  sx={{
                    color: '#4CB752',
                    fontSize: { xs: 40, sm: 50 }
                  }}
                />
              </IconButton>
              <Typography
                id="modal-modal-title"
                // variant="h4"
                // component="h2"
                color="primary"
                sx={{
                  fontSize: { xs: '1.25rem', sm: '2.125rem' }
                }}>
                Success
              </Typography>
              <Typography
                id="modal-modal-description"
                // variant="body2"
                sx={{
                  mt: 0.8,
                  px: 4,
                  textAlign: 'center',
                  fontSize: { xs: '0.8rem', sm: '1.0rem' }
                }}>
                You can also copy the invite link below and share directly to them
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  // backgroundColor: 'pink',
                  width: '100%',
                  mt: { xs: 2, sm: 2 }
                }}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    textTransform: 'none',
                    typography: { xs: 'caption', sm: 'caption' },
                    borderRadius: '0.4rem',
                    bgcolor: '#EDEDED',
                    color: '#1958B8',
                    pointer: 'none',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    py: { xs: 0.97, sm: 0.97 },
                    pl: 1,
                    width: { xs: '11.6rem', sm: '10.6rem' },
                    height: { xs: '2.3rem', sm: '2.3rem' },
                    '&:hover': {
                      backgroundColor: '#EDEDED'
                    }
                  }}>
                  {inviteLink}
                </Typography>
                <CustomButton
                  onClick={copyToClipBoard}
                  disableRipple={true}
                  endIcon={copyLink && <CheckCircleIcon sx={{ color: 'primary' }} />}
                  disableFocusRipple={true}
                  fullWidth
                  disableElevation={true}
                  label={copyLink ? 'Copied' : 'Copy link'}
                  sx={{
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    typography: { xs: 'caption', sm: 'caption' },
                    borderRadius: '0.4rem',
                    bgcolor: copyLink ? '#4CB752' : 'primary',
                    py: { xs: 1.2, sm: 1.2 },
                    ml: { xs: '1.0rem', sm: '0rem' },
                    width: { xs: '7.6rem', sm: '10.6rem' },
                    height: { xs: '2.3rem', sm: '2.3rem' },
                    '&:hover': {
                      backgroundColor: copyLink ? '#4CB752' : '#1958B8'
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default InviteMembersModal;
