import React, { useContext, useState } from 'react';

export const TestCaseContext = React.createContext({
  isDraggingId: '',
  setIsDraggingId: () => {},
  clickedExample: '',
  setClickedExample: () => {},
  clickedTest: '',
  setClickedTest: () => {},
  clickedFolder: '',
  setClickedFolder: () => {},
  focusedTestId: '',
  setFocusedTestId: () => {},
  showDescriptionModal: false,
  foldersState: {
    entityType: '',
    entityToMove: {},
    foldersList: [],
    destinationFolder: {}
  },
  showDestinationFolder: false,
  setShowDestinationFolder: () => {},
  setFoldersState: () => {},
  setShowDescriptionModal: () => {},
  localMode: false,
  setLocalMode: () => {},
  localRun: false,
  setLocalRun: () => {},
  showLocalModeNotice: 0,
  setShowLocalModeNotice: () => {},
  runAgent: '',
  setRunAgent: () => {},
  agentPort: '',
  setAgentPort: () => {},
  checkIfAgentIsOn: async () => {},
  initialTestProps: '',
  resetInitialTestProps: () => {},
  activeTool: '',
  setActiveTool: () => {},
  testDetails: {},
  setTestDetails: () => {},
  testDocumentData: {
    url: '',
    method: '',
    reqParams: [],
    reqHeaders: [],
    reqBody: '',
    reqBodyType: '',
    authType: ''
  },
  setTestDocumentData: () => {}
});
