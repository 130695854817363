import Flex from '../../../components/base/Flex';
import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import CustomButton from '../../../components/customButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useMenu } from '../../../components/base/useMenu';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { useParams } from 'react-router-dom';
import { FieldTitle } from '../../TestPage/components/TestProperties';
import TextButton from '../../../components/base/TextButton';
import TestDetails from './TestDetails';
import EmailMenu from '../../TestPage/components/EmailMenu';
import { useTheme } from '@emotion/react';

const DeviceConfig = ({
  platformDropdownOptions,
  selectedDevice,
  setSelectedDevice,
  osVersion,
  setOsVersion,
  orientation,
  setOrientation,
  updateConfig,
  sessionActive,
  session,
  activeFolder,
  name,
  client,
  testResponse
}) => {
  const { triggerProps: deviceTriggerProps, menuProps: deviceMenuProps } = useMenu();
  const { triggerProps: orientationTriggerProps, menuProps: orientationMenuProps } = useMenu();
  const { triggerProps: osTriggerProps, menuProps: osMenuProps } = useMenu();
  const { batchId } = useParams();
  const theme = useTheme();

  const handleDeviceChange = async (device) => {
    setSelectedDevice(device);

    const activeOs = device.os.find((os) => os === osVersion);
    setOsVersion(activeOs ? activeOs : device.os[device.os.length - 1]);

    const makeDeviceSelection = async () => {
      await updateConfig(
        {},
        {
          device: device.device_identifier,
          osVersion: activeOs ? activeOs : device.os[device.os.length - 1],
          centered: 'both',
          scale: 'auto'
        }
      );
    };

    if (sessionActive) {
      const confirmChange = window.confirm('This will end your current session. Are you sure?');

      if (confirmChange) {
        makeDeviceSelection();
      }
    } else {
      makeDeviceSelection();
    }

    deviceMenuProps.onClose();
  };

  const handleOsChange = async (os) => {
    setOsVersion(os);

    const makeOsChange = async () => {
      await updateConfig({}, { osVersion: os });
    };

    if (sessionActive) {
      const confirmChange = window.confirm('This will end your current session. Are you sure?');

      if (confirmChange) {
        makeOsChange();
      }
    } else {
      makeOsChange();
    }

    osMenuProps.onClose();
  };

  const handleOrientationChange = async (_orientation) => {
    setOrientation(_orientation);

    await updateConfig({}, { orientation: _orientation });

    orientationMenuProps.onClose();
  };

  return (
    <Flex sx={{ width: 'max-content' }}>
      <Flex
        columnGap={3}
        sx={{
          bgcolor: 'rgba(36, 195, 217, 0.16)',
          borderRadius: '10px',
          py: 0.5,
          px: 1.5,
          width: '100%',
          alignItems: 'center'
        }}>
        <Box>
          <TextButton
            disableElevation
            disabled={!!batchId}
            endIcon={deviceMenuProps.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            {...deviceTriggerProps}
            fullWidth
            label={selectedDevice.device}
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              color: theme.palette.text.black_white,
              fontSize: '0.9rem',
              fontWeight: 'bold',
              justifyContent: 'space-between',
              borderRadius: '0.4rem',
              py: '2px',
              px: 0
            }}>
            {selectedDevice.device}
          </TextButton>
          <Menu
            id="action-menu"
            {...deviceMenuProps}
            elevation={1}
            PaperProps={{
              style: {
                width: 'max-content',
                paddingLeft: 2,
                paddingBottom: 5
              }
            }}>
            <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '300px' }}>
              {platformDropdownOptions.map((device, index) => (
                <MenuItem key={index} onClick={() => handleDeviceChange(device)}>
                  {device.device}
                </MenuItem>
              ))}
            </CustomScrollbar>
          </Menu>
        </Box>

        <Box>
          <TextButton
            disableElevation
            endIcon={osMenuProps.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            {...osTriggerProps}
            fullWidth
            disabled={!!batchId}
            label={`${selectedDevice.os_type} ${osVersion}`}
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              color: theme.palette.text.black_white,
              fontSize: '0.9rem',
              fontWeight: 'bold',
              justifyContent: 'space-between',
              borderRadius: '0.4rem',
              py: '2px',
              px: 0
            }}>{`${selectedDevice.os_type} ${osVersion}`}</TextButton>
          <Menu
            id="action-menu"
            {...osMenuProps}
            elevation={1}
            PaperProps={{
              style: {
                width: 'max-content',
                paddingLeft: 2
              }
            }}>
            {selectedDevice.os.map((os, index) => (
              <MenuItem key={index} onClick={() => handleOsChange(os)}>
                {selectedDevice.os_type} {os.toString()}
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box>
          <TextButton
            disableElevation
            endIcon={orientationMenuProps.open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            {...orientationTriggerProps}
            fullWidth
            disabled={!!batchId}
            label={orientation}
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              color: theme.palette.text.black_white,
              fontSize: '0.9rem',
              fontWeight: 'bold',
              justifyContent: 'space-between',
              borderRadius: '0.4rem',
              py: '2px',
              px: 0
            }}>
            {orientation}
          </TextButton>
          <Menu
            id="action-menu"
            {...orientationMenuProps}
            elevation={1}
            PaperProps={{
              style: {
                width: 'max-content',
                paddingLeft: 2,
                paddingBottom: 5
              }
            }}>
            <MenuItem onClick={() => handleOrientationChange('portrait')}>Portrait</MenuItem>
            <MenuItem onClick={() => handleOrientationChange('landscape')}>Landscape</MenuItem>
          </Menu>
        </Box>

        <Flex>
          <EmailMenu theme={theme} />

          <TestDetails
            activeFolder={activeFolder}
            name={name}
            appetizeClient={client}
            testResponse={testResponse}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DeviceConfig;
