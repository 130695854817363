import React, { useContext } from 'react';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { formatMailDateTime } from '../../../utils/time';
import { ColorContext } from '../../../AppTheme';

const EmailItem = ({ mail, selectedEmail, setSelectedEmail, index, handleFocus }) => {
  const namePattern = /From: (.+?) </;
  const emailSender = mail.meta.email.match(namePattern);
  const { mode } = useContext(ColorContext);

  return (
    <ListItemButton
      id={index === 0 ? 'scandium--latest-mail' : mail.uuid || index}
      selected={selectedEmail === mail.uuid}
      sx={{ py: '2px' }}
      role={'button'}
      aria-pressed={selectedEmail === mail.uuid}
      onClick={() => {
        setSelectedEmail(mail.uuid);
        handleFocus();
      }}>
      <ListItemText
        primary={
          <Typography sx={{ color: mode === 'light' && '#1C1C1C', fontWeight: 500 }}>
            {emailSender?.[1] || ''}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              variant="body2"
              sx={{
                width: 'inherit',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}>
              {mail.meta.subject}
            </Typography>
            <Typography
              sx={{ color: mode === 'light' && '#1C1C1C', fontWeight: 500, fontSize: '0.85rem' }}>
              {formatMailDateTime(mail.created_at)}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItemButton>
  );
};

const EmailList = ({ selectedEmail, setSelectedEmail, mails, handleFocus }) => {
  return (
    <List role="list">
      {mails?.map((mail, index) => (
        <Box key={mail.id}>
          <EmailItem
            mail={mail}
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
            index={index}
            handleFocus={handleFocus}
          />
          {index + 1 < mails?.length && <Divider />}
        </Box>
      ))}
    </List>
  );
};

export default EmailList;
