import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/base/BackButton';
import HelpIcon from '@mui/icons-material/Help';
import ContainedButton from '../../components/base/ContainedButton';
import Flex from '../../components/base/Flex';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useActiveProject } from '../../store/projectState';
import { getBaseUrl } from '../TestPage';
import ProtocolMenu, { getProtocolFromString } from '../TestPage/components/ProtocolMenu';
import AddTestCases from './components/AddTestCases';
import ExecutionEnvirons from './components/ExecutionEvirons';
import ExecutionType from './components/ExecutionType';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

const NewTestSuite = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [starting_url, setStartingUrl] = useState('');
  const [execution_type, setExecutionType] = useState('sequential');
  const [environment_Ids, setEnvironmentIds] = useState([]);
  const [test_Ids, setTestIds] = useState([]);
  const [onError, setOnError] = useState('continue');
  const [test_delay, setTestDelay] = useState(0);
  const [test_timeout, setTestTimeout] = useState(0);
  const [test_retries, setTestRetries] = useState(0);
  const [protocol, setProtocol] = useState('https://');
  const [env_Ids, setEnvIds] = useState([]);
  const [testIds, setTest_Ids] = useState([]);
  const theme = useTheme();

  const { suiteId } = useParams();

  const navigate = useNavigate();
  useDocumentTitle(suiteId ? `Edit Suite${!!name ? `: ${name}` : ''}` : 'New Test Suite');
  const activeProject = useActiveProject();

  // Get Ids
  const _test_Ids = test_Ids?.map((test) => test.id);
  const _environment_Ids = environment_Ids?.map((environment) => environment.id);

  const {
    isLoading: isFetchingSuite,
    isSuccess,
    data: suiteData
  } = useScandiumQuery(`/projects/${activeProject?.id}/suites/${suiteId}`, {
    enabled: !!activeProject?.id && !!suiteId,
    onSuccess: (data) => {
      setName(data.data.name);
      setTestDelay(data.data.test_delay);
      setOnError(data.data.on_error);
      setDescription(data.data.description ? data.data.description : '');
      setStartingUrl(data.data.starting_url ? data.data.starting_url : '');
      setExecutionType(data.data.execution_type);
      setEnvIds(data.data.environment_ids);
      setTest_Ids(data.data.test_ids);
    }
  });

  const { mutateAsync: postNewSuite, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/${suiteId ? `suites/${suiteId}` : 'suites'}`,
    {
      method: suiteId ? 'PUT' : 'POST',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success(data.message);
        navigate(`/projects/${activeProject?.id}/test-suites`);
      }
    }
  );

  const handlePostNewSuite = async (e) => {
    e.preventDefault();

    if (!test_Ids.length) {
      toast.error('Please select at least one test case');
      return;
    }

    if (!_environment_Ids.length) {
      toast.error(
        'Please select at least one execution environment before saving, alternatively you can create a new execution environment if you have none'
      );
      return;
    }

    const baseUrl = getBaseUrl(starting_url);

    await postNewSuite({
      name,
      description,
      starting_url: starting_url ? `${protocol}${baseUrl}` : '',
      execution_type,
      environment_ids: _environment_Ids,
      test_ids: _test_Ids,
      test_delay,
      test_timeout,
      test_retries,
      ...(execution_type === 'sequential' && { on_error: onError })
    });
  };

  const readyOnly = activeProject?.read_only;

  return (
    <Box sx={{ mt: -2, mb: '24rem' }}>
      <Box sx={{ mt: 1, mb: 3 }}>
        <BackButton label={'Back'} onClick={() => navigate('..')} />
        <Typography
          color="primary"
          sx={{
            textAlign: 'left',
            fontSize: '1.1rem'
          }}>
          {suiteId ? 'Edit Test Suite' : 'New Test Suite'}
        </Typography>
      </Box>

      <Box as={'form'} onSubmit={handlePostNewSuite} sx={{ width: '80%' }}>
        <Flex
          columnGap={2}
          sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start', rowGap: 2 }}>
          <Flex sx={{ flex: 1, flexDirection: 'column', rowGap: 2, width: '100%' }}>
            <TextField
              size={'small'}
              value={name}
              label={'Name'}
              required
              fullWidth
              sx={{
                height: { xs: 1, sm: 1 }
              }}
              onChange={(e) => setName(e.target.value)}
              InputProps={{
                endAdornment: !isFetchingSuite ? undefined : (
                  <InputAdornment position="end">
                    <CircularProgress size={12} color={'secondary'} />
                  </InputAdornment>
                )
              }}
            />

            <TextField
              size={'small'}
              value={description}
              label={'Description'}
              fullWidth
              sx={{
                height: { xs: 1, sm: 1 }
              }}
              onChange={(e) => setDescription(e.target.value)}
              InputProps={{
                endAdornment: !isFetchingSuite ? undefined : (
                  <InputAdornment position="end">
                    <CircularProgress size={12} color={'secondary'} />
                  </InputAdornment>
                )
              }}
            />

            <ExecutionEnvirons
              environment_Ids={environment_Ids}
              setEnvironmentIds={setEnvironmentIds}
              env_Ids={env_Ids}
              suiteId={suiteId}
              isFetchingSuite={isFetchingSuite}
              isSuccess={isSuccess}
              exeEnvIds={suiteData?.data?.environment_ids || []}
            />

            <ContainedButton
              disableRipple={true}
              type={'submit'}
              fullWidth
              loadingProps={{ size: 16 }}
              isLoading={isLoading}
              disabled={!!readyOnly}
              sx={{
                textAlign: 'center',
                textTransform: 'capitalize',
                py: 1.5,
                mt: 4,
                display: { xs: 'none', sm: 'inline-block' }
              }}>
              {suiteId ? 'Update Test Suite' : 'Create Test Suite'}
            </ContainedButton>
          </Flex>
          <Flex
            sx={{
              flex: 1,
              flexDirection: 'column ',
              alignItems: 'flex-start',
              rowGap: 2,
              width: '100%'
            }}>
            <ExecutionType execution_type={execution_type} setExecutionType={setExecutionType} />

            {execution_type === 'sequential' && (
              <>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Failure Mode (When a test case fails)
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    value={onError}
                    onChange={(e) => setOnError(e.target.value)}
                    name="row-radio-buttons-group">
                    <FormControlLabel
                      value="continue"
                      control={
                        <Radio
                          sx={{
                            py: 0.5,
                            '&.Mui-checked': {
                              color: theme.palette.radio.main
                            }
                          }}
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          fontSize={13}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            color: theme.palette.text.black_grey
                          }}>
                          Fail and continue
                          <Tooltip title="When a test case fails during the suite execution, the test is marked as failed, but subsequent test cases are executed.">
                            <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                          </Tooltip>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="fail"
                      control={
                        <Radio
                          sx={{
                            py: 0.5,
                            '&.Mui-checked': {
                              color: theme.palette.radio.main
                            }
                          }}
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          fontSize={13}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            color: theme.palette.text.black_grey
                          }}>
                          Fail suite immediately
                          <Tooltip title="When a test case fails within the suite, the test is marked as failed, and subsequent test cases are not executed">
                            <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                          </Tooltip>
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="ignore"
                      control={
                        <Radio
                          sx={{
                            py: 0.5,
                            '&.Mui-checked': {
                              color: theme.palette.radio.main
                            }
                          }}
                          size="small"
                        />
                      }
                      label={
                        <Typography
                          fontSize={13}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            color: theme.palette.text.black_grey
                          }}>
                          Ignore failure
                          <Tooltip title="The 'Ignore failure' mode will execute all test cases even when any of the test cases within the suite failed">
                            <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                          </Tooltip>
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <TextField
                  size={'small'}
                  value={test_delay}
                  label={'Test delay (ms)'}
                  type={'number'}
                  fullWidth
                  sx={{
                    height: { xs: 1, sm: 1 }
                  }}
                  onChange={(e) => setTestDelay(e.target.value)}
                  InputProps={{
                    endAdornment: !isFetchingSuite ? (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            'The delay field specifies the amount of time, in milliseconds, to wait before executing the next test case. This can be used to control the timing of test execution'
                          }>
                          <HelpIcon />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <CircularProgress size={12} color={'secondary'} />
                      </InputAdornment>
                    )
                  }}
                />
              </>
            )}

            <Flex columnGap={0} alignItems={'stretch'} width={'100%'}>
              <ProtocolMenu
                protocol={protocol}
                setProtocol={(protocol) => {
                  if (starting_url) {
                    const _protocol = getProtocolFromString(starting_url);
                    const baseUrl = getBaseUrl(starting_url);
                    setStartingUrl(`${_protocol ? protocol : ''}${baseUrl}`);
                  }
                  setProtocol(protocol);
                }}
              />
              <TextField
                fullWidth
                value={starting_url}
                onChange={(event) => {
                  const _protocol = getProtocolFromString(event.target.value);
                  if (_protocol) setProtocol(_protocol);
                  setStartingUrl(event.target.value);
                }}
                size={'small'}
                label={'Starting url (optional)'}
                InputProps={{
                  sx: {
                    paddingLeft: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                  },
                  endAdornment: !isFetchingSuite ? (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          'Starting URL (optional). Use this field to set a common starting point for all test cases in this suite. Leave blank to use the individual starting URLs defined in each test case.'
                        }>
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <CircularProgress size={12} color={'secondary'} />
                    </InputAdornment>
                  )
                }}
              />
            </Flex>

            <AddTestCases
              test_Ids={test_Ids}
              setTestIds={setTestIds}
              testIds={testIds}
              suiteId={suiteId}
              isFetchingSuite={isFetchingSuite}
              isSuccess={isSuccess}
            />
          </Flex>
        </Flex>
        <Flex>
          <ContainedButton
            disableRipple={true}
            type={'submit'}
            fullWidth
            loadingProps={{ size: 16 }}
            isLoading={isLoading}
            disabled={!!readyOnly}
            sx={{
              textAlign: 'center',
              textTransform: 'capitalize',
              py: 1.5,
              mt: 4,
              flex: 1,
              display: { xs: 'inline-block', sm: 'none' }
            }}>
            {suiteId ? 'Update Test Suite' : 'Create Test Suite'}
          </ContainedButton>
        </Flex>
      </Box>
    </Box>
  );
};

export default NewTestSuite;
