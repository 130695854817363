import React from 'react';
import { useDebounce } from 'use-debounce';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomInput from '../../../components/customInput';
import CustomModal from '../../../components/base/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { MenuItem } from '@mui/material';
import CustomScrollbar from './CustomScrollbar';
import Flex from '../../../components/base/Flex';
import { useTheme } from '@emotion/react';

const InsertVariable = ({
  open,
  onClose,
  handleUpdate,
  onCompleteCreation,
  event,
  stepVariables,
  cursorIndex,
  inputValue,
  testCaseVariables,
  dataSource,
  property,
  globalVariables
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const theme = useTheme();

  const assignVariable = (name, context) => {
    handleUpdate({
      uses_variables: Array.isArray(event.uses_variables)
        ? event.uses_variables.push({ name, context })
        : [{ name, context }]
    });
  };

  // These function will update the variables based on object properties
  const updater = (_variable) => {
    if (cursorIndex !== null) {
      const newInputValue =
        inputValue?.slice(0, cursorIndex) + _variable + inputValue?.slice(cursorIndex);
      handleUpdate({
        [property]:
          property === 'element' ? { ...event.element, value: newInputValue } : newInputValue
      });
      setSearchQuery('');
    } else {
      const newInputValue = inputValue + _variable;
      handleUpdate({
        [property]:
          property === 'element' ? { ...event.element, value: newInputValue } : newInputValue
      });
      setSearchQuery('');
    }
  };

  const handleInsertVariable = (variable) => {
    const _variable = `{{step.${variable.name}}}`;

    assignVariable(variable.name, variable.context);

    updater(_variable);

    onCompleteCreation();
  };

  const handleInsertTestCaseVariable = (variable) => {
    const _variable = `{{test.${variable.name}}}`;

    assignVariable(variable.name, 'test');

    updater(_variable);

    onCompleteCreation();
  };

  const handleInsertExternalFileVariable = (variable) => {
    const _variable = `{{file.${variable}}}`;

    assignVariable(variable, 'file');

    updater(_variable);

    onCompleteCreation();
  };

  const handleInsertGlobalVariable = (variable) => {
    const _variable = `{{global.${variable.name}}}`;

    assignVariable(variable.name, 'global');

    updater(_variable);

    onCompleteCreation();
  };

  const filteredStepVariables = stepVariables.filter((variable) =>
    variable.name.toLowerCase().includes(debouncedQuery)
  );

  const filteredTestCaseVariables = testCaseVariables?.filter((variable) =>
    variable.name.toLowerCase().includes(debouncedQuery)
  );

  const filteredGlobalVariables = globalVariables?.filter((variable) =>
    variable.name.toLowerCase().includes(debouncedQuery)
  );

  const filteredExternalFileVariablesData =
    !!dataSource?.length &&
    Object.keys(dataSource[0])?.filter((key) => key.toLowerCase().includes(debouncedQuery));

  return (
    <CustomModal open={open}>
      <Box
        py={2}
        px={2}
        width={'80vw'}
        minHeight={'250px'}
        maxWidth={'500px'}
        position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Insert Variable
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Input a variable name and select one at a time
        </Typography>

        <Box mb={3}>
          <CustomInput
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            value={searchQuery}
            size={'small'}
            label={'Search'}
            fullWidth
          />
          <CustomScrollbar
            sx={{ overflowY: 'auto', maxHeight: '250px', border: '1px solid #e5e5e5' }}>
            {filteredStepVariables.map((variable, index) => (
              <MenuItem
                sx={{ px: 0 }}
                key={index}
                divider={true}
                onClick={(e) => handleInsertVariable(variable)}>
                <Flex>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      bgcolor: theme.palette.background.lightGrey_dark,
                      py: '5px',
                      px: '12px',
                      borderRadius: '8px',
                      flex: '40%',
                      fontSize: '12px'
                    }}>
                    Test step
                  </Typography>
                  <Typography fontSize={'12px'} sx={{ flex: '60%' }} variant="subtitle1">
                    {variable.name}
                  </Typography>
                </Flex>
              </MenuItem>
            ))}
            {filteredTestCaseVariables?.map((variable) => (
              <MenuItem
                key={variable.name}
                divider={true}
                sx={{ px: 0 }}
                onClick={(e) => handleInsertTestCaseVariable(variable)}>
                <Flex>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      bgcolor: theme.palette.background.lightGrey_dark,
                      py: '5px',
                      px: '12px',
                      borderRadius: '8px',
                      flex: '40%',
                      fontSize: '12px'
                    }}>
                    Test case
                  </Typography>
                  <Typography fontSize={'12px'} sx={{ flex: '60%' }} variant="subtitle1">
                    {variable.name}
                  </Typography>
                </Flex>
              </MenuItem>
            ))}
            {filteredGlobalVariables?.map((variable) => (
              <MenuItem
                key={variable.name}
                divider={true}
                sx={{ px: 0 }}
                onClick={(e) => handleInsertGlobalVariable(variable)}>
                <Flex>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      bgcolor: theme.palette.background.lightGrey_dark,
                      py: '5px',
                      px: '12px',
                      borderRadius: '8px',
                      flex: '40%',
                      fontSize: '12px'
                    }}>
                    Global
                  </Typography>
                  <Typography fontSize={'12px'} sx={{ flex: '60%' }} variant="subtitle1">
                    {variable.name}
                  </Typography>
                </Flex>
              </MenuItem>
            ))}
            {Array.isArray(dataSource) &&
              dataSource?.length &&
              filteredExternalFileVariablesData?.map((key) => (
                <MenuItem
                  key={key}
                  divider={true}
                  sx={{ px: 0 }}
                  onClick={(e) => handleInsertExternalFileVariable(key)}>
                  <Flex>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        bgcolor: theme.palette.background.lightGrey_dark,
                        py: '5px',
                        px: '12px',
                        borderRadius: '8px',
                        flex: '40%',
                        fontSize: '12px'
                      }}>
                      External file
                    </Typography>
                    <Typography fontSize={'12px'} sx={{ flex: '60%' }} variant="subtitle1">
                      {key}
                    </Typography>
                  </Flex>
                </MenuItem>
              ))}
          </CustomScrollbar>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default InsertVariable;
