import React from 'react';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { capitalize } from '../../../utils/strings';
import Flex from '../../../components/base/Flex';
import { useParams } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';

const ProjectType = ({ value, setValue, disabled }) => {
  const { projectId } = useParams();

  return (
    <FormControl
      fullWidth
      size={'small'}
      sx={{
        height: { xs: 1, sm: 1 }
      }}>
      <InputLabel id="Execution-Type-select-label">Project type</InputLabel>
      <Select
        labelId="Execution-Type-select-label"
        id="Execution-Type-select"
        value={value}
        MenuProps={{
          elevation: 1
        }}
        disabled={!!projectId}
        label={'Project type'}
        renderValue={(selected) => capitalize(selected || '')}
        onChange={(e) => setValue(e.target.value)}>
        <MenuItem value={'web'}>Web</MenuItem>
        <MenuItem value={'mobile'} disabled={disabled}>
          <Flex>
            Mobile
            {disabled && (
              <Chip
                label="Premium"
                color="primary"
                sx={{ fontSize: '0.7rem' }}
                size={'small'}
              />
            )}
          </Flex>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default ProjectType;
