import { SvgIcon } from '@mui/material';

const ProjectSvg = ({ width = 40, height = 40, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox="0 0 10 12" {...props}>
      <path
        d="M1.80558 0.823853C1.19493 0.823853 0.700864 1.32348 0.700864 1.93412L0.695312 10.8163C0.695312 11.427 1.18938 11.9266 1.80003 11.9266H8.46722C9.07787 11.9266 9.57749 11.427 9.57749 10.8163V4.15467L6.24667 0.823853H1.80558ZM5.69154 4.70981V1.65656L8.74479 4.70981H5.69154Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ProjectSvg;
