import { SvgIcon } from '@mui/material';

const UploadFileSvg = ({ width = 18, height = 15, fill }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9258 4.09969C12.694 4.05348 12.5043 3.90856 12.4063 3.70132C11.3211 1.41122 8.80557 0.130699 6.29143 0.59418C4.00063 1.01425 2.20482 2.84297 1.82185 5.14567C1.70843 5.82549 1.71684 6.50601 1.84496 7.16902C1.88697 7.38536 1.79385 7.6262 1.60272 7.79983C0.801077 8.52866 0.341797 9.56694 0.341797 10.6493C0.341797 12.7721 2.069 14.5 4.19247 14.5H11.8938C14.7895 14.5 17.1447 12.1448 17.1447 9.24909C17.1447 6.75315 15.3706 4.58697 12.9258 4.09969ZM10.9283 8.69389C10.7918 8.83041 10.6126 8.89903 10.4334 8.89903C10.2541 8.89903 10.0749 8.83041 9.93837 8.69389L8.74326 7.49878V10.9994C8.74326 11.3866 8.4296 11.6995 8.04314 11.6995C7.65667 11.6995 7.34302 11.3866 7.34302 10.9994V7.49878L6.14791 8.69389C5.87416 8.96764 5.43168 8.96764 5.15794 8.69389C4.88419 8.42014 4.88419 7.97766 5.15794 7.70392L7.05317 5.80869C7.32341 5.53844 7.67837 5.40262 8.03334 5.40052L8.04314 5.39842L8.05294 5.40052C8.4086 5.40262 8.76286 5.53844 9.03311 5.80869L10.9283 7.70392C11.2021 7.97766 11.2021 8.42014 10.9283 8.69389Z"
        fill={fill || "#24C3D9"}
      />
    </SvgIcon>
  );
};

export default UploadFileSvg;
