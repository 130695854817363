import Typography from '@mui/material/Typography';
import AndroidSvg from '../../../components/svg/AndroidSvg';
import IphoneSvg from '../../../components/svg/IphoneSvg';

const MobileExecutionEnv = ({ platform, device }) => {
  const platformIconMap = {
    android: <AndroidSvg width={20} />,
    ios: <IphoneSvg width={20} />
  };

  const platformLabelMap = {
    android: 'Android',
    ios: 'iOS'
  };

  return (
    <>
      {platformIconMap[platform]}
      <Typography
        fontSize={'0.8rem'}
        sx={{ lineHeight: 1, mt: '2px', textTransform: 'capitalize', px: '4px' }}>
        {device} /
      </Typography>
      {platformLabelMap[platform]}
    </>
  );
};

export default MobileExecutionEnv;
