import { Box, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { tabStyle } from '../../NewApi';
import { TestListItemsDraggable } from './TestsList';
import { useContext, useState } from 'react';
import { TestAutomationContext } from '../../../../store/testAutomationState';
import CustomScrollbar from '../../../TestPage/components/CustomScrollbar';
import { useRef } from 'react';
import Flex from '../../../../components/base/Flex';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EmptyState from '../../../../components/base/EmptyState';
import { ConfirmDialog, useConfirmDialog } from '../../../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import ScheduleModal from '../../../../components/ScheduleModal';
import useAwaitModal from '../../../../hooks/useAwaitModal';
import ScheduleActionMenu from '../../../TestSuites/components/ScheduleActionMenu';

const TestListAndScheduleTab = ({ isFetchingSuite, refetchSingleSuite }) => {
  const { api_suite_id } = useParams();
  const { activeTab, setActiveTab } = useContext(TestAutomationContext);

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(event, value) => setActiveTab(value)}
        indicatorColor={'secondary'}
        sx={{ minHeight: 0, maxHeight: 'max-content' }}
        TabIndicatorProps={{
          sx: { height: 2 }
        }}>
        <Tab
          label={'Tests'}
          disableRipple={true}
          sx={{
            ...tabStyle,
            mr: 1,
            fontSize: 15
          }}
        />
        <Tab
          disabled={!api_suite_id || !!isFetchingSuite}
          label={'Schedules'}
          disableRipple={true}
          sx={{
            ...tabStyle,
            mr: 1,
            fontSize: 15
          }}
        />
      </Tabs>
      <CustomScrollbar
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: '600px',
          pr: 1,
          mt: 2
        }}>
        {activeTab === 0 && <TestListItemsDraggable />}
        {activeTab === 1 && <SuiteScheduleComponentList refetchSingleSuite={refetchSingleSuite} />}
      </CustomScrollbar>
    </>
  );
};

const SuiteScheduleComponentList = ({ refetchSingleSuite }) => {
  const { activeSuite } = useContext(TestAutomationContext);
  return (
    <Stack gap={1} sx={{}}>
      {activeSuite?.schedules?.map((schedule, index) => {
        return (
          <SuiteScheduleComponent
            key={schedule.id}
            schedule={schedule}
            index={index}
            refetchSuites={refetchSingleSuite}
          />
        );
      })}
      {!activeSuite?.schedules?.length && (
        <EmptyState
          title="There are no active run schedules for this suite"
          description="You can create schedules for Scandium to run your suites automatically "
        />
      )}
    </Stack>
  );
};

const SuiteScheduleComponent = ({ schedule, refetchSuites }) => {
  const { api_suite_id } = useParams();
  const menuRef = useRef(null);
  const activeProject = useActiveProject();
  const { requestConfirm: confirmDelete, ConfirmationDialog: DeleteScheduleDialog } =
    useConfirmDialog();

  const [
    requestScheduleModal,
    { open: openScheduleModal, onClose: onCloseScheduleModal, onComplete: completeScheduleModal }
  ] = useAwaitModal();

  const { mutateAsync: deleteSchedule, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/schedules`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchSuites();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteSuite = async () => {
    if (await confirmDelete()) return await deleteSchedule({ suite_schedule_id: `${schedule.id}` });
  };

  return (
    <Flex sx={{ columnGap: 2 }}>
      <ScheduleIcon fontSize="large" sx={{ color: '#24C3D9' }} />
      <Flex alignItems="flex-start" sx={{ flexDirection: 'column' }}>
        <Typography fontWeight={500} color={'black'} sx={{ lineHeight: 1, mt: '4px' }}>
          {schedule?.name || ''}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1,
            mt: '4px',
            display: 'inline-block',
            fontSize: '0.85rem',
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}>
          {schedule.description || ''}
        </Typography>
      </Flex>
      <Box
        sx={{
          ml: 'auto'
        }}>
        <ScheduleActionMenu
          suiteId={api_suite_id}
          isLoading={isDeleting}
          onDelete={handleDeleteSuite}
          schedule={schedule}
          onSchedule={() => requestScheduleModal()}
          ref={menuRef}
        />
      </Box>

      <DeleteScheduleDialog
        title={'Are you sure you want to delete this test suite schedule?'}
        description={'Suite Schedule will be permanently deleted'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <ScheduleModal
        open={openScheduleModal}
        onClose={onCloseScheduleModal}
        onSuiteCreated={completeScheduleModal}
        suiteId={api_suite_id}
        scheduleType={'api_suite'}
        refetchSuites={refetchSuites}
        schedule={schedule}
      />
    </Flex>
  );
};

// const SuiteScheduleComponent = ({ schedule, index }) => {
//   const activeProject = useActiveProject();
//   const queryClient = useQueryClient();
//   const {
//     mutateAsync: deleteSuiteSchedule,
//     isLoading: isDeleting,
//     isSuccess: deleteSuccessful
//   } = useScandiumMutation(`/projects/${activeProject?.id}/schedules`, {
//     method: 'DELETE',
//     onSuccess: (data) => {
//       queryClient.invalidateQueries({ queryKey: ['fetch-single-suite'] });
//       toast.success('Deletion successful', { autoClose: 1000 });
//     },
//     onError: (error) => {
//       toast.error(error.message, { autoClose: 1000 });
//     }
//   });
//   const [showEditIcon, setShowEditIcon] = useState(false);
//   const { activeSuite } = useContext(TestAutomationContext);
//   const { requestConfirm: confirmScheduleDeletion, ConfirmationDialog: DeleteApiSuiteSchedule } =
//     useConfirmDialog();
//   return (
//     <>
//       <Flex
//         onMouseEnter={() => {
//           setShowEditIcon(true);
//         }}
//         onMouseLeave={() => {
//           setShowEditIcon(false);
//         }}
//         sx={{
//           alignItems: 'center',
//           outline: '',
//           background: '#F6FAFF',
//           borderRadius: 2,
//           '&:hover': {
//             background: 'rgba(220,220,220, 0.3)',
//             pointer: 'cursor'
//           },
//           columnGap: 2,
//           py: 1,
//           px: 4
//         }}>
//         <ScheduleIcon fontSize="large" sx={{ color: '#24C3D9' }} />
//         <Stack>
//           <Typography fontSize={14} color={'primary'}>
//             {schedule?.name}
//           </Typography>
//           <Typography fontSize={12}>{schedule?.description}</Typography>
//         </Stack>
//         <Box
//           onClick={() => {
//             confirmScheduleDeletion();
//           }}
//           marginLeft={'auto'}>
//           {showEditIcon && <DeleteSvg width={16} height={18} />}
//         </Box>
//         {/* <BorderColorOutlinedIcon
//                 color="disabled"
//                 sx={{
//                   ml: 'auto',
//                   '&:hover': {
//                     color: '#2196f3'
//                   }
//                 }}
//               /> */}
//       </Flex>
//       {index + 1 !== activeSuite.schedules.length && <Divider orientation="horizontal" />}
//       <DeleteApiSuiteSchedule
//         runRequest={() => {
//           deleteSuiteSchedule({ suite_schedule_id: `${schedule.id}` });
//         }}
//         runningRequest={isDeleting}
//         requestSuccessful={deleteSuccessful}
//         title={`Are you sure you want to delete suite schedule; "${schedule?.name}" ?`}
//         description={'Suite Schedule will be permanently deleted'}
//         confirmLabel={'Delete'}
//         confirmColor={'error'}
//       />
//     </>
//   );
// };
export default TestListAndScheduleTab;
