import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, TableCell, tableCellClasses, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Flex from '../../components/base/Flex';
import CustomButton from '../../components/customButton';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import PageLoader from '../../components/PageLoader';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import { CircularImage } from '../../components/personalInformation';
import useAwaitModal from '../../hooks/useAwaitModal';
import AddMembersModal from './components/AddMembersModal';
import ActionMenu from './components/ActionMenu';
import UserContext from '../../store/userState';
import ErrorState from '../../components/base/ErrorState';
import MembersOverview from './components/MembersOverview';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../AppTheme';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
    padding: '8px 0px',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    },
    '.cell-content-wrapper': {
      padding: '0px',
      backgroundColor: theme.palette.background.testCaseList,
      // color: '#000',
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: '42px',
      display: 'flex',
      alignItems: 'center'
    },

    '&:first-child': {
      minWidth: '250px',
      '.cell-content-wrapper': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    },

    '&:last-child': {
      '.cell-content-wrapper': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0
  },
}));

const MemberRole = ({ role, setRole, member, handleMemberRoleUpdate }) => {
  return (
    <Select
      value={role}
      disabled={member.membership.role === 'owner'}
      MenuProps={{
        elevation: 1,
      }}
      onChange={(e) => {
        setRole(e.target.value);
        handleMemberRoleUpdate(e.target.value);
      }}
      sx={{
        borderRadius: 0,
        minWidth: 100,
        fontSize: 12,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }}>
      <MenuItem disableRipple value={'member'}>
        Member
      </MenuItem>
      <MenuItem disableRipple value={'admin'}>
        Admin
      </MenuItem>
      {member.membership.role === 'owner' && (
        <MenuItem disableRipple value={'owner'}>
          Owner
        </MenuItem>
      )}
    </Select>
  );
};

const Member = ({ member, refetchMembers }) => {
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const [role, setRole] = useState(member.membership.role || 'member');
  const menuRef = useRef();

  const { mutateAsync: removeMember, isLoading: isRemovingMember } = useScandiumMutation(
    `/company/members/remove`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchMembers();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { mutateAsync: updateMemberRole, isLoading: isUpdatingMemberRole } = useScandiumMutation(
    `/company/members/update`,
    {
      method: 'PUT',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchMembers();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleMemberRoleUpdate = async (memberRole) => {
    await updateMemberRole({ user_id: member.id, role: memberRole });
  };

  const handleRemoveMember = async () => {
    if (await requestConfirm()) return await removeMember({ user_id: member.id });
  };

  return (
    <StyledTableRow tabIndex={0} role={'link'} ref={menuRef}>
      <StyledTableCell component="th" scope="row">
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}
          className={'cell-content-wrapper'}>
          <CircularImage name={member.name} style={{ width: '2.2rem', height: '2.2rem' }} />
          <Flex justifyContent={'flex-start'} flexDirection={'column'} alignItems={'normal'}>
            <Typography
              sx={{
                textAlign: 'left',
                fontWeight: 500
              }}>
              {member.name}
            </Typography>
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '0.8rem'
              }}>
              {member.email}
            </Typography>
          </Flex>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          <MemberRole
            role={role}
            setRole={setRole}
            member={member}
            handleMemberRoleUpdate={handleMemberRoleUpdate}
          />
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            onDelete={handleRemoveMember}
            ref={menuRef}
            isLoading={isRemovingMember}
            disabled={member.membership.role === 'owner'}
          />
        </Box>
      </StyledTableCell>
      <ConfirmationDialog
        title={'Are you sure you want to remove this Team Member?'}
        description={
          'Removing a team member from the project will revoke their access and permissions to this project and the team member will no longer be able to view or interact with project-related content'
        }
        confirmLabel={'Proceed'}
        confirmColor={'error'}
      />
    </StyledTableRow>
  );
};

const Settings = () => {
  const { userData } = useContext(UserContext);
  const theme = useTheme();
  const { mode } = useContext(ColorContext)
  const activeProject = useActiveProject();
  useDocumentTitle('Organizations - Manage Members');

  const {
    data,
    isLoading,
    error: membersError,
    refetch: refetchMembers
  } = useScandiumQuery(`/company`, {
    queryKey: 'company',
    // enabled: !!activeProject?.id,
    select: (data) => data.data
  });

  const [
    requestAddMemberModal,
    {
      open: openCreateAddMemberModal,
      onClose: onCloseAddMemberModal,
      onComplete: completeAddMemberModal
    }
  ] = useAwaitModal();

  return (
    <Box>
      <Flex justifyContent={'space-between'}>
        <Typography variant={'h5'} color={'primary'}>
          {userData?.user?.active_company?.name || userData?.user?.company?.name || ''}
        </Typography>
        <CustomButton
          onClick={() => requestAddMemberModal()}
          disableRipple={true}
          startIcon={<GroupAddIcon color={'secondary'} />}
          disableFocusRipple={true}
          fullWidth
          disableElevation
          label={'Add Members'}
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            typography: { xs: 'caption', sm: 'caption' },
            borderRadius: '0.4rem',
            color: theme.palette.button.textContained,
            border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
            background: mode === 'dark' ? 'none' : 'primary',
            py: { xs: '8rem', sm: '0.4rem' },
            px: 2,
            width: 'max-content'
          }}
        />
      </Flex>
      <Typography variant={'h6'}>Manage Members</Typography>

      <MembersOverview data={data} />

      {/* <Divider sx={{ my: 4 }} /> */}
      <Box my={7}></Box>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: 1, mb: 4, borderBottom: '2px solid #E3E3E3' }}>
        <Table sx={{ width: '100%', minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align={'center'}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.members?.map((member) => (
              <Member key={member.id} member={member} refetchMembers={refetchMembers} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <PageLoader height={'100px'} />}
      {!isLoading && membersError && !data?.members?.length && <ErrorState error={membersError} />}

      <AddMembersModal
        open={openCreateAddMemberModal}
        onClose={onCloseAddMemberModal}
        onComplete={completeAddMemberModal}
        refetchMembers={refetchMembers}
      />
    </Box>
  );
};

export default Settings;
