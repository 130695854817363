import { SvgIcon } from '@mui/material';

const SaveSvg = ({ width = 20, height = 20, disabled, fill, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={'0 0 10 10'}
      {...props}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d="M3.19073 1.64579V0.0356445H6.41103V1.64579C6.41103 1.7312 6.3771 1.81311 6.31671 1.8735C6.25631 1.93389 6.17441 1.96782 6.089 1.96782H3.51276C3.42735 1.96782 3.34544 1.93389 3.28505 1.8735C3.22466 1.81311 3.19073 1.7312 3.19073 1.64579ZM2.8687 9.05247H8.02117V7.94469H2.8687V9.05247ZM2.8687 7.30063H8.02117V6.65657H2.8687V7.30063ZM9.63132 2.19324L8.14033 0.386657C8.0498 0.277013 7.93619 0.188702 7.8076 0.128027C7.67902 0.067351 7.53862 0.0358066 7.39644 0.0356445H7.05509V1.64579C7.05509 1.90202 6.9533 2.14774 6.77213 2.32892C6.59095 2.5101 6.34522 2.61188 6.089 2.61188H3.51276C3.25654 2.61188 3.01081 2.5101 2.82963 2.32892C2.64846 2.14774 2.54667 1.90202 2.54667 1.64579V0.0356445H1.90261C1.64639 0.0356445 1.40066 0.137429 1.21948 0.318605C1.03831 0.499782 0.936523 0.745511 0.936523 1.00173V8.08638C0.936523 8.34261 1.03831 8.58834 1.21948 8.76951C1.40066 8.95069 1.64639 9.05247 1.90261 9.05247H2.22464V5.83218C2.22464 5.57595 2.32643 5.33023 2.5076 5.14905C2.68878 4.96787 2.93451 4.86609 3.19073 4.86609H7.69915C7.95537 4.86609 8.2011 4.96787 8.38227 5.14905C8.56345 5.33023 8.66523 5.57595 8.66523 5.83218V9.05247H8.98726C9.24349 9.05247 9.48921 8.95069 9.67039 8.76951C9.85157 8.58834 9.95335 8.34261 9.95335 8.08638V2.90815C9.95263 2.7731 9.92359 2.6397 9.86813 2.51656C9.81266 2.39342 9.73199 2.28328 9.63132 2.19324ZM7.69915 5.51015H3.19073C3.10532 5.51015 3.02341 5.54408 2.96302 5.60447C2.90263 5.66486 2.8687 5.74677 2.8687 5.83218V6.01251H8.02117V5.83218C8.02117 5.74677 7.98725 5.66486 7.92686 5.60447C7.86646 5.54408 7.78455 5.51015 7.69915 5.51015Z"
        fill={fill || '#24C3D9'}
      />
    </SvgIcon>
  );
};

export default SaveSvg;
