import { SvgIcon } from '@mui/material';

const RotateSvg = ({ width = 28, height = 20, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 18 12'} {...props} fill={'none'}>
      <path
        d="M8.99129 0.964844C3.86626 0.964844 0 2.89798 0 5.4605C0 7.66333 2.86823 9.39868 6.93226 9.84822L6.55462 10.2169C6.47038 10.3005 6.40348 10.3999 6.35782 10.5095C6.3122 10.619 6.28868 10.7365 6.28868 10.8553C6.28868 10.974 6.3122 11.0915 6.35782 11.2011C6.40348 11.3106 6.47038 11.4101 6.55462 11.4936C6.63822 11.5779 6.73766 11.6448 6.84723 11.6905C6.95681 11.7361 7.07433 11.7596 7.19302 11.7596C7.3117 11.7596 7.42922 11.7361 7.5388 11.6905C7.64838 11.6448 7.74782 11.5779 7.83141 11.4936L9.62964 9.69539C9.71151 9.60985 9.77569 9.50904 9.81846 9.39868C9.9084 9.17978 9.9084 8.93421 9.81846 8.71531C9.77569 8.60496 9.71151 8.50414 9.62964 8.4186L7.83141 6.62037C7.6621 6.45107 7.43246 6.35595 7.19302 6.35595C6.95358 6.35595 6.72393 6.45107 6.55462 6.62037C6.38531 6.78968 6.29024 7.01929 6.29024 7.25873C6.29024 7.49817 6.38531 7.72781 6.55462 7.89712L6.66252 7.99604C3.59652 7.56446 1.79825 6.35961 1.79825 5.4605C1.79825 4.36355 4.60353 2.76311 8.99129 2.76311C13.379 2.76311 16.1843 4.36355 16.1843 5.4605C16.1843 6.20675 14.8806 7.25873 12.399 7.7982C12.2815 7.82073 12.1697 7.86644 12.0701 7.93268C11.9706 7.99893 11.8852 8.08434 11.8191 8.18399C11.7529 8.28365 11.7073 8.39547 11.6849 8.51295C11.6625 8.63042 11.6638 8.75118 11.6887 8.86818C11.7132 8.98402 11.7604 9.09385 11.8275 9.19144C11.8947 9.28897 11.9804 9.37231 12.0798 9.43666C12.1792 9.50097 12.2904 9.54503 12.4069 9.56631C12.5234 9.58754 12.6429 9.5856 12.7586 9.56052C16.0404 8.84121 17.9826 7.31267 17.9826 5.4605C17.9826 2.89798 14.1163 0.964844 8.99129 0.964844Z"
        fill={fill || "#1958B8"}
      />
    </SvgIcon>
  );
};

export default RotateSvg;
