import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ContainedButton from '../../components/base/ContainedButton';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';

function ChangePasswordAfterReset() {
  useDocumentTitle("Scandium - Set A New Password")
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['email']);

  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { mutateAsync, isLoading: showProgress } = useScandiumMutation('/auth/password/reset');

  const handleClickShowPassword = () => {
    setShowPassword((val) => !val);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((val) => !val);
  };

  const handleMatch = () => {
    if (password !== password_confirmation) {
      setPasswordMatch('The password confirmation does not match.');
    } else {
      setPasswordMatch(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long.');
    } else if (!password_confirmation) {
      setPasswordMatch('Confirm your password');
    } else {
      try {
        const data = await mutateAsync({
          email: cookies.email,
          password,
          password_confirmation
        });

        setCookie('email', '', {
          path: '/',
          sameSite: 'lax',
          secure: true
        });
        setSuccess(true);
      } catch (error) {
        toast.error(error.message || 'An error occurred, please try again');
      }
    }
  };

  return (
    <Box
      sx={{
        mx: { xs: 3.5, sm: 5 }
      }}>
      <Box
        sx={{
          mt: { xs: 1, sm: 3 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Box sx={{ width: { xs: '30%', sm: '35', md: '20%', lg: '15%' } }}>
          <img
            style={{ width: '100%' }}
            src={'/images/Scandium_logo_beta.svg'}
            alt={'Scandium logo'}
          />
        </Box>
        <Typography
          sx={{
            cursor: 'pointer',
            textAlign: 'right',
            typography: { sm: 'h6', md: 'h6' }
          }}>
          Password reset
        </Typography>
      </Box>
      {!success && (
        <Box
          sx={{
            mx: { xs: 0, sm: 6 },
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              width: '25rem'
            }}>
            <Typography
              sx={{
                align: 'left',
                typography: { xs: 'h6', md: 'h4' },
                mt: { xs: 25, sm: 20 },
                fontWeight: '700'
              }}>
              Reset password
            </Typography>
            <form onSubmit={handleSubmit} method={'POST'} action={'#'}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                required
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                label="New password (8+ Characters)"
                value={password}
                error={passwordError != null}
                helperText={passwordError}
                sx={{
                  mt: { xs: 6, sm: 4 },
                  width: '100%'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                required
                onChange={(event) => {
                  setPasswordConfirmation(event.target.value);
                }}
                label="Confirm password (8+ Characters)"
                value={password_confirmation}
                error={passwordMatch != null}
                helperText={passwordMatch}
                sx={{
                  mt: { xs: 6, sm: 4 },
                  width: '100%'
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end">
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <ContainedButton
                type={'submit'}
                disableRipple={true}
                fullWidth
                isLoading={showProgress}
                sx={{
                  textAlign: 'center',
                  textTransform: 'capitalize',
                  py: 1.5,
                  mt: { xs: 2, sm: 3 }
                }}>
                Reset Password
              </ContainedButton>
            </form>
          </Box>
        </Box>
      )}

      {success && (
        <Box
          sx={{
            mx: { xs: 0, sm: 6 },
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box>
            <Typography
              sx={{
                mt: { xs: 20, sm: 20 },
                backgroundColor: 'rgba(36, 195, 217, 0.16)',
                border: '0.09rem solid #24C3D9',
                borderRadius: '0.2rem',
                width: '25rem',
                height: '5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <IconButton
                aria-label="cancel"
                sx={{
                  clear: 'right'
                }}>
                <CheckCircleOutlineOutlinedIcon
                  sx={{
                    color: 'success',
                    ml: 2
                  }}
                  fontSize="large"
                />
              </IconButton>
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{
                  pl: 0.7,
                  mr: 2,
                  textAlign: 'left'
                }}>
                Your password has been successfully changed. Please login with your new password
              </Typography>
            </Typography>
            <ContainedButton
              onClick={() => navigate('/')}
              disableRipple={true}
              fullWidth
              sx={{
                py: 1.5,
                mt: { xs: 2, sm: 3 }
              }}>
              Go to Log in
            </ContainedButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ChangePasswordAfterReset;
