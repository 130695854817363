import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import useLogout from '../hooks/useLogout';
import { activeClassName } from './sidebar';
import { useScandiumMutation } from '../data-layer/utils';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { ColorContext } from '../AppTheme';
import { resetMixpanelData } from '../mixpanel.constants';

const SuiteSideBar = ({ SidebarItems }) => {
  const { mode } = useContext(ColorContext);
  const { projectId, suiteId } = useParams();
  const logout = useLogout();
  const queryClient = useQueryClient();

  const { mutateAsync: apiLogout } = useScandiumMutation('/auth/logout', {
    method: 'GET'
  });

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      await apiLogout();
      logout();
      queryClient.clear();
      resetMixpanelData()
    } catch (error) {
      toast.error('An error occurred, please try again');
    }
  };

  const _nonActiveClassName = {
    color: mode === 'dark' ? '#ffffffb3' : '#111111',
    fontWeight: 400,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderLeft: '2px solid',
    borderLeftColor: '#EBEBEB'
  };

  return (
    <Box
      sx={{
        // Changed this to account for the extra space
        marginTop: { xs: '3.5rem', sm: '10rem' },
        mx: { xs: '1.5rem', sm: '2rem', md: '4rem' },
        color: 'inherit'
      }}>
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          position: { xs: 'static', sm: 'fixed' },
          maxWidth: { xs: 'auto', sm: '13rem' }
        }}></Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'column' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: { xs: 'center', sm: 'flex-start' },
          height: { xs: 'auto', sm: 'auto' },
          width: { xs: '100%', sm: '12rem' },
          position: { xs: 'static', sm: 'fixed' },
          mt: { xs: 10, sm: 16 }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: { xs: 'flex-start', sm: 'center' },
            alignItems: { xs: 'center', sm: 'flex-start' },
            width: { xs: '100%', sm: 'auto' }
          }}>
          <NavLink
            to={`/projects/${projectId}/test-suites/new`}
            end
            style={({ isActive }) => (isActive ? activeClassName : _nonActiveClassName)}>
            <Button
              fullWidth
              sx={{
                typography: 'body2',
                textTransform: 'capitalize',
                color: 'inherit'
              }}>
              New Suite
            </Button>
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              &#9474;
            </Box>
          </NavLink>
          <NavLink
            to={`/projects/${projectId}/test-suites/${suiteId}/edit`}
            end
            style={({ isActive }) => (isActive ? activeClassName : _nonActiveClassName)}>
            <Button
              fullWidth
              sx={{
                typography: 'body2',
                textTransform: 'capitalize',
                color: 'inherit'
              }}>
              Edit Suite
            </Button>
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              &#9474;
            </Box>
          </NavLink>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            mt: { xs: 5, sm: 16 }
          }}>
          <Typography
            sx={{
              cursor: 'pointer',
              textAlign: 'right',
              typography: { sm: 'subtitle2', md: 'subtitle2' },
              pt: { xs: 6, sm: 1 }
            }}>
            Need Help?
          </Typography>
          <Box target={'_blank'} as={'a'} href={'https://getscandium.com/faqs/'}>
            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textAlign: 'right',
                typography: { sm: 'caption', md: 'caption' },
                pt: { xs: 6, sm: 3 },
                color: '#6A6A6A'
              }}>
              See popular questions
            </Typography>
          </Box>
          <Typography
            color="error"
            sx={{
              cursor: 'pointer',
              align: 'right',
              typography: { sm: 'subtitle2', md: 'subtitle2' },
              pt: { xs: 6, sm: 1 }
            }}
            onClick={handleLogout}>
            Logout
          </Typography>
        </Box>
      </Box>
      <Box className="mainBlock">
        <Outlet />
      </Box>
    </Box>
  );
};

export default SuiteSideBar;
