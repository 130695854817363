import { SvgIcon } from '@mui/material';

const JiraSvg = ({ width = 34, height = 34, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} fill="none" viewBox="0 0 24 24" {...props}>
      <path
        d="M23.0058 0H11.4514C11.4514 2.88 13.7828 5.21142 16.6628 5.21142H18.7886V7.26858C18.7886 10.1486 21.12 12.48 24 12.48V0.994313C24 0.445734 23.5543 0 23.0058 0Z"
        fill="#2684FF"
      />
      <path
        d="M17.2801 5.76H5.72567C5.72567 8.64 8.05713 10.9714 10.9372 10.9714H13.0629V13.0286C13.0629 15.9086 15.3944 18.24 18.2745 18.24V6.75431C18.2745 6.20569 17.8286 5.76 17.2801 5.76Z"
        fill="url(#paint0_linear_4134_5977)"
      />
      <path
        d="M11.5545 11.52H0C0 14.4 2.33145 16.7314 5.21149 16.7314H7.33721V18.7886C7.33721 21.6686 9.66876 24 12.5488 24V12.5143C12.5488 11.9657 12.1031 11.52 11.5545 11.52Z"
        fill="url(#paint1_linear_4134_5977)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4134_5977"
          x1="23.5454"
          y1="0.0283158"
          x2="14.0897"
          y2="9.72617"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.176" stop-color="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4134_5977"
          x1="12.6421"
          y1="11.5645"
          x2="6.95781"
          y2="17.0952"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.176" stop-color="#0052CC" />
          <stop offset="1" stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default JiraSvg;
