import { SvgIcon } from '@mui/material';

const ExportSvg = ({ width = 10, height = 9, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 12 12'} {...props} fill={'none'}>
      <path
        d="M8.62245 4.93222C8.50781 4.93222 8.39787 4.97776 8.31682 5.05881C8.23576 5.13987 8.19022 5.24981 8.19022 5.36444V6.66112C8.19022 7.00502 8.05361 7.33484 7.81043 7.57801C7.56726 7.82119 7.23744 7.9578 6.89354 7.9578H2.57129C2.22739 7.9578 1.89757 7.82119 1.6544 7.57801C1.41122 7.33484 1.27461 7.00502 1.27461 6.66112V2.33886C1.27461 1.99496 1.41122 1.66515 1.6544 1.42197C1.89757 1.1788 2.22739 1.04219 2.57129 1.04219H4.73241C4.84705 1.04219 4.95699 0.996648 5.03804 0.91559C5.1191 0.834532 5.16464 0.724594 5.16464 0.60996C5.16464 0.495327 5.1191 0.385388 5.03804 0.30433C4.95699 0.223272 4.84705 0.177734 4.73241 0.177734H2.57129C1.99833 0.178421 1.44904 0.406331 1.04389 0.811472C0.638753 1.21661 0.410843 1.76591 0.410156 2.33886V6.66112C0.410843 7.23408 0.638753 7.78337 1.04389 8.18851C1.44904 8.59365 1.99833 8.82156 2.57129 8.82225H6.89354C7.4665 8.82156 8.01579 8.59365 8.42093 8.18851C8.82608 7.78337 9.05399 7.23408 9.05467 6.66112V5.36444C9.05467 5.24981 9.00913 5.13987 8.92808 5.05881C8.84702 4.97776 8.73708 4.93222 8.62245 4.93222Z"
        fill="#24C3D9"
      />
      <path
        d="M2.57558 4.99359C2.58867 5.08475 2.63054 5.16934 2.69507 5.23504C2.7596 5.30073 2.84343 5.3441 2.93433 5.35882C2.95719 5.36261 2.98032 5.36449 3.00349 5.36444C3.08381 5.36448 3.16255 5.34214 3.23088 5.29992C3.29921 5.2577 3.35443 5.19727 3.39033 5.12542C3.92672 4.0522 5.35393 3.9878 6.02907 4.028V4.93222C6.02909 5.01769 6.05445 5.10124 6.10194 5.1723C6.14944 5.24336 6.21693 5.29875 6.2959 5.33145C6.37487 5.36416 6.46176 5.37272 6.54559 5.35605C6.62942 5.33938 6.70643 5.29823 6.76688 5.2378L8.92801 3.07667C8.971 3.0337 9.00445 2.98214 9.02617 2.92537C9.04788 2.86859 9.05738 2.80787 9.05403 2.74717C9.05069 2.68648 9.03458 2.62717 9.00676 2.57312C8.97893 2.51908 8.94002 2.47151 8.89256 2.43352L6.73144 0.704616C6.66786 0.653713 6.59119 0.621809 6.51027 0.61258C6.42935 0.603352 6.34747 0.617174 6.27406 0.652455C6.20066 0.687735 6.13871 0.743038 6.09536 0.811991C6.05202 0.880943 6.02904 0.96074 6.02907 1.04218V1.87119C4.81884 1.81457 3.89387 2.10243 3.28833 2.72657C3.01652 3.03291 2.81252 3.39323 2.68969 3.78391C2.56686 4.1746 2.52798 4.58683 2.57558 4.99359Z"
        fill="#24C3D9"
      />
    </SvgIcon>
  );
};

export default ExportSvg;
