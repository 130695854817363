import { Input, styled, TextField } from '@mui/material';

export const EditableTitle = styled(TextField)(({ theme }) => ({
  marginBottom: '-3px',

  ' input': {
    padding: '4px 8px',
    color: theme.palette.text.primary,
    fontSize: '1rem'
  },

  '&:not(:focus-within) fieldset': {
    border: 'none'
  }
}));
