import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Flex from '../../../components/base/Flex';
import FormControl from '@mui/material/FormControl';
import React, { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box, Typography, InputLabel } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from '@mui/material/IconButton';
import { capitalize } from '../../../utils/strings';

export const FieldTitle = ({ children }) => {
  return (
    <Typography
      sx={{
        textAlign: 'left',
        fontSize: '0.875rem',
        opacity: 0.5
      }}>
      {children}
    </Typography>
  );
};

export const EditableIndicator = ({ showHelper }) => (
  <IconButton sx={{ p: 0, cursor: 'default', m: 0 }}>
    <Flex alignItems={'baseline'}>
      <BorderColorIcon
        color="#787878"
        sx={{
          fontSize: '12px'
        }}
        style={{ cursor: 'default' }}
      />
      {showHelper && (
        <Typography component={'span'} fontSize={'12px'} sx={{ ml: '-3px' }}>
          Edit
        </Typography>
      )}
    </Flex>
  </IconButton>
);

export const predefinedScreenSizes = [
  { label: 'Desktop (1440px X 1000px)', value: [1440, 1000] },
  { label: 'Desktop (1200px X 650px)', value: [1200, 650] },
  { label: 'Desktop (1024px X 650px)', value: [1024, 650] },
  { label: 'Tablet (768px X 650px)', value: [768, 650] },
  { label: 'Mobile (425px X 650px)', value: [425, 650] },
  { label: 'Mobile (375px X 650px)', value: [375, 650] }
];

const CustomScreenSizeInput = React.forwardRef(
  ({ value, onChange, handleEnter, label, nextRef, prevRef, ...others }, ref) => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleEnter(e);
        return;
      }

      if (e.key === 'Tab') {
        if (e.shiftKey && !!prevRef?.current) {
          e.preventDefault();
          e.stopPropagation();
          prevRef.current.focus();
        } else if (!!nextRef?.current) {
          e.preventDefault();
          e.stopPropagation();
          nextRef.current.focus();
        }
      }
    };
    return (
      <TextField
        label={label}
        value={value}
        inputRef={ref}
        type={'number'}
        size="small"
        onChange={onChange}
        onKeyDown={handleKeyDown}
        {...others}
        sx={{
          border: '1x solid primary',
          maxWidth: '100px',
          borderRadius: 4
        }}
      />
    );
  }
);

export const ScreenSize = ({ onChange, test, customScreenSize, setCustomScreenSize, screenSizeIndex, setScreenSizeIndex }) => {
  
  const [open, setOpen] = useState(false);

  const widthRef = useRef(null);
  const heightRef = useRef(null);

  const getScreenSize = (index) =>
    ((index !== -1 && predefinedScreenSizes[index].value) || customScreenSize || []).map((col) =>
      parseInt(col)
    );

  // for when you press enter on the inputs. Only a formality really as the inputs already handle this themselves
  const handleSubmit = () => {
    if (!customScreenSize[0] || !customScreenSize[1]) return;
    setScreenSizeIndex(-1);
    onChange(getScreenSize(-1));
    setOpen(false);
  };

  const handleBlur = () => {
    if (!customScreenSize[0] || !customScreenSize[1]) return;
    setScreenSizeIndex(-1);
    onChange(getScreenSize(-1));
  };

  const handleScreenSizeChange = (event) => {
    if (event.target.value !== null) setCustomScreenSize(['', '']);
    setScreenSizeIndex(event.target.value);
    onChange(getScreenSize(event.target.value));
  };

  const handleCustomInputChange = (newValue) => {
    setCustomScreenSize(newValue);
    if (!newValue[0] || !newValue[1]) return;
    setScreenSizeIndex(-1);
    onChange(getScreenSize(-1));
  };

  return (
    <Typography
      sx={{
        textAlign: 'left',
        fontSize: '0.875rem',
        fontWeight: test ? 'normal' : 'bold',
        mt: test ? '0rem' : '-0.7rem'
      }}>
      <FormControl fullWidth size={'small'} required>
        <InputLabel id="select-label">{test ? 'Resolution' : ''}</InputLabel>
        <Select
          variant={test ? 'outlined' : 'standard'}
          label={test ? 'Resolution' : ''}
          disabled={!onChange}
          disableUnderline={true}
          value={screenSizeIndex}
          onChange={handleScreenSizeChange}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            elevation: 1
          }}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          renderValue={(value) =>
            value !== -1
              ? predefinedScreenSizes[value].label
              : `${customScreenSize[0] || '-'}px X ${customScreenSize[1] || '-'}px`
          }
          sx={{
            outline: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '0.875rem',
            fontWeight: test ? 'normal' : 'bold'
          }}>
          {predefinedScreenSizes.map((size, index) => (
            <MenuItem key={index} value={index} divider={true}>
              {size.label}
            </MenuItem>
          ))}

          <Box
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            sx={{ '&:hover': { background: 'transparent' }, px: 1.5, mt: 1.5 }}>
            <Flex>
              <CustomScreenSizeInput
                label={'Width'}
                value={customScreenSize[0]}
                handleEnter={handleSubmit}
                ref={widthRef}
                nextRef={heightRef}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleCustomInputChange([parseInt(e.target.value), customScreenSize[1]]);
                }}
              />
              <CustomScreenSizeInput
                label={'Height'}
                value={customScreenSize[1]}
                handleEnter={handleSubmit}
                ref={heightRef}
                prevRef={widthRef}
                onBlur={handleBlur}
                onChange={(e) => {
                  handleCustomInputChange([customScreenSize[0], parseInt(e.target.value)]);
                }}
              />
            </Flex>
          </Box>
        </Select>
      </FormControl>
    </Typography>
  );
};

export const BrowserSelect = ({ value, onChange }) => {
  return (
    <FormControl>
      <Select
        variant="standard"
        disabled={!onChange}
        disableUnderline={true}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          elevation: 1
        }}
        renderValue={(selected) => capitalize(selected || '')}
        sx={{
          outline: 'none',
          border: 'none',
          backgroundColor: 'transparent',
          fontSize: '0.875rem',
          fontWeight: 'bold'
        }}>
        <MenuItem value="chrome">Chrome</MenuItem>
        <MenuItem value="firefox">Firefox</MenuItem>
        <MenuItem value="edge">Edge</MenuItem>
      </Select>
    </FormControl>
  );
};

export const OSSelect = ({ value, onChange }) => {
  return (
    <Box
      sx={{
        textAlign: 'left',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        mt: { xs: 2, sm: '0.1rem' }
      }}>
      <FormControl>
        <Select
          variant="standard"
          disabled={!onChange}
          disableUnderline={true}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            elevation: 1
          }}
          classes={{
            select: { '&:selected': { backgroundColor: 'transparent' } },
            selectMenu: { backgroundColor: '#FFFFFF' }
          }}
          sx={{
            outline: 'none',
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '0.875rem',
            fontWeight: 'bold'
          }}>
          <MenuItem value="macos">MacOS</MenuItem>
          <MenuItem value="windows">Windows</MenuItem>
          <MenuItem value="linux">Linux</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export const FolderSelect = ({ folders, value, onChange }) => {
  return (
    <FormControl>
      <Select
        variant="standard"
        disabled={!onChange}
        disableUnderline={true}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }}
        sx={{
          outline: 'none',
          border: 'none',
          backgroundColor: 'transparent',
          fontSize: '0.875rem',
          fontWeight: 'bold'
        }}>
        <MenuItem value={'All tests'}>All tests</MenuItem>
        {folders.map((folder, index) => (
          <MenuItem key={index} value={folder.id}>
            {folder.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
