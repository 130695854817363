import { format } from 'date-fns';
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useActiveProject } from '../../../store/projectState';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { dateFormats } from '../constant';
import {
  generateDateOffset,
  generateDatetimeOffset,
  generateRandomAlpha,
  generateRandomAlphanumeric,
  generateRandomInRange,
  generateRandomNumber,
  generateTimeOffset
} from '../utils';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../../AppTheme';

/**
 *
 * @param { boolean } open
 * @param { () => void } onClose
 * @param { ({meta: {}, title: any, id: string, steps: any}) => void } handleUpdate - callback function that will be called to update the state
 * @param inputValue
 * @returns {JSX.Element}
 * @constructor
 */

export const DateFormatSelect = ({ formattedDate, setDateFormat, generateFormattedDate }) => {
  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="demo-simple-select-label">Date Format</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={formattedDate.format}
        onChange={(e) => {
          setDateFormat((prev) => ({
            ...prev,
            format: e.target.value
          }));
          generateFormattedDate(formattedDate.offSet, e.target.value);
        }}
        label="Date Format">
        {dateFormats.map((format) => (
          <MenuItem key={format} value={format}>
            {format}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MobileFunctionVariables = ({
  open,
  onClose,
  handleUpdate,
  inputValue,
  cursorIndex,
  handleInputFieldUpdate,
  index,
  onCompleteInsertion
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { email_identifier } = useActiveProject();
  const theme = useTheme();
  const { mode } = useContext(ColorContext);

  // Check the URL to determine whether the test is on playground or production
  const currentUrl = window.location.href;
  const isPlayground = currentUrl.includes('playground');

  const [randomNum, setRandomNum] = React.useState({ value: '', sampleOutput: '' });
  const [randomNumberInRange, setRandomNumberInRange] = React.useState({
    min: '',
    max: '',
    sampleOutput: ''
  });
  const [randomAlphaString, setRandomAlphaString] = React.useState({
    stringLength: '5',
    charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    sampleOutput: 'itKRJ'
  });
  const [randomAlphaNumericString, setRandomAlphaNumericString] = React.useState({
    stringLength: '7',
    charset: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    sampleOutput: 'JlU1ZZcr'
  });
  const [formattedDate, setFormattedDate] = React.useState({
    offSet: 0,
    format: 'YYYY-MM-DD',
    sampleOutput: '2024-10-30'
  });
  const [dateTime, setDateTime] = React.useState({ value: '', sampleOutput: '' });
  const [timeInMilliseconds, setTimeInMilliseconds] = React.useState({
    value: '',
    sampleOutput: '1723330021122'
  });

  const [randomEmail, setRandomEmail] = React.useState({
    stringLength: '5',
    charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    time: '',
    sampleOutput: `79685948.${email_identifier}@${
      isPlayground ? 'playmail.getscandium.com' : 'testmail.getscandium.com'
    }`
  });

  const ALPHA_REGEX = /^[a-zA-Z-]+$/;
  const ALPHANUMERIC_REGEX = /^[a-zA-z0-9-]+$/;

  // This function generate number of the given length
  const randomNumberGenerator = (length) => {
    if (length <= 0) {
      return;
    }

    const _random = generateRandomNumber(length);
    setRandomNum((prev) => ({ ...prev, sampleOutput: _random }));
  };

  // This function generate number within the given min and max
  const generateRandomNumberInRange = (min, max) => {
    if (min >= max) {
      return;
    }

    const _min = parseInt(min, 10);
    const _max = parseInt(max, 10);
    const randomNumber = generateRandomInRange(_min, _max);
    setRandomNumberInRange((prev) => ({ ...prev, sampleOutput: randomNumber }));
  };

  // This function generate letters of the given length, selecting from the given character set
  const generateRandomAlphaStringSample = (length, charset) => {
    if (!length || !charset.length) return;

    const result = generateRandomAlpha(length, charset);
    setRandomAlphaString((prev) => ({ ...prev, sampleOutput: result }));
  };

  // This function generate letters of the given length, selecting from the given character set
  const generateRandomEmailStringSample = (offsetMilliseconds) => {
    if (offsetMilliseconds === '') return;

    const targetDatetime = generateTimeOffset(offsetMilliseconds);

    const emailSample = `${targetDatetime}.${email_identifier}@${
      isPlayground ? 'playmail.getscandium.com' : 'testmail.getscandium.com'
    }`;

    setRandomEmail((prev) => ({ ...prev, sampleOutput: emailSample }));
  };

  // This function generate alphanumeric characters of the given length, selecting from the given character set
  const generateRandomAlphanumericStringSample = (length, charset) => {
    if (!length || !charset.length) return;

    const result = generateRandomAlphanumeric(length, charset);

    setRandomAlphaNumericString((prev) => ({ ...prev, sampleOutput: result }));
  };

  // This function generate date in given format from the current date offset in days by given number
  function generateFormattedDate(offsetDays, dateFormat) {
    if (offsetDays === '') return;

    const formattedDate = generateDateOffset(offsetDays, dateFormat);

    setFormattedDate((prev) => ({ ...prev, sampleOutput: formattedDate }));
  }

  //This function generate datetime from the current datetime that is offset by milliseconds
  const generateOffsetDatetime = (offsetMilliseconds) => {
    if (offsetMilliseconds === '') return;
    const targetDatetime = generateDatetimeOffset(offsetMilliseconds);

    setDateTime((prev) => ({ ...prev, sampleOutput: targetDatetime }));
  };

  //This function generate time in milliseconds from the current datetime that is offset by milliseconds
  const generateTimeInMilliseconds = (offsetMilliseconds) => {
    if (offsetMilliseconds === '') return;
    const targetDatetime = generateTimeOffset(offsetMilliseconds);

    setTimeInMilliseconds((prev) => ({ ...prev, sampleOutput: targetDatetime }));
  };

  // These function will update the variables based on object properties
  const updater = (_variableName) => {
    if (cursorIndex !== null) {
      const newInputValue =
        inputValue?.slice(0, cursorIndex) + _variableName + inputValue?.slice(cursorIndex);
      handleInputFieldUpdate(index, 'value', newInputValue);
    } else {
      const newInputValue = inputValue + _variableName;
      handleInputFieldUpdate(index, 'value', newInputValue);
    }
  };

  // handle insert {{num()}} variables
  const handleInsert = () => {
    if (randomNum.value <= 0) {
      toast.error('Please enter a positive integer');
      return;
    }

    const _variable = `{{num(${randomNum.value})}}`;

    updater(_variable);
    setRandomNum((prev) => ({ ...prev, value: '' }));
    onCompleteInsertion();
  };

  // handle insert {{range()}} variables
  const handleInsertRangeVariable = () => {
    if (+randomNumberInRange.min >= +randomNumberInRange.max) {
      toast.error('Invalid range: min must be less than max');
      return;
    }

    if (randomNumberInRange.min === '' || randomNumberInRange.max === '') {
      toast.error('Min and max are required');
      return;
    }

    const _variable = `{{range(${randomNumberInRange.min}, ${randomNumberInRange.max})}}`;

    updater(_variable);
    setRandomNumberInRange((prev) => ({ ...prev, max: '', min: '' }));
    onCompleteInsertion();
  };

  // handle insert {{alpha(5, ‘a-zA-z’)}} variables
  const handleInsertAlphaVariable = () => {
    if (!randomAlphaString.stringLength || !randomAlphaString.charset?.length) {
      toast.error('Please provide a length and a random alpha string');
      return;
    }

    const _variable = `{{alpha(${randomAlphaString.stringLength}, ${randomAlphaString.charset})}}`;

    updater(_variable);
    setRandomAlphaString((prev) => ({ ...prev, stringLength: '' }));
    onCompleteInsertion();
  };

  // handle insert random email address
  const handleInsertRandomEmail = () => {
    if (!randomEmail.stringLength || !randomEmail.charset?.length) {
      toast.error('Please provide a length and a random alpha string');
      return;
    }

    const _variable = `{{time(${randomEmail.time})}}.${email_identifier}@${
      isPlayground ? 'playmail.getscandium.com' : 'testmail.getscandium.com'
    }`;

    updater(_variable);
    setRandomEmail((prev) => ({ ...prev, stringLength: 5 }));
    onCompleteInsertion();
  };

  // handle insert {{alphanum(10, ‘a-zA-z0-9’)}}  variables
  const handleInsertAlphaNumericVariable = () => {
    if (!randomAlphaNumericString.stringLength || !randomAlphaNumericString.charset?.length) {
      toast.error('Please provide a length and a random alphaNumeric string');
      return;
    }

    const _variable = `{{alphanum(${randomAlphaNumericString.stringLength}, ${randomAlphaNumericString.charset})}}`;

    updater(_variable);
    setRandomAlphaNumericString((prev) => ({ ...prev, stringLength: '' }));
    onCompleteInsertion();
  };

  // handle insert {{date(10, ‘DD/MM/YYYY’)}}  variables
  const handleInsertDateVariable = () => {
    if (formattedDate.offSet === '') {
      toast.error('Please provide a date format and offset value');
      return;
    }

    const _variable = `{{date(${formattedDate.offSet}, ${formattedDate.format})}}`;

    updater(_variable);
    setFormattedDate((prev) => ({ ...prev, offSet: 0 }));
    onCompleteInsertion();
  };

  // handle insert {{datetime(10)}}  variables
  const handleInsertDatetimeVariable = () => {
    if (dateTime.value === '') {
      toast.error('Please provide an offset in milliseconds');
      return;
    }

    const _variable = `{{datetime(${dateTime.value})}}`;

    updater(_variable);
    setDateTime((prev) => ({ ...prev, value: '' }));
    onCompleteInsertion();
  };

  // handle insert {{datetime(10)}}  variables
  const handleInsertTimeInMillisecondsVariable = () => {
    if (timeInMilliseconds.value === '') {
      toast.error('Please provide an offset in milliseconds');
      return;
    }

    const _variable = `{{time(${timeInMilliseconds.value})}}`;

    updater(_variable);
    setTimeInMilliseconds((prev) => ({ ...prev, value: '' }));
    onCompleteInsertion();
  };

  const options = [
    { badge: 'Email', label: 'Generate random email address' },
    { badge: 'Num', label: 'Generate number of the given length' },
    { badge: 'Range', label: 'Generate number within the given min and max' },
    {
      badge: 'Alpha',
      label: 'Generate letters of the given length, selecting from the given character set'
    },
    {
      badge: 'Alphanumeric',
      label:
        'Generate alphanumeric characters of the given length, selecting from the given character set'
    },
    {
      badge: 'Date',
      label: 'Generate date in given format from the current date offset in days by given number'
    },
    {
      badge: 'Datetime',
      label: 'Generate datetime from the current datetime that is offset by milliseconds'
    },
    { badge: 'Time', label: 'Generate time that is offset from current time in milliseconds' }
  ];

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'800px'} maxHeight={'80vh'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '14px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color="primary"
          // mb={3}
          sx={{
            fontSize: '1.2rem'
          }}>
          Insert Function
        </Typography>

        <Typography
          as={'h4'}
          mb={1.5}
          sx={{
            fontSize: '0.9rem'
          }}>
          Select one from the option below
        </Typography>

        <Flex alignItems={'normal'} columnGap={4}>
          <Flex
            sx={{
              flex: 1,
              flexDirection: 'column'
            }}>
            <CustomScrollbar sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
              {options.map((option, index) => (
                <Flex
                  key={index + option}
                  onClick={() => setSelectedTab(index)}
                  mb={1.5}
                  sx={{
                    backgroundColor:
                      selectedTab === index
                        ? 'rgba(36, 195, 217, 0.33)'
                        : theme.palette.background.reportCard,
                    cursor: 'pointer',
                    py: 1,
                    px: 2.5,
                    borderRadius: '6px',
                    width: '100%',
                    alignItems: 'normal',
                    '&:hover': {
                      backgroundColor:
                        selectedTab === index
                          ? 'rgba(36, 195, 217, 0.39)'
                          : theme.palette.background.lightGrey_dark
                    }
                  }}>
                  <Typography fontSize={'0.9rem'}>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        background: mode === 'light' ? '#1958B8' : 'rgba(200, 200, 200, 0.3)',
                        color: 'white',
                        borderRadius: '5px',
                        mb: '2px',
                        px: '10px',
                        py: '3px',
                        width: 'max-content'
                      }}>
                      {option.badge}
                    </Typography>
                    {option.label}
                  </Typography>
                </Flex>
              ))}
            </CustomScrollbar>
          </Flex>
          <Flex
            sx={{
              flex: 1,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column'
            }}>
            {selectedTab === 0 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={randomEmail.time}
                  type={'number'}
                  label={'Offset in milliseconds'}
                  placeholder={'6'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setRandomEmail((prev) => ({
                      ...prev,
                      time: e.target.value
                    }));
                    generateRandomEmailStringSample(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Box>
                      <Typography
                        fontSize={'0.8rem'}
                        sx={{
                          overflowWrap: 'break-word',
                          wordBreak: 'break-all'
                        }}
                        display={'flex'}
                        flexWrap={'wrap'}>
                        {randomEmail.sampleOutput}
                      </Typography>
                    </Box>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertRandomEmail()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 1 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={randomNum.value}
                  type={'number'}
                  label={'Number of digits'}
                  placeholder={'6'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setRandomNum((prev) => ({ ...prev, value: e.target.value }));
                    randomNumberGenerator(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography
                      fontSize={'0.95'}
                      sx={{
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all'
                      }}>
                      {randomNum.sampleOutput?.toString()}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton fullWidth onClick={() => handleInsert()} sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 2 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={randomNumberInRange.min}
                    type={'number'}
                    label={'Min Number'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomNumberInRange((prev) => ({ ...prev, min: e.target.value }));
                      generateRandomNumberInRange(+e.target.value, randomNumberInRange.max);
                    }}
                  />
                  <TextField
                    size={'small'}
                    value={randomNumberInRange.max}
                    type={'number'}
                    label={'Max Number'}
                    placeholder={'10'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomNumberInRange((prev) => ({ ...prev, max: e.target.value }));
                      generateRandomNumberInRange(randomNumberInRange.min, +e.target.value);
                    }}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography fontSize={'0.95'}>
                      {randomNumberInRange.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertRangeVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 3 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={randomAlphaString.stringLength}
                    type={'number'}
                    label={'Length'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomAlphaString((prev) => ({ ...prev, stringLength: e.target.value }));
                      generateRandomAlphaStringSample(+e.target.value, randomAlphaString.charset);
                    }}
                  />
                  <TextField
                    size={'small'}
                    value={randomAlphaString.charset}
                    type={'text'}
                    label={'Character set'}
                    placeholder={'abc...z'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== '' && !ALPHA_REGEX.test(value)) {
                        return;
                      }
                      setRandomAlphaString((prev) => ({ ...prev, charset: e.target.value }));
                      generateRandomAlphaStringSample(
                        randomAlphaString.stringLength,
                        e.target.value
                      );
                    }}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography fontSize={'0.95'}>
                      {randomAlphaString.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertAlphaVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 4 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={randomAlphaNumericString.stringLength}
                    type={'number'}
                    label={'Length'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setRandomAlphaNumericString((prev) => ({
                        ...prev,
                        stringLength: e.target.value
                      }));
                      generateRandomAlphanumericStringSample(
                        +e.target.value,
                        randomAlphaNumericString.charset
                      );
                    }}
                  />
                  <TextField
                    size={'small'}
                    value={randomAlphaNumericString.charset}
                    type={'text'}
                    label={'Character set'}
                    placeholder={'0123abczABCZ'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value !== '' && !ALPHANUMERIC_REGEX.test(value)) {
                        return;
                      }
                      setRandomAlphaNumericString((prev) => ({ ...prev, charset: e.target.value }));
                      generateRandomAlphanumericStringSample(
                        randomAlphaNumericString.stringLength,
                        e.target.value
                      );
                    }}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography fontSize={'0.95'}>
                      {randomAlphaNumericString.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertAlphaNumericVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 5 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <Flex columnGap={3}>
                  <TextField
                    size={'small'}
                    value={formattedDate.offSet}
                    type={'number'}
                    label={'Offset in days'}
                    placeholder={'5'}
                    fullWidth
                    sx={{
                      height: 1
                    }}
                    onChange={(e) => {
                      setFormattedDate((prev) => ({
                        ...prev,
                        offSet: e.target.value
                      }));
                      generateFormattedDate(+e.target.value, formattedDate.format);
                    }}
                  />
                  <DateFormatSelect
                    formattedDate={formattedDate}
                    setDateFormat={setFormattedDate}
                    generateFormattedDate={generateFormattedDate}
                  />
                </Flex>

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography fontSize={'0.95'}>
                      {formattedDate.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertDateVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 6 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={dateTime.value}
                  type={'number'}
                  label={'Offset in milliseconds'}
                  placeholder={'120000'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setDateTime((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                    generateOffsetDatetime(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography fontSize={'0.8rem'}>
                      {dateTime.sampleOutput?.toString()}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertDatetimeVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}

            {selectedTab === 7 && (
              <Flex
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  height: '100%'
                }}>
                <TextField
                  size={'small'}
                  value={timeInMilliseconds.value}
                  type={'number'}
                  label={'Offset in milliseconds'}
                  placeholder={'6'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) => {
                    setTimeInMilliseconds((prev) => ({
                      ...prev,
                      value: e.target.value
                    }));
                    generateTimeInMilliseconds(+e.target.value);
                  }}
                />

                <Box mb={1.5} width={'100%'}>
                  <Box
                    sx={{
                      background: theme.palette.background.reportCard,
                      mb: 6,
                      borderRadius: '6px',
                      px: 2,
                      pt: 2,
                      pb: 3
                    }}>
                    <Typography fontSize={'0.9rem'}>Sample output</Typography>
                    <Typography fontSize={'0.95'}>
                      {timeInMilliseconds.sampleOutput}
                    </Typography>
                  </Box>
                  <Flex sx={{ width: '100%' }}>
                    <ContainedButton
                      fullWidth
                      onClick={() => handleInsertTimeInMillisecondsVariable()}
                      sx={{ flex: '50%' }}>
                      Insert
                    </ContainedButton>
                    <OutlinedButton fullWidth onClick={() => onClose()} sx={{ flex: '50%' }}>
                      Cancel
                    </OutlinedButton>
                  </Flex>
                </Box>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default MobileFunctionVariables;
