import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useScandiumQuery } from '../../../data-layer/utils';

const SelectDropdown = ({
  value,
  options,
  onChange,
  isLoading = false,
  label,
  labelId,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth size={'small'}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={'Trello Board'}
        value={value}
        label={label}
        onChange={onChange}
        IconComponent={(defaultProps) =>
          isLoading ? (
            <CircularProgress size={16} color={'secondary'} {...defaultProps} />
          ) : (
            <ArrowDropDownIcon {...defaultProps} />
          )
        }
        renderValue={(selectedValue) => {
          return selectedValue ? selectedValue.name : '';
        }}
        MenuProps={{
          elevation: 1
        }}
        {...otherProps}>
        {options?.map((board) => (
          <MenuItem key={options.id} value={board}>
            {board.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const TrelloConnectModal = ({
  open,
  onClose,
  value,
  handleUpdate,
  onComplete,
  updateChannelData,
  setSuccessChannels,
  successChannels,
  errorChannels,
  setErrorChannels,
  activeTab,
  isTrelloTokenSuccess,
  showTrelloSettings,
  handlePostChannels
}) => {
  const activeChannel = activeTab === 0 ? successChannels : errorChannels;
  const trelloChannel = activeChannel.find((channel) => channel.channel === 'trello');
  const activeBoard = trelloChannel.channel_details.data

  const [boards, setBoards] = useState([]);
  const [boardLists, setBoardLists] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [selectedBoardList, setSelectedBoardList] = useState(null);

  const handleTrelloBoardChange = (event) => {
    setSelectedBoard(event.target.value);
  };

  const handleBoardListChange = (event) => {
    setSelectedBoardList(event.target.value);
  };

  useEffect(() => {
    const matchingList = boardLists?.find(list => list.id === activeBoard?.list_id)
    const matchingBoard = boards?.find(board => board.id === activeBoard?.board_id)
    setSelectedBoardList(matchingList || boardLists?.[0])
    setSelectedBoard(matchingBoard || boards?.[0])
  }, [activeChannel, trelloChannel]);


  const handleSaveTrelloData = async () => {
    if (!selectedBoard || !selectedBoardList) {
      toast.error('All fields are required');
      return;
    }

    const updater = activeTab === 0 ? setSuccessChannels : setErrorChannels;

    updater((prevChannels) =>
      prevChannels.map((channel) => {
        if (channel.channel === 'trello') {
          return {
            ...channel,
            status: 'active',
            channel_details: {
              data: {
                list_id: selectedBoardList?.id,
                board_id: selectedBoard?.id
              }
            }
          };
        } else {
          return channel;
        }
      })
    );

    onClose();
  };

  const { isLoading: isFetchingTrelloBoard } = useScandiumQuery('/third-parties/trello/boards', {
    enabled: !!showTrelloSettings || isTrelloTokenSuccess,
    onSuccess: (data) => {
      setBoards(data.data);
      const matchingBoard = data.data.find(
        (workspace) => workspace.id === activeBoard?.board_id
      );
      setSelectedBoard(matchingBoard || data?.data?.[0]);
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const { isLoading: isFetchingBoardLists } = useScandiumQuery(
    `/third-parties/trello/boards/${selectedBoard?.id}/lists`,
    {
      enabled: !!selectedBoard?.id,
      onSuccess: (data) => {
        setBoardLists(data.data);
        const matchingBoardList = data.data.find(
          (list) => list.id === activeBoard?.list_id
        );
        setSelectedBoardList(matchingBoardList || data.data?.[0]);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Connect Your Trello Account
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          The modal allows you to choose a Trello board and list. When a test fails or passes, a
          card will be created in the selected board and list to track the outcome.
        </Typography>

        <Box>
          <SelectDropdown
            options={boards}
            isLoading={isFetchingTrelloBoard}
            onChange={handleTrelloBoardChange}
            value={selectedBoard}
            label={'Select a Board'}
            labelId={'Board label'}
            sx={{ mb: 2 }}
          />
          <SelectDropdown
            options={boardLists}
            isLoading={isFetchingBoardLists}
            onChange={handleBoardListChange}
            value={selectedBoardList}
            label={'Select a List'}
            labelId={'List label'}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton onClick={handleSaveTrelloData} color={'primary'} autoFocus>
            connect
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default TrelloConnectModal;
