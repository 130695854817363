import React from 'react';
import { useScandiumQuery } from '../data-layer/utils';

export const FeatureFlagsContext = React.createContext({
  featureFlags: [],
  featureFlagsError: null,
  isFeatureFlagsLoading: false
});

export const FeatureFlagsContextProvider = ({ children }) => {
  const {
    data: featureFlags = [],
    error: featureFlagsError,
    isLoading: isFeatureFlagsLoading
  } = useScandiumQuery('/feature-flags', {
    select: (data) => data.data
  });

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        featureFlagsError,
        isFeatureFlagsLoading
      }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContext;
