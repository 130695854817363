/* global chrome */
import { Box, Tooltip, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import Flex from '../../../components/base/Flex';
import { StyledTableCell, StyledTableRow } from './StyledTableCell';
import TestSuiteSvg from '../../../components/svg/TestSuiteSvg';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import ActionMenu from './ActionMenu';
import useMeta from '../../../hooks/useMeta';
import { getExtensionId } from '../../TestPage';
import InstallExtensionCta from '../../TestPage/components/InstallExtensionCta';
import useAwaitModal from '../../../hooks/useAwaitModal';
import RemoteRunsModal from './RemoteRunsModal';
import ScheduleModal from '../../../components/ScheduleModal';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { formatDate } from '../../../utils/formateDate';
import { useGlobalVariables } from '../../../store/globalVariables';
import { useTheme } from '@emotion/react';

const SuiteRow = ({ suite, refetchSuites, suiteId }) => {
  const theme = useTheme();
  const activeProject = useActiveProject();
  const navigate = useNavigate();
  const { requestConfirm: confirmDelete, ConfirmationDialog: DeleteSuiteDialog } =
    useConfirmDialog();
  const { requestConfirm: confirmLocalRun, ConfirmationDialog: LocalRunDialog } =
    useConfirmDialog();
  const { requestConfirm: confirmRemoteRun, ConfirmationDialog: RemoteRunDialog } =
    useConfirmDialog();
  const menuRef = useRef(null);
  const { meta } = useMeta();
  const extRef = useRef(getExtensionId());
  const [openModal, setOpenModal] = useState(false);
  const { globalVariables } = useGlobalVariables();

  const [
    requestRemoteRunModal,
    { open: openRemoteRunModal, onClose: onCloseRemoteRunModal, onComplete: completeRemoteRunModal }
  ] = useAwaitModal();

  const [
    requestScheduleModal,
    { open: openScheduleModal, onClose: onCloseScheduleModal, onComplete: completeScheduleModal }
  ] = useAwaitModal();

  const { mutateAsync: deleteSuite, isLoading: isDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/suites/${suite.id}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchSuites();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const localRun = () => {
    if (!window.chrome) {
      toast.error(
        'The Scandium extension only works on Chrome for now. Other browsers are coming soon'
      );
      return;
    }

    // In case you somehow got here without having the extension.
    extRef.current = getExtensionId();
    if (!extRef.current) {
      setOpenModal(true);
      toast.error(
        'The Scandium extension is yet to be installed on your browser. Please install the extension and try again'
      );
      return;
    }

    chrome.runtime.sendMessage(
      extRef.current,
      {
        cmd: 'playSuite',
        suite: suite,
        meta: meta,
        global_variables: globalVariables || []
      },
      (res) => {
        toast.success(
          'Your test suite runs have been successfully completed. Please check the Suite Runs result page for details'
        );
      }
    );

    toast.success(
      'Your test suite is currently being run Locally. Do not close your browser until the test suite has completed running'
    );

    navigate(`/projects/${activeProject?.id}/suites-results`);
  };

  const remoteRun = () => {
    toast.success('Your test suite is currently being run remotely!!');
  };

  const handleLocalRun = async () => {
    if (await confirmLocalRun()) return localRun();
  };

  const handleRemoteRun = async () => {
    if (await confirmRemoteRun()) return await remoteRun();
  };

  const handleDeleteSuite = async () => {
    if (await confirmDelete()) return await deleteSuite();
  };

  return (
    <StyledTableRow onClick={(e) => e.currentTarget.contains(e.target) && navigate(`${suite.id}`)}>
      <StyledTableCell component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <TestSuiteSvg fill={theme.palette.svg.main} />
          <Flex alignItems={'normal'} flexDirection={'column'}>
            <Typography
              fontWeight={500}
              color={theme.palette.text.black_white}
              sx={{ lineHeight: 1, mt: '4px' }}>
              {suite.name}
            </Typography>
            <Typography
              sx={{
                lineHeight: 1,
                mt: '4px',
                display: 'inline-block',
                fontSize: '0.85rem',
                maxWidth: '300px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              {suite?.description || ''}
            </Typography>
          </Flex>
          <Typography fontWeight={300} fontSize={'0.8rem'} sx={{ mt: '4px' }}>
            {`(${suite.test_ids?.length} Test Cases)`}
          </Typography>
          {suite?.schedules_count > 0 ? (
            <Tooltip title={'Schedule set'}>
              <ScheduleIcon fontSize="small" sx={{ color: '#24C3D9' }} />
            </Tooltip>
          ) : null}
        </Flex>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{suite?.creator?.name}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{formatDate(suite.created_at)}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          {!suiteId && (
            <ActionMenu
              suiteId={suite.id}
              isLoading={isDeleting}
              onDelete={handleDeleteSuite}
              onLocal={handleLocalRun}
              onRemote={() => requestRemoteRunModal()}
              onSchedule={() => requestScheduleModal()}
              ref={menuRef}
            />
          )}
        </Box>
      </StyledTableCell>
      <DeleteSuiteDialog
        title={'Are you sure you want to delete this test suite?'}
        description={
          'When you delete a test suite, all associated test cases and data will be permanently removed'
        }
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <LocalRunDialog
        title={'Run 1 Suite Locally'}
        description={`You are about to run a suite containing ${suite.test_ids?.length} test cases in your browser. Keep your browser open while the suite is running.`}
        confirmLabel={'Run'}
      />
      <RemoteRunDialog
        title={'Run 1 Suite Remotely'}
        description={`You are about to run a suite containing ${suite.test_ids?.length} test cases remotely.`}
        confirmLabel={'Run'}
      />
      <InstallExtensionCta openModal={openModal} setOpenModal={setOpenModal} />
      <RemoteRunsModal
        open={openRemoteRunModal}
        onClose={onCloseRemoteRunModal}
        onComplete={completeRemoteRunModal}
        suite={suite}
      />
      <ScheduleModal
        open={openScheduleModal}
        onClose={onCloseScheduleModal}
        onSuiteCreated={completeScheduleModal}
        suiteId={suite.id}
        scheduleType={'test_suite'}
        refetchSuites={refetchSuites}
      />
    </StyledTableRow>
  );
};

export default SuiteRow;
