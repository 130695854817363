import { useContext } from "react";
import { useCookies } from "react-cookie";
import { useActiveProject } from "../store/projectState";
import UserContext from '../store/userState';


const useMeta = (source = 'local') => {
  const [cookies] = useCookies(['token', 'email', 'name']);
  const { userData } = useContext(UserContext);
  const activeProject = useActiveProject();

  const meta = {
    project: !!activeProject && activeProject,
    user: !!userData && userData.user,
    bearer_token: cookies?.token,
    source: source
  }

  return { meta }
}

export default useMeta