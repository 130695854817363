import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { ScreenSize, predefinedScreenSizes } from '../../TestPage/components/TestProperties';
import Dropdown from '../../../components/base/Dropdown';
import { useTheme } from '@emotion/react';

const NewExecutionEnvironmentModal = ({
  open,
  onClose,
  onComplete,
  refetchEnvirons,
  setEnvironmentIds
}) => {
  const activeProject = useActiveProject();
  const [name, setName] = useState('');
  const [browser, setBrowser] = useState('');
  const [operating_system, setOperatingSystem] = useState('');

  const [screenSize, setScreenSize] = useState(predefinedScreenSizes[0].value);
  const [customScreenSize, setCustomScreenSize] = useState(['', '']);
  const [screenSizeIndex, setScreenSizeIndex] = useState(0);
  const theme = useTheme();

  const browsersOptions = [
    { label: 'Chrome', value: 'chrome' },
    { label: 'Firefox', value: 'firefox' },
    { label: 'Edge', value: 'edge' }
  ];

  const oSoptions = [
    { label: 'MacOs', value: 'macos' },
    { label: 'Windows', value: 'windows' },
    { label: 'Linux', value: 'linux' }
  ];

  const { mutateAsync: postExeEnvirons, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/environments`,
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success(data.message);
        refetchEnvirons().then(() => {
            setEnvironmentIds((prev) => [...prev, data.data])
        })
        onComplete();
        setName('');
        setOperatingSystem('');
        setBrowser('');
      }
    }
  );

  const handlePostExeEnvirons = async (e) => {
    e.preventDefault();

    if (!name) {
      toast.error('Please enter a name');
      return;
    }

    if (!browser) {
      toast.error('Please select a browser type');
      return;
    }

    if (!operating_system) {
      toast.error('Please select a operating system type');
      return;
    }

    return await postExeEnvirons({
      name,
      width: screenSize[0],
      height: screenSize[1],
      browser,
      operating_system
    });
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'550px'}>
        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          sx={{
            fontSize: '1.4rem',
            mb: 2
          }}>
          Add New Execution Environment
        </Typography>

        <Box mb={3}>
          <TextField
            size={'small'}
            value={name}
            label={'Name'}
            required
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />

          <Dropdown
            value={browser}
            onChange={setBrowser}
            label={'Browser'}
            options={browsersOptions}
          />

          <Dropdown
            value={operating_system}
            onChange={setOperatingSystem}
            label={'Operating system'}
            options={oSoptions}
          />

          <Box my={2}>
            <ScreenSize
              onChange={setScreenSize}
              customScreenSize={customScreenSize}
              setCustomScreenSize={setCustomScreenSize}
              screenSizeIndex={screenSizeIndex}
              setScreenSizeIndex={setScreenSizeIndex}
              test
            />
          </Box>
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Close</OutlinedButton>
          <ContainedButton
            isLoading={isLoading}
            color={'primary'}
            onClick={handlePostExeEnvirons}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default NewExecutionEnvironmentModal;
