import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { useParams } from 'react-router-dom';

export const BulkStepActionsTrigger = ({ selectedSteps, onDeleteSteps, onDuplicateSteps }) => {
  const { triggerProps, menuProps } = useMenu();
  const { testId } = useParams();

  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const { requestConfirm: confirmDuplicate, ConfirmationDialog: DuplicationDialog } =
    useConfirmDialog();

  return (
    <Box mr={6}>
      <Tooltip title={'More actions'}>
        <IconButton {...triggerProps}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu id="action-menu" {...menuProps} elevation={1}>
        <MenuItem
          onClick={async () => {
            const confirm = await requestConfirm();
            if (confirm) {
              onDeleteSteps();
            }
          }}>
          <Typography>Delete selected steps</Typography>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            const confirm = await confirmDuplicate();
            if (confirm) {
              onDuplicateSteps();
            }
          }}>
          <Typography>Duplicate selected steps</Typography>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title={'Are you sure you want to delete the selected steps?'}
        description={'This action cannot be reversed and you may have to record them again.'}
        confirmLabel={'Delete'}
        cancelLabel={"Don't delete"}
        confirmColor={'error'}
      />
      <DuplicationDialog
        title={'Are you sure you want to duplicate the selected steps?'}
        description={
          'This action will create an exact copy of the steps selected. Any changes made to the duplicated steps will not affect the original ones.'
        }
        confirmLabel={'Duplicate'}
        cancelLabel={"Don't duplicate"}
        confirmColor={'primary'}
      />
    </Box>
  );
};
