
export const generateTimeOffset = (offset = 0) => {
  const now = Date.now();
  return now + offset;
};

export const generateRandomNumber = (length) => {
  return Array.from({ length }, () => Math.floor(Math.random() * 10)).join('');
};

export const generateRandomInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateRandomAlpha = (length, charset) => {
  return generateRandomString(
    length,
    charset || 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  );
};

export const generateRandomAlphanumeric = (length, charset) => {
  return generateRandomString(
    length,
    charset || '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  );
};

const formatDate = (date, format) => {
  const padZero = (number) => (number < 10 ? '0' : '') + number;

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  return format
    .replace('YYYY', year)
    .replace('MM', month)
    .replace('DD', day)
    .replace('HH', hours)
    .replace('mm', minutes)
    .replace('ss', seconds);
};

export const generateDateOffset = (offset = 0, format = 'YYYY-MM-DD') => {
  const date = new Date();
  date.setDate(date.getDate() + +offset);
  return formatDate(date, format);
};

export const generateDatetimeOffset = (offset) => {
  const now = new Date();
  now.setMilliseconds(now.getMilliseconds() + offset);
  return now.toISOString().replace('T', ' ');
};

export const generateRandomString = (length, characters) => {
  let result = '';
  const charsetLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    result += characters.charAt(randomIndex);
  }

  return result;
};
