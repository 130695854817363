import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomInput from '../../../components/customInput';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

const CreateStepVariable = ({ open, onClose, value, handleUpdate, onCompleteCreation, stepVariables }) => {
  const [variableName, setVariableName] = React.useState('');
  const theme = useTheme();

  //check if variable name is already in used
  const checkVariableName = stepVariables?.some((variable) => variable.name === variableName);

  const handleStepVariableCreations = () => {
    if (checkVariableName) {
      toast.error(`${variableName} is already in used. Please choose a different name`);
      return;
    }

    handleUpdate({
      variable_assignment:  { name: variableName, usedBy: [] }
    });
    onCompleteCreation();
    setVariableName('');
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          sx={{
            fontSize: '1.4rem'
          }}>
          Assign to Variable
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Extract value from element and assign to a variable
        </Typography>

        <Box mb={3}>
          <CustomInput
            onChange={(e) => {
              setVariableName(e.target.value);
            }}
            value={variableName}
            size={'small'}
            label={'Enter name'}
            placeholder={'Name can ONLY be a single word'}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!variableName}
            onClick={handleStepVariableCreations}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateStepVariable;
