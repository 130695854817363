import React from 'react';

const BrowserSvg = ({ fill, width = 12, height = 11 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.80058 2.50953C2.99877 2.50953 3.15943 2.34886 3.15943 2.15068C3.15943 1.95249 2.99877 1.79183 2.80058 1.79183C2.6024 1.79183 2.44174 1.95249 2.44174 2.15068C2.44174 2.34886 2.6024 2.50953 2.80058 2.50953Z"
        fill="#1958B8"
      />
      <path
        d="M3.99671 2.50953C4.19489 2.50953 4.35556 2.34886 4.35556 2.15068C4.35556 1.95249 4.19489 1.79183 3.99671 1.79183C3.79852 1.79183 3.63786 1.95249 3.63786 2.15068C3.63786 2.34886 3.79852 2.50953 3.99671 2.50953Z"
        fill="#1958B8"
      />
      <path
        d="M5.19288 2.50953C5.39107 2.50953 5.55173 2.34886 5.55173 2.15068C5.55173 1.95249 5.39107 1.79183 5.19288 1.79183C4.99469 1.79183 4.83403 1.95249 4.83403 2.15068C4.83403 2.34886 4.99469 2.50953 5.19288 2.50953Z"
        fill="#1958B8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1737 5.97838V1.91145C11.1737 1.05261 10.4775 0.356445 9.61866 0.356445C7.7804 0.356445 4.27997 0.356445 2.44172 0.356445C1.58288 0.356445 0.886719 1.05261 0.886719 1.91145V9.08838C0.886719 9.94722 1.58288 10.6434 2.44172 10.6434H6.03019C6.22827 10.6434 6.38904 10.4826 6.38904 10.2845C6.38904 10.0865 6.22827 9.92569 6.03019 9.92569H2.44172C1.97905 9.92569 1.60441 9.55106 1.60441 9.08838V1.91145C1.60441 1.44877 1.97905 1.07414 2.44172 1.07414H9.61866C10.0813 1.07414 10.456 1.44877 10.456 1.91145V5.97838C10.456 6.17646 10.6167 6.33722 10.8148 6.33722C11.0129 6.33722 11.1737 6.17646 11.1737 5.97838Z"
        fill="#1958B8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8148 3.22722H1.24557C1.04748 3.22722 0.886719 3.38798 0.886719 3.58607C0.886719 3.78415 1.04748 3.94491 1.24557 3.94491H10.8148C11.0129 3.94491 11.1737 3.78415 11.1737 3.58607C11.1737 3.38798 11.0129 3.22722 10.8148 3.22722Z"
        fill="#1958B8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.555 10.0788L9.54977 8.64341C9.43637 8.48121 9.21245 8.4415 9.05025 8.55537C8.88805 8.66877 8.84834 8.89269 8.96221 9.05488L9.96698 10.4903C10.0809 10.6525 10.3048 10.6922 10.467 10.5783C10.6292 10.4649 10.6689 10.241 10.555 10.0788Z"
        fill="#1958B8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8469 7.45396C10.0297 6.98219 8.54978 6.12862 7.75123 5.66786C7.53113 5.54107 7.25793 5.55303 7.0498 5.69848C6.84215 5.84393 6.73737 6.09704 6.78138 6.3468C6.94119 7.25492 7.23736 8.93767 7.40099 9.86684C7.44549 10.1219 7.63735 10.3266 7.88902 10.3879C8.1407 10.4496 8.40481 10.3568 8.5627 10.1511L9.40192 9.05729L10.7167 8.64294C10.9641 8.56495 11.1416 8.34821 11.1698 8.09079C11.1981 7.83338 11.0713 7.58314 10.8469 7.45396ZM10.3612 8.00228L9.078 8.40706C9.00767 8.42906 8.94595 8.47213 8.90145 8.5305L8.08232 9.59843L7.512 6.35828L10.3612 8.00228Z"
        fill="#1958B8"
      />
    </svg>
  );
};

export default BrowserSvg;
