const InspectSourceSvg = ({ width = 28, height = 24, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5227 0.152971L2.52079 0.150783C2.47231 0.102701 2.41484 0.0646383 2.35165 0.0387676C2.28846 0.0128969 2.2208 -0.000274956 2.15252 4.35057e-06C2.08424 0.000283657 2.01669 0.0140087 1.95372 0.0403955C1.89074 0.0667823 1.83358 0.105314 1.7855 0.153791C1.73742 0.202268 1.69935 0.259741 1.67348 0.322928C1.64761 0.386116 1.63444 0.45378 1.63472 0.522057C1.63529 0.65995 1.6906 0.791972 1.78851 0.889078L2.57247 1.67304C1.62174 2.32417 0.803395 3.15003 0.160979 4.10667C0.0560724 4.26156 0 4.44434 0 4.63141C0 4.81848 0.0560724 5.00126 0.160979 5.15615C1.80081 7.58322 4.40043 9.00512 7.00005 8.98735C7.8421 8.9904 8.67855 8.85022 9.47361 8.57281L10.7427 9.84185C10.8404 9.93647 10.9714 9.98896 11.1075 9.98803C11.2435 9.98711 11.3738 9.93284 11.4703 9.8369C11.5668 9.74096 11.6218 9.611 11.6234 9.47494C11.6251 9.33889 11.5734 9.20761 11.4793 9.1093L2.5227 0.152971ZM7.00005 7.57611C6.50752 7.57593 6.02289 7.45231 5.59046 7.21656C5.15802 6.98081 4.79156 6.64044 4.52457 6.22656C4.25758 5.81268 4.09857 5.33849 4.06207 4.84732C4.02558 4.35614 4.11277 3.86366 4.31566 3.41487L5.13462 4.23382C5.06866 4.54467 5.0814 4.86708 5.1717 5.17175C5.26201 5.47642 5.427 5.75369 5.6517 5.97839C5.8764 6.20309 6.15367 6.36808 6.45834 6.45838C6.76301 6.54869 7.08542 6.56143 7.39626 6.49547L8.21523 7.31443C7.83352 7.4875 7.41915 7.57673 7.00005 7.57611Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M13.8391 4.10531C12.1993 1.67687 9.59966 0.254965 7.00005 0.272739C6.15799 0.269689 5.32154 0.409867 4.52649 0.687277L5.78432 1.94511C6.33013 1.69811 6.93826 1.62331 7.52767 1.7307C8.11707 1.83808 8.65974 2.12254 9.08337 2.54617C9.507 2.9698 9.79146 3.51248 9.89885 4.10188C10.0062 4.69128 9.93144 5.29941 9.68443 5.84522L11.4274 7.58814C12.3782 6.93711 13.1967 6.11124 13.8391 5.15451C13.944 4.99965 14 4.81692 14 4.62991C14 4.44289 13.944 4.26017 13.8391 4.10531Z"
        fill={fill || "#1958B8"}
      />
      <path
        d="M7.00005 2.72306C6.86689 2.72307 6.73409 2.737 6.60383 2.76462L8.86547 5.02599C8.92444 4.74814 8.92061 4.46064 8.85424 4.18446C8.78788 3.90829 8.66066 3.65043 8.48188 3.42972C8.3031 3.20901 8.07727 3.03103 7.8209 2.90877C7.56453 2.78651 7.28408 2.72306 7.00005 2.72306Z"
        fill={fill || "#1958B8"}
      />
    </svg>
  );
};

export default InspectSourceSvg;
