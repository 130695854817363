import React, { useState, useRef } from 'react';

export const FloatingVariableWindowContext = React.createContext({
  focusedInput: null,
  focusedInpuSetterFn: null,
  focusedInputPosition: {},
  setFocusedInputPosition: () => {},
  showVariableFloatingWindow: false,
  setVariableFloatingWindow: () => {},
  openDynamicValues: false,
  setOpenDynamicValues: () => {},
  editorRef: null,
  isEditorFocused: false,
  setIsEditorFocused: () => {},
  variableFloatingWindowRef: null,
  cursorPosition: {},
  setCursorPosition: () => {}
});

export const FloatingVariableComponentProvider = ({ children }) => {
  const focusedInput = useRef(null);
  const editorRef = useRef(null);
  const focusedInpuSetterFn = useRef(null);
  const [focusedInputPosition, setFocusedInputPosition] = useState({ top: 200, left: 1000 });
  const [cursorPosition, setCursorPosition] = useState({ top: 200, left: 1000 });
  const [editorCursorPosition, setEditorCursorPosition] = useState({ top: 200, left: 1000 });
  const [showVariableFloatingWindow, setVariableFloatingWindow] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const variableFloatingWindowRef = useRef(showVariableFloatingWindow);
  const [jsonResponseFields, setJSONResponseFields] = useState({
    keys: [],
    fields: []
  });
  const [variablesFilter, setVariablesFilter] = useState('');
  const [saveHighLightedTextState, setSaveHighLightedTextState] = useState({
    highLightedValue: '',
    showModal: false,
    useCase: 'text',
    fieldEntry: {
      name: '',
      path: ''
    }
  });
  const [openDynamicValues, setOpenDynamicValues] = useState(false);

  return (
    <FloatingVariableWindowContext.Provider
      value={{
        focusedInput,
        focusedInpuSetterFn,
        focusedInputPosition,
        setFocusedInputPosition,
        showVariableFloatingWindow,
        setVariableFloatingWindow,
        jsonResponseFields,
        setJSONResponseFields,
        saveHighLightedTextState,
        setSaveHighLightedTextState,
        editorCursorPosition,
        setEditorCursorPosition,
        variablesFilter,
        setVariablesFilter,
        openDynamicValues,
        setOpenDynamicValues,
        isEditorFocused,
        setIsEditorFocused,
        editorRef,
        variableFloatingWindowRef,
        cursorPosition,
        setCursorPosition
      }}>
      {children}
    </FloatingVariableWindowContext.Provider>
  );
};
