import { useCallback, useContext, useState } from 'react';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { TestCaseContext } from '../../../store/testState';
import { Box, Typography } from '@mui/material/node';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { useQueryClient } from 'react-query';

const MoveToFolderSuggestion = () => {
  const queryClient = useQueryClient();
  const { foldersState, setShowDestinationFolder, setFoldersState } = useContext(TestCaseContext);
  const { destinationFolder, entityToMove, entityType } = foldersState;
  const activeProject = useActiveProject();
  const endPoint =
    entityType === 'folder'
      ? `/projects/${activeProject?.id}/folders/move`
      : `/projects/${activeProject?.id}/api-tests/move`;
  const { mutateAsync: moveTestOrFolder, isLoading: isMovingFolder } = useScandiumMutation(
    endPoint,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: 'get-apiFoldersAndTests' });
        toast.success(data.message);
        setFoldersState((prev) => {
          return { ...prev, destinationFolder: {}, entityToMove: {}, entityType: '' };
        });
        setShowDestinationFolder(false);
      },
      onError: (error) => {
        toast.error(error?.message);
      }
    }
  );
  const handleMoveTestOrFolder = useCallback(() => {
    if (entityType === 'folder') {
      moveTestOrFolder({
        folder_id: entityToMove?.id,
        parent_id: destinationFolder?.id
      });
    } else {
      moveTestOrFolder({
        api_test_id: entityToMove?.id,
        folder_id: destinationFolder?.id
      });
    }
  }, [entityType, entityToMove, destinationFolder]);
  return (
    <Box>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: 1,

          alignItems: 'start'
        }}>
        <Typography fontWeight={500} fontSize={18}>
          Select destination folder
        </Typography>
        <Flex>
          <Typography color={'primary'}>{entityToMove?.name}</Typography>
          <ArrowForwardOutlined />
          <Typography color={'primary'}>{destinationFolder?.name}</Typography>
        </Flex>
        <CustomScrollbar
          sx={{
            width: '100%',
            overflowY: 'auto',
            maxHeight: '400px',
            pr: 1
          }}>
          {' '}
          {!!foldersState.foldersList.length &&
            foldersState.foldersList.map((folder) => {
              return <SingleFolder folder={folder} />;
            })}{' '}
        </CustomScrollbar>
        <Flex marginLeft={'auto'} paddingRight={1}>
          <OutlinedButton
            onClick={() => {
              setShowDestinationFolder(false);
              setFoldersState((prev) => {
                return { ...prev, destinationFolder: {} };
              });
            }}>
            <Typography>Cancel</Typography>
          </OutlinedButton>
          <ContainedButton
            disabled={!destinationFolder?.id}
            isLoading={isMovingFolder}
            onClick={handleMoveTestOrFolder}>
            <Typography>Move</Typography>
          </ContainedButton>
        </Flex>
      </Flex>
    </Box>
  );
};

const SingleFolder = ({ folder }) => {
  const theme = useTheme();
  const { foldersState, setFoldersState } = useContext(TestCaseContext);
  const entityType = foldersState?.entityType;
  const testToMoveParentID = foldersState?.entityToMove?.folder_id;
  const folderToMoveParentID = foldersState?.entityToMove?.parent_id;
  const entitiyToMoveID = foldersState?.entityToMove?.id;
  const destFolderID = foldersState?.destinationFolder?.id;
  const disabledMode =
    folderToMoveParentID === folder.id ||
    entitiyToMoveID === folder?.id ||
    folder?.parent_id === entitiyToMoveID ||
    testToMoveParentID === folder.id;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          if (entityType === 'folder') {
            if (
              folderToMoveParentID === folder.id ||
              entitiyToMoveID === folder?.id ||
              folder?.parent_id === entitiyToMoveID
            ) {
              return; // restrict moving a folder into its parent or into itself or into one of its subfolders
            }
            setFoldersState((prev) => {
              return { ...prev, destinationFolder: folder };
            });
          } else if (entityType === 'test-case') {
            if (testToMoveParentID === folder.id) {
              return;
            }
            setFoldersState((prev) => {
              return { ...prev, destinationFolder: folder };
            });
          }
        }}
        sx={{
          border:
            folder.id !== destFolderID
              ? '0.05px solid #ccc'
              : `2px solid ${theme.palette.primary.main}`,
          borderLeft: 'none',
          bgcolor: !!disabledMode
            ? `${theme.palette.background.inActive}`
            : 'rgba(36, 195, 217, 0.05)',
          py: '10px',
          pl: '8px',
          pr: '4px',
          mb: 0.5,
          cursor: 'pointer',
          userSelect: 'none',
          transition: 'background-color 0.3s ease',
          '&: hover': {
            background: !disabledMode && 'rgba(36, 195, 217, 0.2)',
            borderLeft: !disabledMode && '3px solid #1958b8'
          }
        }}>
        <Typography
          variant={'body2'}
          fontWeight={400}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
            textOverflow: 'ellipsis'
          }}>
          <Box
            sx={{
              ':hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => {
              setIsOpen((prev) => !isOpen);
            }}>
            {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </Box>
          <FolderIcon sx={{ color: '#24C3D9', ml: 1, mr: '3px' }} /> {folder?.name}
        </Typography>
      </Box>
      {!!isOpen &&
        folder?.subdirectories.map((subfolder) => {
          return (
            <Box marginLeft={2}>
              <SingleFolder folder={subfolder} />
            </Box>
          );
        })}
    </>
  );
};

export default MoveToFolderSuggestion;
