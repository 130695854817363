import { SvgIcon } from '@mui/material';

const ShakeSvg = ({ width = 30, height = 32, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 15 16'} {...props} fill={'none'}>
      <path
        d="M4.60496 13.4794C4.04867 13.2012 3.54855 12.842 3.11802 12.4112C2.68716 11.9807 2.3279 11.4805 2.04979 10.9242C1.96672 10.7577 1.76443 10.6907 1.5986 10.7738C1.43244 10.8569 1.36513 11.0589 1.4482 11.225C1.75885 11.846 2.16047 12.4049 2.64252 12.8867C3.12426 13.3687 3.68317 13.7703 4.30415 14.081C4.35241 14.1049 4.40364 14.1164 4.45421 14.1164C4.57769 14.1164 4.69622 14.0485 4.75533 13.9306C4.83843 13.7644 4.77113 13.5625 4.60496 13.4794Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M4.00368 14.6816C3.31703 14.3384 2.69902 13.8944 2.16669 13.3625C1.6347 12.8302 1.19073 12.2121 0.847551 11.5255C0.764136 11.3596 0.561839 11.292 0.396333 11.3751C0.230164 11.4581 0.162858 11.6601 0.245927 11.8263C0.621611 12.5776 1.10795 13.2544 1.69116 13.838C2.27469 14.4212 2.9515 14.9075 3.70284 15.2832C3.7511 15.3072 3.80233 15.3187 3.8529 15.3187C3.97638 15.3187 4.09492 15.2507 4.15402 15.1328C4.23716 14.9666 4.16985 14.7647 4.00368 14.6816Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M10.7921 1.58095C11.4787 1.9241 12.0967 2.3681 12.6291 2.90008C13.161 3.43238 13.605 4.05043 13.9482 4.73708C14.0073 4.85498 14.1258 4.92295 14.2493 4.92295C14.2999 4.92295 14.3511 4.91145 14.3994 4.88749C14.5656 4.80442 14.6329 4.60244 14.5498 4.43627C14.1741 3.68493 13.6878 3.00812 13.1046 2.42459C12.521 1.84138 11.8442 1.35504 11.0928 0.979357C10.9267 0.896919 10.7247 0.963594 10.6416 1.12976C10.5586 1.29593 10.6259 1.49788 10.7921 1.58095Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M10.1908 2.78319C10.7471 3.06133 11.2472 3.42059 11.6777 3.85142C12.1086 4.28192 12.4678 4.78207 12.746 5.33836C12.8051 5.45626 12.9236 5.52423 13.0471 5.52423C13.0977 5.52423 13.1489 5.51272 13.1971 5.48876C13.3633 5.4057 13.4306 5.20371 13.3475 5.03755C13.0369 4.41657 12.6353 3.85766 12.1532 3.37592C11.6715 2.89384 11.1126 2.49224 10.4916 2.1816C10.3254 2.09951 10.1235 2.16583 10.0404 2.332C9.95731 2.49814 10.0247 2.70009 10.1908 2.78319Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M14.5002 9.43157L6.09417 1.02027C5.71455 0.638757 5.04858 0.637118 4.667 1.0193L0.295547 5.39368C0.105073 5.58425 0 5.83793 0 6.10741C0 6.37689 0.105073 6.63054 0.295547 6.82114L8.70158 15.2324C8.8917 15.4237 9.14523 15.5292 9.41549 15.5292C9.68478 15.5292 9.93827 15.424 10.1287 15.2334L14.5002 10.8591C14.6907 10.6685 14.7958 10.4148 14.7958 10.1453C14.7958 9.87588 14.6907 9.62217 14.5002 9.43157ZM12.0071 12.068L11.3346 12.7405C11.2689 12.8062 11.1829 12.839 11.0968 12.839C11.0108 12.839 10.9248 12.8062 10.8591 12.7405C10.7277 12.6091 10.7277 12.3963 10.8591 12.265L11.5316 11.5925C11.6629 11.4611 11.8757 11.4611 12.0071 11.5925C12.1385 11.7238 12.1385 11.9366 12.0071 12.068ZM8.57497 13.204L1.48429 6.11368L5.38029 2.21804L12.4708 9.30821L8.57497 13.204Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M4.13372 4.1946L3.4612 4.86712C3.32983 4.99849 3.32983 5.21128 3.4612 5.34261C3.52687 5.40828 3.61293 5.44113 3.69896 5.44113C3.78499 5.44113 3.87102 5.40828 3.93672 5.34261L4.60925 4.67009C4.74061 4.53872 4.74061 4.32593 4.60925 4.1946C4.47788 4.06323 4.26509 4.06323 4.13372 4.1946Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M5.38029 3.42356C5.56601 3.42356 5.71656 3.273 5.71656 3.08728C5.71656 2.90156 5.56601 2.751 5.38029 2.751C5.19457 2.751 5.04401 2.90156 5.04401 3.08728C5.04401 3.273 5.19457 3.42356 5.38029 3.42356Z"
        fill={fill || '#1958B8'}
      />
    </SvgIcon>
  );
};

export default ShakeSvg;
