import React, { useContext, useRef } from 'react';
import WebIcon from '@mui/icons-material/Web';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Flex from '../../components/base/Flex';
import CustomButton from '../../components/customButton';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import PageLoader from '../../components/PageLoader';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import useAwaitModal from '../../hooks/useAwaitModal';
import ActionMenu from './components/ActionMenu';
import ErrorState from '../../components/base/ErrorState';
import AddDomainsModal from './components/AddDomainsModal';
import { StyledTableCell, StyledTableRow } from './Settings';
import { useTheme } from '@emotion/react';
import { ColorContext } from '../../AppTheme';

const Domain = ({ domain, refetchDomains }) => {
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const menuRef = useRef();

  const { mutateAsync: deleteDomain, isLoading: isDeletingDomain } = useScandiumMutation(
    `/company/domains/${domain.uuid}`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchDomains();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteDomain = async () => {
    if (await requestConfirm()) return await deleteDomain();
  };

  return (
    <StyledTableRow tabIndex={0} role={'link'} ref={menuRef}>
      <StyledTableCell component="th" scope="row">
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}
          className={'cell-content-wrapper'}>
          <WebIcon color={'primary'} />
          <Flex justifyContent={'flex-start'} flexDirection={'column'} alignItems={'normal'}>
            <Typography
              sx={{
                textAlign: 'left',
                fontWeight: 500
              }}>
              {domain.domain}
            </Typography>
          </Flex>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}></Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            onDelete={handleDeleteDomain}
            ref={menuRef}
            isLoading={isDeletingDomain}
            isDelete={true}
          />
        </Box>
      </StyledTableCell>
      <ConfirmationDialog
        title={'Are you sure you want to delete this domain?'}
        description={
          'Deleting this domain will permanently remove it from our system. Once deleted, this action cannot be undone'
        }
        confirmLabel={'Proceed'}
        confirmColor={'error'}
      />
    </StyledTableRow>
  );
};

const Domains = () => {
  useDocumentTitle('Organization Domains');
  const activeProject = useActiveProject();
  const theme = useTheme();
  const { mode } = useContext(ColorContext)

  const {
    data: domains,
    isLoading,
    error: domainsError,
    refetch: refetchDomains
  } = useScandiumQuery(`/company/domains`, {
    enabled: !!activeProject?.id,
    select: (data) => data.data
  });

  const [
    requestAddDomainModal,
    {
      open: openCreateAddDomainModal,
      onClose: onCloseAddDomainModal,
      onComplete: completeAddDomainModal
    }
  ] = useAwaitModal();

  return (
    <Box>
      <Flex justifyContent={'space-between'} mb={4}>
        <Typography variant={'h6'}>Manage Your Domains</Typography>
        <CustomButton
          onClick={() => requestAddDomainModal()}
          disableRipple={true}
          startIcon={<WebIcon color={'secondary'} />}
          disableFocusRipple={true}
          fullWidth
          disableElevation
          label={'Add Domains'}
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            typography: { xs: 'caption', sm: 'caption' },
            borderRadius: '0.4rem',
            color: theme.palette.button.textContained,
            border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
            background: mode === 'dark' ? 'none' : 'primary',
            py: { xs: '8rem', sm: '0.4rem' },
            px: 2,
            width: 'max-content'
          }}
        />
      </Flex>

      <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
        <Table sx={{ width: '100%', minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align={'left'}>Domains</StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align={'center'}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains?.map((domain) => (
              <Domain key={domain.uuid} domain={domain} refetchDomains={refetchDomains} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading && <PageLoader height={'100px'} />}
      {!isLoading && domainsError && !domains?.length && <ErrorState error={domainsError} />}

      <AddDomainsModal
        open={openCreateAddDomainModal}
        onClose={onCloseAddDomainModal}
        onComplete={completeAddDomainModal}
        refetchDomains={refetchDomains}
      />
    </Box>
  );
};

export default Domains;
