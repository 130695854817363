import React, { useState } from 'react';

export const TestAutomationContext = React.createContext({});

export const TestAutomationProvider = ({ children }) => {
  const [automate, setAutomate] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeSuiteName, setActiveSuiteName] = useState('');
  const [suiteRuns, setSuiteRuns] = useState([]);
  const [hoveredSuite, setHoveredSuite] = useState(null);
  const [activeSuite, setActiveSuite] = useState({ name: '', description: '', test_cases: [] });
  const [activeRun, setActiveRun] = useState({});
  const [testsList, setTestsList] = useState([]);
  const [activeTestResult, setActiveTestResult] = useState({});
  const [responseEditorState, setResponseEditorState] = useState({
    wordWrap: 'on',
    responseEditorLanguage: 'json',
    lineNumbers: 'on',
    //  value: bodyType === 'json' ? JSON.stringify(_body, null, 2) : _body,
    value: '',
    activeBTN: 'pretty'
  });
  const [automationSuites, setAutomationSuites] = useState([]);
  const [testsPage, setTestpage] = useState('');
  const [suiteOrRunQueryParams, setSuiteOrRunQueryParams] = useState({
    page: '',
    status: '',
    date_from: '',
    date_to: new Date(),
    showFilter: false,
    showStatus: true,
    showDate: true
  });
  return (
    <TestAutomationContext.Provider
      value={{
        activeTab,
        setActiveTab,
        testsList,
        setTestsList,
        automationSuites,
        setAutomationSuites,
        activeSuiteName,
        setActiveSuiteName,
        automate,
        setAutomate,
        activeSuite,
        setActiveSuite,
        activeRun,
        setActiveRun,
        hoveredSuite,
        setHoveredSuite,
        suiteRuns,
        setSuiteRuns,
        suiteOrRunQueryParams,
        setSuiteOrRunQueryParams,
        activeTestResult,
        setActiveTestResult,
        responseEditorState,
        setResponseEditorState,
        testsPage,
        setTestpage
      }}>
      {children}
    </TestAutomationContext.Provider>
  );
};
