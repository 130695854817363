import React, { useRef, useState } from 'react';

export const ScandiumContext = React.createContext({
  refreshedToken: false,
  setRefreshedToken: () => {}
});

export const ScandiumProvider = ({ children }) => {
  const [refreshedToken, setRefreshedToken] = useState(false);

  return (
    <ScandiumContext.Provider value={{ refreshedToken, setRefreshedToken }}>
      {children}
    </ScandiumContext.Provider>
  );
};
