import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import CustomInput from '../../components/customInput';
import ContainedButton from '../../components/base/ContainedButton';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useCreateFolder } from '../../data-layer/project-management';
import { toast } from 'react-toastify';
import { useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';

function NewProjectFolder() {
  useDocumentTitle('New Folder');
  const { projectId, folderId } = useParams();
  let navigate = useNavigate();

  const activeProject = useActiveProject();
  const readyOnly = activeProject?.read_only;

  const [name, setName] = useState('');

  const currentUrl = window.location.href;
  const containsEdit = currentUrl.includes('edit');

  const { isLoading } = useScandiumQuery(`/projects/${projectId}/folders/${folderId}`, {
    onSuccess: (data) => {
      setName(data.data.name)
    },
    enabled: !!projectId && !!containsEdit
  });

  const { mutateAsync, isLoading: showProgress } = useCreateFolder(projectId, {
    folderId,
    editFolder: containsEdit,
    method: !!containsEdit ? 'PATCH' : 'POST',
    onSuccess: () => {
      navigate(containsEdit ? -1 : `/projects/${projectId}/folders/${folderId || ''}`);
    },
    onError: () => {
      toast.error(`Sorry, an error occurred`);
    }
  });

  const handleCreateFolder = async () => {
    if (!name) {
      toast.error('Folder name is required');
    } else {
      try {
        await mutateAsync({
          name,
          parent_id: folderId || undefined
        });
      } catch (error) {}
    }
  };

  const handleUpdateFolder = async () => {
    if (!name) {
      toast.error('Folder name is required');
    } else {
      try {
        await mutateAsync({ name });
      } catch (error) {}
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '50%' }
          }}>
          <Typography
            color="primary"
            sx={{
              textAlign: 'left',
              fontSize: { xs: '1.0rem', sm: '1.25rem' },
              fontWeight: 'bold',
              mt: { xs: 4, sm: 4 }
            }}>
            {containsEdit ? 'Update Folder' : 'New Folder'}
          </Typography>
          <CustomInput
            required
            onChange={(event) => {
              setName(event.target.value);
            }}
            label="Folder Name"
            size={'small'}
            value={name}
            formControlStyle={{
              mt: { xs: 3, sm: 6 },
              mb: 2,
              width: '100%'
            }}
            endAdornment={
              (isLoading && (
                <InputAdornment position="end">
                  <CircularProgress size={16} color={'secondary'} />
                </InputAdornment>
              )) ||
              undefined
            }
          />
          <ContainedButton
            onClick={containsEdit ? handleUpdateFolder : handleCreateFolder}
            disableRipple={true}
            fullWidth
            size={'small'}
            disabled={!!readyOnly}
            isLoading={showProgress}
            sx={{
              textAlign: 'center',
              textTransform: 'capitalize',
              py: 1.5,
              mt: { xs: 4, sm: 4, md: 2 }
            }}>
            {containsEdit ? 'Update Folder' : 'Create Folder'}
          </ContainedButton>
        </Box>
      </Box>
    </Box>
  );
}

export default NewProjectFolder;
