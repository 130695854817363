import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { capitalize } from '../../../utils/strings';
import Flex from '../../../components/base/Flex';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { Checkbox, Chip, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const DropdownSelect = ({ dropdown, onTextFieldChange, label, option, index }) => {
  return (
    <FormControl
      fullWidth
      size={'small'}
      sx={{
        height: 1,
        mt: 1
      }}>
      <InputLabel id={'assertions-select-label'}>{label}</InputLabel>
      <Select
        value={dropdown.selectedOperator === 'one_of' ? dropdown.oneOf : dropdown.value || ''}
        label={label}
        multiple={dropdown.selectedOperator === 'one_of' ? true : false}
        labelId={'assertions-select-label'}
        id={'assertions-select-label'}
        // renderValue={(selected) => capitalize(selected || '')}
        onChange={(e) => {
          onTextFieldChange(index, e.target.value);
        }}>
        {option.map((optionItem) => {
          return (
            <MenuItem key={optionItem} value={optionItem}>
              {optionItem}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const StatusCodesListUi = ({ dropDownArray, setDropdowns, index, valuesToDisplay = [] }) => {
  const handleCodeRemove = (position) => {
    const oldArray = [...dropDownArray];
    oldArray[index].oneOf.splice(position, 1);
    setDropdowns(oldArray);
  };
  const CodeUiBox = ({ code, index }) => {
    return (
      <Chip
        label={code}
        onDelete={() => {
          handleCodeRemove(index);
        }}
        variant="outlined"
        size={'small'}
        color={'primary'}
        IconDelete={<CloseIcon />}
        sx={{
          borderRadius: '0.3rem',
          py: 1,
          mt: 1
        }}
      />
    );
  };
  return (
    <Flex>
      {valuesToDisplay.map((code, index) => {
        return <CodeUiBox key={code + index} code={code} index={index} />;
      })}
    </Flex>
  );
};
const OperatorSelector = ({ assertionType, operator, changeOperator, index }) => {
  const operatorFilter = {
    equals: [
      'status code',
      'response body type', // json, etc
      'response body content'
    ],
    doesNotEqual: [
      'status code',
      'response body type', // json, etc
      'response body content'
    ],
    range: ['status code', 'request duration'],
    lessThan: ['status code', 'request duration'],
    greaterThan: ['status code', 'request duration'],
    contains: ['response body content'],
    oneOf: ['status code', 'response body type']
  };

  return (
    <FormControl
      fullWidth
      size={'small'}
      sx={{
        height: { xs: 1, sm: 1 }
      }}>
      <InputLabel id={'assertions-select-operator'}>Select Operator</InputLabel>
      <Select
        value={operator}
        label={'select operator'}
        labelId={'assertions-select-operator'}
        id={'assertions-select-operator'}
        MenuProps={{
          elevation: 1
        }}
        // renderValue={(selected) => capitalize(selected || '')}
        onChange={(e) => {
          changeOperator(index, e.target.value);
        }}>
        {operatorFilter.equals.includes(assertionType) && (
          <MenuItem divider={true} value={'equal'}>
            Equals
          </MenuItem>
        )}
        {operatorFilter.doesNotEqual.includes(assertionType) && (
          <MenuItem divider={true} value={'not_equal'}>
            Does not equal
          </MenuItem>
        )}
        {operatorFilter.range.includes(assertionType) && (
          <MenuItem divider={true} value={'range'}>
            Range
          </MenuItem>
        )}
        {operatorFilter.lessThan.includes(assertionType) && (
          <MenuItem value={'less_than'}>Less than</MenuItem>
        )}
        {operatorFilter.greaterThan.includes(assertionType) && (
          <MenuItem value={'greater_than'}>Greater than</MenuItem>
        )}
        {operatorFilter.contains.includes(assertionType) && (
          <MenuItem value={'contains'}>contains</MenuItem>
        )}
        {operatorFilter.oneOf.includes(assertionType) && (
          <MenuItem value={'one_of'}>oneOf</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
const RenderTextField = ({
  dropdown,
  dropDownArray,
  index,
  setDropdowns,
  handleOneOfInsertions,
  handleAssertHeaderValue,
  handleRangeLimit,
  handleCreateVariableFromJson,
  onTextFieldChange
}) => {
  const [currentCode, setCurrentCode] = useState('');
  const [cascade, setCascade] = useState(true);
  if (dropdown.selectedOperator !== 'range') {
    switch (dropdown.selectedOption) {
      case 'status code':
        if (dropdown.selectedOperator === 'one_of') {
          return (
            <Flex justifyContent="center" flexDirection="column" alignItems="flex-start">
              <TextField
                size={'small'}
                value={currentCode || ''}
                label={'Expected status code Array'}
                type={'number'}
                fullWidth
                sx={{
                  height: 1,
                  mt: 2
                }}
                inputProps={{ maxLength: 3 }}
                onChange={(e) => {
                  setCurrentCode(e.target.value);
                  if (e.target.value.length === 3) {
                    handleOneOfInsertions(index, e.target.value);
                    setTimeout(() => {
                      setCurrentCode('');
                    }, 500);
                  }
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                }}
              />
              <StatusCodesListUi
                index={index}
                dropDownArray={dropDownArray}
                setDropdowns={setDropdowns}
                valuesToDisplay={dropdown.oneOf}
                // assertStatusCode={assertStatusCode}
              />
            </Flex>
          );
        } else {
          return (
            <Flex justifyContent="center">
              <TextField
                size={'small'}
                value={dropdown.value || ''}
                label={'Expected status code'}
                type={'number'}
                fullWidth
                sx={{
                  height: 1,
                  mt: 2
                }}
                inputProps={{ maxLength: 3 }}
                onChange={(e) => onTextFieldChange(index, e.target.value)}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                }}
              />
            </Flex>
          );
        }

      case 'response body type':
        return (
          <>
            <DropdownSelect
              dropdown={dropdown}
              // selectedOperator={dropdown.selectedOperator}
              // assertBodyType={assertBodyType}
              label={'Expected body type'}
              option={['application/json', 'image', 'text', 'document']}
              // option={'application/json'}
              onTextFieldChange={onTextFieldChange}
              index={index}
            />
          </>
        );
      case 'response body content':
        return (
          <TextField
            size={'small'}
            value={dropdown?.value || ''}
            label={'Expected body content'}
            rows={4}
            multiline={true}
            fullWidth
            sx={{ mt: 1 }}
            onChange={(e) => onTextFieldChange(index, e.target.value)}
          />
        );
      case 'response header':
        return (
          <Flex mt={1} width={'100%'}>
            <TextField
              size={'small'}
              value={dropdown.value.key}
              label={'Expected header key'}
              fullWidth
              sx={{
                height: 1
              }}
              onChange={(e) => handleAssertHeaderValue(index, 'key', e.target.value)}
            />
            <TextField
              size={'small'}
              value={dropdown.value.value}
              label={'Expected header value'}
              fullWidth
              sx={{
                height: 1
              }}
              onChange={(e) => handleAssertHeaderValue(index, 'value', e.target.value)}
            />
          </Flex>
        );
      case 'request duration':
        return (
          <TextField
            size={'small'}
            value={dropdown.value || ''}
            label={'Expected duration in milliseconds'}
            type={'number'}
            fullWidth
            sx={{
              height: 1,
              mt: 2
            }}
            onChange={(e) => onTextFieldChange(index, e.target.value)}
          />
        );
      case 'create global variable':
        return (
          <Stack>
            <Flex>
              {!!dropdown.value.cascade && (
                <TextField
                  size={'small'}
                  value={dropdown?.value.variableName || ''}
                  placeholder="variable name"
                  label={'variable name'}
                  fullWidth
                  sx={{
                    height: 1
                  }}
                  onChange={(e) =>
                    handleCreateVariableFromJson(index, 'variableName', e.target.value)
                  }
                />
              )}
              <TextField
                multiline
                size={'small'}
                value={`response${dropdown?.value.value}` || ''}
                placeholder={'e.g response.token'}
                label={'Expected JSON response field'}
                fullWidth
                sx={{
                  height: 1,
                  fontSize: 4
                }}
                onChange={(e) => {
                  handleCreateVariableFromJson(index, 'value', e.target.value);
                }}
              />
            </Flex>
            <Flex>
              <Checkbox
                checked={dropdown.value.cascade}
                onChange={(e) => {
                  handleCreateVariableFromJson(index, 'cascade', e.target.checked);
                }}
              />
              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                {' '}
                create variable if field is present
              </Typography>
            </Flex>
          </Stack>
        );
      default:
        return null;
    }
  } else {
    if (dropdown.selectedOption === 'status code' || 'response duration') {
      return (
        <Flex justifyContent="flex-start" flex="1" columnGap={5}>
          <TextField
            size={'small'}
            value={dropdown.range[0] || ''}
            label={
              dropdown.selectedOption === 'status code'
                ? 'lower limit'
                : 'lower limit in milliseconds'
            }
            type={'number'}
            sx={{
              height: 1,
              mt: 2
            }}
            onChange={(e) => {
              handleRangeLimit(index, e.target.value, 0);
              onTextFieldChange(index);
            }}
          />
          <TextField
            size={'small'}
            value={dropdown.range[1] || ''}
            label={
              dropdown.selectedOption === 'status code'
                ? 'upper limit'
                : 'upper limit in milliseconds'
            }
            type={'number'}
            sx={{
              height: 1,
              mt: 2
            }}
            onChange={(e) => {
              handleRangeLimit(index, e.target.value, 1);
              onTextFieldChange(index);
            }}
          />
        </Flex>
      );
    }
  }
};

const TestAssertionss = ({
  dropdown,
  index,
  setDropdowns,
  dropDownArray,
  handleAssertHeaderValue,
  handleOneOfInsertions,
  handleRangeLimit,
  handleOptionSelect,
  handleOperatorSelect,
  onTextFieldChange,
  handleCreateVariableFromJson,
  onDelete
}) => {
  const [currentCode, setCurrentCode] = useState('');
  //for , status code, request duration and response body type which can be selected once
  // check if the assertion already exist in dropdwons
  const checkAssertionExist = (assertionType) => {
    let assert = [];
    dropDownArray?.map((item) => {
      if (item.selectedOption === assertionType) {
        assert.push(item);
      }
    });
    if (assert.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  // further checks its position in the dropdown array (this is also to either show it or not). hereby it is only visible to the dropdown that originally selcted it
  const checkAssertionIndex = (assertionType) => {
    let index = '';
    dropDownArray.map((item, position) => {
      if (item.selectedOption === assertionType) {
        index = position;
      }
    });
    return index;
  };
  return (
    <Box mb={3}>
      <Flex columnGap={5} sx={{ mb: 3 }}>
        <FormControl
          fullWidth
          size={'small'}
          sx={{
            height: { xs: 1, sm: 1 }
          }}>
          <InputLabel id={'assertions-select-label'}>Add new</InputLabel>
          <Select
            value={dropdown.selectedOption || ''}
            label={'Add new'}
            labelId={'assertions-select-label'}
            id={'assertions-select-label'}
            // renderValue={(selected) => capitalize(selected || '')}
            onChange={(e) => {
              handleOptionSelect(index, e.target.value);
            }}>
            {(!checkAssertionExist('status code') ||
              checkAssertionIndex('status code') === index) && (
              <MenuItem divider={true} value={'status code'}>
                Status code
              </MenuItem>
            )}
            <MenuItem divider={true} value={'response header'}>
              Response header
            </MenuItem>
            {(!checkAssertionExist('response body type') ||
              checkAssertionIndex('response body type') === index) && (
              <MenuItem divider={true} value={'response body type'}>
                Response body type
              </MenuItem>
            )}
            <MenuItem value={'response body content'}>Response body content</MenuItem>

            {(!checkAssertionExist('request duration') ||
              checkAssertionIndex('request duration') === index) && (
              <MenuItem divider={true} value={'request duration'}>
                Request duration
              </MenuItem>
            )}
            <MenuItem value={'create global variable'}>Check for field in JSON response</MenuItem>
          </Select>
        </FormControl>
        {!!dropdown.selectedOption &&
          dropdown.selectedOption !== 'response header' &&
          dropdown.selectedOption !== 'create global variable' && (
            <OperatorSelector
              index={index}
              assertionType={dropdown.selectedOption}
              operator={dropdown.selectedOperator}
              changeOperator={handleOperatorSelect}
            />
          )}
        <Tooltip title={'Delete this assertion'}>
          <Box sx={{ cursor: 'pointer' }} onClick={() => onDelete(index)}>
            <DeleteSvg width={16} height={18} />
          </Box>
        </Tooltip>
      </Flex>
      <Box sx={{ width: '95.5%' }}>
        <RenderTextField
          dropdown={dropdown}
          index={index}
          dropDownArray={dropDownArray}
          setDropdowns={setDropdowns}
          handleAssertHeaderValue={handleAssertHeaderValue}
          handleOneOfInsertions={handleOneOfInsertions}
          handleRangeLimit={handleRangeLimit}
          handleCreateVariableFromJson={handleCreateVariableFromJson}
          onTextFieldChange={onTextFieldChange}
        />
      </Box>
    </Box>
  );
};

export default TestAssertionss;
