import { SvgIcon } from '@mui/material';

const ResponseSvg = ({ width = 128, height = 128, disabled, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={'0 0 128 128'}
      {...props}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}>
      <circle cx="64" cy="64" r="64" fill="#EAFCFF" />
      <path
        d="M86.17 32H41.83C36.41 32 32 36.41 32 41.83V78.11C32 83.53 36.41 87.94 41.83 87.94H59.06C61 92.66 65.64 96 71.05 96C76.46 96 81.11 92.66 83.04 87.93H86.16C91.58 87.93 95.99 83.52 95.99 78.1V41.83C96 36.41 91.59 32 86.17 32ZM94.06 41.83V43.47H60.74C59.63 43.47 58.59 42.97 57.9 42.09L51.47 33.93H86.17C90.52 33.94 94.06 37.48 94.06 41.83ZM71.05 94.06C64.97 94.06 60.03 89.11 60.03 83.03C60.03 76.95 64.97 72.01 71.05 72.01C77.13 72.01 82.07 76.95 82.07 83.03C82.08 89.12 77.13 94.06 71.05 94.06ZM86.17 85.99H83.66C83.88 85.04 84.01 84.05 84.01 83.03C84.01 75.88 78.2 70.07 71.05 70.07C63.9 70.07 58.09 75.88 58.09 83.03C58.09 84.05 58.22 85.04 58.44 85.99H41.83C37.48 85.99 33.94 82.45 33.94 78.1V41.83C33.94 37.48 37.48 33.94 41.83 33.94H49L56.38 43.3C57.44 44.64 59.03 45.41 60.74 45.41H94.06V78.11C94.06 82.46 90.52 85.99 86.17 85.99Z"
        fill="black"
      />
      <path
        d="M86.1897 39.67H87.4797C88.0197 39.67 88.4497 39.24 88.4497 38.7C88.4497 38.16 88.0197 37.73 87.4797 37.73H86.1897C85.6497 37.73 85.2197 38.16 85.2197 38.7C85.2197 39.24 85.6497 39.67 86.1897 39.67Z"
        fill="black"
      />
      <path
        d="M80.7005 39.67H81.9905C82.5305 39.67 82.9605 39.24 82.9605 38.7C82.9605 38.16 82.5305 37.73 81.9905 37.73H80.7005C80.1605 37.73 79.7305 38.16 79.7305 38.7C79.7305 39.24 80.1605 39.67 80.7005 39.67Z"
        fill="black"
      />
      <path
        d="M75.2102 39.67H76.5002C77.0402 39.67 77.4702 39.24 77.4702 38.7C77.4702 38.16 77.0402 37.73 76.5002 37.73H75.2102C74.6702 37.73 74.2402 38.16 74.2402 38.7C74.2402 39.24 74.6702 39.67 75.2102 39.67Z"
        fill="black"
      />
      <path
        d="M42.1295 60.3901H39.2395C38.6995 60.3901 38.2695 60.8201 38.2695 61.3601C38.2695 61.9001 38.6995 62.3301 39.2395 62.3301H42.1295C42.6695 62.3301 43.0995 61.9001 43.0995 61.3601C43.0995 60.8201 42.6595 60.3901 42.1295 60.3901Z"
        fill="black"
      />
      <path
        d="M42.1295 66.1602H39.2395C38.6995 66.1602 38.2695 66.5902 38.2695 67.1302C38.2695 67.6702 38.6995 68.1002 39.2395 68.1002H42.1295C42.6695 68.1002 43.0995 67.6702 43.0995 67.1302C43.0995 66.5902 42.6595 66.1602 42.1295 66.1602Z"
        fill="black"
      />
      <path
        d="M42.1295 71.9302H39.2395C38.6995 71.9302 38.2695 72.3602 38.2695 72.9002C38.2695 73.4402 38.6995 73.8702 39.2395 73.8702H42.1295C42.6695 73.8702 43.0995 73.4402 43.0995 72.9002C43.0995 72.3602 42.6595 71.9302 42.1295 71.9302Z"
        fill="black"
      />
      <path
        d="M42.1295 77.6899H39.2395C38.6995 77.6899 38.2695 78.1199 38.2695 78.6599C38.2695 79.1999 38.6995 79.6299 39.2395 79.6299H42.1295C42.6695 79.6299 43.0995 79.1999 43.0995 78.6599C43.0995 78.1199 42.6595 77.6899 42.1295 77.6899Z"
        fill="black"
      />
      <path
        d="M86.1902 60.3901H48.7102C48.1702 60.3901 47.7402 60.8201 47.7402 61.3601C47.7402 61.9001 48.1702 62.3301 48.7102 62.3301H86.1902C86.7302 62.3301 87.1602 61.9001 87.1602 61.3601C87.1602 60.8201 86.7202 60.3901 86.1902 60.3901Z"
        fill="black"
      />
      <path
        d="M86.1902 66.1602H48.7102C48.1702 66.1602 47.7402 66.5902 47.7402 67.1302C47.7402 67.6702 48.1702 68.1002 48.7102 68.1002H86.1902C86.7302 68.1002 87.1602 67.6702 87.1602 67.1302C87.1602 66.5902 86.7202 66.1602 86.1902 66.1602Z"
        fill="black"
      />
      <path
        d="M52.4302 71.9302H48.7102C48.1702 71.9302 47.7402 72.3602 47.7402 72.9002C47.7402 73.4402 48.1702 73.8702 48.7102 73.8702H52.4302C52.9702 73.8702 53.4002 73.4402 53.4002 72.9002C53.4002 72.3602 52.9702 71.9302 52.4302 71.9302Z"
        fill="black"
      />
      <path
        d="M50.5702 77.6899H48.7102C48.1702 77.6899 47.7402 78.1199 47.7402 78.6599C47.7402 79.1999 48.1702 79.6299 48.7102 79.6299H50.5702C51.1102 79.6299 51.5402 79.1999 51.5402 78.6599C51.5402 78.1199 51.1002 77.6899 50.5702 77.6899Z"
        fill="black"
      />
      <path
        d="M75.15 76.7801C74.7 76.4901 74.1 76.6101 73.81 77.0601L66.68 87.9501C66.39 88.4001 66.51 89.0001 66.96 89.2901C67.12 89.4001 67.31 89.4501 67.49 89.4501C67.81 89.4501 68.12 89.3001 68.3 89.0101L75.43 78.1201C75.72 77.6801 75.6 77.0801 75.15 76.7801Z"
        fill="black"
      />
      <path
        d="M67.6197 82.5099C67.8697 82.5099 68.1197 82.4199 68.3097 82.2299C68.6897 81.8499 68.6897 81.2399 68.3097 80.8599L66.5697 79.1199L68.2997 77.3899C68.6797 77.0099 68.6797 76.3999 68.2997 76.0199C67.9197 75.6399 67.3097 75.6399 66.9297 76.0199L64.5097 78.4299C64.3297 78.6099 64.2197 78.8599 64.2197 79.1199C64.2197 79.3799 64.3197 79.6199 64.4997 79.8099L66.9197 82.2299C67.1197 82.4099 67.3697 82.5099 67.6197 82.5099Z"
        fill="black"
      />
      <path
        d="M75.1796 83.8499C74.7996 83.4699 74.1897 83.4699 73.8097 83.8499C73.4297 84.2299 73.4297 84.8399 73.8097 85.2199L75.5397 86.9499L73.7996 88.6899C73.4196 89.0699 73.4196 89.6799 73.7996 90.0599C73.9896 90.2499 74.2397 90.3399 74.4897 90.3399C74.7397 90.3399 74.9896 90.2499 75.1796 90.0599L77.5996 87.6399C77.7796 87.4599 77.8797 87.2099 77.8797 86.9499C77.8797 86.6899 77.7796 86.4499 77.5896 86.2599L75.1796 83.8499Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default ResponseSvg;
