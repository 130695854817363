const WindowsSvg = ({ width, height }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="192 206 608 612">
      <path
        fill="#0078d4"
        d="M800 206v290H472V252.9zM192 496h248V257.5L192 293zm0 235l248 35.5V528H192zm280 40.1L800 818V528H472z"
      />
    </svg>
  );
};

export default WindowsSvg;
