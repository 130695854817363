import { useCookies } from 'react-cookie';
import { useContext } from 'react';
import { ScandiumContext } from '../ScandiumProvider';

const useUpdateToken = () => {
  const [, setCookie] = useCookies(['token', 'email']);
  const { setRefreshedToken } = useContext(ScandiumContext);

  return (data) => {
    setCookie('token', data.authorization.token, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('email', data.user.email, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('name', data.user.name, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('user', data.user, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('local_features', data.user.enabled_features, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('active_company', data.user.active_company, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('company', data.user.company, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('designation', data.user.active_company_designation, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('role', data.user.active_company_role, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    setCookie('memberships', data.user.company_memberships, {
      path: '/',
      sameSite: 'lax',
      secure: true
    });
    if (data.authorization?.token) setRefreshedToken(true);
  };
};

export default useUpdateToken;
