import React, { useState, useContext, useEffect } from 'react';
import { List, ListItem, ListItemText, Divider, Typography, Box, Chip } from '@mui/material';
import Flex from '../../../components/base/Flex';
import { FloatingVariableWindowContext } from '../../../store/floatingVariableWindowState';
import GMobiledataIcon from '@mui/icons-material/GMobiledata';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@emotion/react';
export const VariablesList = React.forwardRef(({ variables, setRawContent }, ref) => {
  const theme = useTheme();
  const {
    focusedInput,
    focusedInpuSetterFn,
    focusedInputPosition,
    setVariableFloatingWindow,
    variablesFilter,
    setOpenDynamicValues,
    isEditorFocused,
    editorRef
  } = useContext(FloatingVariableWindowContext);
  const [focusedVaraiableValue, setFocusedVaraibleValue] = useState('');
  const [filterApplied, setFilterApplied] = useState(true);
  const [variablesList, setVariablesList] = useState(variables);

  useEffect(() => {
    let variablesWithAppliedFilter = variables.filter((entry) => {
      return !!entry.name && entry.name.includes(variablesFilter);
    });
    if (!!variablesFilter) {
      // length of filter id being used to calculate adjust insertion
      if (!variablesWithAppliedFilter.length) {
        //if filter is present but not valid dont use filter length to adjust insertion, just insert
        setFilterApplied(false);
        return;
      }
      //if filter is present and valid, use filter character length to adjust insertion
      setFilterApplied(true);
      setVariablesList(variablesWithAppliedFilter);
    } else {
      //if filter is not present, setFilterApplied to false, in case it was true.
      setFilterApplied(false);
      setVariablesList(variables);
    }
  }, [variablesFilter, variables]);
  return (
    <Box
      // ref
      ref={ref}
      sx={{
        display: 'flex',

        flexDirection: 'column',
        rowGap: 1.4,
        position: 'absolute',
        outline: '2px solid rgba(224, 224, 224, .5)',
        borderRadius: 1,
        boxShadow: `5px 5px 15px ${theme.palette.background.shadow}`,
        top: focusedInputPosition.top,
        left: focusedInputPosition.left,
        alignItems: 'flex-start',
        background: theme.palette.background.default,
        width: 'fit-content',
        py: 0.5,
        px: 1,
        opacity: 1,
        justifyContent: 'center',
        justifyContent: 'flex-start',
        direction: 'column',
        columnGap: 1,
        boxSizing: 'border-box',
        zIndex: 1
      }}>
      <Flex p={1} pb={0} width={'100%'}>
        <Chip
          variant="outlined"
          color="primary"
          icon={<AddIcon />}
          sx={{
            fontSize: 12,
            columnGap: 0,
            borderRadius: '0.3rem',
            px: 0
          }}
          label="Dynamic Values"
          onClick={() => {
            setVariableFloatingWindow(false);
            setOpenDynamicValues(true);
          }}
        />
        <Chip
          color="primary"
          variant="solid"
          label="esc"
          size="small"
          onClick={() => {
            setVariableFloatingWindow(false);
          }}
          sx={{
            ml: 'auto',
            border: 'none',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        />
      </Flex>
      <Divider orientation="horizontal" flexItem />
      {variables.length > 0 && (
        <Flex
          sx={{
            alignItems: 'flex-start'
          }}>
          <CustomScrollbar
            sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '150px', width: '180px' }}>
            <List
              sx={{
                padding: 0
              }}>
              {variablesList.map((item, index) => {
                if (item.name && item.value)
                  return (
                    <Box key={item?.value + index}>
                      <ListItem
                        color="primary"
                        sx={{
                          textAlign: 'start',
                          '&:hover': { background: 'rgb(180, 180, 180, 0.1)' },
                          height: 30,
                          pr: 1,
                          my: 0.5
                        }}
                        onMouseEnter={() => {
                          setFocusedVaraibleValue(item.value);
                        }}
                        onClick={() => {
                          if (!!editorRef.current && !!isEditorFocused) {
                            const { lineNumber, column } = editorRef.current.getPosition();
                            const charAfterCursor = editorRef.current
                              .getModel()
                              .getLineContent(lineNumber)
                              .substring(column - 1, column + 2);
                            const range = {
                              startLineNumber: lineNumber,
                              startColumn: column,
                              endLineNumber: lineNumber,
                              endColumn: column
                            };
                            editorRef.current.executeEdits('', [
                              {
                                range: range,
                                text: !charAfterCursor.startsWith('}')
                                  ? `${item.name}}}`
                                  : charAfterCursor.startsWith('}}')
                                  ? item.name
                                  : `${item.name}}`
                              }
                            ]);
                            setRawContent(editorRef.current.getValue());
                            return;
                          }
                          if (!focusedInput.current) {
                            return;
                          }
                          let cursorPosition = focusedInput.current.selectionStart;
                          let lengthOfFilter = !!filterApplied ? variablesFilter.length : 0;
                          let newValue = `
                          ${focusedInput.current.value.slice(
                            0,
                            cursorPosition - (2 + lengthOfFilter)
                          )}{{${item.name}}}${focusedInput.current.value.slice(cursorPosition)} `;
                          focusedInput.current.value = newValue.trim();
                          focusedInput.current.focus();
                          focusedInpuSetterFn.current();
                          // focusedInput.current.click();
                          setVariableFloatingWindow(false);
                          focusedInput.current = null;
                        }}>
                        {/* <ListItemIcon> */}
                        <GMobiledataIcon
                          color="white"
                          sx={{
                            color: 'white',
                            background: '#0059b3',
                            borderRadius: '2px'
                          }}
                        />
                        {/* </ListItemIcon> */}
                        <ListItemText
                          primary={item?.name}
                          sx={{ ml: 1, color: theme.palette.primary.main }}
                        />
                      </ListItem>
                      {++index !== variablesList.length && (
                        <Divider sx={{ height: 1, borderWidth: 1 }} />
                      )}
                    </Box>
                  );
              })}
            </List>
          </CustomScrollbar>

          <Divider orientation="vertical" flexItem />
          <VariableValueArea value={focusedVaraiableValue} />
        </Flex>
      )}
    </Box>
  );
});
const truncateText = (text) => {
  if (text.length <= 100) {
    return text;
  }
  return text.substring(0, 100) + '...';
};
const VariableValueArea = ({ value }) => {
  return (
    <Flex
      sx={{
        alignItems: 'flex-start',
        height: '100%',
        flexDirection: 'column',
        gap: 1,
        width: '180px'
      }}>
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: 1,
          maxWidth: '100%'
        }}>
        <Typography
          variant="string"
          sx={{
            opacity: 0.6
          }}>
          CURRENT
        </Typography>
        <Typography
          fontWeight={'light'}
          variant="string"
          marginLeft={1}
          sx={{
            overflow: 'hidden',
            overflowWrap: 'break-word'
          }}>
          {truncateText(value)}
        </Typography>
      </Flex>
      <Flex
        sx={{
          maxWidth: '200px',
          alignItems: 'flex-start',
          gap: 1,
          boxSizing: 'border-box'
        }}>
        <Typography
          sx={{
            opacity: 0.6
          }}>
          SCOPE
        </Typography>
        <Typography
          fontWeight={'medium'}
          marginLeft={1}
          sx={{
            maxWidth: '70%',
            overflowWrap: 'break-word'
          }}>
          global
        </Typography>
      </Flex>
    </Flex>
  );
};
