import { SvgIcon } from '@mui/material';

const NoTestAssertionsSvg = ({ width = 100, height = 100, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 128 128'} {...props} fill={'none'}>
      <circle cx="64" cy="64" r="64" fill="#EAFCFF" />
      <path
        d="M42.2476 43.5147C41.9779 42.8675 41.2127 42.5487 40.564 42.8186C39.9155 43.0885 39.5976 43.8523 39.8667 44.5021C40.1349 45.1503 40.9031 45.4669 41.5502 45.1982C42.201 44.928 42.5145 44.1657 42.2476 43.5147Z"
        fill="black"
      />
      <path
        d="M46.6577 43.516C46.3915 42.866 45.6212 42.5504 44.9741 42.8186C44.3251 43.0876 44.0085 43.8513 44.2768 44.5008C44.5446 45.1496 45.3117 45.4683 45.9604 45.1982C46.6071 44.9289 46.9281 44.1646 46.6577 43.516Z"
        fill="black"
      />
      <path
        d="M51.3256 43.516C51.0592 42.8658 50.289 42.5514 49.6421 42.8186C48.9925 43.0869 48.6767 43.8519 48.9447 44.5008C49.2127 45.1496 49.9797 45.4681 50.6283 45.1982C51.275 44.9291 51.596 44.1645 51.3256 43.516Z"
        fill="black"
      />
      <path
        d="M99.6255 92.2628L93.9204 86.5578L99.0437 84.9706C99.5756 84.8059 99.9493 84.3009 99.9514 83.7443C99.9535 83.1876 99.5839 82.6797 99.0533 82.5108L92.3328 80.3715V70.983C92.3328 70.271 91.7556 69.6939 91.0437 69.6939C90.3318 69.6939 89.7546 70.271 89.7546 70.983V79.5508L78.9638 76.1156C77.9844 75.8043 77.0333 76.7596 77.3441 77.7352L80.9403 89.0307H40.1688C38.1889 89.0307 36.5782 87.4201 36.5782 85.4403V50.0173H89.7546V59.6317C89.7546 60.3437 90.3318 60.9209 91.0437 60.9209C91.7556 60.9209 92.3328 60.3437 92.3328 59.6317L92.334 44.1682C92.334 40.7671 89.567 38 86.1658 38H40.1688C36.7676 38 34.0006 40.7671 34.0006 44.1682L34 85.4403C34 88.8417 36.7674 91.6091 40.1688 91.6091H81.7611L83.7398 97.8246C83.9081 98.3535 84.4135 98.7229 84.9684 98.7227C85.5237 98.7226 86.0291 98.3526 86.1969 97.8232L87.852 92.6038L93.5682 98.32C94.0717 98.8235 94.8877 98.8235 95.3914 98.32L99.6257 94.0859C100.125 93.6059 100.125 92.7429 99.6255 92.2628ZM36.5789 44.1682C36.5789 42.1887 38.1893 40.5782 40.1688 40.5782H86.1659C88.1454 40.5782 89.7559 42.1887 89.7559 44.1682V47.4388H36.5789V44.1682ZM94.4797 95.5855L88.1757 89.2813C87.5038 88.6094 86.3218 88.9 86.0352 89.8032L84.9656 93.1763L80.5575 79.329L94.3598 83.7226L91.1167 84.7272C90.2074 85.009 89.9121 86.1957 90.5866 86.8703L96.8908 93.1745L94.4797 95.5855Z"
        fill="black"
      />
      <path
        d="M49.109 52.6955C48.757 52.6483 48.4067 52.6245 48.0683 52.6245C43.7705 52.6245 40.2739 56.1211 40.2739 60.4188C40.2739 64.7164 43.7705 68.2131 48.0683 68.2131C51.7601 68.2131 54.9721 65.5901 55.7056 61.9761C55.8473 61.2785 55.3966 60.5981 54.6987 60.4563C54.001 60.3149 53.3206 60.7653 53.1789 61.4632C52.6882 63.8803 50.5389 65.6348 48.0682 65.6348C45.1919 65.6348 42.852 63.2949 42.852 60.4188C42.852 57.5426 45.1919 55.2027 48.0682 55.2027C48.2926 55.2027 48.5279 55.2189 48.7673 55.2509C49.4722 55.3455 50.1215 54.8499 50.2159 54.1441C50.3102 53.4384 49.8148 52.7899 49.109 52.6955Z"
        fill="black"
      />
      <path
        d="M48.0683 86.2764C52.3661 86.2764 55.8626 82.7798 55.8626 78.4821C55.8626 74.1843 52.3661 70.6877 48.0683 70.6877C43.7705 70.6877 40.2739 74.1843 40.2739 78.4821C40.2739 82.7798 43.7705 86.2764 48.0683 86.2764ZM48.0683 73.2658C50.9445 73.2658 53.2844 75.6057 53.2844 78.4819C53.2844 81.3581 50.9445 83.698 48.0683 83.698C45.192 83.698 42.8522 81.3581 42.8522 78.4819C42.8522 75.6058 45.192 73.2658 48.0683 73.2658Z"
        fill="black"
      />
      <path
        d="M81.2694 55.8643H60.2387C59.5268 55.8643 58.9496 56.4414 58.9496 57.1534C58.9496 57.8654 59.5268 58.4425 60.2387 58.4425H81.2694C81.9813 58.4425 82.5586 57.8654 82.5586 57.1534C82.5586 56.4414 81.9813 55.8643 81.2694 55.8643Z"
        fill="black"
      />
      <path
        d="M82.5586 63.3465C82.5586 62.6345 81.9813 62.0574 81.2694 62.0574H60.2387C59.5268 62.0574 58.9496 62.6345 58.9496 63.3465C58.9496 64.0585 59.5268 64.6356 60.2387 64.6356H81.2694C81.9813 64.6356 82.5586 64.0585 82.5586 63.3465Z"
        fill="black"
      />
      <path
        d="M60.2387 76.7574H72.1087C72.8205 76.7574 73.3978 76.1802 73.3978 75.4682C73.3978 74.7563 72.8205 74.1791 72.1087 74.1791H60.2387C59.5268 74.1791 58.9496 74.7563 58.9496 75.4682C58.9496 76.1802 59.5268 76.7574 60.2387 76.7574Z"
        fill="black"
      />
      <path
        d="M60.2387 82.9506H73.399C74.1108 82.9506 74.6881 82.3734 74.6881 81.6614C74.6881 80.9495 74.1108 80.3723 73.399 80.3723H60.2387C59.5268 80.3723 58.9496 80.9495 58.9496 81.6614C58.9496 82.3734 59.5268 82.9506 60.2387 82.9506Z"
        fill="black"
      />
      <path
        d="M47.091 58.7495C46.5875 58.2461 45.7715 58.2461 45.2678 58.7495C44.7644 59.2529 44.7644 60.0691 45.2678 60.5727L47.2438 62.5486C47.7473 63.052 48.5634 63.052 49.067 62.5486L55.33 56.2857C55.8334 55.7823 55.8334 54.966 55.33 54.4626C54.8267 53.9592 54.0107 53.9592 53.5068 54.4626L48.1553 59.814L47.091 58.7495Z"
        fill="black"
      />
      <path
        d="M92.1149 64.5816C91.8149 64.1228 91.2257 63.9098 90.7004 64.0563C90.18 64.2013 89.8005 64.6639 89.7588 65.2025C89.6663 66.3982 91.1884 67.0447 91.9941 66.1676C92.3908 65.7354 92.4401 65.0691 92.1149 64.5816Z"
        fill="black"
      />
      <circle cx="63.5" cy="34.5" r="9.5" fill="black" />
      <path
        d="M63 30.5H64.6908L64.4623 36.6236H63.2056L63 30.5ZM63.3313 38.9542C63.2628 38.9542 63.2056 38.9352 63.1599 38.8971C63.1219 38.859 63.1028 38.8019 63.1028 38.7257V37.5604C63.1028 37.4004 63.179 37.3205 63.3313 37.3205H64.3138C64.4814 37.3205 64.5652 37.4004 64.5652 37.5604V38.7257C64.5652 38.878 64.4814 38.9542 64.3138 38.9542H63.3313Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default NoTestAssertionsSvg;
