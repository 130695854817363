import { SvgIcon } from "@mui/material";

const AddTestSvg = ({ width, height, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox="0 0 10 12" {...props}>
      <path d="M9.28623 2.25465L6.90361 0.112006C6.82502 0.0405711 6.7227 0.000865594 6.61649 0.000588333H1.37986C1.02696 -0.00986185 0.684162 0.119254 0.425841 0.359925C0.167521 0.600596 0.0145092 0.933413 0 1.28618V10.7138C0.0145092 11.0666 0.167521 11.3994 0.425841 11.6401C0.684162 11.8807 1.02696 12.0099 1.37986 11.9994H8.04778C8.40069 12.0099 8.74348 11.8807 9.0018 11.6401C9.26012 11.3994 9.41314 11.0666 9.42765 10.7138V2.57176C9.4275 2.51197 9.41485 2.45286 9.39049 2.39824C9.36614 2.34363 9.33062 2.29472 9.28623 2.25465ZM8.36061 2.57176H6.85647C6.74282 2.57176 6.63382 2.52662 6.55345 2.44625C6.47309 2.36589 6.42794 2.25689 6.42794 2.14323V0.857647H6.45365L8.36061 2.57176ZM8.57059 10.7138C8.55653 10.8393 8.494 10.9544 8.39633 11.0344C8.29867 11.1145 8.1736 11.1532 8.04778 11.1424H1.37986C1.25405 11.1532 1.12898 11.1145 1.03131 11.0344C0.933649 10.9544 0.87112 10.8393 0.857059 10.7138V1.28618C0.87112 1.16068 0.933649 1.04564 1.03131 0.965591C1.12898 0.885538 1.25405 0.846804 1.37986 0.857647H5.57088V2.14323C5.57088 2.48419 5.70633 2.81119 5.94742 3.05228C6.18852 3.29338 6.51551 3.42882 6.85647 3.42882H8.57059V10.7138ZM6.42794 6.42853C6.42794 6.54218 6.38279 6.65118 6.30243 6.73154C6.22206 6.81191 6.11306 6.85706 5.99941 6.85706H5.14235V7.71412C5.14235 7.82777 5.0972 7.93677 5.01684 8.01713C4.93647 8.0975 4.82748 8.14264 4.71382 8.14264C4.60017 8.14264 4.49117 8.0975 4.41081 8.01713C4.33044 7.93677 4.28529 7.82777 4.28529 7.71412V6.85706H3.42823C3.31458 6.85706 3.20558 6.81191 3.12522 6.73154C3.04485 6.65118 2.99971 6.54218 2.99971 6.42853C2.99971 6.31487 3.04485 6.20588 3.12522 6.12551C3.20558 6.04515 3.31458 6 3.42823 6H4.28529V5.14294C4.28529 5.02929 4.33044 4.92029 4.41081 4.83992C4.49117 4.75956 4.60017 4.71441 4.71382 4.71441C4.82748 4.71441 4.93647 4.75956 5.01684 4.83992C5.0972 4.92029 5.14235 5.02929 5.14235 5.14294V6H5.99941C6.11306 6 6.22206 6.04515 6.30243 6.12551C6.38279 6.20588 6.42794 6.31487 6.42794 6.42853Z" />
    </SvgIcon>
  );
};

export default AddTestSvg;
