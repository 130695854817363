import {
  Box,
  Menu,
  Typography,
  IconButton,
  Divider,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  ListItemText,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Flex from '../../../components/base/Flex';
import { useMenu } from '../../../components/base/useMenu';
import { useTheme } from '@emotion/react';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedButton from '../../../components/base/OutlinedButton';
import {
  Badge,
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select
} from '@mui/material/node';
import { useCollaborators } from '../../../store/membersState';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RadioButtonOptions = [
  { value: '', label: 'No status' },
  { value: 'passed', label: 'Passed' },
  { value: 'failed', label: 'Failed' },
  { value: 'draft', label: 'Draft' },
  { value: 'active', label: 'Active' },
  { value: 'retest', label: 'Retest' },
  { value: 'outdated', label: 'Outdated' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'review', label: 'Review' }
];

const SearchFilterMenu = ({ searchQuery, handleSearchChange, setSearchQuery }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(queryParams.get('status') || '');
  const navigate = useNavigate();
  const { collaborators } = useCollaborators();
  const { triggerProps, menuProps } = useMenu();
  const theme = useTheme();

  const showClearAll =
    queryParams.has('users') || queryParams.has('status') || queryParams.has('query');

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    updateUrlQueryParams(event.target.value);
  };

  const handleClearAll = () => {
    queryParams.delete('users');
    queryParams.delete('status');
    queryParams.delete('query');
    setSelectedUsers([]);
    setSelectedStatus('');
    setSearchQuery('');

    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const updateUrlQueryParams = (_selectedStatus) => {
    if (_selectedStatus) {
      queryParams.set('status', _selectedStatus);
    } else {
      queryParams.delete('status');
    }
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const handleUsersChange = (event) => {
    setSelectedUsers(event.target.value);
    updateUsersQueryParams(event.target.value);
  };

  const updateUsersQueryParams = (selected) => {
    const ids = selected.map((user) => user.id);
    if (ids.length > 0) {
      queryParams.set('users', ids.join('-'));
    } else {
      queryParams.delete('users');
    }
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  const renderValue = (selected) => {
    if (selected.length === 0) {
      return <Typography color={'gray'}>Select creators...</Typography>;
    }
    return selected.map((obj) => obj.name).join(', ');
  };

  useEffect(() => {
    const usersString = queryParams.get('users');
    const userIds = usersString?.split('-')?.map(Number);

    if (usersString && !!userIds.length) {
      const filteredCollaborators = collaborators?.filter((obj) => userIds.includes(obj.id));
      setSelectedUsers(filteredCollaborators);
    }
  }, [collaborators]);

  return (
    <Box>
      <Box>
        <Badge color="secondary" variant="standard" badgeContent={!showClearAll ? undefined : ' '}>
          <OutlinedButton
            {...triggerProps}
            startIcon={<FilterListIcon color={theme.palette.svg.primary} />}
            sx={{
              py: { xs: '0.2rem', sm: '0.25rem' },
              px: 2,
              minHeight: '29px',
              borderTopLeftRadius: '0.4rem',
              borderBottomLeftRadius: '0.4rem',
              borderTopRightRadius: showClearAll ? '0rem' : '0.4rem',
              borderBottomRightRadius: showClearAll ? '0rem' : '0.4rem',
              borderRight: showClearAll && 'none',
              '&:hover': {
                borderRight: showClearAll && 'none'
              }
            }}>
            Filters
          </OutlinedButton>
        </Badge>
        {showClearAll && (
          <Tooltip title={'Clear Filters'}>
            <OutlinedButton
              onClick={handleClearAll}
              sx={{
                py: { xs: '0.2rem', sm: '0.25rem' },
                px: 2,
                minHeight: '29px',
                borderTopLeftRadius: '0rem',
                borderBottomLeftRadius: '0rem',
                borderTopRightRadius: '0.4rem',
                borderBottomRightRadius: '0.4rem',
                borderLeft: 'none',
                '&:hover': {
                  borderLeft: 'none'
                }
              }}>
              Clear all
            </OutlinedButton>
          </Tooltip>
        )}
      </Box>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{
          style: {
            width: 400,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <Box sx={{ width: '100%', px: 1, py: 1 }}>
          <Flex sx={{ width: '100%' }}>
            <Typography
              textAlign={'center'}
              color={theme.palette.text.black_grey}
              sx={{ flexGrow: 1, flexShrink: 0, mr: 1 }}
              variant={'body1'}>
              Filter
            </Typography>
            <IconButton sx={{ flexShrink: 0 }} size={'small'} onClick={() => menuProps.onClose()}>
              <CloseIcon sx={{ color: theme.palette.svg.primary }} />
            </IconButton>
          </Flex>

          <Divider sx={{ my: 1 }} />

          <TextField
            id={'search-field'}
            variant={'outlined'}
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={'Search for a test case or folder by name'}
            size={'small'}
            sx={{ my: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.svg.primary }} />
                </InputAdornment>
              )
            }}
          />

          <FormControl fullWidth sx={{ my: 1.5 }}>
            <FormLabel id="demo-radio-buttons-group-label">Filter by Creator</FormLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              size={'small'}
              variant={'outlined'}
              placeholder={'Select creators'}
              MenuProps={{
                elevation: 1
              }}
              value={selectedUsers}
              onChange={handleUsersChange}
              renderValue={renderValue}>
              {collaborators?.map((collaborator) => (
                <MenuItem key={collaborator.id} value={collaborator} sx={{ py: 0 }}>
                  <Checkbox
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    checked={selectedUsers.some((obj) => obj.id === collaborator.id)}
                  />
                  <ListItemText primary={collaborator.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ my: 1.5 }}>
            <FormLabel id="radio-buttons-for-status-filter">Filter by test status</FormLabel>
            <RadioGroup
              aria-labelledby="radio-buttons-for-status-filter"
              value={selectedStatus}
              name="row-radio-buttons-group"
              onChange={handleStatusChange}>
              {RadioButtonOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Menu>
    </Box>
  );
};

export default SearchFilterMenu;
