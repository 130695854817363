import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CustomButton from '../../components/customButton';
import ContainedButton from '../../components/base/ContainedButton';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Logo from '../../components/base/Logo';

function ResetPassword() {
  useDocumentTitle('Scandium - Reset Your Password');
  let navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['email']);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = React.useState(false);
  const [emailError, setEmailError] = useState(null);

  const { mutateAsync, isLoading: showProgress } = useScandiumMutation('/auth/password/email');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      setEmailError('Enter a valid email');
    } else {
      try {
        const data = await mutateAsync({ email });
        setCookie('email', email, {
          path: '/',
          sameSite: 'lax',
          secure: true
        });
        navigate('/verify-otp');
      } catch (error) {
        toast.error(error.status === 422 ? error.message : 'An error occurred, please try again');
      }
    }
  };

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const handleBlur = () => {
    if (emailRegex.test(email)) {
      setEmailError(null);
    }
  };

  return (
    <Box
      sx={{
        mx: { xs: 3.5, sm: 5 }
      }}>
      <Box
        sx={{
          mt: { xs: 1, sm: 1 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Box
          component={'div'}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}>
          <Logo />
        </Box>
        <Typography
          sx={{
            textAlign: 'right',
            typography: { sm: 'h6', md: 'h6' }
          }}>
          Password reset
        </Typography>
      </Box>
      <Box
        sx={{
          mx: { xs: 0, sm: 6 },
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box
          sx={
            {
              // backgroundColor: 'pink'
            }
          }>
          <Typography
            sx={{
              textAlign: 'left',
              typography: { xs: 'h6', md: 'h4' },
              mt: { xs: 25, sm: 20 },
              fontWeight: '700'
            }}>
            Reset password
          </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              typography: { sm: 'caption' },
              color: '#A3A3A3'
              // ml: { xs: 6, sm: -6, },
              // backgroundColor: 'blue'
            }}>
            Enter the email address associated with your account
          </Typography>
          <TextField
            type="email"
            required
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            label="Email"
            onBlur={handleBlur}
            value={email}
            error={emailError !== null}
            helperText={emailError}
            sx={{
              mt: { xs: 6, sm: 4 },
              width: '100%'
            }}
          />
          <ContainedButton
            onClick={handleSubmit}
            disableRipple={true}
            fullWidth
            isLoading={showProgress}
            sx={{
              textAlign: 'center',
              textTransform: 'capitalize',
              py: 1.5,
              mt: { xs: 2, sm: 3 }
            }}>
            Reset Password
          </ContainedButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;
