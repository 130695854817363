import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import CustomModal from '../../../components/base/CustomModal';
import SeleniumSvg from '../../../components/svg/SeleniumSvg';
import PlaywrightSvg from '../../../components/svg/PlaywrightSvg';
import PuppeteerSvg from '../../../components/svg/PuppeteerSvg';
import ScandiumLogoSolid from '../../../components/svg/ScandiumLogoSolid';
import PageLoader from '../../../components/PageLoader';
import TextButton from '../../../components/base/TextButton';
import CopySvg from '../../../components/svg/CopySvg';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

const tabValues = [
  { label: 'Scandium', value: 'scandium', icon: <ScandiumLogoSolid width={22} height={22} /> },
  { label: 'Selenium', value: 'selenium', icon: <SeleniumSvg /> },
  { label: 'Playwright', value: 'playwright', icon: <PlaywrightSvg /> },
  { label: 'Puppeteer', value: 'puppeteer', icon: <PuppeteerSvg /> }
];

const ExportTestModal = ({
  open,
  onClose,
  onComplete,
  activeTab,
  setActiveTab,
  handleConvertTestcase,
  isLoading,
  convertedCodes,
  events
}) => {
  const stringifiedEvents = JSON.stringify(events, null, 2);
  const theme = useTheme();

  const copyTestIdToClipBoard = () => {
    navigator.clipboard.writeText(
      activeTab === 'scandium' ? stringifiedEvents : convertedCodes?.data?.code || ''
    );

    toast.success('copied to clipboard');
  };

  return (
    <CustomModal open={open}>
      <Box
        py={1}
        px={2}
        width={'80vw'}
        maxWidth={'600px'}
        minHeight={'400px'}
        position={'relative'}>
        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          sx={{
            fontSize: '1.4rem'
          }}>
          Test Case Conversion
        </Typography>

        <Typography
          as={'h4'}
          mb={2}
          color={theme.palette.text.black_grey}
          sx={{
            fontSize: '0.9rem'
          }}>
          Your Scandium test case can be converted for use with Selenium, Playwright, or Puppeteer.
          Select and copy the framework you want to use.
        </Typography>

        <Flex
          sx={{ borderBottom: `3px solid ${theme.palette.table.outline}` }}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Tabs
            value={activeTab}
            onChange={async (event, newTab) => {
              await setActiveTab(newTab);
              if (newTab !== 'scandium') {
                handleConvertTestcase();
              }
            }}
            aria-label={'Test cases tabs'}
            indicatorColor={'primary'}
            TabIndicatorProps={{
              sx: { height: 3 }
            }}>
            {tabValues.map((tab, i) => (
              <Tab
                key={i}
                label={
                  <Flex columnGap={2}>
                    {tab.icon}{' '}
                    <Typography variant="body1" color={theme.palette.text.custom}>
                      {tab.label}
                    </Typography>
                  </Flex>
                }
                value={tab.value}
                sx={{ textTransform: 'none', textAlign: 'left' }}
              />
            ))}
          </Tabs>
        </Flex>

        {(convertedCodes?.data?.code || stringifiedEvents) && !isLoading && (
          <SyntaxHighlighter
            language={'javascript'}
            showLineNumbers={true}
            wrapLines={true}
            customStyle={{
              maxHeight: '350px',
              overflowY: 'auto'
            }}
            style={dracula}>
            {activeTab === 'scandium' ? stringifiedEvents : convertedCodes?.data?.code}
          </SyntaxHighlighter>
        )}

        {isLoading && <PageLoader height={'150px'} />}

        <Flex sx={{ flex: 1, pt: 2 }} columnGap={2} justifyContent={'space-between'}>
          <TextButton
            onClick={copyTestIdToClipBoard}
            startIcon={<CopySvg fill={theme.palette.svg.primary} />}>
            Copy
          </TextButton>
          <OutlinedButton onClick={onClose}>Close</OutlinedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default ExportTestModal;
