import { SvgIcon } from "@mui/material";

const UserGuideSvg = ({ width = 40, height = 40 }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox="0 0 11 12">
      <path d="M1.40625 10.125H8.69531C9.47072 10.125 10.1016 9.49415 10.1016 8.71875V1.40625C10.1016 0.630844 9.47072 0 8.69531 0H1.40625C0.630844 0 0 0.630844 0 1.40625V10.5937C0 11.3692 0.630844 12 1.40625 12H8.69531C9.47072 12 10.1016 11.3692 10.1016 10.5937V10.5926C9.70957 10.8875 9.22251 11.0625 8.69531 11.0625H1.40625C1.14778 11.0625 0.9375 10.8522 0.9375 10.5937C0.9375 10.3353 1.14778 10.125 1.40625 10.125ZM0.9375 1.40625C0.9375 1.14778 1.14778 0.9375 1.40625 0.9375H8.69531C8.95378 0.9375 9.16406 1.14778 9.16406 1.40625V8.71875C9.16406 8.97722 8.95378 9.1875 8.69531 9.1875H1.40625C1.24198 9.1875 1.08417 9.21581 0.9375 9.26782V1.40625ZM4.26562 3.95109H3.32812C3.32812 3.4702 3.53423 3.00991 3.89362 2.68819C4.25773 2.36222 4.74752 2.20641 5.23753 2.26083C6.02252 2.34794 6.65463 2.97841 6.74055 3.75998C6.8189 4.47281 6.40087 4.98431 6.07962 5.30705C5.99693 5.39011 5.92062 5.46288 5.85328 5.52712C5.54697 5.81925 5.50795 5.86837 5.50795 6.07022V6.09375H4.57045V6.07019C4.57045 5.45503 4.86511 5.17404 5.20629 4.84866C5.27257 4.78544 5.34112 4.72008 5.41519 4.64566C5.71793 4.34151 5.83561 4.10728 5.8087 3.86241C5.77031 3.51342 5.48665 3.23173 5.13415 3.19261C4.90573 3.16734 4.6871 3.23616 4.51894 3.38672C4.35558 3.53292 4.26562 3.73336 4.26562 3.95109ZM4.57031 6.79687H5.50781V7.73437H4.57031V6.79687Z" />
    </SvgIcon>
  );
};

export default UserGuideSvg;
