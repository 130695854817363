import '@fontsource/exo/300.css';
import '@fontsource/exo/400.css';
import '@fontsource/exo/500.css';
import '@fontsource/exo/700.css';
import '@fontsource/exo/800.css';
import '@fontsource/exo/900.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'rsuite/DateRangePicker/styles/index.css';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ScandiumProvider } from './ScandiumProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import history from './history';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { UserContextProvider } from './store/userState';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { RedirectProvider } from './store/RedirectContext';
import { AppThemeProvider } from './AppTheme';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: 0,
      cacheTime: 5000 * 100,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchIntervalInBackground: false,
      suspense: false
    },
    mutations: {
      retry: 0
    }
  }
});

root.render(
  <React.StrictMode>
    <RedirectProvider>
      <AppThemeProvider>
        {/* <ThemeProvider theme={theme}> */}
        <CookiesProvider>
          <Router history={history}>
            <QueryClientProvider client={queryClient}>
              <UserContextProvider>
                <ScandiumProvider>
                  <CssBaseline />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DndProvider backend={HTML5Backend}>
                      <App />
                    </DndProvider>
                  </LocalizationProvider>
                  <ToastContainer pauseOnFocusLoss={false} position={'top-right'} />
                </ScandiumProvider>
              </UserContextProvider>
            </QueryClientProvider>
          </Router>
        </CookiesProvider>
        {/* </ThemeProvider> */}
      </AppThemeProvider>
    </RedirectProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
