import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FolderIcon from '@mui/icons-material/Folder';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';

export const foldersData = [
  {
    id: 1,
    name: 'Folder 1',
    subfolders: [
      {
        id: 2,
        name: 'Subfolder 1.1',
        subfolders: [],
        testCases: [
          { id: 101, name: 'Test Case 1.1.1' },
          { id: 102, name: 'Test Case 1.1.2' }
        ]
      },
      {
        id: 3,
        name: 'Subfolder 1.2',
        subfolders: [
          {
            id: 5,
            name: 'Subfolder 1.2.1',
            subfolders: [
              {
                id: 6,
                name: 'Subfolder 1.2.1.1',
                subfolders: [],
                testCases: [
                  { id: 107, name: 'Test Case 3.1.1' },
                  { id: 108, name: 'Test Case 3.1.2' }
                ]
              }
            ],
            testCases: [{ id: 109, name: 'Test Case 3.2' }]
          }
        ],
        testCases: []
      }
    ],
    testCases: [
      { id: 103, name: 'Test Case 1.2' },
      { id: 104, name: 'Test Case 1.3' }
    ]
  },
  {
    id: 4,
    name: 'Folder 2',
    subfolders: [],
    testCases: [
      { id: 105, name: 'Test Case 2.1' },
      { id: 106, name: 'Test Case 2.2' }
    ]
  },
  {
    id: 5,
    name: 'Folder 3',
    subfolders: [
      {
        id: 6,
        name: 'Subfolder 3.1',
        subfolders: [],
        testCases: [
          { id: 107, name: 'Test Case 3.1.1' },
          { id: 108, name: 'Test Case 3.1.2' }
        ]
      }
    ],
    testCases: [{ id: 109, name: 'Test Case 3.2' }]
  },
  {
    id: 7,
    name: 'Folder 4',
    subfolders: [],
    testCases: []
  },
  {
    id: 8,
    name: 'Folder 5',
    subfolders: [],
    testCases: [{ id: 110, name: 'Test Case 5.1' }]
  },
  {
    id: 9,
    name: 'Folder 6',
    subfolders: [
      {
        id: 10,
        name: 'Subfolder 6.1',
        subfolders: [],
        testCases: [{ id: 111, name: 'Test Case 6.1.1' }]
      }
    ],
    testCases: [
      { id: 112, name: 'Test Case 6.2' },
      { id: 113, name: 'Test Case 6.3' }
    ]
  }
  // Add more folders and test cases as needed
];

const Folder = ({ data, onSelectFolder, selectedFolder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    onSelectFolder(data);
  };
  console.log(selectedFolder);

  return (
    <Box
      sx={{
        borderBottom: '0.5px solid #ccc',
        borderTop: '0.5px solid #ccc',
        borderRight: '0.5px solid #ccc',
        marginBottom: '4px'
      }}>
      <Box
        sx={{
          backgroundColor:
            selectedFolder?.id === data.id ? 'rgba(36, 195, 217, 0.2)' : 'rgba(36, 195, 217, 0.05)',
          py: '8px',
          pl: '8px',
          pr: '4px',
          borderLeft:
            selectedFolder?.id === data.id
              ? '3px solid #1958b8'
              : isOpen
              ? '2px solid #24C3D9'
              : '',
          cursor: 'pointer',
          userSelect: 'none',
          transition: 'background-color 0.3s ease',
          '&: hover': {
            background: 'rgba(36, 195, 217, 0.2)'
          }
        }}
        onClick={(e) => e.currentTarget.contains(e.target) && handleClick()}>
        <Flex justifyContent={'space-between'} sx={{ maxHeight: 'max-content', minHeight: 0 }}>
          <Typography
            variant={'body2'}
            color={'black'}
            fontWeight={400}
            sx={{
              width: 'inherit',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }}>
            <FolderIcon sx={{ color: '#24C3D9' }} /> {data.name}
          </Typography>
        </Flex>
      </Box>
      {isOpen &&
        data.subfolders.map((subfolder) => (
          <Box key={subfolder.id} pl={2}>
            <Folder
              data={subfolder}
              onSelectFolder={onSelectFolder}
              selectedFolder={selectedFolder}
            />
          </Box>
        ))}
      {isOpen &&
        data.testCases.map((testCase, index) => (
          <Box key={testCase.id} pr={'4px'} pl={2}>
            <Flex
              justifyContent={'space-between'}
              sx={{
                py: 1,
                '&: hover': {
                  background: '#fbfbfb'
                }
              }}>
              <Typography
                variant="body2"
                sx={{
                  width: 'inherit',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}>
                {testCase.name}
              </Typography>
            </Flex>
            {index + 1 < data.testCases.length && <Divider my={1} />}
          </Box>
        ))}
      {isOpen && data.testCases?.length === 0 && data.subfolders?.length === 0 && (
        <Typography textAlign={'center'} variant={'body2'} py={1}>
          This folder is empty
        </Typography>
      )}
    </Box>
  );
};

const SaveRequestModal = ({ open, onClose, onCompleteCreation, title, setTitle }) => {
  const [createNewFolder, setCreateNewFolder] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [folderName, setFolderName] = useState('');
  const [path, setPath] = useState([]);

  const handleFolderClick = (folder) => {
    setPath((prevPath) => [...prevPath, folder]);
  };

  const currentFolder = path.length > 0 ? path[path.length - 1] : null;

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'700px'} height={'85vh'} position={'relative'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Save Request
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            mt: 3
          }}>
          Request name
        </Typography>
        <TextField
          size={'small'}
          fullWidth
          placeholder={'description'}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          inputProps={{
            style: {
              height: '14px',
              outline: 'none'
            }
          }}
        />
        <Typography
          variant={'body2'}
          sx={{
            mt: 5
          }}>
          Save To:{' '}
          <Typography sx={{ ml: 2 }} component={'span'}>
            {selectedFolder?.name || 'Select a folder'}
          </Typography>
        </Typography>
        <TextField
          size={'small'}
          fullWidth
          placeholder={'Search for collection or folder'}
          //   value={title}
          //   onChange={(e) => setTitle(e.target.value)}
          inputProps={{
            style: {
              height: '14px',
              outline: 'none'
            }
          }}
        />

        <Box sx={{ border: '1px solid #e5e5e5', maxHeight: '300px', bgcolor: '#fff' }}>
          {createNewFolder && (
            <Flex sx={{ my: 1 }}>
              <TextField
                size={'small'}
                autoFocus
                fullWidth
                placeholder={'Name your folder'}
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                inputProps={{
                  style: {
                    height: '14px',
                    outline: 'none'
                  }
                }}
              />
              <Flex>
                <Chip
                  label={`Create`}
                  onClick={() => console.log('Cancel clicked')}
                  size={'small'}
                  disabled={!folderName}
                  component={'button'}
                  sx={{
                    borderRadius: '0.4rem',
                    py: '14px',
                    '&:disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'all !important'
                    }
                  }}
                />
                <Chip
                  label={`Cancel`}
                  onClick={() => setCreateNewFolder(null)}
                  size={'small'}
                  sx={{
                    borderRadius: '0.4rem',
                    py: '14px'
                  }}
                />
              </Flex>
            </Flex>
          )}

          <CustomScrollbar
            sx={{
              overflowY: 'auto',
              maxHeight: createNewFolder ? '260px' : '280px',
              minHeight: createNewFolder ? '260px' : '280px',
              mt: 1
            }}>
            {currentFolder ? (
              <Folder
                data={currentFolder}
                onSelectFolder={setSelectedFolder}
                selectedFolder={selectedFolder}
                onClick={handleFolderClick}
              />
            ) : (
              foldersData.map((folder) => (
                <Folder
                  key={folder.id}
                  data={folder}
                  selectedFolder={selectedFolder}
                  onSelectFolder={setSelectedFolder}
                  onClick={handleFolderClick}
                />
              ))
            )}
          </CustomScrollbar>
        </Box>

        <Flex
          columnGap={2}
          justifyContent={'space-between'}
          sx={{ position: 'absolute', bottom: 0, left: 4, right: 4, px: 2, pb: 2, mt: 4 }}>
          <Button onClick={() => setCreateNewFolder(true)} sx={{ textTransform: 'none' }}>
            New Folder
          </Button>
          <Box>
            <ContainedButton
              disabled={!title || !selectedFolder?.id}
              sx={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all !important'
                }
              }}
              // onClick={handleStepVariableCreations}
              color={'primary'}
              autoFocus>
              Save
            </ContainedButton>
            <OutlinedButton sx={{ ml: '1rem' }} onClick={onClose}>
              Cancel
            </OutlinedButton>
          </Box>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default SaveRequestModal;
