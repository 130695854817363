import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import Flex from '../../../components/base/Flex';
import React, { useContext, useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import RestartSvg from '../../../components/svg/ReStartSvg';
import { ColorContext } from '../../../AppTheme';

const Tag = ({ source, setSelectedElement, isSelected, selectedElement }) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const handleElementClick = () => {
    setIsOpen(!isOpen);
    setSelectedElement(source);
  };

  return (
    <Box>
      <Typography
        variant="body1"
        onClick={handleElementClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          cursor: 'pointer',
          color: isSelected ? 'black' : 'inherit',
          bgcolor: isSelected ? '#DEF0FF' : 'inherit'
        }}>
        {isOpen ? (
          <ArrowDropDownIcon
            sx={{ color: isSelected ? 'black' : theme.palette.text.black_white }}
          />
        ) : (
          <ArrowRightIcon sx={{ color: isSelected ? 'black' : theme.palette.text.black_white }} />
        )}
        <span>&lt;{source?.attributes?.class || source?.type || ''}&gt;</span>
      </Typography>

      {isOpen &&
        source?.children?.map((child, i) => (
          <Box pl={1.5} key={i}>
            <Tag
              source={child}
              setSelectedElement={setSelectedElement}
              isSelected={child === selectedElement}
              selectedElement={selectedElement}
            />
          </Box>
        ))}
    </Box>
  );
};

const AppSource = ({
  appSource,
  selectedElement,
  setSelectedElement,
  sessionActive,
  handleInspectToggle
}) => {
  const excludedKeys = ['children', 'bounds', 'attributes'];
  const { mode } = useContext(ColorContext);

  return (
    <Flex sx={{ flex: 'flex-grow', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
      <Box
        sx={{
          bgcolor: mode === 'light' ? '#F9F9F9' : 'rgba(40,40,40)',
          p: 3,
          minHeight: '350px',
          minWidth: '400px'
        }}>
        <Flex>
          <Typography variant="h6">App Source</Typography>
          <Tooltip title={'Reload App source'}>
            <IconButton size="small" disabled={!sessionActive} onClick={handleInspectToggle}>
              <RestartSvg width={15} height={15} />
            </IconButton>
          </Tooltip>
        </Flex>

        <CustomScrollbar sx={{ overflowY: 'auto', minHeight: '350px', maxHeight: '90vh', pr: 1 }}>
          <Box>
            {appSource?.map((source, i) => (
              <Tag
                key={i}
                source={source}
                setSelectedElement={setSelectedElement}
                isSelected={source === selectedElement}
                selectedElement={selectedElement}
              />
            ))}
          </Box>
        </CustomScrollbar>
      </Box>
      <Box
        sx={{
          bgcolor: mode === 'light' ? '#F3FDFF' : 'rgba(40,40,40)',
          p: 3,
          minHeight: '350px',
          minWidth: '400px'
        }}>
        <Typography variant="h6">Selected Element</Typography>
        {selectedElement?.attributes && (
          <Grid container spacing={1} sx={{ flexWrap: 'wrap', mb: 4 }}>
            <Grid item xs={6}>
              <Typography fontWeight={'500'} variant="subtitle1" gutterBottom>
                Find By
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight={'500'} variant="subtitle1" gutterBottom>
                Selector
              </Typography>
            </Grid>
            {Object.entries(selectedElement).map(([key, value]) => (
              <React.Fragment key={key}>
                {!excludedKeys.includes(key) && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
                        {key}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
                        {value}
                      </Typography>
                    </Grid>
                  </>
                )}
              </React.Fragment>
            ))}
          </Grid>
        )}

        {selectedElement?.attributes && (
          <Grid container spacing={1} sx={{ flexWrap: 'wrap' }}>
            <Grid item xs={6}>
              <Typography fontWeight={'500'} variant="subtitle1" gutterBottom>
                Attribute
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight={'500'} variant="subtitle1" gutterBottom>
                Value
              </Typography>
            </Grid>
            {Object.entries(selectedElement?.attributes).map(([key, value]) => (
              <React.Fragment key={key}>
                <Grid item xs={6}>
                  <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
                    {key}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
                    {value}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Box>
    </Flex>
  );
};

export default AppSource;
