import Flex from '../../../../components/base/Flex';
import TextButton from '../../../../components/base/TextButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import ExternalLinkSvg from '../../../../components/svg/ExternalLinkSvg';
import { useMenu } from '../../../../components/base/useMenu';
import { v4 } from 'uuid';

const OpenUrl = ({
  orientation,
  session,
  theme,
  isRecordingMode,
  setUnsaved,
  setActions,
  insertEventsAtRef
}) => {
  const { triggerProps, menuProps } = useMenu();
  const [url, setUrl] = useState('');

  const handleEnterKeyPress = async () => {
    const data = {
      type: 'openUrl',
      url,
      id: v4(),
      timeout: 15000,
      step_delay: 1000
    };

    try {
      await session.openUrl(url);

      if (insertEventsAtRef.current !== null) {
        const insertIndex = insertEventsAtRef.current + 1;
        setActions((prevActions) => [
          ...prevActions.slice(0, insertIndex),
          { ...data },
          ...prevActions.slice(insertIndex)
        ]);
        insertEventsAtRef.current = insertIndex;
      } else {
        setActions((prevActions) => [...prevActions, { ...data }]);
      }

      setUnsaved(true);
    } catch (e) {
      console.error('Failed to open url', e);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && url) {
      handleEnterKeyPress();
      setUrl('')
      menuProps.onClose();
    }
  };

  const onUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const anchorOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'right' }
      : { vertical: 'bottom', horizontal: 'left' };

  const transformOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'left' }
      : { vertical: 'top', horizontal: 'left' };

  return (
    <Box>
      <TextButton
        sx={{ py: 1 }}
        {...triggerProps}
        disabled={!session || !isRecordingMode}
        aria-controls="simple-menu"
        aria-haspopup="true">
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <ExternalLinkSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Open URL
          </Typography>
        </Flex>
      </TextButton>
      <Menu
        id="simple-menu"
        {...menuProps}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={1}
        PaperProps={{
          style: {
            width: 250
          }
        }}>
        <Box px={2}>
          <TextField
            fullWidth
            variant={'standard'}
            value={url}
            onChange={onUrlChange}
            onKeyDown={handleKeyPress}
            InputProps={{
              disableUnderline: true
            }}
            placeholder={'Enter URL'}
            size={'small'}
          />
        </Box>
      </Menu>
    </Box>
  );
};

export default OpenUrl;
