import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import FeatureFlagsContext from '../store/featureFlagsState';

const useFeatureEnabled = (featureSlug) => {
  const [cookies] = useCookies(['local_features', 'designation', 'memberships', 'projectType']);
  const { featureFlags } = useContext(FeatureFlagsContext);

  const isFeatureLocallyEnabled = cookies?.local_features?.some(
    (feature) => feature.slug === featureSlug
  );

  const isFeatureEnabledGlobally = featureFlags?.some(
    (feature) => feature.slug === featureSlug && feature.enabled
  );

  const enterpriseFeatures = cookies?.designation?.features?.some(
    (feature) => feature.slug === featureSlug
  );

  const isFeatureEnabled =
    isFeatureLocallyEnabled ||
    enterpriseFeatures ||
    isFeatureEnabledGlobally;

  return { isFeatureEnabled };
};

export default useFeatureEnabled;
