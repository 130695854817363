import Button from '@mui/material/Button';
import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@emotion/react';

const TextButton = ({ children, isLoading, sx, fullWidth, onClick, loadingProps, ...props }) => {
  const theme = useTheme();
  return (
    <Button
      variant={'text'}
      sx={{
        borderRadius: '6px',
        minWidth: '80px',
        minHeight: '36px',
        width: fullWidth ? '100%' : 'auto',
        textTransform: { xs: 'none' },
        fontWeight: { xs: 500 },
        border: 'none',
        color: theme.palette.button.textOutlined,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          ...(sx?.['&:hover'] || {})
        },
        ...sx
      }}
      onClick={isLoading ? undefined : onClick}
      {...props}>
      {isLoading ? <CircularProgress size={24} color={'inherit'} {...loadingProps} /> : children}
    </Button>
  );
};

export default TextButton;
