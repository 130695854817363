import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useRedirect } from '../store/RedirectContext';

const useLogout = () => {
  const [_, setCookie] = useCookies(['token', 'email']);
  let navigate = useNavigate();
  const { setRedirectPath } = useRedirect();

  return () => {
    setCookie('token', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('email', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('name', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('user', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('local_features', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('active_company', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('company', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('designation', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('role', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('roles', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('memberships', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setCookie('projectType', '', {
      path: '/',
      expires: new Date(0),
      sameSite: 'lax'
    });
    setRedirectPath('/dashboard')
    navigate('/');
  };
};

export default useLogout;
