import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Chip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useMenu } from '../../../components/base/useMenu';
import { toast } from 'react-toastify';
import { useActiveProject } from '../../../store/projectState';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';

const ActionMenu = React.forwardRef(({ onClickCreateTag }, ref) => {
  const { isFeatureEnabled: enabledRemoteExecutionFeature } = useFeatureEnabled('remote-execution');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const activeProject = useActiveProject();
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const readyOnly = activeProject?.read_only;

  return (
    <div>
      <IconButton
        ref={ref}
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVert color={'primary'} />
      </IconButton>
      <Menu
        id="action-menu"
        elevation={1}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          style: {
            minWidth: 150
          }
        }}>
        <MenuItem onClick={onClickCreateTag}>Tag test failure</MenuItem>
      </Menu>
    </div>
  );
});

export default ActionMenu;
