import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/Container';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell, StyledTableRow } from './Settings';
import { useActiveProject } from '../../store/projectState';
import { useScandiumQuery } from '../../data-layer/utils';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import ErrorState from '../../components/base/ErrorState';
import PageLoader from '../../components/PageLoader';
import Flex from '../../components/base/Flex';
import ProjectSvg from '../../components/svg/ProjectSvg';
import { formatDate } from '../../utils/formateDate';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const ProjectRow = ({ project }) => {
  const theme = useTheme();
  return (
    <StyledTableRow
      tabIndex={0}
      role={'link'}
      sx={{ textDecoration: 'none' }}
      component={Link}
      to={`/projects/${project.id}/dashboard`}>
      <StyledTableCell component="th" scope="row">
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '.8rem' }}
          className={'cell-content-wrapper'}>
          <ProjectSvg fill={theme.palette.svg.main} width={24} height={24} color={'primary'} />
          <Flex justifyContent={'flex-start'} flexDirection={'column'} alignItems={'normal'}>
            <Typography
              variant={'body2'}
              sx={{
                textAlign: 'left',
                fontWeight: 500
              }}>
              {project.name}
            </Typography>
          </Flex>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Box className={'cell-content-wrapper'}>{project.creator.name}</Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Box className={'cell-content-wrapper'}>{project.test_cases_count}</Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Box className={'cell-content-wrapper'}>{formatDate(project.created_at)}</Box>
      </StyledTableCell>
      <StyledTableCell align="center">
        <Box className={'cell-content-wrapper'}>{project.collaborators_count}</Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const CompanyProjects = () => {
  useDocumentTitle('Organization Projects');
  const activeProject = useActiveProject();

  const {
    data: projects,
    isLoading: isLoadingProjects,
    error: projectsError
  } = useScandiumQuery(`/company/projects`, {
    enabled: !!activeProject?.id,
    select: (data) => data.data
  });

  return (
    <Box>
      <Typography variant={'h6'} color={'primary'} mb={5}>
        Company's projects
      </Typography>

      <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
        <Table sx={{ width: '100%', minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align={'left'}>Name</StyledTableCell>
              <StyledTableCell align={'left'}>Created By</StyledTableCell>
              <StyledTableCell align={'left'}>Test cases</StyledTableCell>
              <StyledTableCell align={'left'}>Created On</StyledTableCell>
              <StyledTableCell align={'left'}>Collaborators</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects?.map((project) => (
              <ProjectRow key={project.id} project={project} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoadingProjects && <PageLoader height={'100px'} />}
      {!isLoadingProjects && projectsError && !projects?.length && (
        <ErrorState error={projectsError} />
      )}
    </Box>
  );
};

export default CompanyProjects;
