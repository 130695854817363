import React, { useContext } from 'react';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import ProjectsActionMenu from './ProjectsActionMenu';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useDeleteProject } from '../../../data-layer/project-management';
import { Link } from 'react-router-dom';
import UserContext from '../../../store/userState';

const ProjectCard = ({ project, fetchProjects }) => {
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const { userData } = useContext(UserContext);

  const { mutateAsync: deleteProject, isLoading: isDeletingProject } = useDeleteProject({
    onSuccess: (data) => {
      toast.success(data.message);
      fetchProjects();
    },
    onError: (data) => {
      toast.error(data.message);
    }
  });

  const handleDeleteProject = async () => {
    if (await requestConfirm()) return await deleteProject(project.id);
  };

  return (
    <Typography
      component="div"
      sx={{
        position: 'relative',
        px: { xs: 2, sm: 2 },
        mr: { xs: 2, sm: 2 },
        mb: { xs: 2, sm: 2 },
        width: { xs: '15.6rem', sm: '360px' },
        height: { xs: '3rem', sm: '64px' },
        backgroundColor: 'rgba(36, 195, 217, 0.16)',
        borderRadius: '0.3rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <Tooltip title="Set this as the active project">
        <Box
          as={Link}
          to={`/projects/${project.id}/dashboard`}
          sx={{
            textDecoration: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            color: 'inherit',
            overflow: 'hidden'
          }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '1rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}>
            {project.name}
          </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '0.7rem',
              opacity: 0.7
            }}>
            {project.folders_count} Folders | {project.test_cases_count} Tests
          </Typography>
        </Box>
      </Tooltip>
      <Typography style={{ display: 'flex' }}>
        <ProjectsActionMenu
          projectId={project.id}
          isLoading={isDeletingProject}
          onDelete={handleDeleteProject}
          project={project}
          userData={userData}
        />
      </Typography>
      {project.is_default === 1 && (
        <Typography
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: { xs: '3rem', sm: '3rem' },
            backgroundColor: '#1958B8',
            color: '#fff',
            textAlign: 'center',
            fontSize: '0.5rem',
            borderTopLeftRadius: 5,
            borderBottomRightRadius: 5
          }}>
          Default
        </Typography>
      )}
      <ConfirmationDialog
        title={'Are you sure you want to delete this projects?'}
        description={
          'Deleting a project will permanently remove all associated data, files, and configurations. This action cannot be undone!'
        }
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </Typography>
  );
};

export default ProjectCard;
