import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Box, Menu, Grid, Typography } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { formatDateFromTimestamp } from '../../TestPage/components/TestDetails';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { ColorContext } from '../../../AppTheme';

const TestDetails = ({ activeFolder, name, appetizeClient, testResponse }) => {
  const { triggerProps, menuProps } = useMenu();
  const theme = useTheme();
  const { mode } = useContext(ColorContext)

  return (
    <div>
      <IconButton
        aria-controls={menuProps.open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuProps.open ? 'true' : undefined}
        {...triggerProps}>
        <MoreVertIcon sx={{ color: theme.palette.svg.primary }} />
      </IconButton>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: 300
          },
          sx: {
            bgcolor: mode === 'light' && '#F6F6F6',
            py: 0
          }
        }}>
        <Box px={2} py={1} sx={{ bgcolor: '', my: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="body2">App name:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                {appetizeClient?.app?.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Folder name:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                {activeFolder?.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Test case name:</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Created By</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                textTransform={'capitalize'}
                fontWeight={'medium'}
                variant="body2"
                sx={{ wordWrap: 'break-word' }}>
                {testResponse?.data?.creator?.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Date Created</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                {formatDateFromTimestamp(testResponse?.data?.created_at)}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Last Modified</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                {formatDateFromTimestamp(testResponse?.data?.updated_at)}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">Last Run</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontWeight={'medium'} variant="body2" sx={{ wordWrap: 'break-word' }}>
                {formatDateFromTimestamp(testResponse?.data?.last_run_at)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </div>
  );
};

export default TestDetails;
