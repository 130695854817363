import Flex from '../../../../components/base/Flex';
import React, { useContext, useEffect } from 'react';
import { DateRangePicker } from 'rsuite';
import AddIcon from '@mui/icons-material/Add';
import { useScandiumMutation, useScandiumQuery } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import ReplayIcon from '@mui/icons-material/Replay';

import { Box, Button, Chip, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SuitesList from './SuitesList';
import { useLocation, useSearchParams } from 'react-router-dom';
import { TestAutomationContext } from '../../../../store/testAutomationState';
import Sidebar from '../../../../components/sidebar';
import EmptyState from '../../../../components/base/EmptyState';
import FilterForSuitesAndRuns from './FilterForSuitesAndRuns';
import Pagination from '@mui/material/Pagination';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { TestCaseContext } from '../../../../store/testState';
import { Close } from '@mui/icons-material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useConfirmDialog } from '../../../../components/base/ConfirmDialog';
import DeleteSvg from '../../../../components/svg/DeleteSvg';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { useState } from 'react';
import { Ranges } from '../../../Reports/Reports';
import { useTheme } from '@emotion/react';

export const TestAutomationSuites = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const activeProject = useActiveProject();
  const { massSelection, setMassSelection } = useContext(TestCaseContext);
  const {
    setActiveSuite,
    automationSuites,
    setAutomationSuites,
    setInitialSuiteState,
    setActiveTab,
    suiteOrRunQueryParams,
    setSuiteOrRunQueryParams
  } = useContext(TestAutomationContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState([]);
  const {
    data: allSuites,
    isFetching: isFetchingSuites,
    isSuccess: suitesFetched,
    isError: errorFetchingSuites,
    refetch: refetchSuites,
    isRefetching
  } = useScandiumQuery(`/projects/${activeProject?.id}/api-suites`, {
    queryKey: ['api-suites', suiteOrRunQueryParams.limit, suiteOrRunQueryParams.page, dateRange],
    params: {
      page: suiteOrRunQueryParams.page,
      limit: suiteOrRunQueryParams.limit,
      date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
      date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
    },
    onSuccess: (data) => {
      const suitesList = data.data.data;
      setAutomationSuites(suitesList);
    },
    enabled: !!activeProject?.id
  });
  const { mutateAsync: massDelete, isLoading: isMassDeleting } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/multi-delete`,
    {
      method: 'DELETE',
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['api-suites'] });
        toast.success(`${data.message}`, { autoClose: 800 });
        setMassSelection({
          active: false,
          selectedApiTests: [],
          selectedApiTestExamples: [],
          selectedFolders: [],
          selectedSuites: []
        });
      },
      onError: (error) => {
        toast.error(error?.message, {
          autoClose: 800
        });
      }
    }
  );
  useEffect(() => {
    setSuiteOrRunQueryParams((prev) => {
      return {
        ...prev,

        limit: '',
        status: '',
        date_from: '',
        date_to: format(new Date(), 'yyyy-MM-dd'),
        showFilter: false
      };
    });
  }, []);
  const { requestConfirm: confirmSuitesDeletion, ConfirmationDialog: DeleteSelectedApiSuites } =
    useConfirmDialog();
  const paginate = allSuites?.data?.meta;
  const handleResultLimit = (value) => {
    setSuiteOrRunQueryParams((prev) => {
      return { ...prev, page: '1', limit: value.toString() };
    });
  };
  const resultBatchOptions =
    allSuites?.data?.meta?.total > 15 && Math.ceil(Number(allSuites?.data?.meta.total) / 5);

  const handlePageChange = (e, value) => {
    setSuiteOrRunQueryParams((prev) => {
      return { ...prev, page: value.toString() };
    });
  };

  const { afterToday } = DateRangePicker;

  const handleChangeDatePicker = (newValue) => {
    setDateRange(newValue);

    if (newValue?.length === 2) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        date_from: newValue[0].toISOString().split('T')[0],
        date_to: newValue[1].toISOString().split('T')[0]
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('date_from');
      newParams.delete('date_to');
      setSearchParams(newParams);
      setDateRange(null);
    }
  };

  useEffect(() => {
    const dateFrom = searchParams.get('date_from');
    const dateTo = searchParams.get('date_to');

    if (dateFrom && dateTo) {
      setDateRange([new Date(dateFrom), new Date(dateTo)]);
    }
  }, []);

  return (
    <Flex
      sx={{
        width: '100%',
        mt: 1.5,
        mb: 3,
        minHeight: '80vh',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
        // columnGap: 3
        // outline: '1px solid black'
      }}>
      {/* <Stack gap={50} width="200px" sx={{ height: 'inherit' }}>
        <Sidebar height={'500px'} SidebarItems={[]} />{' '}
      </Stack> */}
      {!!isFetchingSuites && !isRefetching && <LoadingComponent />}
      {suitesFetched && (
        <Stack
          gap={2}
          sx={{
            flex: 1
          }}>
          <Flex>
            {' '}
            <Typography color={'primary'} fontSize={18} fontWeight={500}>
              API Suites
            </Typography>
            <Flex sx={{ ml: 'auto', columnGap: 1 }}>
              {' '}
              {automationSuites.length > 0 && (
                <Flex sx={{ gap: '10px' }}>
                  <IconButton
                    sx={{
                      display: !massSelection.selectedSuites.length ? 'none' : ''
                    }}
                    onClick={() => {
                      confirmSuitesDeletion();
                    }}>
                    <DeleteSvg width={'18px'} height={'18px'} />
                  </IconButton>
                  <Tooltip title={!massSelection.active ? 'select multiple' : ''}>
                    <IconButton size={'small'} sx={{ borderRadius: '16px' }}>
                      {!massSelection.active ? (
                        <PlaylistAddCheckIcon
                          onClick={() => {
                            setMassSelection((prev) => {
                              return { ...prev, active: true };
                            });
                          }}
                          color="primary"
                          fontSize="large"
                        />
                      ) : (
                        <Close
                          onClick={() => {
                            setMassSelection({
                              active: false,
                              selectedApiTests: [],
                              selectedApiTestExamples: [],
                              selectedFolders: [],
                              selectedSuites: []
                            });
                          }}
                          sx={{
                            color: 'rgba(100,100,100, 0.7)',

                            '&:hover': {
                              color: 'rgba(100,100,100)'
                            }
                          }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                  <DateRangePicker
                    shouldDisableDate={afterToday()}
                    appearance={'default'}
                    style={{ width: 210, marginRight: '10px' }}
                    value={dateRange}
                    onChange={handleChangeDatePicker}
                    placement={'bottom'}
                    ranges={Ranges}
                    size={'sm'}
                    placeholder={'Filter by Date Range'}
                  />
                </Flex>
              )}
              <Chip
                label={`Create New`}
                disabled={!!massSelection.active}
                onClick={() => {
                  setActiveTab(0);
                  setActiveSuite({ name: '', description: '', test_cases: [] });
                  navigate(`${pathname}/new`);
                }}
                size={'small'}
                color={'primary'}
                icon={<AddIcon />}
                sx={{
                  fontSize: 12,
                  borderRadius: '0.3rem',
                  px: 0.2,
                  py: 2,
                  ml: 'auto'
                }}
              />
            </Flex>
          </Flex>

          {automationSuites.length > 0 && !isFetchingSuites && (
            <Stack gap={4}>
              <SuitesList />

              {paginate.total > 15 && (
                <Flex>
                  <Pagination
                    count={paginate?.last_page}
                    page={Number(suiteOrRunQueryParams.page) || 1}
                    color="secondary"
                    onChange={handlePageChange}
                    size={'small'}
                    sx={{
                      '& .Mui-selected': {
                        backgroundColor: '#24C3D9',
                        color: '#ffffff'
                      },
                      '& .MuiPaginationItem-root:not(.Mui-selected)': {
                        backgroundColor: '#1958B8',
                        color: '#fff'
                      }
                    }}
                  />

                  <FormControl sx={{ ml: 'auto', mr: 8 }}>
                    <InputLabel id="go-to-page-label-api">limit</InputLabel>
                    <Select
                      labelId={'go-to-page-label-api'}
                      value={suiteOrRunQueryParams?.limit || '15'}
                      label={'Suites per page'}
                      size={'small'}
                      MenuProps={{
                        elevation: 1
                      }}
                      onChange={(e) => {
                        handleResultLimit(e.target.value);
                      }}>
                      {Array.from(Array(resultBatchOptions), (_, index) => (index + 1) * 5).map(
                        (number) => {
                          return <MenuItem value={number}>{number.toString()}</MenuItem>;
                        }
                      )}
                    </Select>
                  </FormControl>
                </Flex>
              )}
            </Stack>
          )}

          {!!isRefetching && <LoadingComponent />}
          {!automationSuites.length &&
            !isFetchingSuites &&
            (!dateRange?.[0] || !dateRange?.[1]) && (
              <EmptyState
                title="You don't have any Test Suite"
                description="Why not create a test suite now"
              />
            )}
          {!automationSuites.length && !isFetchingSuites && !!dateRange?.[0] && !!dateRange?.[1] && (
            <Stack alignItems={'center'}>
              <EmptyState
                title="Result not  found"
                description="No result matches your filter option"
              />

              <Chip
                variant="outlined"
                color="primary"
                label={`Back to Suites`}
                onClick={async () => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete('date_from');
                  newParams.delete('date_to');
                  setSearchParams(newParams);
                  setDateRange(null);
                  refetchSuites();
                }}
                size={'small'}
                sx={{
                  width: 100,
                  borderRadius: '0.3rem',
                  px: 0.5,

                  py: 2,
                  mt: -4
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
      {errorFetchingSuites && (
        <Box
          sx={{
            ml: '40%',
            alignSelf: 'center',
            justifySelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center'
          }}>
          <Typography fontWeight={1}>Error while fetching suites</Typography>
          <Button
            onClick={() => {
              refetchSuites();
            }}
            variant="outlined"
            startIcon={<ReplayIcon />}
            size="large"
            sx={{
              width: 'fit-content'
            }}>
            Retry
          </Button>
        </Box>
      )}
      <DeleteSelectedApiSuites
        runRequest={async () => {
          return massDelete({
            suites: [...massSelection.selectedSuites]
          });
        }}
        runningRequest={isMassDeleting}
        // requestSuccessful={deleteSuccessful}
        title={`Are you sure you want to proceed`}
        description={'This process is irreversible'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </Flex>
  );
};

export const LoadingComponent = ({ length, height }) => {
  const theme = useTheme();
  const arrayOfNumbers = Array.from({ length: length || 4 }, (_, index) => index + 1);
  return (
    <Stack gap={1.2} sx={{ width: '100%' }}>
      {arrayOfNumbers.map((num) => {
        return (
          <Skeleton
            key={num}
            animation="wave"
            variant="rectangular"
            width={'100%'}
            height={height || 70}
            sx={{ borderRadius: 2, bgcolor: theme.palette.hover }}
          />
        );
      })}
    </Stack>
  );
};
