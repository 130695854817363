import {
    styled,
    TableCell,
    tableCellClasses,
    TableRow,
  } from '@mui/material';
  
  export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'transparent',
      color: 'inherit',
      borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
      '&:not(:first-of-type)': {
        maxWidth: '200px'
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      cursor: 'pointer',
      borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
      padding: '8px 0px',
      '&:not(:first-of-type)': {
        maxWidth: '200px'
      },
      '&:hover': {
        bgcolor: '#F7F7F7'
      },
      '.cell-content-wrapper': {
        padding: '16px',
        backgroundColor: theme.palette.background.suiteList,
        // color: '#787878',
        fontSize: '0.8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: '64px',
        display: 'flex',
        alignItems: 'center'
      },
  
      '&:first-child': {
        minWidth: '250px',
        '.cell-content-wrapper': {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px'
        }
      },
  
      '&:last-child': {
        '.cell-content-wrapper': {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px'
        }
      }
    }
  }));
  
  export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
      border: 2
    }
  }));
  
  // testCases row
  export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'transparent',
      color: 'inherit',
      borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
      '&:not(:first-of-type)': {
        maxWidth: '200px'
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      cursor: 'move',
      borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
      padding: '8px 0px',
      '&:not(:first-of-type)': {
        maxWidth: '200px'
      },
      '.cell-content-wrapper': {
        padding: '16px',
        backgroundColor: theme.palette.background.testCaseList,
        color: theme.palette.text.resultTableText,
        fontSize: '0.8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: '64px',
        display: 'flex',
        alignItems: 'center'
      },
  
      '&:first-child': {
        minWidth: '250px',
        '.cell-content-wrapper': {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px'
        }
      },
  
      '&:last-child': {
        '.cell-content-wrapper': {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px'
        }
      }
    }
  }));
  
  export const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
      border: 2
    }
  }));
  