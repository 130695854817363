import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, Outlet } from 'react-router-dom';
import { Typography } from '@mui/material';
import useLogout from '../hooks/useLogout';
import { useScandiumMutation } from '../data-layer/utils';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { ColorContext } from '../AppTheme';
import { resetMixpanelData } from '../mixpanel.constants';

export const nonActiveClassName = {
  color: '#111111',
  fontWeight: 400,
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderLeft: '2px solid',
  borderLeftColor: '#EBEBEB'
};

export const activeClassName = {
  color: '#1958B8',
  textDecoration: 'none',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  fontWeight: 500,
  borderLeft: '2px solid #1958B8',
  alignItems: 'center'
};

const Sidebar = ({ SidebarItems = [], height, customStyle = false }) => {
  const { mode } = React.useContext(ColorContext);
  const logout = useLogout();
  const queryClient = useQueryClient();
  const { mutateAsync: apiLogout } = useScandiumMutation('/auth/logout', {
    method: 'GET'
  });

  const _activeClassName = {
    color: '#1958B8',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 500,
    borderLeft: `2px solid #1958B8`,
    alignItems: 'center'
  };

  const _nonActiveClassName = {
    color: mode === 'dark' ? '#ffffffb3' : '#111111',
    fontWeight: 400,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderLeft: '2px solid',
    borderLeftColor: '#EBEBEB'
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await apiLogout();
      logout();
      queryClient.clear();
      resetMixpanelData()
    } catch (error) {
      toast.error('An error occurred, please try again');
    }
  };

  return (
    <Box
      sx={{
        marginTop: !customStyle && { xs: '3.5rem', sm: '10rem' },
        mx: !customStyle && { xs: '1.5rem', sm: '2rem', md: '4rem' },
        color: 'inherit'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'column' },
          justifyContent: 'flex-end',
          alignItems: { xs: 'center', sm: 'flex-start' },
          height: { xs: 'auto', sm: '65vh' },
          width: { xs: '100%', sm: '12rem' },
          position: !customStyle && { xs: 'static', sm: 'fixed' }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: { xs: 'flex-start', sm: 'center' },
            alignItems: { xs: 'center', sm: 'flex-start' },
            width: { xs: '100%', sm: 'auto' },
            mb: 12
          }}>
          {SidebarItems.map((item, itemIndex) => (
            <NavLink
              key={item.name + item.path}
              to={{
                pathname: item.path,
                search: item.search || ''
              }}
              end
              style={({ isActive }) => (isActive ? _activeClassName : _nonActiveClassName)}>
              <Button
                sx={{
                  typography: 'body2',
                  textTransform: 'capitalize',
                  color: 'inherit'
                }}>
                {item.name}
              </Button>
              {SidebarItems.length !== itemIndex + 1 && (
                <Box
                  sx={{
                    display: { xs: 'flex', sm: 'none' },
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  &#9474;
                </Box>
              )}
            </NavLink>
          ))}
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}>
          <Typography
            sx={{
              textAlign: 'right',
              typography: { sm: 'subtitle2', md: 'subtitle2' },
              py: 2
            }}>
            Need Help?
          </Typography>
          <Box target={'_blank'} as={'a'} href={'https://getscandium.com/faqs/'}>
            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textAlign: 'right',
                typography: { sm: 'caption', md: 'caption' },
                py: 1,
                color: '#6A6A6A'
              }}>
              See popular questions
            </Typography>
          </Box>
          <Typography
            color="error"
            sx={{
              cursor: 'pointer',
              align: 'right',
              typography: { sm: 'subtitle2', md: 'subtitle2' },
              py: 1
            }}
            onClick={handleLogout}>
            Logout
          </Typography>
        </Box>
      </Box>
      <Box className="mainBlock">
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
