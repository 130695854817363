import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mui/material';

const DraggableCard = React.memo(
  ({ id, index, moveCard, findCardIndex, onDragEnd, children, type, ...props }) => {
    const [, drag] = useDrag(
      () => ({
        type,
        item: { id, index },
        end: (item, monitor) => {
          // If !monitor.didDrop, reset to originalIndex
          if (index === 0) return;
          if (monitor.getDropResult()) {
            const { dragId, dropIndex } = monitor.getDropResult();
            onDragEnd(dragId, dropIndex);
          }
        }
      }),
      [id, index, onDragEnd]
    );

    const [, drop] = useDrop(
      () => ({
        accept: type,
        hover(item, monitor) {
          const hoverIndex = findCardIndex(id);
          if (hoverIndex === 0) return;

          const dragIndex = findCardIndex(item.id);

          // We don't want to replace the first step
          if (hoverIndex < 0 || !monitor.canDrop()) return;

          moveCard(dragIndex, hoverIndex);
        },
        drop(item, monitor) {
          return { dragId: item.id, dropIndex: findCardIndex(id) };
        }
      }),
      [id, index, moveCard]
    );

    return (
      <Box
        ref={(node) => drag(drop(node))}
        style={{ cursor: type === 'moveable' ? 'move' : 'disabled' }}
        {...props}>
        {children}
      </Box>
    );
  }
);

export default DraggableCard;
