import { SvgIcon } from '@mui/material';

const PassedSvg = ({ width = 20, height = 20, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 15 15'} {...props} fill={'none'}>
      <g clipPath="url(#clip0_1735_206)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.83748 0.907227C3.97111 0.907227 0.827637 4.0507 0.827637 7.91707C0.827637 11.7834 3.97111 14.9269 7.83748 14.9269C11.7038 14.9269 14.8473 11.7834 14.8473 7.91707C14.8473 4.0507 11.7038 0.907227 7.83748 0.907227Z"
          fill="#00CA08"
        />
        <path
          d="M6.3111 11.1233C5.4157 10.2279 4.52578 9.32425 3.62764 8.42886C3.52907 8.33028 3.52907 8.16599 3.62764 8.06741L4.65995 7.0351C4.75853 6.93653 4.92282 6.93653 5.0214 7.0351L6.4973 8.511L10.2925 4.71309C10.3938 4.61452 10.5553 4.61452 10.6567 4.71309L11.6917 5.74814C11.793 5.84945 11.793 6.01101 11.6917 6.10958L6.67254 11.1233C6.57397 11.2246 6.41241 11.2246 6.3111 11.1233Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1735_206">
          <rect
            width="14.0197"
            height="14.0197"
            fill="white"
            transform="translate(0.827637 0.907227)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PassedSvg;
