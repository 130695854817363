import { useNavigate, useLocation } from 'react-router-dom';
import BackButton from '../../components/base/BackButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Flex from '../../components/base/Flex';
import { Checkbox, Chip, Divider, FormControlLabel, Switch, Tab, Tabs } from '@mui/material';
import EmailNotificationSvg from '../../components/svg/EmailNotificationSvg';
import WebhookSvg from '../../components/svg/WebhookSvg';
import SlackSvg from '../../components/svg/SlackSvg';
import JiraSvg from '../../components/svg/JiraSvg';
import TrelloSvg from '../../components/svg/TrelloSvg';
import GithubSvg from '../../components/svg/GithubSvg';
import { useCookies } from 'react-cookie';
import EmptyState from '../../components/base/EmptyState';
import ContainedButton from '../../components/base/ContainedButton';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import { useActiveProject } from '../../store/projectState';
import useAwaitModal from '../../hooks/useAwaitModal';
import MultiInputModal from './components/MultiInputModal';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import TextButton from '../../components/base/TextButton';
import JiraConnectModal from './components/JiraIConnectModal';
import SlackConnectModal from './components/SlackConnectModal';
import TrelloConnectModal from './components/TrelloConnectModal';
import GithubConnectModal from './components/GithubConnectModal';
import useUpdateChannelData from '../../hooks/useUpdateChannelData';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { useContext } from 'react';
import UserContext from '../../store/userState';
import ClickUpSvg from '../../components/svg/ClickupSvg';
import ClickUpConnectModal from './components/ClickUpConnectModal';
import { ColorContext } from '../../AppTheme';

const TabPanel = ({ children, value, index }) => {
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const NotificationCard = ({
  icon,
  buttonLabel,
  onClick,
  description,
  data,
  disconnect,
  isSuccessNotificationEnabled,
  setEnabledSuccessNotification,
  notificationOptions,
  index,
  status,
  updateStatus,
  updateArrayData,
  updateObjectData,
  channels
}) => {
  const handleStatusChange = (e) => {
    updateStatus(e.target.checked === true ? 'active' : 'inactive');
  };

  const { mode } = useContext(ColorContext)

  const cardBackground = mode === 'light' ? '#F6F6F6' : 'rgba(40,40,40)'

  const showDisconnect =
    ['trello', 'jira', 'github', 'clickup'].includes(channels[index]?.channel) &&
    Object.keys(channels[index]?.channel_details.data).length > 0;

  return (
    <Box>
      <Flex
        columnGap={0}
        alignItems={'flex-start'}
        sx={{
          height: 'auto',
          width: { xs: '100%', sm: '100%', md: '100%' },
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          bgcolor: cardBackground
        }}>
        <FormControlLabel
          sx={{ mt: 1, flex: '5%', pl: 1 }}
          control={<Checkbox checked={status === 'active'} onChange={handleStatusChange} />}
        />
        <Flex sx={{ py: 2, px: 2, alignItems: 'flex-start', flex: '80%', pr: 4 }}>
          {icon}
          <Flex
            flexDirection={'column'}
            sx={{ flexWrap: 'wrap', alignItems: 'flex start', justifyContent: 'flex-start' }}>
            <Typography fontSize={'0.9rem'} mb={1} width={'100%'}>
              {description}
            </Typography>
            <Box>
              <Chip
                label={buttonLabel}
                onClick={onClick}
                size="small"
                sx={{
                  borderRadius: '0.3rem',
                  bgcolor: '#121212',
                  color: '#FFFFFF',
                  px: 2,
                  py: 1,
                  '&:hover': {
                    backgroundColor: '#000000'
                  }
                }}
              />
            </Box>
            <Typography fontSize={'0.85rem'} mt={1.5}>
              {channels[index]?.channel_details?.data
                ? Array.isArray(channels[index]?.channel_details?.data)
                  ? channels[index]?.channel_details?.data
                      .map((item) => (typeof item === 'string' ? item : item.channel))
                      .join(', ')
                  : channels[index]?.channel_details?.data?.repo_name || ''
                : null}
            </Typography>
          </Flex>
        </Flex>
        <Flex
          sx={{
            flex: '15%',
            flexGrow: 1,
            justifyContent: 'flex-end',
            pt: 2,
            pr: 2,
            height: 'inherit'
          }}>
          {showDisconnect ? (
            <TextButton
              onClick={disconnect}
              sx={{ justifySelf: 'flex-end', fontSize: '0.9rem', py: 0, color: 'inherit' }}>
              Disconnect
            </TextButton>
          ) : null}
        </Flex>
      </Flex>

      {index < notificationOptions.length - 1 && <Divider sx={{ my: 3 }} />}
    </Box>
  );
};

const useNotificationMutation = (url) => {
  const { mutateAsync } = useScandiumMutation(url, {
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (data) => {
      toast.error(data.message);
    }
  });

  const handleNotification = async (status) => {
    await mutateAsync({ run_status: status });
  };

  return handleNotification;
};

const getValueFromUrlHash = (paramName) => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  const value = params.get(paramName);

  return value;
};

const Notifications = () => {
  const [cookies] = useCookies(['active_company', 'role', 'channelData']);
  const setChannelData = useUpdateChannelData();
  const { userData } = useContext(UserContext);
  const userId = userData?.user?.id;

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const __code = queryParams.get('code');
  const [selectedTab, setSelectedTab] = useState(cookies?.channelData?.tab || 0);
  const [enabledSuccessNotification, setEnabledSuccessNotification] = useState(false);
  const [enabledErrorNotification, setEnabledErrorNotification] = useState(false);
  const activeProject = useActiveProject();

  const { isFeatureEnabled: isProjectSettingsEnabled } = useFeatureEnabled('project-settings');

  const {
    requestConfirm: requestCOnfirmTrelloSuccessDisconnect,
    ConfirmationDialog: ConfirmationDialogSuccessTrello
  } = useConfirmDialog();

  const {
    requestConfirm: requestCOnfirmJiraSuccessDisconnect,
    ConfirmationDialog: ConfirmationDialogSuccessJira
  } = useConfirmDialog();

  const {
    requestConfirm: requestCOnfirmGithubSuccessDisconnect,
    ConfirmationDialog: ConfirmationDialogSuccessGithub
  } = useConfirmDialog();

  const {
    requestConfirm: requestConfirmSlackDisconnect,
    ConfirmationDialog: ConfirmationDialogSlack
  } = useConfirmDialog();

  const {
    requestConfirm: requestConfirmClickUpDisconnect,
    ConfirmationDialog: ConfirmationDialogClickUp
  } = useConfirmDialog();

  const [channels, setChannels] = useState([
    {
      channel: 'email',
      run_status: 'success',
      channel_details: {
        data: []
      },
      status: 'inactive'
    },
    {
      channel: 'webhook',
      run_status: 'success',
      channel_details: {
        data: []
      },
      status: 'inactive'
    },
    {
      channel: 'slack',
      run_status: 'success',
      channel_details: {
        data: []
      },
      status: 'inactive'
    },
    {
      channel: 'jira',
      run_status: 'success',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    },
    {
      channel: 'trello',
      run_status: 'success',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    },
    {
      channel: 'github',
      run_status: 'success',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    },
    {
      channel: 'clickup',
      run_status: 'success',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    }
  ]);
  const [errorChannels, setErrorChannels] = useState([
    {
      channel: 'email',
      run_status: 'error',
      channel_details: {
        data: []
      },
      status: 'inactive'
    },
    {
      channel: 'webhook',
      run_status: 'error',
      channel_details: {
        data: []
      },
      status: 'inactive'
    },
    {
      channel: 'slack',
      run_status: 'error',
      channel_details: {
        data: []
      },
      status: 'inactive'
    },
    {
      channel: 'jira',
      run_status: 'error',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    },
    {
      channel: 'trello',
      run_status: 'error',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    },
    {
      channel: 'github',
      run_status: 'error',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    },
    {
      channel: 'clickup',
      run_status: 'error',
      channel_details: {
        data: {}
      },
      status: 'inactive'
    }
  ]);

  const activeChannel = selectedTab === 0 ? channels : errorChannels;
  const trelloChannel = activeChannel.find((channel) => channel.channel === 'trello');
  const githubChannel = activeChannel.find((channel) => channel.channel === 'github');
  const jiraChannel = activeChannel.find((channel) => channel.channel === 'jira');
  const slackChannel = activeChannel.find((channel) => channel.channel === 'slack');
  const clickUpChannel = activeChannel.find((channel) => channel.channel === 'clickup');
  const showTrelloSettings = Object.keys(trelloChannel.channel_details.data).length > 0;
  const showGithubSettings = Object.keys(githubChannel.channel_details.data).length > 0;
  const showJiraSettings = Object.keys(jiraChannel.channel_details.data).length > 0;
  const showClickUpSettings = Object.keys(jiraChannel.channel_details.data).length > 0;
  const showSlackSettings = slackChannel.channel_details.data?.length > 0;

  const trelloToken = getValueFromUrlHash('token');

  const [
    requestMultiInputModal,
    {
      open: openMultiInputModal,
      onClose: onCloseMultiInputModal,
      onComplete: completeMultiInputModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const [
    requestTrelloConnectModal,
    {
      open: openTrelloConnectModal,
      onClose: onCloseTrelloConnectModal,
      onComplete: completeTrelloConnectModal,
      ...others
    }
  ] = useAwaitModal();

  const [
    requestJiraConnectModal,
    {
      open: openJiraConnectModal,
      onClose: onCloseJiraConnectModal,
      onComplete: completeJiraConnectModal,
      ...rest
    }
  ] = useAwaitModal();

  const [
    requestSlackConnectModal,
    {
      open: openSlackConnectModal,
      onClose: onCloseSlackConnectModal,
      onComplete: completeSlackConnectModal
    }
  ] = useAwaitModal();

  const [
    requestGithubConnectModal,
    {
      open: openGithubConnectModal,
      onClose: onCloseGithubConnectModal,
      onComplete: completeGithubConnectModal
    }
  ] = useAwaitModal();

  const [
    requestClickUpConnectModal,
    {
      open: openClickUpConnectModal,
      onClose: onCloseClickUpConnectModal,
      onComplete: completeClickUpConnectModal
    }
  ] = useAwaitModal();

  // Github
  const { refetch: connectGithub, isLoading: isConnectingGithub } = useScandiumQuery(
    '/third-parties/github/login',
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.url) window.location.href = data.data.url;
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { mutateAsync: postGithubtoken, isSuccess: isGithubTokenSuccess } = useScandiumMutation(
    '/third-parties/github/get-token',
    {
      onSuccess: (data) => {
        requestGithubConnectModal();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handlePostGithubToken = async () => {
    await postGithubtoken({ code: __code });
  };

  const connectGithubAccount = async () => {
    setChannelData({
      tab: selectedTab,
      name: 'github'
    });

    await connectGithub();
  };

  // Slack
  const { refetch: connectSlack, isLoading: isConnectingSlack } = useScandiumQuery(
    '/third-parties/slack/login',
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.url) window.location.href = data.data.url;
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { mutateAsync: postSlackToken } = useScandiumMutation('/third-parties/slack/get-token', {
    onSuccess: (data) => {
      const updater = selectedTab === 0 ? setChannels : setErrorChannels;
      updater((prevChannels) =>
        prevChannels.map((channel) => {
          if (channel.channel === 'slack') {
            return {
              ...channel,
              status: 'active',
              channel_details: {
                data: [{ ...data.data.channel_details }]
              }
            };
          } else {
            return channel;
          }
        })
      );
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handlePostSlackToken = async () => {
    await postSlackToken({
      code: __code
    });
  };

  const connectSlackAccount = async () => {
    setChannelData({
      tab: selectedTab,
      name: 'slack'
    });

    await connectSlack();
  };

  // Trello
  const { refetch: connectTrello, isLoading: isConnectingTrello } = useScandiumQuery(
    '/third-parties/trello/login',
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.url) window.location.href = data.data.url;
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { mutateAsync: postTrelloCredentials, isSuccess: isTrelloTokenSuccess } =
    useScandiumMutation('/third-parties/credentials', {
      onSuccess: (data) => {
        requestTrelloConnectModal();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    });

  const handlePostTrelloCredentials = async () => {
    await postTrelloCredentials({
      access_token: trelloToken,
      provider: 'trello'
    });
  };

  const connectTrelloAccount = async () => {
    setChannelData({
      tab: selectedTab,
      name: 'trello'
    });

    await connectTrello();
  };

  // Jira
  const { refetch: connectJira, isLoading: isConnectingJira } = useScandiumQuery(
    '/third-parties/jira/login',
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.url) window.location.href = data.data.url;
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const connectJiraAccount = async () => {
    setChannelData({
      tab: selectedTab,
      name: 'jira'
    });

    await connectJira();
  };

  const { mutateAsync: postJiraToken, isSuccess: isJiraTokenSuccess } = useScandiumMutation(
    '/third-parties/jira/get-token',
    {
      onSuccess: (data) => {
        requestJiraConnectModal();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handlePostJiraToken = async () => {
    await postJiraToken({ code: __code });
  };

  // clickup
  const { refetch: connectClickUp, isLoading: isConnectingClickUp } = useScandiumQuery(
    '/third-parties/clickup/login',
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.data.url) window.location.href = data.data.url;
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const { mutateAsync: postClickUpCredentials, isSuccess: isClickUpTokenSuccess } =
    useScandiumMutation('/third-parties/clickup/get-token', {
      onSuccess: (data) => {
        requestClickUpConnectModal();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    });

  const connectClickUpAccount = async () => {
    setChannelData({
      tab: selectedTab,
      name: 'clickup'
    });

    await connectClickUp();
  };

  const handlePostClickUpToken = async () => {
    await postClickUpCredentials({ code: __code });
  };

  const triggerTokenExchanges = async () => {
    const services = [
      { name: 'jira', tokenCheck: __code, handler: handlePostJiraToken },
      { name: 'trello', tokenCheck: trelloToken, handler: handlePostTrelloCredentials },
      { name: 'slack', tokenCheck: __code, handler: handlePostSlackToken },
      { name: 'github', tokenCheck: __code, handler: handlePostGithubToken },
      { name: 'clickup', tokenCheck: __code, handler: handlePostClickUpToken }
    ];

    for (const service of services) {
      if (service.tokenCheck && cookies?.channelData?.name === service.name) {
        await service.handler();
      }
    }
  };

  useEffect(() => {
    triggerTokenExchanges();
  }, []);

  const updateArrayData = (index, newData) => {
    const activeChannel = selectedTab === 0 ? channels : errorChannels;
    const updatedChannels = [...activeChannel];
    updatedChannels[index].channel_details.data = [
      ...updatedChannels[index].channel_details.data,
      ...newData
    ];

    const updater = selectedTab === 0 ? setChannels : setErrorChannels;
    updater(updatedChannels);
  };

  const updateObjectData = (index, newData) => {
    const activeChannel = selectedTab === 0 ? channels : errorChannels;
    const updatedChannels = [...activeChannel];
    updatedChannels[index].channel_details.data = newData;

    const updater = selectedTab === 0 ? setChannels : setErrorChannels;
    updater(updatedChannels);
  };

  const updateStatus = (index, newStatus) => {
    const activeChannel = selectedTab === 0 ? channels : errorChannels;
    const updatedChannels = [...activeChannel];
    updatedChannels[index].status = newStatus;

    const updater = selectedTab === 0 ? setChannels : setErrorChannels;
    updater(updatedChannels);
  };

  const getStatusAtIndex = (index) => {
    const activeChannel = selectedTab === 0 ? channels : errorChannels;
    const updatedChannels = [...activeChannel];
    return updatedChannels[index]?.status;
  };

  const resetChannelDetails = (channelName) => {
    const updater = selectedTab === 0 ? setChannels : setErrorChannels;
    updater(
      channels.map((channel) => {
        if (channel.channel === channelName) {
          return {
            ...channel,
            status: 'inactive',
            channel_details: {
              data: {}
            }
          };
        }
        return channel;
      })
    );
  };

  const handleSuccessDisconnect = async (channelName, requestConfirmFunction) => {
    if (await requestConfirmFunction()) {
      await resetChannelDetails(channelName);
    }
  };

  const handleSlackDisconnect = async () => {
    if (await requestConfirmSlackDisconnect()) {
      const updater = selectedTab === 0 ? setChannels : setErrorChannels;
      updater((prevChannels) =>
        prevChannels.map((channel) => {
          if (channel.channel === 'slack') {
            return {
              ...channel,
              status: 'inactive',
              channel_details: {
                data: []
              }
            };
          } else {
            return channel;
          }
        })
      );
    }
  };

  const handleTrelloSuccessDisconnect = () =>
    handleSuccessDisconnect('trello', requestCOnfirmTrelloSuccessDisconnect);
  const handleJiraSuccessDisconnect = () =>
    handleSuccessDisconnect('jira', requestCOnfirmJiraSuccessDisconnect);
  const handleGithubSuccessDisconnect = () =>
    handleSuccessDisconnect('github', requestCOnfirmGithubSuccessDisconnect);
  const handleClickUpSuccessDisconnect = () =>
    handleSuccessDisconnect('clickup', requestConfirmClickUpDisconnect);

  const updateChannelData = (channelName, newData) => {
    const updater = selectedTab === 0 ? setChannels : setErrorChannels;

    updater((prevChannels) => {
      return prevChannels.map((channel) => {
        if (channel.channel === channelName) {
          return {
            ...channel,
            channel_details: {
              ...channel.channel_details,
              data: newData
            }
          };
        }
        return channel;
      });
    });
  };

  const isUserProjectOwnerOrCollaborator =
    activeProject?.user_id === userId || activeProject?.collaboration?.user_id === userId;

  const { refetch: refetchChannels } = useScandiumQuery(
    `/projects/${activeProject?.id}/notifications/settings`,
    {
      enabled: !!activeProject?.id && !!isUserProjectOwnerOrCollaborator,
      onSuccess: (data) => {
        const successData = data.data?.notification_settings?.success;
        const errorData = data.data?.notification_settings?.error;
        const __data = data.data.notification_settings;
        setEnabledSuccessNotification(__data.success_toggle_on);
        setEnabledErrorNotification(__data.error_toggle_on);

        if (!!successData) {
          const updatedSuccessChannels = channels.map((channel) => {
            const matchingChannel = successData.find((data) => data.channel === channel.channel);
            return matchingChannel
              ? {
                  ...channel,
                  status: matchingChannel.status,
                  channel_details: JSON.parse(matchingChannel.channel_details)
                }
              : channel;
          });
          setChannels(updatedSuccessChannels);
        }

        if (!!errorData) {
          const updatedSuccessChannels = errorChannels.map((channel) => {
            const matchingChannel = errorData.find((data) => data.channel === channel.channel);
            return matchingChannel
              ? {
                  ...channel,
                  status: matchingChannel.status,
                  channel_details: JSON.parse(matchingChannel.channel_details)
                }
              : channel;
          });
          setErrorChannels(updatedSuccessChannels);
        }
      }
    }
  );

  const { mutateAsync: postChannels, isLoading: isSavingNotificationChannels } =
    useScandiumMutation(`/projects/${activeProject?.id}/notifications/settings`, {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchChannels();
      },
      onError: (data) => {
        toast.error(data.message);
      }
    });

  const postEnableNotification = useNotificationMutation(
    `/projects/${activeProject?.id}/notifications/settings/enable`
  );
  const postDisableNotification = useNotificationMutation(
    `/projects/${activeProject?.id}/notifications/settings/disable`
  );

  const handleSwitchChange = async (event, status, setValue) => {
    const isChecked = event.target.checked;

    setValue(isChecked);

    if (isChecked) {
      await postEnableNotification(status);
    } else {
      await postDisableNotification(status);
    }
  };

  //
  const filterEmptyChannelDetails = (__channels) => {
    const filteredChannels = __channels.filter((channel) => {
      const channelDetails = channel.channel_details.data;
      // Check if channelDetails is an empty array or empty object
      return (
        !(Array.isArray(channelDetails) && channelDetails.length === 0) &&
        !(typeof channelDetails === 'object' && Object.keys(channelDetails).length === 0)
      );
    });
    return filteredChannels;
  };

  const handlePostChannels = async () => {
    const __successChannels = filterEmptyChannelDetails(channels);
    const __errorChannels = filterEmptyChannelDetails(errorChannels);

    await postChannels({ channels: [...__successChannels, ...__errorChannels] });
  };

  if (!isProjectSettingsEnabled || !isUserProjectOwnerOrCollaborator) {
    const descriptionText = isProjectSettingsEnabled
      ? 'Sorry, this page is restricted to administrators and owners only. If you believe you should have access, please contact your administrator for assistance!'
      : 'This feature is only available to subscribed users. Upgrade your account to unlock access.';

    return (
      <Box
        sx={{
          marginTop: { xs: '8rem', sm: '12rem' }
        }}>
        <EmptyState title={'Access Restricted'} maxWidth="40%" description={descriptionText} />
      </Box>
    );
  }

  const notificationOptions = [
    {
      icon: <EmailNotificationSvg />,
      buttonLabel: 'Add new email',
      onClick: () => requestMultiInputModal({ webhook: false, channelName: 'email' }),
      description: `Please provide one or multiple email addresses to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }.`,
      data: []
    },
    {
      icon: <WebhookSvg />,
      buttonLabel: 'Add webhook',
      onClick: () => requestMultiInputModal({ webhook: true, channelName: 'webhook' }),
      description: `Please provide one or multiple webhook URL to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }.`,
      data: ''
    },
    {
      icon: <SlackSvg />,
      buttonLabel: showSlackSettings ? 'Disconnect' : 'Connect',
      onClick: showSlackSettings ? handleSlackDisconnect : connectSlackAccount,
      description: `Please connect one or multiple Slack channels to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }.`,
      data: []
    },
    {
      icon: <JiraSvg />,
      buttonLabel: showJiraSettings ? 'Edit settings' : 'Connect',
      onClick: showJiraSettings ? requestJiraConnectModal : connectJiraAccount,
      description: `Please connect your Jira account to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }.`,
      data: [],
      disconnect: handleJiraSuccessDisconnect
    },
    {
      icon: <TrelloSvg />,
      buttonLabel: showTrelloSettings ? 'Edit settings' : 'Connect',
      onClick: showTrelloSettings ? requestTrelloConnectModal : connectTrelloAccount,
      description: `Please connect your Trello account to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }.`,
      data: [],
      disconnect: handleTrelloSuccessDisconnect
    },
    {
      icon: <GithubSvg />,
      buttonLabel: showGithubSettings ? 'Edit settings' : 'Connect',
      onClick: showGithubSettings ? requestGithubConnectModal : connectGithubAccount,
      description: `Please connect your Github account to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }`,
      data: [],
      disconnect: handleGithubSuccessDisconnect
    },
    {
      icon: <ClickUpSvg />,
      buttonLabel: showClickUpSettings ? 'Edit settings' : 'Connect',
      onClick: showClickUpSettings ? requestClickUpConnectModal : connectClickUpAccount,
      description: `Please connect your ClickUp account to receive notifications for test case run ${
        selectedTab === 0 ? 'success' : 'errors'
      }`,
      data: [],
      disconnect: handleClickUpSuccessDisconnect
    }
  ];

  return (
    <Box>
      <BackButton label={'Back'} onClick={() => navigate('..')} />
      <Typography
        color="primary"
        sx={{
          textAlign: 'left',
          fontSize: '1.2rem',
          mt: 2
        }}>
        Notifications configuration
      </Typography>
      <Typography
        color="inherit"
        as={'p'}
        sx={{
          textAlign: 'left',
          fontSize: '0.9rem',
          mt: 1
        }}>
        Configure your notification to receive success and or failure alerts on different platforms.
      </Typography>

      <Box
        sx={{
          width: { xs: '100%', sm: '100%', md: '60%' },
          mt: 2
        }}>
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          aria-label={'Test cases tabs'}
          variant={'fullWidth'}
          sx={{ borderBottom: '3px solid #E3E3E3' }}
          indicatorColor={'secondary'}
          TabIndicatorProps={{
            sx: { height: 3 }
          }}>
          <Tab
            label={<Flex columnGap={2}>Success</Flex>}
            sx={{ textTransform: 'none', textAlign: 'left', fontWeight: 'bold' }}
          />
          <Tab
            label={<Flex columnGap={2}>Failure</Flex>}
            sx={{ textTransform: 'none', textAlign: 'left', fontWeight: 'bold' }}
          />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <Flex justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 3, mb: 2 }}>
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '1.2rem'
              }}>
              Enable
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={enabledSuccessNotification}
                  onChange={(event) =>
                    handleSwitchChange(event, 'success', setEnabledSuccessNotification)
                  }
                />
              }
            />
          </Flex>
          {notificationOptions.map((item, index) => (
            <NotificationCard
              key={index}
              {...item}
              notificationOptions={notificationOptions}
              index={index}
              status={getStatusAtIndex(index)}
              updateStatus={(newStatus) => updateStatus(index, newStatus)}
              updateArrayData={(newData) => updateArrayData(index, newData)}
              updateObjectData={(newData) => updateObjectData(index, newData)}
              channels={channels}
            />
          ))}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Flex justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 3, mb: 2 }}>
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '1.2rem'
              }}>
              Enable
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={enabledErrorNotification}
                  onChange={(event) =>
                    handleSwitchChange(event, 'error', setEnabledErrorNotification)
                  }
                />
              }
            />
          </Flex>

          {notificationOptions.map((item, index) => (
            <NotificationCard
              key={index}
              {...item}
              notificationOptions={notificationOptions}
              index={index}
              status={getStatusAtIndex(index)}
              updateStatus={(newStatus) => updateStatus(index, newStatus)}
              updateArrayData={(newData) => updateArrayData(index, newData)}
              updateObjectData={(newData) => updateObjectData(index, newData)}
              channels={errorChannels}
            />
          ))}
        </TabPanel>

        <ContainedButton
          sx={{ textDecoration: 'none', display: 'inline-flex', mt: 5, mb: 6 }}
          isLoading={isSavingNotificationChannels}
          onClick={handlePostChannels}
          loadingProps={{ size: 16 }}>
          Save
        </ContainedButton>
      </Box>

      <MultiInputModal
        open={openMultiInputModal}
        onClose={onCloseMultiInputModal}
        onComplete={completeMultiInputModal}
        updateChannelData={updateChannelData}
        channels={channels}
        successChannels={channels}
        errorChannels={errorChannels}
        setSuccessChannels={setChannels}
        setErrorChannels={setErrorChannels}
        activeTab={selectedTab}
        handlePostChannels={handlePostChannels}
        {...otherProps}
      />

      <TrelloConnectModal
        open={openTrelloConnectModal}
        onClose={onCloseTrelloConnectModal}
        onComplete={completeTrelloConnectModal}
        updateChannelData={updateChannelData}
        successChannels={channels}
        errorChannels={errorChannels}
        setSuccessChannels={setChannels}
        setErrorChannels={setErrorChannels}
        activeTab={selectedTab}
        isTrelloTokenSuccess={isTrelloTokenSuccess}
        showTrelloSettings={showTrelloSettings}
        handlePostChannels={handlePostChannels}
        {...others}
      />

      <GithubConnectModal
        open={openGithubConnectModal}
        onClose={onCloseGithubConnectModal}
        onComplete={completeGithubConnectModal}
        updateChannelData={updateChannelData}
        successChannels={channels}
        errorChannels={errorChannels}
        setSuccessChannels={setChannels}
        setErrorChannels={setErrorChannels}
        activeTab={selectedTab}
        isGithubTokenSuccess={isGithubTokenSuccess}
        showGithubSettings={showGithubSettings}
        handlePostChannels={handlePostChannels}
      />

      <ClickUpConnectModal
        open={openClickUpConnectModal}
        onClose={onCloseClickUpConnectModal}
        onComplete={completeClickUpConnectModal}
        updateChannelData={updateChannelData}
        successChannels={channels}
        errorChannels={errorChannels}
        setSuccessChannels={setChannels}
        setErrorChannels={setErrorChannels}
        activeTab={selectedTab}
        isClickUpTokenSuccess={isClickUpTokenSuccess}
        showClickUpSettings={showClickUpSettings}
        handlePostChannels={handlePostChannels}
      />

      <JiraConnectModal
        open={openJiraConnectModal}
        onClose={onCloseJiraConnectModal}
        onComplete={completeJiraConnectModal}
        updateChannelData={updateChannelData}
        successChannels={channels}
        errorChannels={errorChannels}
        setSuccessChannels={setChannels}
        setErrorChannels={setErrorChannels}
        activeTab={selectedTab}
        showJiraSettings={showJiraSettings}
        isJiraTokenSuccess={isJiraTokenSuccess}
        handlePostChannels={handlePostChannels}
        {...rest}
      />

      <SlackConnectModal
        open={openSlackConnectModal}
        onClose={onCloseSlackConnectModal}
        onComplete={completeSlackConnectModal}
        updateChannelData={updateChannelData}
        successChannels={channels}
        errorChannels={errorChannels}
        setSuccessChannels={setChannels}
        setErrorChannels={setErrorChannels}
        activeTab={selectedTab}
        handlePostChannels={handlePostChannels}
      />

      <ConfirmationDialogSuccessTrello
        title={'Disconnect Trello Account'}
        description={'Are you sure you want to disconnect this Trello account?'}
        confirmLabel={'Disconnect'}
        confirmColor={'error'}
      />
      <ConfirmationDialogSuccessJira
        title={'Disconnect Jira Account'}
        description={'Are you sure you want to disconnect this Jira account?'}
        confirmLabel={'Disconnect'}
        confirmColor={'error'}
      />
      <ConfirmationDialogSuccessGithub
        title={'Disconnect Github Account'}
        description={'Are you sure you want to disconnect this Jira account?'}
        confirmLabel={'Disconnect'}
        confirmColor={'error'}
      />
      <ConfirmationDialogSlack
        title={'Disconnect Slack Account'}
        description={'Are you sure you want to disconnect this Slack account?'}
        confirmLabel={'Disconnect'}
        confirmColor={'error'}
      />
      <ConfirmationDialogClickUp
        title={'Disconnect ClickUp Account'}
        description={'Are you sure you want to disconnect this CLickUp account?'}
        confirmLabel={'Disconnect'}
        confirmColor={'error'}
      />
    </Box>
  );
};

export default Notifications;
