import Flex from '../../../components/base/Flex';
import { ArrowDropDownOutlined, ArrowRight } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { Box, Typography, Stack, Chip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { colorMap } from './TestFolders';
import { TestCaseContext } from '../../../store/testState';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';

// const requestData = [
//   {
//     url: 'https://jsonplaceholder.typicode.com/todos/1',
//     request_method: 'get',
//     headers: { content: 'meenat', origin: 'love', type: 'lolly', gate: 'mon' },
//     cookies: [],
//     body: [
//       {
//         key: 'file',
//         value:
//           'https://scandium-user-storage.s3.amazonaws.com/api/9abfb815-63d4-47d9-91ed-cefa3be5436f/test-files/aanu%202.jpg'
//       }
//     ],
//     language: 'json',
//     mode: 'formData'
//   },
//   {
//     url: 'https://jsonplaceholder.typicode.com/',
//     request_method: 'Post',
//     headers: [],
//     cookies: [],
//     body: {
//       name: 'mean',
//       body: 'frequent'
//     }
//   }
// ];
const useJSONPrettyStyle = () => {
  const theme = useTheme();
  const style = {
    mainStyle: `background:${theme.palette.background.lightGrey_dark};margin:0em;`,
    valueStyle: '',
    keyStyle: ''
  };
  return style;
};

export const Console = () => {
  const { consoleData, setConsoleData } = useContext(TestCaseContext);
  return (
    <CustomScrollbar
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '250px',
        px: 2,
        pt: 0.7
      }}>
      <Flex
        sx={{
          justifyContent: 'center'
        }}>
        {!!consoleData.length && (
          <Flex flex={1}>
            <Stack
              gap={1}
              sx={{
                flex: 1
              }}>
              {consoleData.map((item, index) => {
                return <RequestEntry key={index} data={item} />;
              })}
            </Stack>
            <Chip
              label="Clear console"
              size="small"
              color="primary"
              onClick={() => {
                setConsoleData([]);
              }}
              onDelete={() => {
                setConsoleData([]);
              }}
              sx={{
                mt: 1,
                ml: 'auto',
                mr: 3,
                alignSelf: 'flex-start'
              }}
            />
          </Flex>
        )}
        {!consoleData.length && (
          <Flex mt={12} justifyContent={'center'} flexDirection={'column'}>
            <HourglassDisabledIcon fontSize="large" />
            <Typography mt={2} fontSize={'0.9rem'} textAlign={'center'}>
              No request data
            </Typography>
            <Typography mt={1} textAlign={'center'} fontSize={'0.75rem'}>
              Send a test request to view its details
            </Typography>
          </Flex>
        )}
      </Flex>
    </CustomScrollbar>
  );
};

const RequestEntry = ({ data }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <Stack
      py={0.5}
      // bgcolor={theme.palette.background.suiteList}
      sx={{
        '&:hover': {
          background: !open && theme.palette.hover.default,
          cursor: 'pointer'
        }
      }}>
      <Flex
        alignItems={'flex-start'}
        onClick={() => {
          setOpen((prev) => !prev);
        }}>
        {!!open ? <ArrowDropDownOutlined /> : <ArrowRight />}
        <Flex
          columnGap={1}
          alignItems={'flex-start'}
          // maxWidth={'200px'}
        >
          <Box
            sx={{
              bgcolor: data.isError ? 'red' : 'green',
              borderRadius: '100%',
              p: '5px',
              mt: 0.5
            }}
          />

          <Typography
            fontWeight={'bold'}
            color={colorMap[data.request_method.toLowerCase()] || 'black'}>
            {data.request_method.toUpperCase()}{' '}
          </Typography>
          <Typography
            maxWidth={'750px'}
            sx={{
              overflowWrap: 'break-word'
            }}
            ml={2}
            display={'inline'}
            color={colorMap[data.request_method.toLowerCase()] || 'black'}>
            {data.url}
          </Typography>
        </Flex>
      </Flex>
      {open && (
        <Box ml={2} mt={0.4}>
          <Headers headers={data.headers} />
          <RequestBody requestBody={data.body} language={data.language} mode={data.mode} />
          <LogError logErrors={data?.log_error} />
        </Box>
      )}
    </Stack>
  );
};

const Headers = ({ headers }) => {
  const theme = useTheme();
  const style = useJSONPrettyStyle();
  const [open, setOpen] = useState(false);
  if (Array.isArray(headers)) {
    let headersToObject = {};
    headers.forEach((entry) => {
      headersToObject[entry.key] = entry.value;
    });
    headers = headersToObject;
  }
  return (
    <Stack py={0.3}>
      <Flex
        onClick={() => {
          setOpen((prev) => !prev);
        }}>
        {!!open ? <ArrowDropDownOutlined color="disabled" /> : <ArrowRight color="disabled" />}
        <Typography color={theme.palette.custom.contrastText}>Request Headers</Typography>
      </Flex>
      {open && (
        <Flex>
          <JSONPretty id="json-pretty" data={headers} mainStyle={style.mainStyle} />
        </Flex>
      )}
    </Stack>
  );
};

const RequestBody = ({ requestBody, mode, language }) => {
  const theme = useTheme();
  const style = useJSONPrettyStyle();
  const [open, setOpen] = useState(false);
  return (
    <Stack py={0.3}>
      <Flex
        onClick={() => {
          setOpen((prev) => !prev);
        }}>
        {!!open ? <ArrowDropDownOutlined color="disabled" /> : <ArrowRight color="disabled" />}
        <Typography color={theme.palette.custom.contrastText}>
          Request Body :{' '}
          <Typography fontStyle={'italic'} display={'inline'}>
            {' '}
            {mode !== 'raw' ? mode : language}{' '}
          </Typography>
        </Typography>
      </Flex>

      {open && mode === 'raw' && (language === 'json' || language === 'graphql') && (
        <JSONPretty id="json-pretty" data={requestBody} mainStyle={style.mainStyle} />
      )}
      {open && mode !== 'raw' && mode !== 'none' && (
        <Box ml={4}>
          <JSONPretty id="json-pretty" data={requestBody} mainStyle={style.mainStyle} />
        </Box>
      )}
      {open && mode === 'none' && <Typography> no request body </Typography>}
    </Stack>
  );
};

const LogError = ({ logErrors = [] }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const style = useJSONPrettyStyle();
  return (
    <Stack py={0.3}>
      <Flex
        onClick={() => {
          setOpen((prev) => !prev);
        }}>
        {!!open ? <ArrowDropDownOutlined color="disabled" /> : <ArrowRight color="disabled" />}
        <Typography color={theme.palette.custom.contrastText}>Log Error</Typography>
      </Flex>
      {open &&
        (!!logErrors.length ? (
          <Flex>
            <JSONPretty id="json-pretty" data={logErrors} mainStyle={style.mainStyle} />
          </Flex>
        ) : (
          <Typography ml={5}>no error logs</Typography>
        ))}
    </Stack>
  );
};
