import { useRef, useState } from 'react';

const useAwaitModal = () => {
  const [open, setOpen] = useState(false);
  const extraPropsRef = useRef(undefined);
  const resolveRef = useRef(null);
  const requestAction = async (extraProps) => {
    extraPropsRef.current = extraProps;
    setOpen(true);
    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  };

  if (!open) extraPropsRef.current = undefined;

  const modalProps = {
    open,
    ...extraPropsRef.current,
    onClose: () => {
      extraPropsRef.current = undefined;
      setOpen(false);
      resolveRef.current?.(false);
    },
    onComplete: () => {
      resolveRef.current?.(true);
      setOpen(false);
    }
  };

  return [requestAction, modalProps];
};

export default useAwaitModal;
