import { SvgIcon } from '@mui/material';

const EventPlaySvg = ({ width = 25, height = 25, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={'0 0 10 9'}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.81577 0C2.41986 0 0.477539 1.94229 0.477539 4.33823C0.477539 6.73417 2.41986 8.67646 4.81577 8.67646C7.21167 8.67646 9.154 6.73417 9.154 4.33823C9.154 1.94229 7.21167 0 4.81577 0ZM6.31517 4.56819L4.14605 5.92389C4.10216 5.95129 4.05226 5.96506 4.00235 5.96506C3.95714 5.96506 3.91186 5.95381 3.87088 5.93104C3.78469 5.88325 3.73121 5.79255 3.73121 5.69393V2.98253C3.73121 2.88391 3.78469 2.79321 3.87088 2.74542C3.95707 2.69736 4.06246 2.70028 4.14605 2.75257L6.31517 4.10827C6.39441 4.15792 6.4426 4.24477 6.4426 4.33823C6.4426 4.43169 6.39441 4.51855 6.31517 4.56819Z"
        fill="#1958B8"
      />
    </SvgIcon>
  );
};

export default EventPlaySvg;
