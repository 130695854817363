import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Logo from '../../components/base/Logo';
import EmailList from './components/EmailList';
import EmailDetail from './components/EmailDetail';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Flex from '../../components/base/Flex';
import { useScandiumQuery } from '../../data-layer/utils';
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import EmptyState from '../../components/base/EmptyState';

const MailBox = () => {
  useDocumentTitle('Scandium Mailbox');

  const ITEMS_PER_PAGE = 7;

  const [selectedEmail, setSelectedEmail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [emails, setEmails] = useState([]);
  const { projectId } = useParams();
  const emailDetailsRef = useRef(null);

  const handleFocus = () => {
    emailDetailsRef.current.focus();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const { isLoading, error } = useScandiumQuery(`/projects/${projectId}/mailbox`, {
    requireAuth: false,
    enabled: !!projectId,
    refetchInterval: 10000,
    params: {
      page: currentPage,
      limit: ITEMS_PER_PAGE
    },
    onSuccess: (data) => {
      setCurrentPage(data.data.current_page);
      setTotalPages(data.data.last_page);
      setEmails(data.data.data);
    }
  });

  return (
    <Box sx={{ py: 2, px: { xs: 1, sm: 1, md: 3, lg: 5 }, mb: 4 }}>
      <Box>
        <Logo />
      </Box>
      <Typography variant={'h5'} color={'primary'} mt={7}>
        Email Inbox
      </Typography>

      {!!emails.length && (
        <>
          <Flex>
            <IconButton
              onClick={handlePrevPage}
              aria-label={'Previous Page'}
              disabled={currentPage === 1}
              sx={{ bgcolor: 'rgba(25, 88, 184, 0.16)', p: '1.5px' }}>
              <KeyboardArrowLeft sx={{ color: currentPage === 1 ? '#cccccc' : '#1958B8' }} />
            </IconButton>
            <IconButton
              onClick={handleNextPage}
              aria-label={'Next Page'}
              disabled={currentPage === totalPages}
              sx={{ bgcolor: 'rgba(25, 88, 184, 0.16)', p: '1.5px' }}>
              <KeyboardArrowRight
                sx={{ color: currentPage === totalPages ? '#cccccc' : '#1958B8' }}
              />
            </IconButton>
          </Flex>

          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <EmailList
                selectedEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
                mails={emails}
                handleFocus={handleFocus}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box ref={emailDetailsRef}>
                <EmailDetail selectedEmail={selectedEmail} mails={emails} />
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {isLoading && !error && !emails?.length && <PageLoader height={'300px'} />}
      {!isLoading &&
        (error ? (
          <Box mt={'8rem'}>
            <ErrorState error={error} />
          </Box>
        ) : (
          emails?.length === 0 && (
            <Box mt={'8rem'}>
              <EmptyState description={`Your mailbox currently appears to be empty`} />
            </Box>
          )
        ))}
    </Box>
  );
};

export default MailBox;
