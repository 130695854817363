import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useActiveProject } from '../../store/projectState';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import PageLoader from '../../components/PageLoader';
import ErrorState from '../../components/base/ErrorState';
import { CircularImage } from '../../components/personalInformation';
import Flex from '../../components/base/Flex';
import DeleteSvg from '../../components/svg/DeleteSvg';
import UserContext from '../../store/userState';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import BackButton from '../../components/base/BackButton';
import { ColorContext } from '../../AppTheme';
import { useCollaborators } from '../../store/membersState';

export const Collaborator = ({ member, refetchMembers }) => {
  const [cookies] = useCookies(['token', 'email', 'name']);
  const { userData } = useContext(UserContext);
  const activeProject = useActiveProject();
  const { mode } = useContext(ColorContext);
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();

  const { mutateAsync: removeCollaborator } = useScandiumMutation(
    `/projects/${activeProject?.id}/collaborators/remove`,
    {
      params: {
        user_id: member.id
      },
      onSuccess: (data) => {
        toast.success(data.message);
        refetchMembers();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleRemoveCollaborator = async () => {
    if (await requestConfirm()) return await removeCollaborator();
  };

  return (
    <MenuItem
      disableRipple
      key={member.id}
      divider={true}
      sx={{
        color: mode === 'light' ? '#1958B8' : 'white',
        px: 0,
        borderRadius: '0.4rem',
        '&:hover': { bgcolor: 'transparent', cursor: 'default' }
      }}>
      <Box
        sx={{
          bgcolor: 'rgba(36, 195, 217, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderRadius: '0.6rem',
          py: 1,
          pl: 3,
          pr: 5
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <CircularImage name={member.name} style={{ width: '2.5rem', height: '2.5rem' }} />
          <Flex justifyContent={'flex-start'} flexDirection={'column'} alignItems={'normal'}>
            <Typography
              sx={{
                textAlign: 'left',
                fontWeight: 500
              }}>
              {member.name}
            </Typography>
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '0.8rem'
              }}>
              {member.email}
            </Typography>
          </Flex>
        </Box>
        {userData?.user?.id === activeProject?.user_id && cookies?.email !== member.email && (
          <Tooltip title={`Remove ${member.name} as a collaborator from this project`}>
            <Box
              onClick={handleRemoveCollaborator}
              sx={{
                bgcolor: 'rgba(239, 27, 27, 0.16)',
                borderRadius: '100%',
                p: 1,
                width: '2rem',
                height: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': { cursor: 'pointer' }
              }}>
              <DeleteSvg width={14} height={16} />
            </Box>
          </Tooltip>
        )}
      </Box>
      <ConfirmationDialog
        description={
          "Are you sure you want to remove this collaborator from this project? This will revoke the collaborator's access to the project and any associated files or tasks"
        }
        confirmColor={'error'}
        title={'Remove Collaborator'}
      />
    </MenuItem>
  );
};

function Members() {
  let navigate = useNavigate();
  const { collaborators, isLoadingCollaborators, collaboratorsError, refetchCollaborators } =
    useCollaborators();

  return (
    <Box>
      <BackButton label={'Back'} onClick={() => navigate('..')} />
      <Typography
        color="primary"
        sx={{
          textAlign: 'left',
          fontSize: '1.1rem',
          mt: { xs: 4, sm: 4 }
        }}>
        Project Collaborators
      </Typography>
      <Box sx={{ width: { xs: '100%', sm: '100%', md: '60%' } }}>
        {collaborators?.map((member) => (
          <Collaborator key={member.id} member={member} refetchMembers={refetchCollaborators} />
        ))}
      </Box>
      {isLoadingCollaborators && <PageLoader height={'100px'} />}
      {!isLoadingCollaborators && collaboratorsError && <ErrorState error={collaboratorsError} />}
    </Box>
  );
}

export default Members;
