import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import CustomInput from '../../../components/customInput';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';

const GetTestTitle = ({ open, handleClose, handleSave }) => {
  const [title, setTitle] = useState('');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Save test</DialogTitle>
      <DialogContent>
        <Box minWidth={'300px'} py={2}>
          <CustomInput
            onChange={(e) => setTitle(e.target.value)}
            size={'small'}
            label={'Test name'}
            value={title}
            formControlStyle={{
              width: '100%'
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 2 }}>
        <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
        <ContainedButton
          color={'primary'}
          onClick={() => {
            handleSave(title);
            handleClose();
          }}
          autoFocus>
          Save test
        </ContainedButton>
      </DialogActions>
    </Dialog>
  );
};

export default GetTestTitle;
