const style = {
  color: '#000000',
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontSize: 'medium',
  lineHeight: 'normal',
  fontFamily: 'sans-serif',
  fontVariantLigatures: 'normal',
  fontVariantPosition: 'normal',
  fontVariantCaps: 'normal',
  fontVariantNumeric: 'normal',
  fontVariantAlternates: 'normal',
  fontVariantEastAsian: 'normal',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  textIndent: '0',
  textAlign: 'start',
  textDecoration: 'none',
  textDecorationLine: 'none',
  textDecorationStyle: 'solid',
  textDecorationColor: '#000000',
  letterSpacing: 'normal',
  wordSpacing: 'normal',
  textTransform: 'none',
  writingMode: 'lr-tb',
  direction: 'ltr',
  textOrientation: 'mixed',
  dominantBaseline: 'auto',
  baselineShift: 'baseline',
  textAnchor: 'start',
  whiteSpace: 'normal',
  shapePadding: '0',
  shapeMargin: '0',
  inlineSize: '0',
  clipRule: 'nonzero',
  display: 'inline',
  overflow: 'visible',
  visibility: 'visible',
  opacity: '1',
  isolation: 'auto',
  mixBlendMode: 'normal',
  colorInterpolation: 'sRGB',
  colorInterpolationFilters: 'linearRGB',
  solidColor: '#000000',
  solidOpacity: '1',
  vectorEffect: 'none',
  fill: '#000000',
  fillOpacity: '1',
  fillRule: 'nonzero',
  stroke: 'none',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: '4',
  strokeDasharray: 'none',
  strokeDashoffset: '0',
  strokeOpacity: '1',
  colorRendering: 'auto',
  imageRendering: 'auto',
  shapeRendering: 'auto',
  textRendering: 'auto',
  enableBackground: 'accumulate',
  stopColor: '#000000',
  stopOpacity: '1'
};

const NewItemSvg = ({ width = 16, height = 16, disabled, ...props }) => {
  return (
    <svg
      height={height}
      id="svg8"
      version="1.1"
      viewBox="0 0 24 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <defs id="defs2" />
      <g id="g2140" style={{ display: 'inline' }} transform="translate(0,-290.65039)">
        <path
          d="m 6,292.65039 c -2.1987,0 -4,1.8013 -4,4 v 12 c 0,2.1987 1.8013,4 4,4 h 12 c 2.1987,0 4,-1.8013 4,-4 v -12 c 0,-2.1987 -1.8013,-4 -4,-4 z m 0,2 h 12 c 1.1253,0 2,0.8747 2,2 v 12 c 0,1.1253 -0.8747,2 -2,2 H 6 c -1.1253,0 -2,-0.8747 -2,-2 v -12 c 0,-1.1253 0.8747,-2 2,-2 z"
          id="rect2131"
          style={style}
        />
        <path
          d="m 12,296.64998 a 1,1 0 0 0 -1,1 v 4 H 7 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 4 v 4 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -4 h 4 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -4 v -4 a 1,1 0 0 0 -1,-1 z"
          id="path2133"
          style={style}
        />
      </g>
    </svg>
  );
};

export default NewItemSvg;
