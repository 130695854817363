import { useContext, useEffect, useState } from 'react';
import PostalMime from 'postal-mime';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomScrollbar from './CustomScrollbar';
import EmailSvg from '../../../components/svg/EmailSvg';
import Flex from '../../../components/base/Flex';
import { getDateTime } from '../../../utils/time';
import { ColorContext } from '../../../AppTheme';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.mobileOutline}`
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.mobileOutline}`
  }
}));

const TabPanel = ({ children, value, index }) => {
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const EmailBody = ({ selectedTab, setSelectedTab, email }) => {
  const [body, setBody] = useState('');
  const messageIDPattern = /Message-ID: <(.*?)>/;
  const match = email.meta.email.match(messageIDPattern);

  const mimeVersionRegex = /MIME-Version: ([^\r\n]+)/i;
  const mimeVersionMatch = email.meta.email.match(mimeVersionRegex);

  const contentTypeRegex = /Content-Type: ([^\r\n]+)/i;
  const contentTypeMatch = email.meta.email.match(contentTypeRegex);

  useEffect(() => {
    const parseEmail = async () => {
      try {
        const parser = new PostalMime();
        const parsed = await parser.parse(email.meta.email);
        setBody(parsed.html);
      } catch (error) {
        console.error('Error parsing email:', error);
      }
    };

    parseEmail();
  }, [email]);

  return (
    <>
      <Tabs
        value={selectedTab}
        aria-label={'Email body tabs'}
        onChange={(event, value) => setSelectedTab(value)}
        indicatorColor={'secondary'}
        sx={{
          borderBottom: '2px solid #C5C5C5',
          mt: 4
        }}
        TabIndicatorProps={{
          sx: { height: 3 }
        }}>
        <Tab
          label={'HTML'}
          aria-label={'HTML'}
          role="tab"
          sx={{
            textTransform: 'none',
            textAlign: 'left',
            mr: 6,
            px: 0,
            minWidth: 0,
            maxWidth: 'max-content',
            minHeight: 0,
            maxHeight: 'max-content'
          }}
        />
        <Tab
          label={'Raw data'}
          aria-label={'Raw data'}
          role="tab"
          sx={{
            textTransform: 'none',
            textAlign: 'left',
            mr: 6,
            px: 0,
            minWidth: 0,
            maxWidth: 'max-content',
            minHeight: 0,
            maxHeight: 'max-content'
          }}
        />
        <Tab
          label={'Headers'}
          aria-label={'Headers'}
          role="tab"
          sx={{
            textTransform: 'none',
            textAlign: 'left',
            mr: 6,
            px: 0,
            minWidth: 0,
            maxWidth: 'max-content',
            minHeight: 0,
            maxHeight: 'max-content'
          }}
        />
      </Tabs>
      <CustomScrollbar
        sx={{
          overflowY: 'auto',
          maxHeight: '300px',
          minHeight: '300px',
          overflowX: 'auto'
        }}>
        <TabPanel value={selectedTab} index={0}>
          <Typography variant={'body2'} mt={3} dangerouslySetInnerHTML={{ __html: body }} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Box mt={3}>
            {Object.entries(email.meta)?.map(([key, value]) => (
              <Typography
                component={'pre'}
                fontSize={'0.9rem'}
                key={key}>{`${key}:  ${value}`}</Typography>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <Table sx={{ width: '100%', mt: 3 }} size="small" aria-label={'email header Table'}>
            <TableBody>
              <TableRow>
                <StyledTableCell variant={'head'}>Message ID</StyledTableCell>
                <StyledTableCell>{match && match?.[1] ? match[1] : ''}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant={'head'}>Date</StyledTableCell>
                <StyledTableCell>{getDateTime(email.created_at)}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant={'head'}>Subject</StyledTableCell>
                <StyledTableCell>{email.meta.subject}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant={'head'}>From</StyledTableCell>
                <StyledTableCell>{email.meta.from}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant={'head'}>To</StyledTableCell>
                <StyledTableCell>{email.meta.to}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant={'head'}>Mime-version</StyledTableCell>
                <StyledTableCell>{mimeVersionMatch?.[1] || ''}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant={'head'}>Content-type</StyledTableCell>
                <StyledTableCell>{contentTypeMatch?.[1] || ''} </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
      </CustomScrollbar>
    </>
  );
};

const EmailDetail = ({ selectedEmail, mails }) => {
  const email = mails.find((mail) => mail.uuid === selectedEmail);
  const { mode } = useContext(ColorContext)

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Box
      sx={{
        pt: '25px',
        px: { xs: 2, sm: 2, md: 3, lg: '40px' },
        bgcolor: mode === 'light' ? '#FAFAFA' : 'rgba(40,40,40)',
        overflowY: 'auto',
        borderRadius: '10px'
      }}>
      {!email ? (
        <Flex justifyContent={'center'} flexDirection={'column'} height={'70vh'}>
          <EmailSvg />
          <Typography color={'primary'} textAlign={'center'} lineHeight={1.2} mt={2}>
            Welcome to your inbox
          </Typography>
          <Typography
            fontSize={'0.85rem'}
            color={'primary'}
            textAlign={'center'}
            lineHeight={1.2}
            mt={1}>
            Select an email on the left to read
          </Typography>
        </Flex>
      ) : (
        <Box height={'inherit'}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '1.4rem',
              color: mode === 'light' && '#1C1C1C'
            }}>
            {email.meta.subject}
          </Typography>
          <Typography
            sx={{
              fontWeight: 300,
              color: '#C2C2C2',
              my: 1
            }}>
            {getDateTime(email.created_at)}
          </Typography>
          <Flex columnGap={8.5}>
            <Typography
              variant={'body2'}
              sx={{
                fontWeight: 200,
                fontSize: '0.9rem',
                color: '#616161'
              }}>
              From:
            </Typography>
            <Typography variant={'body2'} sx={{ wordBreak: 'break-word' }}>
              {email.meta.from}
            </Typography>
          </Flex>
          <Flex columnGap={10.5}>
            <Typography
              variant={'body2'}
              sx={{
                fontWeight: 200,
                fontSize: '0.9rem',
                color: '#616161',
                my: 1
              }}>
              To:
            </Typography>
            <Typography variant={'body2'}>{email.meta.to}</Typography>
          </Flex>
          <Flex columnGap={7}>
            <Typography
              variant={'body2'}
              sx={{
                fontWeight: 200,
                fontSize: '0.9rem',
                color: '#616161'
              }}>
              Subject:
            </Typography>
            <Typography variant={'body2'}>{email.meta.subject}</Typography>
          </Flex>
          <EmailBody selectedTab={selectedTab} setSelectedTab={setSelectedTab} email={email} />
        </Box>
      )}
    </Box>
  );
};

export default EmailDetail;
