import { useCookies } from 'react-cookie'; 

const useUpdateChannelData = () => {
  const [_, setCookie] = useCookies(['token']); 

  const setChannelDataCookie = (data) => {
    const cookieOptions = {
      path: '/',
      sameSite: 'lax',
      secure: true,
    };

    setCookie('channelData', data, cookieOptions);
  };

  return setChannelDataCookie;
};

export default useUpdateChannelData
