import { useEffect } from 'react';
import history from '../history'; // <-- import

const useBlocker = (message, when = true) => {
  const blocker = (transition) => {
    const cont = window.confirm(message);
    if (cont) transition.retry();
  };
  useEffect(() => {
    if (!when) return;
    const unblock = history.block((tx) => {
      const autoUnblockingTransition = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };
      blocker(autoUnblockingTransition);
    });
    return unblock;
  }, [message, when]);
};

export default useBlocker;
