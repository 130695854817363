import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Chip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useMenu } from '../../../components/base/useMenu';
import { toast } from 'react-toastify';
import { useActiveProject } from '../../../store/projectState';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import { useTheme } from '@emotion/react';

const ActionMenu = React.forwardRef(
  ({ isLoading, onDelete, suiteId, onRemote, onLocal, onSchedule }, ref) => {
    const { menuProps, triggerProps } = useMenu();
    const theme = useTheme();
    const { isFeatureEnabled: enabledRemoteExecutionFeature } =
      useFeatureEnabled('remote-execution');
    const activeProject = useActiveProject();

    const copySuiteIdToClipBoard = () => {
      navigator.clipboard.writeText(suiteId);

      toast.success('copied to clipboard');
      menuProps.onClose();
    };

    const readyOnly = activeProject?.read_only;

    return (
      <div>
        <IconButton
          ref={ref}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={isLoading}
          aria-expanded={menuProps.open ? 'true' : undefined}
          {...triggerProps}>
          {isLoading ? (
            <CircularProgress size={18} color={'primary'} />
          ) : (
            <MoreVert sx={{ color: theme.palette.svg.main }} />
          )}
        </IconButton>
        <Menu id="action-menu" {...menuProps} elevation={1}>
          <MenuItem
            as={NavLink}
            disabled={!!readyOnly}
            sx={{ color: 'inherit' }}
            to={`${suiteId}/edit`}>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              onLocal();
              menuProps.onClose();
            }}
            disabled={!!readyOnly}>
            Run Locally
          </MenuItem>
          <MenuItem
            disabled={!enabledRemoteExecutionFeature || !!readyOnly}
            onClick={() => {
              onRemote();
              menuProps.onClose();
            }}>
            Run Remotely{' '}
            {!enabledRemoteExecutionFeature && (
              <Chip
                label="Premium"
                color="primary"
                sx={{ fontSize: '0.7rem', ml: 1 }}
                size={'small'}
              />
            )}
          </MenuItem>
          <MenuItem onClick={copySuiteIdToClipBoard}>Copy suite id</MenuItem>
          <MenuItem onClick={onSchedule}>Schedule Runs</MenuItem>
          <MenuItem onClick={onDelete} sx={{ color: 'red' }} disabled={!!readyOnly}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default ActionMenu;
