import { useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumUpload } from '../../../data-layer/upload';
import { useTheme } from '@emotion/react';

const UploadAppModal = ({
  open,
  onClose,
  onComplete,
  refetchMobileApps,
  publicKey,
  refetchAppInfo
}) => {
  const activeProject = useActiveProject();
  const theme = useTheme();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [selectedOption, setSelectedOption] = useState('project');
  const projectCode = `xcodebuild -project '<project_name>.xcodeproj' \\
    -scheme '<scheme_name>' \\
    -sdk iphonesimulator \\
    -configuration Debug`;
  const workspaceCode = `xcodebuild -workspace '<your_workspace_name>.xcworkspace' \\
    -scheme '<scheme_name>' \\ 
    -sdk iphonesimulator \\
    -configuration Debug`;

  const toggleAccordion = (index) => {
    setActiveAccordion((prevActive) => {
      if (prevActive === index) {
        return null;
      } else {
        return index;
      }
    });
  };

  const acceptedFileTypes = ['apk', 'zip', 'tar', 'tz'];
  const allowedFileTypes = [
    'application/vnd.android.package-archive',
    'application/zip',
    'application/x-tar',
    'application/x-gzip'
  ];

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles[0] || !allowedFileTypes.includes(acceptedFiles[0].type)) {
      toast.error(
        'Only apps and files with the following extensions are allowed: ' +
          acceptedFileTypes.join(', ')
      );
      return;
    }

    setSelectedFile(acceptedFiles[0]);
    fileInputRef.current = acceptedFiles[0];
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.android.package-archive': ['.apk', '.apks'],
      'application/zip': ['.zip'],
      'application/x-tar': ['.tar'],
      'application/x-gzip': ['.tz']
    },
    maxFiles: 1
  });

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const { mutateAsync: uploadApp, isLoading: isUploadingApp } = useScandiumUpload(
    `/projects/${activeProject?.id}/mobile-tests/apps/${publicKey ? publicKey : ''}`,
    {
      enabled: !!activeProject?.id && !!selectedFile,
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success(data.message);
        if (publicKey) {
          refetchAppInfo();
        } else {
          refetchMobileApps();
        }
        onComplete();
      }
    }
  );

  const handleUploadMobileApp = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error('please select a file before saving');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);

    await uploadApp(formData);
  };

  const editorOptions = {
    fontSize: 12,
    formatOnPaste: true,
    readOnly: true,
    renderIndentGuides: false,
    lineNumbers: false,
    formatOnType: true,
    inlineSuggest: false,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  return (
    <CustomModal open={open}>
      <Box
        component={'form'}
        onSubmit={handleUploadMobileApp}
        encType={'multipart/form-data'}
        py={1}
        px={2}
        width={'80vw'}
        maxWidth={'680px'}
        position={'relative'}>
        <Typography
          as={'h4'}
          color="primary"
          fontWeight={600}
          sx={{
            fontSize: '1.4rem',
            mb: 1
          }}>
          {publicKey ? 'Upload Your App Latest Build' : 'Upload Your App File'}
        </Typography>

        <Typography variant="body">
          For{' '}
          <Typography component={'span'} fontWeight={500}>
            iOS
          </Typography>
          , upload a{' '}
          <Typography component={'span'} color={'primary'} fontWeight={500}>
            .zip, .tar
          </Typography>{' '}
          or{' '}
          <Typography component={'span'} color={'primary'} fontWeight={500}>
            .gz
          </Typography>
          . file containing your compressed{' '}
          <Typography component={'span'} color={'primary'} fontWeight={500}>
            .app
          </Typography>{' '}
          bundle.
        </Typography>

        <Box mb={2} sx={{ bgcolor: theme.palette.background.reportCard, py: 1.5 }}>
          <Typography
            variant="body"
            color={'primary'}
            onClick={() => toggleAccordion(1)}
            sx={{ cursor: 'pointer' }}>
            {activeAccordion === 1
              ? 'Hide detailed iOS instructions'
              : 'Show detailed iOS instructions'}
          </Typography>
          {activeAccordion === 1 && (
            <Box>
              <Typography variant="body">
                Your .app must represent a simulator build of your app. After running the iOS
                simulator in Xcode, you can find your .app bundle by navigating to{' '}
                <Typography fontWeight={500} component={'span'}>
                  Product &rarr; Show Build Folder in Finder &rarr; Products/Debug-iphonesumulator.
                </Typography>
              </Typography>

              <Typography component={'div'} variant="body" sx={{ mt: 1 }}>
                Alternatively, you can run the following command in your project directory:
              </Typography>

              <div className="button-container">
                <button
                  className={`option-button ${selectedOption === 'project' ? 'active' : ''}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setSelectedOption('project');
                  }}>
                  Using .xcodeproj
                </button>
                <button
                  className={`option-button ${selectedOption === 'workspace' ? 'active' : ''}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setSelectedOption('workspace');
                  }}>
                  Using .xcworkspace
                </button>
              </div>
              <Editor
                height={'80px'}
                theme={'vs-dark'}
                value={selectedOption === 'project' ? projectCode : workspaceCode}
                language={'shell'}
                options={editorOptions}
              />

              <Typography component={'div'} variant="body" sx={{ mt: 1 }}>
                You can then zip the .app bundle found in{' '}
                <Typography fontWeight={500} component={'span'}>
                  build/Debug-iphonesimulator/.
                </Typography>
              </Typography>

              <Typography component={'div'} variant="body" sx={{ mt: 1 }}>
                For more information, please see our{' '}
                <a
                  href="https://docs.getscandium.com/mobile-application-testing/uploading-apps/ios"
                  target="_blank"
                  rel="noreferrer">
                  docs
                </a>{' '}
                for iOS
              </Typography>
            </Box>
          )}
        </Box>

        <Typography
          variant="body"
          sx={{
            mt: 1
          }}>
          For{' '}
          <Typography component={'span'} fontWeight={500}>
            Android
          </Typography>
          , upload the{' '}
          <Typography component={'span'} color={'primary'} fontWeight={500}>
            .apk
          </Typography>{' '}
          containing your app.
        </Typography>

        <Box mb={2} sx={{ bgcolor: theme.palette.background.reportCard, py: 1.5 }}>
          <Typography
            variant="body"
            color={'primary'}
            onClick={() => toggleAccordion(2)}
            sx={{ cursor: 'pointer' }}>
            {activeAccordion === 2
              ? 'Hide detailed Android instructions'
              : 'Show detailed Android instructions'}
          </Typography>
          {activeAccordion === 2 && (
            <Box>
              <Typography variant="body">
                You can build your app via Android Studio, or by running the following command in
                your project directory:{' '}
              </Typography>

              <Editor
                height={'20px'}
                theme={'vs-dark'}
                value={'./gradlew <assembleDebug | assembleRelease>'}
                language={'shell'}
                options={editorOptions}
              />

              <Typography component={'div'} variant="body" sx={{ mt: 1 }}>
                You can then find the .apk file in:{' '}
                <Typography fontWeight={500} component={'span'}>
                  &lt;project-name&gt;/&lt;module-name&gt;/build/outputs/apk/.
                </Typography>
              </Typography>

              <Typography component={'div'} variant="body" sx={{ mt: 1 }}>
                For more information, please see our{' '}
                <a
                  href="https://docs.getscandium.com/mobile-application-testing/uploading-apps/android"
                  target="_blank"
                  rel="noreferrer">
                  docs
                </a>{' '}
                for Android
              </Typography>
            </Box>
          )}
        </Box>

        <Flex
          {...getRootProps()}
          sx={{
            bgcolor: theme.palette.background.reportCard,
            mt: 1,
            py: 3,
            cursor: 'pointer',
            justifyContent: 'center'
          }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography fontSize={'0.9rem'}>Drop the file here ...</Typography>
          ) : (
            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <CloudUploadIcon color={'primary'} sx={{ fontSize: '4rem' }} />
              <Typography fontSize={'0.95rem'}>
                Drag and Drop File here, or click to select one.
              </Typography>
            </Flex>
          )}
        </Flex>

        {selectedFile && (
          <Flex flexWrap={'wrap'}>
            <Typography as={'h6'} sx={{ fontSize: '0.9rem' }}>
              {selectedFile?.name}
            </Typography>
            <Tooltip title={'Delete the selected file'}>
              <IconButton size={'small'} onClick={clearFileInput}>
                <CloseIcon color={'error'} />
              </IconButton>
            </Tooltip>
          </Flex>
        )}

        <Flex sx={{ mt: 3 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton
            onClick={() => {
              onClose();
              clearFileInput();
            }}>
            Cancel
          </OutlinedButton>
          <Tooltip title={!selectedFile ? 'Select a file before saving' : ''}>
            <ContainedButton
              disabled={!selectedFile || !!isUploadingApp}
              isLoading={isUploadingApp}
              type={'submit'}
              color={'primary'}
              sx={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all !important'
                }
              }}
              autoFocus>
              Save
            </ContainedButton>
          </Tooltip>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default UploadAppModal;
