import React from 'react';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import { useTheme } from '@emotion/react';

const ConsoleLogs = ({ consoleLogs }) => {
  const theme = useTheme();
  return (
    <CustomScrollbar
      sx={{
        flex: 1,
        maxHeight: '48vh',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}>
      {!!consoleLogs?.length &&
        consoleLogs.map((log, i) => (
          <Box
            key={i}
            component={'pre'}
            sx={{
              bgcolor: theme.palette.background.lightGrey_dark,
              px: 2,
              py: 1,
              fontSize: '0.9rem',
              whiteSpace: 'pre-wrap'
            }}>
            {log}
          </Box>
        ))}

      {!consoleLogs?.length && (
        <Flex columnGap={3} sx={{ justifyContent: 'center', py: '10%', flexDirection: 'column' }}>
          <Typography variant={'body1'} textAlign={'center'}>
            Console logs from the device will appear here
          </Typography>
        </Flex>
      )}
    </CustomScrollbar>
  );
};

export default ConsoleLogs;
