import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';

const CheckboxItem = ({
  title,
  marginTopLength,
  cancelIcon
}) => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: marginTopLength ? marginTopLength : { xs: 1.5, sm: 1.5, }
      }}
    >
      {
        cancelIcon ? <CancelIcon
          sx={{
            color: '#FF0000',
            fontSize: 18
          }}
        /> : <CheckCircleRoundedIcon
          sx={{
            color: '#00CA08',
            fontSize: 18
          }}
        />
      }
      <Typography
        sx={{
          textAlign: 'left',
          typography: { xs: 'caption', sm: 'caption'},
          // fontSize: '0.70rem',
          pl: { xs: 1, sm: 1, }
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default CheckboxItem;
