import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Flex from '../../../components/base/Flex';
import FolderIcon from '@mui/icons-material/Folder';
import ActionMenu from './ActionMenu';
import { useScandiumMutation } from '../../../data-layer/utils';
import { toast } from 'react-toastify';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { StyledTableCell, StyledTableRow } from '../../projects/ProjectFolders';
import { useActiveProject } from '../../../store/projectState';
import { useTheme } from '@emotion/react';

const GroupedStepRow = ({ group, refetchGroups }) => {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const activeProject = useActiveProject();
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const theme = useTheme();

  const { mutateAsync: deleteGroup, isLoading: isDeleting } = useScandiumMutation(
    `/groups/${group?.id}/delete`,
    {
      method: 'DELETE',
      onSuccess: () => {
        refetchGroups();
        toast.success('Group deleted successfully');
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDeleteGroup = async () => {
    if (await requestConfirm()) return await deleteGroup();
  };

  return (
    <StyledTableRow
      onClick={(e) =>
        e.currentTarget.contains(e.target) && navigate(`/projects/${activeProject?.id}/folders/tests-group/${group.id}`)
      }>
      <StyledTableCell component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <FolderIcon sx={{ color: theme.palette.svg.main }} />
          <Typography fontWeight={500} sx={{ lineHeight: 1, mt: '4px' }}>
            {group.title}
          </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '0.8rem',
              fontWeight: '300'
            }}>
            {group.is_reusable ? '(Reusable)' : '(Non Reusable)'}
          </Typography>
        </Flex>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '0.9rem',
              fontWeight: '300'
            }}>
            {group.tests.length} {group.tests.length === 1 ? 'Test Case' : 'Test Cases'}
          </Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell>
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            isLoading={isDeleting}
            onDelete={handleDeleteGroup}
            ref={menuRef}
            groupId={group.id}
          />
        </Box>
      </StyledTableCell>
      <ConfirmationDialog
        title={'Are you sure you want to delete this Group'}
        description={'This action is not reversible'}
      />
    </StyledTableRow>
  );
};

export default GroupedStepRow;
