import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import useLogout from '../hooks/useLogout';
import { activeClassName } from './sidebar';
import { useFetchFolders } from '../data-layer/project-management';
import { useActiveProject } from '../store/projectState';
import { useScandiumMutation } from '../data-layer/utils';
import { toast } from 'react-toastify';
import { resetMixpanelData, TRACKING_IDS, trackMixPanel } from '../mixpanel.constants';
import { useQueryClient } from 'react-query';
import BackButton from './base/BackButton';
import { ColorContext } from '../AppTheme';

const ProjectFolderSidebar = ({ SidebarItems }) => {
  const { mode } = React.useContext(ColorContext);
  let navigate = useNavigate();
  const logout = useLogout();
  const activeProject = useActiveProject();
  const { projectId, folderId } = useParams();
  const queryClient = useQueryClient();

  const { data: parentFolder } = useFetchFolders(projectId, {
    folderId,
    enabled: !!folderId && !!activeProject?.id,
    select: (data) => data.data
  });

  const { mutateAsync: apiLogout } = useScandiumMutation('/auth/logout', {
    method: 'GET'
  });

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      await apiLogout();
      logout();
      queryClient.clear();
      resetMixpanelData();
    } catch (error) {
      toast.error('An error occurred, please try again');
    }
  };

  const nonActiveClassName = {
    color: mode === 'dark' ? '#ffffffb3' : '#111111',
    fontWeight: 400,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderLeft: '2px solid',
    borderLeftColor: '#EBEBEB'
  };

  return (
    <Box
      sx={{
        marginTop: { xs: '3.5rem', sm: '10rem' },
        mx: { xs: '1.5rem', sm: '2rem', md: '4rem' },
        color: 'inherit'
      }}>
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
          position: { xs: 'static', sm: 'fixed' },
          maxWidth: { xs: 'auto', sm: '13rem' }
        }}>
        {!!folderId && (
          <BackButton
            label={'Go up'}
            onClick={() =>
              navigate(`/projects/${projectId}/folders/${parentFolder?.parent_id || ''}`)
            }
          />
        )}
        <Typography
          color="primary"
          sx={{
            textAlign: 'left',
            fontSize: '1.1rem',
            mt: { xs: 4, sm: 1 }
          }}>
          {parentFolder?.name ? `${parentFolder.name}` : 'Test cases'}
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '0.8rem',
            mb: { xs: 4, sm: 3 }
          }}>
          {''}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'column' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: { xs: 'center', sm: 'flex-start' },
          height: { xs: 'auto', sm: 'auto' },
          width: { xs: '100%', sm: '12rem' },
          position: { xs: 'static', sm: 'fixed' },
          mt: { xs: 10, sm: 16 }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: { xs: 'flex-start', sm: 'center' },
            alignItems: { xs: 'center', sm: 'flex-start' },
            width: { xs: '100%', sm: 'auto' }
          }}>
          {SidebarItems.map((item, itemIndex) => (
            <NavLink
              key={item.name + item.path}
              to={{
                pathname: item.path,
                search: item.search || ''
              }}
              end
              style={({ isActive }) => (isActive ? activeClassName : nonActiveClassName)}>
              <Button
                onClick={() => {
                  item.name === 'New Test' &&
                    trackMixPanel(TRACKING_IDS.NEW_TEST_CLICKED, {
                      type: 'web',
                      position: 'sidebar'
                    });
                }}
                sx={{
                  typography: 'body2',
                  textTransform: 'capitalize',
                  color: 'inherit'
                }}>
                {item.name}
              </Button>
              {SidebarItems.length !== itemIndex + 1 && (
                <Box
                  sx={{
                    display: { xs: 'flex', sm: 'none' },
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  &#9474;
                </Box>
              )}
            </NavLink>
          ))}
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            mt: { xs: 5, sm: 16 }
          }}>
          <Typography
            sx={{
              cursor: 'pointer',
              textAlign: 'right',
              typography: { sm: 'subtitle2', md: 'subtitle2' },
              pt: { xs: 6, sm: 1 }
            }}>
            Need Help?
          </Typography>
          <Box target={'_blank'} as={'a'} href={'https://getscandium.com/faqs/'}>
            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textAlign: 'right',
                typography: { sm: 'caption', md: 'caption' },
                pt: { xs: 6, sm: 3 },
                color: '#6A6A6A'
              }}>
              See popular questions
            </Typography>
          </Box>
          <Typography
            color="error"
            sx={{
              cursor: 'pointer',
              align: 'right',
              typography: { sm: 'subtitle2', md: 'subtitle2' },
              pt: { xs: 6, sm: 1 }
            }}
            onClick={handleLogout}>
            Logout
          </Typography>
        </Box>
      </Box>
      <Box
        className="mainBlock"
        sx={
          {
            // bgcolor: "darkkhaki",
          }
        }>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ProjectFolderSidebar;
