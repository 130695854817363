import EmptyState from './EmptyState';
import React from 'react';
import OutlinedButton from './OutlinedButton';
import useLogout from '../../hooks/useLogout';

const statusMap = {
  404: '404: Not Found',
  401: 'You have been logged out',
  500: 'Sorry, an error occurred'
};

const statusDescriptions = {
  404: 'We could not find this resource. Do you have the correct URL?',
  401: 'Try to log in again',
  500: 'We are working to fix this as soon as possible. Feel free to reach out to our tech support about this issue'
};

const ErrorState = ({ error, title, description, cta }) => {
  const logout = useLogout();

  // Check if the error message is "Failed to fetch"
  const isOfflineError = error?.message === 'Failed to fetch';

  return (
    <EmptyState
      title={
        isOfflineError
          ? "Oops! It seems like you're offline. Please check your internet connection and try again."
          : title || statusMap[error?.status] || error?.message || 'Sorry, an error occurred'
      }
      description={description || statusDescriptions[error?.status] || ''}
      cta={
        error?.status === 401 ? (
          <OutlinedButton onClick={logout}>Go to Sign in</OutlinedButton>
        ) : (
          cta
        )
      }
    />
  );
};

export default ErrorState;
