import CustomModal from '../../../components/base/CustomModal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import CustomInput from '../../../components/customInput';
import { useScandiumQuery } from '../../../data-layer/utils';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Divider } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import Flex from '../../../components/base/Flex';
import EmptyState from '../../../components/base/EmptyState';
import { useDebounce } from 'use-debounce';
import { useUpdateGroup } from '../../../data-layer/groups';
import { toast } from 'react-toastify';
import CustomScrollbar from './CustomScrollbar';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase
} from '../../../components/helpers/inputHelper';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@emotion/react';

const GroupItem = ({ group, onClick }) => {
  return (
    <Box
      px={2}
      py={2}
      sx={{ cursor: 'pointer' }}
      backgroundColor={group.is_reusable ? '#DCF5F9' : '#F4F4F4'}
      borderRadius={'8px'}
      onClick={onClick}>
      <Flex columnGap={2}>
        <FolderIcon sx={{ color: '#24C3D9' }} />
        <Typography fontWeight={500} color={'black'} sx={{ lineHeight: 1, mt: '4px' }}>
          {group.title}
        </Typography>
      </Flex>
    </Box>
  );
};
const AddToGroup = ({
  open,
  onClose,
  testGroups = [],
  // TODO: this function should be called onGroupAttached
  onStepsAdded,
  selectedSteps,
  addSteps = true,
  insertAfter = false
}) => {
  const [filter, setFilter] = React.useState('');
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [debouncedQuery] = useDebounce(filter, 600);
  const { testId, projectId } = useParams();
  const theme = useTheme();

  const nonReusableGroups = testGroups.filter((group) => !group.is_reusable);

  const { data: groups, isLoading } = useScandiumQuery('/groups', {
    params: {
      project_id: projectId,
      is_reusable: 1,
      search: debouncedQuery || null
    },
    select: (response) => response.data
  });

  const { mutateAsync: updateGroup, isLoading: isSubmitting } = useUpdateGroup();

  const addStepsToGroup = async (group) => {
    try {
      setSelectedGroup(group);
      const response = await updateGroup({
        groupId: group.id,
        data: {
          ...group,
          test_id: testId,
          steps: [...(group.steps || []), ...(addSteps ? selectedSteps : [])]
        }
      });
      toast.success('Steps added to group successfully');
      onStepsAdded(response.data, addSteps, insertAfter);
    } catch (e) {
      setSelectedGroup(null);
      toast.error(e.message);
    }
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          mb={3}
          sx={{
            fontSize: '1.5rem'
          }}>
          Add to group
        </Typography>

        <Box mb={3} width={'200px'}>
          <Box>
            <Search sx={{ width: { xs: 'max-content' } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              />
            </Search>
          </Box>
        </Box>

        {isLoading && (
          <Box
            height={'250px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <CircularProgress color={'secondary'} size={26}></CircularProgress>
          </Box>
        )}

        {!isLoading && groups?.length === 0 && (
          <EmptyState
            title={'There are no matching groups'}
            description={'You can create a new group or adjust your search query'}
          />
        )}

        <CustomScrollbar
          sx={{
            overflowY: 'auto',
            maxHeight: '350px',
            overflowX: 'hidden'
          }}>
          {!isLoading &&
            nonReusableGroups.map((group, index) => (
              <React.Fragment key={index}>
                <GroupItem
                  isLoading={selectedGroup?.id === group.id && isSubmitting}
                  group={group}
                  onClick={() => addStepsToGroup(group)}
                />
                {(index + 1 < testGroups.length || !!groups?.length) && <Divider sx={{ my: 1 }} />}
              </React.Fragment>
            ))}

          {groups?.map((group, index) => (
            <React.Fragment key={index}>
              <GroupItem
                isLoading={selectedGroup?.id === group.id && isSubmitting}
                group={group}
                onClick={() => addStepsToGroup(group)}
              />
              {index + 1 < groups.length && <Divider sx={{ my: 1 }} />}
            </React.Fragment>
          ))}
        </CustomScrollbar>
      </Box>
    </CustomModal>
  );
};
export default AddToGroup;
