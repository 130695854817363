import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { toHexAlpha } from '../../../utils/colors';
import TestRunsSvg from '../../../components/svg/TestRunsSvg';
import { StatusDot } from '../../dashboard/DashboardCards';

const colorMap = {
  testCases: {
    text: 'primary',
    border: '#FFD600',
    backgroundOpacity: 15,
    icon: '#FFD600'
  },
  localRuns: {
    text: 'secondary',
    border: '#1958B8',
    backgroundOpacity: 6,
    icon: '#24C3D9'
  },
  remoteRuns: {
    text: '#1958B8',
    border: '#24C3D9',
    backgroundOpacity: 15,
    icon: '#1958B8'
  }
};

const DashboardCard = ({ cardType, children, ...props }) => {
  return (
    <Box
      sx={{
        textDecoration: 'none',
        border: `1px solid ${colorMap[cardType].border}`,
        backgroundColor: toHexAlpha(
          colorMap[cardType].border,
          colorMap[cardType].backgroundOpacity
        ),
        color: '#6A6A6A',
        px: 1.5,
        py: 1.5,
        borderRadius: '8px',
        position: 'relative'
      }}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-around'}
      gap={1}
      {...props}>
      {children}
    </Box>
  );
};

const MembersOverview = ({ data }) => {
  return (
    <DashboardCard cardType={'remoteRuns'} to={`#`}>
      <Box
        borderRadius={'4px'}
        px={1}
        py={1}
        backgroundColor={toHexAlpha(colorMap['remoteRuns'].icon, 21)}>
        <TestRunsSvg
          width={32}
          height={32}
          sx={{ display: 'block', color: colorMap['remoteRuns'].icon }}
        />
      </Box>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-around'} flex={1}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            color={colorMap['remoteRuns'].text}
            fontSize={'3rem'}
            fontWeight={600}
            lineHeight={1}>
            {data?.members_count || 0} / {data?.settings?.max_allowed_users || 0}
          </Typography>
          <Typography>Team Members</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            color={colorMap['remoteRuns'].text}
            fontSize={'3rem'}
            fontWeight={600}
            lineHeight={1}>
            {data?.projects_count || 0} / {data?.settings?.max_allowed_projects || 0}
          </Typography>
          <Typography>Projects</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography
              color={colorMap['remoteRuns'].text}
              fontSize={'1.5rem'}
              fontWeight={600}
              lineHeight={1}></Typography>
            {/* <StatusDot status={'success'} /> */}
          </Box>
          {/* <Typography fontSize={'0.7rem'}>Passed</Typography> */}
        </Box>
      </Box>
    </DashboardCard>
  );
};

export default MembersOverview;
