import React, { useRef, useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  Box,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  Menu
} from '@mui/material';
import Flex from '../../components/base/Flex';
import ContainedButton from '../../components/base/ContainedButton';
import BackButton from '../../components/base/BackButton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import useAwaitModal from '../../hooks/useAwaitModal';
import CreateGlobalVariablesModal from './components/CreateGlobalVariablesModal';
import PageLoader from '../../components/PageLoader';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import AddIcon from '@mui/icons-material/Add';
import MoreVert from '@mui/icons-material/MoreVert';
import { useMenu } from '../../components/base/useMenu';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import { useGlobalVariables } from '../../store/globalVariables';
import { useContext } from 'react';
import { ColorContext } from '../../AppTheme';

const ActionMenu = React.forwardRef(({ isLoading, onDelete, onEdit }, ref) => {
  const { menuProps, triggerProps } = useMenu();
  const activeProject = useActiveProject();

  const readyOnly = activeProject?.read_only;

  return (
    <div>
      <IconButton
        ref={ref}
        aria-controls={menuProps.open ? 'action-menu' : undefined}
        aria-haspopup="true"
        disabled={isLoading}
        aria-expanded={menuProps.open ? 'true' : undefined}
        {...triggerProps}>
        {isLoading ? <CircularProgress size={18} color={'primary'} /> : <MoreVert />}
      </IconButton>
      <Menu id="action-menu" {...menuProps} elevation={1}>
        <MenuItem disabled={!!readyOnly} onClick={onEdit}>
          Edit
        </MenuItem>
        <MenuItem disabled={!!readyOnly} sx={{ color: 'red' }} onClick={onDelete}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
});

function GlobalVariables() {
  const { mode } = useContext(ColorContext);
  const { globalVariables, IsLoadingVariables, refetchVariables } = useGlobalVariables();
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [deletionIndex, setDeletionIndex] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const activeProject = useActiveProject();
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();

  const [
    requestCreateGlobalVariableModal,
    {
      open: openCreateGlobalVariableModal,
      onClose: onCloseCreateGlobalVariableModal,
      onComplete: completeCreateGlobalVariableModal
    }
  ] = useAwaitModal();

  const { mutateAsync: createVariable, isLoading: isCreatingVariable } = useScandiumMutation(
    `/projects/${activeProject?.id}/variables`,
    {
      onSuccess: (data) => {
        refetchVariables();
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  const handleDeleteVariable = async (index) => {
    const _variables = (globalVariables || [])
      .filter((_, i) => i !== index)
      .map(({ uuid, project_id, ...rest }) => rest);

    const response = await createVariable({ variables: _variables });
    response?.success && toast.success('Project variable deleted successfully');
  };

  const onDeleteVariable = async (index) => {
    if (await requestConfirm()) {
      setDeletionIndex(index);
      return await handleDeleteVariable(index);
    }
  };

  return (
    <Box sx={{ mb: '160px' }}>
      <BackButton label={'Back'} onClick={() => navigate('..')} />
      <Typography
        color="primary"
        sx={{
          textAlign: 'left',
          fontSize: '1.2rem',
          mb: 2
        }}>
        Global Variables
      </Typography>
      <Flex sx={{ mb: 2 }}>
        <ContainedButton
          sx={{ minHeight: '28px' }}
          startIcon={<AddIcon />}
          onClick={async () => {
            await setSelectedVariable(null);
            requestCreateGlobalVariableModal();
          }}>
          New variable
        </ContainedButton>
      </Flex>

      <Grid
        container
        sx={{
          borderBottom: '1px solid lightgray',
          px: '16px',
          py: '12px',
          backgroundColor: mode === 'dark' ? 'rgba(200, 200, 200, 0.5)' : '#F5F5F5'
        }}>
        <Grid item xs={5}>
          <Typography variant="body1" fontWeight={500}>
            Names
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1" fontWeight={500}>
            Values
          </Typography>
        </Grid>
      </Grid>

      <List>
        {globalVariables?.map((variable, index) => (
          <ListItem
            key={index}
            sx={{
              backgroundColor: mode === 'dark' ? 'rgba(200, 200, 200, 0.2)' : '#F6FAFF',
              py: '6px',
              px: '16px',
              marginBottom: '5px',
              borderRadius: '5px',
              borderBottom: '1px solid lightgray'
            }}>
            <Grid container alignItems="center" columnSpacing={4}>
              <Grid item xs={5}>
                <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
                  {variable.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'body2'} sx={{ wordWrap: 'break-word' }}>
                  {variable.value}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <ActionMenu
                  isLoading={deletionIndex === index && isCreatingVariable}
                  onDelete={() => onDeleteVariable(index)}
                  onEdit={async () => {
                    await setSelectedVariable(variable);
                    requestCreateGlobalVariableModal();
                  }}
                  ref={menuRef}
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>

      {IsLoadingVariables && <PageLoader height={'100px'} />}
      {!IsLoadingVariables && !globalVariables?.length && (
        <EmptyState
          title="It's lonely here at the moment"
          description="Why not create a few variables?"
          cta={
            <Flex>
              <OutlinedButton onClick={() => requestCreateGlobalVariableModal()}>
                New variable
              </OutlinedButton>
            </Flex>
          }
        />
      )}

      <CreateGlobalVariablesModal
        open={openCreateGlobalVariableModal}
        onClose={onCloseCreateGlobalVariableModal}
        onComplete={completeCreateGlobalVariableModal}
        createVariable={createVariable}
        variables={globalVariables || []}
        isLoading={isCreatingVariable}
        selectedVariable={selectedVariable}
      />

      <ConfirmationDialog
        title={'Are you sure you want to delete this variable?'}
        description={
          "Are you sure you want to delete this global variable? Deleting it might cause unexpected errors in other parts of the app where it's used."
        }
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </Box>
  );
}

export default GlobalVariables;
