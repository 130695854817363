import DatasetIcon from '@mui/icons-material/Dataset';
import IconButton from '@mui/material/IconButton';
import { Box, Menu, Grid, Typography, Divider, Tooltip } from '@mui/material';
import { useMenu } from '../../../components/base/useMenu';
import { Fragment } from 'react';

const DataSetMenu = ({ previousRun }) => {
  const { triggerProps, menuProps } = useMenu();

  if (!previousRun?.testData?.total_loops) return null;

  return (
    <div>
      <Tooltip title={'Data params'}>
        <IconButton
          size={'small'}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuProps.open ? 'true' : undefined}
          {...triggerProps}>
          <DatasetIcon color={'primary'} />
        </IconButton>
      </Tooltip>
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            maxWidth: 250
          },
          sx: {
            bgcolor: '#F6F6F6',
            py: 0
          }
        }}>
        <Box py={1} sx={{ bgcolor: '', my: 0 }}>
          <Grid container spacing={1} px={2}>
            <Grid item xs={5}>
              <Typography variant="body2">Current Index</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                textTransform={'capitalize'}
                fontWeight={'medium'}
                variant="body2"
                sx={{ wordWrap: 'break-word' }}>
                {previousRun?.testData?.current_index + 1} / {previousRun?.testData?.total_loops}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={1} px={2}>
            {Object.entries(previousRun?.testData?.data_params)?.map(([key, value], i) => (
              <Fragment key={i}>
                <Grid item xs={5}>
                  <Typography variant="body2">{key}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    textTransform={'capitalize'}
                    fontWeight={'medium'}
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}>
                    {value}
                  </Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </Menu>
    </div>
  );
};

export default DataSetMenu;
