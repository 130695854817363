import React from 'react';
import { useCookies } from 'react-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ScandiumLogoSolid from '../../components/svg/ScandiumLogoSolid';
import SpinnerSvg from '../../components/svg/SpinnerSvg';
import { useScandiumQuery } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const VerifyInviteCode = () => {
  useDocumentTitle('Scandium - Join Project');
  const [cookies, setCookie] = useCookies(['email']);

  const navigate = useNavigate();
  const { inviteCode } = useParams();

  const { data, isLoading, error } = useScandiumQuery(`/join-project/${inviteCode}`, {
    requireAuth: false,
    onError: (error) => {
      if (error.status === 400) {
        toast.info('Your invite link has already been verified! log in to continue using Scandium');
        navigate('/');
      } else {
        toast.error(error.message);
      }
    },
    onSuccess: (data) => {
      toast.success(
        data.user_is_new
          ? 'Welcome to Scandium! To get started, please register an account'
          : 'Welcome back! Please log in to continue using Scandium'
      );
      setCookie('email', data.email, { path: '/', sameSite: 'lax' });
      navigate(data.user_is_new ? `/register/${inviteCode}` : '/');
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#FFFFFF'
      }}>
      <ScandiumLogoSolid width={40} height={40} />
      <SpinnerSvg />
      <Typography textAlign={'center'} sx={{ fontWeight: 600, opacity: 0.8 }}>
        Validating your invite. Please wait a moment.
      </Typography>
    </Box>
  );
};

export default VerifyInviteCode;
