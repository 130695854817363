import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomInput from '../../../components/customInput';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const CreateGlobalVariablesModal = ({
  open,
  onClose,
  onComplete,
  createVariable,
  variables,
  isLoading,
  selectedVariable
}) => {
  const [name, setName] = useState(selectedVariable?.name || '');
  const [value, setValue] = useState(selectedVariable?.value || '');

  useEffect(() => {
    if (selectedVariable) {
      setName(selectedVariable.name);
      setValue(selectedVariable.value);
    } else {
      setName('');
      setValue('');
    }
  }, [selectedVariable]);

  const handleCreateVariable = async () => {
    if (!name || !value) {
      toast.error('Please provide a variable name and value');
      return;
    }

    // find variable index for updating
    const variableIndex = variables.findIndex(
      (variable) => variable.uuid === selectedVariable?.uuid
    );

    const _variables = variables.map(({ uuid, project_id, ...rest }) => rest);
    const updateValuesArray = variables
      .filter((variable) => variable.uuid !== selectedVariable?.uuid)
      .map(({ uuid, project_id, ...rest }) => rest);
    updateValuesArray.splice(variableIndex, 0, { name, value });

    const response = await createVariable({
      variables: selectedVariable ? updateValuesArray : [..._variables, { name, value }]
    });
    response?.success &&
      toast.success(
        selectedVariable
          ? 'Project variables updated successfully'
          : 'Project variables saved successfully'
      );
    onComplete();
    setName('');
    setValue('');
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          {selectedVariable?.name ? 'Update Variable' : 'Create Global Variable'}
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          {selectedVariable
            ? 'Update the value of the existing variable by updating the variable name or the value in the input fields below'
            : 'Enter a variable name and a value in the input fields below'}
        </Typography>

        <Box mb={1.5}>
          <CustomInput
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            size={'small'}
            label={'Enter variable name'}
            fullWidth
          />
        </Box>
        <Box mb={3}>
          <CustomInput
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
            size={'small'}
            label={'Enter a value'}
            fullWidth
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            isLoading={isLoading}
            onClick={handleCreateVariable}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateGlobalVariablesModal;
