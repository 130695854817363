import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.button.border,
  borderStyle: "solid",
  borderWidth: "0.03rem",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  // backgroundColor: 'blue',
  width: "100%",
  height: "2rem",
  display: "flex",
  alignItems: "center",
  // justifyContent: 'center',
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    // width: 'auto',
    width: "60%",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.4,
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 0, 1, 0),
    // vertical padding + font size from searchIcon
    paddingRight: "0.8rem",
    transition: theme.transitions.create("width"),
    width: "100%",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "2.5rem",
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));
