export const formatDate = (dateString) => {
  let date = new Date(dateString);
  let months = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.'
  ];
  let month = months[date.getMonth()];
  let day = date.getDate();
  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }
  let hour = date.getHours();
  let minute = date.getMinutes();
  let ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12 || 12;

  return `${month} ${day}${suffix} ${date.getFullYear()} | ${hour}:${minute
    .toString()
    .padStart(2, '0')}${ampm}`;
};
