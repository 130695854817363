import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContainedButton from '../../../components/base/ContainedButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMenu } from '../../../components/base/useMenu';

const EditTestMenu = ({ requestSaveTestModal }) => {
  const { menuProps, triggerProps } = useMenu();

  return (
    <Box>
      <ContainedButton
        {...triggerProps}
        sx={{
          borderTopLeftRadius: '0rem',
          borderBottomLeftRadius: '0rem',
          borderTopRightRadius: '0.4rem',
          borderBottomRightRadius: '0.4rem',
          minWidth: '25px',
          borderLeft: 'none',
          ml: -1,
          px: '0.2rem'
        }}>
        <ArrowDropDownIcon sx={{ color: '#fff' }} />
      </ContainedButton>
      <Menu id="action-menu" {...menuProps} elevation={1} PaperProps={{ style: { width: 150 } }}>
        <MenuItem
          onClick={() => {
            requestSaveTestModal();
            menuProps.onClose();
          }}>
          Edit Test Details
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default EditTestMenu;
