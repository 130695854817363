import { SvgIcon } from '@mui/material';

const ReplaySvg = ({ width = 20, height = 20, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 11 10'} {...props} fill={'none'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.02923 6.28263C1.4924 8.37211 3.35292 9.87041 5.50501 9.87041C7.25617 9.87041 8.77801 8.88855 9.55005 7.44541L8.0731 7.44545C7.45762 8.17663 6.53557 8.64126 5.50501 8.64126C4.0317 8.64126 2.73446 7.68042 2.30025 6.28265L2.97694 6.28263L1.66473 3.6582L0.352539 6.28263H1.02923Z"
        fill={disabled ? '#5e6062' : fill || '#1958B8'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98102 4.28944C9.5178 2.19993 7.65734 0.70166 5.50524 0.70166C3.75408 0.70166 2.23225 1.68351 1.46021 3.12671H2.93708C3.55254 2.39548 4.47463 1.93081 5.50524 1.93081C6.75828 1.93081 8.56918 2.97885 8.71001 4.28947H8.03331L9.34553 6.91389L10.6577 4.28947L9.98102 4.28944Z"
        fill={disabled ? '#5e6062' : fill || '#1958B8'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08564 5.49558L4.79921 6.81566C4.72228 6.86007 4.6342 6.86007 4.55725 6.81566C4.48033 6.77123 4.43628 6.69495 4.43628 6.60611V3.96598C4.43628 3.87714 4.48033 3.80088 4.55725 3.75645C4.6342 3.71204 4.72228 3.71204 4.79921 3.75645L7.08564 5.07653C7.16256 5.12094 7.20659 5.19722 7.20659 5.28606C7.20659 5.37489 7.16256 5.45118 7.08564 5.49558Z"
        fill={disabled ? '#5e6062' : fill || '#1958B8'}
      />
    </SvgIcon>
  );
};

export default ReplaySvg;
