import { useCookies } from 'react-cookie';
import { getQueryFn, getReactQueryKey, useScandiumMutation } from './utils';

export const useUpdateGroup = (options = {}) => {
  const [cookies] = useCookies(['token', 'email', 'name']);

  const mutationKey = getReactQueryKey('/groups/{groupId}/update');
  const mutationFn = ({ groupId, data }) =>
    getQueryFn({
      path: `/groups/${groupId}/update`,
      token: cookies?.token,
      method: 'PUT'
    })(data);
  return useScandiumMutation('', {
    mutationFn,
    mutationKey,
    ...options
  });
};

export const useDissociateGroup = (options = {}) => {
  const [cookies] = useCookies(['token', 'email', 'name']);

  const mutationKey = getReactQueryKey('/groups/{groupId}/dissociate-test');
  const mutationFn = ({ groupId, data }) =>
    getQueryFn({
      path: `/groups/${groupId}/dissociate-test`,
      token: cookies?.token,
      method: 'POST'
    })(data);
  return useScandiumMutation('', {
    mutationFn,
    mutationKey,
    ...options
  });
};
