// converts a given number opacity to hex (00 to FF). Useful for calculating hex + alpha colors
import { hexToRgb } from "@mui/material";

export const opacityToHex = (opacity) => {
  if (opacity > 100) return "FF";
  if (opacity < 0) return "00";
  // From ascii character code. 48 to 70, removing 58 to 64
  const asciiKeys = [...Array(23).keys()]
    .filter((i) => i < 11 || i > 17)
    .map((i) => i + 48);

  const base16Percentage = (opacity / 100) * 255;
  const quotient = Math.floor(base16Percentage / 16);
  const remainder = Math.round(base16Percentage % 16);
  return `${String.fromCharCode(asciiKeys[quotient])}${String.fromCharCode(
    asciiKeys[remainder]
  )}`;
};

export const toHexAlpha = (color, opacity) => {
  return `${color.toUpperCase()}${opacityToHex(opacity)}`;
};

export const rgba = (color, opacity) => {
  return `rgba(${hexToRgb(color).slice(4, -1)}, ${(opacity / 100).toFixed(2)})`;
};
