import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer
} from 'recharts';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { ColorContext } from '../../../AppTheme';

const LineChartComponent = ({ data = [] }) => {
  const runsDurations = data?.map((item) => ({
    date: item.date,
    duration: parseFloat(item?.average_duration_seconds || item?.average_time_taken).toFixed(2)
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={runsDurations}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: 10 }} dataKey="date" />
        <YAxis tick={{ fontSize: 12 }} />
        <Tooltip />
        <Legend />
        <Line dataKey="duration" stroke="#8884d8" name="Runs Duration (secs)" />
      </LineChart>
    </ResponsiveContainer>
  );
};

const BarChartComponent = ({ testcases = [], runsCount = [] }) => {
  const allDates = Array.from(
    new Set([...testcases.map((tc) => tc.date), ...runsCount.map((rc) => rc.date)])
  );

  const combinedData = allDates
    .map((date) => {
      const testcaseData = testcases.find((tc) => tc.date === date);
      const runsCountData = runsCount.find((rc) => rc.date === date);
      return {
        date,
        testcases: testcaseData ? testcaseData.count : 0,
        runs: runsCountData ? runsCountData.count : 0
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={combinedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: 9 }} dataKey={"date"} />
        <YAxis tick={{ fontSize: 12 }} />
        <Tooltip />
        <Legend />
        <Bar dataKey="testcases" fill="#8884d8" name="Testcases" />
        <Bar dataKey="runs" fill="#82ca9d" name="Runs" />
      </BarChart>
    </ResponsiveContainer>
  );
};

const ReportCard = ({ title, icon, data }) => {
  const theme = useTheme();
  const { mode } = useContext(ColorContext)

  const iconBackground = mode === 'light' ? '#F6F6F6' : 'rgba(40,40,40)'

  return (
    <Box mb={8}>
      <Typography variant={'subtitle1'} color={'primary'} fontWeight={500}>
        {title}
      </Typography>
      <Box sx={{ bgcolor: theme.palette.background.reportCard, p: 2 }}>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} md={5}>
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 1,
                height: '100%'
              }}>
              <Flex
                sx={{
                  background: theme.palette.background.default,
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                  py: 4,
                  px: 4,
                  gap: 2,
                  flex: 1
                }}>
                <Box borderRadius={'4px'} px={1} py={1} backgroundColor={iconBackground}>
                  {icon}
                </Box>
                <Flex
                  sx={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: { xs: 4, sm: 4, md: 9 }
                  }}>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start'
                    }}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Tests Created
                    </Typography>
                    <Typography
                      fontSize={'3.5rem'}
                      color={'primary'}
                      fontWeight={600}
                      lineHeight={1}>
                      {data?.testsCount || 0}
                    </Typography>
                  </Flex>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start'
                    }}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Tests Runs
                    </Typography>
                    <Typography
                      fontSize={'3.5rem'}
                      color={'primary'}
                      fontWeight={600}
                      lineHeight={1}>
                      {data?.passedRunsPercentage ? Math.round(data?.passedRunsPercentage) : 0}%
                    </Typography>
                    <Typography variant={'body2'}>
                      {data?.passedRunsCount || 0} of {data?.runsCount || 0} tests passed
                    </Typography>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                sx={{
                  bgcolor: theme.palette.background.default,
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                  py: 4,
                  px: 4,
                  gap: 2,
                  flex: 1
                }}>
                <Box borderRadius={'4px'} px={1} py={1} backgroundColor={iconBackground}>
                  {icon}
                </Box>
                <Flex
                  sx={{
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start'
                    }}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Average Runs Duration
                    </Typography>
                    <Typography
                      fontSize={'3.5rem'}
                      color={'primary'}
                      fontWeight={600}
                      lineHeight={1}>
                      {data?.averageRunsDuration?.toFixed(2) || 0}sec
                    </Typography>
                    <Typography variant={'caption'}>Durations for all test runs</Typography>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Grid>
          <Grid item xs={12} sx={{ height: '100%' }} md={7}>
            <Flex sx={{ flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
              <Box backgroundColor={theme.palette.background.default} sx={{ height: 300, width: '100%' }}>
                <BarChartComponent
                  testcases={data?.graph?.testsCountByDate || []}
                  runsCount={data?.graph?.runsCountByDate || []}
                />
              </Box>
              <Box backgroundColor={theme.palette.background.default} sx={{ height: 300, width: '100%' }}>
                <LineChartComponent data={data?.graph?.averageRunsDurationByDate || []} />
              </Box>
            </Flex>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReportCard;
