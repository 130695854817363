import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import Flex from '../../../../components/base/Flex';
import TextButton from '../../../../components/base/TextButton';
import BiometericSvg from '../../../../components/svg/BiometricSvg';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMenu } from '../../../../components/base/useMenu';
import { useState } from 'react';

const Biometrics = ({
  orientation,
  setActions,
  setUnsaved,
  session,
  insertEventsAtRef,
  theme,
  isRecordingMode
}) => {
  const [biometric, setBiometric] = useState(null);
  const { triggerProps, menuProps } = useMenu();

  const handleSaveStep = async (event) => {
    if (event.target.value === null) {
      toast.error('Please make a true or false selection before saving the step');
      return;
    }

    setBiometric(event.target.value);

    await session.biometry({
      match: event.target.value
    });

    const data = {
      type: 'biometry',
      value: event.target.value,
      id: v4(),
      timeout: 15000,
      step_delay: 1000
    };

    if (insertEventsAtRef.current !== null) {
      const insertIndex = insertEventsAtRef.current + 1;
      setActions((prevActions) => [
        ...prevActions.slice(0, insertIndex),
        { ...data },
        ...prevActions.slice(insertIndex)
      ]);
      insertEventsAtRef.current = insertIndex;
    } else {
      setActions((prevActions) => [...prevActions, { ...data }]);
    }

    setBiometric(null);
    setUnsaved(true);
    toast.success('New Biometric step added successfully');
    menuProps.onClose();
  };

  const anchorOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'right' }
      : { vertical: 'bottom', horizontal: 'left' };

  const transformOrigin =
    orientation === 'portrait'
      ? { vertical: 'center', horizontal: 'left' }
      : { vertical: 'top', horizontal: 'left' };

  return (
    <Box>
      <TextButton
        disabled={!session || !isRecordingMode}
        sx={{ py: 1 }}
        {...triggerProps}
        aria-controls="simple-menu"
        aria-haspopup="true">
        <Flex columnGap={2} sx={{ flexDirection: 'column' }}>
          <BiometericSvg fill={theme.palette.svg.primary} />
          <Typography
            variant={'body2'}
            color={theme.palette.text.black_grey}
            fontWeight={500}
            fontSize={'0.85rem'}>
            Biometrics
          </Typography>
        </Flex>
      </TextButton>
      <Menu
        id="simple-menu"
        {...menuProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={1}
        PaperProps={{
          style: {
            width: 'max-content',
            paddingLeft: 5
          }
        }}>
        <FormControl sx={{ px: 2 }}>
          <RadioGroup
            row
            size={'small'}
            aria-labelledby="demo-radio-buttons-group-label"
            value={biometric}
            onChange={handleSaveStep}
            name="radio-buttons-group">
            <FormControlLabel value={true} control={<Radio />} label="True" />
            <FormControlLabel value={false} control={<Radio />} label="False" />
          </RadioGroup>
        </FormControl>
      </Menu>
    </Box>
  );
};

export default Biometrics;
