import { createContext, useContext, useState } from 'react';

const RedirectContext = createContext();

export const RedirectProvider = ({ children }) => {
  const [redirectPath, setRedirectPath] = useState('/dashboard');

  return (
    <RedirectContext.Provider value={{ redirectPath, setRedirectPath }}>
      {children}
    </RedirectContext.Provider>
  );
};

export const useRedirect = () => useContext(RedirectContext);
