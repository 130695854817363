import React from 'react';

const AbortSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.60084 9.79524C7.09111 9.79524 9.10988 7.77647 9.10988 5.2862C9.10988 2.79593 7.09111 0.777161 4.60084 0.777161C2.11056 0.777161 0.0917969 2.79593 0.0917969 5.2862C0.0917969 7.77647 2.11056 9.79524 4.60084 9.79524Z"
        fill="#000000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.95155 3.44879H6.25069C6.35417 3.44879 6.43857 3.53319 6.43857 3.63667V6.93581C6.43857 7.03929 6.35417 7.12368 6.25069 7.12368H2.95155C2.84808 7.12368 2.76367 7.03929 2.76367 6.93581V3.63667C2.76369 3.53319 2.84808 3.44879 2.95155 3.44879Z"
        fill="white"
      />
    </svg>
  );
};

export default AbortSvg;
