import { SvgIcon } from '@mui/material';

const SkippedSvg = ({ width = 20, height = 20, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox="0 0 48 48" {...props} fill={'none'}>
      <g>
        <g>
          <rect width="48" height="48" fill="none" />
        </g>
        <g>
          <g>
            <circle cx="8" cy="37" r="6" />
            <path d="M24,31a6,6,0,1,0,6,6A6,6,0,0,0,24,31Zm0,8a2,2,0,1,1,2-2A2,2,0,0,1,24,39Z" />
            <circle cx="40" cy="37" r="6" />
            <path d="M37.4,19.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l4,3.9a1.9,1.9,0,0,0,2.8,0l4-3.9a2.3,2.3,0,0,0,.3-2.7,2,2,0,0,0-3.1-.2l-.6.6A18,18,0,0,0,6,21v2a2,2,0,0,0,4,0V21a14,14,0,0,1,28-.9Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default SkippedSvg;
