import { Box, Divider } from '@mui/material';
import { useCookies } from 'react-cookie';
import Typography from '@mui/material/Typography';
import React from 'react';
import { capitalize } from '../../utils/strings';
import DashboardCards from './DashboardCards';
import Flex from '../../components/base/Flex';
import LinkButton from '../../components/base/LinkButton';
import PageLoader from '../../components/PageLoader';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumQuery } from '../../data-layer/utils';
import ResultsTable from '../TestRuns/components/ResultsTable';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import ErrorState from '../../components/base/ErrorState';
import InviteCollaboratorsCta from '../../components/InviteCollaboratorsCta';
import { useActiveProject } from '../../store/projectState';

const Dashboard = () => {
  const [cookies] = useCookies(['name', 'token', 'email', 'projectType']);
  const { projectId } = useParams();
  useDocumentTitle('Scandium | Dashboard');
  const activeProject = useActiveProject()

  const navigate = useNavigate();
  const readyOnly = activeProject?.read_only;

  const projectType = activeProject?.type || cookies?.projectType || 'web';

  const {
    data: projectInfo,
    error: projectFetchError,
    isFetchingProjectInfo
  } = useScandiumQuery(`/projects/${projectId}`, {
    enabled: !!projectId,
    select: (data) => ({ ...data.project, runsCount: data.count, api: data?.api})
  });

  const { data, error, isLoading } = useScandiumQuery(`/projects/${projectId}/test-runs?limit=10`, {
    enabled: !!projectId,
    select: (data) => data.results || data
  });

  const testRuns = data?.runs?.data || data;

  if (isFetchingProjectInfo || (!projectInfo && !projectFetchError)) return <PageLoader />;

  if (projectFetchError) return <ErrorState error={projectFetchError} />;

  const onNavigate = (row) => {
    return navigate(`/projects/${projectId}/test-runs/${row.id}`);
  };

  return (
    <Box sx={{ mt: -2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '1rem',
        }}>
        <Box sx={{ display: 'flex' }} my={1}>
          <Typography
            sx={{
              cursor: 'pointer',
              textAlign: 'right',
              fontSize: '1.5rem',
              pr: { xs: 1, sm: 1 }
            }}>
            Welcome,
          </Typography>
          <Typography
            sx={{
              cursor: 'pointer',
              textAlign: 'right',
              fontWeight: 'bold',
              fontSize: '1.5rem'
            }}>
            {capitalize(cookies?.name?.split(' ')[0])}
          </Typography>
        </Box>
        <InviteCollaboratorsCta />
      </Box>
      <DashboardCards project={projectInfo} />

      <Divider sx={{ my: 4 }} />

      <Box>
        <Flex alignItems={'center'} justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Typography fontWeight={'600'} as={'h2'} fontSize={'1.2rem'}>
            Latest Runs
          </Typography>

          <LinkButton
            to={
              projectType === 'web'
                ? `/projects/${projectId}/test-results`
                : `/projects/${projectId}/mobile-test-runs`
            }>
            View All
          </LinkButton>
        </Flex>

        <ResultsTable onNavigate={onNavigate} testRuns={testRuns} />

        {(isLoading || (!error && !testRuns)) && <PageLoader height={'100px'} />}

        {!isLoading &&
          (!!error ? (
            <ErrorState error={error} />
          ) : (
            testRuns?.length === 0 && (
              <EmptyState
                title={
                  projectInfo?.test_cases_count > 0
                    ? 'You have not run any tests'
                    : 'Welcome to Scandium'
                }
                description={
                  projectInfo?.test_cases_count > 0
                    ? 'You can run some tests from the tests page'
                    : 'Get started by creating a few tests'
                }
                cta={
                  <OutlinedButton
                    component={NavLink}
                    disabled={!!readyOnly}
                    to={projectInfo?.test_cases_count > 0 ? '/folders' : '/folders/tests/new'}>
                    {projectInfo?.test_cases_count > 0 ? 'View all tests' : 'Create new test'}
                  </OutlinedButton>
                }></EmptyState>
            )
          ))}
      </Box>
    </Box>
  );
};

export default Dashboard;
