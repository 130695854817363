import Box from '@mui/material/Box';
import { v4 } from 'uuid';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import CustomModal from '../../../components/base/CustomModal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/customButton';
import { useParams } from 'react-router-dom';
import ContainedButton from '../../../components/base/ContainedButton';

export const assertionTypes = [
  { value: 'component', label: 'Component' },
  { value: 'text', label: 'Text' },
  { value: 'numeric', label: 'Numeric' }
];

export const componentSubtypes = [
  { value: 'component present', label: 'Component is present' },
  { value: 'component not present', label: 'Component is not present' }
  //   { value: 'component-enabled', label: 'Component is enabled' },
  //   { value: 'component-disabled', label: 'Component is disabled' },
  //   { value: 'component-checked', label: 'Component is checked' },
  //   { value: 'component-unchecked', label: 'Component is unchecked' }
];

export const textSubtypes = [
  { value: 'text has content and should be equal', label: 'Text has content and should be equal' },
  {
    value: 'text has content and should not be equal',
    label: 'Text has content and should not be equal'
  },
  { value: 'text has content and should contain', label: 'Text has content and should contain' },
  {
    value: 'text has content and should not contain',
    label: 'Text has content and should not contain'
  }
];

export const numericSubtypes = [
  { value: 'number equal to', label: 'Number is equal to' },
  { value: 'number greater than', label: 'Number is greater than' },
  { value: 'number greater than or equal to', label: 'Number is greater than or equal to' },
  { value: 'number is less than', label: 'Number is less than' },
  { value: 'number is less than or equal to', label: 'Number is less than or equal to' },
  { value: 'number is in-between', label: 'Number is in between (inclusive)' }
];

export const AssertionSubtypeDropdown = ({ selectedType, selectedSubtype, onSubtypeChange }) => {
  const { batchId } = useParams()

  const getSubtypes = () => {
    switch (selectedType) {
      case 'component':
        return componentSubtypes;
      case 'text':
        return textSubtypes;
      case 'numeric':
        return numericSubtypes;
      default:
        return [];
    }
  };

  return (
    <FormControl disabled={!!batchId} fullWidth size="small" sx={{ my: 2 }}>
      <InputLabel>Assertion Subtype</InputLabel>
      <Select
        MenuProps={{
          elevation: 1
        }}
        sx={{ '.MuiOutlinedInput-notchedOutline': { borderWidth: '1px' } }}
        label={'Assertion Subtype'}
        value={selectedSubtype}
        onChange={onSubtypeChange}>
        {getSubtypes().map((subtype) => (
          <MenuItem key={subtype.value} value={subtype.value}>
            {subtype.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const AssertionInput = ({
  selectedSubtype,
  inputValue,
  onInputChange,
  selectedType,
  rangeStart,
  rangeEnd,
  handleRangeStartChange,
  handleRangeEndChange
}) => {
  const { batchId } = useParams()

  if (selectedSubtype === 'number is in-between') {
    return (
      <Flex sx={{ gap: 2, flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
        {' '}
        <TextField
          size={'small'}
          type={'number'}
          disabled={!!batchId}
          sx={{
            '.MuiOutlinedInput-notchedOutline': { borderWidth: '1px' },
            width: { sm: 'calc(50% - 6px)' }
          }}
          label="Start of Range"
          value={rangeStart}
          onChange={handleRangeStartChange}
        />
        <TextField
          size={'small'}
          type={'number'}
          disabled={!!batchId}
          sx={{
            '.MuiOutlinedInput-notchedOutline': { borderWidth: '1px' },
            width: { sm: 'calc(50% - 6px)' }
          }}
          label="End of Range"
          value={rangeEnd}
          onChange={handleRangeEndChange}
        />
      </Flex>
    );
  } else {
    return (
      <>
        <TextField
          size={'small'}
          type={selectedType === 'numeric' ? 'number' : 'text'}
          fullWidth
          disabled={!!batchId}
          sx={{ '.MuiOutlinedInput-notchedOutline': { borderWidth: '1px' } }}
          label="Expected Value"
          value={inputValue}
          onChange={onInputChange}
        />
      </>
    );
  }
};

export const AssertionTypeDropdown = ({ types, selectedType, onTypeChange }) => {
  const { batchId } = useParams()

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Assertion Type</InputLabel>
      <Select
        MenuProps={{
          elevation: 1
        }}
        sx={{ '.MuiOutlinedInput-notchedOutline': { borderWidth: '1px' } }}
        label={'Assertion Type'}
        disabled={!!batchId}
        value={selectedType}
        onChange={onTypeChange}>
        {types.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AssertionModal = ({
  open,
  onClose,
  onComplete,
  setActions,
  setUnsaved,
  selectedElement,
  assertionMode,
  setAssertionMode,
  assertionModeRef,
  insertEventsAtRef
}) => {
  const [selectedType, setSelectedType] = useState('component');
  const [selectedSubtype, setSelectedSubtype] = useState('');
  const [inputValue, setInputValue] = useState(
    selectedElement?.attributes?.text || selectedElement?.attributes?.['context-desc'] || ''
  );
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);

  useEffect(() => {
    if (selectedElement?.attributes) {
      setInputValue(
        selectedElement?.attributes?.text ||
          selectedElement?.attributes?.['context-desc'] ||
          selectedElement?.attributes?.accessibilityLabel ||
          ''
      );
    }
  }, [selectedElement?.attributes]);

  const handleSubtypeChange = (event) => {
    setSelectedSubtype(event.target.value);
  };

  const handleAssertionTypeChange = (event) => {
    const newSelectedType = event.target.value;
    setSelectedType(newSelectedType);

    // if (newSelectedType === 'text' && !/^[a-zA-Z\s]*$/.test(inputValue)) {
    //   setInputValue('');
    // } else if (newSelectedType === 'numeric' && !/^\d+$/.test(inputValue)) {
    //   setInputValue('');
    // }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleRangeStartChange = (event) => {
    setRangeStart(event.target.value);
  };

  const handleRangeEndChange = (event) => {
    setRangeEnd(event.target.value);
  };

  const handleSaveAssertion = (e) => {
    e.preventDefault();

    if (!(selectedSubtype || inputValue)) {
      toast.error('Please select a subtype and provide a valid expected value');
      return;
    }

    if (selectedSubtype === 'number is in-between' && (!rangeStart || !rangeEnd)) {
      toast.error('Please select a value for the range');
      return;
    }

    if (selectedSubtype === 'number is in-between' && rangeStart >= rangeEnd) {
      toast.error('The start value must be less than the end value');
      return;
    }

    const data = {
      id: v4(),
      type: 'assert',
      assertType: selectedType,
      subtype: selectedSubtype,
      expectedValue: inputValue,
      attributes: selectedElement?.attributes,
      rangeEnd,
      rangeStart
    };

    if (insertEventsAtRef.current !== null) {
      const insertIndex = insertEventsAtRef.current + 1;
      setActions((prevActions) => [
        ...prevActions.slice(0, insertIndex),
        { ...data },
        ...prevActions.slice(insertIndex)
      ]);
      insertEventsAtRef.current = insertIndex;
    } else {
      setActions((prevActions) => [...prevActions, { ...data }]);
    }

    setUnsaved(true);
    toast.success('Assertion successfully added');
    setSelectedType('');
    setSelectedSubtype('');
    setInputValue('');
    setAssertionMode(false);
    assertionModeRef.current = false;
    onComplete();
  };

  const handleCloseModal = () => {
    onClose();
    setAssertionMode(false);
    assertionModeRef.current = false;
  };

  return (
    <CustomModal open={open}>
      <Box
        py={2}
        px={2}
        width={'80vw'}
        maxWidth={'500px'}
        component={'form'}
        onSubmit={handleSaveAssertion}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Assertions
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          Make an assertion to ensure your application works as expected.
        </Typography>

        <Box mb={3}>
          <AssertionTypeDropdown
            types={assertionTypes}
            selectedType={selectedType}
            onTypeChange={handleAssertionTypeChange}
          />

          {selectedType && (
            <>
              {' '}
              <AssertionSubtypeDropdown
                selectedType={selectedType}
                selectedSubtype={selectedSubtype}
                onSubtypeChange={handleSubtypeChange}
              />
              {selectedSubtype && selectedType !== 'component' && (
                <FormControl fullWidth sx={{ mb: 1 }}>
                  <AssertionInput
                    selectedSubtype={selectedSubtype}
                    inputValue={inputValue}
                    selectedType={selectedType}
                    onInputChange={handleInputChange}
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    handleRangeStartChange={handleRangeStartChange}
                    handleRangeEndChange={handleRangeEndChange}
                  />
                </FormControl>
              )}
            </>
          )}

          <ContainedButton
            size="small"
            sx={{
              textTransform: 'none',
              py: 1,
              mt: 1
            }}
            fullWidth
            type={'submit'}
            label={'Save Assertion'}>
            Save Assertion
          </ContainedButton>
          <OutlinedButton
            fullWidth
            size={'small'}
            sx={{
              py: 1,
              mt: 1
            }}
            onClick={handleCloseModal}>
            Cancel
          </OutlinedButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AssertionModal;
