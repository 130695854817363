import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomInput from '../../components/customInput';
import ContainedButton from '../../components/base/ContainedButton';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';

function ChangePassword() {
  useDocumentTitle('Setting | Change Password');
  const [password, setPassword] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [error, setError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [oldPasswordError, setOldPasswordError] = useState(null);

  const { mutateAsync: updatePassword, isLoading: isUpdating } = useScandiumMutation(
    '/user/account/password/update',
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        handleClose();
      }
    }
  );

  const { mutateAsync: verifyPassword, isLoading: isVerifying } = useScandiumMutation(
    '/user/account/password/verify',
    {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        handleOpen();
      }
    }
  );

  const handleClickShowPassword = () => {
    setShowPassword((val) => !val);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((val) => !val);
  };

  const handleOpen = () => {
    setShowOldPassword(false);
    setIsPasswordValid(true);
  };
  const handleClose = () => {
    setShowOldPassword(false);
    setIsPasswordValid(false);
    setSuccess(true);
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();

    if (!password) {
      setOldPasswordError('Old password is required');
    } else if (new_password.length < 8) {
      setNewPasswordError('New Password must be at least 8 characters long.');
    } else {
      await updatePassword({ password, new_password });
    }
  };

  const handleVerifyOldPassword = async (e) => {
    e.preventDefault();

    if (!password) {
      toast.error('Old password is required');
    } else {
      await verifyPassword({ password });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          ml: { xs: 3, sm: 5, md: 8 },
          display: 'flex',
          justifyContent: 'flex-start'
        }}>
        {showOldPassword && (
          <Box
            sx={{
              width: { xs: '100%', sm: '80%', md: '50%' }
            }}>
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: { xs: '1.15rem', sm: '1.35rem' },
                fontWeight: 'bold'
              }}>
              Change password
            </Typography>
            <CustomInput
              type={showPassword ? 'text' : 'password'}
              required
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              label="Old Password (8+ Characters)"
              formHelperText=" "
              value={password}
              formControlStyle={{
                mt: { xs: 6, sm: 6, md: 2, lg: 3 },
                width: '100%'
              }}
              error={false}
              helperText="ojfalse"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <ContainedButton
              onClick={handleVerifyOldPassword}
              disableRipple={true}
              fullWidth
              isLoading={isVerifying}
              sx={{
                textAlign: 'center',
                textTransform: 'capitalize',
                mt: { xs: 0, sm: 4, md: 1, lg: 2 },
                py: 1.5
              }}>
              Proceed
            </ContainedButton>
          </Box>
        )}

        {isPasswordValid && (
          <Box
            sx={{
              // backgroundColor: 'pink',
              // width: '50%',
              width: { xs: '100%', sm: '80%', md: '50%' }
            }}>
            <Typography
              sx={{
                textAlign: 'left',
                // typography: { sm: 'h6', md: 'h4' },
                fontSize: { xs: '1.15rem', sm: '1.35rem' },
                fontWeight: 'bold'
              }}>
              Change password
            </Typography>
            <TextField
              type={showPassword ? 'text' : 'password'}
              required
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              label="Old password (8+ Characters)"
              value={password}
              error={oldPasswordError != null}
              helperText={oldPasswordError}
              sx={{
                mt: { xs: 6, sm: 4 },
                width: '100%'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              required
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              label="New password (8+ Characters)"
              value={new_password}
              error={newPasswordError != null}
              helperText={newPasswordError}
              sx={{
                mt: { xs: 6, sm: 4 },
                width: '100%'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <ContainedButton
              onClick={handlePasswordUpdate}
              disableRipple={true}
              fullWidth
              isLoading={isUpdating}
              sx={{
                textAlign: 'center',
                textTransform: 'capitalize',
                mt: { xs: 0, sm: 5.0 },
                py: 1.5
              }}>
              Change Password
            </ContainedButton>
          </Box>
        )}
        {success && (
          <Box
            sx={{
              // backgroundColor: 'pink',
              width: '50%'
            }}>
            <Typography
              sx={{
                backgroundColor: 'rgba(36, 195, 217, 0.16)',
                border: '0.09rem solid #24C3D9',
                borderRadius: '0.2rem',
                // width: '25rem',
                height: '5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <IconButton
                aria-label="cancel"
                sx={{
                  clear: 'right'
                }}>
                <CheckCircleOutlineOutlinedIcon
                  sx={{
                    ml: 2
                  }}
                  fontSize="large"
                  color="success"
                />
              </IconButton>
              <Typography
                id="modal-modal-description"
                variant="caption"
                sx={{
                  // mt: 0.8,
                  p: 1.2,
                  mr: 2,
                  align: 'left'
                }}>
                Your password has been successfully changed. Please log in again with your new
                password.
              </Typography>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ChangePassword;
