import { SvgIcon } from '@mui/material';

const FailedSvg = ({ width = 20, height = 20, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 15 15'} {...props} fill={'none'}>
      <g clipPath="url(#clip0_1735_399)">
        <path
          d="M7.83748 14.0842C11.7089 14.0842 14.8473 10.9479 14.8473 7.07897C14.8473 3.21008 11.7089 0.0737305 7.83748 0.0737305C3.96605 0.0737305 0.827637 3.21008 0.827637 7.07897C0.827637 10.9479 3.96605 14.0842 7.83748 14.0842Z"
          fill="#EF1B1B"
        />
        <path
          d="M10.0614 10.3859L11.1455 9.30176L5.61434 3.77059L4.53023 4.85471L10.0614 10.3859Z"
          fill="white"
        />
        <path
          d="M5.61458 10.3871L11.1458 4.85596L10.0616 3.77184L4.53047 9.30301L5.61458 10.3871Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1735_399">
          <rect
            width="14.0197"
            height="14.0197"
            fill="white"
            transform="translate(0.827637 0.0688477)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default FailedSvg;
