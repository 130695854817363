import { rgba } from "../../utils/colors";
import { Box } from "@mui/material";

const SvgChart = ({ width, height, passing = 60 }) => {
  const viewBoxSize = 160;
  const strokeWidth = 24;
  const center = viewBoxSize / 2;
  const radius = center - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - passing) / 100) * circumference;

  const errorProgress = 100 - passing;
  const errorOffset = ((errorProgress - 100) / 100) * circumference;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "rotate(90deg)", transformOrigin: "center" }}
    >
      <circle
        stroke={rgba("#00CA08", 50)}
        fill={"none"}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      ></circle>
      <circle
        stroke={rgba("#EF1B1B", 50)}
        fill={"none"}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={errorOffset}
      ></circle>
    </svg>
  );
};

export default SvgChart;
