import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Chip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useMenu } from '../../../components/base/useMenu';
import { toast } from 'react-toastify';
import { useActiveProject } from '../../../store/projectState';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';

const ScheduleActionMenu = React.forwardRef(
  ({ isLoading, onDelete, suiteId, onRemote, onLocal, onSchedule }, ref) => {
    const { menuProps, triggerProps } = useMenu();
    const { isFeatureEnabled: enabledRemoteExecutionFeature } =
      useFeatureEnabled('remote-execution');
    const activeProject = useActiveProject();

    const copySuiteIdToClipBoard = () => {
      navigator.clipboard.writeText(suiteId);

      toast.success('copied to clipboard');
      menuProps.onClose();
    };

    const readyOnly = activeProject?.read_only;

    return (
      <div>
        <IconButton
          ref={ref}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={isLoading}
          aria-expanded={menuProps.open ? 'true' : undefined}
          {...triggerProps}>
          {isLoading ? (
            <CircularProgress size={18} color={'primary'} />
          ) : (
            <MoreVert color={'primary'} />
          )}
        </IconButton>
        <Menu id="action-menu" {...menuProps} elevation={1}>
          <MenuItem onClick={onSchedule} disabled={!!readyOnly}>
            Edit schedule
          </MenuItem>
          <MenuItem onClick={onDelete} sx={{ color: 'red' }} disabled={!!readyOnly}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default ScheduleActionMenu;
