import React from 'react';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { capitalize } from '../../../utils/strings';
import Flex from '../../../components/base/Flex';

const ExecutionType = ({ execution_type, setExecutionType }) => {
  return (
    <FormControl
      fullWidth
      size={'small'}
      sx={{
        height: { xs: 1, sm: 1 }
      }}>
      <InputLabel id="Execution-Type-select-label">Execution Type</InputLabel>
      <Select
        labelId="Execution-Type-select-label"
        id="Execution-Type-select"
        value={execution_type}
        MenuProps={{
          elevation: 1
        }}
        label={'Execution Type'}
        renderValue={(selected) => capitalize(selected || '')}
        onChange={(e) => setExecutionType(e.target.value)}>
        <MenuItem value={'sequential'}>Sequential</MenuItem>
        <MenuItem value={'parallel'}>Parallel</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ExecutionType;
