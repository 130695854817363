import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Stack,
  Tabs,
  Tab,
  Button,
  Tooltip,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import OAuth from 'oauth-1.0a';
import { isValidUrl } from '../utils';
import CryptoES from 'crypto-es';
// import DigestAuth from 'http-digest-auth';
import Flex from '../../../components/base/Flex';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFocusedState } from '../../../hooks/useFocusedState';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { tabStyle, TabPanel } from '../NewApi';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import ContainedButton from '../../../components/base/ContainedButton';
const Authorization = ({
  authorizationHeader,
  setAuthorizationHeader,
  setQueryHeaders,
  setQueryParams,
  httpMethod,
  url,
  globalVariableState
}) => {
  const flexStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 2,
    mx: 4,
    pt: 5,
    // outline: '2px solid green',
    minHeight: 'inherit'
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const currentAuthOption = authorizationHeader.option;
  const moveableAuthEntry = ['apiKey', 'oauth1', 'oauth2'];

  useEffect(() => {
    const convertDigestAuthToString = (authObj, uri, response) => {
      let filteredObj = {};
      if (authObj.qop === 'auth' || authObj === 'auth-int') {
        const { retry, password, algorithm, qop, nc, cnonce, opaque, ...rest } = authObj;
        filteredObj = { ...rest, uri, response, algorithm, qop, nc, cnonce, opaque };
      } else {
        const { retry, password, qop, nc, cnonce, ...rest } = authObj;
        filteredObj = { ...rest, uri, response };
      }
      const string = Object.entries(filteredObj).map(([key, value]) => {
        if (key === 'nc' && value.trim() === '') {
          return;
        }
        return `${key}=${value}`;
      });
      return string.join(',');
    };
    const convertOAuth1ObjToString = (authObjArray) => {
      const result = authObjArray.map((entry) => {
        return entry.key + '=' + entry.value;
      });
      return result.filter(Boolean).join(',');
    };
    const checkValueInGlobalVariables = (value) => {
      const savedVariables = globalVariableState.globalVariables;
      let result = value;
      if (value.startsWith('{{') && value.endsWith('}}')) {
        const valueWithoutBraces = value.replace(/{{|}}/g, '');
        const variable = savedVariables.find((entry) => {
          return entry.name === valueWithoutBraces;
        });
        if (!!variable) {
          result = variable.value;
        } else {
          result = value;
        }
      }
      return result;
    };
    const checkForObjectsValueInGlobalVariables = (obj) => {
      const savedVariables = globalVariableState.globalVariables;
      let result = obj;
      Object.entries(obj).map(([key, value]) => {
        if (typeof value === 'string' && value.startsWith('{{') && value.endsWith('}}')) {
          const valueWithoutBraces = value.replace(/{{|}}/g, '');
          const variable = savedVariables.find((entry) => {
            return entry.name === valueWithoutBraces;
          });
          if (!!variable) {
            value = variable.value;
          }
        }
        result = { ...result, [key]: value };
      });
      return result;
    };
    const handleAuthHeaderInsert = () => {
      let headerEntry = { key: 'Authorization', value: '', src: 'authorization' };
      let option = authorizationHeader.option;
      let authHeaderValue = { ...authorizationHeader }[option];
      // wirite a function that replaces auth header variable values with the actual value
      //check for each option, namely bearer, basic, etc
      const removeAuthFromQueryParams = () => {
        setQueryParams((prev) => {
          const removeAuthFromParams = prev.filter((param) => param.src !== 'authorization');
          return removeAuthFromParams;
        });
      };
      const removeAuthFromQueryHeaders = () => {
        setQueryHeaders((prev) => {
          const removeAuthFromHeaders = prev.filter((headers) => headers.src !== 'authorization');
          return removeAuthFromHeaders;
        });
      };
      const addAuthToQueryHeaders = () => {
        setQueryHeaders((prev) => {
          // check for and remove existing auth header
          const newHeaders = prev.filter((header) => header.src !== 'authorization');
          return [headerEntry, ...newHeaders];
        });
      };
      const addAuthToQueryParams = () => {
        setQueryParams((prev) => {
          const prevParams = [...prev];
          // check for and remove existing auth header
          const newParams = prevParams.filter((header) => header.src !== 'authorization');
          if (option !== 'oauth1') {
            return [headerEntry, ...newParams];
          } else {
            return [...headerEntry, ...newParams];
          }
        });
      };
      if (option === 'no-auth') {
        removeAuthFromQueryHeaders();
        removeAuthFromQueryParams();
        return;
      }
      if (option === 'bearer' && authHeaderValue !== '') {
        // const valueInGlobalVariables = checkValueInGlobalVariables(authHeaderValue);
        // headerEntry.value = `Bearer ${valueInGlobalVariables}`;
        headerEntry.value = `Bearer ${authHeaderValue}`;
      }
      if (option === 'basic') {
        // authHeaderValue = checkForObjectsValueInGlobalVariables(authHeaderValue);
        const codedValue = window.btoa(`${authHeaderValue.username}:${authHeaderValue.password}`);
        headerEntry.value = `Basic ${codedValue}`;
      }
      if (option === 'apiKey') {
        // authHeaderValue = checkForObjectsValueInGlobalVariables(authHeaderValue);
        headerEntry.key = authHeaderValue.key;
        headerEntry.value = authHeaderValue.value;
      }
      if (option === 'digestAuth') {
        if (!isValidUrl(url)) {
          return;
        }
        authHeaderValue = checkForObjectsValueInGlobalVariables(authHeaderValue);
        let _uri = new URL(url);
        _uri = _uri.pathname + _uri.search;
        let HA1;
        let HA2;
        let response;
        const _realm = authHeaderValue.realm;
        const _username = authHeaderValue.username;
        const _password = authHeaderValue.password;
        const _nonce = authHeaderValue.nonce;
        const _nc = authHeaderValue.nc;
        const _cNounce = authHeaderValue.cnonce;
        const _qop = authHeaderValue.qop;
        const _algorithm = authHeaderValue.algorithm;
        // if (!!_qop) {
        //   _nc === '' && (_nc = '0000001');
        //   _cNounce === '' && (_cNounce = Math.random().toString(36).slice(2, 11));
        // }
        if (_realm.trim() !== '' && _username.trim() !== '') {
          const responseGenerator = (hashMethod, sess) => {
            HA1 = CryptoES[hashMethod](`${_username}:${_realm}:${_password}`).toString();
            HA2 = CryptoES[hashMethod](`${httpMethod}:${_uri}`).toString();
            response = CryptoES[hashMethod](`${HA1}:${_nonce}:${HA2}`).toString();
            if (sess === true) {
              HA1 = CryptoES[hashMethod](`${HA1}:${_nonce}:${_cNounce}`).toString();
              response = CryptoES[hashMethod](`${HA1}:${_nonce}:${HA2}`).toString();
            }
            if (_qop === 'auth' || _qop === 'auth-int') {
              response = CryptoES[hashMethod](
                `${HA1}:${_nonce}:${_nc}:${_cNounce}:${_qop}:${HA2}`
              ).toString();
            }
          };
          switch (_algorithm) {
            case 'MD5':
              responseGenerator('MD5');
              break;
            case 'MD5-sess':
              responseGenerator('MD5', true);
              break;
            case 'SHA256':
              responseGenerator('SHA256');
              break;
            case 'SHA256-sess':
              responseGenerator('SHA256', true);
              break;
            case 'SHA512-256':
              responseGenerator('SHA512');
              break;
            default:
              break;
          }
          headerEntry.value = `Digest ${convertDigestAuthToString(
            authHeaderValue,
            _uri,
            response
          )}`;
        }
      }
      if (option === 'oauth1') {
        if (!isValidUrl(url)) {
          return;
        }
        authHeaderValue = checkForObjectsValueInGlobalVariables(authHeaderValue);
        const consumerSecret = authHeaderValue.oauth_consumer_secret;
        const oauthSecret = authHeaderValue.oauth_token_secret;
        //---------------generate oauth_signature---------------------//
        // remove, unecessary fields for calculating signature
        let {
          realm,
          addEmptyFields,
          path,
          oauth_consumer_secret,
          oauth_token_secret,
          oauth_signature,
          ...cleanObj
        } = authHeaderValue;
        //generate values for time_stamp and nonce if they are empty---//
        if (cleanObj.oauth_nonce === '') {
          cleanObj.oauth_nonce = Math.random().toString(36).slice(2, 11);
        }
        if (cleanObj.oauth_timestamp === '') {
          cleanObj.oauth_timestamp = Math.floor(Date.now() / 1000).toString();
        }
        // filter empty fields before calculating signature
        let ObjWithoutEmptyFields = {};
        Object.entries(cleanObj).map(([key, value]) => {
          if (value.trim() !== '') {
            ObjWithoutEmptyFields = { ...ObjWithoutEmptyFields, [key]: value };
          }
        });
        !addEmptyFields && (cleanObj = ObjWithoutEmptyFields);
        //  = cleanObj.ouath_signature_method
        const hash_function = (base_string, key) => {
          if (cleanObj.oauth_signature_method !== 'PLAINTEXT') {
            const signatureType = cleanObj.oauth_signature_method.split('-')[1];
            return CryptoES[`Hmac${signatureType}`](base_string, key).toString(CryptoES.enc.Base64);
          }
          return key;
        };
        const oauth = OAuth({
          consumer: { secret: consumerSecret },
          signature_method: cleanObj.oauth_signature_method,
          hash_function
        });
        const request_data = {
          url: url,
          method: httpMethod,
          data: { ...cleanObj }
        };
        let newOauth = oauth.authorize(request_data, {
          key: cleanObj.oauth_token,
          secret: oauthSecret
        });
        if (!!realm) {
          newOauth = { realm, ...newOauth };
        }
        const reqPath = authorizationHeader.oauth1.path;
        const newOauthEncoded = Object.entries(newOauth).map(([key, value]) => {
          return {
            key: encodeURIComponent(key),
            value: encodeURIComponent(value),
            src: 'authorization'
          };
        });
        if (reqPath === 'headers') {
          headerEntry.value = `OAuth ${convertOAuth1ObjToString(newOauthEncoded)}`;
        } else {
          headerEntry = newOauthEncoded;
          // headerEntry will now be an array of objects (key, value pairs)
        }
      }
      if (option === 'oauth2') {
        const path = authorizationHeader.oauth2.path;
        const headerPrefix = authorizationHeader.oauth2.header_prefix;
        const accessToken = authorizationHeader.oauth2.access_token;
        if (path === 'headers') {
          headerEntry.value = `${headerPrefix} ${accessToken}`;
        } else {
          headerEntry.key = 'access_token';
          headerEntry.value = accessToken;
        }
      }
      //-------------appropriate auth destination------------------------------//
      if (
        moveableAuthEntry.includes(option) &&
        authorizationHeader[currentAuthOption].path === 'headers'
      ) {
        removeAuthFromQueryParams();
      }
      if (
        moveableAuthEntry.includes(option) &&
        authorizationHeader[currentAuthOption].path === 'queryParams'
      ) {
        removeAuthFromQueryHeaders();
      }
      //--------------insert auth to destination-------------------------------//
      switch (option) {
        case 'bearer':
          removeAuthFromQueryParams();
          if (!headerEntry.value) {
            removeAuthFromQueryHeaders();
            return;
          } else {
            addAuthToQueryHeaders();
          }
          break;
        case 'basic':
          removeAuthFromQueryParams();
          addAuthToQueryHeaders();
          break;
        case 'apiKey':
          if (headerEntry.key.trim() === '') {
            removeAuthFromQueryParams();
            removeAuthFromQueryHeaders();
            return;
          }
          if (authorizationHeader.apiKey.path === 'queryParams') {
            return addAuthToQueryParams();
          } else {
            addAuthToQueryHeaders();
          }
          break;
        case 'digestAuth':
          if (authHeaderValue.username.trim() === '' || authHeaderValue.realm.trim() === '') {
            return removeAuthFromQueryHeaders();
          }
          addAuthToQueryHeaders();
          break;
        case 'oauth1':
          if (
            authHeaderValue.oauth_consumer_key.trim() === '' ||
            authHeaderValue.oauth_consumer_secret.trim() === ''
          ) {
            removeAuthFromQueryHeaders();
            removeAuthFromQueryParams();
            return;
          }
          if (authorizationHeader.oauth1.path === 'queryParams') {
            return addAuthToQueryParams();
          } else {
            addAuthToQueryHeaders();
          }
          break;
        case 'oauth2':
          if (authHeaderValue.access_token.trim() === '') {
            removeAuthFromQueryHeaders();
            removeAuthFromQueryParams();
            return;
          }
          if (authorizationHeader.oauth2.path === 'headers') {
            return addAuthToQueryHeaders();
          }
          addAuthToQueryParams();

          break;
        default:
          break;
      }
    };
    handleAuthHeaderInsert();
  }, [authorizationHeader, url]);
  const handleOAuth2PopUp = () => {
    const authUrl = `${authorizationHeader.oauth2.auth_url}?response_type=code&client_id=${
      authorizationHeader.oauth2.client_ID
    }&redirect_uri=${encodeURIComponent('https://getpostman.com/oauth2/callback')}`;
    const popup = window.open(authUrl, '_blank');
  };
  const { handleVariableFloatingWindow } = useFocusedState();
  const handleAuthFieldChange = (e, entry, field, index) => {
    setAuthorizationHeader((prev) => {
      if (field === 'resource') {
        const newValue = prev[entry].resource;
        newValue[index] = e.target.value;
        return {
          ...prev,
          [entry]: { ...prev[entry], [field]: newValue }
        };
      } else {
        return {
          ...prev,
          [entry]: { ...prev[entry], [field]: e.target.value }
        };
      }
    });
  };
  return (
    <Flex sx={{ minHeight: '300px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pb: 2,
          px: 2,
          pt: 4,
          minHeight: 'inherit',
          flex: 1
        }}>
        <FormControl fullWidth size={'medium'}>
          <InputLabel id={'authorization-type'}>Auth Type</InputLabel>
          <Select
            fullWidth
            size="small"
            value={authorizationHeader.option}
            label={'Add new'}
            labelId={'assertions-select-label'}
            id={'assertions-select-label'}
            onChange={(e) => {
              setAuthorizationHeader((prev) => {
                return { ...prev, option: e.target.value };
              });
            }}>
            <MenuItem divider={true} value={'no-auth'}>
              No auth
            </MenuItem>
            <MenuItem divider={true} value={'bearer'}>
              Bearer Token
            </MenuItem>
            <MenuItem divider={true} value={'basic'}>
              Basic Auth
            </MenuItem>
            <MenuItem divider={true} value={'apiKey'}>
              API key
            </MenuItem>
            <MenuItem divider={true} value={'digestAuth'}>
              Digest Auth
            </MenuItem>
            <MenuItem divider={true} value={'oauth1'}>
              Oauth 1.0
            </MenuItem>
            {/* <MenuItem divider={true} value={'oauth2'}>
              Oauth 2.0
            </MenuItem> */}
          </Select>
        </FormControl>
        <Typography fontSize={12} fontWeight={1}>
          The authorization header will be automatically generated when you send the request.
        </Typography>
        {moveableAuthEntry.includes(authorizationHeader.option) && (
          <Stack
            sx={{
              mt: 'auto'
            }}>
            <Typography marginBottom={2} fontSize={14} fontWeight={'light'}>
              Add auth data to:
            </Typography>
            <FormControl fullWidth size={'medium'}>
              <InputLabel id={'authorization-type'}>Auth Type</InputLabel>
              <Select
                fullWidth
                size="small"
                value={authorizationHeader[authorizationHeader.option].path}
                label={'Add auth to'}
                labelId={'Add-auth-to'}
                id={'add_auth_to'}
                onChange={(e) => {
                  setAuthorizationHeader((prev) => {
                    return {
                      ...prev,
                      [currentAuthOption]: { ...prev[currentAuthOption], path: e.target.value }
                    };
                  });
                }}>
                <MenuItem divider={true} value={'headers'}>
                  Query Headers
                </MenuItem>
                <MenuItem divider={true} value={'queryParams'}>
                  Query Params
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        )}
        {/* {authorizationHeader.option === 'digestAuth' && (
          <Stack>
            <Typography fontSize={12} fontWeight={1}>
              Scandium automatically extracts and adds values from the response to retry the
              request. Do you want to disable this?
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!authorizationHeader.digestAuth.retry}
                  onChange={() => {
                    setAuthorizationHeader((prev) => {
                      return {
                        ...prev,
                        digestAuth: { ...prev.digestAuth, retry: !prev.digestAuth.retry }
                      };
                    });
                  }}
                />
              }
              label="Disable this feature"
            />
          </Stack>
        )} */}
      </Box>
      <Divider orientation="vertical" flexItem />
      {}
      <Flex sx={{ ...flexStyle }}>
        {authorizationHeader.option === 'no-auth' && (
          <Typography
            sx={{
              opacity: 0.5,
              justifySelf: 'center',
              alignSelf: 'center'
            }}>
            {' '}
            No authorization is specified for this request
          </Typography>
        )}

        {authorizationHeader.option === 'bearer' && (
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              minWidth: '100%'
            }}>
            <Typography
              fontWeight={'medium'}
              sx={{
                flex: 1
              }}>
              Token
            </Typography>
            <TextField
              placeholder="Enter token value"
              value={authorizationHeader.bearer}
              sx={{
                mx: 2,
                flex: 1.5
              }}
              size="small"
              onChange={(e) => {
                handleVariableFloatingWindow(e, () => {
                  setAuthorizationHeader((prev) => {
                    return { ...prev, bearer: `${e.target.value}` };
                  });
                });
                setAuthorizationHeader((prev) => {
                  return { ...prev, bearer: `${e.target.value}` };
                });
              }}
            />
          </Flex>
        )}
        {authorizationHeader.option === 'basic' && (
          <Stack
            spacing={4}
            sx={{
              width: '100%',
              px: 5
            }}>
            <FieldAndValueComponent
              field={'Username'}
              value={authorizationHeader.basic?.username}
              handleOnChange={(e) => {
                handleVariableFloatingWindow(e, () => {
                  handleAuthFieldChange(e, 'basic', 'username');
                });
                handleAuthFieldChange(e, 'basic', 'username');
              }}
            />
            <FieldAndValueComponent
              field={'Password'}
              otherProps={{ type: 'password' }}
              value={authorizationHeader.basic?.password}
              handleOnChange={(e) => {
                handleVariableFloatingWindow(e, () => {
                  handleAuthFieldChange(e, 'basic', 'password');
                });
                handleAuthFieldChange(e, 'basic', 'password');
              }}
            />
          </Stack>
        )}
        {authorizationHeader.option === 'apiKey' && (
          <Stack
            spacing={4}
            sx={{
              width: '100%'
              // outline: '1px solid pink',
              // px: 5
            }}>
            <FieldAndValueComponent
              field={'Key'}
              value={authorizationHeader.apiKey?.key}
              handleOnChange={(e) => {
                handleVariableFloatingWindow(e, () => {
                  handleAuthFieldChange(e, 'apiKey', 'key');
                });
                handleAuthFieldChange(e, 'apiKey', 'key');
              }}
            />
            <FieldAndValueComponent
              field={'Value'}
              value={authorizationHeader.apiKey?.value}
              handleOnChange={(e) => {
                handleVariableFloatingWindow(e, () => {
                  handleAuthFieldChange(e, 'apiKey', 'value');
                });
                handleAuthFieldChange(e, 'apiKey', 'value');
              }}
            />
          </Stack>
        )}
        {authorizationHeader.option === 'digestAuth' && (
          <CustomScrollbar sx={{ width: '100%', overflowY: 'auto', maxHeight: '300px' }}>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                mt: 1,
                mb: 1
              }}>
              <FieldAndValueComponent
                field={'Username'}
                value={authorizationHeader.digestAuth?.username}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'username');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'username');
                }}
              />
              <FieldAndValueComponent
                field={'Password'}
                otherProps={{ type: 'password' }}
                value={authorizationHeader.digestAuth?.password}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'password');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'password');
                }}
              />
              <Divider />
              <Box sx={{ display: 'flex', columnGap: 1 }}>
                <KeyboardArrowUpIcon color="primary" />
                <Typography color="primary">Advanced Options</Typography>
              </Box>

              <FieldAndValueComponent
                field={'Realm'}
                value={authorizationHeader.digestAuth?.realm}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'realm');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'realm');
                }}
              />
              <FieldAndValueComponent
                field={'Nonce'}
                value={authorizationHeader.digestAuth?.nonce}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'nonce');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'nonce');
                }}
              />
              <Algorithm
                field={'Algorithm'}
                authorizationHeader={authorizationHeader}
                setAuthorizationHeader={setAuthorizationHeader}
              />
              <FieldAndValueComponent
                field={'qop'}
                value={authorizationHeader.digestAuth?.qop}
                otherProps={{
                  placeholder: 'e,g auth, auth-int'
                }}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'qop');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'qop');
                }}
              />
              <FieldAndValueComponent
                field={'Nonce Count'}
                value={authorizationHeader.digestAuth?.nc}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'nc');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'nc');
                }}
              />
              <FieldAndValueComponent
                field={'Client Nonce'}
                value={authorizationHeader.digestAuth?.cnonce}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'cnonce');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'cnonce');
                }}
              />
              <FieldAndValueComponent
                field={'Opaque'}
                value={authorizationHeader.digestAuth?.opaque}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'digestAuth', 'opaque');
                  });
                  handleAuthFieldChange(e, 'digestAuth', 'opaque');
                }}
              />
            </Stack>
          </CustomScrollbar>
        )}
        {authorizationHeader.option === 'oauth1' && (
          <CustomScrollbar sx={{ width: '100%', overflowY: 'auto', maxHeight: '300px' }}>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                mt: 1,
                mb: 1,
                // outline: '1px solid pink',
                pr: 1
              }}>
              <SignatureMethod
                field={'Signature Method'}
                authorizationHeader={authorizationHeader}
                setAuthorizationHeader={setAuthorizationHeader}
              />
              <FieldAndValueComponent
                field={'Consumer Key'}
                value={authorizationHeader.oauth1?.oauth_consumer_key}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_consumer_key');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_consumer_key');
                }}
              />
              <FieldAndValueComponent
                field={'Consumer Secret'}
                value={authorizationHeader.oauth1?.oauth_consumer_secret}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_consumer_secret');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_consumer_secret');
                }}
              />
              <FieldAndValueComponent
                field={'Access Token'}
                value={authorizationHeader.oauth1?.oauth_token}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_token');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_token');
                }}
              />
              <FieldAndValueComponent
                field={'Token_Secret'}
                value={authorizationHeader.oauth1?.oauth_token_secret}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_token_secret');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_token_secret');
                }}
              />
              <Divider />
              <Box sx={{ display: 'flex', columnGap: 1 }}>
                <Typography color="primary">Advanced Options</Typography>
                <KeyboardArrowUpIcon color="primary" />
              </Box>

              <FieldAndValueComponent
                field={'Callback URL'}
                value={authorizationHeader.oauth1?.oauth_callback}
                otherProps={{ placeholder: 'callback-URL' }}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_callback');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_callback');
                }}
              />
              <FieldAndValueComponent
                field={'Timestamp'}
                value={authorizationHeader.oauth1?.oauth_timestamp}
                otherProps={{ placeholder: 'timestamp' }}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_timestamp');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_timestamp');
                }}
              />
              <FieldAndValueComponent
                field={'Verifier'}
                value={authorizationHeader.oauth1?.oauth_verifier}
                otherProps={{ placeholder: 'verifier' }}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e, () => {
                    handleAuthFieldChange(e, 'oauth1', 'oauth_verifier');
                  });
                  handleAuthFieldChange(e, 'oauth1', 'oauth_verifier');
                }}
              />
              <FieldAndValueComponent
                field={'Nonce'}
                value={authorizationHeader.oauth1?.oauth_nonce}
                otherProps={{ placeholder: 'Nonce' }}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e);
                  handleAuthFieldChange(e, 'oauth1', 'oauth_nonce');
                }}
              />
              <FieldAndValueComponent
                field={'Version'}
                value={authorizationHeader.oauth1?.oauth_version}
                otherProps={{ placeholder: 'version' }}
                handleOnChange={(e) => {
                  handleAuthFieldChange(e, 'oauth1', 'oauth_version');
                }}
              />
              <FieldAndValueComponent
                field={'Realm'}
                value={authorizationHeader.oauth1?.realm}
                otherProps={{ placeholder: 'realm' }}
                handleOnClick={(e) => {
                  handleAuthFieldChange(e, 'oauth1', 'realm');
                }}
                handleOnChange={(e) => {
                  handleVariableFloatingWindow(e);
                  handleAuthFieldChange(e, 'oauth1', 'realm');
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={authorizationHeader.oauth1.addEmptyFields}
                    onChange={() => {
                      setAuthorizationHeader((prev) => {
                        return {
                          ...prev,
                          oauth1: { ...prev.oauth1, addEmptyFields: !prev.oauth1.addEmptyFields }
                        };
                      });
                    }}
                  />
                }
                label="Include empty fields in calculating signature"
              />
            </Stack>
          </CustomScrollbar>
        )}
        {authorizationHeader.option === 'oauth2' && (
          <CustomScrollbar sx={{ width: '100%', overflowY: 'auto', maxHeight: '300px' }}>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                mt: 1,
                mb: 1,
                pr: 1
              }}>
              <FieldAndValueComponent
                field={'Access Token'}
                value={authorizationHeader.oauth2?.access_token}
                handleOnChange={(e) => {
                  handleAuthFieldChange(e, 'oauth2', 'access_token');
                }}
              />
              <FieldAndValueComponent
                field={'Header Prefix'}
                value={authorizationHeader.oauth2?.header_prefix}
                handleOnChange={(e) => {
                  handleAuthFieldChange(e, 'oauth2', 'header_prefix');
                }}
              />
              <Divider />
              <Typography fontSize={16} fontWeight={'medium'} color="primary">
                Configure New Token
              </Typography>
              <Tabs
                value={selectedTab}
                onChange={(event, value) => {
                  setSelectedTab(value);
                }}
                indicatorColor={'secondary'}
                sx={{
                  borderBottom: '3px solid #E3E3E3',
                  minHeight: 0,
                  maxHeight: 'max-content',
                  mt: 10
                }}
                TabIndicatorProps={{
                  sx: { height: 3 }
                }}>
                <Tab
                  label={
                    <Flex columnGap={'5px'}>
                      <Typography fontSize={'0.85rem'}>Configuration Options</Typography>
                      <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                    </Flex>
                  }
                  disableRipple={true}
                  sx={{ ...tabStyle }}
                />
                <Tab
                  label={
                    <Flex columnGap={'5px'}>
                      <Typography fontSize={'0.85rem'}>Advanced Options</Typography>
                      <Box sx={{ bgcolor: 'skyblue', borderRadius: '100%', p: '3px' }} />
                    </Flex>
                  }
                  disableRipple={true}
                  sx={{ ...tabStyle }}
                />
              </Tabs>
              <TabPanel index={0} value={selectedTab}>
                <Stack
                  spacing={2}
                  sx={{
                    width: '100%',
                    mt: 1,
                    mb: 1
                  }}>
                  <FieldAndValueComponent
                    field={'Token Name'}
                    value={authorizationHeader.oauth2?.token_name}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'token_name');
                    }}
                  />
                  <GrantType
                    field={'Grant Type'}
                    authorizationHeader={authorizationHeader}
                    setAuthorizationHeader={setAuthorizationHeader}
                  />

                  <FieldAndValueComponent
                    field={'Callback URL'}
                    value={authorizationHeader.oauth2?.callback_url}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'callback_url');
                    }}
                  />
                  <FieldAndValueComponent
                    field={'Auth URL'}
                    value={authorizationHeader.oauth2?.auth_url}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'auth_url');
                    }}
                  />
                  <FieldAndValueComponent
                    field={'Access Token URL'}
                    value={authorizationHeader.oauth2?.access_token_url}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'access_token_url');
                    }}
                  />
                  <FieldAndValueComponent
                    field={'Client ID'}
                    value={authorizationHeader.oauth2?.client_ID}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'client_ID');
                    }}
                  />
                  <FieldAndValueComponent
                    field={'Client Secret'}
                    value={authorizationHeader.oauth2?.client_secret}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'client_secret');
                    }}
                  />
                  <FieldAndValueComponent
                    field={'Scope'}
                    value={authorizationHeader.oauth2?.scope}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'scope');
                    }}
                  />
                  <FieldAndValueComponent
                    field={'State'}
                    value={authorizationHeader.oauth2?.state}
                    handleOnChange={(e) => {
                      handleAuthFieldChange(e, 'oauth2', 'state');
                    }}
                  />
                  <ClientAuthentication
                    field={'Client Authentication'}
                    authorizationHeader={authorizationHeader}
                    setAuthorizationHeader={setAuthorizationHeader}
                  />
                  <ContainedButton
                    // isLoading={isSavingVariables}
                    onClick={() => {
                      handleOAuth2PopUp();
                    }}
                    loadingProps={{ size: 14 }}
                    sx={{ width: 'fit-content' }}>
                    Get new access token
                  </ContainedButton>
                </Stack>
              </TabPanel>
              <TabPanel index={1} value={selectedTab}>
                <Stack spacing={2} sx={{}}>
                  <Flex
                    sx={{
                      alignItems: 'flex-start'
                    }}>
                    <Typography
                      fontWeight={'medium'}
                      sx={{
                        flex: 1
                      }}>
                      Resource
                    </Typography>
                    <Stack
                      spacing={2}
                      sx={{
                        ml: 'auto',
                        flex: 2,
                        textAlign: 'center'
                      }}>
                      {authorizationHeader.oauth2.resource.map((entry, index) => {
                        return (
                          <AdvancedOptionsInput
                            entry={entry}
                            index={index}
                            field={'resource'}
                            handleAuthFieldChange={handleAuthFieldChange}
                            authorizationHeader={authorizationHeader}
                            setAuthorizationHeader={setAuthorizationHeader}
                          />
                        );
                      })}
                      <Button
                        sx={{ width: '55%' }}
                        variant="outlined"
                        onClick={() => {
                          setAuthorizationHeader((prev) => {
                            const newValue = prev.oauth2.resource.concat('');
                            return { ...prev, oauth2: { ...prev.oauth2, resource: newValue } };
                          });
                        }}>
                        Add another resource
                      </Button>
                    </Stack>
                  </Flex>
                  <Flex
                    sx={{
                      alignItems: 'flex-start'
                    }}>
                    <Typography
                      fontWeight={'medium'}
                      sx={{
                        flex: 1
                      }}>
                      Audience
                    </Typography>
                    <Stack
                      spacing={2}
                      sx={{
                        ml: 'auto',
                        flex: 2,
                        textAlign: 'center'
                      }}>
                      {authorizationHeader.oauth2.audience.map((entry, index) => {
                        return (
                          <AdvancedOptionsInput
                            entry={entry}
                            index={index}
                            field={'audience'}
                            handleAuthFieldChange={handleAuthFieldChange}
                            authorizationHeader={authorizationHeader}
                            setAuthorizationHeader={setAuthorizationHeader}
                          />
                        );
                      })}
                      <Button
                        sx={{ width: '55%' }}
                        variant="outlined"
                        onClick={() => {
                          setAuthorizationHeader((prev) => {
                            const newValue = prev.oauth2.audience.concat('');
                            return { ...prev, oauth2: { ...prev.oauth2, audience: newValue } };
                          });
                        }}>
                        Add another resource
                      </Button>
                    </Stack>
                  </Flex>
                </Stack>
              </TabPanel>
            </Stack>
          </CustomScrollbar>
        )}
      </Flex>
    </Flex>
  );
};

const FieldAndValueComponent = ({ field, handleOnChange, handleOnClick, value, otherProps }) => {
  return (
    <Box
      sx={{
        display: 'flex'
        // marginBottom: 2
      }}>
      <Typography
        fontWeight={'medium'}
        sx={{
          flex: 1
        }}>
        {field}
      </Typography>
      <TextField
        value={value}
        onClick={handleOnClick}
        onChange={handleOnChange}
        size="small"
        label={field}
        style={{}}
        {...otherProps}
        sx={{
          ml: 'auto',
          flex: 1.5,
          textAlign: 'center'
        }}
        inputProps={{
          style: {
            fontSize: '12px'
          }
        }}
      />
    </Box>
  );
};

const SignatureMethod = ({ field, authorizationHeader, setAuthorizationHeader }) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}>
      <Typography
        fontWeight={'medium'}
        sx={{
          flex: 1
        }}>
        {field}
      </Typography>
      <FormControl size={'large'} sx={{ flex: 1.5 }}>
        <InputLabel id={'authorization-type'}>Signature Method</InputLabel>
        <Select
          fullWidth
          size="small"
          value={authorizationHeader.oauth1.oauth_signature_method}
          label={'signature'}
          labelId={'signature-method'}
          id={'signature-method'}
          onChange={(e) => {
            setAuthorizationHeader((prev) => {
              return {
                ...prev,
                oauth1: { ...prev.oauth1, oauth_signature_method: e.target.value }
              };
            });
          }}>
          <MenuItem divider={true} value={'HMAC-SHA1'}>
            HMAC-SHA1
          </MenuItem>
          <MenuItem divider={true} value={'HMAC-SHA256'}>
            HMAC-SHA256
          </MenuItem>
          <MenuItem divider={true} value={'HMAC-SHA512'}>
            HMAC-SHA512
          </MenuItem>
          {/* <MenuItem divider={true} value={'RSA-SHA1'}>
            RSA-SHA1
          </MenuItem>
          <MenuItem divider={true} value={'RSA-SHA256'}>
            RSA-SHA256
          </MenuItem>
          <MenuItem divider={true} value={'RSA-SHA512'}>
            RSA-SHA512
          </MenuItem> */}
          <MenuItem divider={true} value={'PLAINTEXT'}>
            PLAINTEXT
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
const Algorithm = ({ field, authorizationHeader, setAuthorizationHeader }) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}>
      <Typography
        fontWeight={'medium'}
        sx={{
          flex: 1
        }}>
        {field}
      </Typography>
      <FormControl size={'large'} sx={{ flex: 1.5 }}>
        <InputLabel id={'authorization-type'}>Algorithm</InputLabel>
        <Select
          fullWidth
          size="small"
          value={authorizationHeader.digestAuth.algorithm}
          label={'algorithm'}
          labelId={'algorithm-method'}
          id={'algorithm-method'}
          onChange={(e) => {
            setAuthorizationHeader((prev) => {
              return {
                ...prev,
                digestAuth: { ...prev.digestAuth, algorithm: e.target.value }
              };
            });
          }}>
          <MenuItem divider={true} value={'MD5'}>
            MD5
          </MenuItem>
          <MenuItem divider={true} value={'MD5-sess'}>
            MD5-sess
          </MenuItem>
          <MenuItem divider={true} value={'SHA256'}>
            SHA-256
          </MenuItem>
          <MenuItem divider={true} value={'SHA256-sess'}>
            SHA-256-sess
          </MenuItem>
          <MenuItem divider={true} value={'SHA512-256'}>
            SHA-512-256
          </MenuItem>
          {/* <MenuItem divider={true} value={'SHA512-256-sess'}>
            SHA-512-256-sess
          </MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
};
const ClientAuthentication = ({ field, authorizationHeader, setAuthorizationHeader }) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}>
      <Typography
        fontWeight={'medium'}
        sx={{
          flex: 1
        }}>
        {field}
      </Typography>
      <FormControl size={'large'} sx={{ flex: 1.5 }}>
        <InputLabel id={'authorization-type'}>client authentication</InputLabel>
        <Select
          fullWidth
          size="small"
          value={authorizationHeader.oauth2.client_authentication}
          label={'algorithm'}
          labelId={'algorithm-method'}
          id={'algorithm-method'}
          onChange={(e) => {
            setAuthorizationHeader((prev) => {
              return {
                ...prev,
                oauth2: { ...prev.oauth2, client_authentication: e.target.value }
              };
            });
          }}>
          <MenuItem divider={true} value={'header'}>
            Send as Basic Auth Header
          </MenuItem>
          <MenuItem divider={true} value={'body'}>
            Send client credentials in body
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
const GrantType = ({ field, authorizationHeader, setAuthorizationHeader }) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}>
      <Typography
        fontWeight={'medium'}
        sx={{
          flex: 1
        }}>
        {field}
      </Typography>
      <FormControl size={'large'} sx={{ flex: 1.5 }}>
        <InputLabel id={'authorization-type'}>grant type</InputLabel>
        <Select
          fullWidth
          size="small"
          value={authorizationHeader.oauth2.grant_type}
          label={'grant'}
          labelId={'grant-type'}
          id={'grant-type'}
          onChange={(e) => {
            setAuthorizationHeader((prev) => {
              return {
                ...prev,
                oauth2: { ...prev.oauth2, grant_type: e.target.value }
              };
            });
          }}>
          <MenuItem divider={true} value={'authorization_code'}>
            Authorization Code
          </MenuItem>
          <MenuItem divider={true} value={'authorization_code'}>
            Authorization Code (with PKCE)
          </MenuItem>
          <MenuItem divider={true} value={'implicit'}>
            Implicit
          </MenuItem>
          <MenuItem divider={true} value={'password'}>
            Password
          </MenuItem>
          <MenuItem divider={true} value={'client_credentials'}>
            Client Credentials
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const AdvancedOptionsInput = ({
  entry,
  index,
  field,
  handleAuthFieldChange,
  authorizationHeader,
  setAuthorizationHeader
}) => {
  return (
    <Flex>
      <TextField
        fullWidth
        size="small"
        value={entry}
        onChange={(e) => {
          handleAuthFieldChange(e, 'oauth2', field);
        }}
      />
      {authorizationHeader.oauth2[field].length > 1 && (
        <Tooltip title={'Delete'}>
          <Box
            sx={{ cursor: 'pointer', flex: '2%', mx: 1 }}
            onClick={() => {
              setAuthorizationHeader((prev) => {
                const newResourceValue = prev.oauth2[field];
                newResourceValue.splice(index, 1);
                return {
                  ...prev,
                  oauth2: { ...prev.oauth2, [field]: newResourceValue }
                };
              });
            }}>
            <DeleteSvg width={16} height={18} />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};

export default Authorization;
