import { useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useActiveProject } from '../../../store/projectState';
import { useScandiumUpload } from '../../../data-layer/upload';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../../mixpanel.constants';

const ManualTestCasesUploadModal = ({ open, onClose, onComplete, refetchTests }) => {
  const activeProject = useActiveProject();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const theme = useTheme();

  const allowedFileTypes = [
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
  ];

  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles[0] || !allowedFileTypes.includes(acceptedFiles[0].type)) {
      toast.error('You can only upload excel files');
      return;
    }

    setSelectedFile(acceptedFiles[0]);
    fileInputRef.current = acceptedFiles[0];
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    maxFiles: 1
  });

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const { mutateAsync: uploadFile, isLoading: isUploadingApp } = useScandiumUpload(
    `/projects/${activeProject?.id}/test-cases/import`,
    {
      enabled: !!activeProject?.id && !!selectedFile,
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        refetchTests();
        toast.success(data.message);
        onComplete();

        trackMixPanel(TRACKING_IDS.TESTCASE_IMPORTED, {
          type: 'web',
        });
      }
    }
  );

  const handleUploadMobileApp = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error('please select a file before uploading');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);

    await uploadFile(formData);
  };

  return (
    <CustomModal open={open}>
      <Box
        component={'form'}
        onSubmit={handleUploadMobileApp}
        encType={'multipart/form-data'}
        py={1}
        px={2}
        width={'80vw'}
        maxWidth={'580px'}
        position={'relative'}>
        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          fontWeight={600}
          sx={{
            fontSize: '1.4rem',
            mb: 1
          }}>
          Manual Test Cases Upload
        </Typography>

        <Typography variant="body">
          Please upload a{' '}
          <Typography component={'span'} fontWeight={500}>
            CSV or EXCEL file{' '}
          </Typography>
          containing the details of your test cases. Only one file can be uploaded at a time. The
          information will be processed and converted into test cases.{' '}
          <Typography
            component={'a'}
            href={'/test_document_sample.xlsx'}
            download={'test_document_sample.xlsx'}
            sx={{ textDecoration: 'none' }}
            fontWeight={500}
            color={'primary'}>
            Download a sample Excel file
          </Typography>{' '}
          to see the required format.
        </Typography>

        <Flex
          {...getRootProps()}
          sx={{ bgcolor: theme.palette.background.reportCard, mt: 1, py: 4, cursor: 'pointer', justifyContent: 'center' }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography fontSize={'0.9rem'}>Drop the file here ...</Typography>
          ) : (
            <Flex sx={{ flexDirection: 'column', gap: 1 }}>
              <CloudUploadIcon color={'primary'} sx={{ fontSize: '4rem' }} />
              <Typography fontSize={'0.95rem'}>
                Drag and Drop File here, or click to select one.
              </Typography>
            </Flex>
          )}
        </Flex>

        {selectedFile && (
          <Flex flexWrap={'wrap'}>
            <Typography as={'h6'} sx={{ fontSize: '0.9rem' }}>
              {selectedFile?.name}
            </Typography>
            <Tooltip title={'Delete the selected file'}>
              <IconButton size={'small'} onClick={clearFileInput}>
                <CloseIcon color={'error'} />
              </IconButton>
            </Tooltip>
          </Flex>
        )}

        <Flex sx={{ mt: 3 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton
            onClick={() => {
              onClose();
              clearFileInput();
            }}>
            Cancel
          </OutlinedButton>
          <Tooltip title={!selectedFile ? 'Select a file before saving' : ''}>
            <ContainedButton
              disabled={!!isUploadingApp}
              isLoading={isUploadingApp}
              type={'submit'}
              color={'primary'}
              sx={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all !important'
                }
              }}
              autoFocus>
              Upload
            </ContainedButton>
          </Tooltip>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default ManualTestCasesUploadModal;
