import React, { useState, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  Paper,
  Checkbox,
  Divider,
  Typography,
  Chip
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Flex from '../../../components/base/Flex';
import { useParams } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import { useFetchTests } from '../../../data-layer/project-management';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@emotion/react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddTestCases = ({ test_Ids, setTestIds, suiteId, testIds, isFetchingSuite, isSuccess }) => {
  const [selectAll, setSelectAll] = useState(false);
  const activeProject = useActiveProject();
  const { folderId } = useParams();
  const theme = useTheme();

  const { data: tests = [], isLoading: isTestsLoading } = useFetchTests(
    activeProject?.id,
    folderId,
    {
      enabled: !!activeProject?.id,
      params: {
        unlimited: 'true'
      },
      select: (data) => data.data,
      onSuccess: (data) => {
        if (suiteId) {
          const defaultValues = data?.filter((test) => testIds?.includes(test.id));
          setTestIds(defaultValues);
        }
      }
    }
  );

  useEffect(() => {
    if (suiteId) {
      const defaultValues = tests?.filter((test) => testIds?.includes(test.id));
      setTestIds(defaultValues);
    }
  }, [tests, testIds]);

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setTestIds([...tests]);
      else setTestIds([]);
      return !prev;
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Flex
        alignItems={'flex-end'}
        sx={{ justifyContent: 'space-between', alignItems: 'flex-end', mb: '3px' }}>
        <Typography sx={{ fontSize: '1rem', color: theme.palette.text.black_grey }}>
          Add/Remove Test Cases
        </Typography>
        <Chip
          label={`${test_Ids.length} selected`}
          size="small"
          sx={{
            borderRadius: '0.4rem',
            color: 'primary',
            bgcolor: 'rgba(25, 88, 184, 0.12)'
          }}
        />
      </Flex>

      <Autocomplete
        id={'autocomplete_with_select_all_add_test_cases'}
        multiple
        required
        size={'small'}
        options={tests}
        fullWidth
        placeholder={'Search test cases'}
        limitTags={2}
        disableCloseOnSelect
        freeSolo={false}
        value={test_Ids}
        getOptionLabel={(option) => option.name}
        loading={isTestsLoading}
        onChange={(_e, value, reason) => {
          if (reason === 'clear' || reason === 'removeOption') setSelectAll(false);
          if (!!tests && reason === 'selectOption' && value.length === tests?.length)
            setSelectAll(true);
          setTestIds(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={'Search test cases'}
            required
            InputProps={{
              ...params.InputProps,
              required: test_Ids.length === 0,
              endAdornment: (
                <React.Fragment>
                  {isTestsLoading ? <CircularProgress color={'secondary'} size={14} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} sx={{ py: '0.8rem' }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.radio.main
                }
              }}
              style={{ marginRight: 6 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        PaperComponent={(paperProps) => {
          const { children, ...restPaperProps } = paperProps;
          return (
            <Paper {...restPaperProps}>
              <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
                <Chip
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggleSelectAll();
                  }}
                  label="Select all"
                  size="small"
                  sx={{
                    borderRadius: '0.3rem',
                    color: 'primary',
                    bgcolor: 'rgba(25, 88, 184, 0.12)',
                    ml: 2
                  }}
                />
              </Box>
              <Divider />
              {children}
            </Paper>
          );
        }}
      />
    </Box>
  );
};

export default AddTestCases;
