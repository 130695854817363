import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ServerErrorSvg from '../svg/ServerErrorSvg';

class ErrorBoundary extends React.Component {
  static defaultProps = {
    errorMessage: 'Sorry, an error occured'
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          display={'flex'}
          flex={1}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          py={4}>
          <ServerErrorSvg width={'100px'} />
          <Typography as={'h2'} fontWeight={500} sx={{ mt: 2, mb: 1 }}>
            {this.props.errorMessage}
          </Typography>
          <Typography fontSize={'0.8rem'}>
            This is on us, we are working really hard to fix this as soon as possible
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
