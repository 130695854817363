import React, { useState, useContext } from 'react';
import {
  Typography,
  Stack,
  TextField,
  Menu,
  MenuItem,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Divider,
  Checkbox
} from '@mui/material';
import { TestAutomationContext } from '../../../../store/testAutomationState';
import Flex from '../../../../components/base/Flex';

import CustomScrollbar from '../../../TestPage/components/CustomScrollbar';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useLocation, useNavigate } from 'react-router-dom';
import ProjectSvg from '../../../../components/svg/ProjectSvg';
import { useQueryClient } from 'react-query';
import TestSuiteSvg from '../../../../components/svg/TestSuiteSvg';
import { useScandiumMutation } from '../../../../data-layer/utils';
import { useActiveProject } from '../../../../store/projectState';
import { toast } from 'react-toastify';
import { MoreVert } from '@mui/icons-material';
import { useConfirmDialog } from '../../../../components/base/ConfirmDialog';
import { TestCaseContext } from '../../../../store/testState';
import { useTheme } from '@emotion/react';
const SuitesList = () => {
  const { automationSuites } = useContext(TestAutomationContext);

  return (
    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '500px', mt: 1 }}>
      <Stack gap={0.5} sx={{ mr: 2 }}>
        {automationSuites.map((suite, index) => {
          return <Suite key={suite.id} suite={suite} index={index} />;
        })}
      </Stack>
    </CustomScrollbar>
  );
};

const Suite = ({ suite, index }) => {
  const theme = useTheme();
  const { setActiveSuite, setHoveredSuite, setActiveTab, automationSuites } =
    useContext(TestAutomationContext);
  const { massSelection, setMassSelection } = useContext(TestCaseContext);
  const [anchorEL, setAnchorEl] = useState(null);
  const { pathname, search } = useLocation();
  const activeSuiteId = new URLSearchParams(search).get('suite_id') || '';
  const navigate = useNavigate();
  const setSuiteIdInUrl = () => {
    setActiveTab(0);
    setActiveSuite({ name: '', description: '', test_cases: [] });
    navigate(`${pathname}/edit/${suite.id}`);
  };
  return (
    <>
      <Flex
        onMouseEnter={(e) => {
          setHoveredSuite(suite);
        }}
        onMouseLeave={() => {
          // setAnchorEl(null);
        }}
        onClick={(e) => {
          // e.stopPropagation();
          e.preventDefault();
          // console.log(e.currentTarget);
          e.currentTarget.contains(e.target) && !massSelection.active && setSuiteIdInUrl();
        }}
        sx={{
          borderRadius: 2,
          py: 1.2,
          px: 4,
          pr: 8,
          borderLeft: activeSuiteId === suite.id ? '3px solid #24C3D9' : '',
          background: theme.palette.background.suiteList,

          borderBottom: '1px solid rgba(220,220,220, .5)',
          '&:hover': {
            background: theme.palette.hover.default,
            cursor: 'pointer'
          },
          columnGap: 2,
          width: '100%'
        }}>
        <IconButton
          size={'small'}
          sx={{
            p: 0,
            m: 0,
            display: !!massSelection.active ? 'inline' : 'none'
          }}>
          <Checkbox
            checked={!!massSelection.selectedSuites.includes(suite.id)}
            onClick={(e) => {
              e.stopPropagation();
              setMassSelection((prev) => {
                let suiteIDs = [...prev.selectedSuites];
                if (suiteIDs.includes(suite.id)) {
                  suiteIDs = suiteIDs.filter((id) => id !== suite.id);
                } else {
                  suiteIDs = suiteIDs.concat(suite.id);
                }
                return { ...prev, selectedSuites: suiteIDs };
              });
            }}
          />
        </IconButton>
        <TestSuiteSvg width={24} height={24} color={'primary'} />
        <Stack>
          <Typography fontSize={15} color={'primary'}>
            {suite.name}
          </Typography>
          <Typography fontSize={13}>
            {suite.test_cases_count} Test {suite.test_cases_count > 1 ? 'cases' : 'case'}
          </Typography>
        </Stack>
        <ApiTestSuiteMenu anchorEL={anchorEL} setAnchorEl={setAnchorEl} />
      </Flex>
      {index + 1 !== automationSuites.length && <Divider orientation="horizontal" sx={{ mt: 1 }} />}
    </>
  );
};

const ApiTestSuiteMenu = ({ anchorEL, setAnchorEl }) => {
  const { pathname, search } = useLocation();
  const { hoveredSuite } = useContext(TestAutomationContext);
  const activeProject = useActiveProject();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { requestConfirm: confirmSuiteDeletion, ConfirmationDialog: DeleteApiTestSuite } =
    useConfirmDialog();
  const {
    mutateAsync: deleteTestSuite,
    isLoading: isDeleting,
    isSuccess: deleteSuccessful
  } = useScandiumMutation(`/projects/${activeProject?.id}/api-suites/${hoveredSuite?.id}`, {
    method: 'DELETE',
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['api-suites'] });
    }
  });
  const {
    mutateAsync: runTestSuite,
    isLoading: isRunningSuite,
    isSuccess: runSuccessful
  } = useScandiumMutation(`/projects/${activeProject?.id}/api-suites/${hoveredSuite?.id}/run`, {
    method: 'POST',
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success('Api suite run initiated. You will get a notification when it is ready', {
        autoClose: 1500
      });
      const timeOutId = setTimeout(() => {
        navigate(`/projects/${activeProject?.id}/api-suites/api_suite_runs?page=1`);
        return () => clearTimeout(timeOutId);
      }, 2500);
    }
  });

  return (
    <Box
      sx={{
        ml: 'auto'
      }}>
      <IconButton
        aria-haspopup="true"
        disabled={isRunningSuite}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setAnchorEl(e.currentTarget);
        }}>
        {isRunningSuite ? (
          <CircularProgress size={18} color={'primary'} />
        ) : (
          <MoreVert color={'primary'} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEL}
        open={!!anchorEL}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <MenuItem
          onClick={() => {
            runTestSuite();
            setAnchorEl(null);
          }}>
          Run{' '}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`${pathname}/edit/${hoveredSuite.id}`);
          }}>
          Edit{' '}
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirmSuiteDeletion();
          }}>
          Delete
        </MenuItem>
      </Menu>
      <DeleteApiTestSuite
        runRequest={deleteTestSuite}
        runningRequest={isDeleting}
        requestSuccessful={deleteSuccessful}
        title={`Are you sure you want to delete suite "${hoveredSuite?.name}" ?`}
        description={
          'When you delete a test suite, all associated test cases and data will be permanently removed'
        }
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
    </Box>
  );
};

export default SuitesList;
