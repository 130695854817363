import { SvgIcon } from "@mui/material";

const StopSvg = ({ width = 20, height = 20, fill, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={"0 0 10 10"}
      {...props}
      fill={"none"}
    >
      <g clipPath="url(#clip0_924_3243)">
        <path
          d="M4.60133 9.79542C7.0916 9.79542 9.11036 7.77666 9.11036 5.28638C9.11036 2.79611 7.0916 0.777344 4.60133 0.777344C2.11105 0.777344 0.0922852 2.79611 0.0922852 5.28638C0.0922852 7.77666 2.11105 9.79542 4.60133 9.79542Z"
          fill={fill || "#EF1B1B"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.95204 3.44922H6.25118C6.35466 3.44922 6.43906 3.53362 6.43906 3.6371V6.93624C6.43906 7.03972 6.35466 7.1241 6.25118 7.1241H2.95204C2.84856 7.1241 2.76416 7.03972 2.76416 6.93624V3.6371C2.76418 3.53362 2.84856 3.44922 2.95204 3.44922Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_924_3243">
          <rect
            width="9.01808"
            height="9.01808"
            fill="white"
            transform="translate(0.0922852 0.777344)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default StopSvg;
