import { Box, Paper, TableContainer, styled, tableCellClasses, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { rgba } from '../../../utils/colors';
import TableRow from '@mui/material/TableRow';
import { StyledTableRow2 } from '../../../components/base/Table';
import Flex from '../../../components/base/Flex';
import { StyledTableCell, StyledTableCell2 } from './StepsListView';
import { useTheme } from '@emotion/react';

const StatusCodeDisplay = ({ statusCode }) => {
  const statusCodeColors = {
    '2xx': rgba('#00CA08', 19),
    '3xx': rgba('#FFEA00', 20),
    '4xx': rgba('#EF1B1B', 20),
    '5xx': rgba('#EF1B1B', 20),
    default: 'transparent'
  };

  const getBackgroundColor = (code) => {
    const codeGroup = Math.floor(code / 100);

    // Map the code group to the corresponding background color
    const colorKey =
      codeGroup === 2
        ? '2xx'
        : codeGroup === 3
        ? '3xx'
        : codeGroup === 4
        ? '4xx'
        : codeGroup === 5
        ? '5xx'
        : 'default';

    return statusCodeColors[colorKey];
  };

  const backgroundColor = getBackgroundColor(statusCode);

  return (
    <Box
      sx={{
        padding: '4px 6px',
        borderRadius: '5px',
        backgroundColor: backgroundColor,
        color: '#787878',
        fontWeight: 'bold',
        textAlign: 'center'
      }}>
      {statusCode}
    </Box>
  );
};

const NetworkRow = ({ index, isOpen, onRowClick, log }) => {
  const theme = useTheme();
  const contentType = (responseData) => {
    return (
      responseData?.response?.headers?.find(
        (header) => header.name.toLowerCase() === 'content-type'
      )?.value || ''
    );
  };

  const contentSizeInKb = (responseData) => {
    return (
      responseData?.response?.headers?.find(
        (header) => header.name.toLowerCase() === 'content-length'
      )?.value / 1024 || ''
    );
  };

  return (
    <>
      <StyledTableRow2 sx={{ textDecoration: 'none' }} onClick={() => onRowClick(index)}>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>
            <Flex>
              {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} {log.request?.method}
            </Flex>
          </Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>
            <StatusCodeDisplay statusCode={log?.response?.status} />
          </Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>{log?.request?.url}</Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>{contentType(log)}</Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>{contentSizeInKb(log)}</Box>
        </StyledTableCell2>
        <StyledTableCell2 align="right">
          <Box className={'cell-content-wrapper'}>{log.time}</Box>
        </StyledTableCell2>
      </StyledTableRow2>

      {isOpen && (
        <StyledTableRow2>
          <StyledTableCell2 colSpan={6}>
            <Box
              sx={{
                whiteSpace: 'pre-wrap',
                fontSize: 12,
                bgcolor: theme.palette.background.lightGrey_dark
              }}
              component={'pre'}
              width={'inherit'}
              px={2}>
              {JSON.stringify(log, null, 2)}
            </Box>
          </StyledTableCell2>
        </StyledTableRow2>
      )}
    </>
  );
};

const NetworkLogsTable = ({ openRow, setOpenRow, networkLogs }) => {
  const handleRowClick = (index) => {
    setOpenRow((prevOpenRow) => (prevOpenRow === index ? null : index));
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          mb: 2,
          boxShadow: 'none',
          position: 'relative',
          maxHeight: '48vh'
        }}>
        <Table stickyHeader aria-label={'sticky table'} sx={{ width: '100%', minWidth: 700 }}>
          <TableHead sx={{ zIndex: 5 }}>
            <TableRow>
              <StyledTableCell>Method</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>URL</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Size</StyledTableCell>
              <StyledTableCell>Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {networkLogs?.map((log, i) => (
              <NetworkRow
                key={i}
                index={i}
                log={log}
                isOpen={openRow === i}
                onRowClick={handleRowClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!networkLogs?.length && (
        <Flex columnGap={3} sx={{ justifyContent: 'center', py: '8%', flexDirection: 'column' }}>
          <Typography variant={'body1'} textAlign={'center'}>
            Network logs from the device will appear here
          </Typography>
        </Flex>
      )}
    </Box>
  );
};

export default NetworkLogsTable;
