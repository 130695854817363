import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import { toast } from 'react-toastify';

const channelMap = {
  slack: {
    label: 'slack',
    description: 'Enter Slack URLs below. Press the Enter key after each URL to add another',
    title: 'Slack Input Modal'
  },
  webhook: {
    label: 'webhook',
    description: 'Enter one or multiple Webhook URLs below, separated by commas or spaces.',
    title: 'Webhook Input Modal'
  },
  email: {
    label: 'email',
    description: 'Enter one or multiple email addresses below, separated by commas or spaces.',
    title: 'Email Entry Modal'
  }
};

const MultiInputModal = ({
  open,
  onClose,
  value,
  handleUpdate,
  onComplete,
  webhook = false,
  updateChannelData,
  channelName = 'email',
  setSuccessChannels,
  successChannels,
  errorChannels,
  setErrorChannels,
  activeTab,
  handlePostChannels
}) => {
  const activeChannel = activeTab === 0 ? successChannels : errorChannels;
  const [inputValues, setInputValues] = React.useState(
    activeChannel
      .find((channel) => channel.channel === channelMap[channelName].label)
      ?.channel_details?.data?.join(', ') || [].join(', ')
  );

  const handleInputChange = (event) => {
    setInputValues(event.target.value);
  };

  const handleSave = async () => {
    const newValues = inputValues
      .split(/[\s,]+/)
      .map((value) => value.trim())
      .filter((value) => value !== '');

    await updateChannelData(channelMap[channelName].label, newValues);
    onComplete();

    // await handlePostChannels()
  };

  React.useEffect(() => {
    setInputValues(
      activeChannel
        .find((channel) => channel.channel === channelName)
        ?.channel_details?.data?.join(', ') || [].join(', ')
    );
  }, [channelName, activeChannel]);

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          {channelMap[channelName] ? channelMap[channelName].title : ''}
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          {channelMap[channelName] ? channelMap[channelName].description : ''}
        </Typography>

        <Box mb={3}>
          <TextField
            label="Enter values (comma or space separated)"
            variant="outlined"
            fullWidth
            value={inputValues}
            size={'small'}
            onChange={handleInputChange}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton onClick={handleSave} color={'primary'} autoFocus>
            {webhook
              ? `Add ${inputValues.length > 1 ? 'URLs' : 'URL'}`
              : `Add ${inputValues.length > 1 ? 'recipients' : 'recipient'}`}
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default MultiInputModal;
