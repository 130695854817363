import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { capitalize } from '../../utils/strings';

const Dropdown = ({ value, onChange, label, options, disabled }) => {
  return (
    <FormControl
      fullWidth
      required
      disabled={disabled}
      size={'small'}
      sx={{
        height: { xs: 1, sm: 1 },
        mt: { xs: 1.5, sm: 1.5 }
      }}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={value}
        label={label}
        MenuProps={{
          elevation: 1
        }}
        renderValue={(selected) => capitalize(selected || '')}
        onChange={(e) => onChange(e.target.value)}>
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            value={option.value}
            divider={index + 1 < options.length && true}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
