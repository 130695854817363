import { Box, CircularProgress } from '@mui/material';

const PageLoader = ({ height = '400px' }) => {
  return (
    <Box
      height={height}
      sx={{
        mt: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <CircularProgress color={'secondary'} size={26}></CircularProgress>
    </Box>
  );
};

export default PageLoader;
