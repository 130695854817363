import { useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DateRangePicker } from 'rsuite';
import { format, startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../components/base/Flex';
import ExportSvg from '../../components/svg/ExportSvg';
import ContainedButton from '../../components/base/ContainedButton';
import WebSvg from '../../components/svg/WebSvg';
import MobileReportSvg from '../../components/svg/MobileReportSvg';
import ApiSvg from '../../components/svg/ApiSvg';
import ReportCard from './components/ReportCard';
import { useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';

export const Ranges = [
  {
    label: 'today',
    value: [startOfDay(new Date()), endOfDay(new Date())]
  },
  {
    label: 'yesterday',
    value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))]
  },
  {
    label: 'last7Days',
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())]
  },
  {
    label: 'Last 30 Days',
    value: [startOfDay(subDays(new Date(), 29)), endOfDay(new Date())]
  }
];

const Reports = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const dateFrom = searchParams.get('date_from');
  const dateTo = searchParams.get('date_to');

  const activeProject = useActiveProject();

  const { afterToday } = DateRangePicker;
  const startDate = new Date(dateFrom) || null;
  const endDate = new Date(dateTo) || null;
  const default_date_from = format(startOfDay(subDays(new Date(), 6)), 'yyyy-LL-dd');
  const default_date_to = format(endOfDay(new Date()), 'yyyy-LL-dd');

  const handleChange = (newRange) => {
    if (newRange) {
      const dateFrom = format(newRange[0], 'yyyy-LL-dd');
      const dateTo = format(newRange[1], 'yyyy-LL-dd');
      setSearchParams({ date_from: dateFrom, date_to: dateTo });
      navigate(pathname + `?date_from=${dateFrom}&date_to=${dateTo}`);
    } else {
      // Handle case where range is cleared
      setSearchParams({ date_from: default_date_from, date_to: default_date_to });
      navigate(pathname + `?date_from=${default_date_from}&date_to=${default_date_to}`);
    }
  };

  const { data: reports, isLoading } = useScandiumQuery(`/projects/${activeProject?.id}/reports`, {
    enabled: !!activeProject?.id,
    params: {
      date_from: dateFrom || undefined,
      date_to: dateTo || undefined
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const exportAsPDF = () => {
    html2canvas(document.body)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        const now = new Date();
        const formattedDateTime = now.toLocaleString();

        const filename = `scandium_report_page_${formattedDateTime}.pdf`;
        pdf.save(filename);

        trackMixPanel(TRACKING_IDS.DATA_EXPORTED, {
          type: 'reports',
        });
      })
      .catch((err) => {
        toast.error('Error exporting page to PDF');
      });
  };

  return (
    <Box
      sx={{
        mt: { xs: '3.5rem', sm: '9rem' },
        mb: '10rem',
        ml: { xs: '1.5rem', sm: '2rem', md: '4rem' },
        mr: { xs: '1.5rem', sm: '2rem', md: '7rem' },
        position: 'relative'
      }}>
      <Flex justifyContent={'space-between'} flexWrap={'wrap'} mb={8}>
        <Typography
          color={'primary'}
          sx={{
            textAlign: 'right',
            fontSize: '1.3rem',
            fontWeight: 500
          }}>
          Reports
        </Typography>
        <Flex flexWrap={'wrap'}>
          <DateRangePicker
            shouldDisableDate={afterToday()}
            appearance={'default'}
            style={{ width: 220 }}
            value={[startDate, endDate]}
            loading={isLoading}
            placement={'bottom'}
            ranges={Ranges}
            cleanable={false}
            onChange={handleChange}
            size={'sm'}
            placeholder={'Filter by Date'}
          />

          <ContainedButton
            startIcon={<ExportSvg width={18} height={18} />}
            label={'Export'}
            onClick={exportAsPDF}
            sx={{
              borderRadius: '0.4rem',
              bgcolor: 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 2,
              minHeight: '30px'
            }}>
            Export
          </ContainedButton>
        </Flex>
      </Flex>
      {activeProject?.type === 'web' && (
        <>
          <ReportCard
            title={'Web'}
            icon={<WebSvg width={32} height={32} sx={{ display: 'block' }} />}
            data={reports?.web}
          />
          <ReportCard
            title={'API'}
            icon={<ApiSvg width={32} height={32} sx={{ display: 'block' }} />}
            data={reports?.api}
          />
        </>
      )}
      {activeProject?.type === 'mobile' && (
        <ReportCard
          title={'Mobile'}
          icon={<MobileReportSvg width={32} height={32} sx={{ display: 'block' }} />}
          data={reports?.mobile}
        />
      )}
    </Box>
  );
};

export default Reports;
