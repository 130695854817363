import { SvgIcon } from '@mui/material';

const KeyPressSvg = ({ width = 24, height = 32, disabled, fill, ...props }) => {
  return (
    <SvgIcon sx={{ width, height }} viewBox={'0 0 14 21'} {...props} fill={'none'}>
      <path
        d="M1.51999 7.03627C1.62604 7.03627 1.73262 7.0002 1.81997 6.9265C2.01639 6.76064 2.0413 6.46694 1.87544 6.2706C1.3923 5.69833 1.12629 4.97005 1.12629 4.21989C1.12629 2.46607 2.55314 1.0393 4.30689 1.0393C6.06063 1.0393 7.48748 2.46607 7.48748 4.21989C7.48748 4.70924 7.37949 5.17826 7.16655 5.61423C7.05368 5.84525 7.1494 6.12391 7.38042 6.2367C7.61136 6.34988 7.89009 6.25377 8.00289 6.02282C8.27859 5.45854 8.41831 4.85198 8.41831 4.21989C8.41831 1.95275 6.57395 0.108398 4.30681 0.108398C2.03967 0.108398 0.195312 1.95275 0.195312 4.21989C0.195312 5.18959 0.539359 6.13112 1.164 6.87103C1.25616 6.98011 1.38765 7.03627 1.51999 7.03627Z"
        fill={fill || '#1958B8'}
      />
      <path
        d="M13.0668 11.1059C12.7669 9.3325 11.49 9.09147 10.94 9.07162C10.7843 9.06595 10.6424 8.98403 10.5589 8.85254C10.1426 8.19656 9.57798 8.02396 9.09949 8.02396C8.88042 8.02388 8.6795 8.06003 8.51884 8.10254C8.47168 8.11496 8.42366 8.12108 8.37618 8.12108C8.20722 8.12108 8.04439 8.04397 7.94269 7.90302C7.53309 7.33556 6.99852 7.18211 6.54028 7.18211C6.41771 7.18211 6.30057 7.19313 6.19282 7.21004C6.16404 7.21462 6.13549 7.21679 6.10749 7.21679C5.81425 7.21679 5.56687 6.97863 5.56648 6.67477L5.5626 3.39434C5.56151 2.54667 4.92578 2.12311 4.29005 2.12311C3.65308 2.12311 3.01611 2.54814 3.01719 3.39744C3.0189 4.82149 3.0241 9.09869 3.02557 10.3874C3.02588 10.6053 2.89563 10.8021 2.69471 10.8861C0.170333 11.9413 3.28762 16.6747 3.84593 17.4859C3.90039 17.565 3.93266 17.6568 3.93988 17.7526L4.07633 19.5881C4.09759 19.8713 4.33357 20.1097 4.61758 20.1097C4.61781 20.1097 4.61804 20.1097 4.61827 20.1097L10.7175 20.0827C10.9897 20.0823 11.2195 19.8707 11.2549 19.6008L11.5033 17.7039C11.5139 17.6239 11.5429 17.5446 11.5866 17.4768C13.2167 14.9573 13.3447 12.7496 13.0668 11.1059Z"
        fill={fill || '#1958B8'}
      />
    </SvgIcon>
  );
};

export default KeyPressSvg;
