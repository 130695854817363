import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import Dropdown from '../../../components/base/Dropdown';
import UserContext from '../../../store/userState';
import { capitalize } from '../../../utils/strings';
import RolesContext from '../../../store/rolesState';
import { TRACKING_IDS, trackMixPanel } from '../../../mixpanel.constants';

const extractEmailDomain = (email) => {
  const atIndex = email?.indexOf('@');
  if (atIndex >= 0) {
    return email.slice(atIndex);
  } else {
    return '';
  }
};

const Designation = ({ value, onChange, _designation }) => {
  return (
    <FormControl
      fullWidth
      required
      size={'small'}
      sx={{
        height: { xs: 1, sm: 1 },
        mt: { xs: 1.5, sm: 1.5 }
      }}>
      <InputLabel id="select-label">Designation</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        value={value}
        label={'Designation'}
        renderValue={(selected) => capitalize(selected || '')}
        MenuProps={{
          elevation: 1
        }}
        onChange={(e) => onChange(e.target.value)}>
        {_designation?.map((option, index) => (
          <MenuItem
            key={index}
            value={option.name}
            divider={index + 1 < _designation.length && true}>
            {capitalize(option.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AddMembersModal = ({ open, onClose, onComplete, refetchMembers }) => {
  const { userData } = React.useContext(UserContext);
  const [email, setEmail] = React.useState(extractEmailDomain(userData?.user?.email) || '');
  const [role, setRole] = React.useState('member');
  const [designation, setDesignation] = React.useState('');
  const activeProject = useActiveProject();
  const { roles: _designation } = React.useContext(RolesContext)

  const { mutateAsync: InviteMember, isLoading } = useScandiumMutation(`/company/members`, {
    enabled: !!activeProject?.id,
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data.message);
      refetchMembers();
      setEmail(userData?.user?.email || '');

      trackMixPanel(TRACKING_IDS.MEMBER_INVITED, {
        type: 'organization'
      });
    }
  });

  const handleInviteMember = async (e) => {
    e.preventDefault();

    if (!email || !role) {
      toast.error('Email and role must be specified');
      return;
    }

    await InviteMember({ email, role, designation });
  };

  const options = [
    { label: 'Member', value: 'member' },
    { label: 'Admin', value: 'admin' }
  ];

  return (
    <CustomModal open={open}>
      <Box
        py={2}
        px={2}
        width={'80vw'}
        maxWidth={'480px'}
        position={'relative'}
        component={'form'}
        onSubmit={handleInviteMember}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          New Member Invitation
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          The invitee's email address should be from the same organization for them to join this
          team project.
        </Typography>

        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            autoFocus={true}
            required
            size={'small'}
            type={'email'}
            label={`Email`}
            fullWidth
            sx={{ mb: 1 }}
          />

          <Box mb={1}>
            <Dropdown value={role} onChange={setRole} label={'Role'} options={options} />
          </Box>

          <Designation
            value={designation}
            onChange={setDesignation}
            _designation={_designation}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!email || !role || isLoading}
            isLoading={isLoading}
            type={'submit'}
            color={'primary'}
            sx={{
              '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important'
              }
            }}
            autoFocus>
            Send Invite
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default AddMembersModal;
