import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useMenu } from '../../../components/base/useMenu';
import { toast } from 'react-toastify';

const ProjectsActionMenu = React.forwardRef(
  ({ isLoading, onDelete, projectId, project, userData }, ref) => {
    const { menuProps, triggerProps } = useMenu();

    const copyProjectIdToClipBoard = () => {
      navigator.clipboard.writeText(projectId);

      toast.success('copied to clipboard');
      menuProps.onClose();
    };

    return (
      <div>
        <IconButton
          ref={ref}
          aria-controls={menuProps.open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={isLoading}
          aria-expanded={menuProps.open ? 'true' : undefined}
          {...triggerProps}>
          {isLoading ? (
            <CircularProgress size={18} color={'primary'} />
          ) : (
            <MoreVert color={'primary'} />
          )}
        </IconButton>
        <Menu id="action-menu" {...menuProps} elevation={1}>
          <MenuItem as={NavLink} sx={{ color: 'inherit' }} to={`${projectId}/editProject`}>
            Edit
          </MenuItem>
          <MenuItem onClick={copyProjectIdToClipBoard}>Copy project id</MenuItem>
          {project.is_default !== 1 && project.user_id === userData?.user?.id && (
            <MenuItem onClick={onDelete} sx={{ color: 'red' }}>
              Delete
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
);

export default ProjectsActionMenu;
