import { SvgIcon } from '@mui/material';

const ViewStepSvg = ({ width = 25, height = 25, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={'0 0 10 9'}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.81577 4.82025C5.08198 4.82025 5.29779 4.60444 5.29779 4.33823C5.29779 4.07201 5.08198 3.8562 4.81577 3.8562C4.54955 3.8562 4.33374 4.07201 4.33374 4.33823C4.33374 4.60444 4.54955 4.82025 4.81577 4.82025Z"
        fill="#1958B8"
      />
      <path
        d="M4.81577 2.89215C3.52587 2.89215 2.8479 4.00418 2.67317 4.33823C2.84742 4.67757 3.51526 5.7843 4.81577 5.7843C6.10567 5.7843 6.78339 4.67227 6.95837 4.33823C6.78364 3.99744 6.11579 2.89215 4.81577 2.89215ZM4.81577 5.30228C4.6251 5.30228 4.43871 5.24574 4.28017 5.13981C4.12163 5.03387 3.99807 4.88331 3.9251 4.70715C3.85213 4.531 3.83304 4.33716 3.87024 4.15015C3.90744 3.96314 3.99926 3.79137 4.13408 3.65654C4.26891 3.52172 4.44068 3.4299 4.62769 3.3927C4.8147 3.3555 5.00854 3.37459 5.18469 3.44756C5.36085 3.52053 5.51142 3.64409 5.61735 3.80263C5.72328 3.96117 5.77982 4.14756 5.77982 4.33823C5.77982 4.59391 5.67825 4.83912 5.49745 5.01991C5.31666 5.20071 5.07145 5.30228 4.81577 5.30228Z"
        fill="#1958B8"
      />
      <path
        d="M4.81577 0C3.95775 0 3.119 0.254433 2.40558 0.731123C1.69216 1.20781 1.13612 1.88535 0.807769 2.67806C0.479419 3.47077 0.393507 4.34304 0.560899 5.18457C0.72829 6.02611 1.14147 6.7991 1.74818 7.40582C2.35489 8.01253 3.12789 8.42571 3.96942 8.5931C4.81096 8.76049 5.68323 8.67458 6.47594 8.34623C7.26864 8.01788 7.94618 7.46184 8.42287 6.74842C8.89956 6.035 9.154 5.19625 9.154 4.33823C9.15266 3.18807 8.69516 2.0854 7.88188 1.27212C7.06859 0.458832 5.96593 0.00133951 4.81577 0ZM7.44738 4.43319C7.41533 4.50694 6.64072 6.26633 4.81577 6.26633C2.99082 6.26633 2.21621 4.50694 2.18415 4.43319C2.17129 4.40318 2.16466 4.37087 2.16466 4.33823C2.16466 4.30558 2.17129 4.27327 2.18415 4.24327C2.21621 4.16952 2.99082 2.41013 4.81577 2.41013C6.64072 2.41013 7.41533 4.16952 7.44738 4.24327C7.46025 4.27327 7.46688 4.30558 7.46688 4.33823C7.46688 4.37087 7.46025 4.40318 7.44738 4.43319Z"
        fill="#1958B8"
      />
    </SvgIcon>
  );
};

export default ViewStepSvg;
