import ChromeSvg from '../../../components/svg/ChromeSvg';
import { Typography } from '@mui/material';
import LinuxSvg from '../../../components/svg/LinuxSvg';
import React from 'react';
import MacOsSvg from '../../../components/svg/MacOsSvg';
import WindowsSvg from '../../../components/svg/WindowsSvg';
import EdgeSvg from '../../../components/svg/EdgeSvg';
import FirefoxSvg from '../../../components/svg/FirefoxSvg';

const ExecutionEnv = ({ browser, os }) => {
  const browserIconMap = {
    chrome: <ChromeSvg width={20} />,
    'microsoft edge': <EdgeSvg width={20} />,
    edge: <EdgeSvg width={20} />,
    microsoftedge: <EdgeSvg width={20} />,
    firefox: <FirefoxSvg width={20} />
  };

  const browserLabelMap = {
    chrome: 'Google Chrome',
    'microsoft edge': 'Microsoft Edge',
    edge: 'Microsoft Edge',
    microsoftedge: 'Microsoft Edge',
    firefox: 'Mozilla Firefox'
  };

  const osIcon = () => {
    const _os = os.substring(0, 3);
    const osIconMap = {
      mac: <MacOsSvg width={20} />,
      lin: <LinuxSvg width={20} />,
      win: <WindowsSvg width={20} />
    };
    return osIconMap[_os];
  };

  const osLabel = () => {
    const _os = os.substring(0, 3);
    const osLabelMap = {
      mac: 'MacOs',
      lin: 'Linux',
      win: 'Windows'
    };

    return osLabelMap[_os] || os;
  };

  return (
    <>
      {browserIconMap[browser]}
      <Typography fontSize={'0.8rem'} sx={{ lineHeight: 1, mt: '2px' }}>
        {browserLabelMap[browser]} /
      </Typography>
      {osIcon()}
      {osLabel()}
    </>
  );
};

export default ExecutionEnv;
