import React, { useContext } from 'react';
import { useScandiumQuery } from '../data-layer/utils';
import { useActiveProject } from './projectState';

export const CollaboratorsContext = React.createContext({
  collaborators: [],
  collaboratorsError: null,
  isLoadingCollaborators: false
});

export const CollaboratorsContextProvider = ({ children }) => {
    const activeProject = useActiveProject();

  const {
    data: collaborators = [],
    error: collaboratorsError,
    isLoading: IsLoadingCollaborators, 
    refetch: refetchCollaborators
  } = useScandiumQuery(`/projects/${activeProject?.id}/collaborators`, {
    enabled: !!activeProject?.id,
    select: (data) => data?.collaborators.data
  });

  return (
    <CollaboratorsContext.Provider
      value={{
        collaborators,
        collaboratorsError,
        IsLoadingCollaborators,
        refetchCollaborators
      }}>
      {children}
    </CollaboratorsContext.Provider>
  );
};

export const useCollaborators = () => useContext(CollaboratorsContext);

