import React from 'react';
import { useCookies } from 'react-cookie';
import { useScandiumQuery } from '../data-layer/utils';

export const RolesContext = React.createContext({
  roles: [],
  rolesError: null,
  isFetchingRoles: false
});

export const RolesContextProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['token', 'email', 'active_company']);

  const {
    data: roles = [],
    error: rolesError,
    isLoading: isFetchingRoles
  } = useScandiumQuery('/company/roles', {
    enabled: cookies?.active_company?.type === 'enterprise',
    select: (data) => data.data,
    onSuccess: (data) => {
      // setCookie('roles', data, {
      //   path: '/',
      //   sameSite: 'lax',
      //   secure: true
      // });
    }
  });

  return (
    <RolesContext.Provider
      value={{
        roles,
        rolesError,
        isFetchingRoles
      }}>
      {children}
    </RolesContext.Provider>
  );
};

export default RolesContext;
