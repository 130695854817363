import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment-timezone';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useScandiumMutation } from '../data-layer/utils';
import CustomModal from './base/CustomModal';
import CustomInput from './customInput';
import Flex from './base/Flex';
import OutlinedButton from './base/OutlinedButton';
import ContainedButton from './base/ContainedButton';
import { useActiveProject } from '../store/projectState';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../mixpanel.constants';

const buttonStyle = {
  // color: 'black',
  border: 'none',
  textTransform: 'none',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: 'rgba(25, 88, 184, 0.15)',
    border: 'none'
  }
};

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezoneOptions = moment.tz.names().map((timezoneName) => ({
  value: timezoneName,
  label: `(GMT${moment.tz(timezoneName).format('Z')}) ${timezoneName}`
}));

const TimeSelect = ({ time, handleTimeChange }) => {
  return (
    <FormControl required size="small" sx={{ width: 150 }}>
      <InputLabel>Select Time</InputLabel>
      <Select
        value={time}
        label="Select Time"
        onChange={handleTimeChange}
        MenuProps={{
          elevation: 1
        }}>
        {Array.from(Array(24).keys()).map((hour) => (
          <MenuItem key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
            {hour.toString().padStart(2, '0')}:00
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const TimeZoneSelect = ({ selectedTimezone, handleTimezoneChange, timezoneOptions }) => (
  <FormControl required size="small">
    <InputLabel id="timezone-select-label">Timezone</InputLabel>
    <Select
      labelId="timezone-select-label"
      value={selectedTimezone}
      label="Timezone"
      MenuProps={{
        elevation: 1
      }}
      onChange={handleTimezoneChange}>
      {timezoneOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const ScheduleStatus = ({ status, handleStatusChange }) => (
  <FormControl required fullWidth size="small" sx={{ mb: 3 }}>
    <InputLabel id="timezone-select-label">Status</InputLabel>
    <Select
      labelId="timezone-select-label"
      value={status}
      label="Status"
      MenuProps={{
        elevation: 1
      }}
      onChange={handleStatusChange}>
      <MenuItem value={'active'}>Active</MenuItem>
      <MenuItem value={'inactive'}>Inactive</MenuItem>
    </Select>
  </FormControl>
);

const ScheduleModal = ({
  open,
  onClose,
  onSuiteCreated,
  suiteId,
  scheduleType = 'test_suite',
  refetchSuites,
  schedule
}) => {
  const theme = useTheme();
  const [name, setName] = useState(schedule?.name || '');
  const [description, setDescription] = useState(schedule?.description || '');
  const [frequency, setFrequency] = useState(schedule?.frequency || 'daily');
  const [status, setStatus] = useState(schedule?.status || '');
  const [time, setTime] = useState(schedule?.time || '00:00');
  //   const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState(schedule?.days || []);
  const [selectedTimezone, setSelectedTimezone] = useState(
    schedule?.timezone || userTimezone || ''
  );
  const activeProject = useActiveProject();

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleDaySelect = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const { mutateAsync: postSuiteScheduling, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/schedules`,
    {
      method: schedule?.id ? 'PATCH' : 'POST',
      onSuccess: (data) => {
        toast.success(data.message);
        refetchSuites();
        onSuiteCreated();

        trackMixPanel(TRACKING_IDS.SCHEDULE_CREATED, {
          type: scheduleType,
          status: status || 'active',
          frequency,
          timezone: selectedTimezone
        })
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (frequency === 'weekly' && !selectedDays?.length) {
      toast.error('Please select at least one day of the week.');
      return;
    }

    const payload = {
      name,
      description,
      frequency,
      schedulable_type: scheduleType,
      schedulable_id: suiteId,
      time: frequency === 'hourly' ? undefined : time,
      days: frequency === 'weekly' ? selectedDays : undefined,
      timezone: frequency === 'hourly' ? undefined : selectedTimezone,
      ...(schedule?.id ? { suite_schedule_id: schedule.id, status } : {})
    };
    await postSuiteScheduling(payload);
  };

  return (
    <CustomModal open={open}>
      <Box
        component={'form'}
        onSubmit={handleSubmit}
        py={2}
        px={2}
        width={'80vw'}
        maxWidth={'600px'}
        position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          mb={3}
          sx={{
            fontSize: '1.5rem'
          }}>
          Test Scheduling
        </Typography>

        <Box mb={3}>
          <CustomInput
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            required
            size={'small'}
            label={'Name'}
            fullWidth
          />
        </Box>

        {schedule && <ScheduleStatus status={status} handleStatusChange={handleStatusChange} />}

        <Box mb={3}>
          <TextField
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
            multiline
            label={'Description'}
            rows={2}
            fullWidth
          />
        </Box>

        {/* Scheduling Buttons */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Button
            variant={'outlined'}
            sx={{
              bgcolor:
                frequency === 'hourly'
                  ? theme.palette.background.activeSchedule
                  : 'rgba(246, 246, 246, 1)',
              ...buttonStyle
              // color: theme.palette.text.white_black,
            }}
            onClick={() => setFrequency('hourly')}>
            Hourly
          </Button>
          <Button
            variant={'outlined'}
            sx={{
              bgcolor:
                frequency === 'daily'
                  ? theme.palette.background.activeSchedule
                  : 'rgba(246, 246, 246, 1)',
              ...buttonStyle
              // color: theme.palette.text.white_black,
            }}
            onClick={() => setFrequency('daily')}>
            Daily
          </Button>
          <Button
            variant={'outlined'}
            sx={{
              bgcolor:
                frequency === 'weekly'
                  ? theme.palette.background.activeSchedule
                  : 'rgba(246, 246, 246, 1)',
              ...buttonStyle
              // color: theme.palette.text.white_black,
            }}
            onClick={() => setFrequency('weekly')}>
            Weekly
          </Button>
          {/* <Button
            variant={'outlined'}
            sx={{
              bgcolor:
                frequency === 'monthly' ? 'rgba(25, 88, 184, 0.13)' : 'rgba(246, 246, 246, 1)',
                color: theme.palette.text.black_white,
              ...buttonStyle
            }}
            onClick={() => setFrequency('monthly')}>
            Monthly
          </Button> */}
        </Box>

        {frequency !== 'none' && (
          <Typography variant="caption" sx={{ mb: 1 }}>
            {frequency === 'hourly' ? 'Runs once every hour' : null}
          </Typography>
        )}

        {frequency === 'daily' && (
          <Flex sx={{ mt: 2 }}>
            <TimeSelect time={time} handleTimeChange={handleTimeChange} />
            <TimeZoneSelect
              selectedTimezone={selectedTimezone}
              handleTimezoneChange={handleTimezoneChange}
              timezoneOptions={timezoneOptions}
            />
          </Flex>
        )}

        {frequency === 'weekly' && (
          <Box>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(
                (day) => (
                  <Button
                    key={day}
                    variant={'outlined'}
                    sx={{
                      bgcolor: selectedDays.includes(day)
                        ? theme.palette.background.activeSchedule
                        : 'rgba(246, 246, 246, 1)',
                      fontSize: 10,
                      px: 2,
                      ...buttonStyle,
                      textTransform: 'capitalize'
                    }}
                    onClick={() => handleDaySelect(day)}>
                    {day.substring(0, 3)}
                  </Button>
                )
              )}
            </Box>

            <Flex sx={{ mt: 2 }}>
              <TimeSelect time={time} handleTimeChange={handleTimeChange} />
              <TimeZoneSelect
                selectedTimezone={selectedTimezone}
                handleTimezoneChange={handleTimezoneChange}
                timezoneOptions={timezoneOptions}
              />
            </Flex>
          </Box>
        )}

        {/* {frequency === 'monthly' && (
          <Box>
            <Flex sx={{ mt: 2 }}>
              <TimeSelect time={time} handleTimeChange={handleTimeChange} />
              <TimeZoneSelect
                selectedTimezone={selectedTimezone}
                handleTimezoneChange={handleTimezoneChange}
                timezoneOptions={timezoneOptions}
              />
            </Flex>
          </Box>
        )} */}

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            isLoading={isLoading}
            disabled={!name}
            type={'submit'}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default ScheduleModal;
