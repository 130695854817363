import { Box, Typography } from '@mui/material';
import NoDataSvg from '../svg/NoDataSvg';
import { useTheme } from '@emotion/react';

const EmptyState = ({
  title = "It's lonely here at the moment",
  description = 'Why not create a few entries',
  cta,
  width = '100px',
  height = '100px',
  maxWidth = '100%',
  sx
}) => {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      flex={1}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      py={4}
      sx={{ ...sx }}>
      <NoDataSvg width={width} height={height} />
      <Typography
        as={'h2'}
        textAlign={'center'}
        fontWeight={500}
        sx={{ mt: 2, mb: 1, color: theme.palette.text.black_grey }}>
        {title}
      </Typography>
      <Typography
        fontSize={'0.8rem'}
        color={theme.palette.text.black_grey}
        maxWidth={maxWidth}
        textAlign={'center'}>
        {description}
      </Typography>
      <Box mt={2}>{cta}</Box>
    </Box>
  );
};

export default EmptyState;
